import { FC, useState } from 'react'
import { TableCell, TableRow, useTheme } from '@mui/material'
import { Box, Button, Checkbox, DataTable, Dialog, Icon, Input, Grid } from '@ntpkunity/controls'
import { DataTableWrap } from 'components'
import { useStoreContext } from '@store/storeContext'
import { useGetCustomerOrders } from '@apis/order-management.service'

const CustomerOrderList: FC<{ closeVdModal: any; navigate: any }> = ({
  closeVdModal,
  navigate
}) => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const [orderIds, setOrderIds] = useState<Array<string>>([])
  const { mutate: getCustomerOrders, data: ordersList } = useGetCustomerOrders()
  const [customerEmail, setCustomerEmail] = useState('')
  const [filters, _setFilters] = useState<any>()
  const [orderFilters, setOrderFilters] = useState<any>({
    order_id: '',
    asset: '',
    vin: ''
  })
  const [handleChange, setHandleChange] = useState<any>(undefined)
  const setQueryString = (email: string): any => {
    let query_string: string = `email=${email}&order_status=Draft&dealer_id=${states?.dealerAndLenderInfo?.id}`
    if (filters?.orderIdFilter?.trimStart() != null && filters?.orderIdFilter?.trimStart() != '') {
      query_string = query_string.concat(`&reference_number=${filters?.orderIdFilter}`)
    }
    if (filters?.assetFilter?.trimStart() != null && filters?.assetFilter?.trimStart() != '') {
      query_string = query_string.concat(`&make_model_trim=${filters?.assetFilter}`)
    }
    if (filters?.vinFilter?.trimStart() != null && filters?.vinFilter?.trimStart() != '') {
      query_string = query_string.concat(`&vin=${filters?.vinFilter}`)
    }

    return query_string
  }
  // const handleFilter = () => {
  //   setQueryString(customerEmail)
  // }

  const getOrders = (email: string) => {
    getCustomerOrders(setQueryString(email))
  }
  const onCheckAllChange = (e: any) => {
    let arr: Array<string> = []
    if (e.target.checked === true) {
      ordersList?.map((x: any) => {
        arr.push(x?.Order?.reference_number)
      })
      setOrderIds(arr)
    } else {
      setOrderIds([])
    }
  }
  const onCheckChange2 = (e: any) => {
    if (e.target.checked === true) {
      setOrderIds([...orderIds, e.target.value])
    } else {
      setOrderIds(orderIds?.filter((x) => x !== e.target.value))
    }
  }
  const handleData = () => {
    navigate(
      `/lane/quote-comparison/${customerEmail}/${orderIds
        .map((option) => `reference_number=${option}`)
        .join('&')}`,
      {
        state: null
      }
    )
  }
  return (
    <>
      <Dialog
        disablePortal
        className="dialog-with-border"
        theme={theme}
        title={'Compare Deals'}
        open
        onCloseDialog={closeVdModal}
        size="lg"
        customFooter={
          <>
            {ordersList?.length > 0 ? (
              <Grid container theme={theme} spacing={2}>
                <Grid theme={theme} item xs={6} textAlign={'right'}>
                  <Button
                    theme={theme}
                    secondary
                    id="CancelBtn"
                    text="Cancel"
                    onClick={closeVdModal}
                  />
                </Grid>
                <Grid theme={theme} item xs={6}>
                  <Button
                    theme={theme}
                    primary
                    id="CompareOrders"
                    text="Compare Deals"
                    onClick={(_e: any) => {
                      handleData()
                    }}
                    disabled={orderIds.length < 2}
                  />
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </>
        }
      >
        <Input
          theme={theme}
          fullWidth
          label="Customer's Email Address"
          placeholder=""
          type="text"
          dynamic={false}
          onBlur={(e: any) => {
            setCustomerEmail(e.target.value)
            getOrders(e.target.value)
          }}
        />
        {ordersList && (
          <DataTableWrap className="table-dialog">
            <Box className="scroll" theme={theme}>
              <Box className="scroll-hide sh-fixed-cell spr-border" theme={theme} />
            </Box>
            <DataTable
              theme={theme}
              variant="basic"
              theadChildren={
                <>
                  <TableRow>
                    <TableCell className="checkbox-cell fixed-cell">
                      <Checkbox
                        theme={theme}
                        checkBoxChecked={
                          orderIds?.length === ordersList?.length && orderIds?.length != 0
                        }
                        // disabled={isDisabled}
                        onChange={onCheckAllChange}
                        label={''}
                      />
                    </TableCell>
                    <TableCell className="img-cell">
                      <Icon name="ImageIcon" />
                    </TableCell>
                    <TableCell id="priceHead" className="w-auto">
                      Year/Make/Model/Trim
                    </TableCell>
                    <TableCell id="descriptionHead">Deal ID</TableCell>
                    <TableCell id="installationModeHead" className="w-auto">
                      VIN
                    </TableCell>
                  </TableRow>
                  <TableRow className="filters-row">
                    <TableCell className="checkbox-cell"></TableCell>
                    <TableCell className="img-cell"></TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={'Search...'}
                          type="text"
                          startAdornment={undefined}
                          endAdornment={undefined}
                          value={handleChange?.asset?.trimStart() ?? ''}
                          onChange={(e) => {
                            setHandleChange({ ...handleChange, asset: e })
                          }}
                          onBlur={(_e) => {
                            setOrderFilters({
                              ...orderFilters,
                              asset: handleChange?.asset ?? ''
                            })
                          }}
                          id={'assetFilter'}
                        />
                        <Button
                          defaultBtn
                          id="priceFilter"
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={'Search...'}
                          type="text"
                          startAdornment={undefined}
                          endAdornment={undefined}
                          value={handleChange?.order_id?.trimStart() ?? ''}
                          onChange={(e) => {
                            setHandleChange({ ...handleChange, order_id: e })
                          }}
                          onBlur={(_e) => {
                            setOrderFilters({
                              ...orderFilters,
                              order_id: handleChange?.order_id ?? ''
                            })
                          }}
                          id={'orderIdFilter'}
                        />
                        <Button
                          defaultBtn
                          id="descriptionFilter"
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={'Search...'}
                          type="text"
                          startAdornment={undefined}
                          endAdornment={undefined}
                          value={handleChange?.vin?.trimStart() ?? ''}
                          onChange={(e) => {
                            setHandleChange({ ...handleChange, vin: e })
                          }}
                          onBlur={(_e) => {
                            setOrderFilters({
                              ...orderFilters,
                              vin: handleChange?.vin ?? ''
                            })
                          }}
                          id={'vinFilter'}
                        />
                        <Button
                          defaultBtn
                          id="installationModeFilter"
                          iconText={<Icon name="IconFilter" />}
                        ></Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                </>
              }
              tbodyChildren={
                <>
                  {ordersList
                    ?.filter((row: any) => {
                      if (
                        orderFilters?.order_id == '' &&
                        orderFilters?.asset == '' &&
                        orderFilters?.vin == ''
                      ) {
                        return row
                      } else if (
                        row.Order?.reference_number
                          .toLocaleLowerCase()
                          .includes(orderFilters?.order_id?.trimStart().toLocaleLowerCase()) &&
                        (row.year + ' ' + row.make + ' ' + row.model + ' ' + row.trim_description)
                          .toLocaleLowerCase()
                          .includes(orderFilters.asset?.trimStart().toLocaleLowerCase()) &&
                        row.vin
                          .toLocaleLowerCase()
                          .includes(orderFilters.vin?.trimStart().toLocaleLowerCase())
                      ) {
                        return row
                      }
                    })
                    ?.map((workOrder: any, index: number) => (
                      <TableRow key={index} className="child-tr">
                        <TableCell className="checkbox-cell fixed-cell">
                          <Checkbox
                            id={'checkBox' + workOrder?.Order?.reference_number}
                            checkBoxChecked={
                              orderIds.find((x) => x === workOrder?.Order?.reference_number)
                                ? true
                                : false
                            }
                            key={index}
                            theme={theme}
                            checkBoxValue={workOrder?.Order?.reference_number}
                            onChange={onCheckChange2}
                            label={''}
                          />
                        </TableCell>
                        <TableCell className="img-cell">
                          <img
                            className="prd-img"
                            src={workOrder?.photo_urls?.[0]?.location.toString()}
                            alt="Car"
                          />
                        </TableCell>
                        <TableCell
                          id={
                            'assets' +
                            workOrder?.year +
                            ' ' +
                            workOrder?.make +
                            ' ' +
                            workOrder?.model +
                            ' ' +
                            workOrder?.trim_description
                          }
                          className="w-auto"
                        >
                          {workOrder?.year +
                            ' ' +
                            workOrder?.make +
                            ' ' +
                            workOrder?.model +
                            ' ' +
                            workOrder?.trim_description}
                        </TableCell>
                        <TableCell id={'order_id' + workOrder?.Order?.reference_number}>
                          {workOrder?.Order?.reference_number}
                        </TableCell>
                        <TableCell id={'vin' + workOrder?.vin} className="w-auto">
                          {workOrder?.vin}
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              }
            />
          </DataTableWrap>
        )}
      </Dialog>
    </>
  )
}
export default CustomerOrderList
