import { decodeToken } from "react-jwt";

export const useExtractAndDecodeCode = () => {
  const url = window.location.href;
  const fragment = url.split("#")[1];
  const change_url = url.split("#")[0];

  if (fragment) {
    const params = new URLSearchParams(fragment);
    if (params.has("code")) {
      const code = params.get("id_token");
      if (code) {
        const decoded = decodeToken(code);
        localStorage.setItem("customer", JSON.stringify(decoded));
        window.location.replace(change_url);
        return decoded;
      }
    }
  }

  return null;
};
