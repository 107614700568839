import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const AuditTrailListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.audit-trail-list-wrap": {
      ".weekdays": {
        ".u-accordian-wrap": {
          marginBottom: 0,
          ".u-accordion-header": {
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.shape.borderRadius,
            padding: "4px 8px",
            justifyContent: "flex-start",
            minHeight: 'auto',
            svg: {
              width: 16,
              height: 16
            },
            "&.Mui-expanded": {
              backgroundColor: theme.palette.common.white,
            },
            ".MuiAccordionSummary-content": {
              margin: 0,
              flexGrow: 0,
              ".main-title": {
                ".MuiTypography-body2": {
                  fontSize: theme.typography.caption.fontSize,
                  lineHeight: theme.typography.caption.lineHeight,
                  fontWeight: theme.typography.fontWeightMedium,
                  "@media(min-width: 1599.95px)": {
                    fontSize: theme.typography.body2.fontSize,
                    lineHeight: theme.typography.body2.lineHeight,
                    fontWeight: theme.typography.body2.fontWeight,
                  }
                }
              },
            }
          },
          ".u-accordian-body": {
            padding: 0,
            border: "none",
            ".activities-wrap": {
              marginTop: 16,
              ".accordion-box": {
                "&.child": {
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                  ".u-accordian-wrap": {
                    padding: 0,
                    transition: "0.3s",
                    "&.Mui-expanded": {
                      padding: 8,
                      borderRadius: 8,
                      backgroundColor: "#f5f5f5",
                      ".u-accordion-header": {
                        "&.Mui-expanded": {
                          backgroundColor: theme.palette.common.white
                        }
                      }
                    },
                    ".u-accordion-header": {
                      backgroundColor: "#f7f7f7",
                      padding: 8,
                      ".MuiAccordionSummary-content": {
                        flexGrow: 1
                      }
                    },
                    ".u-accordian-body": {
                      ".content": {
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'baseline',
                        gap: 8,
                      }
                    }
                  }
                },
              }
            }
          }
        }
      }
    }
}));

