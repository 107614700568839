import { BlurCircular } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { Box, Grid, Typography, CircleLoader } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { Stack } from "@src/components/stack";
import { useSelector } from "react-redux";

export const ApplicantDetailsList = () => {
  const theme = useTheme();
  const { applicantDetails, dialogLoading, error } = useSelector((state: any) => state.applicantDetails);

  const basicDetailsFields = [
    {
      title: "Applicant Name:",
      textRight: true,
      subtitle: `${applicantDetails?.customer_info?.first_name ?? "N/A"} ${applicantDetails?.customer_info?.last_name ?? "N/A"}`,
    },
    { title: "ID Type:", textRight: true, subtitle: "Driving License" },
    { title: "ID Number:", textRight: true, subtitle: applicantDetails?.customer_license?.[0]?.license_number ?? "N/A" },
    {
      title: "ID Expiry Date:",
      textRight: true,
      subtitle: applicantDetails?.customer_license?.[0]?.expiry_date
        ? new Date(applicantDetails?.customer_license?.[0]?.expiry_date).toLocaleDateString()
        : "N/A",
    },
    {
      title: "Date of Birth:",
      textRight: true,
      subtitle: applicantDetails?.customer_info?.date_of_birth
        ? new Date(applicantDetails?.customer_info?.date_of_birth).toLocaleDateString()
        : "N/A",
    },
    { title: "Age:", textRight: true, subtitle: "38 Yrs." }, // Calculate dynamically if needed
    { title: "Gender:", textRight: true, subtitle: "Male" }, // Add key for gender if available
    { title: "Nationality:", textRight: true, subtitle: "N/A" },
    { title: "Education:", textRight: true, subtitle: "N/A" },
    { title: "Mobile Number:", textRight: true, subtitle: applicantDetails?.customer_info?.mobile_number ?? "N/A" },
  ];

  const addressFields = [
    {
      title: "Current Address:",
      textRight: true,
      subtitle: applicantDetails?.customer_info?.customer_addresses?.[0]?.address_line_1 ?? "N/A",
    },
    { title: "City:", textRight: true, subtitle: applicantDetails?.customer_info?.customer_addresses?.[0]?.city ?? "N/A" },
    { title: "State:", textRight: true, subtitle: applicantDetails?.customer_info?.customer_addresses?.[0]?.state_name ?? "N/A" },
    { title: "Zip Code:", textRight: true, subtitle: applicantDetails?.customer_info?.customer_addresses?.[0]?.zip_code ?? "N/A" },
    { title: "County:", textRight: true, subtitle: applicantDetails?.customer_info?.customer_addresses?.[0]?.county ?? "N/A" },
    { title: "Living Since:", textRight: true, subtitle: applicantDetails?.customer_info?.customer_addresses?.[0]?.move_in_date ?? "N/A" },
  ];

  const incomeDetailsFields = [
    { title: "Average Balance From Bank Statement:", textRight: true, subtitle: "$00,000.00" },
    { title: "Net Monthly Income After Tax:", textRight: true, subtitle: "$00,000.00" },
  ];

  if (dialogLoading) {
    return (
      <CircleLoader theme={theme} size="sm" />
    );
  }

  if (error) {
    return (
      <Box theme={theme} textAlign="center" mt={2}>
        <Typography theme={theme} variant="body2" component="p" color="error">
          Error: {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Grid theme={theme} container rowSpacing={0} columnSpacing={2} sx={{ mt: 0 }}>
      {/* Basic Details */}
      <Grid item xs={6} theme={theme}>
        <Stack paddingMd={0} paddingXs={0}>
          <Typography theme={theme} variant="subtitle2" component={"span"} className="fw-sbold" p={1}>
            Basic Details
          </Typography>
          <BasicList noWrap options={basicDetailsFields} />
        </Stack>
      </Grid>

      {/* Address Details */}
      <Grid item xs={6} theme={theme}>
        <Stack paddingMd={0} paddingXs={0}>
          <Typography theme={theme} variant="subtitle2" component={"span"} className="fw-sbold" p={1}>
            Address
          </Typography>
          <Box theme={theme}>
            <BasicList noWrap options={addressFields} />
          </Box>
          <Box theme={theme} mt={2}>
            <Typography theme={theme} variant="subtitle2" component={"span"} className="fw-sbold" p={1}>
              Income Details
            </Typography>
            <BasicList options={incomeDetailsFields} />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};
