import { TableCell, TableRow, useTheme } from "@mui/material";
import { Skeleton } from "@ntpkunity/controls";

type TableRowLoadingProps = {
  noOfCells: number;
  noOfRows?: number;
};

export const TableRowSkeleton = ({
  noOfCells,
  noOfRows = 5,
}: TableRowLoadingProps) => {
  const theme = useTheme();
  return (
    <>
      {[...Array(noOfRows)].map((_, index) => (
        <TableRow key={`row-loading-${index}`}>
          {[...Array(noOfCells)].map((_, index) => (
            <TableCell key={`row-cell-loading-${index}`}>
              <Skeleton theme={theme} height={14} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};
