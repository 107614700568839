import { useEffect, useState } from 'react'
import { TablePagination, useTheme } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Box, Button, DataTableWrap } from 'components'
import { unityTheme, Icon, Input, DataTable, Menu } from '@ntpkunity/controls'
import { IPreQualificationResponse } from 'types/pre-qualified'
import { PRE_QUALIFICATIONS_STATUS } from '@helpers/constants'
import { PreQualifiedStatus } from '@helpers/enums/pre-qualified-status.enum'
import ChevronDown from '@public/assets/icons/chevron-down'

interface IPreQualificationTableProps {
  data: IPreQualificationResponse[]
  setPreQualificationResponse: (value: IPreQualificationResponse) => void
}

const PreQualificationTable = ({
  data,
  setPreQualificationResponse
}: IPreQualificationTableProps) => {
  const theme = useTheme()
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)

  const [filters, setFilters] = useState({
    refNum: '',
    name: '',
    email: '',
    result: '',
    contact: ''
  })
  const [filteredData, setFilteredData] = useState<IPreQualificationResponse[]>([])

  useEffect(() => {
    if (data && data?.length > 0) {
      setFilteredData(data)
    }
  }, [data])

  //client side filtering
  const applyFilters = () => {
    const newFilteredData = data?.filter((item) => {
      const data: any = JSON.parse(item?.data || '')
      const key: PreQualifiedStatus.Accept | PreQualifiedStatus.Reject = data?.status

      return (
        (!filters.refNum ||
          item?.id?.toString()?.toLowerCase()?.includes(filters?.refNum?.toLowerCase())) &&
        (!filters.name ||
          data?.customer_name?.toLowerCase()?.includes(filters?.name?.toLowerCase())) &&
        (!filters.email || data?.email?.toLowerCase()?.includes(filters?.email?.toLowerCase())) &&
        (!filters.result ||
          PRE_QUALIFICATIONS_STATUS[key]
            ?.toLowerCase()
            ?.includes(filters?.result?.toLowerCase())) &&
        (!filters.contact ||
          data?.mobile_phone_number?.toLowerCase().includes(filters?.contact?.toLowerCase()))
      )
    })
    setPageNumber(0)
    setFilteredData(newFilteredData)
  }

  const handleFilterChange = (field: string) => (value: string) => {
    setFilters({ ...filters, [field]: value })
  }

  //client side pagination
  const startIndex = pageNumber * pageSize
  const endIndex = Math.min(startIndex + pageSize, data?.length || 0)
  const paginatedData = filteredData?.slice(startIndex, endIndex)

  const handleChangePage = (_: any, page: number) => {
    setPageNumber(page)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value)
    setPageNumber(0)
  }

  return (
    <>
      <DataTableWrap className="table-pagination">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide sh-fixed-cell spr-border" theme={unityTheme} />
        </Box>
        <DataTable
          theme={unityTheme}
          variant="basic"
          theadChildren={
            <>
              <TableRow>
                <TableCell id="refNumHead">Ref. No.</TableCell>
                <TableCell id="nameHead">First/Last Name</TableCell>
                <TableCell id="emailHead">Email Address</TableCell>
                <TableCell id="resultHead">Result</TableCell>
                <TableCell id="contactHead">Contact Number</TableCell>
                <TableCell className="action-cell fixed-cell"></TableCell>
              </TableRow>
              <TableRow className="filters-row">
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'refNumFilter'}
                      onChange={handleFilterChange('refNum')}
                      onBlur={applyFilters}
                      value={filters.refNum}
                    />
                    <Button
                      defaultBtn
                      id="descriptionFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'nameFilter'}
                      onChange={handleFilterChange('name')}
                      onBlur={applyFilters}
                      value={filters.name}
                    />
                    <Button
                      defaultBtn
                      id="priceFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'emailFilter'}
                      onChange={handleFilterChange('email')}
                      onBlur={applyFilters}
                      value={filters.email}
                    />
                    <Button
                      defaultBtn
                      id="installationModeFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'resultFilter'}
                      onChange={handleFilterChange('result')}
                      onBlur={applyFilters}
                      value={filters.result}
                    />
                    <Button
                      defaultBtn
                      id="installationModeFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'contactFilter'}
                      onChange={handleFilterChange('contact')}
                      onBlur={applyFilters}
                      value={filters.contact}
                    />
                    <Button
                      defaultBtn
                      id="installationModeFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell"></TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {paginatedData?.map((item: IPreQualificationResponse, index: number) => {
                const parsedItem = JSON.parse(item.data || '')
                const key: PreQualifiedStatus.Accept | PreQualifiedStatus.Reject =
                  parsedItem?.status

                return (
                  <TableRow
                    className="child-tr"
                    onDoubleClick={() => {}}
                    key={`${item?.id}-${index}`}
                  >
                    <TableCell>{item.id || '-'}</TableCell>
                    <TableCell>{parsedItem.customer_name || '-'}</TableCell>
                    <TableCell>{parsedItem.email || '-'}</TableCell>
                    <TableCell>{PRE_QUALIFICATIONS_STATUS[key] || '-'}</TableCell>
                    <TableCell>{parsedItem.mobile_phone_number || '-'}</TableCell>
                    <TableCell className="action-cell fixed-cell">
                      <Menu
                        theme={theme}
                        options={[
                          {
                            optionText: 'Results',
                            optionValue: 'Results'
                          }
                        ]}
                        handleOptionClick={() => setPreQualificationResponse(item)}
                        render={(onMenuSelection: any) => (
                          <Button
                            defaultBtn
                            iconText={<Icon name="MoreIcon" />}
                            onClick={onMenuSelection}
                          />
                        )}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </>
          }
        />
        {data && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={filteredData?.length ?? -1}
            rowsPerPage={pageSize}
            page={pageNumber}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            SelectProps={{
              IconComponent: ChevronDown
            }}
          />
        )}
      </DataTableWrap>
    </>
  )
}

export default PreQualificationTable
