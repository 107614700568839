import { FC, useState } from 'react'
import { TableCell, TablePagination, TableRow, ToggleButton, useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { DashboardStyle } from './dashboard.style'
import { Box, Button, DataTable, Icon, Input, Typography, Menu } from '@ntpkunity/controls'
import { DataTableWrap } from '@components'
import { ToggleCollapse } from '@styles/components'
import { useGetLeadDetails } from '@apis/lead-management.service'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@helpers/links'
import { LeadData } from 'models/lead-data.interface'
import { useStoreContext } from '@store/storeContext'
import { getAllSettingsFromLocalStorage } from '@helpers/methods'
import ChevronDown from '@public/assets/icons/chevron-down'

const ChildRow: FC<{ data: LeadData; vehicleIndex: number }> = ({ data }) => {
  const theme = useTheme()
  const vehicleDetails = data?.data?.vehicle_details[0]
  const customerDetails = data?.data?.customer_details
  const navigate = useNavigate()
  const handleViewDetails = (id: number) => {
    navigate(`${APP_ROUTES.LEAD_SUMMARY}/${id}`)
  }
  const getStatusClass = (status: string) => {
    switch (status?.toLowerCase()) {
      case 'completed':
        return 'green'
      case 'abandoned':
        return 'red'
      case 'active':
        return 'blue'
      default:
        return ''
    }
  }

  const settings = getAllSettingsFromLocalStorage()
  const showDealerColumn = ['Admin']?.includes(settings?.role?.name)

  return (
    <TableRow className="child-tr">
      <TableCell></TableCell>
      <TableCell>
        {vehicleDetails?.year &&
        vehicleDetails?.make &&
        vehicleDetails?.model &&
        vehicleDetails?.trim_description
          ? `${vehicleDetails.year} ${vehicleDetails?.make} ${vehicleDetails?.model} ${vehicleDetails?.trim_description}`
          : '-'}
      </TableCell>
      <TableCell>
        <span className={`status ${getStatusClass(vehicleDetails?.status)}`}></span>
        {vehicleDetails?.status || '-'}
      </TableCell>
      <TableCell>{data?.id || '-'}</TableCell>
      <TableCell>{vehicleDetails?.deal_id || '-'}</TableCell>
      {showDealerColumn && <TableCell>{vehicleDetails?.dealer_name || '-'}</TableCell>}
      <TableCell>{customerDetails?.first_name || '-'}</TableCell>
      <TableCell>{customerDetails?.last_name || '-'}</TableCell>
      <TableCell>{customerDetails?.mobile_number || '-'}</TableCell>
      <TableCell>{customerDetails?.preferred_contact || '-'}</TableCell>
      <TableCell>{vehicleDetails?.vin || '-'}</TableCell>
      <TableCell>{vehicleDetails?.stock_number || '-'}</TableCell>
      <TableCell>
        {data?.created_at ? new Date(data?.created_at).toLocaleDateString() : '-'}
      </TableCell>
      <TableCell>
        {data?.updated_at ? new Date(data?.updated_at).toLocaleDateString() : '-'}
      </TableCell>
      <TableCell>
        {data?.last_sync_date ? new Date(data.last_sync_date).toLocaleDateString() : '-'}
      </TableCell>
      <TableCell>{vehicleDetails?.lead_source || '-'}</TableCell>
      <TableCell>{vehicleDetails?.deal_status || '-'}</TableCell>
      <TableCell className="action-cell fixed-cell">
        <Menu
          theme={theme}
          options={[
            { optionText: 'View Details', optionkey: 'viewDetails', optionValue: 'View Details' }
          ].map((option: any) => {
            return {
              optionText: option.optionText,
              optionkey: option.optionkey,
              optionValue: option.optionValue
            }
          })}
          handleOptionClick={() => handleViewDetails(data?.id)}
          render={(onMenuSelection: any) => (
            <Button defaultBtn iconText={<Icon name="MoreIcon" />} onClick={onMenuSelection} />
          )}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
      </TableCell>
    </TableRow>
  )
}

const MainRow: FC<{ data: LeadData }> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)
  const theme = useTheme()
  const customerDetails = data?.data?.customer_details
  const vehicleDetails = data?.data?.vehicle_details || []

  return (
    <>
      {!data || Object.keys(data).length === 0 ? (
        <div>No data available</div>
      ) : (
        <>
          {customerDetails?.email && (
            <TableRow className="child-tr">
              <TableCell className="indent-cell" colSpan={18}>
                <Box theme={theme} display={'flex'}>
                  <ToggleCollapse theme={theme} className="toggle-collapse right-bottom" mr={1}>
                    <ToggleButton
                      value="check"
                      selected={isOpen}
                      onChange={() => setIsOpen(!isOpen)}
                    >
                      <Icon name="ChevronDown" />
                    </ToggleButton>
                  </ToggleCollapse>
                  {customerDetails?.email}
                </Box>
              </TableCell>
            </TableRow>
          )}
          {isOpen &&
            customerDetails &&
            Object.keys(customerDetails).length > 0 &&
            vehicleDetails.length > 0 && (
              <>
                {vehicleDetails.map((vehicle, index) => (
                  <ChildRow
                    key={index}
                    data={{ ...data, data: { ...data.data, vehicle_details: [vehicle] } }}
                    vehicleIndex={index}
                  />
                ))}
              </>
            )}
        </>
      )}
    </>
  )
}

type FilterKeys =
  | 'emailAddressFilter'
  | 'yearMakeModelTrimFilter'
  | 'leadStatusFilter'
  | 'leadIdFilter'
  | 'dealIdFilter'
  | 'dealerNameFilter'
  | 'firstNameFilter'
  | 'lastNameFilter'
  | 'contactNumberFilter'
  | 'preferredCommunicationFilter'
  | 'vinFilter'
  | 'stockFilter'
  | 'createdAtFilter'
  | 'lastUpdatedAtFilter'
  | 'lastSyncedStatusFilter'
  | 'leadSourceFilter'
  | 'dealStatusFilter'

interface Filters {
  [key: string]: string
}

const defaultFilters: Filters = {
  emailAddressFilter: '',
  yearMakeModelTrimFilter: '',
  leadStatusFilter: '',
  leadIdFilter: '',
  dealIdFilter: '',
  dealerNameFilter: '',
  firstNameFilter: '',
  lastNameFilter: '',
  contactNumberFilter: '',
  preferredCommunicationFilter: '',
  vinFilter: '',
  stockFilter: '',
  createdAtFilter: '',
  lastUpdatedAtFilter: '',
  lastSyncedStatusFilter: '',
  leadSourceFilter: '',
  dealStatusFilter: ''
}

const Home: FC = () => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)

  const [filters, setFilters] = useState<Filters>(defaultFilters)
  const [tempFilters, setTempFilters] = useState<Filters>(defaultFilters)

  const { data, isLoading, error } = useGetLeadDetails(
    states?.dealerAndLenderInfo?.id,
    pageNumber,
    pageSize,
    filters
  )

  const handleChangePage = (_: any, newPage: number) => {
    setPageNumber(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value)
    setPageNumber(0)
  }

  const handleFilterChange = (filterKey: FilterKeys, value: string) => {
    setTempFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: value
    }))
  }

  const applyFilters = () => {
    setFilters(tempFilters)
    setPageNumber(0)
  }

  const settings = getAllSettingsFromLocalStorage()
  const showDealerColumn = ['Admin']?.includes(settings?.role?.name)

  return (
    <LayoutWithSideNav theme={theme}>
      <DashboardStyle theme={theme} className="content-wrap">
        <Typography theme={theme} component="h2" variant="h2" sx={{ mt: 3 }}>
          Leads
        </Typography>
        <Box theme={theme} sx={{ opacity: 0.3, mt: 3, mb: 3 }}></Box>
        <DataTableWrap className="min-h" mb={2}>
          <Box theme={theme} className="scroll">
            <Box className="scroll-hide spr-border" theme={theme} />
          </Box>
          <DataTable
            theme={theme}
            theadChildren={
              <>
                <TableRow>
                  <TableCell className="indent-cell">Email Address</TableCell>
                  <TableCell className="indent-cell">Year/Make/Model/Trim</TableCell>
                  <TableCell>Lead Status</TableCell>
                  <TableCell>Lead ID </TableCell>
                  <TableCell>Deal ID </TableCell>
                  {showDealerColumn && <TableCell>Dealer Name</TableCell>}
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Contact Number</TableCell>
                  <TableCell>Preferred Communication</TableCell>
                  <TableCell>VIN</TableCell>
                  <TableCell>Stock</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Last Updated At</TableCell>
                  <TableCell>Last Synced Status</TableCell>
                  <TableCell>Lead Source</TableCell>
                  <TableCell>Deal Status </TableCell>
                  <TableCell className="action-cell fixed-cell" />
                </TableRow>
                <TableRow className="filters-row">
                  {Object.keys(filters).map((filterKey: string, index: number) => {
                    if (filterKey === 'dealerNameFilter' && !showDealerColumn) {
                      return null
                    }
                    return (
                      <TableCell key={index}>
                        <Box theme={theme} className="table-filter">
                          <Input
                            theme={theme}
                            fullWidth={true}
                            type={''}
                            startAdornment={''}
                            endAdornment={''}
                            id={filterKey}
                            onChange={(e) => handleFilterChange(filterKey as FilterKeys, e)}
                            onBlur={applyFilters}
                            value={tempFilters[filterKey as FilterKeys].trimStart()}
                            placeholder="Search"
                          />
                          <Button defaultBtn iconText={<Icon name="IconFilter" />} />
                        </Box>
                      </TableCell>
                    )
                  })}
                  <TableCell className="action-cell fixed-cell" />
                </TableRow>
              </>
            }
            tbodyChildren={
              isLoading ? (
                <TableRow>
                  <TableCell colSpan={17}>Loading...</TableCell>
                </TableRow>
              ) : error ? (
                <TableRow>
                  <TableCell colSpan={17}>Error loading data. Please try again.</TableCell>
                </TableRow>
              ) : data ? (
                data?.data?.map((item: any, index: number) => <MainRow key={index} data={item} />)
              ) : (
                <TableRow>
                  <TableCell colSpan={17}>No data available.</TableCell>
                </TableRow>
              )
            }
          />
          {data ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20]}
              component="div"
              count={data?.total_count ?? 0}
              rowsPerPage={pageSize}
              page={pageNumber}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              SelectProps={{
                IconComponent: ChevronDown
              }}
            />
          ) : null}
        </DataTableWrap>
      </DashboardStyle>
    </LayoutWithSideNav>
  )
}

export default Home
