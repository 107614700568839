import {
  VehicleCard,
  FixedVehicleCard,
  TradeInOption,
  TradeInOffer,
  TradeInDrawerContent,
  RightRail,
} from "@src/components/molecules";
import "./trade-in.scss";
import {
  Button,
  IconList,
  MessageAlert,
  Radio,
  Image,
  Drawer,
} from "@src/components/atoms";
import { Col, Row } from "antd";
import kbbBadgeImage from "../../../../shared/assets/img/trade-in-option-badge-image.png";

export const TradeIn = () => {
  return (
    <>
      <div className="trade-in">
        <div className="bmw-container">
          <Row gutter={{ xs: 16, sm: 16, md: 24 }}>
            <Col xs={24} sm={24} md={24} lg={16}>
              <div className="trade-in__left">
                <div className="trade-in__title">
                  <span className="text--body-2 label">Build my deal</span>
                  <h3>Trade-in</h3>
                </div>

                <div className="trade-in__options-content">
                  <TradeInOption
                    radioSection={<></>}
                    radio={
                      <>
                        <Radio
                          name="trade-in"
                          id="trade-in-1"
                          htmlFor="trade-in-1"
                        />
                      </>
                    }
                    radioLabel={"Continue without trade-in."}
                    radioDiscription={
                      "I no longer want to include a trade-in for this deal."
                    }
                    content={
                      <>
                        <div className="trade-in__option-estimate">
                          <div className="trade-in__option-estimate__text">
                            <small className="trade-in__option-estimate__text__title text--body-1">
                              Estimated trade-in equity:
                            </small>
                            <span className="trade-in__option-estimate__text__price text--promo-focus-price">
                              $X,XXX
                            </span>
                            <p className="trade-in__option-estimate__text__discription text--body-1">
                              Get a trade-in offer from Kelley Blue Book. This
                              step is optional for now, but we’ll need to
                              confirm your estimate before processing your
                              credit application.
                            </p>
                          </div>
                          <div className="trade-in__option-estimate__kbb">
                            <div className="trade-in__option-estimate__kbb__image">
                              <Image
                                src={kbbBadgeImage}
                                alt="image"
                                aspectRatio="9:16"
                              />
                            </div>
                            <div className="trade-in__option-estimate__kbb__inner">
                              <div className="trade-in__option-estimate__kbb__text">
                                <span className="trade-in__option-estimate__kbb__title text--body-1">
                                  Verify with Kelley Blue Book
                                </span>
                                <small className="trade-in__option-estimate__kbb__subTitle text--disclaimer text-secondary">
                                  About 15 min
                                </small>
                              </div>
                              <div className="trade-in__option-estimate__kbb__cta">
                                <Button
                                  htmlType={"button"}
                                  text="Start"
                                  type="default"
                                  size="small"
                                />
                              </div>
                            </div>
                          </div>
                          <p className="trade-in__option-estimate__contact-text text--body-2">
                            or you can{" "}
                            <a href="/" className="link-underline text--link-2">
                              contact dealer
                            </a>{" "}
                            to verify your trade-in equity.
                          </p>
                        </div>
                      </>
                    }
                  />
                  <TradeInOption
                    isActive
                    hasBadgeImage
                    radioSection={<></>}
                    radio={
                      <>
                        <Radio
                          name="trade-in"
                          id="trade-in-2"
                          htmlFor="trade-in-2"
                          checked
                        />
                      </>
                    }
                    radioLabel={"Add a trade-in."}
                    radioDiscription={
                      "Get a Kelley Blue Book trade-in value estimate within minutes."
                    }
                    content={
                      <>
                        <div className="trade-in__option-list">
                          <IconList
                            size="big"
                            listItems={[
                              {
                                className: "car-compare",
                                type: "icon",
                                text: "Get a trade-in estimate without visiting a BMW Center.",
                              },
                              {
                                className: "list-checkmark",
                                type: "icon",
                                text: "Follow along with a step-by step guide.",
                              },
                              {
                                className: "clock",
                                type: "icon",
                                text: "Complete the process in 15 minutes or less",
                              },
                            ]}
                          />
                        </div>
                      </>
                    }
                    actionArea={
                      <>
                        <Button
                          htmlType={"button"}
                          text="Start"
                          type="default"
                          fixed
                          fullWidth
                        />
                      </>
                    }
                  />
                </div>

                <TradeInOffer />

                <div className="trade-in__alert">
                  <MessageAlert
                    type="error"
                    title="Something went wrong."
                    subTitle="Sorry, we’re unable to retrieve your instant cash offer value. Please contact the dealer to discuss the next steps, or choose to proceed without a Trade-in value."
                    buttonText="Contact dealer"
                    onButtonClick={() => {}}
                  />
                </div>

                <div className="trade-in__ctas">
                  <div className="trade-in__ctas__flexible">
                    <div>
                      <Button
                        htmlType={"button"}
                        text="Continue"
                        type="primary"
                        fixed
                        fullWidth
                      />
                    </div>
                    <div>
                      <Button
                        htmlType={"button"}
                        text="Sign in to save"
                        fullWidth
                      />
                    </div>
                  </div>
                  <p className="text--disclaimer">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                    proident, sunt in culpa qui officia deserunt mollit anim id
                    est laborum.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <div className="address-right-content">
                <div className="address-right-content__inner">
                  {/* <RightRail financeNoCart /> */}
                  <VehicleCard cashDownInfo hasFooter />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <FixedVehicleCard />
      </div>
      <div className="trade-in-drawer-main">
        <Drawer
          width={336}
          open={false}
          maskClosable={true}
          keyboard={true}
          content={
            <>
              <TradeInDrawerContent />
            </>
          }
        />
      </div>
    </>
  );
};
