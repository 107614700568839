import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import { Box, Button, CircleLoader, Grid, Icon } from "@ntpkunity/controls";
import { Alert } from "@src/components";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { AssetDetailDialog } from "../asset-detail-dialog/asset-detail-dialog.component"
import { Stack } from "@src/components/stack";
import { closeAssetDialogue, fetchAssetDetailsRequest, openAssetDialog } from "@src/components/redux/actions/assetDetailsActions";

export const AssetDetailsStack = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { reference_number } = useParams();

  const { assetDetails, loading, error } = useSelector((state: any) => state.assetDetails);
  localStorage.setItem("reference_number", assetDetails?.reference_number);
  const { isOpen } = useSelector((state: any) => state.assetDialog);

  useEffect(() => {
    if (reference_number) {
    }
    dispatch(fetchAssetDetailsRequest(reference_number))
  }, [dispatch, reference_number]);

  const handleOpenDialog = (reference_number) => {
    // setOpened(true);
    dispatch(openAssetDialog(reference_number));
  };

  const handleCloseDialog = () => {
    // setOpened(false);
    dispatch(closeAssetDialogue());
  };

  const fields = [
    { title: "Make:", key: "make" },
    { title: "Model:", key: "model" },
    { title: "Year:", key: "year" },
    { title: "Trim:", key: "trim_description" },
    { title: "Condition:", key: "type" },
  ];

  return (
    <>
      <Stack
        title={"Asset Details"}
        paddingXs={2}
        paddingMd={2}
        actionArea={
          <Button
            theme={theme}
            defaultBtn
            iconText={
              <>
                <Icon name="ArrowLinkIcon" />
              </>
            }
            onClick={() => handleOpenDialog(reference_number)}
          />
        }
      >
        {loading ? (
          <Box theme={theme} mt={2}>
            <CircleLoader theme={theme} size={"sm"} />
          </Box>
        ) : error ? (
          <Box theme={theme} mt={2}>
            {error}
          </Box>
        ) : assetDetails ? (
          <>
            <Box theme={theme} mt={2}>
              <Alert text="Verified" success fullWidth />
            </Box>
            <BasicList
                  options={fields.map((field) => ({
                    title: field.title,
                    subtitle: assetDetails?.Vehicle_details?.[field.key] ?? "N/A",
                  }))}
                />
          </>
        ) : (
          <Box theme={theme} mt={2}>
            No data available
          </Box>
        )}
      </Stack>
      <AssetDetailDialog open={isOpen} closeDialog={handleCloseDialog} />
    </>
  );
};