import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

const ChatBoxWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({}) => ({
  '&.chat-box': {
    margin: '16px 40px',
    position: 'relative',
    '.u-form-group': {
      '.textarea': {
        '.MuiInputBase-root': {
          borderRadius: 16,
          padding: '16px 116px 16px 16px'
        }
      }
    },
    '.btn': {
      position: 'absolute',
      right: 16,
      bottom: 16
    }
  }
}))

export default ChatBoxWrap
