import { ProtectionAndWarranty } from "@src/components/organisms/build-your-deal";
import { BaseLayout } from "@src/layouts";

export const ProtectionAndWarrantyPage = () => {
  return (
    <BaseLayout>
      <ProtectionAndWarranty />
    </BaseLayout>
  );
};
