import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
    FETCH_FINANCE_STRUCTURE_REQUEST,
    fetchFinanceStructureSuccess,
    fetchFinanceStructureFailure,
    UPDATE_FINANCE_STRUCTURE_REQUEST,
    updateFinanceStructureSuccess,
    updateFinanceStructureFailure,
} from "../actions/financeStructureActions";

function* fetchFinanceStructureSaga(action) {
    const financeStructureRefId = action.payload;
    try {
        const response = yield call(
            axios.get,
            `${process.env.REACT_APP_API_URL}/dms/order/order_information/${financeStructureRefId}`,
            { headers: { "x-api-key": process.env.REACT_APP_API_KEY } }
        );
        yield put(fetchFinanceStructureSuccess(response.data));
    } catch (error) {
        console.error("Saga: Fetch error:", error.message);
        yield put(fetchFinanceStructureFailure(error.message));
    }
}

function* updateFinanceStructureSaga(action) {
    try {
        const response = yield call(
            axios.put,
            `${process.env.REACT_APP_API_URL}/dms/order/order_information/BU-SD-2024-366`,
            action.payload,
            { headers: { "x-api-key": process.env.REACT_APP_API_KEY } }
        );


        yield put(updateFinanceStructureSuccess(response.data));
    } catch (error) {
        console.error("Saga: Update error:", error.message);
        console.error("Saga: API error details:", error.response?.data ?? "No response data");
        yield put(updateFinanceStructureFailure(error.message));
    }
}


export function* watchFinanceStructureSaga() {
    yield takeLatest(FETCH_FINANCE_STRUCTURE_REQUEST, fetchFinanceStructureSaga);
    yield takeLatest(UPDATE_FINANCE_STRUCTURE_REQUEST, updateFinanceStructureSaga);
}
