import { TableCell, TableRow, useTheme } from "@mui/material";
import { Typography } from "@ntpkunity/controls";

type MessageRowProps = {
  message: string;
  columnSpan: number;
};

export const MessageRow = ({ message, columnSpan }: MessageRowProps) => {
  const theme = useTheme();
  return (
    <TableRow>
      <TableCell colSpan={columnSpan}>
        <Typography
          theme={theme}
          variant="body2"
          component="span"
          display="block"
          textAlign="center"
        >
          {message}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
