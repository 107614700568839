import { ReactNode } from "react";
import { Switch as AntSwitch } from "antd";
import "../../../styles/components/_switch.scss";
import clsx from "clsx";

export const Switch = ({
  onChange,
  value,
  size = "default",
  disabled,
  label,
  loading = false,
  className,
  checked,
  onClick,
}: {
  onChange?: (
    checked: boolean,
    event: React.MouseEvent | React.KeyboardEvent
  ) => void;
  onClick?: (
    checked: boolean,
    event: React.MouseEvent | React.KeyboardEvent
  ) => void;
  value?: boolean;
  size?: "default" | "small";
  disabled?: boolean;
  label?: string;
  loading?: boolean;
  className?: string;
  checked?: boolean;
}) => {
  return (
    <div
      className={clsx({
        "switch-wrapper": true,
      })}
    >
      <AntSwitch
        className={className}
        defaultChecked
        onChange={onChange}
        size={size}
        value={value}
        disabled={disabled}
        loading={loading}
        checked={checked}
        onClick={onClick}
      />
      {label && <span className="text--body-2">{label}</span>}
    </div>
  );
};
