import { Typography, DuoTab, CircleLoader, Skeleton } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { PaymentMethod } from './paymentMethod'
import { useState, type SyntheticEvent, useEffect, type FC } from 'react'
import { BankTransfer } from './BankTransfer'
import { PaymentFrequency, PaymentMethodStatus, PaymentMethodType } from '@shared/constants'
import { useGetCards } from '@modules/billingDetails/services'
import { useLocation } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'
import { useGetSubscriptions } from '@modules/product'
interface IPaymentMethodComponent {
  selectedTab?: number
  setSelectedTab?: React.Dispatch<React.SetStateAction<number>>
  setPaymentFrequency?: React.Dispatch<React.SetStateAction<string | null>>
}
export const PaymentMethodComponent: FC<IPaymentMethodComponent> = ({ selectedTab, setSelectedTab, setPaymentFrequency }) => {
  const theme = useTheme()
  const { pathname } = useLocation()
  const onCheckoutPage = pathname === APP_ROUTES.CHECKOUT
  const creditCardTabTitle = onCheckoutPage ? 'Pay By Credit Card' : 'Credit Card'
  const bankTransferTabTitle = onCheckoutPage ? 'Pay By Bank Transfer' : 'Bank Transfer'

  const [defaultTabIndex] = useState(selectedTab ?? PaymentMethodType.CARD)
  const [activeTab, setActiveTab] = useState(defaultTabIndex)
  const [disabledTab, setDisabledTab] = useState<number>(-1)

  const { data: cards, isLoading: loadingPaymentMethods } = useGetCards()

  const onTabChange = (event: SyntheticEvent, value: number): void => {
    setActiveTab(value)
    onCheckoutPage && setSelectedTab?.(value)
  }

  useEffect(() => {
    onCheckoutPage && setPaymentFrequency?.(cards?.bank_transfer?.payment_frequency ?? PaymentFrequency.MONTHLY)

    if (cards?.bank_transfer?.status === PaymentMethodStatus.APPROVED) {
      setActiveTab(PaymentMethodType.BANK_TRANSFER)
      onCheckoutPage && setDisabledTab(PaymentMethodType.CARD)
      onCheckoutPage && setSelectedTab?.(PaymentMethodType.BANK_TRANSFER)
    } else if ((cards?.data ?? []).length > 0) {
      setActiveTab(PaymentMethodType.CARD)
      onCheckoutPage && setSelectedTab?.(PaymentMethodType.CARD)
    }
  }, [cards])

  return (
    <>
      {
        onCheckoutPage
          ? (
            <>
              <Typography
                data-testid="payment-method"
                mb={0.5}
                theme={theme}
                variant="h4"
                component="h4"
                className="text-h4"
              >
                Payment Method
              </Typography>
              <Typography
                className="text-muted"
                theme={theme}
                variant="caption"
                component="small"
                display='block'
                mb={3}
              >
                Complete your checkout to get access for 30 days free of cost!
              </Typography>
            </>
            )
          : (
            <Typography
              theme={theme}
              variant="h3"
              component="h3"
              className="text-h3"
              sx={{ mb: 3 }}
            >
              Payment Method
            </Typography>
            )
      }
      <DuoTab
        theme={theme}
        varient={'default'}
        items={[
          {
            title: <>
              {loadingPaymentMethods ? <Skeleton theme={theme} width={'100%'} height={17} /> : creditCardTabTitle}
            </>,
            content: <PaymentMethod />,
            disabled: disabledTab === PaymentMethodType.CARD
          },
          {
            title: <>
              {loadingPaymentMethods ? <Skeleton theme={theme} width={'100%'} height={17} /> : bankTransferTabTitle}
            </>,
            content: <BankTransfer bankTransfer={cards?.bank_transfer} setPaymentFrequency={setPaymentFrequency} />,
            disabled: disabledTab === PaymentMethodType.BANK_TRANSFER
          }
        ]}
        defaultTabIndex={defaultTabIndex}
        value={activeTab}
        onChange={onTabChange}
      />
    </>
  )
}
