import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { http } from "../utilities";
import { AppError } from "single-spa";
import {
  CONFIG_BASE_URL,
  DMS_BASE_URL,
  EP_DMS_GET_STATES,
  DEALERS,
  CONFIG_DEALER_PROFILE,
  GET_SETUP_DATA,
  GET_DISCLAIMER_BY_NAME,
  THEME,
  DISCLAIMER_AGREEMENT,
} from "../constants/routes";
import { State } from "@src/types";
import { AxiosResponse } from "axios";

export const useGetByDealerId = (): any => {
  const { data, isSuccess, mutate, error } = useMutation<any, AppError>({
    mutationFn: async (body: any) => {
      return await http(CONFIG_BASE_URL)
        .get<{ dealer_code: string }>(`${DEALERS}/${body.dealerId}`, {
          headers: { Company_id: body.company_id.toString() },
        })
        .then(
          async (
            res: AxiosResponse<{
              dealer_code: string;
              contact_person_email: string;
            }>
          ) => {
            const response = await http(CONFIG_BASE_URL).get(
              `${CONFIG_DEALER_PROFILE}/${res.data.dealer_code}`
            );
            const dealer_response = {
              ...res.data,
              dealer_email: res.data.contact_person_email,
            };
            return { ...dealer_response, ...response.data };
          }
        );
    },
  });
  return { data, isSuccess, mutate, error: error?.message };
};

export const useGetTheme = (): {
  mutate: UseMutateFunction<any, AppError, { email: string }>;
  error?: string;
} => {
  const { mutate, error } = useMutation<any, AppError, { email: string }>({
    mutationFn: async ({ email }) => {
      const response = await http(CONFIG_BASE_URL).get(
        `${THEME}?user_email=${email}`
      );
      return response.data;
    },
  });
  return { mutate, error: error?.message };
};

export const useGetStates = (): any => {
  const { mutate, error } = useMutation<State[], AppError>({
    mutationFn: async () => {
      const response = await http(CONFIG_BASE_URL).get(EP_DMS_GET_STATES);
      return response.data;
    },
  });
  return { mutate, error: error?.message };
};

export const useGetSetupData = (): any => {
  const { mutate, error } = useMutation<State[], AppError>({
    mutationFn: async (tenantId) => {
      const response = await http(DMS_BASE_URL).get(
        `${GET_SETUP_DATA}?company_id=${tenantId}&setup_data_indicators=credit-ratings&setup_data_indicators=contract-terms&setup_data_indicators=contract-allowed-annum-mileage`
      );
      return response.data;
    },
  });
  return { mutate, error: error?.message };
};

export const useAddCustomerConsent = (): {
  mutate: UseMutateFunction<any, AppError, any>;
  error: string | undefined;
} => {
  const { mutate, error } = useMutation<any, AppError, any>({
    mutationFn: async (body) => {
      const response = await http(DMS_BASE_URL).post(
        DISCLAIMER_AGREEMENT,
        body
      );
      return response.data;
    },
  });
  return { mutate, error: error?.message };
};

export const useGetDisclaimerByName = () => {
  const { isPending, mutate, error } = useMutation<
    State[],
    AppError,
    { disclaimerName: string }
  >({
    mutationFn: async ({ disclaimerName }) => {
      return http(DMS_BASE_URL)
        .get(`${GET_DISCLAIMER_BY_NAME}/${disclaimerName}`)
        .then((res) => res.data);
    },
  });

  return { isPending, mutate, error: error?.message };
};
