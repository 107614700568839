import { Button, CardSelect, Checkbox } from "@src/components/atoms";
import "./finance-offer-provider.style.scss";
import { useGetDisclaimerByName, useAddCustomerConsent } from "@src/hooks";
import BMWFSLogo from "@src/shared/assets/img/logo.jpg";
import GroupImage from "../../../../../../shared/assets/img/Group.svg";
import { useAppContext } from "@src/context/app-context";
import { useEffect, useState, version } from "react";
import { updateDisclaimers } from "@src/context/app-actions";
import { FinanceType } from "@src/constants";

export const FinanceOfferProvider = ({
  stepFiveDataReceivedEvent,
}: {
  stepFiveDataReceivedEvent: () => void;
}) => {
  const [handleSelect, setHandleSelect] = useState(false);
  const { isPending: loaddisclaimer, mutate: ChangeDisclaimer } =
    useGetDisclaimerByName();

  const {
    state: {
      user: {
        customer: { reference_id },
      },
      dealer: { dealer_name, disclaimer },
      order: { reference_number, finance_type },
      lender: { defaultLender },
    },
    dispatch: appDispatch,
  } = useAppContext();
  const result = Array.isArray(disclaimer)
    ? disclaimer.find((disclaimer) => disclaimer.name === "lender-submission")
    : null;

  const { mutate: consent_add } = useAddCustomerConsent();
  const dealdisclaimer = () => {
    consent_add({
      customer_reference_id: reference_id,
      disclaimer_name: result?.name,
      disclaimer_version: result?.version,
      order_reference_id: reference_number,
    });
    ChangeDisclaimer(
      { disclaimerName: "credit-application-disclaimer" },
      {
        onSuccess: async (response: any) => {
          if (response[0]) {
            const changesDisclaimer = response[0].description.replace(
              /@dealer/,
              `${dealer_name}`
            );
            await appDispatch(
              updateDisclaimers([
                ...disclaimer,
                {
                  name: response[0].name,
                  version: response[0].version,
                  description: changesDisclaimer,
                },
              ])
            );
          }
          stepFiveDataReceivedEvent();
        },
      }
    );
  };
  return (
    <div className="select-finance-offer-provider">
      <div className="select-finance-header">
        <div className="checkout-form-header">
          <h3 className="checkout-title checkout-step text--subsection-2">
            05
          </h3>
          <h3 className="checkout-title text--subsection-2">
            Select finance offer provider(s).
          </h3>
        </div>
      </div>
      <div className="select-finance-body">
        <div className="description-container">
          <span className="text--body-2">
            Your credit application will be processed by BMW Financial Services,
            for whom this platform is optimized. You may also request quotes
            from other finance offer providers through BMW of Manhattan.
          </span>
          <span className="text--body-2 your-finance">
            Your finance offer provider.
          </span>
        </div>
        <div className="card-container">
          {[defaultLender].map((option, index) => (
            <CardSelect
              key={index}
              logo={BMWFSLogo}
              text={option.name}
              defaultCard={option.default_lender}
            />
          ))}
          {finance_type == FinanceType.FINANCE && (
            <CardSelect
              logo={GroupImage}
              text={"Preferred Lenders for BMW of Manhattan"}
              hasCheckbox={
                <Checkbox
                  onChange={(val) => setHandleSelect(val)}
                  label={handleSelect ? "Selected" : "Select"}
                  isCompact
                />
              }
            />
          )}
        </div>
        {result?.description && (
          <div
            dangerouslySetInnerHTML={{ __html: result?.description ?? <></> }}
          />
        )}
        <Button
          type="primary"
          htmlType={"button"}
          text="Agree &amp; continue"
          isLoading={loaddisclaimer}
          onClick={dealdisclaimer}
          fixed
          fullWidth
        />
      </div>
    </div>
  );
};
