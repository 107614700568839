import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const StickyNotesListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.sticky-notes-list-wrap": {
    display: 'flex',
    flexDirection: 'column',
    gap: 8
  },
}));
