import { TextBox } from '@components'
import { useTheme } from '@mui/material'
import { DatePicker, Input, Select } from '@ntpkunity/controls'
import { FC } from 'react'
import { Controller } from 'react-hook-form'

const AddNewPayment: FC<{
  paymentForm?: any
  defaultCurrency?: string
  isSubmitting?: boolean
  disableAmount?: boolean
}> = ({ paymentForm, defaultCurrency, isSubmitting, disableAmount = false }) => {
  const theme = useTheme()
  const {
    control,
    watch,
    formState: { errors }
  } = paymentForm
  return (
    <>
      <Controller
        name="amount"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Amount Paid is required'
          }
        }}
        render={({ field }) => (
          <TextBox
            label={'Amount Paid'}
            type="text"
            numeric={true}
            masking={true}
            disabled={disableAmount}
            startAdornment={defaultCurrency}
            scale={2}
            {...field}
            error={errors?.amount?.message}
          />
        )}
      />
      <Controller
        name="payment_method_type"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Payment Mode is required'
          }
        }}
        render={({ field }) => (
          <Select
            label="Payment Mode"
            theme={theme}
            disablePortal={false}
            items={[
              { text: 'Credit Card', value: 'card' },
              { text: 'ACH', value: 'ach_debit' },
              { text: 'Cheque', value: 'cheque' }
            ]}
            {...field}
            value={field.value}
            sxProps={''}
            onChange={(e) => {
              field.onChange(e)
            }}
            selectError={errors?.payment_method_type?.message}
            disabled={isSubmitting}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          />
        )}
      />
      {watch('payment_method_type') == 'cheque' && (
        <Controller
          name="cheque_number"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Cheque Number is required'
            }
          }}
          render={({ field }) => (
            <Input
              label="Cheque Number"
              theme={theme}
              fullWidth
              {...field}
              placeholder={' Add here..'}
              type="text"
              error={errors?.cheque_number?.message}
              disabled={isSubmitting}
            />
          )}
        />
      )}

      <Controller
        name="payment_date"
        control={control}
        defaultValue=""
        rules={{
          required: {
            value: true,
            message: 'Payment Date is required'
          }
        }}
        render={({ field: { value, onChange } }) => (
          <DatePicker
            label="Payment Date"
            disableFuture
            disablePortal={false}
            theme={theme}
            value={value ?? ''}
            onChange={(e: any) => {
              if (
                e instanceof Date &&
                e.getFullYear() === 1970 &&
                e.getMonth() === 0 &&
                e.getDate() === 1
              ) {
                onChange(null)
              } else {
                onChange(e.toISOString())
              }
            }}
            error={errors.payment_date?.message}
            inputReadOnly
            disabled={isSubmitting}
          />
        )}
      />
    </>
  )
}
export default AddNewPayment
