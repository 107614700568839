import { FC, useState } from 'react'
import { useTheme } from '@mui/material'
import { Dialog, Button, Box, Icon, Typography, IBoxProps } from '@ntpkunity/controls'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { useSearchParams, useNavigate } from 'react-router-dom'
import AddNewPayment from '@app/order-management/order-summary/payment-details-popup'
import { createOrderRequestPayloadFromState } from '@app/desking/utils'
import {
  useConvertQuotationToApplication,
  useUpdateQuotationById
} from '@apis/order-management.service'
import { useStoreContext } from '@store/storeContext'
import { useUpdateOrderStatus } from '@apis/dealer-workqueue.service'
import { DeskingActionTypes, useDeskingContext } from '@app/desking/desking-context-provider'
import { Status } from '@helpers/enums'
import { useGetReservation } from '@apis/configurations.service'
import { useSavePaymentDetail } from '@apis/payment.service'
import { APP_ROUTES } from '@helpers/links'

export const RervationDialogWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({}) => ({
  '&.reservation-dialog-wrap': {
    '.u-dialog': {
      '.u-dialog-action': {
        display: 'block',
        '.btn': {
          '&.btn-secondary': {
            marginLeft: 0,
            marginTop: '16px' + '!important'
          }
        }
      }
    }
  }
}))

interface ReservationDialogProps {
  onClose: () => void
}
interface PaymentData {
  amount: string
  payment_method_type: string
  cheque_number?: string
  payment_date: string
  reference_number: string
  email: string
  token: string
}

const ReservationDialog: FC<ReservationDialogProps> = ({ onClose }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [showPayment, setShowPayment] = useState<boolean>(false)
  const [dialogOpen, setDialogOpen] = useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const orderReferenceId = searchParams.get('reference_id')
  const { state, dispatch } = useDeskingContext()
  const {
    states: { dealerInfo }
  } = useStoreContext()
  const { states } = useStoreContext()
  const { lender_id, submission_tier, order } = state
  const { mutateAsync: updateOrderRequest } = useUpdateQuotationById()
  const { mutate: convertQuotationRequest } = useConvertQuotationToApplication()
  const { mutate: updateOrderStatus } = useUpdateOrderStatus()
  const { vin } = state?.selection
  const vehicleDealerCode = state?.vehiclesData?.get(vin)?.vehicle?.dealer_code
  const dealerCode = states?.dealerInfo?.dealer_code
  const { data: GetReservation } = useGetReservation(dealerCode)
  const { mutate: createPaymentDetails } = useSavePaymentDetail()

  const calculateInitialAmount = () => {
    let amount = 0
    if (GetReservation?.reservation?.reservation_type === 'Percentage of MSRP') {
      amount =
        (state?.order?.order_asset?.msrp * GetReservation?.reservation?.reservation_amount) / 100
    } else {
      amount = GetReservation?.reservation?.reservation_amount || 0
    }
    return amount.toFixed(2)
  }

  const paymentForm = useForm({
    defaultValues: {
      amount: calculateInitialAmount(),
      payment_method_type: '',
      cheque_number: '',
      payment_date: ''
    }
  })

  const updateOrderStatusAndHandle = (isDealerCodeMatch: boolean) => {
    if (isDealerCodeMatch) {
      setIsSubmitting(false)
      setShowPayment(false)
      onClose()
    } else {
      const payload = {
        order_id: order?.reference_number,
        reqBody: {
          status: Status.InReservation,
          user_id: 0
        }
      }
      updateOrderStatus(payload, {
        onSuccess() {
          setIsSubmitting(false)
          onClose()
          navigate(APP_ROUTES.WORKQUEUE)
        },
        onError() {
          setIsSubmitting(false)
        }
      })
    }
  }

  const handleSubmit = paymentForm.handleSubmit(async (data) => {
    setIsSubmitting(true)
    const isDealerCodeMatch = vehicleDealerCode === dealerCode

    const paymentData: PaymentData = {
      amount: parseFloat(data.amount).toFixed(2),
      payment_method_type: data.payment_method_type,
      payment_date: data.payment_date,
      reference_number: order?.reference_number,
      email: order?.customer_info?.email,
      token: 'abc'
    }

    if (data.payment_method_type === 'cheque') {
      paymentData.cheque_number = data.cheque_number
    }

    createPaymentDetails(paymentData, {
      onSuccess(_response: any) {
        setDialogOpen(false)
        dispatch({
          type: DeskingActionTypes.SKIP_RESERVATION,
          payload: true
        })
        dispatch({
          type: DeskingActionTypes.SAVE_ORDER,
          payload: false
        })
        dispatch({
          type: DeskingActionTypes.UPDATE_PROCEEDED_WITHOUT_PAYMENT,
          payload: false
        })
        updateOrderStatusAndHandle(isDealerCodeMatch)
      },
      onError() {
        setIsSubmitting(false)
      }
    })
  })

  const handleProceedWithoutCharges = async () => {
    setIsSubmitting(true)
    const isDealerCodeMatch = vehicleDealerCode === dealerCode

    if (isDealerCodeMatch) {
      dispatch({
        type: DeskingActionTypes.UPDATE_PROCEEDED_WITHOUT_PAYMENT,
        payload: true
      })
      dispatch({
        type: DeskingActionTypes.SKIP_RESERVATION,
        payload: true
      })
      setIsSubmitting(false)
      onClose()
      return
    }

    const updateStatusAndNavigate = () => {
      updateOrderStatusAndHandle(isDealerCodeMatch)
    }

    if (orderReferenceId && state.isVehicleSelectedAndOrderSaved && !submission_tier) {
      const updatedOrderPayload = {
        ...order,
        changeVehicleStatus: false
      }
      convertQuotationRequest(updatedOrderPayload, {
        onSuccess(res: any) {
          if (isDealerCodeMatch) {
            dispatch({
              type: DeskingActionTypes.UPDATE_STAGE,
              payload: res.order_stage
            })
          }
          updateStatusAndNavigate()
        },
        onError() {
          setIsSubmitting(false)
        }
      })
    } else {
      try {
        const orderReqObj = createOrderRequestPayloadFromState(state, dealerInfo, lender_id)
        if (orderReferenceId) {
          await updateOrderRequest(orderReqObj, {
            onSuccess(_response: any) {
              if (submission_tier) {
                updateStatusAndNavigate()
                return
              }
              if (isDealerCodeMatch) {
                dispatch({
                  type: DeskingActionTypes.VEHICLE_SELECTED_AND_QUOTATION_SAVED,
                  payload: true
                })
              }
              updateStatusAndNavigate()
            }
          })
        }
      } catch {
        setIsSubmitting(false)
      }
    }
  }

  const handleGetReservationAmount = () => {
    setShowPayment(true)
    setDialogOpen(false)
  }

  return (
    <>
      <RervationDialogWrap theme={theme} className="reservation-dialog-wrap">
        {dialogOpen && (
          <Dialog
            size="xs"
            open={true}
            onCloseDialog={onClose}
            theme={theme}
            variant="confirmation"
            disablePortal
            customFooter={
              <>
                <Button
                  type="button"
                  primary
                  fullWidth
                  theme={theme}
                  text="Get Reservation Amount"
                  onClick={handleGetReservationAmount}
                  disabled={isSubmitting}
                />
                <Button
                  secondary
                  text={isSubmitting ? 'Submitting...' : 'Proceed without charges'}
                  theme={theme}
                  onClick={handleProceedWithoutCharges}
                  disabled={isSubmitting}
                />
              </>
            }
          >
            <Box theme={theme} className="dialog-icon">
              <Icon className="alert-icon" name="DialogAlertIcon" />
            </Box>
            <Box theme={theme}>
              <Typography
                theme={theme}
                mb={2}
                className="content-title"
                variant="h2"
                component="h2"
              >
                Reservation amount required?
              </Typography>
              <Typography theme={theme} className="text-muted" variant="body2" component="span">
                You can either charge the reservation amount or proceed with this deal without it.
              </Typography>
            </Box>
          </Dialog>
        )}
      </RervationDialogWrap>

      {showPayment && (
        <Dialog
          size="sm"
          open={true}
          onCloseDialog={() => {
            setShowPayment(false)
            setDialogOpen(true)
          }}
          theme={theme}
          disablePortal={false}
          title="Add Reservation Payment"
          customFooter={
            <Button
              type="submit"
              primary
              fullWidth
              theme={theme}
              text={isSubmitting ? 'Submitting...' : 'Submit Payment'}
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          }
        >
          <Box theme={theme}>
            <AddNewPayment
              paymentForm={paymentForm}
              defaultCurrency="$"
              isSubmitting={isSubmitting}
              disableAmount={true}
            />
          </Box>
        </Dialog>
      )}
    </>
  )
}

export default ReservationDialog
