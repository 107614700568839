import {
  useGetCustomerByEmail,
  useGetVehicleByVin,
  useGetCurrencies,
  useGetDealerProfileByDealerCode,
  useGetStates,
  useGetOrderInformation,
  useWorkFlowNextStep,
  useGetSetupData,
  useGetAllPrograms,
  useGetDealerFeebyState
} from "hooks";
import { useParams} from "react-router-dom";
import { useEffect, useReducer, useState } from 'react'
import { loadDefaultCurrencyConfigurations, useAppContext } from '@app/context-provider'
import { useOrderContext} from 'pages'
import {addCustomerReferenceId, getOrder, updateDealerFee, updateOrderAsset} from './order-context-provider'
import { PreOrderJourneyLayoutComponent } from "../../../layouts/journey-layout/pre-order-journey-layout.component";
import { PostOrderJourneyLayoutComponent } from "../../../layouts/journey-layout/post-order-journey-layout.component";
import {Box, CircleLoader, Snackbar} from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import {Status, DocumentPackageTypes, ContractingTypes} from "../../../constants/enums";
import { JourneyLayoutContextProvider } from "../../../layouts/journey-layout/journey-layout-context-provider";
import {areArraysEqual} from "../../../helpers/methods";

import { useWorkflowContext } from "@app/router-workflow";
import {Tabs, QueryKeys} from '@constants'

import { SnackbarUtility } from "@utilities";
import {useCreateOrderContractDocument} from "../../../hooks/order-management";
import {useQueryClient} from "react-query";
import {useGetDealerByDealerId} from "../../../hooks/dealer-management";
import {IStates} from "@interfaces";

export const OrderPage = () => {
  const theme = useTheme()
  const { vin } = useParams()
  const { orderId } = useParams()
  const { state: appState, dispatch: appDispatch } = useAppContext()
  const { state: {order, activeTab}, dispatch: orderDispatch ,handleUpdateOrder} = useOrderContext()
  const [showPopup, setShowPopup] = useState(false);
  const [orderTabs, setOrderTabs] = useState([]);
  const [nextTab, setNextTab] = useState('');
  const dealer = JSON.parse(localStorage.getItem("dealer_information"))
      ?.dealer;
  const { data: vehicle } = useGetVehicleByVin(vin, appState?.slug, appState?.tenant, dealer?.dealer_code)
  const { getOrderWorkflow } = useWorkflowContext()
  const { data: orderData, isLoading: orderLoading } = useGetOrderInformation(orderId)
  const { data: currencyData } = useGetCurrencies(appState?.slug)
  const { data: customerData } = useGetCustomerByEmail(JSON.parse(localStorage.getItem("settings"))?.email);
  const { mutate: creatOrderContractDocument } = useCreateOrderContractDocument()
  const queryClient = useQueryClient();

  const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)
  const PLACEHOLDERS = appState.language.placeholders
  const workflow: any = JSON.parse(localStorage.getItem('workflow'))
   const { mutate: dealerFee } = useGetDealerFeebyState(appState?.slug)

   const states: IStates[] = queryClient.getQueryData(
	QueryKeys.GET_ALL_STATES
  	);

  useEffect(() => {
    if (dealer?.dealer_address?.state_id && states) {
      const stateCode = states.find((x) => x?.id === dealer?.dealer_address?.state_id)?.code;
      if (stateCode && dealer?.dealer_code) {
        dealerFee({ stateCode, dealerCode: dealer?.dealer_code }, {
          onSuccess(data) {
            if (data && Array.isArray(data)) {
              orderDispatch(updateDealerFee(data));
            } else {
              console.error("Received invalid data from API:", data);
            }
          },
          onError(error) {
            console.error("API call failed:", error);
          }
        });
      }
    }
  }, [dealer?.dealer_address?.state_id, states])

  useEffect(() => {
    if(!order?.order_asset?.unit_price){
      orderDispatch(updateOrderAsset({
        ...vehicle,
        unit_price: vehicle?.internet_price
      }));
    }
  }, [vehicle]);

  const {data: dealerData} = useGetDealerByDealerId(
      {
        dealerId: order?.dealer_id,
        headers: { company_id: appState?.tenant}
        }
  )
  const useDealerCode = order?.dealer_id ? dealerData?.dealer_code : vehicle?.dealer_code
  const {data: dealerProfile} = useGetDealerProfileByDealerCode(useDealerCode, appState?.slug);

  useEffect(() => {
    if(dealerProfile && !dealerProfile?.detail) {
      localStorage.setItem("dealer_information", JSON.stringify({dealer: dealerProfile}));
    }
  }, [dealerProfile]);

  useGetStates(appState?.slug)

  useEffect(() => {
    if (orderData) {
      if (orderData?.status == Status.Withdrawn)
        getOrderWorkflow()
      orderDispatch(getOrder(orderData))
    }
  }, [orderData])

  useEffect(() => {
    if (customerData) {
      orderDispatch(addCustomerReferenceId(customerData?.reference_id))
    }
  }, [customerData])

  useEffect(() => {
    if (Array.isArray(currencyData)) {
      const defaultCurrency = currencyData?.find(
        (currency: any) => currency.id === appState.default_currency
      );
      appDispatch(loadDefaultCurrencyConfigurations(defaultCurrency));
    }
  }, [currencyData]);




  const handleLinkClick = () => {
    setShowPopup(true);
  };

  const handleCloseDialog = () => {
    setShowPopup(false);
  };

const onNextTabClick = () => {
  const currentTabIndex = orderTabs?.findIndex((item) => item === activeTab);
  const nextTab = currentTabIndex >= 0 && currentTabIndex + 1 < orderTabs.length ? orderTabs[currentTabIndex + 1] : '';

  if (!nextTab) return;

  if (activeTab === Tabs.INSURANCE) {
    const { insurance, reference_number, reference_id } = order || {};
    const hasContractDocuments = order?.documents?.some(
    (item) => item.type === DocumentPackageTypes.CONTRACT && item?.documents?.length > 0
  );

    if (insurance && !hasContractDocuments && appState?.default_lender?.contracting_type != ContractingTypes.NOT_REQUIRED) {
      snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Generating contract documents'));

      creatOrderContractDocument(
        {
          reference_number,
          document_event: "Insurance Received"
        },
        {
          onSuccess: () => {
            snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR());
            setNextTab(nextTab);
            queryClient.invalidateQueries([QueryKeys.GET_ORDER_INFORMATION, reference_number]);
            queryClient.invalidateQueries([QueryKeys.GET_ALL_DOCUMENT_PACKAGES, reference_id]);

          },
          onError: () => {
            snackbardispatch(SnackbarUtility.OPEN_SNACKBAR('Unable to generate contract documents'));
            setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); }, 3000);

          }
        }
      );
      return;
    }
  }
  if (activeTab === Tabs.TRADE_IN && nextTab === Tabs.FINANCING && order.is_trade_in === null) {
    snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.TRADE_IN_CHECKED));

  }else{
    setNextTab(nextTab);
  }
};


  useEffect(() => {
    if (workflow?.meta_data?.tabs && !areArraysEqual(workflow?.meta_data?.tabs, orderTabs)) {
        setOrderTabs(workflow?.meta_data?.tabs)
      }
  },[] )


    useEffect(() => {
    if (workflow?.meta_data?.tabs  && Object.keys(appState?.default_lender ?? {}).length > 0) {
        const {status} = order

      if(appState?.default_lender?.contracting_type == ContractingTypes.NOT_REQUIRED && status && ['Approved', 'Appointment Scheduled', 'Awaiting Scheduling'].includes(status)){
        setOrderTabs(workflow?.meta_data?.tabs.filter(tab => ![ "Insurance", "Contracting", "Stripe Payment"].includes(tab)))
      }
      }
  },[appState?.default_lender, order?.status] )

  const commonProps = {
    vehicle,
    showPopup,
    orderTabs,
    nextTab,
    handleLinkClick,
    handleCloseDialog,
    onNextTabClick,
    setNextTab
  };


  return (
    <JourneyLayoutContextProvider>
      {orderLoading ? <CircleLoader theme={theme} size='lg' /> :
        [Status.Approved, Status.Appointment, Status.SchedulePickup, Status.Cancelled].includes(orderData?.status) ?
          <PostOrderJourneyLayoutComponent
            {...commonProps}
            children
          />
          :
          <PreOrderJourneyLayoutComponent
            {...commonProps}
            children
          />
      }
    <Snackbar
        theme={theme}
        message={[PLACEHOLDERS.ORDER_SAVING_IN_PROGRESS, PLACEHOLDERS.ORDER_VALIDATION_IN_PROGRESS, "Generating contract documents"].includes(snackbarState.message) ?
        <Box theme={theme} display={'flex'}>
            <Box theme={theme} width="30px" position="relative">
              <CircleLoader theme={theme} size='xs' />
            </Box> {snackbarState.message}
          </Box> : snackbarState.message}
        open={snackbarState.open}
        onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      />

    </JourneyLayoutContextProvider>
  )
}
