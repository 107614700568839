import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const WidgetWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.widget-wrap': {
    height: '100%',
    fontFamily: theme.typography.fontFamily,
    '.stack-wrap': {
      height: '100%'
    },
    '.in-progress-stack': {
      height: '100%'
    },
    '.widget-in-progress': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 24,
      alignItems: 'center',
      height: '100%'
    },
    '.widget-content': {
      '.graph-ph': {
        img: {
          maxHeight: 405,
          maxWidth: '100%'
        }
      }
    },
    '.graph-value-box-combine': {
      backgroundColor: '#F2F2F2',
      padding: '16px 24px',
      borderRadius: theme.shape.borderRadius
    },
    '.action-area': {
      '.u-form-group': {
        '.u-form-control': {
          '&.u-select': {
            height: 32,
            color: theme.palette.grey[600],
            backgroundColor: '#F5F5F7',
            '.MuiInputBase-input': {
              padding: '0px 35px 0px 8px'
            },
            '.MuiSelect-icon': {
              right: 8
            },
            fieldset: {
              border: 'none'
            }
          }
        }
      },
      '.button-group-wrap': {
        '.btn': {
          '&.btn-default': {
            [theme.breakpoints.down('md')]: {
              padding: 8
            }
          }
        }
      }
    },
    '.recharts-layer, .recharts-surface, .recharts-sector': {
      '&:focus': {
        outline: 'none'
      }
    }
  }
}))
