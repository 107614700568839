import { useTheme } from '@mui/material'
import { AnalyticsContentWrap } from './business-kpi-analytics.style'
import { Box, Grid, Select, Typography } from '@ntpkunity/controls'
import { Widget } from '../analytics-widget'
import { ButtonGroup, Stack } from '@components'
import KPIGraph01 from '../../../public/assets/images/kpi-graph-01.png'
import KPIGraph02 from '../../../public/assets/images/kpi-graph-02.png'
import KPIGraph03 from '../../../public/assets/images/kpi-graph-03.png'
import KPIGraph04 from '../../../public/assets/images/kpi-graph-04.png'
import KPIGraph05 from '../../../public/assets/images/kpi-graph-05.png'
import KPIGraph06 from '../../../public/assets/images/kpi-graph-06.png'
import KPIGraph07 from '../../../public/assets/images/kpi-graph-07.png'
import KPIGraph08 from '../../../public/assets/images/kpi-graph-08.png'
import KPIGraph09 from '../../../public/assets/images/kpi-graph-09.png'
import KPIGraph10 from '../../../public/assets/images/kpi-graph-10.png'
import KPIGraph11 from '../../../public/assets/images/kpi-graph-11.png'
import KPIGraph12 from '../../../public/assets/images/kpi-graph-12.png'
import KPIGraph13 from '../../../public/assets/images/kpi-graph-13.png'
import KPIGraph14 from '../../../public/assets/images/kpi-graph-14.png'
import { ValueBox } from '../value-box'
import { LeadsTable, TopSelling } from 'components/lane-dashboard'

import CarImage01 from '../../../public/assets/cars-images/CarImage01.png'
import CarImage02 from '../../../public/assets/cars-images/CarImage02.png'
import CarImage03 from '../../../public/assets/cars-images/CarImage03.png'
import CarImage04 from '../../../public/assets/cars-images/CarImage04.png'
import CarImage05 from '../../../public/assets/cars-images/CarImage05.png'
import CarImage06 from '../../../public/assets/cars-images/CarImage06.png'
import CarImage07 from '../../../public/assets/cars-images/CarImage07.png'
import CarImage08 from '../../../public/assets/cars-images/CarImage08.png'

export const BusinessKpiAnalytics = () => {
  const theme = useTheme()
  return (
    <AnalyticsContentWrap theme={theme} className="analytics-content-wrap">
      <Grid theme={theme} container item spacing={3} alignItems={'stretch'}>
        <Grid theme={theme} item xs={12} md={8}>
          <Widget
            title="Total Revenue"
            actionArea={
              <>
                <ButtonGroup
                  items={[
                    {
                      title: 'Daily',
                      onClick: () => {},
                      selected: false
                    },
                    {
                      title: 'Weekly',
                      onClick: () => {},
                      selected: true
                    },
                    {
                      title: 'Monthly',
                      onClick: () => {},
                      selected: false
                    },
                    {
                      title: 'Yearly',
                      onClick: () => {},
                      selected: false
                    }
                  ]}
                />
              </>
            }
          >
            <Box theme={theme} className="graph-ph" textAlign={'center'} mt={3}>
              <img src={KPIGraph01} alt="" />
            </Box>
            <Box theme={theme} mt={2}>
              <Grid theme={theme} container item spacing={1.375} alignItems={'stretch'}>
                <Grid theme={theme} item xs={12} md={6}>
                  <ValueBox title={'Total Sales'} value={'$4,890,234'} progressText={'3%'} danger />
                </Grid>
                <Grid theme={theme} item xs={12} md={6}>
                  <ValueBox
                    title={'Dealer Commission'}
                    value={'$426,800'}
                    progressText={'23%'}
                    success
                  />
                </Grid>
              </Grid>
            </Box>
          </Widget>
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Widget
            title="Active Orders"
            actionArea={
              <>
                <Select
                  theme={theme}
                  disablePortal={false}
                  items={[
                    {
                      text: 'All',
                      value: '0'
                    },
                    {
                      text: 'Weekly',
                      value: '1'
                    },
                    {
                      text: 'Monthly',
                      value: '2'
                    }
                  ]}
                  value={0}
                />
              </>
            }
          >
            <Box theme={theme} className="graph-ph" textAlign={'center'} mt={3}>
              <img src={KPIGraph03} alt="" />
            </Box>
          </Widget>
        </Grid>
        <Grid theme={theme} item xs={12} md={8}>
          <Widget
            title="Top F&I Products"
            actionArea={
              <>
                <ButtonGroup
                  items={[
                    {
                      title: 'Daily',
                      onClick: () => {},
                      selected: false
                    },
                    {
                      title: 'Weekly',
                      onClick: () => {},
                      selected: false
                    },
                    {
                      title: 'Monthly',
                      onClick: () => {},
                      selected: true
                    },
                    {
                      title: 'Yearly',
                      onClick: () => {},
                      selected: false
                    }
                  ]}
                />
              </>
            }
          >
            <Box theme={theme} className="graph-ph" textAlign={'center'} mt={3}>
              <img src={KPIGraph02} alt="" />
            </Box>
            <Box theme={theme} mt={2}>
              <Grid theme={theme} container item spacing={1.375} alignItems={'stretch'}>
                <Grid theme={theme} item xs={12} md={6}>
                  <ValueBox title={'F&I Revenue'} value={'$194,238'} progressText={'15%'} success />
                </Grid>
                <Grid theme={theme} item xs={12} md={6}>
                  <ValueBox
                    title={'F&I Product Penetration Rate (PPR)'}
                    value={'38%'}
                    progressText={'23%'}
                    success
                  />
                </Grid>
              </Grid>
            </Box>
          </Widget>
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Box theme={theme} height={'100%'} display={'flex'} flexDirection={'column'} gap={3}>
            <Box theme={theme} flexGrow={1}>
              <Widget title="Average Time to Buy">
                <Box theme={theme} className="graph-ph" textAlign={'center'} mt={3}>
                  <img src={KPIGraph04} alt="" style={{ maxHeight: 205 }} />
                </Box>
              </Widget>
            </Box>
            <Box theme={theme} flexGrow={1}>
              <Widget title="Customer Satisfaction Score">
                <Box theme={theme} display={'flex'} alignItems={'center'} gap={3} mt={3}>
                  <Box theme={theme} flexShrink={0}>
                    <ValueBox
                      value={'17%'}
                      progressText={'-36%'}
                      danger
                      noBg
                      subText={'vs Last Month'}
                    />
                  </Box>
                  <Box theme={theme} className="graph-ph">
                    <img src={KPIGraph05} alt="" />
                  </Box>
                </Box>
              </Widget>
            </Box>
          </Box>
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Box theme={theme} height={'100%'} display={'flex'} flexDirection={'column'} gap={3}>
            <Box theme={theme} flexGrow={1}>
              <Stack paddingMd={3} paddingXs={3}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component={'span'}
                  display={'block'}
                  className="text-muted"
                >
                  Total Number of Inquiries
                </Typography>
                <Typography theme={theme} variant="h3" component={'h3'} mt={0.5}>
                  5,983
                </Typography>
                <Box theme={theme} className="graph-ph" textAlign={'center'} mt={3}>
                  <img src={KPIGraph06} alt="" style={{ maxHeight: 47, maxWidth: '100%' }} />
                </Box>
              </Stack>
            </Box>
            <Box theme={theme} flexGrow={1}>
              <Stack paddingMd={3} paddingXs={3}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component={'span'}
                  display={'block'}
                  className="text-muted"
                >
                  Total Number of Vehicles
                </Typography>
                <Typography theme={theme} variant="h3" component={'h3'} mt={0.5}>
                  6,908
                </Typography>
                <Box theme={theme} className="graph-ph" textAlign={'center'} mt={3}>
                  <img src={KPIGraph07} alt="" style={{ maxHeight: 50, maxWidth: '100%' }} />
                </Box>
              </Stack>
            </Box>
          </Box>
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Widget
            title="Approved Orders"
            actionArea={
              <>
                <Select
                  theme={theme}
                  disablePortal={false}
                  items={[
                    {
                      text: 'All',
                      value: '0'
                    },
                    {
                      text: 'Weekly',
                      value: '1'
                    },
                    {
                      text: 'Monthly',
                      value: '2'
                    }
                  ]}
                  value={0}
                />
              </>
            }
          >
            <Box theme={theme} className="graph-ph" textAlign={'center'} mt={3}>
              <img src={KPIGraph14} alt="" style={{ maxHeight: 280 }} />
            </Box>
          </Widget>
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Widget title="Sales Volume">
            <Box theme={theme} className="graph-ph" textAlign={'center'} mt={3}>
              <img src={KPIGraph08} alt="" style={{ maxHeight: 280 }} />
            </Box>
          </Widget>
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Widget
            title="Top Selling Vehicles"
            actionArea={
              <>
                <Select
                  theme={theme}
                  disablePortal={false}
                  items={[
                    {
                      text: 'All',
                      value: '0'
                    },
                    {
                      text: 'Last Year',
                      value: '1'
                    }
                  ]}
                  value={1}
                />
              </>
            }
          >
            <Box theme={theme} mt={3}>
              <TopSelling
                items={[
                  {
                    productImage: CarImage01,
                    productModal: '2021 Audi A4 Sedan Premium',
                    productName: '40 TFSI quattro',
                    listBg: true
                  },
                  {
                    productImage: CarImage02,
                    productModal: '2021 Honda Passport',
                    productName: 'Touring FWD',
                    listBg: true
                  },
                  {
                    productImage: CarImage03,
                    productModal: '2021 Hyundai Veloster',
                    productName: 'N DCT',
                    listBg: true
                  },
                  {
                    productImage: CarImage04,
                    productModal: '2021 Mercedes-Benz',
                    productName: 'C-Class AMG C 43 4MATIC Cabriolet',
                    listBg: true
                  },
                  {
                    productImage: CarImage05,
                    productModal: '2021 MINI Hardtop 2 Door',
                    productName: 'John Cooper Works FWD',
                    listBg: true
                  },
                  {
                    productImage: CarImage06,
                    productModal: '2021 Nissan Altima',
                    productName: '2.5 SR AWD Sedan',
                    listBg: true
                  },
                  {
                    productImage: CarImage07,
                    productModal: '2021 Tesla Model 3 ',
                    productName: 'Long Range AWD',
                    listBg: true
                  },
                  {
                    productImage: CarImage08,
                    productModal: '2021 Toyota Avalon',
                    productName: 'XSE Nightshade FWD (Natl)',
                    listBg: true
                  }
                ]}
              />
            </Box>
          </Widget>
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Widget
            title="Delivery Stats"
            actionArea={
              <>
                <Select
                  theme={theme}
                  disablePortal={false}
                  items={[
                    {
                      text: 'Lease',
                      value: '0'
                    },
                    {
                      text: 'Finance',
                      value: '1'
                    }
                  ]}
                  value={0}
                />
              </>
            }
          >
            <Box theme={theme} className="graph-ph" textAlign={'center'} mt={3}>
              <img src={KPIGraph09} alt="" style={{ maxHeight: 310 }} />
            </Box>
          </Widget>
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Widget
            title="Trade-In"
            actionArea={
              <>
                <Select
                  theme={theme}
                  disablePortal={false}
                  items={[
                    {
                      text: 'Lease',
                      value: '0'
                    },
                    {
                      text: 'Finance',
                      value: '1'
                    }
                  ]}
                  value={1}
                />
              </>
            }
          >
            <Box theme={theme} className="graph-ph" textAlign={'center'} mt={3}>
              <img src={KPIGraph10} alt="" style={{ maxHeight: 310 }} />
            </Box>
          </Widget>
        </Grid>
        <Grid theme={theme} item xs={12} md={8}>
          <Widget
            title="Lead-to-close Conversion"
            actionArea={
              <>
                <ButtonGroup
                  items={[
                    {
                      title: 'Daily',
                      onClick: () => {},
                      selected: false
                    },
                    {
                      title: 'Weekly',
                      onClick: () => {},
                      selected: true
                    },
                    {
                      title: 'Monthly',
                      onClick: () => {},
                      selected: false
                    },
                    {
                      title: 'Yearly',
                      onClick: () => {},
                      selected: false
                    }
                  ]}
                />
              </>
            }
          >
            <Box theme={theme} className="graph-ph" textAlign={'center'} mt={3}>
              <img src={KPIGraph11} alt="" />
            </Box>
            <Box theme={theme} mt={2} className="graph-value-box-combine">
              <Grid theme={theme} container item spacing={3} alignItems={'stretch'}>
                <Grid theme={theme} item xs={12} md={6}>
                  <ValueBox
                    title={'Avg number of days to close'}
                    value={'5 days'}
                    progressText={'23%'}
                    danger
                    noBg
                  />
                </Grid>
                <Grid theme={theme} item xs={12} md={3}>
                  <ValueBox title={'Lowest'} value={'3 days'} progressText={'20%'} danger noBg />
                </Grid>
                <Grid theme={theme} item xs={12} md={3}>
                  <ValueBox title={'Highest'} value={'8 days'} progressText={'10%'} danger noBg />
                </Grid>
              </Grid>
            </Box>
          </Widget>
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Box theme={theme} height={'100%'} display={'flex'} flexDirection={'column'} gap={3}>
            <Box theme={theme} flexGrow={1}>
              <Stack paddingMd={3} paddingXs={3}>
                <Typography theme={theme} variant="h4" component={'h4'}>
                  Number of leads
                </Typography>
                <Typography theme={theme} variant="h3" component={'h3'} mt={0.5}>
                  12,00
                </Typography>
                <Box theme={theme} className="graph-ph" textAlign={'center'} mt={3}>
                  <img src={KPIGraph12} alt="" style={{ maxHeight: 47, maxWidth: '100%' }} />
                </Box>
              </Stack>
            </Box>
            <Box theme={theme} flexGrow={1}>
              <Widget title="Percentage of First Pencils Accepted">
                <Box theme={theme} display={'flex'} alignItems={'center'} gap={3} mt={3}>
                  <Box theme={theme} flexShrink={0}>
                    <ValueBox
                      value={'17%'}
                      progressText={'-36%'}
                      danger
                      noBg
                      subText={'vs Last Month'}
                    />
                  </Box>
                  <Box theme={theme} className="graph-ph">
                    <img src={KPIGraph05} alt="" />
                  </Box>
                </Box>
              </Widget>
            </Box>
          </Box>
        </Grid>
        <Grid theme={theme} item xs={12}>
          <Stack title="Leads" paddingMd={3} paddingXs={3}>
            <Box theme={theme} mt={3}>
              <LeadsTable />
            </Box>
          </Stack>
        </Grid>
        <Grid theme={theme} item xs={12} md={8}>
          <Widget
            title="Car Lot Heatmap"
            actionArea={
              <>
                <ButtonGroup
                  items={[
                    {
                      title: 'Daily',
                      onClick: () => {},
                      selected: false
                    },
                    {
                      title: 'Weekly',
                      onClick: () => {},
                      selected: false
                    },
                    {
                      title: 'Monthly',
                      onClick: () => {},
                      selected: true
                    },
                    {
                      title: 'Yearly',
                      onClick: () => {},
                      selected: false
                    }
                  ]}
                />
              </>
            }
          >
            <Box theme={theme} className="graph-ph" textAlign={'center'} mt={3}>
              <img src={KPIGraph13} alt="" />
            </Box>
          </Widget>
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Stack
            paddingMd={3}
            paddingXs={3}
            title="Insights & Recommendations"
            actionArea={
              <Typography
                theme={theme}
                variant="body2"
                component={'a'}
                onClick={() => {}}
                className="text-primary link cp"
              >
                View All Insights
              </Typography>
            }
          >
            <Box theme={theme} className="insights-list-wrap" mt={3}>
              <Typography
                theme={theme}
                variant="caption"
                component={'small'}
                display={'block'}
                className="text-uppercase fw-sbold"
              >
                INSIGHTS
              </Typography>
              <ul>
                <li>Page views for 2024 BMW X3 has spiked from August 20 to 26, 2023 by 150%.</li>
                <li>Average time to buy has improved by 18% as compared to last month.</li>
                <li>Penetration of F&I products have boosted by 23% in August 2023.</li>
              </ul>
              <Typography
                theme={theme}
                variant="caption"
                component={'small'}
                display={'block'}
                className="fw-sbold"
                mt={1}
              >
                Recommendations
              </Typography>
              <ul>
                <li>
                  Increase marketing efforts around the 2024 BMW X3 to capitalize on the 150% spike
                  in page views.
                </li>
                <li>
                  Focus on streamlining the buying process to further improve the average time to
                  buy.
                </li>
                <li>
                  Expand the promotion and cross-selling of F&I products to continue building on the
                  23% boost.
                </li>
              </ul>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </AnalyticsContentWrap>
  )
}
