import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const DataTableWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '.pt-0': {
    paddingTop: '0 !important'
  },
  '.pb-0': {
    paddingBottom: '0 !important'
  },
  '.MuiTablePagination-root': {
    marginTop: '-4px',
    borderRight: '1px solid',
    borderBottom: '1px solid',
    borderLeft: '1px solid',
    paddingTop: '4px !important',
    borderColor: theme.palette.grey[100],
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    color: theme.palette.grey[600],
    fontSize: theme.typography.htmlFontSize,
    fontFamily: theme.typography.fontFamily
  },
  '.MuiTablePagination-select:focus': {
    backgroundColor: 'transparent'
  },
  '.MuiTablePagination-selectIcon': {
    top: 'calc(35% - 0.5em)',
    left: 24
  },
  '.scroll': {
    width: '100%',
    position: 'relative',
    '.scroll-hide': {
      backgroundColor: theme.palette.common.white,
      borderBottom: '1px solid' + theme.palette.divider,
      position: 'absolute',
      width: 7,
      height: 100,
      right: 1,
      top: 1,
      display: 'block',
      borderRadius: '0 8px 0 0',

      '&.spr-border': {
        '&::after': {
          display: 'inline-block',
          content: `''`,
          backgroundColor: theme.palette.divider,
          width: 7,
          height: 1,
          position: 'absolute',
          top: '49.5%'
        },
        '&.chart-header': {
          fontWeight: theme.customVariables.fontWeightSemiBold
        }
      },

      '&.sh-fixed-cell': {
        height: 100.5,
        top: 1
      }
    }
  },

  '.u-table-wrap': {
    '.u-table-container': {
      maxHeight: 'calc(100vh - 212px)',
      '&::-webkit-scrollbar': {
        borderRadius: 0 + '!important'
      },
      '&::-webkit-scrollbar-track': {
        marginTop: 100,
        borderRadius: 0 + '!important',
        backgroundColor: '#474747'
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 0 + '!important'
      },
      '.u-table': {
        '.u-table-head, .u-table-body': {
          tr: {
            'td, th': {
              img: {
                width: '52px',
                height: '32px',
                objectFit: 'cover',
                borderRadius: '8px'
              },
              '&.fixed-cell': {
                '&.space-fixed': {
                  right: -1
                },
                '.styled-tooltip div[data-mui-internal-clone-element=true]': {
                  width: 'auto',
                  svg: {
                    top: 3,
                    position: 'relative'
                  }
                }
              },
              '.styled-tooltip': {
                div: {
                  '&[data-mui-internal-clone-element=true]': {
                    maxWidth: '200px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: '185px'
                  }
                },
                '.MuiTooltip-popper': {
                  '.MuiTooltip-tooltip': {
                    maxWidth: 'initial'
                  }
                }
              },
              '.MuiInputBase-root': {
                backgroundColor: theme.palette.common.white
              },
              '&.w-auto': {
                maxWidth: 'fit-content'
              },
              '&.fixed-left': {
                position: 'sticky',
                left: 0
              },
              '&.fixed-right': {
                position: 'sticky',
                right: 0
              },
              '.status': {
                width: 8,
                height: 8,
                borderRadius: 8,
                marginRight: 8,
                display: 'inline-block',

                '&.inquiry, &.red': {
                  backgroundColor: '#FF3333'
                },
                '&.available, &.green': {
                  backgroundColor: '#33CC59'
                },
                '&.in-contract, &.blue': {
                  backgroundColor: '#007BFF'
                }
              }
            },
            '&.filters-row': {
              '.table-filter': {
                '.u-form-group': {
                  minWidth: '80%',

                  '.MuiFormControl-root': {
                    '.MuiFormLabel-root': {
                      color: 'rgba(0,0,0,0.25)',
                      transform: 'none',
                      padding: 0,
                      backgroundColor: 'transparent',
                      margin: '6px 0 0 10px'
                    }
                  },

                  '.MuiInputBase-root.u-select': {
                    fieldset: {
                      legend: {
                        span: {
                          display: 'none'
                        }
                      }
                    },
                    '.MuiSelect-icon': {
                      backgroundColor: theme.palette.common.white
                    }
                  },
                  '.MuiInputLabel-shrink.MuiFormLabel-filled': {
                    display: 'none'
                  }
                }
              }
            },
            '&.disabled': {
              opacity: 0.5,
              cursor: 'not-allowed'
            }
          }
        }
      }
    }
  },

  '.u-table-head': {
    tr: {
      '.indent-cell': {
        width: '192px'
      },
      'td, th': {
        height: 50
      }
    }
  },
  '.u-table-body': {
    '.toggle-btn': {
      transform: 'rotate(-90deg)',
      webkitTransform: 'rotate(-90deg)',
      '&.ch-right': {
        transform: 'rotate(0deg)',
        webkitTransform: 'rotate(0deg)'
      }
    },
    tr: {
      td: {
        '&.second-level-cell': {
          paddingLeft: '45px !important'
        },
        '&.third-level-cell': {
          paddingLeft: '90px !important'
        },
        '.add-option': {
          width: '160px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          '.link': {
            color: theme.palette.primary,
            textDecoration: 'none',
            display: 'initial',
            alignItems: 'center',
            cursor: 'pointer',
            '&.add': {
              display: 'flex'
            },
            svg: {
              marginRight: '8px',
              path: {
                stroke: theme.palette.primary
              }
            },
            '&:hover, &:focus': {
              textDecoration: 'underline'
            }
          }
        },
        '.status-indicator': {
          width: 8,
          height: 8,
          borderRadius: '50%',
          display: 'inline-block',
          backgroundColor: theme.palette.primary.main,
          marginRight: 8,
          '&.ind-danger': {
            backgroundColor: theme.palette.error.main
          },
          '&.ind-success': {
            backgroundColor: theme.palette.success.main
          },
          '&.ind-contract': {
            backgroundColor: theme.palette.info.main
          }
        }
      }
    }
  },

  '.table': {
    '.add-option': {
      display: 'inline-block',
      'Button, .btn': {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.fontSize,
        padding: 0,
        '&:hover, &:focus': {
          backgroundColor: 'transparent',
          textDecoration: 'underline'
        },
        '.MuiTouchRipple-root': {
          display: 'none'
        },
        svg: {
          height: 20,
          width: 20,
          float: 'left',
          margin: '0 3px 0 0'
        }
      }
    }
  },

  '&.table-button': {
    '.u-table-wrap': {
      '.u-table-container': {
        maxHeight: 'calc(100vh - 293px)'
      }
    },
    '.btn-secondary': {
      marginTop: '24px'
    }
  },
  '&.table-pagination': {
    '.u-table-wrap': {
      '.u-table-container': {
        maxHeight: 'calc(100vh - 265px)',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }
    },
    '.MuiTablePagination-root': {
      marginTop: '-4px',
      borderRight: '1px solid',
      borderBottom: '1px solid',
      borderLeft: '1px solid',
      paddingTop: '4px',
      borderColor: theme.palette.grey[100],
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
      color: theme.palette.grey[600],
      fontSize: theme.typography.htmlFontSize,
      fontFamily: theme.typography.fontFamily
    },
    '.MuiTablePagination-select:focus': {
      backgroundColor: 'transparent'
    },
    '.MuiTablePagination-selectIcon': {
      top: 'calc(35% - 0.5em)',
      left: 24
    }
  },
  '&.table-pagination-button': {
    '.u-table-wrap': {
      '.u-table-container': {
        maxHeight: 'calc(100vh - 342px)'
      }
    },
    '.btn-secondary': {
      marginTop: '24px'
    }
  },

  '&.table-tabs': {
    '.u-table-wrap': {
      '.u-table-container': {
        maxHeight: 'calc(100vh - 293px)'
      }
    }
  },
  '&.table-tabs-button': {
    '.u-table-wrap': {
      '.u-table-container': {
        maxHeight: 'calc(100vh - 367px)'
      }
    },
    '.btn-secondary': {
      marginTop: '24px'
    }
  },
  '&.table-tabs-pagination': {
    '.u-table-wrap': {
      '.u-table-container': {
        maxHeight: 'calc(100vh - 350px)',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }
    }
  },
  '&.table-tabs-pagination-button': {
    '.u-table-wrap': {
      '.u-table-container': {
        maxHeight: 'calc(100vh - 420px)',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }
    },
    '.btn-secondary': {
      marginTop: '24px'
    }
  },
  '&.table-dialog': {
    '.u-table-wrap': {
      '.u-table-container': {
        maxHeight: 'calc(100vh - 392px)',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      },
      '.u-table': {
        '.editable-cell': {
          '.btn': {
            marginRight: -32,
            '&.with-icon': {
              '&.btn-default': {
                '&:disabled': {
                  opacity: 0
                }
              }
            },
            '&.btn-tick': {
              marginRight: 0
            },
            '&.btn-close': {
              marginRight: -32
            }
          },
          '.cell-content .u-form-group .u-form-control': {
            height: 32
          }
        }
      }
    }
  },
  '&.min-h .u-table-container': {
    maxHeight: '400px !important'
  },
  '&.simple-table': {
    '.u-table-wrap .u-table .u-table-body tr:nth-of-type(odd) td, .u-table-wrap .u-table .u-table-body tr.child-tr:hover td ':
      {
        background: 'transparent'
      },
    '.u-table-wrap .u-table .u-table-body tr td.action-cell.fixed-cell': {
      background: theme.palette.common.white
    },
    '.u-table-wrap .u-table .u-table-body tr td, .u-table-wrap .u-table .u-table-head tr td': {
      padding: 16,
      minWidth: 'auto'
    },
    '.u-table-wrap .u-table .u-table-head tr td:first-child, .u-table-wrap .u-table .u-table-body tr td:first-child':
      {
        paddingLeft: 0
      },
    '.u-table-wrap .u-table-container': {
      border: 0
    },
    '.center': {
      display: 'flex',
      alignItems: 'center',
      svg: {
        marginRight: 8,
        width: 24,
        height: 24,
        '&.grey path': {
          stroke: theme.palette.grey[400],
          strokeWidth: 3
        },
        '&.warning path': {
          stroke: theme.palette.warning.main
        },
        '&.error': {
          path: {
            stroke: theme.palette.error.main,
            strokeWidth: 12
          },
          circle: {
            stroke: theme.palette.error.main,
            strokeWidth: 10,
            r: 60
          }
        }
      }
    }
  }
}))
