import { type FC, useState, useEffect } from 'react'
import { HomePageLayout } from 'layout'
import {
  Box,
  Button,
  CircleLoader,
  Container,
  Grid,
  Icon,
  Typography
} from '@ntpkunity/controls'
import {
  ProductsWrap,
  ProductSlider,
  FeaturedProducts,
  NewsLetterArea,
  HomePageBlogsWrap
} from './homePageStyle'
import { useTheme } from '@mui/material'
import {
  useGetFAQS,
  useGetProducts,
  useGetProductsDetail
} from '@modules/homePage/services'
import { ProductBox } from '@modules/homePage/components/productComponentStyle'
import ProductImage from '../../../../shared/assets/images/device-img.svg'
import ImagePlaceholder from '../../../../shared/assets/images/img-placeholder.svg'
import {
  ResponsiveCarousel,
  type ResponsiveType
} from '@shared/components/ResponsiveCarousel/ResponsiveCarousel'
import { scrollOnTop, hashToId, scrollToId } from '@shared/helper'
import { colors } from '@modules/homePage/constants'
import { APP_ROUTES } from '@router/path'
import { useNavigate } from 'react-router'
import { NavLink, useLocation } from 'react-router-dom'
import { TestimonialsComponent } from '@modules/testimonial/components'
import {
  Category,
  className,
  homepageBlogDesc,
  Product as UnityProduct
} from '@shared/constants'
import {
  NewsLetterComponent,
  FaqsComponent,
  HomePageBannerComponent,
  BrandsLogoComponent
} from '@modules/homePage/components'

import { type IProduct, type IProductDetail } from '@shared/typings'
import { BestOfAppexNow } from '@modules/homePage/components/bestOfAppexnow/bestOfAppexnow'
import { FadeAnimation } from '@shared/components'
import {
  BlogListItemComponent,
  RelatedBlogsComponent,
  useGetAllBlogs
} from '@modules/blog'
import EmBankLogo from '../../../../shared/assets/images/logo-em-bank.svg'
import HayDockLogo from '../../../../shared/assets/images/logo-haydock.svg'
import CharlesDeanLogo from '../../../../shared/assets/images/logo-charles-dean.svg'
import CloseBrothersLogo from '../../../../shared/assets/images/logo-close-brothers.svg'
import BibbyLogo from '../../../../shared/assets/images/logo-bibby.svg'
import UTBLogo from '../../../../shared/assets/images/logo-utb.svg'
import AutoNationLogo from '../../../../shared/assets/images/logo-autonation.svg'
import MiniLogo from '../../../../shared/assets/images/mini-logo.svg'
import CPFLogo from '../../../../shared/assets/images/cpf-logo.svg'
import RjsLogo from '../../../../shared/assets/images/rjs-logo.svg'
import ElevLogo from '../../../../shared/assets/images/Elev8-logo.svg'
import SynergiLogo from '../../../../shared/assets/images/synergi-logo.svg'
import { useGetTestimonials } from '@modules/homePage/services/testimonials/testimonialsService'

export const NoDataFound = (): JSX.Element => {
  const theme = useTheme()
  return (
    <Box theme={theme} className="no-data">
      <CircleLoader theme={theme} />
    </Box>
  )
}

export const responsive = ({
  itemXl,
  itemLg,
  itemMd,
  itemSm,
  itemXs
}): ResponsiveType => ({
  xl: {
    breakpoint: { max: 4000, min: 1920 },
    items: itemXl
  },
  lg: {
    breakpoint: { max: 1920, min: 1201 },
    items: itemLg
  },
  md: {
    breakpoint: { max: 1200, min: 992 },
    items: itemMd,
    partialVisibilityGutter: 16
  },
  sm: {
    breakpoint: { max: 991, min: 576 },
    items: itemSm,
    partialVisibilityGutter: 16
  },
  xs: {
    breakpoint: { max: 575, min: 0 },
    items: itemXs,
    partialVisibilityGutter: 16
  }
})

export const NewHomePage: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()

  const { data, isLoading: productsLoading } = useGetProducts()
  const { data: productDetailData, isLoading: productsDetailLoading } =
    useGetProductsDetail()
  const { isLoading: testimonialsLoading } = useGetTestimonials()
  const queryParams = '?is_latest=true'
  const { data: blogs, isLoading: blogsLoading } = useGetAllBlogs(queryParams)
  const { isLoading: faqsLoading } = useGetFAQS()

  const [products, setProducts] = useState<IProduct[]>([])
  const [productDetail, setProductDetail] = useState<IProductDetail[]>([])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null
    if (location.hash.length > 0) {
      if (
        !productsLoading &&
        !productsDetailLoading &&
        !testimonialsLoading &&
        !blogsLoading &&
        !faqsLoading
      ) {
        timeoutId = setTimeout(() => {
          scrollToId(hashToId(location.hash))
        }, 500)
      }
    } else {
      scrollOnTop()
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [
    location.hash,
    productsLoading,
    productsDetailLoading,
    testimonialsLoading,
    blogsLoading,
    faqsLoading
  ])

  useEffect(() => {
    if (data !== undefined) {
      setProducts(data)
    }
  }, [data])

  useEffect(() => {
    if (Array.isArray(productDetailData) && productDetailData?.length > 0) {
      setProductDetail(productDetailData)
    }
  }, [productDetailData])

  return (
    <HomePageLayout data-testid="root">
      <Box
        theme={theme}
        className="banner-area"
        m={{ xs: '-24px 24px 24px 24px', md: '-52px 40px 40px 40px' }}
      >
        <HomePageBannerComponent />
      </Box>
      <Box theme={theme} pt={{ xs: 5, md: 8 }} pb={{ xs: 5, md: 8 }}>
        <BrandsLogoComponent
          logoItem={[
            {
              logo: EmBankLogo,
              className: 'size-28'
            },
            {
              logo: HayDockLogo,
              className: 'size-28'
            },
            {
              logo: CloseBrothersLogo,
              className: 'size-28'
            },
            {
              logo: BibbyLogo,
              className: 'size-30'
            },
            {
              logo: UTBLogo,
              className: 'size-30'
            },
            {
              logo: CharlesDeanLogo,
              className: 'size-24'
            },
            {
              logo: AutoNationLogo,
              className: 'size-24'
            },
            {
              logo: MiniLogo,
              className: 'size-32'
            },
            {
              logo: CPFLogo,
              className: 'size-36'
            },
            {
              logo: RjsLogo,
              className: 'size-36'
            },
            {
              logo: ElevLogo,
              className: 'size-32'
            },
            {
              logo: SynergiLogo,
              className: 'size-36'
            }
          ]}
        />
      </Box>
      <Container
        theme={theme}
        maxWidth="lg"
        className="custom-container"
        data-testid="parent"
      >
        <ProductsWrap
          theme={theme}
          className="product-wrap"
          pt={{ xs: 8, md: 10 }}
        >
          <Box theme={theme} className="product-section">
            <FadeAnimation>
              <Box
                theme={theme}
                className="section-head"
                display={'flex'}
                alignItems={'center'}
                mb={2}
              >
                <Typography
                  theme={theme}
                  variant="h3"
                  component="h3"
                  flexGrow={1}
                  className="text-h3"
                >
                  Explore Our Products
                </Typography>

                <Box
                  theme={theme}
                  className="action-area"
                  flexShrink={0}
                  display={'flex'}
                  alignItems={'center'}
                >
                  <Box theme={theme} className="action-btn">
                    <Button
                      defaultBtn
                      theme={theme}
                      endIcon={<Icon name="IconLeftArrow" />}
                      text={
                        <>
                          View All{' '}
                          <span className="btn-sub-text">Products</span>
                        </>
                      }
                      onClick={() => {
                        navigate(`${APP_ROUTES.PRODUCTS}`)
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </FadeAnimation>

            <FadeAnimation>
              <Box theme={theme} display={'none'}>
                {products?.length > 0
                  ? (
                  <Grid
                    theme={theme}
                    container
                    item
                    spacing={3}
                    className="in-grid"
                    alignItems={'stretch'}
                  >
                    {products
                      ?.filter(
                        (product) =>
                          product.name !== UnityProduct.SUPPORT_SERVICES
                      )
                      ?.map((product, index) => (
                        <Grid
                          theme={theme}
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          key={product.id}
                        >
                          <ProductBox
                            theme={theme}
                            className={`product-box ${className.homePageProduct}-${product.name}-home`}
                            onClick={() => {
                              navigate(
                                `${APP_ROUTES.DETAIL_PAGE}/${product.name}`
                              )
                            }}
                          >
                            <a
                              className={`prd-link ${className.homePageProduct}-${product.name}-home`}
                            >
                              <span
                                className={`prd-bg ${colors[index] as string} ${
                                  className.homePageProduct
                                }-${product.name}-home`}
                              ></span>
                              <Box
                                theme={theme}
                                className={`prd-img ${className.homePageProduct}-${product.name}-home`}
                              >
                                <img
                                  src={product.image_url}
                                  alt="Product Image"
                                />
                              </Box>
                              <Typography
                                className={`prd-name ${className.homePageProduct}-${product.name}-home`}
                                theme={theme}
                                variant="body1"
                                component="p"
                              >
                                {product.name}
                              </Typography>
                              <Typography
                                className="prd-desc text-muted text-ellipses line-clamp line-clamp-3"
                                theme={theme}
                                variant="caption"
                                component="small"
                                display={'block'}
                                mt={1}
                              >
                                {product?.product_thumbnail}
                              </Typography>
                            </a>
                          </ProductBox>
                        </Grid>
                      ))}
                  </Grid>
                    )
                  : (
                  <NoDataFound />
                    )}
              </Box>
              <ProductSlider theme={theme} className="product-slider">
                {products?.length > 0
                  ? (
                  <ResponsiveCarousel
                    responsive={responsive({
                      itemXl: 4,
                      itemLg: 4,
                      itemMd: 3,
                      itemSm: 2.04,
                      itemXs: 1.03
                    })}
                  >
                    {products
                      ?.filter(
                        (product) =>
                          product.name !== UnityProduct.SUPPORT_SERVICES
                      )
                      ?.map((product, index) => (
                        <ProductBox
                          key={product.id}
                          theme={theme}
                          className={`product-box ${className.homePageProduct}-${product.name}-home`}
                          onClick={() => {
                            navigate(
                              `${APP_ROUTES.DETAIL_PAGE}/${product.name}`
                            )
                          }}
                        >
                          <a className="prd-link">
                            <span
                              className={`prd-bg ${colors[index] as string}`}
                            ></span>
                            <Box theme={theme} className="prd-img">
                              <img
                                src={product.image_url}
                                alt="Product Image"
                              />
                            </Box>
                            <Typography
                              className="prd-name"
                              theme={theme}
                              variant="body1"
                              component="p"
                            >
                              {product.name}
                            </Typography>
                          </a>
                        </ProductBox>
                      ))}
                  </ResponsiveCarousel>
                    )
                  : (
                  <NoDataFound />
                    )}
              </ProductSlider>
            </FadeAnimation>
            {/* <TakeQuiz type={"products"} mt={-3.75} /> */}
          </Box>

          <FeaturedProducts
            theme={theme}
            className="feature-products"
            mt={{ xs: 10, md: 15 }}
            display={'none'}
          >
            <Grid
              theme={theme}
              className="mobile-slider-grid"
              container
              item
              spacing={{ xs: 1, md: 3 }}
              flexWrap={{ xs: 'nowrap', md: 'wrap' }}
              display={'none'}
            >
              <Grid theme={theme} item xs={12} sm={6} md={4}>
                <Box theme={theme} className="feat-prod-item">
                  <a className="prd-link">
                    <Box theme={theme} className="prd-img" mb={2}>
                      <img src={ImagePlaceholder} alt="Product Image" />
                    </Box>
                    <Box theme={theme} className="prd-content">
                      <Typography
                        display={'block'}
                        mb={1}
                        className="text-muted text-uppercase fw-sbold"
                        component="small"
                        variant="caption"
                        theme={theme}
                      >
                        featured app
                      </Typography>
                      <Typography
                        className="text-h4"
                        mb={1}
                        theme={theme}
                        component="h4"
                        variant="h4"
                      >
                        Sample Product Name
                      </Typography>
                      <Typography
                        className="text-muted text-body2"
                        component="span"
                        variant="body2"
                        theme={theme}
                      >
                        Sed aenean ipsum, non dictum elementum mauris sit.
                        Scelerisque ultrices posuere leo metus.
                      </Typography>
                    </Box>
                  </a>
                </Box>
              </Grid>
              <Grid theme={theme} item xs={12} sm={6} md={4}>
                <Box theme={theme} className="feat-prod-item">
                  <a className="prd-link">
                    <Box theme={theme} className="prd-img" mb={2}>
                      <img src={ImagePlaceholder} alt="Product Image" />
                    </Box>
                    <Box theme={theme} className="prd-content">
                      <Typography
                        display={'block'}
                        mb={1}
                        className="text-muted text-uppercase fw-sbold"
                        component="small"
                        variant="caption"
                        theme={theme}
                      >
                        upcoming product
                      </Typography>
                      <Typography
                        className="text-h4"
                        mb={1}
                        theme={theme}
                        component="h4"
                        variant="h4"
                      >
                        Sample Product Name
                      </Typography>
                      <Typography
                        className="text-muted text-body2"
                        component="span"
                        variant="body2"
                        theme={theme}
                      >
                        Sed aenean ipsum, non dictum elementum mauris sit.
                        Scelerisque ultrices posuere leo metus.
                      </Typography>
                    </Box>
                  </a>
                </Box>
              </Grid>
              <Grid theme={theme} item xs={12} sm={6} md={4}>
                <Box theme={theme} className="feat-prod-item">
                  <a className="prd-link">
                    <Box theme={theme} className="prd-img" mb={2}>
                      <img src={ImagePlaceholder} alt="Product Image" />
                    </Box>
                    <Box theme={theme} className="prd-content">
                      <Typography
                        display={'block'}
                        mb={1}
                        className="text-muted text-uppercase fw-sbold"
                        component="small"
                        variant="caption"
                        theme={theme}
                      >
                        recommended app
                      </Typography>
                      <Typography
                        className="text-h4"
                        mb={1}
                        theme={theme}
                        component="h4"
                        variant="h4"
                      >
                        Sample Product Name
                      </Typography>
                      <Typography
                        className="text-muted text-body2"
                        component="span"
                        variant="body2"
                        theme={theme}
                      >
                        Sed aenean ipsum, non dictum elementum mauris sit.
                        Scelerisque ultrices posuere leo metus.
                      </Typography>
                    </Box>
                  </a>
                </Box>
              </Grid>
            </Grid>
          </FeaturedProducts>

          <Box
            theme={theme}
            className="product-section"
            mt={{ xs: 10, md: 15 }}
            display={'none'}
          >
            <Grid theme={theme} container item spacing={{ xs: 1, md: 3 }}>
              <Grid theme={theme} item xs={12} md={3}>
                <Box
                  theme={theme}
                  className="section-head with-quiz-text"
                  mb={{ xs: 1, md: 0 }}
                >
                  <Typography
                    theme={theme}
                    variant="h3"
                    component="h3"
                    className="text-h3"
                  >
                    Explore Our Services
                  </Typography>
                  <Typography
                    theme={theme}
                    className="text-muted"
                    variant="body2"
                    component="span"
                    mt={2}
                  >
                    Platea diam, fames sociis pharetra ac nibh. Sed ultricies
                    nulla urna porttitor pulvinar malesuada iaculis ut mauris.
                    Pulvinar phasellus mi nisl, quis proin pulvinar nat.
                  </Typography>
                  {/* <TakeQuiz type={"services"} mt={2} /> */}
                  <Box
                    theme={theme}
                    className="action-area"
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <Box theme={theme} className="action-btn" mt={2}>
                      <Button
                        defaultBtn
                        theme={theme}
                        endIcon={<Icon name="IconLeftArrow" />}
                        text={'View All Services'}
                        // onClick={() => { }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid theme={theme} item xs={12} md={9}>
                <ProductSlider theme={theme} className="product-slider">
                  <ResponsiveCarousel
                    responsive={responsive({
                      itemXl: 3,
                      itemLg: 3,
                      itemMd: 2.04,
                      itemSm: 2.04,
                      itemXs: 1.03
                    })}
                  >
                    <ProductBox
                      theme={theme}
                      className="product-box"
                      // onClick={() => { }}
                    >
                      <a className="prd-link">
                        <span className="prd-bg"></span>
                        <Box theme={theme} className="prd-img">
                          <img src={ProductImage} alt="Product Image" />
                        </Box>
                        <Typography
                          className="prd-name"
                          theme={theme}
                          variant="body1"
                          component="p"
                        >
                          Sample Product Name
                        </Typography>
                      </a>
                    </ProductBox>
                    <ProductBox
                      theme={theme}
                      className="product-box"
                      // onClick={() => { }}
                    >
                      <a className="prd-link">
                        <span className="prd-bg"></span>
                        <Box theme={theme} className="prd-img">
                          <img src={ProductImage} alt="Product Image" />
                        </Box>
                        <Typography
                          className="prd-name"
                          theme={theme}
                          variant="body1"
                          component="p"
                        >
                          Sample Product Name
                        </Typography>
                      </a>
                    </ProductBox>
                    <ProductBox
                      theme={theme}
                      className="product-box"
                      // onClick={() => { }}
                    >
                      <a className="prd-link">
                        <span className="prd-bg"></span>
                        <Box theme={theme} className="prd-img">
                          <img src={ProductImage} alt="Product Image" />
                        </Box>
                        <Typography
                          className="prd-name"
                          theme={theme}
                          variant="body1"
                          component="p"
                        >
                          Sample Product Name
                        </Typography>
                      </a>
                    </ProductBox>
                    <ProductBox
                      theme={theme}
                      className="product-box"
                      // onClick={() => { }}
                    >
                      <a className="prd-link">
                        <span className="prd-bg"></span>
                        <Box theme={theme} className="prd-img">
                          <img src={ProductImage} alt="Product Image" />
                        </Box>
                        <Typography
                          className="prd-name"
                          theme={theme}
                          variant="body1"
                          component="p"
                        >
                          Sample Product Name
                        </Typography>
                      </a>
                    </ProductBox>
                  </ResponsiveCarousel>
                </ProductSlider>
              </Grid>
            </Grid>
          </Box>

          <Box
            theme={theme}
            className="product-section"
            mt={{ xs: 10, md: 15 }}
            display={'none'}
          >
            <Box
              theme={theme}
              className="section-head"
              display={'flex'}
              alignItems={'center'}
              mb={2}
            >
              <Typography
                theme={theme}
                variant="h3"
                component="h3"
                flexGrow={1}
                className="text-h3"
              >
                Explore the Add-Ons
              </Typography>
              <Box
                theme={theme}
                className="action-area"
                flexShrink={0}
                display={'flex'}
                alignItems={'center'}
              >
                <Box theme={theme} className="action-btn">
                  <Button
                    defaultBtn
                    theme={theme}
                    endIcon={<Icon name="IconLeftArrow" />}
                    text={
                      <>
                        View All <span className="btn-sub-text">Add-Ons</span>
                      </>
                    }
                    // onClick={() => { }}
                  />
                </Box>
              </Box>
            </Box>
            <ProductSlider theme={theme} className="product-slider">
              <Grid
                theme={theme}
                container
                item
                spacing={{ xs: 1, md: 3 }}
                className="mobile-slider-grid"
                flexWrap={{ xs: 'nowrap', md: 'wrap' }}
              >
                <Grid theme={theme} item xs={12} sm={4} md={3}>
                  <ProductBox
                    theme={theme}
                    className="product-box"
                    // onClick={() => { }}
                  >
                    <a className="prd-link">
                      <span className="prd-bg"></span>
                      <Box theme={theme} className="prd-img">
                        <img src={ProductImage} alt="Product Image" />
                      </Box>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        Sample Product Name
                      </Typography>
                    </a>
                  </ProductBox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={4} md={3}>
                  <ProductBox
                    theme={theme}
                    className="product-box"
                    // onClick={() => { }}
                  >
                    <a className="prd-link">
                      <span className="prd-bg"></span>
                      <Box theme={theme} className="prd-img">
                        <img src={ProductImage} alt="Product Image" />
                      </Box>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        Sample Product Name
                      </Typography>
                    </a>
                  </ProductBox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={4} md={3}>
                  <ProductBox
                    theme={theme}
                    className="product-box"
                    // onClick={() => { }}
                  >
                    <a className="prd-link">
                      <span className="prd-bg"></span>
                      <Box theme={theme} className="prd-img">
                        <img src={ProductImage} alt="Product Image" />
                      </Box>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        Sample Product Name
                      </Typography>
                    </a>
                  </ProductBox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={4} md={3}>
                  <ProductBox
                    theme={theme}
                    className="product-box"
                    // onClick={() => { }}
                  >
                    <a className="prd-link">
                      <span className="prd-bg"></span>
                      <Box theme={theme} className="prd-img">
                        <img src={ProductImage} alt="Product Image" />
                      </Box>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        Sample Product Name
                      </Typography>
                    </a>
                  </ProductBox>
                </Grid>
              </Grid>
            </ProductSlider>
            {/* <TakeQuiz type={"add-ons"} mt={2} /> */}
          </Box>
          <Box theme={theme} pt={{ xs: 10, md: 9.375 }} id="education">
            <BestOfAppexNow
              responsive={responsive}
              productDetail={productDetail}
              data-testid="child"
            />
          </Box>
          <Box
            theme={theme}
            className="product-section"
            mt={{ xs: 10, md: 15 }}
            display={'none'}
          >
            <Box
              theme={theme}
              className="section-head"
              alignItems={'center'}
              mb={2}
            >
              <Typography
                theme={theme}
                variant="h3"
                component="h3"
                flexGrow={1}
                className="text-h3"
              >
                Explore Our Bundles
              </Typography>
              <Box
                theme={theme}
                className="action-area"
                flexShrink={0}
                alignItems={'center'}
              >
                <Box theme={theme} className="action-btn" display={'none'}>
                  <Button
                    defaultBtn
                    theme={theme}
                    endIcon={<Icon name="IconLeftArrow" />}
                    text={
                      <>
                        View All <span className="btn-sub-text">Bundles</span>
                      </>
                    }
                    // onClick={() => { }}
                  />
                </Box>
              </Box>
            </Box>
            <ProductSlider theme={theme} className="product-slider">
              <Grid
                theme={theme}
                container
                item
                spacing={{ xs: 1, md: 3 }}
                className="mobile-slider-grid"
                flexWrap={{ xs: 'nowrap', md: 'wrap' }}
              >
                <Grid theme={theme} item xs={12} sm={4} md={3}>
                  <ProductBox
                    theme={theme}
                    className="product-box"
                    // onClick={() => { }}
                  >
                    <a className="prd-link">
                      <span className="prd-bg"></span>
                      <Box theme={theme} className="prd-img">
                        <img src={ProductImage} alt="Product Image" />
                      </Box>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        Sample Product Name
                      </Typography>
                    </a>
                  </ProductBox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={4} md={3}>
                  <ProductBox
                    theme={theme}
                    className="product-box"
                    // onClick={() => { }}
                  >
                    <a className="prd-link">
                      <span className="prd-bg"></span>
                      <Box theme={theme} className="prd-img">
                        <img src={ProductImage} alt="Product Image" />
                      </Box>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        Sample Product Name
                      </Typography>
                    </a>
                  </ProductBox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={4} md={3}>
                  <ProductBox
                    theme={theme}
                    className="product-box"
                    // onClick={() => { }}
                  >
                    <a className="prd-link">
                      <span className="prd-bg"></span>
                      <Box theme={theme} className="prd-img">
                        <img src={ProductImage} alt="Product Image" />
                      </Box>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        Sample Product Name
                      </Typography>
                    </a>
                  </ProductBox>
                </Grid>
                <Grid theme={theme} item xs={12} sm={4} md={3} display={'none'}>
                  <ProductBox
                    theme={theme}
                    className="product-box"
                    // onClick={() => { }}
                  >
                    <a className="prd-link">
                      <span className="prd-bg"></span>
                      <Box theme={theme} className="prd-img">
                        <img src={ProductImage} alt="Product Image" />
                      </Box>
                      <Typography
                        className="prd-name"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        Sample Product Name
                      </Typography>
                    </a>
                  </ProductBox>
                </Grid>
              </Grid>
            </ProductSlider>
            {/* <TakeQuiz type={"bundles"} mt={2} /> */}
          </Box>
        </ProductsWrap>
        <Box theme={theme} pt={{ xs: 10, md: 15 }}>
          <TestimonialsComponent />
        </Box>
        <Box theme={theme} pt={{ xs: 10, md: 15 }} id="faqs">
          <FaqsComponent />
        </Box>
        <HomePageBlogsWrap
          theme={theme}
          className="home-page-blogs-wrap"
          mt={{ xs: 5, md: 15 }}
        >
          <RelatedBlogsComponent
            blogBadge="OUR BLOG"
            blogSectionTitle="Resources and insights"
            blogSectionDescription={homepageBlogDesc}
          />
          <Box theme={theme} className="blog-list-items-wrap">
            <Grid
              theme={theme}
              container
              item
              spacing={3}
              alignItems={'stretch'}
              justifyContent={'center'}
            >
              {blogs?.map((blog) => (
                <Grid key={blog.id} theme={theme} item xs={12} sm={6} md={4}>
                  <NavLink
                    to={`${APP_ROUTES.BLOGS}/${blog.title_url}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <BlogListItemComponent
                      onClick={() => {
                        navigate(`${APP_ROUTES.BLOGS}/${blog.title_url}`)
                      }}
                      blog={blog}
                    />
                  </NavLink>
                </Grid>
              ))}
            </Grid>
          </Box>
        </HomePageBlogsWrap>
      </Container>
      <NewsLetterArea
        theme={theme}
        className="newsletter-area"
        pt={{ xs: 0, md: 10 }}
      >
        <NewsLetterComponent />
      </NewsLetterArea>
    </HomePageLayout>
  )
}
