import { useQuery, UseQueryResult } from "react-query";
import { QueryKeys } from "../../Enums/UseQueryKeys";
import { getAllLenderSelectionRules } from "../../services/lender-selection-rules.service";
import {
  LenderSelectionRule,
  SearchParams,
} from "../../Types/lenderSelectionRules";
import { getQueryParamsString } from "../../utilities/common";

export const useGetAllLenderSelectioRules = (
  params: SearchParams
): UseQueryResult<LenderSelectionRule[]> => {
  const searchParams = getQueryParamsString(params);
  return useQuery<LenderSelectionRule[]>({
    queryKey: [QueryKeys.GET_ALL_LENDER_SELECTION_RULES, params],
    queryFn: () => getAllLenderSelectionRules(searchParams),
    enabled: !!params,
    retry: 3,
  });
};
