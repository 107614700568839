import { Link, useTheme } from '@mui/material'
import { Box, Icon, Label, Typography } from '@ntpkunity/controls'
import { FC } from 'react'

interface CodeItem {
  code: string
  status: string
  description: string
}

interface decisionDetails {
  description?: string
  code?: string
  status?: string
  title?: string
  codeStatus?: string
  icon?: any
}
interface BodySectionProps {
  riskCodes: CodeItem[]
  infoCodes: CodeItem[]
  riskTitle: string
  infoTitle: string
  addressTitle?: string
  addressDetails?: string
  addressLink?: string
  decisionDetails: decisionDetails[]
}

const BodySection: FC<BodySectionProps> = ({
  riskCodes,
  infoCodes,
  riskTitle,
  infoTitle,
  addressTitle,
  addressDetails,
  addressLink,
  decisionDetails
}) => {
  const theme = useTheme()

  return (
    <Box theme={theme} className="content-body">
      {addressTitle && addressDetails && addressLink && (
        <Box theme={theme} mb={3}>
          <Box theme={theme} mb={3}>
            <Typography theme={theme} component="p" variant="caption" children={addressTitle} />
          </Box>
          <Box theme={theme} mb={1}>
            <Typography theme={theme} component="p" variant="body2" children={addressDetails} />
          </Box>
          <Link className="custom-link" onClick={() => {}}>
            <Typography
              theme={theme}
              variant="body2"
              component={'p'}
              className="custom-link"
              children={addressLink}
            />
          </Link>
        </Box>
      )}

      {/* Risk Codes Section */}
      {riskCodes?.length > 0 ? (
        <Box theme={theme} mb={3}>
          <Box theme={theme} mb={3}>
            <Typography theme={theme} component="p" variant="caption" children={riskTitle} />
          </Box>
          <Box theme={theme}>
            {riskCodes?.map((item, index) => (
              <Box key={index} theme={theme} display={'flex'} alignItems={'center'} gap={1} mb={1}>
                <Label
                  theme={theme}
                  state={
                    item?.status === 'primary'
                      ? 'primary'
                      : item?.status === 'info'
                      ? 'info'
                      : item?.status === 'error'
                      ? 'error'
                      : item?.status === 'warning'
                      ? 'warning'
                      : item?.status === 'success'
                      ? 'success'
                      : undefined
                  }
                  value={item?.code}
                />
                <Typography
                  theme={theme}
                  component="p"
                  variant="body2"
                  children={item?.description}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ) : null}

      {/* Info Codes Section */}
      {infoCodes?.length > 0 ? (
        <Box theme={theme} mb={3}>
          <Box theme={theme} mb={3}>
            <Typography
              theme={theme}
              component="p"
              variant="caption"
              children={infoTitle} // Use the prop value for the Informational Codes title
            />
          </Box>
          <Box theme={theme}>
            {infoCodes?.map((item, index) => (
              <Box key={index} theme={theme} display={'flex'} alignItems={'center'} gap={1} mb={1}>
                <Label
                  theme={theme}
                  state={
                    item?.status === 'primary'
                      ? 'primary'
                      : item?.status === 'info'
                      ? 'info'
                      : item?.status === 'error'
                      ? 'error'
                      : item?.status === 'warning'
                      ? 'warning'
                      : item?.status === 'success'
                      ? 'success'
                      : undefined
                  }
                  value={item?.code}
                />
                <Typography
                  theme={theme}
                  component="p"
                  variant="body2"
                  children={item?.description}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ) : null}
      {decisionDetails &&
        decisionDetails?.length > 0 &&
        decisionDetails?.map((decisionItem, index) => (
          <Box key={index} theme={theme} mb={3}>
            <Box theme={theme} display={'flex'} alignItems={'center'} gap={1} mb={1}>
              {decisionItem?.title && (
                <Typography
                  theme={theme}
                  component="p"
                  variant="caption"
                  children={decisionItem?.title}
                  className="title"
                />
              )}
              {decisionItem?.icon && (
                <Box
                  theme={theme}
                  className={`fill-svg ${
                    decisionItem?.status === 'primary'
                      ? 'primary'
                      : decisionItem?.status === 'info'
                      ? 'info'
                      : decisionItem?.status === 'error'
                      ? 'error'
                      : decisionItem?.status === 'warning'
                      ? 'warning'
                      : decisionItem?.status === 'success'
                      ? 'success'
                      : ''
                  }`}
                >
                  <Icon name={decisionItem?.icon} />
                </Box>
              )}
            </Box>
            {decisionItem?.description && (
              <Box theme={theme} mb={1}>
                <Typography
                  theme={theme}
                  component="p"
                  variant="caption"
                  children={decisionItem?.description}
                  className="title"
                />
              </Box>
            )}
            {decisionItem?.code && (
              <Label
                theme={theme}
                state={
                  decisionItem?.codeStatus === 'primary'
                    ? 'primary'
                    : decisionItem?.codeStatus === 'info'
                    ? 'info'
                    : decisionItem?.codeStatus === 'error'
                    ? 'error'
                    : decisionItem?.codeStatus === 'warning'
                    ? 'warning'
                    : decisionItem?.codeStatus === 'success'
                    ? 'success'
                    : undefined
                }
                value={decisionItem?.code}
              />
            )}
          </Box>
        ))}
    </Box>
  )
}

export default BodySection
