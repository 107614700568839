import { useTheme } from '@mui/material'
import { CustomPageHeader } from '@shared/components/pageHeader/pageHeader'
import { Button, Grid, Input, Select, Typography } from '@ntpkunity/controls'

export const AddPackageList = () => {
  const theme = useTheme()
  return (
    <Grid theme={theme} container item columnSpacing={3} rowSpacing={3.5} mt={0}>
        <Grid theme={theme} item xs={12} md={3}>
            <Input theme={theme} type='text' label='Full Name' placeholder='Type here...' fullWidth />
        </Grid>
        <Grid theme={theme} item xs={12} md={3}>
            <Input theme={theme} type='text' label='Email Address' placeholder='Type here...' fullWidth />
        </Grid>
        <Grid theme={theme} item xs={12} md={3}>
            <Input theme={theme} type='text' label='Contact Number' placeholder='Type here...' fullWidth />
        </Grid>
        <Grid theme={theme} item xs={12} md={3}>
            <Select
                theme={theme}
                items={[
                { text: 'Option 1', value: '1' },
                { text: 'Option 2', value: '2' }
                ]}
                label="Product/Products"
                sxProps={undefined}
            />
        </Grid>
        <Grid theme={theme} item xs={12} md={3}>
            <Input theme={theme} type='text' label='Max Tran per Month' startAdornment={'$'} placeholder='Type here...' fullWidth />
        </Grid>
        <Grid theme={theme} item xs={12} md={3}>
            <Select
                theme={theme}
                items={[
                { text: 'Option 1', value: '1' },
                { text: 'Option 2', value: '2' }
                ]}
                label="Status"
                sxProps={undefined}
            />
        </Grid>
        <Grid theme={theme} item xs={12} md={3}>
            <Select
                theme={theme}
                items={[
                { text: 'Option 1', value: '1' },
                { text: 'Option 2', value: '2' }
                ]}
                label="Payment Mode"
                sxProps={undefined}
            />
        </Grid>
        <Grid theme={theme} item xs={12} md={3}>
            <Input theme={theme} type='text' label='Pricing' startAdornment={'$'} placeholder='Type here...' fullWidth />
        </Grid>
    </Grid>
  )
}
