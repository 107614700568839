import { Radio } from "@src/components/atoms";
import { QrCode } from "../qr-code/qr-code.component";

import "./varify-radio.scss";
import { ProvidePhone } from "../provide-phone/provide-phone.component";
import { ProvideEmail } from "../provide-email/provide-email.component";
import { useState } from "react";

export const VerifyRadio = ({
  stepFourDataReceivedEvent,
}: {
  stepFourDataReceivedEvent: () => void;
}) => {
  const [values, setValues] = useState<{
    email: boolean;
    phone: boolean;
  }>({ email: false, phone: false });

  return (
    <div className="verify-radio-container">
      <p className="sub-text-container sub-text text--body-2">
        Please select your preferred method to continue.
      </p>
      <div className="radio-container">
        <label htmlFor="qr-code" className="radio-container__options">
          <Radio
            label="QR Code"
            onChange={() => {
              setValues({ email: false, phone: false });
              stepFourDataReceivedEvent();
            }}
            name="contactMethod"
            htmlFor="qr-code"
            id="qr-code"
          />
        </label>
        <label className="radio-container__options" htmlFor="text-message">
          <Radio
            label="Text Message"
            onChange={() => setValues({ email: false, phone: true })}
            name="contactMethod"
            htmlFor="text-message"
            id="text-message"
          />
        </label>
        <label className="radio-container__options" htmlFor="email">
          <Radio
            label="Email"
            name="contactMethod"
            onChange={() => setValues({ email: true, phone: false })}
            htmlFor="email"
            id="email"
          />
        </label>
      </div>
      {!values.phone && !values.email && <QrCode />}
      {values.phone && <ProvidePhone />}
      {values.email && <ProvideEmail />}
    </div>
  );
};
