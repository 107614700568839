import "./buyer-order-info.scss";
import { Button } from "@src/components/atoms";

export const BuyerOrderInfo = ({ isSigned }: { isSigned?: boolean }) => {
  return (
    <div className="buyers-bar-container">
      <div className="buyers-left-container">
        <div className="buyers-logo-container bmw-icon bmw-icon--before" />
        <div className="buyers-text-container">
          <p className="buyers-text-title text--body-2">Buyer's order</p>
          <p className="text--label">4 min read</p>
        </div>
      </div>
      <div className="buyers-right-container">
        {isSigned ? (
          <div className="filled-status-container">
            <i className="icon-filled-status bmw-icon bmw-icon--before" />
            <span className="text--link-2">Signed</span>
          </div>
        ) : (
          <Button
            type="default"
            htmlType={"button"}
            text="Sign now"
            size="small"
          />
        )}
      </div>
    </div>
  );
};
