import { useTheme } from "@mui/material"
import { StickyNotesListWrap } from "./sticky-notes-list.style";
import { StickyNotes } from "../sticky-notes/sticky-notes.component"

export const StickyNotesList = () => {
    const theme = useTheme();

    return(
        <>
            <StickyNotesListWrap theme={theme} className="sticky-notes-list-wrap">
                <StickyNotes 
                    title={'#High_Priority'}
                    subTitle={"The applicant's credit score is 720, indicating a good credit rating. They have a history of timely repayments on previous loans, including a personal loan of $15,000 repaid over 3 years and a car."} 
                    color={'yellow'}
                />
                <StickyNotes 
                    title={'#High_Priority'}
                    subTitle={"Based on the comprehensive verification checks, employment history, and risk assessment, the decision-making process is entering its final stages. The applicant's financial profile and credibility have been thoroughly evaluated. The next steps involve weighing all factors to arrive at a well-informed decision regarding the credit application."} 
                    color={'ferozi'}
                />
                <StickyNotes 
                    title={'#High_Priority'}
                    subTitle={"Based on the comprehensive verification checks, employment history, and risk assessment, the decision-making process is entering its final stages. The applicant's financial profile and credibility have been thoroughly evaluated. The next steps involve weighing all factors to arrive at a well-informed decision regarding the credit application."} 
                    color={'purple'}
                />
            </StickyNotesListWrap>
        </>
    )
}