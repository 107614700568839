import "../../../styles/components/card-select.scss";
import { CardSelectProps } from "./card-select.props";
import clsx from "clsx";

export const CardSelect = ({
  className,
  logo,
  text,
  defaultCard,
  hasCheckbox,
}: CardSelectProps) => {
  return (
    <>
      <div
        className={clsx(className, {
          "card-select-container": true,
        })}
      >
        {logo && <img src={logo} alt={text} className="card-select-logo" />}
        <span className="card-select-name text--body-2">{text}</span>
        {defaultCard && (
          <>
            <span className="card-select-before-icon bmw-icon bmw-icon--before" />
            <span className="card-select-default-checked text--body-2">
              Default
            </span>
            <span className="card-select-after-icon bmw-icon bmw-icon--before" />
          </>
        )}
        {hasCheckbox && hasCheckbox}
      </div>
    </>
  );
};
