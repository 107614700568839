import { useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { TableCell, TableRow } from "@mui/material";
import { Box, Button, CircleLoader, DataTable, Icon } from "@ntpkunity/controls";
import { DataTableWrap } from "@shared/DataTableWrap";
import { Skeleton } from "@ntpkunity/controls";
import { useDocumentURLByArtifactId, useGetStips } from "common/hooks/useCustomerData";
import { StipulationType } from "../../../../enums/StipulationStatuses";
import { utcToLocal } from "utilities/methods";
import { utcToLocalFormat } from "common/constants";
import { SortingOrder } from "enums/SortingOrder";
import { useEffect, useRef, useState } from "react";
import { AdditionalDocModal } from "@modules/orderDetails/components/documents/additionalDocumentsModal";

const NewCustomerDocuments = () => {
  const theme = useTheme();
  const params = useParams();
  const { reference_id } = params;
  const order_reference_id = undefined;
  const [sortBy, setSortBy] = useState(null);
  const [customerAdditionalDocuments, setAdditionalDocumentsData] = useState([]);
  const { data: getCustomerAdditionalDocs, isLoading: isLoadingGet } = useGetStips(reference_id, order_reference_id, StipulationType.NON_STIP);
  const { mutate: documentURL  } = useDocumentURLByArtifactId();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [isAdded, setIsAdded] = useState<boolean>();
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadDocument = async (signedUrl, filename) => {
    const response = await fetch(signedUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const documentURLByArtifactId = async (artifactId) => {
    return new Promise((resolve, reject) => {
      documentURL(
        { key: artifactId },
        {
          onSuccess: ({ data }) => {
            resolve(data?.document_url);
          },
          onError: (error) => {
            reject(error);
          },
        }
      );
    });
  };
  const handleOpenSipulationDialog = (
    isAdded: boolean,
  ) => {
    setIsAdded(isAdded);
    setOpenAddDialog(true);
  };
  useEffect(() => {
    if (getCustomerAdditionalDocs?.data) {
      setAdditionalDocumentsData(getCustomerAdditionalDocs?.data.filter(f => f?.external_reference_id === null));
    }
  }, [getCustomerAdditionalDocs?.data]);

  const sorting_num_data = () => {
    const sortedData = [...customerAdditionalDocuments];
    let nextSortBy;

    if (sortBy === SortingOrder.ASC) {
      sortedData.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
      nextSortBy = SortingOrder.DSC;
    } else if (sortBy === SortingOrder.DSC) {
      nextSortBy = null; 
    } else {
      sortedData.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
      nextSortBy = SortingOrder.ASC;
    }

    setAdditionalDocumentsData(nextSortBy ? sortedData : (getCustomerAdditionalDocs?.data.filter(f => f?.external_reference_id === null))); 
    setSortBy(nextSortBy);
  };
  const getSortIcon = () => {
    if (sortBy === SortingOrder.ASC) {
      return <Icon name="AscendingIcon" />;
    } else if (sortBy === SortingOrder.DSC) {
      return <Icon name="DescendingIcon" />;
    } else {
      return <Icon name="AscDescIcon" />;
    }
  };
  const currentLoadingDocumentRef = useRef(null);

  const handleDownloadClick = async (documentUrl, documentName) => {
    currentLoadingDocumentRef.current = documentUrl;
    try {
      setIsDownloading(true)
      const url = await documentURLByArtifactId(documentUrl);
      await downloadDocument(url, documentName);
    } finally {
      currentLoadingDocumentRef.current = null;
      setIsDownloading(false)
    }
  };
  const Table = () => {
    return (
      <DataTableWrap className="table-pagination input-width">
        <DataTable
          theme={theme}
          theadChildren={
            <TableRow className="table-header no-gap">
              <TableCell>Document Name</TableCell>
              <TableCell>
                <Box theme={theme} className="table-data">
                  <Box className="table-data-title" theme={theme}>
                    Uploaded At
                  </Box>
                  <Button
                    defaultBtn
                    iconText={getSortIcon()}
                    onClick={sorting_num_data}
                  />
                </Box>
              </TableCell>
              <TableCell className="action-cell fixed-cell"></TableCell>
            </TableRow>
          }
          tbodyChildren={
            <>
              {isLoadingGet ? (
                <>
                  <TableRow>
                    <TableCell>
                      <Skeleton width={"100%"} height={20} theme={theme} />
                    </TableCell>
                    <TableCell>
                      <Skeleton width={"100%"} height={20} theme={theme} />
                    </TableCell>
                    <TableCell className="action-cell fixed-cell">
                      <Skeleton width={"100%"} height={20} theme={theme} />
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                customerAdditionalDocuments?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item?.document_type}</TableCell>
                    <TableCell>
                      {item?.created_at && utcToLocal(item?.created_at, utcToLocalFormat)}
                    </TableCell>
                    <TableCell className="action-cell fixed-cell">
                    {item?.document_url && (
                       isDownloading && currentLoadingDocumentRef.current === item.document_url ? (
                          <CircleLoader theme={theme} size="xs" />
                        ) : (
                          <Button
                            theme={theme}
                            defaultBtn
                            iconText={<Icon name="DownloadIcon" />}
                            onClick={() => handleDownloadClick(item?.document_url, item?.document_name)}
                          />
                        )
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </>
          }
        />
      </DataTableWrap>
    );
  };

  return (
    <Box theme={theme}>
      <Box theme={theme}>
        <Table />
      </Box>
      <Box mt={4} mb={2} theme={theme}>
        <Button 
          fullWidth
          theme={theme}
          secondary
          text="Upload Document"
          onClick={() => handleOpenSipulationDialog(true)}
        />
        {openAddDialog && (
          <AdditionalDocModal
            openAddDialog={openAddDialog}
            setOpenAddDialog={setOpenAddDialog}
            isAdded={isAdded}
          />
        )}
      </Box>
    </Box>
  );
};

export default NewCustomerDocuments;
