import { FC } from 'react'
import { useTheme } from '@mui/material'
import ChatsWrap from './ReceivingChats.style'
import { Avatar, Box, Icon, Typography } from '@ntpkunity/controls'

const ReceivingChat: FC = () => {
  const theme = useTheme()
  return (
    <ChatsWrap theme={theme} className="chats-wrap receiver-chat">
      <Box theme={theme} className="with-avatar">
        <Typography theme={theme} component="p" variant="body2" className="message-text">
          Ipsum sed neque varius nec varius sit ac netus metus. Dolor laoreet viverra dis eu nunc a
          adipiscing. Mus egestas faucibus hendrerit malesuada nunc sit molestie quam tellus. Tellus
          donec in nibh donec. Ac tempor sit faucibus sed vestibulum quisque. Non tristique quis
          tincidunt sed mattis eu etiam semper. Sit risus dictum porttitor pellentesque urna. Eget
          auctor volutpat et iaculis elit.
        </Typography>
        <Avatar theme={theme}>SK</Avatar>
      </Box>
      <Box theme={theme} className="chat-details">
        <Typography
          theme={theme}
          component="span"
          variant="caption"
          className="text-dark"
          children="Sample Customer Name"
        />
        <Box theme={theme} className="separator"></Box>
        <Typography theme={theme} component="span" variant="caption" children="00:00 AM" />
        <Box theme={theme} className="separator"></Box>
        <Typography theme={theme} component="span" variant="caption" mr={1}>
          <>Via:</> In-App Chat
        </Typography>
        <Icon name="TickIcon" />
      </Box>
    </ChatsWrap>
  )
}

export default ReceivingChat
