import { useTheme } from '@mui/material'
import { TopSellingWrap } from './top-selling.style'
import { Box, Typography } from '@ntpkunity/controls'
import { TopSellingProps } from './top-selling.props'

export const TopSelling = ({ items }: TopSellingProps) => {
  const theme = useTheme()
  return (
    <TopSellingWrap theme={theme} className="top-selling-wrap section-h">
      {items.map((item, index) => (
        <Box
          key={index}
          theme={theme}
          mb={1}
          className={item.listBg ? 'with-bg selling-list' : 'selling-list'}
        >
          <Box theme={theme} className="prd-image">
            <img src={item.productImage} alt="top-selling-item" />
          </Box>
          <Box theme={theme} className="prd-details">
            <Typography theme={theme} variant="caption" component="p" className="title-md">
              {item.productModal}
            </Typography>
            <Typography theme={theme} variant="body2" component="p" className="fw-700 title-lg">
              {item.productName}
            </Typography>
          </Box>
        </Box>
      ))}
    </TopSellingWrap>
  )
}
