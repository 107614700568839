import { useTheme } from '@mui/material'
import { AnalyticsContentWrap } from './vdp-analytics.style'
import { Box, Grid, Select, Typography } from '@ntpkunity/controls'
import { Widget } from '../analytics-widget'
import { ButtonGroup } from '@components'
import VDPGraph01 from '../../../public/assets/images/vdp-graph-01.png'
import VDPGraph02 from '../../../public/assets/images/vdp-graph-02.png'
import VDPGraph03 from '../../../public/assets/images/vdp-graph-03.png'
import VDPGraph04 from '../../../public/assets/images/vdp-graph-04.png'
import KPIGraph05 from '../../../public/assets/images/kpi-graph-05.png'
import VDPGraph05 from '../../../public/assets/images/vdp-graph-05.png'
import { ValueBox } from '../value-box'

export const VdpAnalytics = () => {
  const theme = useTheme()
  return (
    <AnalyticsContentWrap theme={theme} className="analytics-content-wrap">
      <Grid theme={theme} container item spacing={3} alignItems={'stretch'}>
        <Grid theme={theme} item xs={12}>
          <Typography theme={theme} component={'h2'} variant="h2">
            X7 xDrive40i
          </Typography>
        </Grid>
        <Grid theme={theme} item xs={12} md={8}>
          <Widget
            title="Total Revenue"
            actionArea={
              <>
                <ButtonGroup
                  items={[
                    {
                      title: 'Daily',
                      onClick: () => {},
                      selected: false
                    },
                    {
                      title: 'Weekly',
                      onClick: () => {},
                      selected: false
                    },
                    {
                      title: 'Monthly',
                      onClick: () => {},
                      selected: true
                    },
                    {
                      title: 'Yearly',
                      onClick: () => {},
                      selected: false
                    }
                  ]}
                />
              </>
            }
          >
            <Box theme={theme} className="graph-ph" textAlign={'center'} mt={3}>
              <img src={VDPGraph01} alt="" />
            </Box>
          </Widget>
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Box theme={theme} height={'100%'} display={'flex'} flexDirection={'column'} gap={3}>
            <Box theme={theme} flexGrow={1}>
              <Widget
                title="No. of Saves"
                actionArea={
                  <>
                    <Select
                      theme={theme}
                      disablePortal={false}
                      items={[
                        {
                          text: 'All',
                          value: '0'
                        },
                        {
                          text: 'Weekly',
                          value: '1'
                        },
                        {
                          text: 'Monthly',
                          value: '2'
                        }
                      ]}
                      value={1}
                    />
                  </>
                }
              >
                <Box theme={theme} display={'flex'} alignItems={'center'} gap={3} mt={3}>
                  <Box theme={theme} flexShrink={0}>
                    <ValueBox
                      value={'1,498'}
                      progressText={'+46%'}
                      success
                      noBg
                      subText={'vs Last Week'}
                    />
                  </Box>
                  <Box theme={theme} className="graph-ph">
                    <img src={VDPGraph02} alt="" />
                  </Box>
                </Box>
              </Widget>
            </Box>
            <Box theme={theme} flexGrow={1}>
              <Widget
                title="Checkout Started"
                actionArea={
                  <>
                    <Select
                      theme={theme}
                      disablePortal={false}
                      items={[
                        {
                          text: 'All',
                          value: '0'
                        },
                        {
                          text: 'Weekly',
                          value: '1'
                        },
                        {
                          text: 'Monthly',
                          value: '2'
                        }
                      ]}
                      value={1}
                    />
                  </>
                }
              >
                <Box theme={theme} display={'flex'} alignItems={'center'} gap={3} mt={3}>
                  <Box theme={theme} flexShrink={0}>
                    <ValueBox
                      value={'1,792'}
                      progressText={'-36%'}
                      danger
                      noBg
                      subText={'vs Last Week'}
                    />
                  </Box>
                  <Box theme={theme} className="graph-ph">
                    <img src={KPIGraph05} alt="" />
                  </Box>
                </Box>
              </Widget>
            </Box>
          </Box>
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Widget
            title="Pricing"
            actionArea={
              <>
                <Select
                  theme={theme}
                  disablePortal={false}
                  items={[
                    {
                      text: 'All',
                      value: '0'
                    },
                    {
                      text: 'Weekly',
                      value: '1'
                    },
                    {
                      text: 'Monthly',
                      value: '2'
                    }
                  ]}
                  value={2}
                />
              </>
            }
          >
            <Box theme={theme} mt={3}>
              <ValueBox
                value={'$426,800'}
                progressText={'+0.4'}
                success
                noBg
                subText={'vs Average'}
              />
              <Box theme={theme} className="graph-ph" mt={3}>
                <img src={VDPGraph03} alt="" />
              </Box>
            </Box>
          </Widget>
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Widget
            title="Cost to Market"
            actionArea={
              <>
                <Select
                  theme={theme}
                  disablePortal={false}
                  items={[
                    {
                      text: 'All',
                      value: '0'
                    },
                    {
                      text: 'Cash',
                      value: '1'
                    }
                  ]}
                  value={1}
                />
              </>
            }
          >
            <Box theme={theme} className="graph-ph" textAlign={'center'} mt={3}>
              <img src={VDPGraph04} alt="" style={{ maxHeight: 280 }} />
            </Box>
          </Widget>
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Widget
            title="Days Sale Inventory"
            actionArea={
              <>
                <Select
                  theme={theme}
                  disablePortal={false}
                  items={[
                    {
                      text: 'Lease',
                      value: '0'
                    },
                    {
                      text: 'Finance',
                      value: '1'
                    }
                  ]}
                  value={0}
                />
              </>
            }
          >
            <Box theme={theme} mt={3}>
              <ValueBox value={'0.7'} progressText={'+0.4'} success noBg subText={'vs Average'} />
              <Box theme={theme} className="graph-ph" mt={3}>
                <img src={VDPGraph05} alt="" />
              </Box>
            </Box>
          </Widget>
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Widget
            title="Days Payable Outstanding"
            actionArea={
              <>
                <Select
                  theme={theme}
                  disablePortal={false}
                  items={[
                    {
                      text: 'Lease',
                      value: '0'
                    },
                    {
                      text: 'Finance',
                      value: '1'
                    }
                  ]}
                  value={0}
                />
              </>
            }
          >
            <Box theme={theme} mt={3}>
              <ValueBox value={'175'} progressText={'+0.4'} success noBg subText={'vs Average'} />
              <Box theme={theme} className="graph-ph" mt={3}>
                <img src={VDPGraph05} alt="" />
              </Box>
            </Box>
          </Widget>
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Widget
            title="Days Sales Outstanding"
            actionArea={
              <>
                <Select
                  theme={theme}
                  disablePortal={false}
                  items={[
                    {
                      text: 'Lease',
                      value: '0'
                    },
                    {
                      text: 'Finance',
                      value: '1'
                    }
                  ]}
                  value={0}
                />
              </>
            }
          >
            <Box theme={theme} mt={3}>
              <ValueBox value={'108'} progressText={'-24%'} danger noBg subText={'vs Average'} />
              <Box theme={theme} className="graph-ph" mt={3}>
                <img src={VDPGraph05} alt="" />
              </Box>
            </Box>
          </Widget>
        </Grid>
      </Grid>
    </AnalyticsContentWrap>
  )
}
