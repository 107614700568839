import { Input } from "@src/components/atoms";
import "./spouse-name.scss";

export const SpouseName = () => {
  return (
    <div className="spouse-name-wrap title-fields-spacer">
      <p className="material-status-check--subtitle text--body-1">
        Please provide your spouse’s name.
      </p>
      <div className="dsx--m-0 checkout-column-wrapper">
        <Input
          name="spouseFirstName"
          id={"spouseFirstName"}
          placeholder="First name"
          label="Spouse’s first name"
        />
        <Input
          name="spouseLastName"
          id={"spouseLastName"}
          placeholder="Last name"
          label="Spouse’s last name"
        />
      </div>
    </div>
  );
};
