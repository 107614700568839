import clsx from "clsx";
import "../../../styles/components/icon-list.scss";
import { IconListProps } from "./icon-list.props";

export const IconList = ({ size, onDark, listItems }: IconListProps) => {
  return (
    <ul
      className={clsx({
        "list-items-wrap": true,
        "list-items-wrap--dark": onDark,
      })}
    >
      {listItems.map((item, index) => (
        <li
          className={clsx(
            {
              "list-item": true,
              "list-item--checklist": item.type === "checklist",
              "list-item--loading": item.type === "loading",
              "list-item--big": size === "big",
              "list-item--small": size === "small",
            },
            item.className
          )}
          key={index}
        >
          {item.type === "checklist" && (
            <span
              className={clsx({
                "list-item__icon bmw-icon bmw-icon--before data-icon": true,
              })}
              data-icon={"checkmark"}
            ></span>
          )}
          {item.type === "loading" && (
            <span
              className={clsx({
                "list-item__icon bmw-icon bmw-icon--before data-icon": true,
              })}
              data-icon={"hourglass"}
            ></span>
          )}
          {item.type === "icon" && (
            <span
              className={clsx({
                "list-item__icon bmw-icon bmw-icon--before data-icon": true,
              })}
              data-icon={item.icon}
            ></span>
          )}
          <span
            className={clsx({
              "list-item__text": true,
              "text--body-1": size === "big",
              "text--body-2": size === "small",
            })}
          >
            {item.text}
          </span>
        </li>
      ))}
    </ul>
  );
};
