import { useTheme } from "@mui/material";
import { Icon, Input } from "@ntpkunity/controls";

export type FuzzySearchInputProps = {
  fuzzySearch: string;
  setFuzzySearch: (value: string) => void;
};

export const FuzzySearchInput = ({
  fuzzySearch,
  setFuzzySearch,
}: FuzzySearchInputProps) => {
  const theme = useTheme();

  return (
    <Input
      theme={theme}
      endAdornment={<Icon name="SearchIcon" />}
      type="text"
      placeholder="Search here.."
      fullWidth
      startAdornment=""
      id="fuzzy-search-workqueue-input"
      value={fuzzySearch}
      onChange={(value: string) => {
        setFuzzySearch(value);
      }}
    />
  );
};
