import { ReactNode } from "react";
import clsx from "clsx";
import { Progress as AntProgress } from "antd";
import "../../../styles/components/_progress-bar.scss";

export const ProgressBar = ({
  percent,
  status,
  type,
  showInfo,
  label,
  helpText,
  showLabelIcon = false,
  iconName,
  onDark = false,
}: {
  percent?: number;
  status?: "success" | "exception" | "normal" | "active";
  type?: "line" | "circle" | "dashboard";
  showInfo?: boolean;
  label?: string | ReactNode;
  helpText?: string;
  showLabelIcon?: boolean;
  iconName?: string;
  onDark?: boolean;
}) => {
  return (
    <div
      className={clsx({
        "progress-bar-wrapper": true,
        "progress-bar-wrapper--on-dark": onDark,
      })}
    >
      {label && (
        <label className="progress-label text--body-2">
          {showLabelIcon && (
            <div
              className="bmw-icon bmw-icon--before data-icon"
              role="img"
              aria-label="Progress Icon"
              data-icon={iconName}
            />
          )}
          <span>{label}</span>
        </label>
      )}
      <AntProgress
        percent={percent}
        status={status}
        type={type}
        showInfo={showInfo}
        format={(percent) => {
          if (status === "exception") {
            return `${percent}%`;
          }
          if (status === "success") {
            return (
              <div
                className="bmw-icon bmw-icon--before data-icon"
                data-icon={"checkmark"}
              />
            );
          }
          return `${percent}%`;
        }}
      />
      {helpText && (
        <label className="help-text text-secondary text--body-2">
          <span>{helpText}</span>
        </label>
      )}
    </div>
  );
};
