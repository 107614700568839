import { FC } from 'react'
import { useTheme } from '@mui/material'
import { DuoTab } from '@ntpkunity/controls'
import Chats from '../Chats/Chats-component'

const Tabs: FC = () => {
  const theme = useTheme()
  return (
    <DuoTab
      theme={theme}
      varient={'underline'}
      defaultTabIndex={0}
      items={[
        {
          title: 'Conversation',
          content: <Chats />
        },
        {
          title: 'Orders',
          content: 'Orders'
        },
        {
          title: 'Activity Logs',
          content: 'Activity Logs'
        },
        {
          title: 'Notes',
          content: 'Notes'
        }
      ]}
    />
  )
}

export default Tabs
