import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

const ChatsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({}) => ({
  '&.chats-wrap': {
    '.divider': {
      margin: '0 40px 8px'
    }
  }
}))

export default ChatsWrap
