import { notification } from "antd";
import clsx from "clsx";
import { ReactNode, useEffect, useCallback, useRef } from "react";

export const Notification = ({
  title,
  placement,
  show = true,
  description,
  type = "info",
  icon,
  className,
  role,
  variant,
  showContent = true,
  fullWidth,
}: {
  title?: string;
  placement?:
    | "top"
    | "topLeft"
    | "topRight"
    | "bottom"
    | "bottomLeft"
    | "bottomRight";
  show?: boolean;
  description: ReactNode;
  type?: "success" | "info" | "warning" | "error";
  variant?: "neutral" | "default";
  icon?: ReactNode;
  className?: string;
  role?: "alert" | "status";
  showContent?: boolean;
  fullWidth?: boolean;
}) => {
  const [api, contextHolder] = notification.useNotification();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const configureContainer = useCallback(() => {
    if (containerRef.current) {
      notification.config({
        getContainer: () => containerRef.current!, // Ensure the wrapper is used
      });
    }
  }, []);

  const openNotification = useCallback(() => {
    api.open({
      message: title,
      placement: placement,
      description: description,
      duration: 0,
      type: type,
      icon: icon,
      role: role,
      className: clsx(
        className,
        type && `notification-${type}`,
        role === "alert" && "grid-notification",
        role === "status" && "status-notification",
        variant && `notification-${variant}`,
        showContent === false && "notification-closed",
        fullWidth === true && "full-width"
      ),
    });
  }, [
    api,
    title,
    placement,
    description,
    type,
    icon,
    role,
    className,
    variant,
    showContent,
    fullWidth,
  ]);

  const closeNotification = useCallback(() => {
    api.destroy();
  }, [api]);

  useEffect(() => {
    configureContainer(); // Set up the container
  }, [configureContainer]);

  useEffect(() => {
    if (show) {
      openNotification();
    } else {
      closeNotification();
    }
  }, [show, openNotification, closeNotification]);

  return (
    <div
      ref={containerRef} // Attach the ref to this div
      className={clsx({
        "notification-wrapper": true,
      })}
    >
      {contextHolder}
    </div>
  );
};
