import { PageHeader } from "@components";
import { useTheme } from "@mui/material";
import { PageWrap } from "./customer-proposal-page.style";
import { Box, Button } from "@ntpkunity/controls";
import { CustomerProposal } from "@components/customer-proposal";
import Logo from "../../public/assets/images/bibby-financial-logo.svg";
import { useForm, FormProvider } from "react-hook-form"; // Import useForm and FormProvider

export const CustomerProposalPage = () => {
  const theme = useTheme();
  const formMethods = useForm();

  return (
    <FormProvider {...formMethods}>
      <PageWrap theme={theme} className="page-wrap">
        <Box theme={theme} className="main-header">
          <PageHeader
            title={
              <>
                <img src={Logo} alt="Logo" />
              </>
            }
          />
        </Box>

        <Box theme={theme} className="page-content" pt={3} pb={3}>
          <PageHeader
            title="Sample Proposal Name"
            actionArea={
              <>
                <Button theme={theme} primary text={"Submit"} />
              </>
            }
          />
          <Box theme={theme} mt={2}>
            <CustomerProposal />
          </Box>
        </Box>
      </PageWrap>
    </FormProvider>
  );
};
