import { useTheme } from '@mui/material'
import { Box } from '@ntpkunity/controls'
import { WidgetsProps } from './analytics-widget.props'
import { WidgetWrap } from './analytics-widget.style'
import { Stack } from '../../stack'

export const Widget = ({ title, actionArea, children }: WidgetsProps) => {
  const theme = useTheme()

  return (
    <WidgetWrap className="widget-wrap" theme={theme}>
      <Stack
        title={title}
        paddingXs={3}
        paddingMd={3}
        actionArea={<>{actionArea && <>{actionArea}</>}</>}
      >
        <Box theme={theme} className="widget-content">
          {children}
        </Box>
      </Stack>
    </WidgetWrap>
  )
}
