import { Button, Image } from "@src/components/atoms";
import shopBanner from "../../../../shared/assets/img/waiting-hub/shop/banner.jpg";
import shopBannerXl from "../../../../shared/assets/img/waiting-hub/shop/banner-xl.jpg";
import shop1 from "../../../../shared/assets/img/waiting-hub/shop/1.png";
import shop2 from "../../../../shared/assets/img/waiting-hub/shop/2.png";
import shop3 from "../../../../shared/assets/img/waiting-hub/shop/3.png";
import shop4 from "../../../../shared/assets/img/waiting-hub/shop/4.png";
import "./shop-now.scss";
import { Col, Row } from "antd";
import React from "react";

const shopImages = [
  { src: shop1, alt: "Shop 1" },
  { src: shop2, alt: "Shop 2" },
  { src: shop3, alt: "Shop 3" },
  { src: shop4, alt: "Shop 4" },
];

const categories = [
  { name: "Apparel", href: "/apparel" },
  { name: "Genuine Parts", href: "/genuine" },
  { name: "Accessories", href: "/accessories" },
];

export const ShopNow = () => {
  return (
    <div className="waiting-hub-shop-section">
      <div className="bmw-container">
        <div className="bmw-explore__inner">
          <div className="waiting-hub-shop__content">
            <ul className="waiting-hub-shop__link">
              {categories.map((category, index) => (
                <React.Fragment key={category.name}>
                  <li>
                    <a
                      className="text--label text-muted no-decoration"
                      href={category.href}
                    >
                      {category.name}
                    </a>
                  </li>
                  {index < categories.length - 1 && (
                    <li className="waiting-hub-shop__dot">•</li>
                  )}
                </React.Fragment>
              ))}
            </ul>
            <h1>Gear up for summer.</h1>
            <p className="text--body-1">
              The performance you expect, the style you want. Explore
              high-performing apparel, accessories, and gear from our summer
              collection.
            </p>
            <div className="waiting-hub-shop__cta">
              <Button
                type="default"
                htmlType={"button"}
                text="Shop now"
                fullWidth
              />
            </div>
          </div>
          <Row
            gutter={{ xs: 16, sm: 16, md: 24 }}
            className="waiting-hub-shop__gutter"
          >
            <Col xs={24}>
              <div className="d-none d-xxl-block">
                <Image
                  src={shopBanner}
                  alt="Explore Vehicle"
                  aspectRatio="3:1"
                />
              </div>
              <div className="d-none d-md-block d-xxl-none">
                <Image
                  src={shopBannerXl}
                  alt="Explore Vehicle"
                  aspectRatio="16:7"
                />
              </div>
              <div className="d-block d-md-none">
                <Image
                  src={shopBannerXl}
                  alt="Explore Vehicle"
                  aspectRatio="2:1"
                />
              </div>
            </Col>
            {shopImages.map((image, index) => (
              <Col key={index} xs={12} md={6}>
                <Image src={image.src} alt={image.alt} aspectRatio="1:1" />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};
