import { QueryClientProviderWrapper as QueryClientProvider } from "./application-query-client";
import { RouterProviderWrapper as RouterProvider } from "./application-router";
import { ThemeProvider } from "./application-ant-design";
import "@styles/main.scss";
import { ApplicationContextProvider } from "@src/context/app-context";
import { ModalProvider } from "react-modal-hook";

const Application = () => {
  return (
    <ThemeProvider>
      <QueryClientProvider>
        <ApplicationContextProvider>
          <ModalProvider>
            <RouterProvider />
          </ModalProvider>
        </ApplicationContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default Application;
