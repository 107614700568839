import { useTheme } from "@mui/material"
import {CarouselSlider, Image, Box } from "@ntpkunity/controls"
import { AssetDetailCarouselWrap} from "./asset-detail-carousel.style"
import { ResponsiveCarousel, type ResponsiveType } from "@src/components/ResponsiveCarousel/ResponsiveCarousel"
import kbbLogo from "@src/assets/images/kbb-logo.svg"
import { useSelector } from "react-redux"
import slide1 from "@src/assets/images/slide-1.svg"
import slide2 from "@src/assets/images/slide-2.svg"

export const responsive = ({ itemXl, itemLg, itemMd, itemSm, itemXs }): ResponsiveType => ({
    xl: {
      breakpoint: { max: 4000, min: 1920 },
      items: itemXl
    },
    lg: {
      breakpoint: { max: 1920, min: 1201 },
      items: itemLg
    },
    md: {
      breakpoint: { max: 1200, min: 992 },
      items: itemMd,
      partialVisibilityGutter: 16
    },
    sm: {
      breakpoint: { max: 991, min: 576 },
      items: itemSm,
      partialVisibilityGutter: 16
    },
    xs: {
      breakpoint: { max: 575, min: 0 },
      items: itemXs,
      partialVisibilityGutter: 16
    }
})

export const AssetDetailCarousel = () => {
  const theme = useTheme();
  const { assetDetails, loading, error } = useSelector((state: any) => state.assetDialog);

  const photoUrls = assetDetails?.order_asset?.photo_urls ?? [];
  const validImages = photoUrls.filter((photo) => photo?.location);

    return(
        <>
            <AssetDetailCarouselWrap theme={theme} className="asset-detail-carousel-wrap">
                <Box theme={theme} display="flex" justifyContent="center" width={140}>
                    <Image theme={theme} src={kbbLogo} alt={kbbLogo} />
                </Box>
                <ResponsiveCarousel
                    responsive={responsive({
                      itemXl: 3,
                      itemLg: 2.04,
                      itemMd: 2.04,
                      itemSm: 2.04,
                      itemXs: 1.03
                    })}
                    // removeArrowOnDeviceType={["itemXl", "itemLg", "itemMd", "itemSm", "itemXs"]}
                  >
            {validImages.map((photo, index) => (
              <Box theme={theme} key={index}>
                <Image theme={theme} src={photo.location} alt={`Image ${index + 1}`} />
              </Box>
            ))}
                </ResponsiveCarousel>
            </AssetDetailCarouselWrap>
            
        </>
    )
}