import { TableCell, TableRow, useTheme } from "@mui/material";
import { Box, DataTable } from "@ntpkunity/controls";
import { DataTableWrap } from "@styles/data-table-wrap";
import { DashboardTableProps } from "./dashboard-table.props";
import { useGetDashboardProposals, useGetDealers } from "@hooks/queries";
import {
  DashboardAnalyticsResponse,
  ManualStatusChangeData,
  TSortParams,
  StatusConfirmationData,
  TDashboardSearchParams,
  DiaryDialogData,
} from "@_types";
import { OrderStage, Roles, SortOrder, WorkQueueTabs } from "@helpers/enum";
import { useEffect, useMemo, useState } from "react";
import { QUEUE_TO_STATUS_MAP, DASHBOARD_QUEUE_STATUSES } from "@helpers/const";
import {
  ManualStatusChangeDialog,
  ViewConditionDialog,
  WorkQueueExport,
} from "../../work-queue";
import DashboardFilter from "./dashboard-filter/dashboard-filter.component";
import DashboardTableRow from "./dashboard-table-row/dashboard-table-row.component";
import { useDialogState, useSetupsSelector } from "@hooks";
import { StatusConfirmationDialog } from "@components/work-queue/status-confirmation-dialog/status-confirmation-dialog.component";
import { DiaryDialog } from "@components/diary/diary-dialog/diary-dialog";
import { TableRowSkeleton, MessageRow } from "@components";
import { Stack } from "@components/stack";

import {
  getEntityTypeDealer,
  getUserDataFromToken,
  getUserSettings,
} from "@helpers/utils";

const sortInitialState: TSortParams<keyof TDashboardSearchParams> = {
  sort_by: "updated_at",
  sort_order: SortOrder.DESC,
};

export const DashboardTable = ({
  searchParams,
  setSearchParams,
}: DashboardTableProps) => {
  const { data: dealerAssociation } = useGetDealers(getEntityTypeDealer());
  const userSettings = getUserSettings();
  const brokerId =
    userSettings?.role?.name == Roles.BROKER_USER
      ? getUserDataFromToken()?.user_id
      : null;
  const searchInitialState: TDashboardSearchParams = {
    name: "",
    finance_type: "",
    finance_amount: undefined,
    updated_at: "",
    order_stage: OrderStage.APPLICATION,
    customer_name: "",
    date_submitted: "",
    start_date: "",
    end_date: "",
    introducer_name: "",
    lender_name: "",
    dealer_name: "",
    updated_at_from: "",
    updated_at_to: "",
    dealer_id: dealerAssociation?.[0]?.id || null,
    broker_id: brokerId,
    statuses: QUEUE_TO_STATUS_MAP[WorkQueueTabs.DASHBOARD_PROPOSALS],
  };

  const [sortParams, setSortParams] =
    useState<TSortParams<keyof TDashboardSearchParams>>(sortInitialState);
  const {
    dialogOpen: statusDialogOpened,
    dialogData: statusDialogData,
    openDialog: openStatusDialog,
    closeDialog: closeStatusDialog,
  } = useDialogState<ManualStatusChangeData>();

  const {
    dialogOpen: confirmDialogOpened,
    dialogData: confirmDialogData,
    openDialog: openConfirmDialog,
    closeDialog: closeConfirmDialog,
  } = useDialogState<StatusConfirmationData>();

  const {
    dialogOpen: conditionDialogOpened,
    openDialog: openConditionDialog,
    closeDialog: closeConditionDialog,
  } = useDialogState();

  const {
    dialogOpen: diaryDialogOpened,
    dialogData: diaryDialogData,
    openDialog: openDiaryDialog,
    closeDialog: closeDiaryDialog,
  } = useDialogState<DiaryDialogData>();

  const financeTypes =
    useSetupsSelector(
      (setups) => setups.getFilteredFinanceTypes().financeTypes
    ) || [];

  const theme = useTheme();

  const { data, isLoading: proposalsLoading } = useGetDashboardProposals<
    TDashboardSearchParams,
    DashboardAnalyticsResponse
  >(searchInitialState, sortParams);

  const proposals = useMemo(() => {
    let {
      order_stage,
      updated_at_from,
      updated_at_to,
      dealer_id,
      broker_id,
      page_number,
      ...params
    } = searchParams || searchInitialState;
    params.date_submitted = params.date_submitted
      ? params.date_submitted.slice(0, 10)
      : params.date_submitted;
    params.start_date = params.start_date
      ? params.start_date.slice(0, 10)
      : params.start_date;
    params.end_date = params.end_date
      ? params.end_date.slice(0, 10)
      : params.end_date;
    params.updated_at = params.updated_at
      ? params.updated_at.slice(0, 10)
      : params.updated_at;

    return data?.filter((proposal) => {
      return Object.keys(params).every((key) =>
        key == "statuses"
          ? params.statuses.includes(proposal.status)
          : params[key]?.toString().trim()
          ? proposal[key]
              ?.toLowerCase()
              .includes(params[key]?.trim().toLowerCase())
          : true
      );
    });
  }, [searchParams, data]);

  const commonFilterParams = {
    searchParams,
    setSearchParams,
    sortParams,
    setSortParams,
  };

  useEffect(() => {
    setSearchParams(searchInitialState);
    setSortParams(sortInitialState);
  }, []);

  return (
    <>
      <Stack
        title={<>Track Proposals</>}
        paddingMd={3}
        paddingXs={3}
        actionArea={
          <>
            <WorkQueueExport />
          </>
        }
      >
        <Box theme={theme} mt={{ xs: 3 }}>
          <DataTableWrap theme={theme} className="table-pagination-button">
            <Box theme={theme} className="scroll">
              <Box className="scroll-hide spr-border" theme={theme} />
            </Box>
            <DataTable
              theme={theme}
              theadChildren={
                <>
                  <TableRow>
                    <TableCell>State</TableCell>
                    <TableCell>Proposal</TableCell>
                    <TableCell>Finance Type</TableCell>
                    <TableCell>Customer Name</TableCell>
                    <TableCell>Date Submitted</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Last Updated</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell className="action-cell fixed-cell" />
                  </TableRow>
                  {searchParams && (
                    <TableRow className="filters-row">
                      <TableCell>
                        <DashboardFilter
                          {...commonFilterParams}
                          searchKey="statuses"
                          dropDownList={DASHBOARD_QUEUE_STATUSES}
                        />
                      </TableCell>
                      <TableCell>
                        <DashboardFilter
                          {...commonFilterParams}
                          searchKey="name"
                        />
                      </TableCell>
                      <TableCell>
                        <DashboardFilter
                          {...commonFilterParams}
                          searchKey="finance_type"
                          dropDownList={[
                            {
                              text: "All",
                              value: "all",
                            },
                            ...financeTypes.map((item) => ({
                              text: item.name,
                              value: item.code,
                            })),
                          ]}
                        />
                      </TableCell>
                      <TableCell>
                        <DashboardFilter
                          {...commonFilterParams}
                          searchKey="customer_name"
                        />
                      </TableCell>
                      <TableCell>
                        <DashboardFilter
                          {...commonFilterParams}
                          searchKey="date_submitted"
                          placeholder="DD/MM/YYYY"
                        />
                      </TableCell>
                      <TableCell>
                        <DashboardFilter
                          {...commonFilterParams}
                          searchKey="finance_amount"
                          type="number"
                        />
                      </TableCell>
                      <TableCell>
                        <DashboardFilter
                          {...commonFilterParams}
                          searchKey="updated_at"
                          placeholder="DD/MM/YYYY"
                        />
                      </TableCell>
                      <TableCell>
                        <DashboardFilter
                          {...commonFilterParams}
                          searchKey="start_date"
                          placeholder="DD/MM/YYYY"
                        />
                      </TableCell>
                      <TableCell>
                        <DashboardFilter
                          {...commonFilterParams}
                          searchKey="end_date"
                          placeholder="DD/MM/YYYY"
                        />
                      </TableCell>
                      <TableCell className="action-cell fixed-cell" />
                    </TableRow>
                  )}
                </>
              }
              tbodyChildren={
                <>
                  {proposalsLoading ? (
                    <TableRowSkeleton noOfCells={9} />
                  ) : !proposals?.length ? (
                    <MessageRow columnSpan={10} message="No Proposals found." />
                  ) : (
                    proposals.map((proposal) => (
                      <DashboardTableRow
                        key={proposal.identifier}
                        proposal={proposal}
                        openConfirmDialog={openConfirmDialog}
                        openStatusDialog={openStatusDialog}
                        openConditionDialog={openConditionDialog}
                        openDiaryDialog={openDiaryDialog}
                      />
                    ))
                  )}
                </>
              }
            />
          </DataTableWrap>
        </Box>
      </Stack>
      <ManualStatusChangeDialog
        open={statusDialogOpened}
        closeDialog={closeStatusDialog}
        dialogData={statusDialogData}
        openConfirmDialog={openConfirmDialog}
      />
      <ViewConditionDialog
        open={conditionDialogOpened}
        closeDialog={closeConditionDialog}
      />
      <StatusConfirmationDialog
        dialogData={confirmDialogData}
        open={confirmDialogOpened}
        closeDialog={closeConfirmDialog}
        closeStatusDialog={closeStatusDialog}
      />
      <DiaryDialog
        dialogData={diaryDialogData}
        open={diaryDialogOpened}
        closeDialog={closeDiaryDialog}
      />
    </>
  );
};
