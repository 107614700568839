import { BuyerOrderInfo } from "@src/components/molecules";
import { Button } from "@src/components/atoms";
import "./buyers-order-completed.scss";

export const BuyersOrderCompleted = ({
  handleClick,
}: {
  handleClick: () => void;
}) => {
  return (
    <div className="offer-completed-container">
      <div className="select-offer-header-buyer">
        <div className="checkout-form-header">
          <h3 className="checkout-title checkout-step text--subsection-2 text-secondary">
            03
          </h3>
          <h3 className="checkout-title text--subsection-2">Buyer's order.</h3>
        </div>
        <Button
          htmlType={"button"}
          text="Download"
          onClick={handleClick}
          size="small"
        />
      </div>
      <BuyerOrderInfo isSigned />
    </div>
  );
};
