import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const DecisionHistoryWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.decision-history-wrap": {
    ".MuiButtonBase-root": {
      color: CustomColors.bodyColor,
      ".MuiButton-endIcon": {
        svg: {
          path: {
              stroke: "#6C6E7A"
          }
        }
      }
    }
  },
}));
