import { useTheme } from "@mui/material";
import { FinancialConfigTabsWrap } from "./config-tabs.style";
import { ScrollableTabs } from "@ntpkunity/controls";
import { FinancialConfigurationFields } from "@components";
import React from "react";
import { useSetupsSelector } from "@hooks/useGetSetups";

export const FinancialConfigurationTabs = ({
  financeType,
  setFinanceType,
}: {
  financeType: string;
  setFinanceType: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const theme = useTheme();
  const { data: financeTypes } = useSetupsSelector(
    (setups) => setups.financeTypes
  );
  const handleTabChange = (newTabIndex: number) => {
    const selectedConfig = financeTypes[newTabIndex];
    if (selectedConfig) {
      setFinanceType(selectedConfig.code);
    }
  };
  const tabItems = financeTypes?.map((config, index) => ({
    title: config?.name,
    content: (
      <FinancialConfigurationFields financeType={financeType} key={index} />
    ),
  }));

  return (
    <FinancialConfigTabsWrap
      theme={theme}
      className="financial-config-tabs-wrap"
    >
      <ScrollableTabs
        theme={theme}
        items={tabItems}
        scrollButtons={"auto"}
        defaultTabIndex={0}
        onChange={(_, newTabIndex: number) => handleTabChange(newTabIndex)}
      />
    </FinancialConfigTabsWrap>
  );
};
