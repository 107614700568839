import {
    FETCH_FINANCE_STRUCTURE_REQUEST,
    FETCH_FINANCE_STRUCTURE_SUCCESS,
    FETCH_FINANCE_STRUCTURE_FAILURE,
    UPDATE_FINANCE_STRUCTURE_REQUEST,
    UPDATE_FINANCE_STRUCTURE_SUCCESS,
    UPDATE_FINANCE_STRUCTURE_FAILURE,
} from "../actions/financeStructureActions";

const initialState = {
    data: {},
    updatedData: {},
    financeStructureRefId: null,
    loading: false,
    error: null,
};

const financeStructureReducer = (state = initialState, action): any => {
    switch (action.type) {
        case FETCH_FINANCE_STRUCTURE_REQUEST:
            return { ...state, loading: true, error: null, financeStructureRefId: action.payload };
        case FETCH_FINANCE_STRUCTURE_SUCCESS:
            return { ...state, loading: false, data: action.payload };
        case FETCH_FINANCE_STRUCTURE_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case UPDATE_FINANCE_STRUCTURE_REQUEST:
            return { ...state, loading: true, error: null };
        case UPDATE_FINANCE_STRUCTURE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: { ...state.data, ...action.payload },
                updatedData: { ...state.updatedData, ...action.payload },
            };

        case UPDATE_FINANCE_STRUCTURE_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default financeStructureReducer;
