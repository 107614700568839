import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { Input, Select, Button, ConfirmationDialog } from "@sharedComponents";
import { INewContractTermsPopUp } from "./AddNewContractTerms.type";
import { IContractTerms, ICreditRating, IFinanceTypeModel } from "Interface";
import { Controller, useForm } from "react-hook-form";
import {
  UseMutation_AddContractTerms,
  UseMutation_UpdateContractTerms,
} from "services";
import {
  ActionOptions,
  ValidationMessages,
  DialogMessages,
  QueryKeys,
} from "Enums";
import { Icon, MultiSelect, PersistentDrawer } from "@ntpkunity/controls";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { logout } from "@ntpkunity/controls-ums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { useQueryClient } from "react-query";

const AddNewContractTerms: FC<INewContractTermsPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  contractTermsData,
  enabledPin,
}) => {
  const theme = useTheme();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    reset,
    formState: { errors, isDirty },
    watch,
  } = useForm<IContractTerms>();
  const { actions } = useStoreContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [navigationState, setNavigationState] = useState<any>();
  const available_finance: Array<IFinanceTypeModel> = queryClient.getQueryData(
    QueryKeys.GET_ALL_FINANCE_TYPES
  );
  const { mutate: AddContractTerms } = UseMutation_AddContractTerms();
  const { mutate: UpdateContractTerms } = UseMutation_UpdateContractTerms(
    getValues("id")
  );

  useEffect(() => {
    if (actionType !== ActionOptions.ADD) {
      setValue("id", contractTermsData?.id);
    }
    setValue("code", contractTermsData?.code);
    setValue("term", contractTermsData?.term);
    setValue("finance_type", contractTermsData?.finance_type);
    setValue(
      "is_active",
      contractTermsData ? contractTermsData.is_active : true
    );
  }, [contractTermsData]);

  function onSubmit(data) {
    if (actionType === ActionOptions.ADD) {
      AddContractTerms(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error: any) => {
          const errorDetail = error?.response?.data;
          if (errorDetail?.detail?.code === "Code should be unique") {
            setError("code", { message: "Code should be unique" });
          }

          if (errorDetail?.detail?.value === "Value should be unique") {
            setError("term", { message: "Value should be unique" });
          }

          if (!errorDetail?.detail?.code && !errorDetail?.detail?.value) {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    } else if (actionType === ActionOptions.EDIT) {
      UpdateContractTerms(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error: any) => {
          const errorDetail = error?.response?.data;

          if (errorDetail?.detail?.code === "Code should be unique") {
            setError("code", { message: "Code should be unique" });
          }

          if (errorDetail?.detail?.value === "Value should be unique") {
            setError("term", { message: "Value should be unique" });
          }

          if (!errorDetail?.detail?.code && !errorDetail?.detail?.value) {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    }
  }

  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Controller
              name="code"
              control={control}
              rules={{
                required: ValidationMessages.CODE_REQUIRED,
                maxLength: {
                  value: 5,
                  message: ValidationMessages.CODE_MAX_LENGTH,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type here..."}
                  label={"Code"}
                  type="text"
                  value={value?.trimStart()}
                  onChange={onChange}
                  error={errors?.code?.message}
                />
              )}
            />
            <Controller
              name="term"
              control={control}
              rules={{
                required: ValidationMessages.VALUE_REQUIRED,
                maxLength: {
                  value: 3,
                  message: ValidationMessages.CONTRACT_TERM_VALUE_MAX_LENGTH,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  placeholder={"0"}
                  label={"Value"}
                  fullWidth={true}
                  type="number"
                  value={value}
                  onChange={onChange}
                  error={errors?.term?.message}
                />
              )}
            />
            <Controller
              name="finance_type"
              control={control}
              rules={{
                required: ValidationMessages.Finance_type_required,
              }}
              defaultValue={[]}
              render={({ field: { value, onChange } }) => (
                <MultiSelect
                  id="financeType"
                  name="finance_type"
                  theme={theme}
                  label={"Finance Type(s)"}
                  items={available_finance?.map((item) => {
                    return { text: item.name, value: item.name };
                  })}
                  value={value ?? []}
                  onChange={onChange}
                  selectError={errors?.finance_type?.message as string}
                />
              )}
            />
            <Controller
              name="is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  theme={theme}
                  label={"Status"}
                  placeholder={"Select"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </>
        }
        customFooter={
          <Button
            primary
            text="Save"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};

export default AddNewContractTerms;
