import { useQuery, UseQueryResult } from "react-query";
import { queryKeyFactory } from "@helpers/query";
import { getOrderWorkqueue } from "@services/dms.service";
import { TSortParams, TWorkQueueResponse } from "@_types";
import {
  getParamsWithoutPagination,
  getUserDataFromToken,
  getUserSettings,
} from "@helpers/utils";
import { Roles } from "@helpers/enum";

const useGetOrders = <
  SearchParams extends { [key: string]: any },
  ReturnType extends { [key: string]: any }
>(
  searchParams: SearchParams,
  sortParams: TSortParams<keyof SearchParams>,
  exportAllData: boolean = false // Default value for exportAllData is false
): UseQueryResult<TWorkQueueResponse<ReturnType>> => {
  const userSettings = getUserSettings();
  const userRoleName = userSettings?.role?.name;
  const isDealerUsers = !!(
    searchParams?.dealer_id &&
    [Roles.BROKER_DEALER_ADMIN, Roles.BROKER_DEALER_USER].includes(userRoleName)
  );
  const _brokerId =
    userRoleName == Roles.BROKER_USER ? getUserDataFromToken()?.user_id : null;
  const isBrokerUser = !!(searchParams.broker_id && _brokerId);
  const isBrokerAdmin = userRoleName == Roles.BROKER_ADMIN;

  const isEnabled = !!(isDealerUsers || isBrokerUser || isBrokerAdmin);
  return useQuery<TWorkQueueResponse<ReturnType>>({
    queryKey: queryKeyFactory.getOrders(
      searchParams,
      sortParams,
      exportAllData
    ),
    queryFn: async () => {
      const params = getParamsWithoutPagination(searchParams, exportAllData);
      return await getOrderWorkqueue(params, sortParams);
    },
    enabled: isEnabled,
  });
};

export default useGetOrders;
