import { Tabs as AntTab } from "antd";
import { TabProps } from "./tab.props";
import clsx from "clsx";
import "../../../styles/components/_tab.scss";

export const Tab = ({
  defaultActiveKey,
  items,
  onChange,
  mode = "regular",
  position = "start",
}: TabProps) => {
  return (
    <div
      className={clsx("tab-container", {
        "tab-condensed": mode === "condensed",
        "tab-start": position === "start",
        "tab-center": position === "center",
        "tab-end": position === "end",
      })}
    >
      <AntTab
        defaultActiveKey={defaultActiveKey}
        items={items}
        onChange={onChange}
      />
    </div>
  );
};

export default Tab;
