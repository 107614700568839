
import { useTheme } from "@mui/material";
import { Box, Button, Grid, Icon, Label } from "@ntpkunity/controls";
import { Alert } from "@src/components";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { BusinessRulesAlerts } from "../business-rules-alerts/business-rules.component"
import { AlertStatusText, BasicStack } from "@src/components/dashboard"
import { Stack } from "@src/components/stack";
import { useState } from "react";

export const BusinessRules = () => {
  const theme = useTheme();
  const [open, setOpened] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpened(true);
  };

  const handleCloseDialog = () => {
    setOpened(false);
  };

  return (
    <>
      <Stack
        title={"Business Rules"}
        paddingXs={2}
        paddingMd={2}>
        <Box theme={theme} display={'flex'} flexDirection={'column'} gap={1} mt={2}>
            <BusinessRulesAlerts />
        </Box>
      </Stack>
    </>
  );
};
