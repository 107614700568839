import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const ActivitiesAndNotesSlidePopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.activities-and-notes-slide-popup-wrap": {
    position: 'fixed',
    top: 0,
    right: '-100%',
    bottom: 0,
    width: 'calc(100% - 56px)',
    transition: '0.8s',
    zIndex: 2,
    "&.open": {
      transform: 'translateX(64px) translateX(-100%)'
    },
    ".activities-and-notes": {
      padding: 16,
      borderRadius: '24px 0 0 24px',
      backgroundColor: theme.palette.common.white,
      width: 'calc(100% - 120px)',
      height: '100%',
      ".audit-trail-wrap, .my-notes-wrap": {
        ".audit-trail-stack, .my-notes-stack": {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'baseline',
          gap: 16,
          ".stack-header": {
            ".title": {
              ".MuiTypography-root": {
                fontSize: theme.typography.body1.fontSize,
                fontWeight: theme.customVariables.fontWeightSemiBold,
              }
            },
            ".action-area": {
              ".MuiButtonBase-root": {
                fontSize: theme.typography.body2.fontSize,
                color: theme.palette.grey[600],
                ".MuiButton-endIcon": {
                  marginLeft: 4,
                },
                "@media(min-width: 1599.95px)": {
                  fontSize: theme.typography.body1.fontSize,
                  fontWeight: theme.typography.body1.fontWeight,
                }
              }
            }
          },
          "&.w-100": {
            ".stack-header": {
              width: "100%"
            }
          },
        },
        ".multi-functions": {
          display: "flex",
          alignItems: "center",
          gap: 4,
          ".datepicker": {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            ".date-range": {
              backgroundColor: CustomColors.lightGrey,
              padding: "8px 16px",
              maxWidth: 120,
              borderRadius: 8,
              "&.focused": {
                backgroundColor: CustomColors.lightGrey,
                ".rmdp-container": {
                  input: {
                    backgroundColor: CustomColors.lightGrey,
                    "&::placeholder": {
                      color: theme.palette.grey[900],
                      opacity: 1
                    }
                  },
                },
              },
              ".rmdp-container": {
                ".ep-arrow": {
                  "+": {
                    div: {
                      zIndex: "1201 !important",
                    },
                  },
                },
                input: {
                  backgroundColor: CustomColors.lightGrey,
                  fontWeight: theme.typography.fontWeightMedium,
                  height: "auto",
                  padding: 0,
                  paddingLeft: 24,
                  border: "none",
                  cursor: 'pointer',
                  "&::placeholder": {
                    color: '#474A59',
                    opacity: 1
                  }
                },
                "&:nth-child(3)": {
                  zIndex: 1201,
                }
              },
              ".calendar-icon": {
                left: 16,
                top: 8,
                path: {
                  stroke: '#6C6E7A',
                },
              },
            },
            ".MuiButtonBase-root": {
              position: 'absolute',
              right: 16,
              top: 9.5,
              "&.btn.icon-only": {
                padding: 0
              },
              svg: {
                path: {
                  stroke: '#6C6E7A',
                }
              }
            }
          },
          ".MuiButtonBase-root": {
            "&.icon-light.btn.with-icon.icon-only": {
              svg: {
                path: {
                  stroke: '#B5B7BD'
                }
              }
            }
          }
        },
      }
    }
  },
  ".btn-dark-anim": {
    position: 'fixed',
    right: '-32px',
    top: 0,
    bottom: 0,
    zIndex: 2,
    "&.icon-only": {
      padding: '0px !important',
      svg: {
        width: 'auto !important',
        height: 'auto !important',
        path: {
          fill: theme.palette.common.black,
          stroke: theme.palette.common.white + "!important"
        }
      }
    },
    "&.hide": {
      right: '-1px'
    }
  },

  ".btn-light-anim": {
    borderRadius: 0,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    "&.icon-only": {
      padding: '0px !important',
      svg: {
        width: 'auto !important',
        height: 'auto !important',
        path: {
          '&:first-child': {
            stroke: 'unset !important',
          },
          '&:last-child': {
            stroke: '#808080'
          }
        }
      }
    },
    "&.show": {
      left: -31.5,
    },
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
}));

export const SlideOutWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.slide-out": {
    backgroundColor: theme.palette.common.black,
    position: 'fixed',
    right: '-60px',
    left: '100%',
    top: 0,
    bottom: 0,
    zIndex: 1,
    opacity: '0.8',
    transition: 'transform 0.5s ease-out',
    display: 'flex',
    "&.show": {
      left: '0px'
    },
  }
}));