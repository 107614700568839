import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

const ChatsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.chats-wrap': {
    '&.sender-chat': {
      marginRight: 40
    },
    '.message-text': {
      maxWidth: '1040px',
      marginLeft: 'auto',
      display: 'block',
      width: 'fit-content',
      backgroundColor: 'rgba(85, 40, 237, 0.05)',
      padding: '16px',
      borderRadius: '16px 16px 0 16px',
      marginBottom: 8,
      color: theme.palette.grey[600]
    },
    '.with-avatar': {
      position: 'relative',
      marginBottom: 8,
      '.u-avator': {
        position: 'absolute',
        right: -40,
        bottom: 0
      },
      '.message-text': {
        marginBottom: 0
      }
    },
    '.chat-details': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      color: theme.palette.grey[600],
      '.text-dark': {
        color: theme.palette.grey[900],
        fontWeight: theme.customVariables.fontWeightSemiBold
      },
      '.separator': {
        position: 'relative',
        width: 3,
        height: 3,
        margin: '0 8px',
        ':before': {
          content: '""',
          position: 'absolute',
          width: 3,
          height: 3,
          borderRadius: '50%',
          backgroundColor: '#cccccc'
        }
      }
    }
  }
}))

export default ChatsWrap
