import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const RiskAssessmentDialogDataWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.risk-assesment-dialog-data-wrap": {
    ".basic-list-wrap": {
      ".list": {
        padding: "0 8px",
        ".MuiTypography-root": {
          "&.title": {
            width: '100%'
          }
        }
      }
    },
  },
}));
