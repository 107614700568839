import "./checkout-footer.scss";

export const CheckoutFooter = () => {
  return (
    <footer className="checkout-footer-container">
      <div className="checkout-footer-content">
        <p className="checkout-footer-copyright text--label">
          &copy; BMW of Manhattan 2024
        </p>
        <nav className="checkout-footer-links" aria-label="Footer Navigation">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="checkout-footer-link text--disclaimer-link">
            Lorem ipsum
          </a>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="checkout-footer-link text--disclaimer-link">
            Lorem ipsum
          </a>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="checkout-footer-link text--disclaimer-link">
            Lorem ipsum
          </a>
        </nav>
      </div>
    </footer>
  );
};
