import { Accessories } from "@src/components/organisms/build-your-deal/accessories/accessories";
import { BaseLayout } from "@src/layouts";

export const AccessoriesPage = () => {
  return (
    <BaseLayout>
      <Accessories />
    </BaseLayout>
  );
};
