import { DMS_GET_LEADS } from '@helpers/endpoints'
import Http from '@helpers/http-api'
import { getAllSettingsFromLocalStorage } from '@helpers/methods'
import { useQuery } from 'react-query'

const settings = getAllSettingsFromLocalStorage()

const mapFrontendFiltersToBackendParams = (filters: Record<string, string>) => {
  return {
    email_address: filters.emailAddressFilter,
    year_make_model_trim: filters.yearMakeModelTrimFilter,
    lead_status: filters.leadStatusFilter,
    lead_id: filters.leadIdFilter,
    first_name: filters.firstNameFilter,
    last_name: filters.lastNameFilter,
    contact_number: filters.contactNumberFilter,
    preferred_communication: filters.preferredCommunicationFilter,
    vin: filters.vinFilter,
    stock: filters.stockFilter,
    created_at: filters.createdAtFilter,
    last_updated_at: filters.lastUpdatedAtFilter,
    last_synced_status: filters.lastSyncedStatusFilter,
    lead_source: filters.leadSourceFilter,
    deal_id: filters.dealIdFilter,
    deal_status: filters.dealStatusFilter,
    dealer_name: filters.dealerNameFilter
  }
}

export const useGetLeadDetails = (
  refId: string,
  pageNumber: number,
  pageSize: number,
  filters: Record<string, string> = {}
) => {
  return useQuery<any, Error>(
    ['leadDetails', refId, pageNumber, pageSize, filters],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })

      let url = `${DMS_GET_LEADS}?`

      url += `page=${pageNumber}&page_size=${pageSize}${refId ? `&dealer_id=${refId}` : ``}`

      const backendFilters = mapFrontendFiltersToBackendParams(filters)

      const validFilters = Object.fromEntries(
        Object.entries(backendFilters)?.filter(([_, value]) => value?.trim() !== '')
      )

      const filterParams = new URLSearchParams(validFilters)

      if (filterParams?.toString()) {
        url += `&${filterParams?.toString()}`
      }

      const response = await apiService.get<any>(url)
      return response
    },
    {
      enabled: settings?.role?.name === 'Admin' || !!refId,
      keepPreviousData: false
    }
  )
}
