import { useTheme } from '@mui/material'
import { PageContent } from './analytics.syle'
import { LayoutWithSideNav } from '@styles/layout'
import { AnalyticsTabs } from '@components'

export const AnalyticsPage = () => {
  const theme = useTheme()
  return (
    <LayoutWithSideNav theme={theme}>
      <PageContent theme={theme} className="page-content" pt={3} pb={3}>
        <AnalyticsTabs />
      </PageContent>
    </LayoutWithSideNav>
  )
}
