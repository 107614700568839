import { useMutation, useQuery, useQueryClient } from "react-query";
import { useStoreContext } from "Stores/SetupStore/Store";
import { IDealerAppointment, IWorkSchedule } from "Interface";
import { QueryKeys, ToastMessages } from "Enums";
import { ConfigurationAPI } from "services";

interface WorkScheduleBody {
  working_days: {
    dealer_code: string;
    id: number;
    workday: string;
    is_active?: boolean;
    day_end_time?: any;
    day_start_time?: any;
  };
}

export const useGetDealerWorkingDaysDetails = (dealer_code: string) => {
  return useQuery(
    [QueryKeys.GET_DEALER_CONFIGURED_WORKING_DAYS, dealer_code],
    () => {
      return ConfigurationAPI.get(`/dealer/${dealer_code}/workday`).then(
        (response) => response.data
      );
    },
    {
      enabled: !!dealer_code,
    }
  );
};
export const useGetDealerHolidayDetails = (dealer_code: string) => {
  return useQuery(
    [QueryKeys.GET_DEALER_CONFIGURED_HOLIDAYS, dealer_code],
    () => {
      return ConfigurationAPI.get(
        `/dealer/holiday/dealer-code/${dealer_code}`
      ).then((response) => response.data);
    },
    {
      enabled: !!dealer_code,
    }
  );
};

export const useMutation_AddDealerHolidays = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IDealerAppointment>(
    (body) => {
      return ConfigurationAPI.post("/dealer/holiday", body);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_DEALER_CONFIGURED_HOLIDAYS);
      },
    }
  );
};

export const useUpdateWorkSchdule = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IWorkSchedule, Error, WorkScheduleBody>(
    (body) => {
      return ConfigurationAPI.patch(
        `/dealer/${body?.working_days?.[0]?.dealer_code}/workday`,
        body
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_DEALER_CONFIGURED_WORKING_DAYS
        );
      },
    }
  );
};
