import {
  Box,
  Input,
  Button,
  Icon,
  DatePicker,
  Select,
} from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { WorkQueueFilterProps } from "./work-queue-filter.props";
import { SortOrder } from "@helpers/enum";
import { convertDateToISOString } from "@helpers/utils";
import { DEFAULT_PAGE_NUMBER } from "@helpers/const";

const WorkQueueFilter = <SearchParams extends { [key: string]: any }>({
  searchParams,
  setSearchParams,
  searchKey,
  placeholder,
  sortParams,
  setSortParams,
  type = "text",
  dropDownList,
}: WorkQueueFilterProps<SearchParams>) => {
  const theme = useTheme();
  const handleSortParamsChange = (key: keyof SearchParams) => {
    if (!key || key !== sortParams.sort_by) {
      setSortParams({ sort_by: key, sort_order: SortOrder.ASC });
      return;
    }
    if (sortParams.sort_order === SortOrder.DESC) {
      setSortParams({ sort_by: null, sort_order: SortOrder.ASC });
      return;
    }
    setSortParams({
      ...sortParams,
      sort_order: SortOrder.DESC,
    });
  };

  const getArrowIcon = (
    key: keyof SearchParams
  ): "DescendingIcon" | "AscendingIcon" | "ArrowUpDown" => {
    if (sortParams.sort_by == key) {
      return (
        {
          [SortOrder.ASC]: "AscendingIcon",
          [SortOrder.DESC]: "DescendingIcon",
        } as const
      )[sortParams.sort_order];
    }
    return "ArrowUpDown";
  };

  return (
    <Box theme={theme} className="table-filter">
      {["updated_at", "date_submitted", "start_date", "end_date"].includes(
        searchKey as string
      ) ? (
        <DatePicker
          theme={theme}
          value={searchParams[searchKey]}
          onChange={(e: Date) => {
            setSearchParams({
              ...searchParams,
              page_number: DEFAULT_PAGE_NUMBER,
              [searchKey]: convertDateToISOString(e),
            });
          }}
          inputFormat="DD/MM/YYYY"
          uppercaseLabel
          disablePortal={false}
          sameDateSelection={true}
        />
      ) : ["statuses", "finance_type"].includes(searchKey as string) ? (
        <Select
          theme={theme}
          items={dropDownList}
          disablePortal={false}
          value={
            (Array.isArray(searchParams[searchKey]) &&
              searchParams[searchKey].length === dropDownList.length - 1) ||
            !searchParams[searchKey]
              ? "all"
              : Array.isArray(searchParams[searchKey])
              ? searchParams[searchKey][0]
              : searchParams[searchKey]
          }
          disabled={!dropDownList.length}
          onChange={(e) => {
            const value = e.target.value;
            if (value === "all") {
              setSearchParams({
                ...searchParams,
                page_number: DEFAULT_PAGE_NUMBER,
                [searchKey]: Array.isArray(searchParams[searchKey])
                  ? dropDownList
                      .map((item) => {
                        if (item.value !== "all") return item.value;
                      })
                      .filter(Boolean)
                  : "",
              });
              return;
            }
            setSearchParams({
              ...searchParams,
              page_number: DEFAULT_PAGE_NUMBER,
              [searchKey]: Array.isArray(searchParams[searchKey])
                ? [e.target.value]
                : e.target.value,
            });
          }}
        />
      ) : (
        <Input
          theme={theme}
          fullWidth={true}
          type={type}
          startAdornment=""
          endAdornment=""
          id=""
          placeholder={!!placeholder ? placeholder : "Search"}
          value={searchParams[searchKey]}
          onChange={(value: string) => {
            setSearchParams({
              ...searchParams,
              page_number: DEFAULT_PAGE_NUMBER,
              [searchKey]: value,
            });
          }}
        />
      )}

      <Button
        theme={theme}
        defaultBtn
        iconText={<Icon name={getArrowIcon(searchKey)} />}
        onClick={() => handleSortParamsChange(searchKey)}
      ></Button>
    </Box>
  );
};

export default WorkQueueFilter;
