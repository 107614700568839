import styled from "@emotion/styled";

export const ImageWrap = styled("div")({
  "&.aspect-ratio-image-wrap": {
    ".aspect-ratio__aspect-ratio-img": {
      width: "100%",
      objectFit: "cover",
      objectPosition: "center center",
      height: "100%",
      display: "block",
      "&.aspect-ratio__aspect-ratio-img-contain": {
        objectFit: "contain",
      },
    },
    "&.aspect-ratio--one-one": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "1/1",
      },
    },
    "&.aspect-ratio--one-two": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "1/2",
      },
    },
    "&.aspect-ratio--two-one": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "2/1",
      },
    },
    "&.aspect-ratio--two-three": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "2/3",
      },
    },
    "&.aspect-ratio--three-one": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "3/1",
      },
    },
    "&.aspect-ratio--three-two": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "3/2",
      },
    },
    "&.aspect-ratio--three-four": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "3/4",
      },
    },
    "&.aspect-ratio--four-one": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "4/1",
      },
    },
    "&.aspect-ratio--four-three": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "4/3",
      },
    },
    "&.aspect-ratio--four-five": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "4/5",
      },
    },
    "&.aspect-ratio--five-two": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "5/2",
      },
    },
    "&.aspect-ratio--five-four": {
      "aspect-ratio__aspect-ratio-img": {
        aspectRatio: "5/4",
      },
    },
    "&.aspect-ratio--six-seven": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "6/7",
      },
    },
    "&.aspect-ratio--eight-three": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "8/3",
      },
    },
    "&.aspect-ratio--eight-five": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "8/5",
      },
    },
    "&.aspect-ratio--nine-eight": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "9/8",
      },
    },
    "&.aspect-ratio--nine-sixteen": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "9/16",
      },
    },
    "&.aspect-ratio--nine-twentyone": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "9/21",
      },
    },
    "&.aspect-ratio--ten-sixteen": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "10/16",
      },
    },
    "&.aspect-ratio--fourteen-three": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "14/3",
      },
    },
    "&.aspect-ratio--sixteen-five": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "16/5",
      },
    },
    "&.aspect-ratio--sixteen-seven": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "16/7",
      },
    },
    "&.aspect-ratio--sixteen-nine": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "16/9",
      },
    },
    "&.aspect-ratio--sixteen-ten": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "16/10",
      },
    },
    "&.aspect-ratio--twentyone-nine": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "21/9",
      },
    },
    "&.aspect-ratio--one-onesix": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "1/1.6",
      },
    },
    "&.aspect-ratio--onesix-one": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "1.6/1",
      },
    },
    "&.aspect-ratio--one-onefour": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "1/1.4",
      },
    },
    "&.aspect-ratio--onefour-one": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "1.4/1",
      },
    },
    "&.aspect-ratio--onetwo-one": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "1.29/1",
      },
    },
    "&.aspect-ratio--one-onetwo": {
      ".aspect-ratio__aspect-ratio-img": {
        aspectRatio: "1/1.29",
      },
    },
  },
});
