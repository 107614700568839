import { ConfirmationDialog } from "@sharedComponents";
import { LenderSelectionRule } from "../../../Types/lenderSelectionRules";
import {
  UseQuery_GetActiveDealers,
  UseQuery_getAllLender,
} from "../../../services";
import { TableCell, TableRow, useTheme } from "@mui/material";
import { Button, Checkbox, Icon, Menu, Skeleton } from "@ntpkunity/controls";
import { FC, useState } from "react";
import { DialogMessages } from "../../../Enums";

type TrProps = {
  rules: LenderSelectionRule;
  deleteRule: (id: number) => void;
  setKey: React.Dispatch<React.SetStateAction<number>>;
  addNew: (_event: any, isEdit?: boolean) => void;
  isDataFetched: boolean;
};

type CustomCellProps = {
  ruleItem: string[];
};

export const Tr: FC<TrProps> = ({
  rules,
  deleteRule,
  setKey,
  addNew,
  isDataFetched,
}) => {
  const [confirmationDialog, setConfirmationDialog] = useState<boolean>(false);
  const { data: dealers } = UseQuery_GetActiveDealers();
  const { data: lenders } = UseQuery_getAllLender();
  const theme = useTheme();

  return (
    <>
      <TableRow className="child-tr">
        <TableCell className="checkbox-cell fixed-cell">
          <Checkbox label="" />
        </TableCell>
        <CustomCell ruleItem={rules.product_types} />
        <CustomCell ruleItem={rules.finance_types} />
        <CustomCell ruleItem={rules.finance_amount_range} />
        <CustomCell ruleItem={rules.customer_types} />
        <CustomCell ruleItem={rules.asset_categories} />
        <CustomCell ruleItem={rules.asset_types} />
        <TableCell className="indent-cell">
          {rules.dealer_ids.map(
            (dealer: number, index: number) =>
              `${dealers?.find((item) => item.id === dealer)?.dealer_name}${
                index < rules.dealer_ids.length - 1 ? ", " : ""
              }`
          )}
        </TableCell>
        <TableCell className="indent-cell">
          {lenders?.find((item) => item.id === rules.lender_id).name ?? (
            <Skeleton theme={theme} height={14} />
          )}
        </TableCell>
        <TableCell className="action-cell fixed-cell">
          <Menu
            theme={theme}
            options={[
              {
                optionText: "Edit",
                optionValue: "edit",
                optionkey: rules.id.toString(),
                disabled: !isDataFetched,
              },
              {
                optionText: "Delete",
                optionValue: "delete",
                optionkey: rules.id.toString(),
              },
            ]}
            handleOptionClick={(event, key, value) => {
              switch (value) {
                case "delete":
                  setKey(Number(key));
                  setConfirmationDialog(true);
                  break;
                case "edit":
                  addNew(event, true);
                  setKey(Number(key));
              }
            }}
            render={(onMenuSelection) => (
              <Button
                defaultBtn
                iconText={<Icon name="MoreIcon" />}
                onClick={onMenuSelection}
              />
            )}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        openPopUp={confirmationDialog}
        onConfirm={deleteRule}
        setPopUpState={setConfirmationDialog}
        confirmationTitle={DialogMessages.confirmationTitle}
        confirmationText={DialogMessages.confirmationText}
        primaryBtnText={DialogMessages.deleteBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
    </>
  );
};

const CustomCell: FC<CustomCellProps> = ({ ruleItem }) => {
  return (
    <>
      <TableCell className="indent-cell">
        {ruleItem.map(
          (item: string, index: number) =>
            `${item}${index < ruleItem.length - 1 ? ", " : ""}`
        )}
      </TableCell>
    </>
  );
};
