import {useState, useEffect, useContext} from 'react';
import {jwtDecode, JwtPayload} from "jwt-decode";
import {AppContext, changeUserLoggedInState, updateHeaderFooter} from "@app/context-provider";
import {useWorkFlowMutation} from "./workflow-management";
import { Navigation } from '@constants'
import {useNavigate} from "react-router-dom";
import {useQueryClient} from "react-query";
import { logout } from '@ntpkunity/controls-ums'
import { useParams } from "react-router"
import {handleTokenValidation} from "../helpers/methods";



export const useAuthentication = () => {
  const appState = useContext(AppContext);
  const [authenticationStatus, setAuthenticationStatus] = useState(false);
  const token = localStorage.getItem('access_token');
  const { mutate: fetch_workflow, isLoading:fetchLoading } = useWorkFlowMutation()
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { '*': pathParam } = useParams<{ '*': string }>()
  const extractedValue = pathParam.split('/')[1]


  useEffect(() => {
    // Function to check if token is present
    const checkToken = () => {
      setAuthenticationStatus(!!token);
    };
    if(token && appState.state.tenant_id) {
      handleTokenValidation(token,appState,setAuthenticationStatus,navigate,extractedValue,queryClient,logout)
      }
    checkToken()
    // Set up event listener for localStorage changes
    window.addEventListener('storage', checkToken)

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('storage', checkToken)
    };
  }, [token,appState.state.tenant_id])

  return { authenticationStatus }
}