import { useEffect, useReducer } from "react";

import { BaseLayout } from "@src/layouts";
import "./finance-offer-page.scss";

import { Steps } from "@src/components/atoms";
import { VehicleCard, FixedVehicleCard } from "@src/components/molecules";

import {
  SelectOfferCompleted,
  SelectOffer,
} from "@src/components/organisms/credit-application/finance-offers/01-select-offer";
import {
  InsuranceInformation,
  InsuranceInformationCompleted,
} from "@src/components/organisms/credit-application/finance-offers/02-insurance-information";
import {
  BuyersOrderCompleted,
  BuyersOrder,
} from "@src/components/organisms/credit-application/finance-offers/03-buyers-order";
import {
  DepositCompleted,
  Deposit,
} from "@src/components/organisms/credit-application/finance-offers/04-deposit";
import { Col, Row } from "antd";
import { useAppContext } from "@src/context/app-context";
import { orderInsurance } from "@src/context/app-actions";
import { useGetOrderInfo } from "@src/hooks";
import { useUrlParamsForOrderInfo } from "@src/hooks/useUrlParamsForOrderInfo";
import { useFetchAndStore } from "@src/hooks/useFetchAndStore";

interface initialState {
  index: number;
  isSelectOfferFilled: boolean;
  isInsuranceInfoFilled: boolean;
  isBuyersOrderFilled: boolean;
  isDepositFilled: boolean;
}

const initialState: initialState = {
  index: 0,
  isSelectOfferFilled: false,
  isInsuranceInfoFilled: false,
  isBuyersOrderFilled: false,
  isDepositFilled: false,
};

interface ActionType {
  MOVE_TO_NEXT_STEP: string;
  TOGGLE_SELECTED_OFFER: string;
  TOGGLE_INSURANCE_INFO: string;
  TOGGLE_BUYERS_ORDER: string;
  TOGGLE_DEPOSIT: string;
}

const ACTION_TYPE: ActionType = {
  MOVE_TO_NEXT_STEP: "move to next step",
  TOGGLE_SELECTED_OFFER: "toggle selected offer",
  TOGGLE_INSURANCE_INFO: "toggle insurance info",
  TOGGLE_BUYERS_ORDER: "toggle buyers order",
  TOGGLE_DEPOSIT: "toggle deposit",
};

const reducer = (state: initialState, action: { type: string }) => {
  switch (action.type) {
    case ACTION_TYPE.MOVE_TO_NEXT_STEP:
      if (state.index === 3) {
        return initialState;
      }
      return { ...state, index: state.index + 1 };
    case ACTION_TYPE.TOGGLE_SELECTED_OFFER:
      return { ...state, isSelectOfferFilled: !state.isSelectOfferFilled };
    case ACTION_TYPE.TOGGLE_INSURANCE_INFO:
      return { ...state, isInsuranceInfoFilled: !state.isInsuranceInfoFilled };
    case ACTION_TYPE.TOGGLE_BUYERS_ORDER:
      return { ...state, isBuyersOrderFilled: !state.isBuyersOrderFilled };
    case ACTION_TYPE.TOGGLE_DEPOSIT:
      return { ...state, isDepositFilled: !state.isDepositFilled };
    default:
      return state;
  }
};

const FinanceOfferPage = () => {
  useUrlParamsForOrderInfo();
  useFetchAndStore();
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    state: {
      order: { reference_number },
    },
  } = useAppContext();

  return (
    <BaseLayout>
      <div className="bmw-container">
        <Row gutter={{ xs: 16, sm: 16, md: 24 }}>
          <Col xs={24} sm={24} md={24} lg={16}>
            <div className="address-left-content">
              <h3 className="text--h3">Select your offer.</h3>
              {state.index >= 0 && !state.isSelectOfferFilled && (
                <SelectOffer
                  handleClick={() => {
                    dispatch({ type: ACTION_TYPE.TOGGLE_SELECTED_OFFER });
                    dispatch({ type: ACTION_TYPE.MOVE_TO_NEXT_STEP });
                  }}
                />
              )}
              {state.isSelectOfferFilled && (
                <SelectOfferCompleted
                  handleClick={() => {
                    dispatch({ type: ACTION_TYPE.TOGGLE_SELECTED_OFFER });
                  }}
                />
              )}

              {state.index >= 1 &&
                !state.isInsuranceInfoFilled &&
                reference_number && (
                  <InsuranceInformation
                    handleClick={() => {
                      dispatch({ type: ACTION_TYPE.TOGGLE_INSURANCE_INFO });
                      dispatch({ type: ACTION_TYPE.MOVE_TO_NEXT_STEP });
                    }}
                  />
                )}
              {state.isInsuranceInfoFilled && (
                <InsuranceInformationCompleted
                  handleClick={() => {
                    dispatch({ type: ACTION_TYPE.TOGGLE_INSURANCE_INFO });
                  }}
                />
              )}

              {state.index >= 2 && !state.isBuyersOrderFilled && (
                <BuyersOrder
                  handleClick={() => {
                    dispatch({ type: ACTION_TYPE.TOGGLE_BUYERS_ORDER });
                    dispatch({ type: ACTION_TYPE.MOVE_TO_NEXT_STEP });
                  }}
                />
              )}
              {state.isBuyersOrderFilled && (
                <BuyersOrderCompleted
                  handleClick={() => {
                    dispatch({ type: ACTION_TYPE.TOGGLE_BUYERS_ORDER });
                  }}
                />
              )}

              {state.index >= 3 && !state.isDepositFilled && (
                <Deposit
                  handleClick={() => {
                    dispatch({ type: ACTION_TYPE.TOGGLE_DEPOSIT });
                    dispatch({ type: ACTION_TYPE.MOVE_TO_NEXT_STEP });
                  }}
                />
              )}
              {state.isDepositFilled && (
                <DepositCompleted
                  hasBankDetails
                  handleClick={() => {
                    dispatch({ type: ACTION_TYPE.TOGGLE_DEPOSIT });
                  }}
                />
              )}

              <Steps financeOfferSteps />
              <p className="disclaimer text--disclaimer">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est
                laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            <div className="address-right-content">
              <div className="address-right-content__inner">
                <VehicleCard
                  hasHeader
                  vehicleFinanceImg
                  cardPaymentInfo
                  hasVehicleFinancing
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <FixedVehicleCard />
    </BaseLayout>
  );
};

export default FinanceOfferPage;
