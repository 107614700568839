export const SalePriceAccordionContent = () => {
  return (
    <>
      <div className="pricing-details-accordion-content">
        <div className="pricing-details-accordion-content__box">
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Total Financed Amount
              <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
            </span>
            <span className="pricing-details__item__price text--body-2">
              $44,850
            </span>
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Financing Charges
              <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
            </span>
            <span className="pricing-details__item__price text--body-2">
              $2,670
            </span>
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Down Payment
              <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
            </span>
            <span className="pricing-details__item__price text--body-2">
              $2,670
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
