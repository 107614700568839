import { FC } from 'react'
import { useTheme } from '@mui/material'
import ChatsWrap from './SendingChats.style'
import { Avatar, Box, Icon, Typography } from '@ntpkunity/controls'

const SendingChat: FC = () => {
  const theme = useTheme()
  return (
    <ChatsWrap theme={theme} className="chats-wrap sender-chat">
      <Typography theme={theme} component="p" variant="body2" className="message-text">
        Ipsum sed neque varius nec varius sit ac netus metus. Dolor laoreet viverra dis eu nunc a
        adipiscing.
      </Typography>
      <Box theme={theme} className="with-avatar">
        <Typography theme={theme} component="p" variant="body2" className="message-text">
          Ipsum sed neque varius nec varius sit ac netus metus. Dolor laoreet viverra dis eu nunc a
          adipiscing. Mus egestas faucibus hendrerit malesuada.
        </Typography>
        <Avatar theme={theme}>SK</Avatar>
      </Box>
      <Box theme={theme} className="chat-details">
        <Typography
          theme={theme}
          component="span"
          variant="caption"
          className="text-dark"
          children="You"
        />
        <Box theme={theme} className="separator"></Box>
        <Typography theme={theme} component="span" variant="caption" children="00:00 AM" />
        <Box theme={theme} className="separator"></Box>
        <Typography theme={theme} component="span" variant="caption" mr={1}>
          <>Via:</> In-App Chat
        </Typography>
        <Icon name="TickIcon" />
      </Box>
    </ChatsWrap>
  )
}

export default SendingChat
