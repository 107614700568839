import "./accessories-list-card.scss";

import { AccessoriesListCardProps } from "./accessories-list-card.props";
import clsx from "clsx";
import { Button, Image } from "@src/components/atoms";

export const AccessoriesListCard = ({
  className,
  image,
  title,
  subTitle,
  termsInfoNode,
  priceNode,
  totalPrice,
  isDisabledCard,
  hasCheckbox,
  actionsNode,
  feedbackLable,
}: AccessoriesListCardProps) => {
  return (
    <div
      className={clsx(className, {
        "accessories--card": true,
        is_row_disabled: isDisabledCard,
      })}
    >
      <div className="accessories--card-flexible">
        <div className="accessories--card-image">
          {image && <Image src={image} alt="card-image" aspectRatio="1:1" />}
        </div>

        <div className="accessories--card-content">
          <div className="accessories--card-right__flexible">
            <div className="accessories--card-right__text">
              {termsInfoNode && termsInfoNode}
              <p className="text--body-2">{subTitle}</p>
            </div>
            <div>
              {hasCheckbox && (
                <div className="accessories--card-checkbox">{hasCheckbox}</div>
              )}
            </div>
          </div>

          <div className="accessories--card__price">
            <div>{priceNode}</div>
            {totalPrice && <p className="text--disclaimer">{totalPrice}</p>}
          </div>

          <div className="accessories--card__price__total">
            <p className="text--disclaimer">Total $X,XXX</p>
          </div>

          {actionsNode && (
            <div className="accessories--card_actions">{actionsNode}</div>
          )}
        </div>
      </div>
      {feedbackLable && (
        <div className="accessories--card_feedback">{feedbackLable}</div>
      )}
    </div>
  );
};
