import { BuyerOrderInfo } from "@src/components/molecules";
import "./buyers-order.scss";
import { Button } from "@src/components/atoms";

export const BuyersOrder = ({ handleClick }: { handleClick: () => void }) => {
  return (
    <div className="select-offer-container">
      <div className="offer-header">
        <div className="checkout-form-header">
          <h3 className="checkout-title checkout-step text--subsection-2 text-secondary">
            03
          </h3>
          <h3 className="checkout-title text--subsection-2">Buyer's order.</h3>
        </div>
      </div>
      <BuyerOrderInfo />
      <div className="buyer-content">
        <div className="buyer-content__download-btn">
          <Button
            className=""
            htmlType={"button"}
            text="Download"
            fixed
            linkButton
            withIcon
            dataIcon="download"
          />
        </div>
        <p className="text--body-2">
          By signing a buyer's order, you are committing to purchase the
          intended vehicle.
        </p>
      </div>
      <div className="offer-cta-wrapper-order">
        <Button
          type="default"
          htmlType={"button"}
          text="Agree & continue"
          fixed
          onClick={handleClick}
          fullWidth
        />
      </div>
    </div>
  );
};
