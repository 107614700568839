import React, { useState } from "react";
import { useTheme } from "@mui/material";
import { Box, Dialog, Icon, Grid, Typography, Checkbox, Input, Button } from "@ntpkunity/controls";
import { Popup } from "@src/components/Dialog/dialog.component";
import { FinanceStructureModifyDialogWrap } from "./finance-structure-modify-popup-dialog.style";
import { Stack } from "@src/components/stack";
import { useSelector } from "react-redux";

export const FinanceStructureModifyDialog = ({ open, closeDialog }) => {
  const theme = useTheme();

  const { data } = useSelector((state: any) => state?.financeStructure);

  const [showUnderwriterColumn, setShowUnderwriterColumn] = useState(false);
  const [underwriterValues, setUnderwriterValues] = useState({});

  const fields = [
    { label: "Finance Type", key: "finance_type" },
    { label: "Finance Amount", key: "finance_amount" },
    { label: "Estimated Monthly Payment", key: "estimated_monthly_payment" },
    { label: "Asset Cost", key: "asset_cost" },
    { label: "Down Payment", key: "down_payment_value" },
    { label: "Tax on Asset Cost", key: "tax_amount" },
    { label: "Tax Treatment", key: "tax_treatment" },
    { label: "Net Trade-in Value", key: "net_trade_in_value" },
    { label: "Fees, charge & discount", key: "fees_charge_discount" },
    { label: "Rebate", key: "rebate" },
    { label: "Discount", key: "discount" },
    { label: "GAP", key: "gap" },
    { label: "Warranty", key: "warranty" },
    { label: "Insurance", key: "insurance" },
    { label: "Annual Admin Fees", key: "annual_admin_fee" },
    { label: "Documentation Fee", key: "documentation_fee" },
    { label: "Option to Purchase Fee", key: "option_to_purchase_fee" },
    { label: "Backend Fees", key: "backend_fees" },
    { label: "Total Backend Fees", key: "total_backend_fees" },
    { label: "Dealer Fees", key: "dealer_fees" },
  ];

  const getValueOrDefault = (key) => {
    const value = data?.[key];
    return value != null && value !== "" ? value : "N/A";
  };

  const handleAddNewStructure = (e) => {
    e.preventDefault();
    setShowUnderwriterColumn(true);

    // Pre-fill values for "Underwriter Recommendation" column
    const prefilledValues = {};
    fields.forEach((field) => {
      prefilledValues[field.key] = getValueOrDefault(field.key);
    });
    setUnderwriterValues(prefilledValues);
  };

  const formatNumber = (value: number | string): string => {
    if (typeof value === "number") {
      return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(value);
    }
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(parsedValue);
    }
    return value || "N/A";
  };

  return (
    <Popup>
      <Dialog
        theme={theme}
        size={"lg"}
        onCloseDialog={closeDialog}
        open={open}
        title={"Add/Modify Structure"}
        disablePortal
        noFooter
      >
        <FinanceStructureModifyDialogWrap theme={theme} className="finance-structure-modify-popup-wrap">
          <Stack
            bgLight
            paddingMd={1.5}
            paddingXs={1.5}
            title={"Finance Structure"}
            actionArea={
              <Box theme={theme} display={"flex"} alignItems={"center"}>
                <Icon name="AddIcon" className="text-primary" />
                <a href="#" className="text-primary" onClick={handleAddNewStructure}>
                  Add New Structure
                </a>
              </Box>
            }
          ></Stack>
          <Stack paddingMd={2} paddingXs={2}>
            <Grid theme={theme} container rowSpacing={0} columnSpacing={2} sx={{ mt: 0 }}>
              <Grid item xs={3.5} theme={theme}>
                <Box theme={theme} className="left-side">
                  {fields.map((field, index) => (
                    <Typography
                      key={index}
                      theme={theme}
                      variant="body2"
                      component={"span"}
                      className="list text-body2 fw-medium"
                    >
                      {field.label}
                    </Typography>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={8.5} theme={theme}>
                <Box theme={theme} className="right-side">
                  <Grid theme={theme} container rowSpacing={0} columnSpacing={2} sx={{ mt: 0 }}>
                    {/* Checkbox Row */}
                    <Grid item xs={4} theme={theme}>
                      <Box theme={theme} display={"flex"} alignItems={"center"} justifyContent={"center"} mb={1}>
                        <Checkbox theme={theme} label={"Requested"} checkBoxChecked={!!data} />
                      </Box>
                      {fields.map((field, index) => (
                        <Input
                          key={index}
                          theme={theme}
                          type="text"
                          value={getValueOrDefault(field.key)}
                          startAdornment={
                            typeof data?.[field.key] === "number" ? "$" : undefined
                          }
                        />
                      ))}
                    </Grid>
                    {showUnderwriterColumn && (
                      <Grid item xs={4} theme={theme}>
                        <Box theme={theme} display={"flex"} alignItems={"center"} justifyContent={"center"} mb={1}>
                          <Checkbox theme={theme} label={"Underwriter Recommendation"} checkBoxChecked={!!data} />
                        </Box>
                        {fields.map((field, index) => (
                          <Input
                            key={index}
                            theme={theme}
                            type="text"
                            value={underwriterValues[field.key]}
                            startAdornment={
                              typeof underwriterValues[field.key] === "number" ? "$" : undefined
                            }
                          />
                        ))}
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} theme={theme} textAlign={"center"} pt={2}>
              <Button theme={theme} primary text={"Save Changes"} />
            </Grid>
          </Stack>
        </FinanceStructureModifyDialogWrap>
      </Dialog>
    </Popup>
  );
};

export default FinanceStructureModifyDialog;