import React, { FC, useState, useEffect } from 'react'
import { TablePagination, useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Icon, IGridProps, DataTable, unityTheme } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'
import {
  useGetInventoryData,
  useSendReferralInvite,
  useUpdateVehicleByVin,
  useUpdateVehicleDetailByVin
} from '@apis/inventory-management.service'
import { getUser, validation } from '@helpers/methods'
import { EMAIL_REGEX } from '@helpers/constants'
import { Vehicle_Type, Lisiting_Status, Daily_Update, Status } from '@helpers/enums'

import {
  Typography,
  Grid,
  Checkbox,
  Button,
  Input,
  Box,
  Switch,
  PersistentDrawer,
  Menu,
  MultiSelect,
  DrawerWrap
} from 'components'
import { IInventory, IVehicle } from '@models'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useStoreContext } from '@store/storeContext'
import ChevronDown from '@public/assets/icons/chevron-down'
import AttachedOptions from '@app/inventory-options/options-listing-dialog'

const DataTableWrap = styled(
  Grid,
  {}
)<Partial<IGridProps>>(({ theme }) => ({
  position: 'relative',
  '.scroll-hide': {
    backgroundColor: theme.palette.common.white,
    borderBottom: '1px solid' + theme.palette.divider,
    position: 'absolute',
    width: 7,
    height: 97,
    right: 1,
    top: 0.5,
    display: 'block',
    borderRadius: '0 2px 0 0',
    span: {
      display: 'block',
      backgroundColor: theme.palette.divider,
      width: 7,
      height: 1,
      position: 'absolute',
      top: 48
    }
  },
  '.u-table-wrap': {
    '.u-table': {
      '.img-cell': {
        '.prd-img': {
          borderRadius: theme.shape.borderRadius
        }
      }
    }
  },
  '.add-option': {
    display: 'inline-block',
    Button: {
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.fontSize,
      padding: 0,
      '&:hover, &:focus': {
        backgroundColor: 'transparent',
        textDecoration: 'underline'
      },
      '.MuiTouchRipple-root': {
        display: 'none'
      },
      svg: {
        height: 20,
        width: 20,
        float: 'left',
        margin: '0 3px 0 0'
      }
    }
  },
  '.MuiTablePagination-root': {
    color: theme.palette.grey[600],
    fontSize: theme.typography.htmlFontSize,
    fontFamily: theme.typography.fontFamily
  },
  '.MuiTablePagination-select:focus': {
    backgroundColor: 'transparent'
  },
  '.MuiTablePagination-selectIcon': {
    top: 'calc(35% - 0.5em)',
    left: 24
  }
}))

const PageHeader = styled(
  Grid,
  {}
)<Partial<IGridProps>>(({ theme }) => ({
  '&.page-header': {
    padding: '24px 0',
    '.btn-primary': {
      marginLeft: 16,
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    }
  }
}))
interface IFormInputs {
  first_name: string
  last_name: string
  email: string
}
interface IFilters {
  makeModelTrimYear?: string
  stockNumber?: string
  vin?: string
  listingStatus?: boolean[]
  status?: string[]
  assetCondition?: string[]
  mileage?: number
  msrp?: number
  internetPrice?: number
  dailyUpdate?: boolean[]
}
const InventoryManagement: FC = () => {
  const theme = useTheme()
  const { states, actions } = useStoreContext()
  const [popUpState, setPopUpState] = useState(false)
  const [editableCell, setEditableCell] = useState<number>(-1)
  const [selectedVin, setSelectedVin] = useState('')
  const [editedInternetPrice, setEditedInternetPrice] = useState(-1)
  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [searchFilters, setSearchFilters] = useState<IFilters>({})
  const { mutate: getInventory } = useGetInventoryData()
  const { mutate: saveReferralInvite } = useSendReferralInvite()
  const { mutate: updateVehicleDetail } = useUpdateVehicleDetailByVin()
  const { mutate: updateVehicle } = useUpdateVehicleByVin()
  const dealerData = getUser()

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value)
    setPageNumber(0)
  }

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<IFormInputs>()
  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    await saveReferralInvite(
      {
        ...data,
        vin: selectedVin,
        dealer_code: dealerData.dealer_code
      },
      {
        onSuccess() {
          actions.setToast({
            toastMessage: 'Invite Sent Successfully',
            toastState: true
          })
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      }
    )
    reset({ first_name: undefined, last_name: undefined, email: undefined })
    setPopUpState(false)
  }
  const handleSelection = (_event: string, key: string, _value: string) => {
    switch (key) {
      case 'referral':
        setPopUpState(true)
        return
      default:
        return
    }
  }
  const updateVehicleStatuses = async (vehicleDetail: any, valueIdentifier: string) => {
    switch (valueIdentifier) {
      case 'dailyUpdate':
        await updateVehicleDetail(vehicleDetail, {
          onSuccess() {
            actions.setToast({
              toastMessage: 'Record Updated Successfully',
              toastState: true
            })
          }
        })
        return
      case 'listingStatus':
        await updateVehicleDetail(vehicleDetail, {
          onSuccess() {
            actions.setToast({
              toastMessage: 'Record Updated Successfully',
              toastState: true
            })
          }
        })
        return
    }
  }
  const updateVehicleInternetPrice = async (id: number, vehicle: IVehicle) => {
    await updateVehicle(
      { id: id, vehicle },
      {
        onSuccess() {
          actions.setToast({
            toastMessage: 'Record Updated Successfully',
            toastState: true
          })
        }
      }
    )
    return
  }

  useEffect(() => {
    let filterData = ``
    if (searchFilters) {
      filterData =
        filterData + (searchFilters.stockNumber ? `&stock_number=${searchFilters.stockNumber}` : '')
      filterData = filterData + (searchFilters.vin ? `&vin=${searchFilters.vin}` : '')
      filterData = filterData + (searchFilters.mileage ? `&mileage=${searchFilters.mileage}` : '')
      filterData = filterData + (searchFilters.msrp ? `&msrp=${searchFilters.msrp}` : '')
      filterData =
        filterData +
        (searchFilters.internetPrice ? `&internet_price=${searchFilters.internetPrice}` : '')
      searchFilters.dailyUpdate?.map((dailyUpdate) => {
        filterData = filterData + `&daily_update=${dailyUpdate}`
      })
      searchFilters.listingStatus?.map((listingStatus) => {
        filterData = filterData + `&listing_status=${listingStatus}`
      })
      searchFilters.status?.map((status) => {
        filterData = filterData + `&vehicle_status=${status}`
      })
      searchFilters.assetCondition?.map((assetCondition) => {
        filterData = filterData + `&asset_condition=${assetCondition}`
      })
    }
    getInventory({
      data: `dealer_code=${1368}&page_number=${1}&page_size=${10000}`
    })
  }, [pageNumber, pageSize, searchFilters])

  const [openOptionDialog, setopenOptionDialog] = React.useState(false)
  const [vehicleObject, setvehicleObject] = React.useState<any>(null)
  const handleClickProductOpen = () => {
    setopenOptionDialog(true)
  }
  return (
    <DrawerWrap open={popUpState}>
      <LayoutWithSideNav theme={theme}>
        <PageHeader className="page-header" theme={theme} container item spacing={2}>
          <Grid item xs={12} sm={6} lg={7} md={6}>
            <Typography variant="h2" component="h2" theme={theme}>
              Inventory Management
            </Typography>
          </Grid>
          <Grid item xs={12} lg={5} sm={6} md={6} textAlign="right" display={'none'}>
            <Button secondary text="Bulk Update" />
            <Button primary text="+ Add New Vehicle" />
          </Grid>
        </PageHeader>
        <PersistentDrawer
          title={'Send Invite'}
          openPopUp={popUpState}
          setPopUpState={setPopUpState}
          theme={theme}
          customFooter={
            <Button
              primary
              type="submit"
              text="Send Invite"
              fullWidth
              onClick={handleSubmit(onSubmit)}
            />
          }
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="first_name"
                  control={control}
                  defaultValue={''}
                  rules={validation('First Name', true)}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      label={'First Name'}
                      type="text"
                      id="first_name"
                      {...field}
                      error={errors?.first_name?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="last_name"
                  control={control}
                  defaultValue={''}
                  rules={validation('Last Name', true)}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      label={'Last Name'}
                      type="text"
                      id="last_name"
                      {...field}
                      error={errors?.last_name?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={''}
                  rules={validation('Email', true, true, EMAIL_REGEX, 'Valid Email is Required.')}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      label={'Email Address'}
                      type="text"
                      id="email"
                      fullWidth
                      {...field}
                      error={errors?.email?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </PersistentDrawer>
        <DataTableWrap>
          <Box className="scroll-hide">
            <span />
          </Box>
          <DataTable
            theme={unityTheme}
            variant="basic"
            sx={{ mb: 3 }}
            theadChildren={
              <>
                <TableRow>
                  <TableCell className="checkbox-cell">
                    <Checkbox label=""></Checkbox>
                  </TableCell>
                  <TableCell className="img-cell">
                    <img src={require('../public/assets/images/unknown.svg')} alt="Car" />
                  </TableCell>
                  <TableCell>Year/Make/Model/Trim</TableCell>
                  <TableCell>Stock Number</TableCell>
                  <TableCell>VIN</TableCell>
                  <TableCell>Listing Status</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Asset Condition</TableCell>
                  <TableCell>Add-Ons</TableCell>
                  <TableCell>Mileage</TableCell>
                  <TableCell>MSRP</TableCell>
                  <TableCell>Seling Price</TableCell>
                  <TableCell>Daily Update</TableCell>
                  <TableCell className="action-cell fixed-cell"></TableCell>
                </TableRow>
                <TableRow className="filters-row">
                  <TableCell className="checkbox-cell"></TableCell>
                  <TableCell className="img-cell"></TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={''}
                        type="text"
                        dynamic={false}
                      />
                      <Button defaultBtn iconText={<Icon name="IconFilter" />} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={''}
                        type="text"
                        dynamic={false}
                        onBlur={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            stockNumber: e.target.value
                          })
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                        onClick={(_e) => {}}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={''}
                        type="text"
                        dynamic={false}
                        onBlur={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            vin: e.target.value
                          })
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                        onClick={(_e) => {}}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <MultiSelect
                        theme={theme}
                        label={''}
                        placeholder={'Select'}
                        value={searchFilters.listingStatus || []}
                        items={[
                          { text: 'Listed', value: true },
                          { text: 'Not Listed', value: false }
                        ]}
                        sxProps={{ m: 0, width: '100%' }}
                        onChange={(e: any) => {
                          setSearchFilters({
                            ...searchFilters,
                            listingStatus: e.target.value
                          })
                        }}
                        renderValue={() =>
                          searchFilters.listingStatus?.map((item) => item).join(', ')
                        }
                      ></MultiSelect>
                      <Button defaultBtn iconText={<Icon name="IconFilter" />} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <MultiSelect
                        theme={theme}
                        label={''}
                        placeholder={'Select'}
                        value={searchFilters.status || []}
                        items={Object.entries(Status).map((item) => ({
                          text: item[1],
                          value: item[0]
                        }))}
                        sxProps={{ m: 0, width: '100%' }}
                        onChange={(e: any) => {
                          setSearchFilters({
                            ...searchFilters,
                            status: e.target.value
                          })
                        }}
                        renderValue={() => searchFilters.status?.map((item) => item).join(', ')}
                      ></MultiSelect>
                      <Button defaultBtn iconText={<Icon name="IconFilter" />} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <MultiSelect
                        theme={theme}
                        label={''}
                        placeholder={'Select'}
                        value={searchFilters.assetCondition || []}
                        items={Object.keys(Vehicle_Type)
                          .filter((value) => isNaN(Number(value)) === false)
                          .map((key: any) => ({
                            value: key,
                            text: Vehicle_Type[key]
                          }))}
                        sxProps={{ m: 0, width: '100%' }}
                        onChange={(e: any) => {
                          setSearchFilters({
                            ...searchFilters,
                            assetCondition: e.target.value
                          })
                        }}
                        renderValue={() =>
                          searchFilters.assetCondition?.map((item) => item).join(', ')
                        }
                      ></MultiSelect>
                      <Button defaultBtn iconText={<Icon name="IconFilter" />} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input theme={theme} fullWidth={true} placeholder={''} type="text"></Input>
                      <Button defaultBtn iconText={<Icon name="IconFilter" />} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={''}
                        type="number"
                        dynamic={false}
                        onBlur={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            mileage: e.target.value
                          })
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                        onClick={(_e) => {}}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={''}
                        type="number"
                        dynamic={false}
                        onBlur={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            msrp: e.target.value
                          })
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                        onClick={(_e) => {}}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={''}
                        type="number"
                        dynamic={false}
                        onBlur={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            internetPrice: e.target.value
                          })
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                        onClick={(_e) => {}}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <MultiSelect
                        theme={theme}
                        label={''}
                        placeholder={'Select'}
                        value={searchFilters.dailyUpdate || []}
                        items={[
                          { text: 'Enabled', value: true },
                          { text: 'Disabled', value: false }
                        ]}
                        sxProps={{ m: 0, width: '100%' }}
                        onChange={(e: any) => {
                          setSearchFilters({
                            ...searchFilters,
                            dailyUpdate: e.target.value
                          })
                        }}
                        renderValue={() =>
                          searchFilters.dailyUpdate?.map((item) => item).join(', ')
                        }
                      ></MultiSelect>
                      <Button defaultBtn iconText={<Icon name="IconFilter" />} />
                    </Box>
                  </TableCell>
                  <TableCell className="action-cell fixed-cell" />
                </TableRow>
              </>
            }
            tbodyChildren={
              <>
                {states.inventoryData.result?.map((vehicleDetail: IInventory, index: number) => (
                  <TableRow key={index} className="child-tr">
                    <TableCell className="checkbox-cell">
                      <Checkbox label=""></Checkbox>
                    </TableCell>
                    <TableCell className="img-cell">
                      <img
                        className="prd-img"
                        src={vehicleDetail?.vehicle?.photo_urls[0].toString()}
                        alt="Car"
                      />
                    </TableCell>
                    <TableCell>
                      {vehicleDetail?.vehicle?.year} {vehicleDetail?.vehicle?.make}{' '}
                      {vehicleDetail?.vehicle?.model} {vehicleDetail?.vehicle?.trim_description}
                    </TableCell>
                    <TableCell>{vehicleDetail?.vehicle?.stock_number}</TableCell>
                    <TableCell>{vehicleDetail?.vehicle?.vin}</TableCell>
                    <TableCell>
                      <Switch
                        theme={theme}
                        varient={'basic'}
                        label={Lisiting_Status[Number(vehicleDetail.listing_status)]}
                        switchEnabled={vehicleDetail?.listing_status}
                        onChange={(event) => {
                          updateVehicleStatuses(
                            {
                              ...vehicleDetail,
                              listing_status: event.target.checked
                            },
                            'listingStatus'
                          )
                          actions.updateInventoryRow({
                            ...vehicleDetail,
                            listing_status: event.target.checked
                          })
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <span className="status in-contract" />
                      {Status[vehicleDetail?.vehicle_status as keyof typeof Status]}
                    </TableCell>
                    <TableCell> {Vehicle_Type[vehicleDetail?.vehicle?.type]}</TableCell>
                    <TableCell>
                      <Box theme={theme} className="add-option">
                        {vehicleDetail.dealer_option_descriptions ? (
                          <Button
                            className="title"
                            text={<>{vehicleDetail?.dealer_option_descriptions}</>}
                            onClick={(_e: any) => {
                              setvehicleObject(vehicleDetail)
                              handleClickProductOpen()
                            }}
                          />
                        ) : (
                          <Button
                            className="add"
                            text={
                              <>
                                <Icon name="AddIcon" /> Add
                              </>
                            }
                            onClick={(_e: any) => {
                              setvehicleObject(vehicleDetail)
                              handleClickProductOpen()
                            }}
                          />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>{vehicleDetail?.vehicle?.mileage.toLocaleString()}</TableCell>
                    <TableCell align="right">
                      <Box theme={theme} sx={{ mr: 4.3 }}>
                        ${vehicleDetail?.vehicle?.msrp.toLocaleString()}
                      </Box>
                    </TableCell>
                    <TableCell className="editable-cell">
                      {editableCell === index ? (
                        <Box theme={theme} className="cell-content" justifyContent={'flex-end'}>
                          <Input
                            type={'text'}
                            fullWidth
                            startAdornment={'$'}
                            theme={theme}
                            id={`editInternetPrice${index}`}
                            dynamic={false}
                            value={vehicleDetail?.vehicle?.internet_price}
                            onBlur={(e) => setEditedInternetPrice(e.target.value)}
                          />
                          <Button
                            defaultBtn
                            className="btn-tick"
                            iconText={<Icon name="TickIcon" />}
                            onClick={(_e) => {
                              updateVehicleInternetPrice(vehicleDetail.vehicle.id, {
                                ...vehicleDetail.vehicle,
                                internet_price: parseFloat(editedInternetPrice.toString())
                              })
                              actions.updateInventoryRow({
                                ...vehicleDetail,
                                vehicle: {
                                  ...vehicleDetail.vehicle,
                                  internet_price: parseFloat(editedInternetPrice.toString())
                                }
                              })
                              setEditableCell(-1)
                              setEditedInternetPrice(-1)
                            }}
                          />
                          <Button
                            defaultBtn
                            className="btn-close"
                            iconText={
                              <Icon
                                name="CloseBlack"
                                onClick={(_e) => {
                                  setEditableCell(-1)
                                  setEditedInternetPrice(-1)
                                }}
                              />
                            }
                          />
                        </Box>
                      ) : (
                        <Box theme={theme} className="cell-content" justifyContent={'flex-end'}>
                          <span>${vehicleDetail?.vehicle?.internet_price.toLocaleString()} </span>
                          <Button
                            defaultBtn
                            iconText={
                              <Icon
                                name="EditIcon"
                                onClick={(_e) => {
                                  setEditedInternetPrice(vehicleDetail?.vehicle?.internet_price)
                                  setEditableCell(index)
                                }}
                              />
                            }
                          />
                        </Box>
                      )}
                    </TableCell>
                    <TableCell>
                      <Switch
                        theme={theme}
                        varient={'basic'}
                        label={Daily_Update[Number(vehicleDetail?.daily_update)]}
                        switchEnabled={vehicleDetail?.daily_update}
                        onChange={(event) => {
                          updateVehicleStatuses(
                            {
                              ...vehicleDetail,
                              daily_update: event.target.checked
                            },
                            'dailyUpdate'
                          )
                          actions.updateInventoryRow({
                            ...vehicleDetail,
                            daily_update: event.target.checked
                          })
                        }}
                      />
                    </TableCell>
                    <TableCell className="action-cell fixed-cell">
                      <Menu
                        theme={theme}
                        options={[
                          {
                            optionText: 'Send for Referral',
                            optionKey: 'referral',
                            optionValue: 'referral'
                          }
                        ]}
                        handleOptionClick={(_event, key, _value) => {
                          handleSelection(_event, key, _value)
                          setSelectedVin(vehicleDetail.vin)
                        }}
                        render={(onMenuSelection) => (
                          <Button
                            defaultBtn
                            iconText={<Icon name="MoreIcon" />}
                            onClick={onMenuSelection}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            }
          />
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={states.inventoryData.totalResults}
            rowsPerPage={pageSize}
            page={pageNumber}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            SelectProps={{
              IconComponent: ChevronDown
            }}
          />
        </DataTableWrap>
        {openOptionDialog && (
          <AttachedOptions
            openPopup={openOptionDialog}
            vehicleData={vehicleObject}
            setopenOptionDialog={setopenOptionDialog}
          ></AttachedOptions>
        )}
      </LayoutWithSideNav>
    </DrawerWrap>
  )
}
export default InventoryManagement
