export enum OrderStatus {
    COMPLETED = "Completed",
    DRAFT = "Draft",
    CANCELLED = "Cancelled",
    APPROVED = "Approved",
    DECLINED = "Declined",
    EXPIRED= "Expired",
    SUBMITTED = "Submitted",
    CONDITIONED= "Conditioned",
    ReSubmitted = "Re Submitted",
    PendingConfirmation = "Pending Confirmation",
    TradeInInspection = "Trade-In Inspection",
    AssetInspectionPending = "Asset Inspection pending",
    PendingTradeInConfirmation = "Pending Trade-In Confirmation",
    Withdrawn = "Withdrawn",
    REVIEWED= 'Reviewed',
    FULLY_RECEIVED = 'Fully Received',
    APPOINTMENT_SCHEDULED = 'Appointment Scheduled'
}

