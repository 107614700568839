import { AppError } from "single-spa";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import {
  DMS_BASE_URL,
  CALCULATE_ORDER_QUOTATION,
  CALCULATE_NFA,
  GET_ALL_PROGRAMS,
  CONFIG_BASE_URL,
} from "../constants/routes";
import { http } from "../utilities";
import { getDownPaymentHelper } from "@src/helpers";
import { GETALLPROGRAMSPAYLOAD } from "@src/types";

export const useGetAllPrograms = (): {
  mutate: UseMutateFunction<{}, AppError, any>;
  error: string | undefined;
  isPending: boolean;
} => {
  const { mutate, error, isPending } = useMutation<
    {},
    AppError,
    GETALLPROGRAMSPAYLOAD
  >({
    mutationFn: async (body: any) => {
      let programData: any = [];
      try {
        programData = await http(CONFIG_BASE_URL).post(GET_ALL_PROGRAMS, body);
      } catch (error) {
        console.error("pricing error:", error);
      }

      if (programData.length > 0) {
        let down_payment = 0,
          minimum_down_payment = 0,
          maximum_down_payment = 0;
        if (Array.isArray(programData[0].downpayment_chart)) {
          down_payment = getDownPaymentHelper(
            programData[0].downpayment_chart[0].default_down_payment,
            body.internetPrice
          );
          minimum_down_payment = getDownPaymentHelper(
            programData[0].downpayment_chart[0].minimum_down_payment,
            body.internetPrice
          );
          maximum_down_payment = getDownPaymentHelper(
            programData[0].downpayment_chart[0].maximum_down_payment,
            body.internetPrice
          );
        } else if ("default_down_payment" in programData[0].downpayment_chart) {
          down_payment = getDownPaymentHelper(
            programData[0]?.downpayment_chart?.minimum_down_payment,
            body.internetPrice
          );
          minimum_down_payment = getDownPaymentHelper(
            programData[0].downpayment_chart.minimum_down_payment,
            body.internetPrice
          );
          maximum_down_payment = getDownPaymentHelper(
            programData[0].downpayment_chart.maximum_down_payment,
            body.internetPrice
          );
        }
        return {
          ...programData[0],
          downPayment: down_payment ?? 0,
          minDownPayment: minimum_down_payment ?? 0,
          maxDownPayment: maximum_down_payment ?? 0,
          asset_rv_amount:
            programData[0]?.rv_balloon_value ??
            programData[0]?.rv_chart?.[0]?.rv_value,
        };
      } else {
        return programData;
      }
    },
  });
  return { mutate, error: error?.message, isPending };
};

export const useCalculateNfa = (): {
  isPending: boolean;
  error: string | undefined;
  mutate: UseMutateFunction<{ net_financed_amount: number }, AppError, any>;
} => {
  const { mutate, isPending, error } = useMutation<any, AppError, any>({
    mutationFn: async (body: any) => {
      return http(DMS_BASE_URL).post(`${CALCULATE_NFA}`, body);
    },
  });
  return { mutate, isPending, error: error?.message };
};

export const useCalculateOrderQuotation = (): {
  mutate: UseMutateFunction<any, AppError, any>;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutate, isPending, error } = useMutation<any, AppError, any>({
    mutationFn: async (body: any) => {
      return http(DMS_BASE_URL).post(`${CALCULATE_ORDER_QUOTATION}`, body);
    },
  });
  return { mutate, isPending, error: error?.message };
};
