import "./pick-up-banner.scss";

const pickUpItems = [
  {
    icon: "driving_license",
    text: "A valid driver's license for verification.",
  },
  {
    icon: "document",
    text: "Any supporting documents that were not uploaded during online Checkout.",
  },

  {
    icon: "credit_card",
    text: "Your Due-at-Signing payment if not yet paid.",
  },
];

export const PickUpBanner = () => {
  return (
    <div className="bmw-container">
      <div className="pick-up-banner">
        <h3 className="text--h3">What do I need to bring to pickup?</h3>
        <ul>
          {pickUpItems.map((item, index) => (
            <li key={index}>
              <span
                className="bmw-icon bmw-icon--before data-icon"
                data-icon={item.icon}
                role="presentation"
              ></span>
              <p className="text--body-2">{item.text}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
