import { Image } from "@src/components/atoms";
import googlePlay from "../../../../shared/assets/img/waiting-hub/google-play.png";
import appStore from "../../../../shared/assets/img/waiting-hub/app-store.png";
import appMb from "../../../../shared/assets/img/waiting-hub/bmw-app/bg-xs.png";
import "./my-bmw-app.scss";

export const MyBmwApp = () => {
  return (
    <div className="my-bmw-app-section">
      <div className="d-md-none">
        <Image src={appMb} alt="Explore Vehicle" aspectRatio="1:1" />
      </div>
      <div className="bmw-container">
        <div className="my-bmw-app__inner">
          <div>
            <p className="text--label">My BMW app</p>
            <h1>
              Your world. <br className="d-none d-xxl-block" /> My BMW.
            </h1>
            <p className="text--body-1">
              Download the new My BMW App for effortless connectivity between
              your BMW and a compatible smartphone.
            </p>
          </div>
          <div className="my-bmw-app__info">
            <div className="my-bmw-app-info__content">
              <h4>Stay updated.</h4>
              <p className="text--body-2">
                Prepare for every adventure with an overview of your BMW’s
                range, current mileage, window and door status, and more.{" "}
              </p>
            </div>
            <div className="my-bmw-app-info__content">
              <h4>Locate anywhere.</h4>
              <p className="text--body-2">
                Keep track of where your BMW is located on the map or monitor
                your car’s surroundings with Remote 3D view.
              </p>
            </div>
          </div>

          <div className="my-bmw-app__link">
            <img className="my-bmw-app__store" src={appStore} alt="App Store" />
            <img
              className="my-bmw-app__play"
              src={googlePlay}
              alt="Google Play"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
