import { Module } from "Enums";
import { FC, useState } from "react";
import { LenderProfile } from "shared/components";
import { LayoutWithSideNav } from "layout";
import { DrawerWrap } from "@sharedComponents";
import { useTheme } from "@mui/material";

const CompanyLenderList: FC = () => {
  const theme = useTheme();
  const [popUpState, setPopUpState] = useState(false);

  return (
    <DrawerWrap open={popUpState}>
      <LayoutWithSideNav theme={theme}>
        <LenderProfile
          module={Module.LENDER_MODULE}
          popUpState={popUpState}
          setPopUpState={setPopUpState}
        />
      </LayoutWithSideNav>
    </DrawerWrap>
  );
};

export default CompanyLenderList;
