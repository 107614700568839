import { InputAdornment, useTheme } from '@mui/material'
import { LiveActionWrap } from './live-action.style'
import { Avatar, Box, Icon, Input, TextDivider, Typography } from '@ntpkunity/controls'
import { Controller, useForm } from 'react-hook-form'

export const LiveAction = () => {
  const theme = useTheme()
  const { control } = useForm()

  return (
    <LiveActionWrap theme={theme} className="live-action-wrap">
      <Box theme={theme} className="search" mb={1}>
        <Controller
          name="search"
          control={control}
          defaultValue={''}
          render={({ field }) => (
            <Input
              theme={theme}
              fullWidth
              type="text"
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start">
                  <Icon name="SearchIcon" />
                </InputAdornment>
              }
              {...field}
            />
          )}
        />
      </Box>
      <Box theme={theme} className="action-list-wrap">
        <TextDivider
          theme={theme}
          title={
            <>
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                mb={2}
                className="text-uppercase"
              >
                TODAY
              </Typography>
            </>
          }
        />
        <Box theme={theme} className="all-auctions">
          <Box theme={theme}>
            <Avatar theme={theme}>SK</Avatar>
          </Box>
          <Box theme={theme}>
            <Typography theme={theme} component="p" variant="body2" className="auction-text">
              <b>Slava Kornilov</b> rescheduled the order <b>A-2838</b>.
            </Typography>
            <Box theme={theme} className="auction-details">
              <Typography
                theme={theme}
                component="span"
                variant="caption"
                children="June 15, 2024"
              />
              <Box theme={theme} className="separator"></Box>
              <Typography theme={theme} component="span" variant="caption" children="11:30 AM" />
            </Box>
          </Box>
        </Box>
        <Box theme={theme} className="all-auctions">
          <Box theme={theme}>
            <Avatar theme={theme}>SK</Avatar>
          </Box>
          <Box theme={theme}>
            <Typography theme={theme} component="p" variant="body2" className="auction-text">
              The credit application of the order <b>C-3283</b> has been <b>approved</b>.
            </Typography>
            <Box theme={theme} className="auction-details">
              <Typography
                theme={theme}
                component="span"
                variant="caption"
                children="June 15, 2024"
              />
              <Box theme={theme} className="separator"></Box>
              <Typography theme={theme} component="span" variant="caption" children="11:30 AM" />
            </Box>
          </Box>
        </Box>
        <Box theme={theme} className="all-auctions">
          <Box theme={theme}>
            <Avatar theme={theme}>SK</Avatar>
          </Box>
          <Box theme={theme}>
            <Typography theme={theme} component="p" variant="body2" className="auction-text">
              <b>Payment received</b> against the order <b>A-8045</b>.
            </Typography>
            <Box theme={theme} className="auction-details">
              <Typography
                theme={theme}
                component="span"
                variant="caption"
                children="June 15, 2024"
              />
              <Box theme={theme} className="separator"></Box>
              <Typography theme={theme} component="span" variant="caption" children="03:23 PM" />
            </Box>
          </Box>
        </Box>
        <Box theme={theme} className="all-auctions">
          <Box theme={theme}>
            <Avatar theme={theme}>SK</Avatar>
          </Box>
          <Box theme={theme}>
            <Typography theme={theme} component="p" variant="body2" className="auction-text">
              The credit application of the order <b>D-2747</b> has been <b>cancelled</b>.
            </Typography>
            <Box theme={theme} className="auction-details">
              <Typography
                theme={theme}
                component="span"
                variant="caption"
                children="June 15, 2024"
              />
              <Box theme={theme} className="separator"></Box>
              <Typography theme={theme} component="span" variant="caption" children="02:00 PM" />
            </Box>
          </Box>
        </Box>
        <TextDivider
          theme={theme}
          title={
            <>
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                mb={2}
                className="text-uppercase"
              >
                June 15,2024
              </Typography>
            </>
          }
        />
        <Box theme={theme} className="all-auctions">
          <Box theme={theme}>
            <Avatar theme={theme}>SK</Avatar>
          </Box>
          <Box theme={theme}>
            <Typography theme={theme} component="p" variant="body2" className="auction-text">
              <b>Slava Kornilov</b> has reached out for some help against the order <b>D-6521</b>.
              Head over to the chat.
            </Typography>
            <Box theme={theme} className="auction-details">
              <Typography
                theme={theme}
                component="span"
                variant="caption"
                children="June 15, 2024"
              />
              <Box theme={theme} className="separator"></Box>
              <Typography theme={theme} component="span" variant="caption" children="04:30 PM" />
            </Box>
          </Box>
        </Box>
      </Box>
    </LiveActionWrap>
  )
}
