import {
  Box,
  Button,
  Dialog,
  FileUpload,
  FileUploadPreview,
  Skeleton,
  Typography
} from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { DialogActionButtons, DialogContent } from './proofOfPaymentDialogStyle'
import { useReducer, type FC, type ReactElement } from 'react'
import { useBulkDeleteFiles, useGetS3PreSignedUrls, useSaveProofOfPayment, useUpdateProofOfPayment, useUploadFile } from '@modules/billingDetails/services'
import { ACTION_TYPE, addFilesToReducer, INITIAL_STATE, reducer } from './proofOfPaymentReducer'
import DisableLoader from '@shared/assets/images/loader-disabled-btn.gif'
import { type IPaymentPlan, type IProofOfPaymentPayload, type IDocumentKeys } from '@shared/typings'
interface ProofOfPaymentPopupComponentProps {
  hide: () => void
  order: IPaymentPlan
}

const ALLOWED_FILES_COUNT = 5

export const ProofOfPaymentDialogComponent: FC<ProofOfPaymentPopupComponentProps> = ({ hide, order }): ReactElement => {
  const theme = useTheme()

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
  const { filesMap, deletedFilesKeys, filesUploadedButNotSaved } = state
  const files = Array.from(filesMap.values())

  const { isFetching: LoadingPreSignedUrls } = useGetS3PreSignedUrls(order?.payment_proof, dispatch)
  const { mutate: saveProofOfPayment, isLoading: savingProofOfPayment } = useSaveProofOfPayment(deletedFilesKeys, dispatch)
  const { mutate: updateProofOfPayment, isLoading: updatingProofOfPayment } = useUpdateProofOfPayment(deletedFilesKeys, dispatch)
  const { mutateAsync: uploadFile } = useUploadFile(dispatch)
  const { mutate: deleteBulkFiles } = useBulkDeleteFiles()

  const handleUploadFile = (files: FileList): any => {
    const currentFileCount = filesMap.size
    const remainingFileSlots = ALLOWED_FILES_COUNT - currentFileCount

    if (remainingFileSlots > 0) {
      const _files = Array.from(files)
      if (_files.length > remainingFileSlots) {
        _files.splice(remainingFileSlots)
      }

      const newFiles = addFilesToReducer(_files, dispatch)

      for (const file of newFiles) {
        uploadFile(file)
      }
    } else {
      console.log('Maximum file limit reached (5 files)')
    }
  }

  const onImageUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e?.target?.files && e?.target?.files.length > 0) {
      void handleUploadFile(e.target.files)
    }
  }

  const onImageDrop = (e: React.DragEvent<HTMLElement>): void => {
    if (e?.dataTransfer?.files && e?.dataTransfer?.files.length > 0) {
      void handleUploadFile(e?.dataTransfer?.files)
    }
  }

  const handleSaveProofOfPayment = (): void => {
    const data = {
      document_keys: [''],
      ...(order.payment_proof === null ? { order_id: order.order_id } : { paymentProofId: order.payment_proof_id })
    }
    if (order.payment_proof === null) {
      saveProofOfPayment(data as IProofOfPaymentPayload, {
        onSuccess: hide
      })
    } else {
      updateProofOfPayment(data as IDocumentKeys & { paymentProofId: number }, {
        onSuccess: hide
      })
    }
  }

  const handleCancel = (): void => {
    if (filesUploadedButNotSaved.length > 0) {
      deleteBulkFiles(
        { keys: filesUploadedButNotSaved },
        {
          onSuccess: () => {
            dispatch({
              type: ACTION_TYPE.INITIAL_STATE
            })
          }
        }
      )
    }
    hide()
  }

  return (
    <Dialog
      theme={theme}
      size="sm"
      title={'Proof Of Payment'}
      open={true}
      onCloseDialog={handleCancel}
      customFooter={
        <DialogActionButtons theme={theme} className="dialog-action-buttons-wrap">
          {// eslint-disable-next-line @typescript-eslint/no-misused-promises
          }<form className="buttons">
            {
              LoadingPreSignedUrls
                ? <Skeleton theme={theme} width={'100%'} height={50} />
                : (
                  <Button
                    secondary
                    theme={theme}
                    text={'Cancel'}
                    onClick={handleCancel}
                  />
                  )
            }
            {
              LoadingPreSignedUrls
                ? <Skeleton theme={theme} width={'100%'} height={50} />
                : (
                  <Button
                    primary
                    theme={theme}
                    text={'Save'}
                    startIcon={
                      (savingProofOfPayment || updatingProofOfPayment) &&
                      (
                        <img src={DisableLoader} alt="Loader" />
                      )
                    }
                    disabled={((filesUploadedButNotSaved?.length === 0 && deletedFilesKeys?.length === 0) || savingProofOfPayment || updatingProofOfPayment)}
                    type="button"
                    onClick={e => { handleSaveProofOfPayment() }}
                  />
                  )
            }
          </form>
        </DialogActionButtons>
      }
      >
      <DialogContent theme={theme} className="dilaog-content">
        {
          <>
            {
              LoadingPreSignedUrls
                ? <Skeleton theme={theme} width={'100%'} height={180} sx={{ marginBottom: 2 }} />
                : (
                  <FileUpload
                    theme={theme}
                    allowMultiple
                    accept='.jpg, .jpeg, .png'
                    disabled={files.length >= 5}
                    onChange={onImageUpload}
                    onDrop={onImageDrop}
                    inlinePreview={false}
                    hoverLabel={
                      <>
                        Drag and drop file, or <span className='text-primary'>browse</span>
                      </>
                    }
                  />
                  )
            }
            {
              LoadingPreSignedUrls
                ? <Skeleton theme={theme} width={'100%'} height={57} />
                : (
                  <FileUploadPreview
                    theme={theme}
                    files={files as any[]}
                  />
                  )
            }
            {
              LoadingPreSignedUrls
                ? <Skeleton theme={theme} width={'100%'} height={32} sx={{ marginTop: 2 }} />
                : (
                  <Box theme={theme} mt={2} textAlign={'center'}>
                    <Typography theme={theme} className='text-muted' variant='caption' component='small' display={'block'}>
                      You can upload up to 5 files. Files can be up to 30MB. You can upload jpg, jpeg and png file formats.
                    </Typography>
                  </Box>
                  )
            }
          </>
        }
        </DialogContent>
      </Dialog>
  )
}
