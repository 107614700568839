import { useTheme } from "@mui/material";
import { Box, Button, CircleLoader, Grid, Icon, Label } from "@ntpkunity/controls";
import { Alert } from "@src/components";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { RiskAssessmentGraph } from "../risk-assessment-graph/risk-assessment-graph"
import { AlertStatusText, BasicStack } from "@src/components/dashboard"
import { Stack } from "@src/components/stack";
import { useEffect, useState } from "react";
import { RiskAssessmentDialog } from "../risk-assessment-dialog/risk-assessment-dialog.component"
import { useDispatch, useSelector } from "react-redux";
import { closeRiskDialog, fetchRiskAssessmentRequest, openRiskDialog } from "@src/components/redux/actions/riskAssessmentActions";
import { fetchFraudScore } from "@src/components/redux/actions/riskAssessmentActions"
import axios from "axios";

export const RiskAssessmentStack = () => {
  const theme = useTheme();
  const [open, setOpened] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);
  const dispatch = useDispatch();
  const { loading, riskAssessmentData, error, isDialogOpen } = useSelector(
    (state: any) => state.riskAssessment
  );

  const creditCheckScore = useSelector((state: any) => state.workQueue.credit_check_score);
  const { running_workflow_instance_id } = useSelector((state: any) => state.workQueue);
  
  console.log("running_workflow_instance_id",running_workflow_instance_id);
  const [fraudScores, setFraudScores] = useState([]) as any;
  const [ratios, setRatios] = useState({
    dtiRatio: null,
    ltvRatio: null,
    creditUtilization: null,
  }) as any;

  useEffect(() => {
    const fetchData = async (activityName: string) => {
      try {
        const base_url = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${base_url}/cde/get_activity_workflow_data`, {
          params: {
            workflowInstanceId: running_workflow_instance_id,
            activity_name: activityName,
          },
        });
        return response.data;
      } catch (err) {
        console.error(`Error fetching data for ${activityName}:`, err);
        return null;
      }
    };

    const fetchAllData = async () => {
      if (running_workflow_instance_id) {
        const fraudData = await fetchData("FraudCheck_Response");
        setFraudScores(fraudData?.fraud?.scores || []);

        const dtiData = await fetchData("LTI");
        const ltvData = await fetchData("LTV");
        const creditUtilizationData = await fetchData("CreditUtilization_Response");

        setRatios({
          dtiRatio: dtiData?.ratio ?? null,
          ltvRatio: ltvData?.ratio ?? null,
          creditUtilization: creditUtilizationData?.ratio ?? null,
        });
      }
    };

    fetchAllData();
  }, []);



  const { data } = useSelector((state: any) => state?.financeStructure);

  useEffect(() => {
    const customerReferenceId = "U-H000D3UA";
    if (!riskAssessmentData) {
      dispatch(fetchRiskAssessmentRequest(customerReferenceId));
    }
  }, [dispatch, riskAssessmentData]);


  if (loading) return <CircleLoader theme={theme} size="sm" />;
  if (error) return <div>Error: {error}</div>;

  // const {
  //   credit_rating,
  //   net_finance_amount,
  //   estimated_monthly_payment,
  //   due_at_signing,
  // } = riskAssessmentData?.primaryApplicant ?? {};

  const handleOpenDialog = () => {
    // setOpened(true);
    dispatch(openRiskDialog());
  };

  const handleCloseDialog = () => {
    // setOpened(false);
    dispatch(closeRiskDialog());
  };

  return (
    <>
      <Stack
        title={"Risk Assessment Metrices"}
        paddingXs={2}
        paddingMd={2}
        actionArea={
          <Button
            theme={theme}
            defaultBtn
            iconText={
              <>
                <Icon name="ArrowLinkIcon" />
              </>
            }
            onClick={handleOpenDialog}
          />
        }
      >
        <Box theme={theme} display={'flex'} flexDirection={'column'} gap={1}>
          {loading ? (
            // <CircleLoader theme={theme} size="sm" />
            <div>loading...</div>
          ) : (
            <>
              {/* Risk Assessment Graph */}
            <RiskAssessmentGraph creditCheckScore={creditCheckScore}/>

                {/* Fraud Check Alert */}
                
                {fraudScores.map((item, index) => (
                <AlertStatusText
              key={index}
                  text={"Fraud Check"}
              statusText={`Score: ${item.score}`}
              success={item.score > 0.5}
                  fullWidth
                />
          ))}
                {/* Monthly Income */}
                <BasicStack
                  text={"Monthly Income:"}
                  subTitle={`${riskAssessmentData?.primaryApplicant?.loanDetails?.financeAmount?.toLocaleString() ?? "N/A"}`}
                  fullWidth
                />

                {/* Monthly Debt Payment */}
                <BasicStack
                  text={"Monthly Debt Payment"}
                  subTitle={`$${data?.estimated_monthly_payment?.toLocaleString() ?? "N/A"}`}
                  fullWidth
                />

                {/* Current Exposure with Action Button */}
                <BasicStack
                  text={"Current Exposure:"}
                  subTitle={`${riskAssessmentData?.primaryApplicant?.dueAtSigning?.toLocaleString() ?? "N/A"}`}
                  fullWidth
                  actionArea={
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="ArrowLinkIcon" />}
                    onClick={handleOpenDialog}
                    />
                }
            />

              {/* Credit Utilisation */}
              <Alert
                text={'Credit Utilisation:'}
                value={ratios.creditUtilization ? `${ratios.creditUtilization}%` : "N/A"}
                success
                fullWidth
              />

              {/* DTI Ratio with Error Alert */}
              <Alert
                text={'DTI Ratio:'}
                value={ratios.dtiRatio ? `${ratios.dtiRatio}%` : "N/A"}
                error
                fullWidth
              />

              {/* LTV Ratio with Error Alert */}
              <Alert
                text={'LTV Ratio:'}
                value={ratios.ltvRatio ? `${ratios.ltvRatio}%` : "N/A"}
                error
                fullWidth
              />
            </>
          )}
        </Box>
      </Stack>
      <RiskAssessmentDialog open={isDialogOpen} closeDialog={handleCloseDialog} />
    </>
  );
};
