import {
  WorkQueueTabs,
  OrderStatus,
  DashboardRangeFilter,
  OrderStatusCategory,
  DocumentTypes,
  ProposalAgeCategories,
  TableRowOptions,
  DirectorRoles,
  WorkQueueExportKeys,
} from "@helpers/enum";
import { DashboardForm, TCompanyDetails, TIndividualDetails } from "@_types";
import { DefaultValues } from "react-hook-form";
import {
  PAYMENT_FREQUENCY,
  FieldPermissions,
} from "@ntpkunity/controls-common";
import { getWorkQueueFileName, isDealerRole } from "./utils";

export const LOCAL_STORAGE_KEYS = {
  MODE: "mode",
  SETTINGS: "settings",
};

export const REGEX = {
  UUID: /^\{?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\}?$/,
};
export const DEFAULT_PAGE_NUMBER = "0";
export const DEFAULT_PAGE_SIZE = 10;

export const BROKER_BASE_ROUTE = "/broker-app";
const AUTHENTICATION_ROUTE = "/authentication";

export const APP_ROUTES = {
  BASE_URL: BROKER_BASE_ROUTE,
  UNKNOW_URL: BROKER_BASE_ROUTE + "/*",
  DASHBOARD: BROKER_BASE_ROUTE + "/dashboard",
  QUOTATION: BROKER_BASE_ROUTE + "/quotation",
  NEW_QUOTATION: BROKER_BASE_ROUTE + "/new-quotation",
  PROPOSAL: BROKER_BASE_ROUTE + "/proposal",
  WORKQUEUE: BROKER_BASE_ROUTE + "/work-queue",
  CONFIGURATIONS: BROKER_BASE_ROUTE + "/configurations",
  SETUPS: BROKER_BASE_ROUTE + "/setups",
  ANALYTICS: BROKER_BASE_ROUTE + "/analytics",
  DIARY: BROKER_BASE_ROUTE + "/diary",
  QUOTATION_COMPARISON: BROKER_BASE_ROUTE + "/quotation-comparison",
  DOCUMENT: BROKER_BASE_ROUTE + "/document",
  SEND_FOR_PAYOUT: BROKER_BASE_ROUTE + "/send-for-payout",
  SIGN_IN: BROKER_BASE_ROUTE + AUTHENTICATION_ROUTE + "/sign-in",
  SIGN_IN_VIRGIN_MONEY: BROKER_BASE_ROUTE + AUTHENTICATION_ROUTE + "/signin",
  DEALER_PROPOSAL: BROKER_BASE_ROUTE + "/work-queue/dealer-proposal",
  CUSTOMER_PROPOSAL: BROKER_BASE_ROUTE + "/customer-proposal",
  SIGNATORY_STATUS: BROKER_BASE_ROUTE + "/signatory-status",
  LENDER_DASHBOARD: BROKER_BASE_ROUTE + "/lender-dashboard",
  FINANCIAL_CONFIGURATIONS: BROKER_BASE_ROUTE + "/financial-configurations",
  COMPLIANCE_DOCUMENT: BROKER_BASE_ROUTE + "/compliance-document",
  USER_MANAGEMENT: BROKER_BASE_ROUTE + "/user-management",
};

export const ALLOWED_FILE_TYPES = {
  [DocumentTypes.CREDIT_DOCUMENTS]: [
    "application/pdf",
    "image/jpeg",
    "image/png",
  ],
} as const;

export const GB_COUNTRY = {
  is_active: true,
  is_deleted: false,
  id: 228,
  name: "United Kingdom",
  code: "44",
  country_code: "GB",
  address_template: {
    is_active: true,
    is_deleted: false,
    id: 4,
    country_id: 229,
    field1_label: "City",
    field1_visible: true,
    field2_label: null,
    field2_visible: null,
    field3_label: "Post Code",
    field3_visible: true,
    field4_label: "County",
    field4_visible: true,
    field5_label: null,
    field5_visible: null,
    field6_label: null,
    field6_visible: null,
  },
};

export const INDIVIDUAL_LABEL_AND_KEYS: {
  label: string;
  key: keyof TIndividualDetails;
}[] = [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "ID Number", key: "idNumber" },
  { label: "Email Address", key: "emailAddress" },
  { label: "Contact Number", key: "contactNumber" },
];

export const COMPANY_LABEL_AND_KEYS: {
  label: string;
  key: keyof TCompanyDetails;
}[] = [
  { label: "Company Name", key: "companyName" },
  { label: "Registration Number", key: "companyRegNum" },
  { label: "Trading As", key: "tradingAs" },
  { label: "Years in Business", key: "yearsInBusiness" },
];

export const WORK_QUEUE_TABS = [
  {
    title: "Track Proposals",
    value: WorkQueueTabs.TRACK_PROPOSALS,
  },
  {
    title: "Dealer Proposal",
    value: WorkQueueTabs.DEALER_PROPOSALS,
  },
  {
    title: "Draft Proposals",
    value: WorkQueueTabs.DRAFT_PROPOSALS,
  },
  {
    title: "Quotations",
    value: WorkQueueTabs.QUOTATIONS,
  },
];

export const QUEUE_TO_STATUS_MAP = {
  [WorkQueueTabs.DRAFT_PROPOSALS]: [OrderStatus.DRAFT],
  [WorkQueueTabs.TRACK_PROPOSALS]: [
    OrderStatus.ACCEPTED,
    OrderStatus.SUBMITTED,
    OrderStatus.CONDITIONED,
    OrderStatus.CONDITIONAL_APPROVAL,
    OrderStatus.ADDITIONAL_INFO_REQUIRED,
    OrderStatus.DECLINED,
    OrderStatus.DOCUMENT_RECEIVED,
    OrderStatus.DOCUMENT_SENT,
    OrderStatus.PAID_OUT,
    OrderStatus.SENT_FOR_PAYOUT,
    OrderStatus.WITHDRAWN,
    OrderStatus.AWAITING_COMPLIANCE,
    OrderStatus.COMPLIED,
  ].concat(isDealerRole() ? [OrderStatus.NEW] : []),
  [WorkQueueTabs.DASHBOARD_PROPOSALS]: [
    OrderStatus.ACCEPTED,
    OrderStatus.SUBMITTED,
    OrderStatus.CONDITIONED,
    OrderStatus.CONDITIONAL_APPROVAL,
    OrderStatus.ADDITIONAL_INFO_REQUIRED,
    OrderStatus.DECLINED,
    OrderStatus.DOCUMENT_RECEIVED,
    OrderStatus.DOCUMENT_SENT,
    OrderStatus.PAID_OUT,
    OrderStatus.SENT_FOR_PAYOUT,
    OrderStatus.AWAITING_COMPLIANCE,
    OrderStatus.COMPLIED,
  ].concat(isDealerRole() ? [OrderStatus.NEW] : []),
  [WorkQueueTabs.DEALER_PROPOSALS]: [OrderStatus.NEW],
};

export const PROPOSAL_STATUSES_BY_CATEGORY = {
  [OrderStatusCategory.ACTIVE]: [
    OrderStatus.SUBMITTED,
    OrderStatus.ACCEPTED,
    OrderStatus.CONDITIONED,
    OrderStatus.CONDITIONAL_APPROVAL,
    OrderStatus.ADDITIONAL_INFO_REQUIRED,
    OrderStatus.DOCUMENT_RECEIVED,
    OrderStatus.SENT_FOR_PAYOUT,
    OrderStatus.DOCUMENT_SENT,
    OrderStatus.AWAITING_COMPLIANCE,
    OrderStatus.COMPLIED,
  ].concat(isDealerRole() ? [OrderStatus.NEW] : []),
  [OrderStatusCategory.CLOSED]: [
    OrderStatus.DECLINED,
    OrderStatus.WITHDRAWN,
    OrderStatus.PAID_OUT,
  ],
};

export const PROPOSAL_AGE_CATEGORY = {
  [ProposalAgeCategories.LESS_THAN_TWO]: [0, 2],
  [ProposalAgeCategories.THREE_TO_FIVE]: [3, 5],
  [ProposalAgeCategories.SIX_TO_TEN]: [6, 10],
  [ProposalAgeCategories.MORE_THAN_TEN]: [10, 1000],
};

export const DRAFT_QUEUE_STATUSES = [
  {
    text: "All",
    value: "all",
  },
  ...QUEUE_TO_STATUS_MAP[WorkQueueTabs.DRAFT_PROPOSALS].map((status) => ({
    text: status,
    value: status,
  })),
];

export const TRACK_QUEUE_STATUSES = [
  {
    text: "All",
    value: "all",
  },
  ...QUEUE_TO_STATUS_MAP[WorkQueueTabs.TRACK_PROPOSALS].map((status) => ({
    text: status,
    value: status,
  })),
];

export const DEALER_QUEUE_STATUSES = [
  ...QUEUE_TO_STATUS_MAP[WorkQueueTabs.DEALER_PROPOSALS].map((status) => ({
    text: status,
    value: status,
  })),
];

export const DASHBOARD_QUEUE_STATUSES = [
  {
    text: "All",
    value: "all",
  },
  ...QUEUE_TO_STATUS_MAP[WorkQueueTabs.DASHBOARD_PROPOSALS].map((status) => ({
    text: status,
    value: status,
  })),
];

export const MAX_NUMBER_OF_QUOTATIONS = 3;
export const MIN_NUMBER_OF_QUOTATIONS = 2;
export const DASHBOARD_RANGE_FILTER_VALUES = [
  {
    text: DashboardRangeFilter.WEEK,
    value: DashboardRangeFilter.WEEK,
  },
  {
    text: DashboardRangeFilter.MONTH,
    value: DashboardRangeFilter.MONTH,
  },
  {
    text: DashboardRangeFilter.QUARTER,
    value: DashboardRangeFilter.QUARTER,
  },
  {
    text: "Calendar Year",
    value: DashboardRangeFilter.CALENDER_YEAR,
  },
];

export const DASHBOARD_FORM_DEFAULT_VALUES: DefaultValues<DashboardForm> = {
  rangeFilter: DashboardRangeFilter.MONTH,
  totalRangeFilter: DashboardRangeFilter.MONTH,
  agingRangeFilter: DashboardRangeFilter.MONTH,
};

const ROW_OPTIONS_FOR_CONDITIONED_STATUSES = [
  TableRowOptions.VIEW_CONDITIONS,
  TableRowOptions.VIEW,
  TableRowOptions.EDIT,
  TableRowOptions.PROPOSAL_SUMMARY,
  TableRowOptions.UPLOAD,
  TableRowOptions.DOWNLOAD,
  TableRowOptions.CLONE,
  TableRowOptions.WITHDRAW,
  TableRowOptions.OPEN_DIARY,
];
const getOrderConditionedStatusesOptions = () =>
  (isDealerRole() ? [] : [TableRowOptions.MANUAL_STATUS_CHANGE]).concat(
    ROW_OPTIONS_FOR_CONDITIONED_STATUSES
  );

export const PROPOSAL_STATUS_TO_ALLOWED_STATUS_MAP = {
  [OrderStatus.DRAFT]: [
    OrderStatus.NEW,
    OrderStatus.SUBMITTED,
    OrderStatus.WITHDRAWN,
  ],
  [OrderStatus.NEW]: [OrderStatus.SUBMITTED, OrderStatus.WITHDRAWN],
  [OrderStatus.SUBMITTED]: [
    OrderStatus.CONDITIONED,
    OrderStatus.CONDITIONAL_APPROVAL,
    OrderStatus.ADDITIONAL_INFO_REQUIRED,
    OrderStatus.ACCEPTED,
    OrderStatus.DECLINED,
    OrderStatus.WITHDRAWN,
  ],
  [OrderStatus.CONDITIONED]: [
    OrderStatus.ACCEPTED,
    OrderStatus.SUBMITTED,
    OrderStatus.DECLINED,
    OrderStatus.WITHDRAWN,
  ],
  [OrderStatus.CONDITIONAL_APPROVAL]: [
    OrderStatus.ACCEPTED,
    OrderStatus.SUBMITTED,
    OrderStatus.DECLINED,
    OrderStatus.WITHDRAWN,
  ],
  [OrderStatus.ADDITIONAL_INFO_REQUIRED]: [
    OrderStatus.ACCEPTED,
    OrderStatus.SUBMITTED,
    OrderStatus.DECLINED,
    OrderStatus.WITHDRAWN,
  ],
  [OrderStatus.ACCEPTED]: [
    OrderStatus.WITHDRAWN,
    OrderStatus.DOCUMENT_SENT,
    OrderStatus.DOCUMENT_RECEIVED,
    OrderStatus.SENT_FOR_PAYOUT,
    OrderStatus.AWAITING_COMPLIANCE,
  ],
  [OrderStatus.DECLINED]: [OrderStatus.SUBMITTED],
  [OrderStatus.WITHDRAWN]: [OrderStatus.SUBMITTED],
  [OrderStatus.DOCUMENT_SENT]: [
    OrderStatus.DOCUMENT_RECEIVED,
    OrderStatus.WITHDRAWN,
  ],
  [OrderStatus.DOCUMENT_RECEIVED]: [
    OrderStatus.SENT_FOR_PAYOUT,
    OrderStatus.WITHDRAWN,
  ],
  [OrderStatus.SENT_FOR_PAYOUT]: [OrderStatus.PAID_OUT],
  [OrderStatus.PAID_OUT]: [],
  [OrderStatus.AWAITING_COMPLIANCE]: [
    OrderStatus.COMPLIED,
    OrderStatus.WITHDRAWN,
  ],
  [OrderStatus.COMPLIED]: [
    OrderStatus.WITHDRAWN,
    OrderStatus.DOCUMENT_SENT,
    OrderStatus.DOCUMENT_RECEIVED,
    OrderStatus.SENT_FOR_PAYOUT,
  ],
};

export const PROPOSAL_STATUS_TO_ROW_OPTION_MAP = {
  [OrderStatus.DRAFT]: (isDealerRole()
    ? []
    : [TableRowOptions.MANUAL_STATUS_CHANGE]
  ).concat([
    TableRowOptions.VIEW,
    TableRowOptions.EDIT,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.CLONE,
    TableRowOptions.OPEN_DIARY,
  ]),
  [OrderStatus.NEW]: isDealerRole()
    ? [
        TableRowOptions.VIEW,
        TableRowOptions.EDIT,
        TableRowOptions.PROPOSAL_SUMMARY,
        TableRowOptions.UPLOAD,
        TableRowOptions.DOWNLOAD,
        TableRowOptions.CLONE,
        TableRowOptions.WITHDRAW,
        TableRowOptions.OPEN_DIARY,
      ]
    : [
        TableRowOptions.MANUAL_STATUS_CHANGE,
        TableRowOptions.VIEW,
        TableRowOptions.EDIT,
        TableRowOptions.PROPOSAL_SUMMARY,
        TableRowOptions.UPLOAD,
        TableRowOptions.DOWNLOAD,
        TableRowOptions.CLONE,
        TableRowOptions.OPEN_DIARY,
      ],
  [OrderStatus.SUBMITTED]: (isDealerRole()
    ? []
    : [TableRowOptions.MANUAL_STATUS_CHANGE]
  ).concat([
    TableRowOptions.VIEW,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.CLONE,
    TableRowOptions.WITHDRAW,
    TableRowOptions.OPEN_DIARY,
  ]),
  [OrderStatus.CONDITIONED]: getOrderConditionedStatusesOptions(),
  [OrderStatus.CONDITIONAL_APPROVAL]: getOrderConditionedStatusesOptions(),
  [OrderStatus.ADDITIONAL_INFO_REQUIRED]: getOrderConditionedStatusesOptions(),
  [OrderStatus.ACCEPTED]: (isDealerRole()
    ? []
    : [TableRowOptions.MANUAL_STATUS_CHANGE]
  ).concat([
    TableRowOptions.VIEW,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.CLONE,
    TableRowOptions.WITHDRAW,
    TableRowOptions.GENERATE_DOCUMENTS,
    TableRowOptions.CHECK_SIGNATORY_STATUS,
    TableRowOptions.SEND_FOR_PAYOUT,
    TableRowOptions.GENERATE_COMPLIANCE_DOCUMENTS,
    TableRowOptions.OPEN_DIARY,
  ]),
  [OrderStatus.DECLINED]: [
    TableRowOptions.VIEW,
    TableRowOptions.EDIT,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.WITHDRAW,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.WITHDRAWN]: (isDealerRole()
    ? []
    : [TableRowOptions.MANUAL_STATUS_CHANGE]
  ).concat([
    TableRowOptions.VIEW,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.OPEN_DIARY,
  ]),
  [OrderStatus.DOCUMENT_SENT]: (isDealerRole()
    ? []
    : [TableRowOptions.MANUAL_STATUS_CHANGE]
  ).concat([
    TableRowOptions.VIEW,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.CLONE,
    TableRowOptions.WITHDRAW,
    TableRowOptions.CHECK_SIGNATORY_STATUS,
    TableRowOptions.SEND_FOR_PAYOUT,
    TableRowOptions.OPEN_DIARY,
  ]),
  [OrderStatus.DOCUMENT_RECEIVED]: (isDealerRole()
    ? []
    : [TableRowOptions.MANUAL_STATUS_CHANGE]
  ).concat([
    TableRowOptions.VIEW,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.CLONE,
    TableRowOptions.CHECK_SIGNATORY_STATUS,
    TableRowOptions.SEND_FOR_PAYOUT,
    TableRowOptions.OPEN_DIARY,
  ]),
  [OrderStatus.SENT_FOR_PAYOUT]: (isDealerRole()
    ? []
    : [TableRowOptions.MANUAL_STATUS_CHANGE]
  ).concat([
    TableRowOptions.VIEW,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.CLONE,
    TableRowOptions.CHECK_SIGNATORY_STATUS,
    TableRowOptions.SEND_FOR_PAYOUT,
    TableRowOptions.OPEN_DIARY,
  ]),
  [OrderStatus.PAID_OUT]: [
    TableRowOptions.VIEW,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.AWAITING_COMPLIANCE]: (isDealerRole()
    ? []
    : [TableRowOptions.MANUAL_STATUS_CHANGE]
  ).concat([
    TableRowOptions.WITHDRAW,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.VIEW,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.UPLOAD,
    TableRowOptions.VIEW_COMPLIANCE_DOCUMENTS,
    TableRowOptions.OPEN_DIARY,
  ]),
  [OrderStatus.COMPLIED]: (isDealerRole()
    ? []
    : [TableRowOptions.MANUAL_STATUS_CHANGE]
  ).concat([
    TableRowOptions.VIEW,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.WITHDRAW,
    TableRowOptions.GENERATE_DOCUMENTS,
    TableRowOptions.CHECK_SIGNATORY_STATUS,
    TableRowOptions.SEND_FOR_PAYOUT,
    TableRowOptions.OPEN_DIARY,
  ]),
};

export const PROPOSAL_OPTION_TEXT_MAP = {
  [TableRowOptions.VIEW]: "View Proposal",
  [TableRowOptions.EDIT]: "Edit Proposal",
  [TableRowOptions.PROPOSAL_SUMMARY]: "View Proposal Summary",
  [TableRowOptions.UPLOAD]: "Upload Documents",
  [TableRowOptions.DOWNLOAD]: "Download Proposal",
  [TableRowOptions.CLONE]: "Clone Proposal",
  [TableRowOptions.WITHDRAW]: "Withdraw Proposal",
  [TableRowOptions.VIEW_CONDITIONS]: "View Conditions",
  [TableRowOptions.GENERATE_DOCUMENTS]: "Generate Documents",
  [TableRowOptions.CHECK_SIGNATORY_STATUS]: "View Signatories",
  [TableRowOptions.SEND_FOR_PAYOUT]: "Send for Payout",
  [TableRowOptions.MANUAL_STATUS_CHANGE]: "Manually Change Status",
  [TableRowOptions.GENERATE_COMPLIANCE_DOCUMENTS]:
    "Generate Compliance Documents",
  [TableRowOptions.VIEW_COMPLIANCE_DOCUMENTS]: "View Compliance Documents",
  [TableRowOptions.OPEN_DIARY]: "Diary",
};

export const PAYOUT_STATUS = [
  {
    status: "Signed",
    color: "ind-success",
  },
  {
    status: "Signed",
    color: "ind-success",
  },
  {
    status: "Signed",
    color: "ind-success",
  },
];

export const PROPOSAL_SUMMARY_STATUS = [
  {
    status: "Out For Signature",
    color: "ind-primary",
  },
  {
    status: "Out For Signature",
    color: "ind-primary",
  },
  {
    status: "Out For Signature",
    color: "ind-primary",
  },
];

export const DOCUMENT_SIGNATORY_STATUS = [
  {
    status: "Signed",
    color: "ind-success",
  },
  {
    status: "Signed",
    color: "ind-success",
  },
  {
    status: "Out For Signature",
    color: "ind-primary",
  },
];

export const AMORTIZATION_METHODS = [
  { value: "Annuity30-360", text: "Annuity - 30/360" },
  { value: "AnnuityActual-360", text: "Annuity - Actual/360" },
  { value: "AnnuityActual-365", text: "Annuity - Actual/365" },
];
export const APR_CALCULATION_METHODS = [
  { value: "Annuity30-360", text: "Annuity - 30/360" },
  { value: "AnnuityActual-360", text: "Annuity - Actual/360" },
  { value: "AnnuityActual-365", text: "Annuity - Actual/365" },
];
export const INITIAL_CASH_OUT_FLOW_FLAT_RATE_METHODS = [
  { value: "IncludingVATAndCommission", text: "Including VAT & Commission" },
  { value: "ExcludingVATAndCommission", text: "Excluding VAT & Commission" },
];
export const COMMISION_CALCULATION_METHODS = [
  { value: "PercentageOfFinancedAmount", text: "% of Financed Amount" },
  { value: "PercentageOfInterestIncome", text: "% of Interest Income" },
];

export const FEE_FREQUENCIES = [
  { value: "OneTime", text: "One Time" },
  { value: "Periodic", text: "Periodic" },
];
export const FEE_OCCURANCES_ONE_TIME = [
  { value: "OnStartDate", text: "On Start Date" },
  { value: "WithFirstPayment", text: "With 1st Regular Payment" },
  { value: "WithLastPayment", text: "With Last Regular Payment" },
  { value: "Annual", text: "Annual" },
];
export const FEE_OCCURANCE_PERIODIC = [
  { value: "WithEveryPayment", text: "With Every Payment" },
  { value: "Monthly", text: "Monthly" },
  { value: "Quarterly", text: "Quarterly" },
  { value: "SemiAnnual", text: "Semi-Annual" },
  { value: "Annual", text: "Annual" },
];

export const RATE_TYPES = [
  { value: "Nominal Rate", text: "Nominal Rate" },
  { value: "APR", text: "APR" },
  { value: "Flat Rate", text: "Flat Rate" },
  { value: "Margin Rate", text: "Margin Rate" },
];

export const ADD_FEES_DEFAULT_VALUES = {
  amount: "",
  firstDueDate: "",
  type: "",
  name: "",
  frequency: "",
  paymentNumber: "",
  isEditable: true,
  firstFeePaymentDueAtStartDate: true,
};
export const BALLOON_COLLECTION = [
  { value: 0, text: "With Last Payment" },
  { value: 1, text: "1 Month after last Payment" },
  { value: 2, text: "2 Month after last Payment" },
  { value: 3, text: "3 Month after last Payment" },
  { value: 4, text: "4 Month after last Payment" },
  { value: 5, text: "5 Month after last Payment" },
  { value: 6, text: "6 Month after last Payment" },
];

export const VAT_DEFFERAL_TYPE = [
  { value: "Month", text: "Month" },
  { value: "Rental", text: "Rental" },
];

export const TRUE_FALSE_VALUES = [
  { value: "true", text: "Yes" },
  { value: "false", text: "No" },
];

export const PERMISSION_OPTIONS = {
  "Payment Frequency": PAYMENT_FREQUENCY,
  "Balloon Collection": BALLOON_COLLECTION,
  "Irregular Payments Applicable": TRUE_FALSE_VALUES,
  "Pdf Template Applicable": TRUE_FALSE_VALUES,
  "Step Payments Applicable": TRUE_FALSE_VALUES,
  "Step Payments Structure Applicable": TRUE_FALSE_VALUES,
  "Vat Amount Auto Calculate Applicable": TRUE_FALSE_VALUES,
  "Word Template Applicable": TRUE_FALSE_VALUES,
  "Vat Deferred": VAT_DEFFERAL_TYPE,
  "Rate Type": RATE_TYPES,
};

export const formatPermissionName = (permissionName: string) => {
  const formattedName = permissionName
    .split("-")
    .slice(1)
    .map((word) => {
      // For output field the name ends with result (asset-cost-result)
      if (word.includes("result")) return undefined;
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .filter(Boolean)
    .join(" ");

  return formattedName;
};

export const PARTNER_ROLES = [
  {
    text: DirectorRoles.PARTNER,
    value: DirectorRoles.PARTNER,
  },
  {
    text: DirectorRoles.CONTACT_PERSON,
    value: DirectorRoles.CONTACT_PERSON,
  },
];

export const DIRECTOR_ROLES = [
  {
    text: DirectorRoles.DIRECTOR,
    value: DirectorRoles.DIRECTOR,
  },
  {
    text: DirectorRoles.CONTACT_PERSON,
    value: DirectorRoles.CONTACT_PERSON,
  },
];

export const SNACKBAR_MESSAGES = {
  COMPANY_EMAIL_INVALID: "Add and verify company's email address",
  ERROR_WHILE_SENDING_EMAIL: "There was an error while sending email",
  EMAIL_SENT: "Email send successfully",
  SENDING_EMAIL: "Sending email...",
  DOWNLOADING_QUOTATION: "Downloading Quotation",
  QUOTATION_DOWNLOADED: "Quotation Downloaded!",
  INVALID_CUSTOMER_EMAIL: "Please provide a valid customer email",
  ERROR_WHILE_DOWNLOADING_QUOTATION:
    "Error occurred while downloading quotation",
  ERROR_WHILE_DOWNLOADING_PROPOSAL: "Error occurred while downloading proposal",
  UNEXPECTED_ERROR_OCCURRED: "Unexpected error occurred",
};

export const SORTED_PERMISSIONS_NAMES = [
  FieldPermissions.ASSET_COST,
  FieldPermissions.NON_VATABLE_AMOUNT,
  FieldPermissions.DEPOSIT,
  FieldPermissions.COMMISSION_PERCENTAGE,
  FieldPermissions.RATE,
  FieldPermissions.MARGIN_PERCENTAGE,
  FieldPermissions.BASE_RATE,
  FieldPermissions.RATE_TYPE,
  FieldPermissions.ADVANCE_PAYMENTS,
  FieldPermissions.REGULAR_PAYMENTS,
  FieldPermissions.BALLOON_PAYMENT,
  FieldPermissions.BALLOON_COLLECTION,
  FieldPermissions.VAT_AMOUNT,
  FieldPermissions.VAT_PAYMENT_NUMBER,
  FieldPermissions.VAT_DEFERRED,
  FieldPermissions.IRREGULAR_PAYMENTS_APPLICABLE,
  FieldPermissions.STEP_PAYMENTS_APPLICABLE,
  FieldPermissions.STEP_PAYMENTS_STRUCTURE_APPLICABLE,
  FieldPermissions.RENTAL_SUMMARY_RESULT,
  FieldPermissions.FINANCE_AMOUNT_RESULT,
  FieldPermissions.COMMISSION_AMOUNT_RESULT,
  FieldPermissions.ASSET_COST_RESULT,
  FieldPermissions.UPFRONT_PAYMENT_RESULT,
  FieldPermissions.CORPORATE_TAX_SAVING_RESULT,
  FieldPermissions.REPAYMENT_PLAN_RESULT,
  FieldPermissions.RATES_GROSS_YIELD_RESULT,
  FieldPermissions.RATES_NET_YIELD_RESULT,
  FieldPermissions.RATES_APR_RESULT,
  FieldPermissions.RATES_FLAT_RATE_EXCL_COMM_RESULT,
  FieldPermissions.RATES_FLAT_RATE_INCL_COMM_RESULT,
  FieldPermissions.FEES,
  FieldPermissions.PAYMENT_FREQUENCY,
  FieldPermissions.PAYMENT_MODE,
];

export const PIE_CHART_CONFIG = {
  DEFAULT_DATA: [
    {
      count: 1,
      color: "#F2F2F2",
      status: "",
    },
  ],
};

export const WORK_QUEUE_EXPORT_HEADERS = [
  { label: "State", key: WorkQueueExportKeys.STATUS },
  { label: "Proposal Name", key: WorkQueueExportKeys.NAME },
  { label: "Finance Type", key: WorkQueueExportKeys.FINANCE_TYPE },
  { label: "Customer Name", key: WorkQueueExportKeys.CUSTOMER_NAME },
  { label: "Finance Amount", key: WorkQueueExportKeys.FINANCE_AMOUNT },
  { label: "Last Updated", key: WorkQueueExportKeys.UPDATED_AT },
  { label: "Date Submitted", key: WorkQueueExportKeys.DATE_SUBMITED },
  { label: "Start Date", key: WorkQueueExportKeys.START_DATE },
  { label: "End Date", key: WorkQueueExportKeys.END_DATE },
];

export const WORK_QUEUE_FILE_NAME = getWorkQueueFileName();
