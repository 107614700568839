import { useEffect } from "react";
import { InsuranceInfo } from "@src/components/molecules";
import "./insurance-information.scss";
import { Button } from "@src/components/atoms";
import { useForm } from "react-hook-form";
import {
  useGetInsurance,
  useUpdateInsurance,
  useGetInsuranceProvider,
} from "@src/hooks";
import { useAppContext } from "@src/context/app-context";
import {
  orderInsurance,
  updateInsuraneProviderOptions,
} from "@src/context/app-actions";
import { insuranceInfoSchema } from "@src/validators/customer/insurance";
import { yupResolver } from "@hookform/resolvers/yup";

export const InsuranceInformation = ({
  handleClick,
}: {
  handleClick: () => void;
}) => {
  const {
    state: {
      tenant: { company_id },

      order: {
        Insurance_Inforation: { identifier, insurer_name, agent_phone },
        customer_info: { email, reference_id },
        reference_number: orderRefNumber,
      },
    },
    dispatch: appDispatch,
  } = useAppContext();
  const { isPending: InsuranceLoading, mutate: addInsurance } =
    useGetInsurance();
  const { isPending: InsuranceUpdateLoading, mutate: updateInsurance } =
    useUpdateInsurance();

  const { mutate: getInsuranceProvider } = useGetInsuranceProvider();
  useEffect(() => {
    getInsuranceProvider(
      { company_id: company_id },
      {
        onSuccess: async (data) => {
          const companies = data.map((item: any) => ({
            insurer_name: item.insurance_provider_name,
            agent_phone: item.contact_number.replace(/\D/g, ""),
          }));
          await appDispatch(updateInsuraneProviderOptions(companies));
        },
      }
    );
  }, [company_id]);
  const onSubmit = (data: any) => {
    if (
      formState.dirtyFields["insurer_name"] ||
      formState.dirtyFields["agent_phone"]
    ) {
      if (identifier) {
        updateInsurance(
          {
            order_reference_id: orderRefNumber,
            reference_id: reference_id,
            insurer_name: data.insurer_name,
            agent_phone: data.agent_phone,
            updated_by: email,
          },
          {
            onSuccess: async (res) => {
              await appDispatch(orderInsurance(res.data));
              handleClick();
            },
          }
        );
      } else {
        addInsurance(
          {
            order_reference_id: orderRefNumber,
            reference_id: reference_id,
            insurer_name: data.insurer_name,
            agent_phone: data.agent_phone,
            created_by: email,
          },
          {
            onSuccess: async (res) => {
              await appDispatch(orderInsurance(res.data));
              handleClick();
            },
          }
        );
      }
    } else {
      handleClick();
    }
  };
  const { handleSubmit, formState, ...rest } = useForm({
    defaultValues: {
      insurer_name: `${insurer_name}`,
      agent_phone: `${agent_phone}`,
    },
    resolver: yupResolver(insuranceInfoSchema),
  });
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="insurance-information-wrapper"
    >
      <InsuranceInfo form={rest} />
      <div className="insurance-information-cta-wrapper">
        <Button
          type="default"
          htmlType={"submit"}
          text="Save & continue"
          isLoading={InsuranceLoading || InsuranceUpdateLoading}
          fullWidth
          fixed
        />
      </div>
    </form>
  );
};
