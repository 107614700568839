import { Radio } from "@src/components/atoms";
import { Controller } from "react-hook-form";
import "./radio-selector.scss";

export const RadioSelector = ({ formControl }) => {
  return (
    <div className="radio-card">
      <Controller
        name="vehicle.ownership"
        control={formControl}
        render={({ field: { value, onChange } }) => (
          <div className="radio-card__options">
            <div className="radio-card__option">
              <Radio
                label="For Myself"
                name="vehicle"
                id="option1"
                checked={value === "sole-person"}
                onChange={() => onChange("sole-person")}
                htmlFor="option1"
              />
              <div className="radio-card__option__radio-wrapper__divider" />
              <p className="radio-card__option__radio-wrapper__description text--body-2">
                You are the sole person responsible for this account and
                vehicle.
              </p>
            </div>
            <div className="radio-card__option">
              <Radio
                label="For Myself &amp; Co-applicant"
                name="vehicle"
                id="option2"
                checked={value === "co-applicant"}
                onChange={() => onChange("co-applicant")}
                htmlFor="option2"
              />
              <div className="radio-card__option__radio-wrapper__divider" />
              <p className="radio-card__option__radio-wrapper__description text--body-2">
                You and a co-buyer will share financial responsibility.
              </p>
            </div>
          </div>
        )}
      />
    </div>
  );
};
