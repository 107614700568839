import { PageHeader } from "@components/page-header";
import { Button } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { useGetSaveProposalPayload } from "@hooks/proposal";
import {
  useSaveProposal,
  useSendEmailNotification,
  useUpdateProposal,
} from "@hooks/mutations";
import { useNavigate, useParams } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import { useSnackbarContext } from "@contexts/snackbar";
import {
  OrderStatus,
  WorkQueueTabs,
  SnackbarVariants,
  DownloadDocument,
  Events,
  ClientTypes,
} from "@helpers/enum";
import { PageHeaderProps } from "./page-header.props";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types/forms";
import { DiaryDialogData } from "@_types";
import { DiaryDialog } from "@components/diary/diary-dialog/diary-dialog";
import { useDialogState, useSetupsSelector } from "@hooks";
import { useQueryClient } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getDownloadDocumentPayload } from "@helpers/utils";
import {
  QuotationForm,
  useCalculationsContext,
  useQuotationFormContext,
} from "@ntpkunity/controls-common";
import {
  useGetCompanyByTenantId,
  useGetAllLenders,
  useGetAllActiveDealers,
} from "@hooks/queries";
import { SNACKBAR_MESSAGES } from "@helpers/const";

const ProposalPageHeader = ({
  showActionBtn = true,
  isLastStep,
}: PageHeaderProps) => {
  const queryClient = useQueryClient();
  const params = useParams<{ proposalId?: string }>();
  const isEditMode = !!params.proposalId;
  const navigate = useNavigate();
  const theme = useTheme();
  const { mutateAsync: saveProposal } = useSaveProposal();
  const { mutateAsync: updateProposal } = useUpdateProposal();
  const { data: lenders } = useGetAllLenders();
  const { data: dealersData } = useGetAllActiveDealers();
  const { watch } = useFormContext<TProposalForm>();
  const { setSnackbar } = useSnackbarContext();
  const { getPayload, validateProposalPayload } = useGetSaveProposalPayload(
    params.proposalId
  );
  const isViewMode = watch("isViewMode");

  const lenderData = lenders?.find(
    (lender) => lender.name === watch("proposalDetails.lenderName")
  );
  const dealerData = dealersData?.find(
    (dealer) => dealer.dealer_name === watch("proposalDetails.dealerName")
  );

  const currentStatus = watch("status");

  const mutateProposal = isEditMode ? updateProposal : saveProposal;

  const handleSaveProposal = async () => {
    const payload = getPayload(currentStatus, lenderData, dealerData);
    if (validateProposalPayload(payload)) {
      try {
        setSnackbar({ open: true, message: "Saving Proposal" });
        await mutateProposal(payload);
        if (isEditMode) {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.GET_DIARY_COMMENTS, params.proposalId],
          });
        }

        navigate(
          `${APP_ROUTES.WORKQUEUE}/${
            currentStatus === OrderStatus.DRAFT
              ? WorkQueueTabs.DRAFT_PROPOSALS
              : WorkQueueTabs.TRACK_PROPOSALS
          }`
        );
      } catch (err) {
        setSnackbar({
          open: true,
          message: "Error Occurred while saving Proposal",
          variant: SnackbarVariants.ERROR,
        });
      }
    }
  };

  const {
    dialogOpen: diaryDialogOpened,
    dialogData: diaryDialogData,
    openDialog: openDiaryDialog,
    closeDialog: closeDiaryDialog,
  } = useDialogState<DiaryDialogData>();

  const handleOpenDiaryDialog = () => {
    openDiaryDialog({ proposalId: params.proposalId });
  };
  return (
    <PageHeader
      title={false}
      hasInput
      inputPlaceholder="Enter Proposal Name..."
      inputName="proposalDetails.name"
      readOnly={isViewMode}
      actionArea={
        showActionBtn && (
          <>
            <Button
              theme={theme}
              type="button"
              text="Save Proposal"
              secondary
              onClick={handleSaveProposal}
            />
            <Button
              theme={theme}
              type="button"
              text="Diary"
              secondary
              disabled={!params.proposalId}
              onClick={handleOpenDiaryDialog}
            />
            {isLastStep && <SendEmailToCustomerAction />}
            <DiaryDialog
              dialogData={diaryDialogData}
              open={diaryDialogOpened}
              closeDialog={closeDiaryDialog}
            />
          </>
        )
      }
    />
  );
};

export default ProposalPageHeader;

const SendEmailToCustomerAction = () => {
  const theme = useTheme();
  const { getValues: getProposalValues } = useFormContext<TProposalForm>();
  const { data: calculations } = useCalculationsContext();
  const { getValues: getFinanceDetailsValues } =
    useQuotationFormContext<QuotationForm>();
  const { setSnackbar } = useSnackbarContext();
  const { mutateAsync: sendQuotationEmail, isLoading: sendingEmail } =
    useSendEmailNotification();

  const setups = useSetupsSelector((state) => state);
  const { data: companyInfo } = useGetCompanyByTenantId();
  const { data: lenders } = useGetAllLenders();

  const validateEmails = (customerEmail: string, clientType: string) => {
    if (!companyInfo.email) {
      throw new Error(SNACKBAR_MESSAGES.COMPANY_EMAIL_INVALID);
    }
    if (!customerEmail) {
      const errorMsg =
        clientType === ClientTypes.INDIVIDUAL
          ? SNACKBAR_MESSAGES.INVALID_CUSTOMER_EMAIL
          : "Provide a valid email address for contact person";
      throw new Error(errorMsg);
    }
  };

  const handleSendEmailToCustomer = async () => {
    try {
      const downloadQuotationPayload = getDownloadDocumentPayload({
        documentType: DownloadDocument.PROPOSAL,
        proposalValues: getProposalValues(),
        financialCalculations: calculations,
        financialValues: getFinanceDetailsValues(),
        setups: {
          financeTypes: setups.financeTypes.data,
          productTypes: setups.productTypes.data,
          clientTypes: setups.clientTypes.data,
          assetCategories: setups.assetCategories.data,
          assetConditions: setups.assetConditions.data,
          assetTypes: setups.assetTypes.data,
          assetSubTypes: setups.assetSubTypes.data,
          lenders: lenders,
        },
        companyId: companyInfo.id,
      });

      const { email_address, client_type } = downloadQuotationPayload;
      validateEmails(email_address, client_type);

      await sendQuotationEmail({
        eventName: Events.EMAIL_PROPOSAL_CUSTOMER,
        payload: {
          source_email: companyInfo.email,
          bcc_emails: [],
          cc_emails: [],
          data: {
            ...downloadQuotationPayload,
            reference_number: "",
            customer_reference_id: "",
          },
          email_subject: `Proposal - ${downloadQuotationPayload.name}`,
          email: downloadQuotationPayload.email_address,
          email_attachment_keys: [],
        },
      });
    } catch (err) {
      const errorMessage =
        err.message || SNACKBAR_MESSAGES.ERROR_WHILE_SENDING_EMAIL;
      setSnackbar(
        {
          open: true,
          message: errorMessage,
          variant: "error",
        },
        5000
      );
    }
  };

  return (
    <Button
      theme={theme}
      type="button"
      text="Send Email To Customer"
      primary
      disabled={sendingEmail}
      onClick={handleSendEmailToCustomer}
    />
  );
};
