import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const StatusWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.status-wrap': {
    '.status': {
      textTransform: 'uppercase',
      borderRadius: 8,
      padding: '8px 16px',
      '&.rejected': {
        background:
          'linear-gradient(0deg, rgba(255, 51, 51, 0.08) 0%, rgba(255, 51, 51, 0.08) 100%), #FFF',
        color: theme.palette.error.main
      },
      '&.verified': {
        background:
          'linear-gradient(0deg, rgba(51, 204, 89, 0.08) 0%, rgba(51, 204, 89, 0.08) 100%), #FFF',
        color: theme.palette.success.main
      },
      '&.recapture-verification': {
        background:
          'linear-gradient(0deg, rgba(0, 51, 253, 0.08) 0%, rgba(0, 51, 253, 0.08) 100%), #FFF',
        color: theme.palette.primary.main
      },
      '&.manual-review': {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%), #FFF',
        color: theme.palette.grey[600]
      }
    }
  },
  '&.reserve-status': {
    '.status': {
      width: '100%',
      textAlign: 'center',
      padding: '4px 8px'
    }
  }
}))
