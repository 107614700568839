import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const ThirdPartyResponseListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.third-party-response-list-wrap": {
    ".u-accordian-wrap": {
      backgroundColor: CustomColors.lightGrey,
      borderRadius: theme.shape.borderRadius,
      marginBottom: 8,
      "&:last-child": {
        marginBottom: 0,
      },
      ".u-accordion-header": {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: CustomColors.lightGrey,
        padding: 8,
        ".MuiAccordionSummary-content": {
          margin: "0 0 0 4px",
          ".main-title": {
            fontSize: theme.typography.caption.fontSize,
            lineHeight: theme.typography.caption.lineHeight,
            fontWeight: theme.typography.fontWeightMedium,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            ".label-wrap": {
              borderRadius: theme.shape.borderRadius,
              ".label-bg": {
                backgroundColor: "#DADBDE",
                padding: "4px 8px",
                ".label-text": {
                  fontSize: theme.typography.caption.fontSize,
                  lineHeight: "22px",
                  fontWeight: theme.typography.caption.fontWeight,
                  color: CustomColors.bodyColor
                }
              }
            }
          },
        },
        
      },
      ".u-accordian-body": {
        padding: "8px",
        border: "none"
      },
      [theme.breakpoints.down("md")]: {
        padding: "24px 16px",
      },
    },
  },
}));
