import { Stack } from "@components";
import { useTheme } from "@mui/material";
import { Grid, Typography } from "@ntpkunity/controls";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types";
import { CustomerDetails } from "@components/proposal/summary/customer-details/customer-details.component";
import { ProposalDetailsList } from "@components/proposal/proposal-details/proposal-details-view/proposal-details-list";
import { generateProposalDetails } from "@helpers/utils";
import { useSetupsSelector } from "@hooks";

export const ProposalDetailsView = () => {
  const theme = useTheme();
  const { watch } = useFormContext<TProposalForm>();
  const { name, lenderName, dealerName, financeType, productType } =
    watch("proposalDetails");
  const {
    filteredFinanceTypes: { financeTypes },
    productTypes: { data: productTypes },
  } = useSetupsSelector((setups) => ({
    filteredFinanceTypes: setups.getFilteredFinanceTypes(financeType),
    productTypes: setups.productTypes,
  }));

  const selectedFinanceType = financeTypes?.find(
    (item) => item.code === financeType
  )?.name;

  const selectedProductType = productTypes?.find(
    (item) => item.code === productType
  )?.name;

  const proposalDetails = generateProposalDetails(
    name,
    lenderName,
    dealerName,
    selectedFinanceType,
    selectedProductType
  );
  return (
    <Grid theme={theme} container item spacing={3} alignItems={"stretch"}>
      <Grid theme={theme} item xs={12} md={6}>
        <Stack paddingXs={3} paddingMd={3} className="h-100">
          <Typography
            theme={theme}
            component="h4"
            variant="h4"
            children="Basic Details"
            sx={{ mb: 2 }}
          />
          <ProposalDetailsList details={proposalDetails} />
        </Stack>
      </Grid>
      <Grid theme={theme} item xs={12} md={6}>
        <CustomerDetails />
      </Grid>
    </Grid>
  );
};
