import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AddSignatoriesWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.add-signatories-wrap": {
    ".action-area": {
      borderTop: "1px solid" + theme.palette.divider,
      paddingTop: 16,
    },
  },
}));

export const AddSignatoriesListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.add-signatories-list-wrap": {
    ".collapse-wrap": {
      "&.list-collapse": {
        [theme.breakpoints.down("md")]: {
          padding: 0,
        },
        ".u-accordion-header": {
          [theme.breakpoints.down("md")]: {
            padding: 0,
          },
        },
      },
    },
    ".u-accordion-header": {
      ".u-switch-label": {
        [theme.breakpoints.up("md")]: {
          ".MuiSwitch-root": {
            order: 2,
            "+": {
              ".MuiFormControlLabel-label": {
                order: 1,
                marginLeft: 0,
                marginRight: 8,
              },
            },
          },
        },
      },
    },
    ".signatories-list": {
      ".item-without-action": {
        "&:last-child": {
          border: "none !important",
        },
      },
      ".signatory-list-item": {
        borderBottom: "1px solid" + theme.palette.grey[100],
        paddingTop: 16,
        paddingBottom: 16,
        ".name-with-action": {
          display: "flex",
          alignItems: "center",
          gap: 16,
          ".MuiTypography-body2": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            flexGrow: 1,
          },
          ".action-area": {
            padding: 0,
            border: "none",
            ".btn": {
              "&.with-icon": {
                "&.icon-only": {
                  padding: 0,
                },
              },
            },
          },
        },
      },
    },
  },
}));

export const SignatoriesTableWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.signatories-table-wrap": {
    ".stack-wrap": {
      "&.bg-light": {
        [theme.breakpoints.down("md")]: {
          padding: 0,
          backgroundColor: "transparent",
        },
      },
    },
  },
}));

export const DialogContentContainer = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.dialog-content-container": {
    ".address-form": {
      borderTop: "1px solid" + theme.palette.divider,
      marginTop: 32,
      paddingTop: 8,
      ".address-layout-wrap": {
        ".MuiGrid-container": {
          ".MuiGrid-item": {
            "&:nth-child(4), &:nth-child(5), &:nth-child(6)": {
              [theme.breakpoints.up("sm")]: {
                flexBasis: "33.33%",
                maxWidth: "33.33%",
              },
            },
          },
        },
      },
    },
  },
}));
