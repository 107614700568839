import { useTheme } from "@mui/material";
import { Box, Button, Icon, Input, Typography } from "@ntpkunity/controls";
import { ButtonGroupProps } from "@components/button-group/button-group.props";
import { ButtonGroupWrap } from "@components/button-group/button-group.style";
import clsx from "clsx";

export const ButtonGroup = ({ items }: ButtonGroupProps) => {
  const theme = useTheme();
  return (
    <ButtonGroupWrap className="button-group-wrap" theme={theme}>
      {items?.map((item, index) => (
        <Button
          key={index}
          className={clsx({ selected: item.selected })}
          theme={theme}
          type="button"
          defaultBtn
          text={item.title}
          onClick={item.onClick}
        />
      ))}
    </ButtonGroupWrap>
  );
};
