import { useGetEventAssociatedChecklist } from "common/hooks/useConfigurationData";
import { useOrderChecklist } from "common/hooks/useCustomerData";
import { ChecklistEvent, OrderStatus, PermissionTypes } from "enums";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Box,
  Grid,
  Accordion,
  Button,
  Icon,
} from "@ntpkunity/controls";
import { Card } from "../../../../shared/Card";
import { Skeleton, useTheme } from "@mui/material";
import { utcToLocal } from "utilities/methods";
import { utcToLocalFormat } from "common/constants";
import { CheckListEditDialog } from "./dealerResponseDialog";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";

export const PickUpScheduleResponse = ({ setSchdulePickupKeysResponse,selectedOrder }) => {
  const theme = useTheme();
  const params = useParams();
  const userId = params?.reference_id;
  const [allowEditChecklist, setEditChecklist] = useState(false);
  const order_reference_id = params?.order_reference_id;
  const [pickupScheduleDialogOpened, setPickUpScheduleDialogOpened] =
    useState(false);
  const {
    mutate: pickupScheduleEvent,
    data: pickupScheduleEventData,
    isLoading: pickupChecklistLoading,
  } = useGetEventAssociatedChecklist();
  const tabOpenIndicator = "pickupSchedule"
  const { data: pickupOrderChecklist, isLoading: pickupOrderLoading } =
    useOrderChecklist({
      customer_reference_id: userId,
      checklist_identifier:
        pickupScheduleEventData?.[0]?.[0]?.checklist_identifier,
      order_reference_id: order_reference_id,
    });
    const editDealerChecklist = useHasPermissions(
      ccpPermissions.FIELDS.EDIT_DEALER_CHECKLIST_RESPONSE,
      PermissionTypes.VIEW
    );
    useEffect(() => {
      setEditChecklist(editDealerChecklist);
    }, [editDealerChecklist]);
  useEffect(() => {
    pickupScheduleEvent({
      event_name: ChecklistEvent.VEHICLE_PICKUP_SCHEDULE_CHECKLIST,
    });
  }, []);
  useEffect(() => {
    if (pickupOrderChecklist) {
      const targetObject = pickupOrderChecklist.find(
        (item) =>
          item.question_text ===
            "How many keys/key cards/FOBs did you provide to the customer ?" &&
          item.question_type === "MULTIPLE_OPTIONS"
      );
      setSchdulePickupKeysResponse(targetObject?.primary_answer);
    }
  }, [pickupOrderChecklist]);
  return (
    <>
      <Grid theme={theme} item sm={6} xs={12}>
        <Card className="min-h">
          <Box theme={theme} className="card-header with-actions">
            <Typography
              theme={theme}
              variant="h4"
              component="h4"
              children="Schedule Pickup"
              className="title"
            />
            {(pickupScheduleEventData?.[0]?.[0]  && allowEditChecklist&& 
            selectedOrder?.status === OrderStatus.COMPLETED
            ) &&
            <Box theme={theme} className="action-area">
              <Button
                theme={theme}
                defaultBtn
                className="btn-action"
                iconText={<Icon name="EditIcon" />}
                onClick={() => {
                  setPickUpScheduleDialogOpened(true);
                }}
              />
            </Box>
            }
          </Box>
          {pickupChecklistLoading || pickupOrderLoading ? (
            <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
          ) : (
            <Box theme={theme} className="card-body">
              <ul className="list">
                {pickupScheduleEventData?.[0]?.[0]?.questions.map(
                  (ques, index) => (
                    <li key={index}>
                      {pickupOrderChecklist?.find(
                        (f) => f.question_text == ques.question && f.description
                      ) ? (
                        <Accordion
                          theme={theme}
                          items={[
                            {
                              isExpanded: false,
                              title: (
                                <>
                                  <Box
                                    theme={theme}
                                    className="ccp-accordian-header"
                                  >
                                    <Typography
                                      variant="body2"
                                      component="p"
                                      theme={theme}
                                    >
                                      {ques.question}
                                    </Typography>
                                    <b>
                                      {
                                        pickupOrderChecklist?.find(
                                          (f) =>
                                            f.question_text == ques.question
                                        )?.primary_answer
                                      }
                                    </b>
                                  </Box>
                                </>
                              ),
                              content: (
                                <>
                                  <Box theme={theme} className="detail">
                                    <Typography
                                      variant="caption"
                                      component="span"
                                      theme={theme}
                                    >
                                      {
                                        pickupOrderChecklist?.find(
                                          (f) =>
                                            f.question_text == ques.question
                                        )?.description
                                      }
                                    </Typography>
                                  </Box>
                                </>
                              ),
                              key: "string",
                            },
                          ]}
                        ></Accordion>
                      ) : (
                        <>
                          <Box theme={theme} className="without-accordian">
                            <Box theme={theme} className="left">
                              {ques.question}
                            </Box>
                            <Box theme={theme} className="right">
                              {pickupOrderChecklist?.find(
                                (f) => f.question_text == ques.question
                              )?.primary_answer || "-"}
                            </Box>
                          </Box>
                          <li></li>
                        </>
                      )}
                    </li>
                  )
                )}
              </ul>
              {(
                <p style={{ fontSize: "smaller" }}>
                  Store Updated by{" "}
                  <b>
                    {pickupOrderChecklist
                      ? pickupOrderChecklist?.[0]?.updated_by
                      : "-"}
                  </b>{" "}
                  {pickupOrderChecklist?.[0]?.updated_at && "at"}{" "}
                  <b>
                    {pickupOrderChecklist?.[0]?.updated_at
                      && utcToLocal(
                          pickupOrderChecklist?.[0]?.updated_at,
                          utcToLocalFormat
                        )}
                  </b>
                </p>
              )}
                {(
                <p style={{ fontSize: "smaller" }}>
                   Company Updated by{" "}
                  <b>
                    {(pickupOrderChecklist && pickupOrderChecklist?.[0]?.changed_by !== null)
                      ? pickupOrderChecklist?.[0]?.changed_by
                      : "-"}
                  </b>{" "}
                  {pickupOrderChecklist?.[0]?.changed_at && "at"}{" "}
                  <b>
                    {pickupOrderChecklist?.[0]?.changed_at
                      && utcToLocal(
                          pickupOrderChecklist?.[0]?.changed_at,
                          utcToLocalFormat
                        )}
                  </b>
                </p>
              )}
            </Box>
          )}
        </Card>
      </Grid>
      {pickupScheduleEventData && pickupScheduleEventData?.[0]?.[0] &&
      <CheckListEditDialog
        checkListUpdateDialogOpened={pickupScheduleDialogOpened}
        setCheckListUpdateDialogOpened={setPickUpScheduleDialogOpened}
        questionsData={pickupScheduleEventData?.[0]?.[0]}
        availabilityOrderChecklist={pickupOrderChecklist}
        dialogOpenedIndicator={tabOpenIndicator}
      ></CheckListEditDialog>}
    </>
  );
};
