import { styled } from "@mui/system";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "./index";

export const CustomTheme = styled(
  Box,

  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '.fw-medium': {
    fontWeight: theme.typography.fontWeightMedium
  },
  '.fw-sbold': {
    fontWeight: theme.customVariables.fontWeightSemiBold
  },
  '.text-muted': {
    color: theme.palette.grey[600]
  },
  '.text-primary': {
    color: theme.palette.primary.main
  },
  '.text-center': {
    textAlign: 'center'
  },
  '.text-white': {
    color: theme.palette.common.white
  },
  '.text-danger': {
    color: theme.palette.error.main
  },
  '.decoration-none': {
    textDecoration: 'none'
  },
  '.text-uppercase': {
    textTransform: 'uppercase'
  },
  '.MuiSkeleton-root': {
    transformOrigin: '0 0',
    transform: 'scale(1, 1)',
    borderRadius: theme.shape.borderRadius
  },
  '.sort-filter': {
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    '.u-form-group': {
      height: 20,
      '.u-form-control': {
        '&.u-select': {
          '.MuiInputBase-input': {
            padding: '0 28px 0px 0',
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            fontWeight: theme.typography.fontWeightMedium
          },
          '.MuiSelect-icon': {
            right: 0
          }
        }
      }
    },
    '.action-item': {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    }
  },
  ".btn": {
    "&.btn-primary": {
      background: CustomColors.gradient,
      backgroundColor: "transparent",
      borderColor: "transparent",
      border: "none",
      "&:hover": {
        background: theme.palette.grey[900] + "!important",
        backgroundColor: theme.palette.grey[900] + "!important",
        color: [theme.palette.common.white] + "!important",
      },
      "&.with-icon:hover": {
        svg: {
          path: {
            stroke: theme.palette.common.white + "!important",
          },
        },
      },
      "&:disabled": {
        opacity: 0.5,
        cursor: "not-allowed",
        color: theme.palette.common.white + "!important",
      },
    },
  },
  ".MuiSwitch-root .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
    background: CustomColors.gradient,
    backgroundColor: "transparent",
  },
  [theme.breakpoints.down('md')]: {
    '.text-h4': {
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight
    },
    '.text-h3': {
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight
    },
    '.text-h2': {
      fontSize: theme.typography.h3.fontSize,
      lineHeight: theme.typography.h3.lineHeight
    },
    '.text-body2': {
      fontSize: theme.typography.caption.fontSize,
      lineHeight: theme.typography.caption.lineHeight
    }
  }
}));
