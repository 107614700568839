import { useTheme } from "@mui/material";
import { Box, Button, Label } from "@ntpkunity/controls";
import { Alert, PageHeader, Stack } from "@src/components";
import { AddDecisionDialog } from "./add-decision-popup/add-decision-dialog.component" 
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const DashboardPageHeader = () => {
  const theme = useTheme();
  const [open, setOpened] = useState(false);

  const { isOpen, applicantDetails, loading, error } = useSelector(
    (state: any) => state.applicantDetails
  );

  localStorage.setItem("reference_number", applicantDetails?.status);

  console.log(applicantDetails?.status)

    const handleOpenDialog = () => {
      setOpened(true);
    };
  
    const handleCloseDialog = () => {
      setOpened(false);
    };

  const ref_num = localStorage.getItem("reference_number")

  return (
    <>
      <Box theme={theme} className="dashboard-page-header-wrap">
        <Stack paddingMd={2} paddingXs={2}>
          <PageHeader
            title={applicantDetails?.reference_number}
            hasLabel={
              <Label
                theme={theme}
                text={applicantDetails?.status}
                state="primary"
                textTransform="none"
              />
            }
            actionArea={
              <>
                <Button
                  theme={theme}
                  primary
                  text={"Add Decision"}
                  type="button"
                  onClick={handleOpenDialog}
                />
              </>
            }
            hasStatus={
              <>
                <Alert text="Asset Verification" error />
                <Alert text="Fraud Alert" error />
                <Alert text="Finance Fee" error />
                <Alert text="Self Employed" error />
              </>
            }
          />
        </Stack>
      </Box>
      <AddDecisionDialog open={open} closeDialog={handleCloseDialog} />
    </>
    
  );
};
