import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { _move_in_duration, setMoveInDate } from "../../helpers/methods";
import {useContext} from 'react';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '@constants';
import { AppContext } from "@app/context-provider";

export const getDefaultValues = (contactData: any, customerReferenceId: any, isGaraging: boolean, isBiiling: boolean, previous: boolean) => {
  const mailAddress = contactData?.customer_addresses?.find((x: any) => x?.address_type === 'Mailing');
  const PrevAddress = contactData?.customer_addresses?.find((x: any) => x?.address_type === 'Previous');
  const garagingAddress = contactData?.customer_addresses?.find((x: any) => x?.address_type === 'Garaging');
  const billingAddress = contactData?.customer_addresses?.find((x: any) => x?.address_type === 'Billing');

  return {
    mail_address: {
      ...mailAddress,
      state_id: mailAddress?.state_name,
      move_in_duration: setMoveInDate(mailAddress?.move_in_date),
    },
    prev_address: {
      ...PrevAddress,
      state_id: PrevAddress?.state_name,
      move_in_duration: setMoveInDate(PrevAddress?.move_in_date),
    },
    garaging_address: isGaraging || garagingAddress?.entity_type === null ? {
      ...garagingAddress,
      state_id: garagingAddress?.state_name,
    } : {},
    Billing: isBiiling || billingAddress?.entity_type === null ? {
      ...billingAddress,
      state_id: billingAddress?.state_name,
    }: {},
    customerReferenceId: customerReferenceId,
  };
}

export function useResidenceDetailsForm(contactData: any, customerReferenceId: any, PLACEHOLDERS: any, formStates: any, isGaraging: boolean, isBilling: boolean, previous: boolean) {
  const appState = useContext(AppContext);
  const queryClient = useQueryClient();
  const LenderConfigurations: any = queryClient.getQueryData(QueryKeys.GET_DEFAULT_LENDER);

  const prev_address = yup.lazy((parent) => {
    const mailAddress: any = parent?.parent?.mail_address?.move_in_duration;
    const isValidDate: boolean = _move_in_duration(mailAddress, appState?.state?.min_months_stay_at_address);
    return !isValidDate || previous
        ? yup.object().shape({
          address_line_1: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_ADDRESS_LINE_1_IS_REQUIRED),
          city: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_CITY_IS_REQUIRED),
          state_name: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_STATE_NAME_IS_REQUIRED),
          zip_code: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_ZIP_CODE_IS_REQUIRED),
          move_in_duration: yup
              .string()
              .nullable()
              .required(PLACEHOLDERS.RESIDENCE_DETAILS_MOVE_IN_DATE_IS_REQUIRED)
              .matches(/^(0[1-9]|1[0-2])(19|20)\d{2}$/, PLACEHOLDERS.RESIDENCE_DETAILS_MOVE_IN_DATE_REQUIRED_FORMAT)
        }):
        yup.object().nullable().shape({})

  });

  function createValidationSchema(isGaraging, isBilling) {
    const schema = yup.object().shape({
      mail_address: yup.object().shape({
        address_line_1: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_ADDRESS_LINE_1_IS_REQUIRED),
        city: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_CITY_IS_REQUIRED),
        state_name: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_STATE_NAME_IS_REQUIRED),
        zip_code: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_ZIP_CODE_IS_REQUIRED),
        move_in_duration: yup
            .string()
            .nullable()
            .required(PLACEHOLDERS.RESIDENCE_DETAILS_MOVE_IN_DATE_IS_REQUIRED)
            .matches(/^(0[1-9]|1[0-2])(19|20)\d{2}$/, PLACEHOLDERS.RESIDENCE_DETAILS_MOVE_IN_DATE_REQUIRED_FORMAT),
      }),
      prev_address: prev_address,
      garaging_address: isGaraging
          ? yup.object().shape({}) // Skip validation for garaging_address if isGaraging is true
          : yup.object().shape({
            address_line_1: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_ADDRESS_LINE_1_IS_REQUIRED),
            city: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_CITY_IS_REQUIRED),
            state_name: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_STATE_NAME_IS_REQUIRED),
            zip_code: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_ZIP_CODE_IS_REQUIRED),
          }),
      Billing: isBilling
          ?yup.object().shape({}) //skip validation is isBilling is true
          : yup.object().shape({
            address_line_1: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_ADDRESS_LINE_1_IS_REQUIRED),
            city: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_CITY_IS_REQUIRED),
            state_name: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_STATE_NAME_IS_REQUIRED),
            zip_code: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_ZIP_CODE_IS_REQUIRED),
          })
    });

    return schema;
  }

  const validationSchema = createValidationSchema(isGaraging, isBilling);

  const residentForm = useForm<any>({
    defaultValues: formStates?.['residence']?.data || getDefaultValues(contactData, customerReferenceId, isGaraging, isBilling, previous),
    resolver: yupResolver(validationSchema),
    mode: 'all'
  });

  const watchResidenceFields = residentForm.watch();
  return { residentForm, watchResidenceFields };
}
