import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Grid } from '@ntpkunity/controls'
import { VehicleList } from '@components'
import { AddCustomer } from './add-customer'
import { AddTradeIn } from './add-trade-in'
import { AddInsurance } from './add-insurance'

const AddCti: FC = () => {
  const theme = useTheme()
  return (
    <>
      <VehicleList theme={theme} className="vehicle-list">
        <Grid theme={theme} container columnSpacing={1}>
          <Grid theme={theme} item xs={4}>
            <AddCustomer />
          </Grid>
          {/* ********************************* */}
          <Grid theme={theme} item xs={4}>
            <AddTradeIn />
          </Grid>
          {/* ********************************* */}
          <Grid theme={theme} item xs={4}>
            <AddInsurance />
          </Grid>
        </Grid>
      </VehicleList>
    </>
  )
}

export default AddCti
