import Http from '@helpers/http-api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useStoreContext } from '@store/storeContext'
import {
  EP_GET_FINANCIAL_INSURANCE_PRODUCTS,
  EP_GET_FINANCIAL_INSURANCE,
  EP_GET_FINANCIAL_INSURANCE_PRODUCTS_BY_DEALER_ID,
  EP_HUBEX_GET_FNIPRODUCT_RATES,
  EP_HUBEX_GET_DEALER_SURCHARGES,
  EP_GET_FINANCIAL_INSURANCE_PRODUCT_BUNDLES,
  EP_GET_ALL_FINANCIAL_INSURANCE_PRODUCTS_BY_DEALER_CODE
} from '@helpers/endpoints'
import { IFinancialInsurance } from '@models'
import { getMarketPlaceToken } from '@helpers/methods'
import { QueryKeys, ToastMessages } from '@helpers/enums'

const token = getMarketPlaceToken()
const headers: any = {
  Authorization: `Bearer ${token}`
}

export const useGetAllFinancialInsuranceInitialData = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation<{}, any>(
    () => {
      const apiService = Http.createConnection()
      return apiService.get<any>(EP_GET_FINANCIAL_INSURANCE)
    },
    {
      onSuccess(_response: any) {
        actions.setFinancialInsuranceInitialData(_response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetFinancialInsuranceProductsByDealerId = (body: any): any => {
  const fetchFinancialInsuranceProducts = async () => {
    const apiService = Http.createConnection({
      baseUrl: process.env.CONFIG_API_GATEWAY
    })
    const params = body?.data ? `${body.data}` : ''
    const company_id = body?.company_id
    headers.company_id = company_id

    const response = await apiService.get<any>(
      params
        ? `${EP_GET_FINANCIAL_INSURANCE_PRODUCTS_BY_DEALER_ID}?${params}`
        : EP_GET_FINANCIAL_INSURANCE_PRODUCTS_BY_DEALER_ID,
      {},
      { headers }
    )
    return response
  }

  const { data, isLoading, error } = useQuery(
    [QueryKeys.GET_FINANCIAL_INSURANCE_PRODUCTS, body],
    fetchFinancialInsuranceProducts,
    {
      enabled: !!body,
      keepPreviousData: true
    }
  )

  return { data, isLoading, error }
}

export const useGetAllFinancialInsuranceProductsByDealerCode = (dealerCode: string): any => {
  const fetchAllFinancialInsuranceProductsByDealerCode = async () => {
    const apiService = Http.createConnection({
      baseUrl: process.env.CONFIG_API_GATEWAY
    })

    const response = await apiService.get<any>(
      `${EP_GET_ALL_FINANCIAL_INSURANCE_PRODUCTS_BY_DEALER_CODE}/${dealerCode}`
    )
    return response
  }

  const { data, isLoading, error } = useQuery(
    [QueryKeys.EP_GET_ALL_FINANCIAL_INSURANCE_PRODUCTS, dealerCode],
    fetchAllFinancialInsuranceProductsByDealerCode,
    {
      enabled: !!dealerCode,
      keepPreviousData: true
    }
  )

  return { data, isLoading, error }
}

export const useGetAllFinancialInsuranceProductBundlesByDealerCode = (dealerCode: string): any => {
  const fetchFinancialInsuranceBundledByDealerCode = async () => {
    const apiService = Http.createConnection({
      baseUrl: process.env.CONFIG_API_GATEWAY
    })

    const response = await apiService.get<any>(
      `${EP_GET_FINANCIAL_INSURANCE_PRODUCT_BUNDLES}/${dealerCode}`
    )
    return response
  }

  const { data, isLoading, error } = useQuery(
    [QueryKeys.GET_FINANCIAL_INSURANCE_PRODUCT_BUNDLES, dealerCode],
    fetchFinancialInsuranceBundledByDealerCode,
    {
      enabled: !!dealerCode,
      keepPreviousData: true,
      select: (response) => response?.filter((bundle: any) => bundle?.is_active)
    }
  )

  return { data, isLoading, error }
}

export const useUpdateFinancialInsuranceBundleById = (): any => {
  const { actions } = useStoreContext()
  const queryClient = useQueryClient()
  const { data, isLoading, mutate } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.CONFIG_API_GATEWAY
      })
      return apiService.patch<any>(`${EP_GET_FINANCIAL_INSURANCE_PRODUCT_BUNDLES}/${body.id}`, body)
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(QueryKeys.GET_FINANCIAL_INSURANCE_PRODUCT_BUNDLES)
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true
        })
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true
        })
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useGetAllFinancialInsuranceProducts = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation<{ dealer_code: string }, any>(
    () => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_GET_FINANCIAL_INSURANCE_PRODUCTS}`)
    },
    {
      onSuccess(_response: any) {
        actions.setFinancialInsuranceProductData(_response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useSaveFinancialInsurance = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<IFinancialInsurance>(
    (body: any) => {
      // body.dealer_id = 1;
      const apiService = Http.createConnection()
      return apiService.post<any>(`${EP_GET_FINANCIAL_INSURANCE}`, body)
    },
    {
      onSuccess() {
        actions.setToast({
          toastMessage: 'Data Updated Sucessfully',
          toastState: true
        })
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true
        })
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateFinancialInsuranceById = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(`${EP_GET_FINANCIAL_INSURANCE}/${body.id}`, body)
    },
    {
      onSuccess() {
        actions.setToast({
          toastMessage: 'Data Updated Sucessfully',
          toastState: true
        })
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true
        })
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useDeleteFinancialInsuranceById = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.delete<any>(`${EP_GET_FINANCIAL_INSURANCE}/${body.id}`)
    },
    {
      onSuccess() {
        actions.setToast({
          toastMessage: 'Record Deleted Sucessfully',
          toastState: true
        })
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true
        })
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useGetFnIProductRate = (): any => {
  const { data, isLoading, mutate, error } = useMutation(
    async (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY
      })
      return apiService.post<any>(`${EP_HUBEX_GET_FNIPRODUCT_RATES}`, body?.data)
    },
    {
      retry: 3,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      onSuccess(_response: any) {},
      onError(_error: any) {}
    }
  )

  return { data, isLoading, mutate, error }
}

export const useGetDealerSurcharges = (): any => {
  const { data, isLoading, mutate, error } = useMutation(
    async (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY
      })
      return apiService.post<any>(`${EP_HUBEX_GET_DEALER_SURCHARGES}`, body)
    },
    {
      retry: 3,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      onSuccess(_response: any) {},
      onError(_error: any) {}
    }
  )

  return { data, isLoading, mutate, error }
}
