export const FETCH_RISK_ASSESSMENT_REQUEST = "FETCH_RISK_ASSESSMENT_REQUEST";
export const FETCH_RISK_ASSESSMENT_SUCCESS = "FETCH_RISK_ASSESSMENT_SUCCESS";
export const FETCH_RISK_ASSESSMENT_FAILURE = "FETCH_RISK_ASSESSMENT_FAILURE";
export const OPEN_RISK_DIALOG = "OPEN_RISK_DIALOG";
export const CLOSE_RISK_DIALOG = "CLOSE_RISK_DIALOG";
export const FETCH_FRAUD_SCORE = "FETCH_FRAUD_SCORE";

export const fetchRiskAssessmentRequest = (customerReferenceId) => ({
    type: FETCH_RISK_ASSESSMENT_REQUEST,
    payload: customerReferenceId,
});

export const fetchRiskAssessmentSuccess = (data) => ({
    type: FETCH_RISK_ASSESSMENT_SUCCESS,
    payload: data,
});

export const fetchRiskAssessmentFailure = (error) => ({
    type: FETCH_RISK_ASSESSMENT_FAILURE,
    payload: error,
});

// Actions for dialog
export const openRiskDialog = () => ({
    type: OPEN_RISK_DIALOG,
});

export const closeRiskDialog = () => ({
    type: CLOSE_RISK_DIALOG,
});

export const fetchFraudScore = (fraudScore) => ({
    type: FETCH_FRAUD_SCORE,
    payload: fraudScore,
});