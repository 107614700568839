import { FC } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import PageContent from './conversation-page.style'
import Tabs from 'components/Conversation/Tabs/Tabs-component'

export const Conversation: FC = () => {
  const theme = useTheme()
  return (
    <LayoutWithSideNav theme={theme}>
      <PageContent theme={theme} className="page-content">
        <Tabs />
      </PageContent>
    </LayoutWithSideNav>
  )
}
