import { IS_VEHICLE_AVAILABLE, utcToLocalFormat } from "common/constants";
import { useGetEventAssociatedChecklist } from "common/hooks/useConfigurationData";
import { useOrderChecklist } from "common/hooks/useCustomerData";
import { ChecklistEvent, OrderStatus, PermissionTypes, SubscriptionStatus } from "enums";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Box,
  Grid,
  Accordion,
  Button,
  Icon,
} from "@ntpkunity/controls";
import { Card } from "../../../../shared/Card";
import { Skeleton, useTheme } from "@mui/material";
import { GetReadyCheckListResponse } from "./getReadyCheckListResponse";
import { PickUpScheduleResponse } from "./pickupScheduleResponse";
import { ReturnChecklist } from "./returnChecklist";
import { useGetContractByRefercnceId } from "common/hooks/useSubscription";
import { utcToLocal } from "utilities/methods";
import { CheckListEditDialog } from "./dealerResponseDialog";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";

export const VehicleAvailabilityResponse = ({ selectedOrder }) => {
  const theme = useTheme();
  const params = useParams();
  const userId = params?.reference_id;
  const [allowEditChecklist, setEditChecklist] = useState(false);
  const order_reference_id = params?.order_reference_id;
  const {
    mutate: availabilityEvent,
    data: availabilityEventData,
    isLoading: avalabilityChecklistLoading,
  } = useGetEventAssociatedChecklist();
  const {
    data: availabilityOrderChecklist,
    isLoading: availabilityOrderLoading,
  } = useOrderChecklist({
    customer_reference_id: userId,
    checklist_identifier: availabilityEventData?.[0]?.[0]?.checklist_identifier,
    order_reference_id: order_reference_id,
  });

  const { data: contractData } = useGetContractByRefercnceId(
    userId,
    order_reference_id
  );
  const editDealerChecklist = useHasPermissions(
    ccpPermissions.FIELDS.EDIT_DEALER_CHECKLIST_RESPONSE,
    PermissionTypes.VIEW
  );
  const [vehicleAvailable, setVehicleAvailable] = useState<any>();
  const [schdulePickupKeyResponse, setSchdulePickupKeysResponse] = useState("");
  const [checkListUpdateDialogOpened, setCheckListUpdateDialogOpened] =
    useState(false);
  const tabOpenIndicator = "vehicleAvaiability"
  useEffect(() => {
    availabilityEvent({
      event_name: ChecklistEvent.AVAILABILITY_CHECKLIST,
    });
  }, []);
  useEffect(() => {
    setEditChecklist(editDealerChecklist);
  }, [editDealerChecklist]);
  useEffect(() => {
    const vehicleAvailable = availabilityOrderChecklist?.find(
      (f) => f.question_text == IS_VEHICLE_AVAILABLE
    );
    setVehicleAvailable(vehicleAvailable);
  }, [availabilityOrderChecklist]);
  const getLatestAvailabilityCheckEvent = (selectedOrder) => {
    if (!selectedOrder?.events) return null;
    const availabilityCheckEvents = selectedOrder?.events?.filter(event => event?.type === "AVAILABILITY_CHECK");
    if (availabilityCheckEvents?.length === 0) return null;
    return availabilityCheckEvents.reduce((latestEvent, currentEvent) => {
      return new Date(currentEvent?.completedOn) > new Date(latestEvent?.completedOn) ? currentEvent : latestEvent;
    });
  };
  return (
    <>
      {avalabilityChecklistLoading || availabilityOrderLoading ? (
        <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
      ) : (
        <Grid theme={theme} container spacing={3}>
          {vehicleAvailable?.primary_answer == "No" ? (
            <Grid theme={theme} item sm={6} xs={12}>
              <Card>
                <Box theme={theme} className="card-header with-actions">
                  <Typography
                    theme={theme}
                    variant="h4"
                    component="h4"
                    children="Vehicle Availability"
                    className="title"
                  />
                  <Box theme={theme} className="action-area">
                    <Box theme={theme} className="status not-available">
                      Not Available
                    </Box>
                  </Box>
                </Box>
                <Box theme={theme} className="card-body">
                  <Box theme={theme} sx={{ mb: 2 }}>
                    <b>Reason</b>
                  </Box>
                  {vehicleAvailable?.description || ""}
                </Box>
              </Card>
            </Grid>
          ) : (
            <>
              <Grid theme={theme} item sm={6} xs={12}>
                <Card className="min-h">
                  <Box theme={theme} className="card-header with-actions">
                    <Typography
                      theme={theme}
                      variant="h4"
                      component="h4"
                      children="Vehicle Availability"
                      className="title"
                    />
                    <Box theme={theme} className="action-area">
                      {vehicleAvailable?.primary_answer == "Yes" && (
                        <Box theme={theme} className="status available">
                          Available
                        </Box>
                      )}
                      {(availabilityEventData?.[0]?.[0] && allowEditChecklist && 
                      selectedOrder?.status !== OrderStatus.PENDING_AVAILABILITY_CHECK) &&
                      <>
                        <Button
                          theme={theme}
                          defaultBtn
                          className="btn-action"
                          iconText={<Icon name="EditIcon" />}
                          onClick={() => {
                            setCheckListUpdateDialogOpened(true);
                          }}
                        ></Button>
                      </>}
                    </Box>
                  </Box>
                  <Box theme={theme} className="card-body">
                    <ul className="list">
                      <li>
                        <Box theme={theme} className="without-accordian">
                          <Box theme={theme} className="left">
                            Current Odometer
                          </Box>
                          <Box theme={theme} className="right">
                          {selectedOrder && getLatestAvailabilityCheckEvent(selectedOrder)?.metadata?.odometer && 
                            getLatestAvailabilityCheckEvent(selectedOrder)?.metadata?.odometer + " Miles"}
                          </Box>
                        </Box>
                      </li>
                      {availabilityEventData?.[0]?.[0]?.questions.map(
                        (ques, index) => (
                          <li key={index}>
                            {availabilityOrderChecklist?.find(
                              (f) =>
                                f.question_text == ques.question &&
                                f.description
                            ) ? (
                              <Accordion
                                theme={theme}
                                items={[
                                  {
                                    isExpanded: false,
                                    title: (
                                      <>
                                        <Box
                                          theme={theme}
                                          className="ccp-accordian-header"
                                        >
                                          <Typography
                                            variant="body2"
                                            component="p"
                                            theme={theme}
                                          >
                                            {ques.question}
                                          </Typography>
                                            {
                                              availabilityOrderChecklist?.find(
                                                (f) =>
                                                  f.question_text ==
                                                  ques.question
                                              )?.primary_answer
                                            }
                                        </Box>
                                      </>
                                    ),
                                    content: (
                                      <>
                                        <Box theme={theme} className="detail">
                                          <Typography
                                            variant="caption"
                                            component="span"
                                            theme={theme}
                                          >
                                            {
                                              availabilityOrderChecklist?.find(
                                                (f) =>
                                                  f.question_text ==
                                                  ques.question
                                              )?.description
                                            }
                                          </Typography>
                                        </Box>
                                      </>
                                    ),
                                    key: "string",
                                  },
                                ]}
                              ></Accordion>
                            ) : (
                              <>
                                <Box
                                  theme={theme}
                                  className="without-accordian"
                                >
                                  <Box theme={theme} className="left">
                                    {ques.question}
                                  </Box>
                                  <Box theme={theme} className="right">
                                      {availabilityOrderChecklist?.find(
                                        (f) => f.question_text == ques.question
                                      )?.primary_answer || "-"}
                                  </Box>
                                </Box>
                              </>
                            )}
                          </li>
                        )
                      )}
                    </ul>
                    {(
                      <p style={{ fontSize: "smaller" }}>
                        Store Updated by{" "}
                        <b>
                          {availabilityOrderChecklist
                            ? availabilityOrderChecklist?.[0]?.updated_by
                            : "-"}
                        </b>{" "}
                        {availabilityOrderChecklist?.[0]?.updated_at && "at"}{" "}
                        <b>
                          {" "}
                          {availabilityOrderChecklist?.[0]?.updated_at
                            && utcToLocal(
                                availabilityOrderChecklist?.[0]?.updated_at,
                                utcToLocalFormat
                              )}
                        </b>
                      </p>
                    )}
                     {(
                      <p style={{ fontSize: "smaller" }}>
                        Company Updated by{" "}
                        <b>
                          {availabilityOrderChecklist 
                            ? availabilityOrderChecklist?.[0]?.changed_by
                            : "-"}
                        </b>{" "}
                        {availabilityOrderChecklist?.[0]?.changed_at && "at"}{" "}
                        <b>
                          {" "}
                          {availabilityOrderChecklist?.[0]?.changed_at
                            && utcToLocal(
                                availabilityOrderChecklist?.[0]?.changed_at,
                                utcToLocalFormat
                              )}
                        </b>
                      </p>
                    )}
                  </Box>
                </Card>
              </Grid>
              {availabilityEventData?.length && (
                <>
                  <GetReadyCheckListResponse
                    selectedOrder={selectedOrder}
                  ></GetReadyCheckListResponse>
                  <PickUpScheduleResponse
                    setSchdulePickupKeysResponse={setSchdulePickupKeysResponse}
                    selectedOrder={selectedOrder}
                  />
                  {contractData &&
                    contractData?.subscription_status !==
                      SubscriptionStatus.DRAFT &&
                    contractData?.subscription_status !==
                      SubscriptionStatus.ACTIVE &&
                    contractData?.subscription_status !==
                      SubscriptionStatus.CANCELED && (
                      <ReturnChecklist
                        contractData={contractData}
                        schdulePickupKeyResponse={schdulePickupKeyResponse}
                      />
                    )}
                </>
              )}
            </>
          )}
        </Grid>
      )}
      {availabilityEventData && availabilityEventData?.[0]?.[0] &&
      <CheckListEditDialog
        checkListUpdateDialogOpened={checkListUpdateDialogOpened}
        setCheckListUpdateDialogOpened={setCheckListUpdateDialogOpened}
        questionsData={availabilityEventData?.[0]?.[0]}
        availabilityOrderChecklist={availabilityOrderChecklist}
        dialogOpenedIndicator={tabOpenIndicator}
        odoMeterReading={getLatestAvailabilityCheckEvent(selectedOrder) && getLatestAvailabilityCheckEvent(selectedOrder)?.metadata?.odometer}
        vehicelStatus={vehicleAvailable?.primary_answer}
      ></CheckListEditDialog>
      }
    </>
  );
};
