import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTheme, SelectChangeEvent } from "@mui/material";
import {
  Select,
  IBoxProps,
  Grid,
  Input,
  Box,
  Typography,
} from "@ntpkunity/controls";
import { styled } from "@mui/material/styles";
import { useStoreContext } from "Stores/SetupStore/Store";
import { useGetReservationByDealerId } from "services/dealer-configurations.service";
import { useQueryClient } from "react-query";
import { QueryKeys } from "Enums";

const TabWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  "&.tab-wrap": {
    width: "100%",
    display: "inline-block",

    hr: {
      opacity: 0.4,
    },

    ".title-icon": {
      width: "100%",
      display: "inline-block",
      "span, Icon": {
        float: "left",
      },
      span: {
        marginRight: 10,
      },
    },
    ".buttons-row": {
      display: "inline-block",
      textAlign: "center",
      width: "100%",

      Button: {
        margin: "0 20px 20px 0",

        "&:last-of-type": {
          marginRight: 0,
        },
      },
    },
  },
}));

const reservationOptions = [
  { text: "Applicable", value: true },
  { text: "Not Applicable", value: false },
];
const applicableToOptions = [
  { text: "All Vehicles", value: "All Vehicles" },
  { text: "Other Inventory", value: "Other Inventory" },
];
const typeOptions = [
  { text: "Fixed Amount", value: "Fixed Amount" },
  { text: "Percentage of MSRP", value: "Percentage of MSRP" },
];

interface DealerReservationProps {
  form: any;
  dealer_code: string | number | null;
  formDefaultData: any;
}

const ReservationandDeposit: FC<DealerReservationProps> = ({
  form,
  dealer_code,
  formDefaultData,
}) => {
  const { actions } = useStoreContext();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = form;
  const currencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const { mutate: getReservationDepositData } = useGetReservationByDealerId();
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");

  const depoistValue = watch("deposit.dep_reservation");
  const isDepositApplicable = depoistValue === true;
  const deposiType = watch("deposit.dep_reservation_type");

  const reservationValue = watch("reservation.res_reservation");
  const isReservationApplicable = reservationValue === true;
  const reservationType = watch("reservation.res_reservation_type");

  useEffect(() => {
    if (dealer_code) {
      getReservationDepositData(
        { id: dealer_code },
        {
          onSuccess(data: any) {
            if (data) {
              setValue("reservation.id", data?.reservation.id ?? null);
              setValue(
                "reservation.res_reservation",
                data?.reservation?.reservation ?? false
              );
              setValue(
                "reservation.res_reservation_type",
                data?.reservation?.reservation_type ?? null
              );
              setValue(
                "reservation.res_reservation_amount",
                data?.reservation?.reservation_amount ?? "0.00"
              );
              setValue(
                "reservation.res_applicable_to",
                data?.reservation?.applicable_to ?? null
              );
              setValue("deposit.id", data?.deposit?.id ?? null);
              setValue(
                "deposit.dep_reservation",
                data?.deposit?.reservation || false
              );
              setValue(
                "deposit.dep_reservation_type",
                data?.deposit?.reservation_type ?? null
              );
              setValue(
                "deposit.dep_reservation_amount",
                data?.deposit?.reservation_amount ?? "0.00"
              );
              setValue(
                "deposit.dep_applicable_to",
                data?.deposit?.applicable_to ?? null
              );
            }
          },
        }
      );
    }
  }, [dealer_code, getReservationDepositData, setValue]);

  useEffect(() => {
    if (reservationType === "Fixed Amount") {
      setValue("reservation.res_reservation_amount", "0.00");
    } else {
      setValue("reservation.res_reservation_amount", "0.00");
    }
  }, [reservationType, setValue]);

  useEffect(() => {
    if (deposiType === "Fixed Amount") {
      setValue("deposit.dep_reservation_amount", "0.00");
    } else {
      setValue("deposit.dep_reservation_amount", "0.00");
    }
  }, [deposiType, setValue]);

  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === companyProfile?.default_currency
    );

    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [companyProfile]);

  return (
    <TabWrap className="tab-wrap" theme={theme}>
      <Typography
        variant="h4"
        component="h4"
        theme={theme}
        children="Reservation"
      />
      <Typography
        variant="caption"
        component="p"
        theme={theme}
        className="text-secondary"
        mb={2}
        children="Reservation amount will be paid 
        by the customers before the dealer will confirm 
        availability of a vehicle so that customers can start a credit application."
      />
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ mb: 2.7 }}
        theme={theme}
      >
        <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
          <Controller
            name="reservation.res_reservation"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Select
                theme={theme}
                label={"Reservation"}
                items={reservationOptions.map((option) => {
                  return { value: option.value, text: option.text };
                })}
                {...field}
                value={field.value ?? ""}
                onChange={(e) => {
                  field.onChange(e);
                  setValue("reservation.res_reservation_type", "");
                  setValue("reservation.res_reservation_amount", "0.00");
                  setValue("reservation.res_applicable_to", "");
                }}
                sxProps={""}
                selectError={errors?.reservation?.res_reservation?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
          <Controller
            name="reservation.res_applicable_to"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Select
                theme={theme}
                disablePortal
                label={"Applicable To"}
                items={applicableToOptions.map((option) => {
                  return { value: option.value, text: option.text };
                })}
                sxProps={""}
                {...field}
                value={field.value ?? ""}
                onChange={field.onChange}
                selectError={errors?.reservation?.res_applicable_to?.message}
                disabled={!isReservationApplicable}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
          <Controller
            name="reservation.res_reservation_type"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Select
                theme={theme}
                disablePortal
                label={"Type"}
                items={typeOptions.map((option) => {
                  return { value: option.value, text: option.text };
                })}
                sxProps={""}
                {...field}
                value={field.value ?? ""}
                onChange={field.onChange}
                selectError={errors?.reservation?.res_reservation_type?.message}
                disabled={!isReservationApplicable}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
          <Controller
            name="reservation.res_reservation_amount"
            control={control}
            defaultValue={"0.00"}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={"Type here..."}
                label={
                  reservationType === "Fixed Amount"
                    ? "Reservation Amount"
                    : reservationType === "Percentage of MSRP"
                    ? "% of MSRP"
                    : "Reservation Amount"
                }
                type="text"
                id="reservationamount"
                startAdornment={
                  <span className="adornment-text">
                    {reservationType === "Percentage of MSRP"
                      ? "%"
                      : defaultCurrency}
                  </span>
                }
                adornmentOnFocus
                value={field.value ?? "0.00"}
                masking
                maskDecimalScale={2}
                maskNumeric
                maskAllowNegative={false}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                }}
                error={errors?.reservation?.res_reservation_amount?.message}
                disabled={!isReservationApplicable}
              />
            )}
          />
        </Grid>
      </Grid>

      <Box theme={theme}>
        <Box sx={{ mb: 3 }} theme={theme}>
          <hr />
        </Box>

        <Typography
          variant="h4"
          component="h4"
          theme={theme}
          children="Deposit"
        />
        <Typography
          variant="caption"
          component="p"
          theme={theme}
          className="text-secondary"
          mb={2}
          children="Deposit amount will be paid by customers at the time of signing the buyer's order."
        />
        <Grid
          container
          rowSpacing={3}
          theme={theme}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mb: 2.7 }}
        >
          <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
            <Controller
              name="deposit.dep_reservation"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Select
                  theme={theme}
                  label={"Deposit"}
                  items={reservationOptions.map((option) => {
                    return { value: option.value, text: option.text };
                  })}
                  {...field}
                  value={field.value ?? ""}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("deposit.dep_reservation_type", "");
                    setValue("deposit.dep_reservation_amount", "0.00");
                    setValue("deposit.dep_applicable_to", "");
                  }}
                  sxProps={""}
                  selectError={errors?.deposit?.dep_reservation?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
            <Controller
              name="deposit.dep_applicable_to"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  theme={theme}
                  disablePortal
                  label={"Applicable To"}
                  items={applicableToOptions.map((option) => {
                    return { value: option.value, text: option.text };
                  })}
                  sxProps={""}
                  {...field}
                  value={field.value ?? ""}
                  onChange={field.onChange}
                  selectError={errors?.deposit?.dep_applicable_to?.message}
                  disabled={!isDepositApplicable}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
            <Controller
              name="deposit.dep_reservation_type"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  theme={theme}
                  disablePortal
                  label={"Type"}
                  items={typeOptions.map((option) => {
                    return { value: option.value, text: option.text };
                  })}
                  sxProps={""}
                  {...field}
                  value={field.value ?? ""}
                  onChange={field.onChange}
                  selectError={errors?.deposit?.dep_reservation_type?.message}
                  disabled={!isDepositApplicable}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} theme={theme}>
            <Controller
              name="deposit.dep_reservation_amount"
              control={control}
              defaultValue={"0.00"}
              render={({ field }) => (
                <Input
                  theme={theme}
                  fullWidth
                  placeholder={"Type here..."}
                  label={
                    deposiType === "Fixed Amount"
                      ? "Deposit Amount"
                      : deposiType === "Percentage of MSRP"
                      ? "% of MSRP"
                      : "Deposit Amount"
                  }
                  type="text"
                  id="depositamount"
                  startAdornment={
                    <span className="adornment-text">
                      {deposiType === "Percentage of MSRP"
                        ? "%"
                        : defaultCurrency}
                    </span>
                  }
                  adornmentOnFocus
                  value={field.value ?? "0.00"}
                  masking
                  maskDecimalScale={2}
                  maskNumeric
                  maskAllowNegative={false}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  error={errors?.deposit?.dep_reservation_amount?.message}
                  disabled={!isDepositApplicable}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </TabWrap>
  );
};
export default ReservationandDeposit;
