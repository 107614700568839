import { validatePhoneNumberLength } from "@src/helpers/appHelper";
import * as yup from "yup";

export const basicValidator = yup
  .object({
    first_name: yup
      .string()
      .required("First Name is required")
      .max(50, "First Name must be at most 50 characters"),
    last_name: yup
      .string()
      .required("Last Name is required")
      .max(50, "Last Name must be at most 50 characters"),
    middle_name: yup
      .string()
      .nullable()
      .max(50, "Middle Name must be at most 50 characters"),
    ssn: yup
      .string()
      .required("Social Security Number is required")
      .matches(/^\d{9}$/, "SSN must be exactly 9 digits"),
  })
  .required();
