import { Button } from "@src/components/atoms";
import "./verify-identity-completed.style.scss";

export const VerifyIdentityCompleted = ({
  changeStateOfEditModeHandler,
}: {
  changeStateOfEditModeHandler: (step: string) => void;
}) => {
  return (
    <div className="completed-verification">
      <div className="completed-verification header-wrapper">
        <h2 className="section-header upper-label">
          <span className="section-header number text--subsection-2">04</span>
          <span className="section-header text text--subsection-2">
            Identity verification.
          </span>
        </h2>
        <Button
          htmlType={"button"}
          text="Edit"
          onClick={() => changeStateOfEditModeHandler("step-four")}
          size="small"
        />
      </div>
      <div className="completed-verification content-container">
        <div className="completed-verification content-container__header-wrap">
          <div
            className="completed-verification content-container__header-wrap__svg-icon bmw-icon bmw-icon--before data-icon"
            data-icon="checkmark"
          />
          <div className="completed-verification content-container__header-wrap__header text--body-1">
            Verification successful
          </div>
        </div>
        <div className="completed-verification content-container__divider" />
        <div className="completed-verification content-container__personal-details">
          <div className="completed-verification content-container__personal-details__detail-row">
            <div className="column">
              <span className="column detail-label text--disclaimer text-secondary">
                Name
              </span>
              <span className="column detail-value text--body-2">
                Christopher Runnen
              </span>
            </div>
            <div className="column">
              <span className="column detail-label text--disclaimer text-secondary">
                Date of Birth
              </span>
              <span className="column detail-value text--body-2">
                MM/DD/1981
              </span>
            </div>
          </div>
          <div className="completed-verification content-container__personal-details__detail-row">
            <div className="column">
              <span className="column detail-label text--disclaimer text-secondary">
                License number
              </span>
              <span className="column detail-value text--body-2">
                *** *** ***
              </span>
            </div>
            <div className="column">
              <span className="column detail-label text--disclaimer text-secondary">
                License expiry date
              </span>
              <span className="column detail-value text--body-2">
                MM/DD/YYYY
              </span>
            </div>
          </div>
          <div className="completed-verification content-container__personal-details__detail-row">
            <div className="column">
              <span className="column detail-label text--disclaimer text-secondary">
                Issuing state
              </span>
              <span className="column detail-value text--body-2">New York</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
