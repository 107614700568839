import {
  Button,
  FeedbackLabel,
  MessageAlert,
  ProgressBar,
} from "@src/components/atoms";

export const BannerInfoContent = ({
  buildInProgress,
  checkoutInProgress,
  hasAlert,
}: {
  buildInProgress?: boolean;
  checkoutInProgress?: boolean;
  haveDeals?: boolean;
  hasAlert?: boolean;
}) => {
  return (
    <>
      <div className="banner-info-content">
        {buildInProgress && (
          <>
            <div className="text-with__icon">
              <span
                className="bmw-icon bmw-icon--before data-icon"
                data-icon="price_tag"
              ></span>
              <p className="text--label text-white fw-normal">
                XX month term, your price $XX,XXX, $X,XXX due at signing.
              </p>
            </div>
            <FeedbackLabel type="success" text="[Status]." />
          </>
        )}
        {checkoutInProgress && (
          <>
            <div className="text-with__icon">
              <span
                className="bmw-icon bmw-icon--before data-icon"
                data-icon="price_tag"
              ></span>
              <p className="text--label text-white fw-normal">
                XX month term, your price $XX,XXX, $X,XXX due at signing.
              </p>
            </div>
            <div className="text-with__icon">
              <span
                className="bmw-icon bmw-icon--before data-icon"
                data-icon="lock_locked"
              ></span>
              <p className="text--label text-white fw-normal">
                This vehicle is reserved for you for 00:00:00.
              </p>
            </div>
            <FeedbackLabel type="success" text="[Status]." />
            <ProgressBar
              percent={75}
              label="[Prior Step] Completed"
              showInfo={false}
              showLabelIcon
              iconName="checkmark"
              onDark
            />
          </>
        )}
        {hasAlert && (
          <MessageAlert
            type="warning"
            title="Sorry, your credit is frozen. Please resume your check out to take action."
            showCloseButton={false}
          />
        )}
        <div className="dr-start-hero__button">
          {buildInProgress && (
            <>
              <div className="button__innner">
                <Button
                  type="primary"
                  htmlType={"button"}
                  text="Continue building deal"
                />
                <Button
                  htmlType={"button"}
                  type="primary"
                  text="Contact dealer"
                  onDark
                />
              </div>
              <Button
                linkButton
                htmlType={"button"}
                text="Remove deal"
                withIcon
                dataIcon="trash_can"
                onDark
              />
            </>
          )}
          {checkoutInProgress && (
            <div className="button__innner">
              <Button
                type="primary"
                htmlType={"button"}
                text="Proceed to [next step]"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
