import {
  useGetAssetCategories,
  useGetAssetConditions,
  useGetAssetTypes,
  useGetClientTypes,
  useGetDocumentTypes,
  useGetFinanceTypes,
  useGetProductTypes,
} from "./queries";

export const useGetClientTypeName = (clientTypeCode: string) => {
  const { data: clientTypes } = useGetClientTypes();
  if (!clientTypeCode || !clientTypes) return undefined;
  return clientTypes.find((ct) => ct.code === clientTypeCode).description;
};

export const useGetFinanceTypeName = (financeType: string): string => {
  const { data: financeTypes } = useGetFinanceTypes();
  if (!financeType || !financeTypes) return undefined;
  return financeTypes.find((ft) => ft.code === financeType).name;
};

export const useGetProductTypeName = (productType: string) => {
  const { data: productTypes } = useGetProductTypes();
  if (!productType || !productTypes) return undefined;
  return productTypes.find((ft) => ft.code === productType).name;
};

export const useGetAssetCategoryName = (assetCategory: string) => {
  const { data: assetCategories } = useGetAssetCategories();
  if (!assetCategory || !assetCategories) return undefined;
  return assetCategories.find((ft) => ft.code === assetCategory).description;
};

export const useGetAssetTypeName = (assetType: string) => {
  const { data: assetTypes } = useGetAssetTypes();
  if (!assetType || !assetTypes) return undefined;
  return assetTypes.find((ft) => ft.code === assetType).name;
};

export const useGetAssetConditionName = (assetCondition: string) => {
  const { data: assetConditions } = useGetAssetConditions();
  if (!assetCondition || !assetConditions) return undefined;
  return assetConditions.find((ft) => ft.code === assetCondition).description;
};

export const useGetDocumentType = (documentType: string): string => {
  const { data: documentTypes } = useGetDocumentTypes();
  if (!documentType || !documentTypes) return undefined;
  return documentTypes.find((ft) => ft.document_code === documentType)
    .description;
};
