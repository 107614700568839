import { FC } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { Grid } from '@ntpkunity/controls'
import CalculationSection from './calculation-section'
import AddCti from './AddCti'
import OrderDetails from './OrderDetails'
import { DeskingWrap as DeskingNewWrap } from '@components'

export const DeskingNew: FC = () => {
  const theme = useTheme()

  return (
    <LayoutWithSideNav theme={theme}>
      <DeskingNewWrap theme={theme} className="desking-wrap">
        <Grid theme={theme} container columnSpacing={2} rowSpacing={1} mt={2}>
          <Grid theme={theme} item xs={12}>
            <AddCti />
          </Grid>
          <Grid theme={theme} item lg={2} md={3} sm={4}>
            <OrderDetails />
          </Grid>
          <Grid theme={theme} item xs>
            <CalculationSection />
          </Grid>
        </Grid>
      </DeskingNewWrap>
    </LayoutWithSideNav>
  )
}
