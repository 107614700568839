import { OPEN_ASSET_DIALOG, CLOSE_ASSET_DIALOG, FETCH_ASSET_DETAILS_SUCCESS, FETCH_ASSET_DETAILS_FAILURE } from "../actions/assetDetailsActions";

const initialDialogState = {
    isOpen: false,
    currentAssetReferenceNum: null,
    assetDetails: null,
    loading: false,
    error: null,
};

const assetDialogReducer = (state = initialDialogState, action) => {
    switch (action.type) {
        case OPEN_ASSET_DIALOG:
            return { ...state, isOpen: true, currentAssetReferenceNum: action.payload, loading: false, error: null };

        case CLOSE_ASSET_DIALOG:
            return { ...state, isOpen: false, currentAssetReferenceNum: null, assetDetails: null, loading: false, error: null };

        case FETCH_ASSET_DETAILS_SUCCESS:
            return { ...state, loading: false, assetDetails: action.payload };

        case FETCH_ASSET_DETAILS_FAILURE:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};

export default assetDialogReducer;