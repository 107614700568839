import { all } from "redux-saga/effects";
import { watchWorkQueuesSaga } from "./workQueueSaga";
import { watchApplicantDetailsSaga } from "./applicantDetailsSaga";
import { watchAssetDetailsSaga } from "./assetDetailsSaga"
import { watchRiskAssessmentSaga } from "./riskAssessmentSaga";
import { watchFinanceStructureSaga } from "./financeStrcuctureSaga";
import { watchAuditTrailSaga } from "./auditTrailSaga";
import { watchMyNotesSaga } from "./myNotesSaga";

export default function* rootSaga() {
    yield all([watchWorkQueuesSaga(), watchApplicantDetailsSaga(), watchAssetDetailsSaga(), watchRiskAssessmentSaga(), watchFinanceStructureSaga(), watchAuditTrailSaga(),  watchMyNotesSaga(),]);
}