import { useTheme } from '@mui/material'
import { DashboardHeaderWrap } from './dashboard-header.style'
import { Box, Button, Grid, Icon, Typography } from '@ntpkunity/controls'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@helpers/links'

export const DashboardHeader = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  return (
    <DashboardHeaderWrap theme={theme} className="dashboard-header-wrap">
      <Grid theme={theme} container spacing={3}>
        <Grid theme={theme} item lg={3} xs={12}>
          <Typography theme={theme} variant="h3" component="h3">
            Hey, Slava Kornilov 👋🏻
          </Typography>
          <Typography theme={theme} variant="body2" component="p" mb={1}>
            Welcome back to your workspace
          </Typography>
          <Box theme={theme}>
            <Typography theme={theme} variant="caption" component="p">
              slavakornilov@dealerone.com
            </Typography>
            <Typography theme={theme} variant="caption" component="p">
              512-433-8321
            </Typography>
          </Box>
        </Grid>
        <Grid theme={theme} item lg={9} xs={12}>
          <Box theme={theme} className="btn-row-wrap">
            <Box theme={theme} className="btn-wrap-col col-one">
              <Button
                theme={theme}
                text="Analytics"
                startIcon={<Icon name="AnalyticsIcon" />}
                onClick={() => {
                  navigate(APP_ROUTES.ANALYTICS)
                }}
              />
              <Button
                theme={theme}
                text="Orders"
                startIcon={<Icon name="TransactionIcon" />}
                onClick={() => {
                  navigate(APP_ROUTES.WORKQUEUE)
                }}
              />
              {/*<Button*/}
              {/*  theme={theme}*/}
              {/*  text="Inventory"*/}
              {/*  startIcon={<Icon name="InventoryIcon" />}*/}
              {/*  onClick={() => {*/}
              {/*    navigate(APP_ROUTES.INVENTORY_MANAGEMENT)*/}
              {/*  }}*/}
              {/*/>*/}
              <Button
                theme={theme}
                text="Desking"
                startIcon={<Icon name="DeskingIcon" />}
                size="large"
                onClick={() => {
                  navigate(APP_ROUTES.DESKING_NEW)
                }}
              />
              <Button
                theme={theme}
                text="Scheduler"
                startIcon={
                  <img
                    src={require('../../../public/assets/images/Scheduler.svg')}
                    alt="Calendar"
                  />
                }
                onClick={() => {
                  navigate(APP_ROUTES.CALENDAR)
                }}
              />
            </Box>
            <Box theme={theme} className="btn-wrap-col col-two">
              <Button
                theme={theme}
                text="Add Quick Links"
                startIcon={<Icon name="AddCircleIcon" />}
                onClick={() => {}}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </DashboardHeaderWrap>
  )
}
