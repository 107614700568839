import React, { FC } from 'react'
import { Link, useTheme } from '@mui/material'
import { Box, Button, Dialog, DuoTab, Grid, Icon, Typography } from '@ntpkunity/controls'
import { BtnAddWrap, DuoTabWrap, VehicleCardUi, VehicleList } from '@components'
import { AddFeesContent } from './add-fees'

import vcImage from '../../../public/assets/images/vc-img.png'
import option_one from '@public/assets/options/img.png'
import option_two from '@public/assets/options/option2.png'

const AddOpptions: FC = () => {
  const theme = useTheme()
  const [openDialog, setOpenDialog] = React.useState(false)
  const handleOpen = () => {
    setOpenDialog(true)
  }
  const handleClose = () => {
    setOpenDialog(false)
  }

  const VlItem = ({
    name,
    price,
    image_path
  }: {
    name: string
    price: string
    image_path: string
  }) => {
    return (
      <>
        <Box theme={theme} className="vl-img-wrap">
          <img src={image_path} alt="Car" />
        </Box>
        <Box theme={theme} className="vl-details">
          <Typography
            theme={theme}
            variant="caption"
            component="p"
            className=" text-overflow vl-title-ul"
            children={name}
          />
          <Typography
            theme={theme}
            variant="caption"
            component="p"
            className=" text-overflow vl-title-ul"
            children={<b>{price}</b>}
          />
        </Box>
      </>
    )
  }

  const TabContent: FC = () => {
    const TabProducts: FC = () => {
      return (
        <>
          <Grid theme={theme} item lg={3} md={4} sm={6} xs={12} mt={2}>
            <VehicleCardUi theme={theme} className="vehicle-card full-width">
              <Typography theme={theme} className="tag" component="span">
                Dealer
              </Typography>
              <Box theme={theme} className="vc-img-wrap h-120">
                <img src={vcImage} alt="Product" />
              </Box>
              <Typography theme={theme} className="title" component="span">
                Sample Product Name
              </Typography>
              <Link className="link">View Details</Link>
              <Box theme={theme} className="coverage">
                <Typography component="span" variant="body2" theme={theme} children="Coverage:" />
                <Typography component="b" variant="body2" theme={theme} children="$350.00" />
              </Box>
              <Button fullWidth secondary theme={theme} text="Add" />
            </VehicleCardUi>
          </Grid>
        </>
      )
    }

    return (
      <>
        <DuoTab
          theme={theme}
          varient={'underline'}
          items={[
            {
              content: (
                <Grid theme={theme} spacing={2} container sx={{ mb: 5 }}>
                  <TabProducts />
                  <TabProducts />
                  <TabProducts />
                  <TabProducts />
                  <TabProducts />
                  <TabProducts />
                  <TabProducts />
                  <TabProducts />
                </Grid>
              ),
              title: 'Key'
            },
            {
              content: (
                <Grid theme={theme} spacing={2} container sx={{ mb: 5 }}>
                  <TabProducts />
                  <TabProducts />
                  <TabProducts />
                  <TabProducts />
                  <TabProducts />
                  <TabProducts />
                  <TabProducts />
                  <TabProducts />
                </Grid>
              ),
              title: 'Gap'
            },
            {
              content: (
                <Grid theme={theme} spacing={2} container sx={{ mb: 5 }}>
                  <TabProducts />
                  <TabProducts />
                  <TabProducts />
                  <TabProducts />
                  <TabProducts />
                  <TabProducts />
                  <TabProducts />
                  <TabProducts />
                </Grid>
              ),
              title: 'Dent'
            }
          ]}
          defaultTabIndex={0}
        />
      </>
    )
  }

  return (
    <>
      <BtnAddWrap theme={theme} className="btn-add-wrap">
        <Button
          theme={theme}
          fullWidth
          startIcon={<Icon name="AddCircleIcon" />}
          text="Add Options"
          size="medium"
          onClick={handleOpen}
        />
      </BtnAddWrap>
      <VehicleList theme={theme} className="vehicle-list" mt={1}>
        <ul className="vl-scroll">
          <li className="vl-item sm">
            <VlItem name={'ZED GPS Navigation'} price={'$200.00'} image_path={option_one} />
          </li>
          <li className="vl-item sm">
            <VlItem name={'Chrome Roof Rack z'} price={'$250,00'} image_path={option_two} />
          </li>
        </ul>
      </VehicleList>
      <Dialog
        theme={theme}
        variant={undefined}
        size="xl"
        title="Add Options"
        open={openDialog}
        onCloseDialog={handleClose}
        children={
          <>
            <DuoTabWrap theme={theme} className="duo-tab-wrap align-center sm">
              <DuoTab
                theme={theme}
                items={[
                  {
                    title: 'F&I Products',
                    content: <TabContent />
                  },
                  {
                    title: 'Options',
                    content: <TabContent />
                  },
                  {
                    title: 'Fees',
                    content: <AddFeesContent />
                  }
                ]}
                defaultTabIndex={0}
              />
            </DuoTabWrap>
          </>
        }
        customFooter={<Button theme={theme} primary text="Save Changes" onClick={handleClose} />}
      />
    </>
  )
}

export default AddOpptions
