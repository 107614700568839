import {
  Button,
  FeedbackLabel,
  Image,
  MultiSwatchCircles,
} from "@src/components/atoms";
import Vehicle from "../../../shared/assets/img/dr-start/vehicle.png";
import "./right-rail.scss";
import { RightRailProps } from "./right-rail.props";

export const RightRail = ({
  hasFinance,
  financeSoftCredit,
  financeNoCart,
  hasLease,
  leaseSoftCredit,
  leaseNoCart,
  cashAuth,
  cashUnAuth,
  cashNoCart,
}: RightRailProps) => {
  const hasAnyOption =
    hasFinance ||
    financeSoftCredit ||
    financeNoCart ||
    hasLease ||
    leaseSoftCredit ||
    leaseNoCart ||
    cashAuth ||
    cashUnAuth ||
    cashNoCart;

  return (
    <div className="right-rail-container">
      {hasAnyOption ? (
        <>
          <div className="right-rail__info">
            {/* Image */}
            <div className="right-rail__image">
              <div className="right-rail-image__content">
                <p className="text--body-2">2025 BMW iX xDrive50</p>
                <div className="right-rait-image__info">
                  <FeedbackLabel type="success" text="At dealership" />
                  <span
                    className="bmw-icon bmw-icon--before data-icon"
                    data-icon="information"
                  />
                </div>
              </div>
              <Image src={Vehicle} alt="image" aspectRatio="16:9" />
            </div>
            <div className="right-rail__divider"></div>
            {/* Add Ons */}
            <div className="right-rail__addons">
              <p className="text--body-2">Add-ons</p>
              {!financeNoCart && !leaseNoCart && !cashNoCart ? (
                <>
                  <MultiSwatchCircles />
                </>
              ) : (
                <p className="text--body-2 right-rail-addons__items">
                  0 Items{" "}
                  <span
                    className="bmw-icon bmw-icon--before data-icon"
                    data-icon="arrow_chevron_right"
                  />
                </p>
              )}
            </div>

            <div className="right-rail__divider"></div>
            {/* Payment */}

            <div className="right-rail__payment">
              <div className="right-rait-payment__content">
                {hasFinance || financeSoftCredit || financeNoCart ? (
                  <>
                    <div className="right-rait-payment__info">
                      <p className="text--body-2">Est. payments</p>
                      <span
                        className="bmw-icon bmw-icon--before data-icon"
                        data-icon="information"
                      />
                    </div>
                    <h6 className="text--body-1 fw-bold">
                      $612.32 <span className="fw-light">/month</span>
                    </h6>
                    <p className="text--label">
                      Finance for 60 months, $15,000 down, 7.09% est. APR
                    </p>
                  </>
                ) : hasLease || leaseSoftCredit || leaseNoCart ? (
                  <>
                    <div className="right-rait-payment__info">
                      <p className="text--body-2">Est. payments</p>
                      <span
                        className="bmw-icon bmw-icon--before data-icon"
                        data-icon="information"
                      />
                    </div>
                    <h6 className="text--body-1 fw-bold">
                      $624.32 <span className="fw-light">/month</span>
                    </h6>
                    <p className="text--label">
                      Lease for 36 months, $5,000 down, 12,000mi
                    </p>
                  </>
                ) : cashAuth || cashUnAuth || cashNoCart ? (
                  <>
                    <div className="right-rait-payment__info">
                      <p className="text--body-2"> Cash offer</p>
                      <span
                        className="bmw-icon bmw-icon--before data-icon"
                        data-icon="information"
                      />
                    </div>
                    <h6 className="text--body-1 fw-bold">$48,670.00</h6>
                  </>
                ) : (
                  <p>N/A</p>
                )}
              </div>
              <Button
                htmlType={"button"}
                text="Pricing details"
                size="small"
                fullWidth
              ></Button>
            </div>
          </div>

          {/* Footer */}
          {!financeSoftCredit &&
            !leaseSoftCredit &&
            !cashUnAuth &&
            !cashNoCart && (
              <div className="right-rail__bottom">
                {!cashAuth ? (
                  <p className="text--body-2">
                    Do a{" "}
                    <a href="/credit" className="text-underline fw-normal">
                      soft credit check
                    </a>{" "}
                    for better estimates.
                  </p>
                ) : (
                  <p className="text--body-2">
                    <a href="/sign" className="text-underline fw-normal">
                      Sign
                    </a>{" "}
                    in or{" "}
                    <a href="/sign" className="text-underline fw-normal">
                      register
                    </a>{" "}
                    to unlock your personalized pricing.
                  </p>
                )}
                <span
                  className="bmw-icon bmw-icon--before data-icon"
                  data-icon="search_offer"
                />
              </div>
            )}
        </>
      ) : (
        <div className="right-rail__not-avilable">
          <h1>N/A</h1>
        </div>
      )}
    </div>
  );
};
