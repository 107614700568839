import "./lease-tab-content.scss";
import { Input, Select, Slider } from "@src/components/atoms";
import { Accordion } from "@src/components/atoms/accordion/accordion.component";
import { Col, Row } from "antd";
import { TotalContributionAccordionContent } from "./total-contribution-accordion-content/total-contribution-accordion-content";
import { DueAtSigningAccordionContent } from "./due-at-signing-accordion-content/due-at-signing-accordion-content";
import { AdjustedCapitalizedCostAccordionContent } from "./adjusted-capitalized-cost-accordion-content/adjusted-capitalized-cost-accordion-content";

export const LeaseTabContent = () => {
  const LeaseAccordionItems = [
    {
      key: "1",
      label: (
        <div className="accordion-title-with-icon">
          Total Contribution
          <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
        </div>
      ),
      children: <TotalContributionAccordionContent />,
      extra: <span className="text--body-focus-price-1">$10,000</span>,
    },
    {
      key: "2",
      label: (
        <div className="accordion-title-with-icon">
          Due at Signing
          <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
        </div>
      ),
      children: <DueAtSigningAccordionContent />,
      extra: <span className="text--body-focus-price-1">$10,000</span>,
    },
    {
      key: "3",
      label: (
        <div className="accordion-title-with-icon">
          Adjusted Capitalized Cost
          <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
        </div>
      ),
      children: <AdjustedCapitalizedCostAccordionContent />,
      extra: <span className="text--body-focus-price-1">$45,970</span>,
    },
  ];
  return (
    <>
      <div className="update-payment-tab update-payment-tab--lease">
        <div className="payment-calculator">
          <div className="payment-calculator__price-card">
            <div className="payment-calculator__price-card__inner">
              <div className="payment-calculator__price-card__left">
                <div className="payment-calculator__price-card__pricing">
                  <span className="text--body-2 label">
                    MSRP
                    <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                  </span>
                  <div className="text--promo-focus-price price">$000,000</div>
                </div>
              </div>
              <div className="payment-calculator__price__inner__right">
                <div className="payment-calculator__price-card__pricing">
                  <span className="text--body-2 label">
                    Estimated finance
                    <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                  </span>
                  <div className="text--promo-focus-price price">
                    $0,000<span className="text--h3">/month</span>
                  </div>
                </div>
              </div>
            </div>
            <p className="text--disclaimer">
              Includes taxes & fees, destination, on approved credit.
            </p>
          </div>
          <div className="payment-calculator__secion">
            <Row gutter={[0, { xs: 24, sm: 24, md: 24, lg: 32, xl: 40 }]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="payment-calculator__secion__slider">
                  <div className="payment-calculator__secion__slider__title">
                    <span className="text--label text-secondary label">
                      Term length
                    </span>
                    <span className="text--cta-2 additional">24 months</span>
                  </div>
                  <Slider
                    min={1}
                    max={5}
                    step={1}
                    dots={true}
                    defaultValue={1}
                    tooltipFormatter={(value) => `${value}`}
                    onChange={() => {}}
                  />
                  <div className="payment-calculator__secion__slider__value text--label text-secondary">
                    <span>24 months</span>
                    <span>72 months</span>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Input
                  id={"idCashdown"}
                  name={""}
                  placeholder=""
                  label="Cash down"
                  showInfoIcon
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Select
                  id={"dropdownLease miles"}
                  placeholder=""
                  label="Lease miles"
                  showInfoIcon
                  options={[
                    { value: "1", label: "7,500 miles", disabled: false },
                    { value: "2", label: "Option 2", disabled: false },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Select
                  id={"dropdownCreditScore"}
                  placeholder=""
                  label="Credit score"
                  showInfoIcon
                  options={[
                    {
                      value: "1",
                      label: "Very good (700-739)",
                      disabled: false,
                    },
                    { value: "2", label: "Option 2", disabled: false },
                  ]}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="accurate-apr-banner">
          <div className="accurate-apr-banner__text">
            <p className="text--body-2">
              Get a more precise monthly payment with a{" "}
              <span className="text--link-2 accurate-apr-banner__text__link">
                soft credit check
              </span>
              .
            </p>
          </div>
          <div className="accurate-apr-banner__icon">
            <i className="bmw-icon bmw-icon--after bmw-icon--search-offer"></i>
          </div>
        </div>
        <div className="pricing-details">
          <div className="pricing-details__heading">
            <div className="text--subsection-1">Pricing details.</div>
          </div>
          <div className="pricing-details__inner">
            <div className="pricing-details-accordion">
              <Accordion
                items={LeaseAccordionItems}
                defaultActiveKey={0}
                ghost={false}
                size="small"
                fullWidth
              />
            </div>
          </div>
          <p className="text--disclaimer pricing-details__due-payment">
            $7,500 residual value (25%), with the first payment due MM/DD/2024
            and a monthly sales tax of $0,000.The first payment is due on
            MM/DD/YYYY.{" "}
          </p>
        </div>
      </div>
    </>
  );
};
