import { Grid } from "@ntpkunity/controls";
import { ProposalFieldView } from "./proposal-field-view.component";
import { type Theme } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types/forms";
import { calculateDateDifference, toCapitalizedWords } from "@helpers/utils";
import { useTheme } from "@mui/material";
import { useGetClientTypeName } from "@hooks/useSetupNames";
import { ClientTypes } from "@helpers/enum";

export const AddressViewDetailsList = () => {
  const theme = useTheme();
  const { watch } = useFormContext<TProposalForm>();

  const { city, zipCode, county, addressLine1, addressLine2, moveInDate } =
    watch("customerDetails.address");
  const clientType = watch("customerDetails.clientType");
  const clientTypeName = useGetClientTypeName(clientType);
  const addressDetails = Object.entries({
    city: city,
    post_code: zipCode,
    county: county,
    address_line_1: addressLine1,
    address_line_2: addressLine2,
  }).map(([key, value]) => {
    return {
      label: toCapitalizedWords(key.replace(/_/g, " ")),
      value,
    };
  });

  if (clientTypeName === ClientTypes.INDIVIDUAL) {
    const formattedMoveInDate = moveInDate
      ? `${moveInDate.slice(0, 2)}/${moveInDate.slice(2)}`
      : "-";
    const timeAtAddress = moveInDate
      ? calculateDateDifference(
          Number(moveInDate.slice(0, 2)),
          Number(moveInDate.slice(2))
        )
      : "-";

    addressDetails.push(
      { label: "Move In Date", value: formattedMoveInDate },
      { label: "Time at Address", value: timeAtAddress }
    );
  }

  const addressDetail = addressDetails.map((field, key) => (
    <ProposalFieldView
      key={key}
      label={field.label}
      value={field.value || "-"}
    />
  ));

  return (
    <Grid container item columnSpacing={{ xs: 4, md: 8 }} theme={theme}>
      {addressDetail}
    </Grid>
  );
};
