import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const DuoTabWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({}) => ({
  '&.duo-tab-wrap': {
    '&.align-center': {
      '.TabsUnstyled-root': {
        '.TabsListUnstyled-root': {
          justifyContent: 'center',
          margin: '0 auto 24px auto',
          width: 'max-content',
          '.TabUnstyled-root': {
            width: 'auto'
          }
        },
        '.tab-panel-default': {
          '.TabsUnstyled-root .TabsListUnstyled-root': {
            justifyContent: 'inherit',
            margin: 'inherit',
            width: 'inherit',
            '.TabUnstyled-root': {
              width: 'inherit'
            }
          }
        }
      }
    },
    '&.sm': {
      '.TabsUnstyled-root': {
        '.TabsListUnstyled-root': {
          '&.tab-list-default': {
            marginBottom: 24
          },
          '.TabUnstyled-root': {
            padding: '4px 12px 4px 12px'
          }
        },
        '.tab-panel-default': {
          '.TabsUnstyled-root': {
            '.TabsListUnstyled-root': {
              '.TabUnstyled-root': {
                padding: '8px 16px'
              }
            },
            '.tab-list-underline': {
              paddingTop: 0
            }
          }
        }
      }
    },

    '&.fixed-child-tab-height': {
      '.TabsUnstyled-root': {
        // minHeight: '62vh'
      },
      '.tab-panel-default': {
        '.table-dialog': {
          '.scroll .scroll-hide.sh-fixed-cell': {
            height: '96.5px',
            '&:after': {
              top: '49.8%'
            }
          },
          '.u-table-wrap': {
            '.u-table-container': {
              maxHeight: 'calc(100vh - 401px)',
              '.u-table': {
                '.u-table-head': {
                  tr: {
                    td: {
                      height: '48px' + '!important'
                    }
                  }
                }
              }
            }
          },
          '&.with-tabs': {
            '.u-table-wrap': {
              '.u-table-container': {
                maxHeight: 'calc(100vh - 466px)'
              }
            }
          }
        }
      }
    }
  }
}))
