import Http from "shared/helper/http-api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useStoreContext } from "Stores/SetupStore/Store";
import { IDealerAppointment } from "Interface";
import { QueryKeys, ToastMessages } from "Enums";
import { ConfigurationAPI } from "services";

export const getAllDealerAppointmentsFilterFromServer = (
  columnFilters: string
) => {
  return ConfigurationAPI.get(
    `/dealer-appointment/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllDealerAppointments_filter = (
  columnFilters: string
) => {
  return useQuery(
    [QueryKeys.GET_ALL_DEALER_APPOINTMENTS, columnFilters],
    async () => {
      return getAllDealerAppointmentsFilterFromServer(columnFilters).then(
        (data) => {
          return data;
        }
      );
    },
    { keepPreviousData: true }
  );
};

export const useUpdateDealerAppointment = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<{ body: any }, any>(
    (body: any) => {
      delete body.appointment_type;
      body.cushion_time === "" ? (body.cushion_time = null) : body.cushion_time,
        body.in_transit_cushion_days === ""
          ? (body.in_transit_cushion_days = null)
          : body.in_transit_cushion_days;
      const apiService = Http.createConnection();
      return apiService.patch<any>(`${"dealer-appointment"}/${body.id}`, body);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALER_APPOINTMENTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError(response: any) {},
    }
  );
  return { data, isLoading, mutate };
};

export const useMutation_DeleteDealerAppointment = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IDealerAppointment>(
    (appointment_id) => {
      return ConfigurationAPI.delete(`/dealer-appointment/${appointment_id}`);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALER_APPOINTMENTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_AddDealerAppointment = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IDealerAppointment>(
    (body: any) => {
      body.cushion_time === "" ? (body.cushion_time = null) : body.cushion_time,
        body.in_transit_cushion_days === ""
          ? (body.in_transit_cushion_days = null)
          : body.in_transit_cushion_days;
      return ConfigurationAPI.post("/create-dealer-appointment", body);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALER_APPOINTMENTS),
          actions.setToast({
            toastMessage: ToastMessages.Record_Added_Success,
            toastState: true,
          });
      },
    }
  );
};

export const getAllConfigruedAppointmentsFromServer = () => {
  return ConfigurationAPI.get("/appointment-type").then((res) => {
    return res.data;
  });
};

export const useQuery_GetAllConfiguredAppointments = () => {
  return useQuery(QueryKeys.GET_ALL_CONFIGURED_APPOINTMENTS, async () => {
    return getAllConfigruedAppointmentsFromServer().then((data) => {
      return data;
    });
  });
};

export const getAllConfigruedTimeSlotIntervalsFromServer = () => {
  return ConfigurationAPI.get("/time-slot-interval").then((res) => {
    return res.data;
  });
};

export const useQuery_GetAllConfiguredTimeSlotIntervals = () => {
  return useQuery(QueryKeys.GET_ALL_CONFIGURED_TIMESLOTINTERVALS, async () => {
    return getAllConfigruedTimeSlotIntervalsFromServer().then((data) => {
      return data;
    });
  });
};
