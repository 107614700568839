import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const CreditScoreCounterWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.credit-score-counter-wrap": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 8,
    padding: 8,
    gap: 8,
    backgroundColor: "#E9EAFE",
    ".number": {
      color: "#474A59"
    },
  },
}));
