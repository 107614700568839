import { FC } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { PageHeader } from '@components'
import { Grid, Typography } from '@ntpkunity/controls'
import FullCalendar from '@fullcalendar/react'
// import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import { FullCalendarWrap, PageContent } from './calendar-page.style'

const events = [
  { title: 'Delivery schedule', start: new Date('2025-02-25T10:15:00') },
  { title: 'Vehicle Pick-up', start: new Date('2025-02-26T11:30:00') },
  { title: 'Test Drive Schedule', start: new Date('2025-02-27T14:00:00') },
  { title: 'Vehicle Delivery Inspection', start: new Date('2025-02-28T09:45:00') },
  { title: 'Vehicle Delivery Checklist', start: new Date('2025-02-29T12:30:00') },
  { title: 'Trade in Inspection', start: new Date('2025-02-30T15:00:00') },
  { title: 'Delivery schedule', start: new Date('2025-02-01T10:30:00') },
  { title: 'Vehicle Pick-up', start: new Date('2025-02-02T13:15:00') },
  { title: 'Test Drive Schedule', start: new Date('2025-02-03T11:00:00') },
  { title: 'Vehicle Delivery Inspection', start: new Date('2025-02-04T10:45:00') },
  { title: 'Vehicle Delivery Checklist', start: new Date('2025-02-05T12:00:00') },
  { title: 'Trade in Inspection', start: new Date('2025-02-06T14:30:00') },
  { title: 'Delivery schedule', start: new Date('2025-02-07T10:00:00') },
  { title: 'Vehicle Pick-up', start: new Date('2025-02-08T12:15:00') },
  { title: 'Test Drive Schedule', start: new Date('2025-02-09T11:30:00') },
  { title: 'Vehicle Delivery Inspection', start: new Date('2025-02-10T13:45:00') },
  { title: 'Vehicle Delivery Checklist', start: new Date('2025-02-11T10:15:00') },
  { title: 'Trade in Inspection', start: new Date('2025-02-12T15:00:00') },
  { title: 'Delivery schedule', start: new Date('2025-02-13T10:30:00') },
  { title: 'Vehicle Pick-up', start: new Date('2025-02-14T12:00:00') },
  { title: 'Test Drive Schedule', start: new Date('2025-02-15T11:15:00') },
  { title: 'Vehicle Delivery Inspection', start: new Date('2025-02-15T13:30:00') },
  { title: 'Vehicle Delivery Checklist', start: new Date('2025-02-15T15:00:00') },
  { title: 'Trade in Inspection', start: new Date('2025-02-03T10:15:00') },
  { title: 'Delivery schedule', start: new Date('2025-02-05T12:30:00') },
  { title: 'Vehicle Pick-up', start: new Date('2025-02-01T14:00:00') },
  { title: 'Test Drive Schedule', start: new Date('2025-02-06T11:30:00') },
  { title: 'Vehicle Delivery Inspection', start: new Date('2025-02-02T09:45:00') },
  { title: 'Vehicle Delivery Checklist', start: new Date('2025-02-04T12:00:00') },
  { title: 'Trade in Inspection', start: new Date('2025-02-08T15:00:00') },
  { title: 'Delivery schedule', start: new Date('2025-02-09T10:00:00') },
  { title: 'Vehicle Pick-up', start: new Date('2025-02-10T12:15:00') },
  { title: 'Test Drive Schedule', start: new Date('2025-02-11T11:00:00') },
  { title: 'Vehicle Delivery Inspection', start: new Date('2025-02-12T10:45:00') },
  { title: 'Vehicle Delivery Checklist', start: new Date('2025-02-13T12:30:00') },
  { title: 'Trade in Inspection', start: new Date('2025-02-14T14:00:00') },
  { title: 'Delivery schedule', start: new Date('2025-02-07T10:30:00') },
  { title: 'Vehicle Pick-up', start: new Date('2025-02-08T11:15:00') },
  { title: 'Delivery schedule', start: new Date('2025-02-08T16:15:00') },
  { title: 'Test Drive Schedule', start: new Date('2025-02-09T13:30:00') },
  { title: 'Vehicle Delivery Inspection', start: new Date('2025-02-10T15:00:00') },
  { title: 'Vehicle Delivery Checklist', start: new Date('2025-02-11T12:00:00') },
  { title: 'Trade in Inspection', start: new Date('2025-02-12T14:15:00') },
  { title: 'Delivery schedule', start: new Date('2025-02-13T11:45:00') },
  { title: 'Vehicle Pick-up', start: new Date('2025-02-14T10:00:00') },
  { title: 'Test Drive Schedule', start: new Date('2025-02-15T10:30:00') },
  { title: 'Vehicle Delivery Inspection', start: new Date('2025-02-15T12:45:00') },
  { title: 'Vehicle Delivery Checklist', start: new Date('2025-02-15T14:15:00') }
]

const renderEventContent = (eventInfo: any) => {
  return (
    <>
      <p className="fw-medium">{eventInfo.event.title}</p>
      <small className="text-muted text-uppercase">{eventInfo.timeText}</small>
    </>
  )
}

export const Calendar: FC = () => {
  const theme = useTheme()
  return (
    <LayoutWithSideNav theme={theme}>
      <PageContent theme={theme} className="page-content" pb={3}>
        <PageHeader
          className="main-page-header border-bottom"
          CalendarComponent
          theme={theme}
          container
          item
          spacing={2}
        >
          <Grid theme={theme} container spacing={2}>
            <Grid theme={theme} item lg={7} md={6} sm={12} xs={12}>
              <Typography theme={theme} variant="h2" component="h2">
                Calendar
              </Typography>
            </Grid>
          </Grid>
        </PageHeader>
        <FullCalendarWrap theme={theme} className="full-calendar-wrap">
          <FullCalendar
            plugins={[timeGridPlugin]}
            initialView="timeGridWeek"
            weekends={false}
            events={events}
            allDaySlot={false}
            eventContent={renderEventContent}
          />
        </FullCalendarWrap>
      </PageContent>
    </LayoutWithSideNav>
  )
}
