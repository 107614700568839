import { useTheme } from '@mui/material'
import { Box, Button, Grid, Input, Typography, } from '@ntpkunity/controls'
import { Stack, Textbox, CjButton } from '@components'
import { ProvideManualEstimatePopupWrap } from './provide-manual-estimate-popup.style'
import { Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { PaymentType } from "../../../constants/enums";
import { useAppContext } from "@app/context-provider";
import * as yup from 'yup';

export const ProvideManualEstimatePopup = (props) => {
    const theme = useTheme()
    const { control, messages, errors, setValue, getValues, unregister,setProgressValue,setCurrentStep,setShowProgressBar} = props;
    setShowProgressBar(true)
    setCurrentStep(1)
    setProgressValue(33.33)
    const values = getValues()
    const { payment_type, year, make, model, loan_lease_balance, is_customer_titled } = values
    const [loanPaymentStatus, setLoanPaymentStatus] = useState(loan_lease_balance || false)
    const [loanType, setLoanType] = useState(payment_type || PaymentType.Finance);
    const [customerType, setCustomerType] = useState(is_customer_titled || false);
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders
    useEffect(() => {
        setValue(messages.name.PaymentType, payment_type || PaymentType.Finance)
        setValue(messages.name.CustomerTitles, is_customer_titled)
    }, [])

    const yearSchema = yup.number()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable()
      .required('Year is required')
      .test('year-range', 'Year must be between 1900 and current year', (value) => {
        if (value === null || isNaN(value)) return false;
        const currentYear = new Date().getFullYear();
        return value >= 1900 && value <= currentYear;
      });

    const makeSchema = yup.string().required('Make is required')
    .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed');

    const handleLoanPaymentStatus = (value) => {

        if (!value) {
            setValue(messages.name.PaymentType, null)
            setValue(messages.name.CustomerTitles, null)
            unregister(messages.name.LenderName)
            setValue(messages.name.LenderName, null)
            unregister(messages.name.ContractNumber)
            setValue(messages.name.ContractNumber, null)
            unregister(messages.name.PayOffAmount)
            setValue(messages.name.PayOffAmount, null)
        }
        setValue(messages.name.LoanLeaseBalance, value)
        setLoanPaymentStatus(value)
    }
    const handleLoanType = (value) => {
        setValue(messages.name.PaymentType, value)
        setLoanType(value)
    }

    const handleCustomerType = (value) => {
        setValue(messages.name.CustomerTitles, value);
        setCustomerType(value);
    };

    return (
        <ProvideManualEstimatePopupWrap theme={theme} className='provide-manual-estimate-popup-wrap has-header-footer'>
            <Stack bgLight paddingXs={3} paddingMd={3}>
                <Typography theme={theme} component={'p'} variant='subtitle1' mb={2}>{PLACEHOLDERS.TRADE_IN_MANUAL_ESTIMATE_VEHICLE_QUESTION}</Typography>
                <Grid
                    theme={theme}
                    container
                    rowSpacing={3}
                    columnSpacing={3}
                >
                    <Grid item xs={6} theme={theme}>
                        <Controller
                            control={control}
                            name={messages.name.Year}
                            rules={{
                                validate: {
                                    yearRange: async (value) => {
                                        try {
                                            await yearSchema.validate(value);
                                            return true;
                                        } catch (error) {
                                            return error.message;
                                        }
                                    },
                                }
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <Textbox>
                                    <Input
                                        type={'number'}
                                        fullWidth
                                        theme={theme}
                                        label={messages.label.Year}
                                        placeholder={'Year'}
                                        error={error?.message}
                                        {...field}
                                    />
                                </Textbox>
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} theme={theme}>
                        <Controller
                            control={control}
                            name={messages.name.Make}
                            rules={{
                                validate: {
                                    alphabetsOnly: async (value) => {
                                        try {
                                            await makeSchema.validate(value);
                                            return true;
                                        } catch (error) {
                                            return error.message;
                                        }
                                    },
                                }
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <Textbox>
                                    <Input
                                        type={'text'}
                                        fullWidth
                                        theme={theme}
                                        label={messages.label.Make}
                                        placeholder={'Make'}
                                        error={error?.message}
                                        {...field}
                                    />
                                </Textbox>
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} theme={theme}>
                        <Controller
                            control={control}
                            name={messages.name.Model}
                            rules={{ required: true }}
                            render={
                                ({ field }) => (
                                    <Textbox>
                                        <Input
                                            type={'text'}
                                            fullWidth
                                            theme={theme}
                                            label={messages.label.Model}
                                            placeholder={'Model'}
                                            error={errors?.[messages.name.Model] ? messages.validation['Model'][errors?.[messages.name.Model]?.type] : false}
                                            {...field} />
                                    </Textbox>
                                )}
                        />
                    </Grid>
                    <Grid item xs={6} theme={theme}>
                        <Controller
                            control={control}
                            name={messages.name.Trim}
                            rules={{ required: true }}
                            render={
                                ({ field }) => (
                                    <Textbox>
                                        <Input
                                            type={'text'}
                                            fullWidth
                                            theme={theme}
                                            label={messages.label.Trim}
                                            placeholder={'Trim'}
                                            error={errors?.[messages.name.Trim] ? messages.validation['Trim'][errors?.[messages.name.Trim]?.type] : false}
                                            {...field} />
                                    </Textbox>
                                )}
                        />
                    </Grid>
                    <Grid item xs={12} theme={theme}>
                        <Controller
                            control={control}
                            name={messages.name.EstimatedValue}
                            rules={{ required: true }}
                            render={
                                ({ field }) => (
                                    <Textbox>
                                        <Input
                                            type='text'
                                            fullWidth
                                            theme={theme}
                                            label={messages.label.EstimatedValue}
                                            maskNumeric
                                            masking
                                            maskDecimalScale={0}
                                            startAdornment={<span className="symbol">{appState.default_currency_symbol}</span>}
                                            placeholder="0"
                                            error={errors?.[messages.name.EstimatedValue] ? messages.validation['EstimatedValue'][errors?.[messages.name.EstimatedValue].type] : false}
                                            {...field} />
                                    </Textbox>)}
                        />
                    </Grid>
                    <Grid item xs={12} theme={theme}>
                        <Controller
                            control={control}
                            name={messages.name.Miles}
                            rules={{ required: true }}
                            render={
                                ({ field }) => (
                                    <Textbox>
                                        <Input
                                            type="text"
                                            theme={theme}
                                            maskNumeric
                                            masking
                                            maskDecimalScale={0}
                                            label={messages.label.Miles}
                                            placeholder='0'
                                            error={errors?.[messages.name.Miles] ? messages.validation['Miles'][errors?.[messages.name.Miles].type] : false}
                                            {...field}
                                            endAdornment={
                                                <>
                                                    <Typography theme={theme} component={'p'} className='text-muted' variant='body1'>{appState?.mileage_unit}</Typography>
                                                </>
                                            }
                                            fullWidth
                                        />
                                    </Textbox>
                                )}
                        />
                    </Grid>
                </Grid>
            </Stack>
            <Stack bgLight paddingXs={3} paddingMd={3} marginTopMd={2} marginTopXs={2}>
                <Typography theme={theme} component={'p'} variant='subtitle1' mb={1}>
                    {PLACEHOLDERS.TRADE_IN_MANUAL_ESTIMATE_TITLE_QUERY}
                </Typography>
                <Box theme={theme} className='action-btn'>
                    <CjButton>
                        <Grid
                            theme={theme}
                            container
                            rowSpacing={3}
                            columnSpacing={1}
                        >
                            <Grid item xs={6} theme={theme}>
                                <Button
                                    theme={theme}
                                    defaultBtn={!customerType}
                                    secondary={customerType}
                                    text={PLACEHOLDERS.TRADE_IN_MANUAL_ESTIMATE_YES}
                                    fullWidth
                                    onClick={() => handleCustomerType(true)}
                                />
                            </Grid>
                            <Grid item xs={6} theme={theme}>
                                <Button
                                    theme={theme}
                                    defaultBtn={customerType}
                                    secondary={!customerType}
                                    text={PLACEHOLDERS.TRADE_IN_MANUAL_ESTIMATE_NO}
                                    fullWidth onClick={() => { handleCustomerType(false) }}
                                />
                            </Grid>
                        </Grid>
                    </CjButton>
                </Box>
            </Stack>
            <Stack bgLight paddingXs={3} paddingMd={3} marginTopXs={2} marginTopMd={2}>
                <Typography theme={theme} component={'p'} variant='subtitle1' mb={1}>
                    {PLACEHOLDERS.TRADE_IN_MANUAL_ESTIMATE_PAYMENTS_QUESTION}
                </Typography>
                <Box theme={theme} className='action-btn'>
                    <CjButton>
                        <Grid
                            theme={theme}
                            container
                            rowSpacing={3}
                            columnSpacing={1}
                        >
                            <Grid item xs={6} theme={theme}>
                                <Button
                                    theme={theme}
                                    defaultBtn={!loanPaymentStatus}
                                    secondary={loanPaymentStatus}
                                    text={PLACEHOLDERS.TRADE_IN_MANUAL_ESTIMATE_PAYMENTS_YES}
                                    fullWidth
                                    onClick={() => { handleLoanPaymentStatus(true) }}
                                />
                            </Grid>
                            <Grid item xs={6} theme={theme}>
                                <Button
                                    theme={theme}
                                    defaultBtn={loanPaymentStatus}
                                    secondary={!loanPaymentStatus}
                                    text={PLACEHOLDERS.TRADE_IN_MANUAL_ESTIMATE_PAYMENTS_NO}
                                    fullWidth onClick={() => { handleLoanPaymentStatus(false) }}
                                />
                            </Grid>
                        </Grid>
                    </CjButton>
                </Box>
                {loanPaymentStatus ? <>

                    <Typography theme={theme} component={'p'} variant='subtitle1' sx={{ mt: 3, mb: 1 }}>{PLACEHOLDERS.TRADE_IN_MANUAL_ESTIMATE_LOAN_TYPE_QUESTION}</Typography>
                    <CjButton>
                        <Grid
                            theme={theme}
                            container
                            rowSpacing={2}
                            columnSpacing={1}
                            sx={{ mb: 3 }}
                        >
                            <Grid item xs={6} theme={theme}>
                                <Button
                                    theme={theme}
                                    defaultBtn={loanType !== PaymentType.Lease}
                                    secondary={loanType === PaymentType.Lease}
                                    text={PLACEHOLDERS.TRADE_IN_MANUAL_ESTIMATE_PAYMENT_TYPE_LEASE}
                                    fullWidth
                                    onClick={() => handleLoanType(PaymentType.Lease)}
                                />
                            </Grid>
                            <Grid item xs={6} theme={theme}>
                                <Button
                                    theme={theme}
                                    secondary={loanType === PaymentType.Finance}
                                    defaultBtn={loanType !== PaymentType.Finance}
                                    text={PLACEHOLDERS.TRADE_IN_MANUAL_ESTIMATE_PAYMENT_TYPE_FINANCE}
                                    fullWidth
                                    onClick={() => handleLoanType(PaymentType.Finance)}
                                />
                            </Grid>
                        </Grid>
                    </CjButton>
                    <Grid
                        theme={theme}
                        container
                        rowSpacing={3}
                        columnSpacing={3}
                    >
                        <Grid theme={theme} item xs={12}>
                            <Controller
                                control={control}
                                name={messages.name.LenderName}
                                rules={{ required: true }}
                                render={
                                    ({ field }) => (
                                        <Textbox>
                                            <Input
                                                type={'text'}
                                                fullWidth
                                                theme={theme}
                                                label={messages.label.LenderName}
                                                placeholder={PLACEHOLDERS.TRADE_IN_MILES_POPUP_TYPE_HERE_PLACEHOLDER}
                                                error={errors?.[messages.name.LenderName] ? messages.validation['LenderName'][errors?.[messages.name.LenderName].type] : false}
                                                {...field}
                                            />
                                        </Textbox>
                                    )}
                            />
                        </Grid>
                        <Grid theme={theme} item xs={12}>
                            <Controller
                                control={control}
                                name={messages.name.ContractNumber}
                                rules={{ required: true }}
                                render={
                                    ({ field }) => (
                                        <Textbox>
                                            <Input
                                                type={'text'}
                                                fullWidth
                                                theme={theme}
                                                label={messages.label.ContractNumber}
                                                placeholder={PLACEHOLDERS.TRADE_IN_MILES_POPUP_TYPE_HERE_PLACEHOLDER}
                                                error={errors?.[messages.name.ContractNumber] ? messages.validation['ContractNumber'][errors?.[messages.name.ContractNumber].type] : false}
                                                {...field}
                                            />
                                        </Textbox>
                                    )}
                            />
                        </Grid>
                        <Grid theme={theme} item xs={12}>
                            <Controller
                                control={control}
                                name={messages.name.PayOffAmount}
                                rules={{ required: true }}
                                render={
                                    ({ field }) => (
                                        <Textbox>
                                            <Input
                                                type='text'
                                                fullWidth
                                                theme={theme}
                                                label={messages.label.PayOffAmount}
                                                maskNumeric
                                                masking
                                                maskDecimalScale={0}
                                                startAdornment={<span className="symbol">{appState.default_currency_symbol}</span>}
                                                placeholder='0'
                                                {...field}
                                                error={errors?.[messages.name.PayOffAmount] ? messages.validation['PayOffAmount'][errors?.[messages.name.PayOffAmount].type] : false}
                                            />
                                        </Textbox>
                                    )}
                            />
                        </Grid>
                    </Grid>
                </> :
                    false
                }
            </Stack>
        </ProvideManualEstimatePopupWrap>
    )
}


