import { Button, FeedbackLabel } from "@src/components/atoms";
import "./hero-scheduled-content.scss";
import { HeroScheduledContentProps } from "./hero-scheduled.props";

export const HeroScheduledContent = ({ type }: HeroScheduledContentProps) => {
  return (
    <div className="waiting-hub-hero__content">
      {type === "dealership" && (
        <div>
          <p className="text--body-2">My BMW order</p>
          <h2 className="text--h2">
            Thanks for providing supporting documents.
          </h2>
          <p className="text--body-2">
            Your contracts will be available to e-sign 24 hours before pickup.
          </p>
          <div className="waiting-hub-hero__status">
            <FeedbackLabel type="success" text="At dealership" />
            <span className="waiting-hub-hero__border"></span>
            <span className="text--label">Order #: 00000000000</span>
          </div>
          <div className="waiting-hub-hero__cta">
            <Button
              htmlType="button"
              type="primary"
              text="Schedule pickup"
              fullWidth
              onDark
            />
            <Button
              type="default"
              htmlType="button"
              text="Order summary"
              fullWidth
              onDark
            />
          </div>
        </div>
      )}
      {type === "production" && (
        <div>
          <p className="text--body-2">My BMW order</p>
          <h2 className="text--h2">
            Lorem ipsum dolor sit amet consectetur lorem feugiat lacus.
          </h2>
          <p className="text--body-2">
            Lorem ipsum dolor sit amet consectetur. Mauris integer velit
            faucibus turpis quis sit integer. Urna ultrices arcu nunc lorem a.
          </p>
          <div className="waiting-hub-hero__status">
            <FeedbackLabel type="warning" text="In production" />
            <span className="waiting-hub-hero__border"></span>
            <span className="text--label">Order #: 00000000000</span>
          </div>
          <hr />
          <div className="waiting-hub-hero__date">
            <span className="text--label">Estimated arrival date:</span>
            <p className="text--body-2 fw-normal">Month - Month 20XX</p>
          </div>
        </div>
      )}

      {type === "production-scheduled" && (
        <div>
          <p className="text--body-2">My BMW order</p>
          <h2 className="text--h2">
            Lorem ipsum dolor sit amet consectetur lorem feugiat lacus.
          </h2>
          <p className="text--body-2">
            Lorem ipsum dolor sit amet consectetur. Mauris integer velit
            faucibus turpis quis sit integer. Urna ultrices arcu nunc lorem a.
          </p>
          <hr />
          <div className="waiting-hub-hero__scheduled">
            <div>
              <span className="text--label">Confirmed pickup:</span>
              <p className="text--link-2">
                Oct. 19, 2024 <br /> 8:00 AM - 9:30 AM
              </p>
            </div>
            <div>
              <span className="text--label">Location:</span>
              <div className="text--link-2 text-underline">
                <p> 200 Varick Street, Suite 610 </p>
                <p className="waiting-hub-hero__location">
                  New York, NY 10454
                  <Button
                    htmlType={"button"}
                    linkButton
                    withIcon
                    dataIcon="duplicate"
                    onDark
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="waiting-hub-hero__cta">
            <Button
              type="default"
              htmlType="button"
              text="Order summary"
              onDark
            />
          </div>
        </div>
      )}
    </div>
  );
};
