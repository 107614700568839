import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Grid } from '@ntpkunity/controls'
import { TableList } from '@components'

const PaymentDetails: FC<{ type: string }> = ({ type }) => {
  const theme = useTheme()
  return (
    <Grid theme={theme} container columnSpacing={3} rowSpacing={2}>
      <Grid theme={theme} item xs={6}>
        <TableList
          listTitle="FA/Gross Capitalized Cost"
          items={[
            {
              title: 'Selling Price',
              value: <>{type === 'finance' ? '$66,875.00' : '$66,875.00'}</>
            },
            {
              title: 'Options',
              value: <>{type === 'finance' ? '$0.00' : '$0.00'}</>
            },
            {
              title: 'F&I Products',
              value: <>{type === 'finance' ? '$0.00' : '$0.00'}</>
            },
            {
              title: 'Fees',
              value: <>{type === 'finance' ? '$160.00' : '$310.00'}</>
            },
            {
              title: 'Trade-In Inequity',
              value: <>{type === 'finance' ? '$0.00' : '$0.00'}</>
            },
            {
              title: 'Taxes Capitalization',
              value: <>{type === 'finance' ? '$5,824.89' : '$1,111.33'}</>
            },
            {
              title: 'Total',
              value: <>{type === 'finance' ? '$72,859.89' : '$68,496.33'}</>,
              hasBoldText: true
            }
          ]}
        />
        <TableList
          listTitle="Capitalized Cost Reduction"
          items={[
            {
              title: 'Down Payment',
              value: <>{type === 'finance' ? '$15,718.75' : '$12,375.00'}</>
            },
            {
              title: 'Trade-In Equity',
              value: <>{type === 'finance' ? '$0.00' : '$0.00'}</>
            },
            {
              title: 'Rebates & Incentives',
              value: <>{type === 'finance' ? '$0.00' : '$0.00'}</>
            },
            {
              title: 'Total',
              value: <>{type === 'finance' ? '$15,718.75' : '$12,375.00'}</>,
              hasBoldText: true
            }
          ]}
        />
        <TableList
          listTitle="NFA/Adjusted Capitalized Cost"
          items={[
            {
              title: 'Balance',
              value: <>{type === 'finance' ? '$57,641.14' : '$55,621.33'}</>,
              hasBoldText: true
            }
          ]}
        />
      </Grid>
      <Grid theme={theme} item xs={6}>
        <TableList
          listTitle="Due at Signing"
          items={[
            {
              title: 'Down Payment',
              value: <>{type === 'finance' ? '$15,718.75' : '$12,375.00'}</>
            },
            {
              title: 'First Periodic Payment',
              value: <>{type === 'finance' ? '$2,477.49' : '$2,277.94'}</>
            },
            {
              title: 'Upfront Options',
              value: <>{type === 'finance' ? '$0.00' : '$0.00'}</>
            },
            {
              title: 'Upfront F&Is',
              value: <>{type === 'finance' ? '$0.00' : '$0.00'}</>
            },
            {
              title: 'Upfront Fees',
              value: <>{type === 'finance' ? '$500.00' : '$700.00'}</>
            },
            {
              title: 'Total',
              value: <>{type === 'finance' ? '$15,718.75' : '$14,652.94'}</>,
              hasBoldText: true
            }
          ]}
        />
        <TableList
          listTitle="Payment Details"
          items={[
            {
              title: 'Monthly Payment',
              value: <>{type === 'finance' ? '$2,477.49' : '$2,277.94'}</>
            },
            {
              title: 'Term',
              value: <>{type === 'finance' ? '24' : '24'}</>
            },
            {
              title: 'Buy Rate',
              value: <>{type === 'finance' ? '3.00%' : '2.25%'}</>
            },
            {
              title: 'Markup Rate',
              value: <>{type === 'finance' ? '0.00%' : '0.00%'}</>
            },
            {
              title: 'Sell Rate',
              value: <>{type === 'finance' ? '3.00%' : '2.25%'}</>
            },
            {
              title: 'Financed Charges',
              value: <>{type === 'finance' ? '$160.00' : '$310.00'}</>
            },
            {
              title: 'Financed Charges',
              value: <>{type === 'finance' ? '$59,459.76' : '$59,686.19'}</>,
              hasBoldText: true
            }
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default PaymentDetails
