import React, { FC } from 'react'
import { useTheme } from '@mui/material'
import { Box, Button, Dialog, Grid, Icon, Input, Typography } from '@ntpkunity/controls'
import { BtnAddWrap, Divider, VehicleList } from '@components'

export const AddFeesContent: FC = () => {
  const theme = useTheme()
  const FeesInputs: FC = () => {
    return (
      <>
        <Grid theme={theme} item xs={4}>
          <Input
            theme={theme}
            fullWidth
            type="text"
            label="Vendor (Optional)"
            placeholder="Type here.."
          />
        </Grid>
        <Grid theme={theme} item xs={3}>
          <Input
            theme={theme}
            fullWidth
            type="text"
            startAdornment="$"
            label="Amount"
            placeholder="Type here.."
          />
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid theme={theme} container rowSpacing={3}>
        <Grid theme={theme} container item columnSpacing={2}>
          <Grid theme={theme} item xs={5}>
            <Typography
              theme={theme}
              component="p"
              variant="body2"
              mt={1.5}
              children="License, Title, Registration"
            />
          </Grid>
          <FeesInputs />
        </Grid>
        <Grid theme={theme} container item columnSpacing={2}>
          <Grid theme={theme} item xs={5}>
            <Typography theme={theme} component="p" variant="body2" mt={1.5} children="Smog" />
          </Grid>
          <FeesInputs />
        </Grid>
        <Grid theme={theme} container item columnSpacing={2}>
          <Grid theme={theme} item xs={5}>
            <Typography theme={theme} component="p" variant="body2" mt={1.5} children="Transfer" />
          </Grid>
          <FeesInputs />
        </Grid>
        <Grid theme={theme} container item columnSpacing={2}>
          <Grid theme={theme} item xs={5}>
            <Typography
              theme={theme}
              component="p"
              variant="body2"
              mt={1.5}
              children="Electronic Title"
            />
          </Grid>
          <FeesInputs />
        </Grid>
        <Grid theme={theme} container item columnSpacing={2}>
          <Grid theme={theme} item xs={5}>
            <Typography
              theme={theme}
              component="p"
              variant="body2"
              mt={1.5}
              children="Documentation Fee"
            />
          </Grid>
          <FeesInputs />
        </Grid>
        <Grid theme={theme} container item columnSpacing={2}>
          <Grid theme={theme} item xs={5}>
            <Typography
              theme={theme}
              component="p"
              variant="body2"
              mt={1.5}
              children="Acquisition Fee (Waived with 50bps adder)"
            />
          </Grid>
          <FeesInputs />
        </Grid>
        <Grid theme={theme} container item columnSpacing={2}>
          <Grid theme={theme} item xs={5}>
            <Typography
              theme={theme}
              component="p"
              variant="body2"
              mt={1.5}
              children="Disposition Fee"
            />
          </Grid>
          <FeesInputs />
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2, mt: 3 }} />
      <Grid theme={theme} container rowSpacing={1} columnSpacing={0}>
        <Grid theme={theme} item xs={6}>
          <Typography theme={theme} component="p" variant="subtitle2" children="Total" />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <Typography
            theme={theme}
            component="p"
            variant="subtitle2"
            className="text-primary"
            textAlign="right"
            children="$1,199.00"
          />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2 }} />
    </>
  )
}

const AddFees: FC = () => {
  const theme = useTheme()
  const [openDialog, setOpenDialog] = React.useState(false)
  const handleOpen = () => {
    setOpenDialog(true)
  }
  const handleClose = () => {
    setOpenDialog(false)
  }

  const FeesItem: FC = () => {
    return (
      <>
        <Box theme={theme} className="vl-details">
          <Typography
            theme={theme}
            variant="caption"
            component="p"
            className=" text-overflow vl-title-ul"
            children="License, Title, Registration"
          />
          <Typography
            theme={theme}
            variant="caption"
            component="p"
            className=" text-overflow vl-title-ul"
            children={<b>$000.00</b>}
          />
        </Box>
      </>
    )
  }

  return (
    <>
      <BtnAddWrap theme={theme} className="btn-add-wrap">
        <Button
          theme={theme}
          fullWidth
          startIcon={<Icon name="EditIcon" />}
          text="Edit Fees"
          size="medium"
          onClick={handleOpen}
        />
      </BtnAddWrap>
      <VehicleList theme={theme} className="vehicle-list" mt={1}>
        <ul className="vl-scroll">
          <li className="vl-item sm">
            <FeesItem />
          </li>
          <li className="vl-item sm">
            <FeesItem />
          </li>
          <li className="vl-item sm">
            <FeesItem />
          </li>
          <li className="vl-item sm">
            <FeesItem />
          </li>
          <li className="vl-item sm">
            <FeesItem />
          </li>
          <li className="vl-item sm">
            <FeesItem />
          </li>
          <li className="vl-item sm">
            <FeesItem />
          </li>
          <li className="vl-item sm">
            <FeesItem />
          </li>
          <li className="vl-item sm">
            <FeesItem />
          </li>
          <li className="vl-item sm">
            <FeesItem />
          </li>
        </ul>
      </VehicleList>
      <Dialog
        variant={undefined}
        size="lg"
        title="Fees"
        open={openDialog}
        onCloseDialog={handleClose}
        customFooter={<Button theme={theme} primary text="Save Changes" onClick={handleClose} />}
        theme={theme}
        children={
          <>
            <AddFeesContent />
          </>
        }
      />
    </>
  )
}

export default AddFees
