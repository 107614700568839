import { useState } from "react";
import clsx from "clsx";
import { MessageAlertProps } from "./message-alert.props";
import "../../../styles/components/_message-alert.scss";
import { Collapse } from "antd";

const createIcon = (iconName: string, onClick?: () => void) => (
  <span
    className="bmw-icon bmw-icon--before data-icon message-alert__close"
    data-icon={iconName}
    onClick={onClick}
    onKeyDown={(e) => {
      if (e.key === "Enter" || e.key === " ") onClick?.();
    }}
    role="button"
    tabIndex={0}
    aria-label="Close alert"
  ></span>
);

const defaultIcons = {
  neutral: createIcon("information"),
  info: createIcon("information"),
  error: createIcon("error_warning_sign"),
  warning: createIcon("warning_sign"),
  success: createIcon("checkmark"),
};

const MessageAlertBody = ({ children }: { children: React.ReactNode }) => (
  <div className="message-alert__body">{children}</div>
);

export const MessageAlert = ({
  type = "neutral",
  title,
  titleIcon = true,
  subTitle,
  buttonText,
  onButtonClick,
  icon,
  contentTitle,
  contentDetail,
  showCloseButton = true,
  closeOnClick = false,
  viewType = "default",
  isExpanded = false,
  fullWidth,
  buttonFullWidth = false,
  dismissButton = false,
  buttonSize,
  actionArea,
}: MessageAlertProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const [expanded, setExpanded] = useState(isExpanded);

  const displayedIcon = icon || defaultIcons[type];

  const handleClose = () => {
    if (closeOnClick) {
      setIsVisible(false);
    }
  };

  if (!isVisible) return null;

  const { Panel } = Collapse;

  const bodyContent = (
    <>
      {subTitle && (
        <p
          className={`message-alert__label text--disclaimer ${
            title ? "message-alert__mt" : ""
          }`}
        >
          {subTitle}
        </p>
      )}
      {(contentTitle || contentDetail) && (
        <div className="message-alert__content">
          {contentTitle && <h6 className="text--body-2">{contentTitle}</h6>}
          {contentDetail && <p className="text--body-2">{contentDetail}</p>}
        </div>
      )}

      {actionArea && <div className="message-alert__button">{actionArea}</div>}
    </>
  );

  return (
    <div
      className={clsx("message-alert-container", `message-alert--${type}`, {
        "message-alert--full-width": fullWidth,
        "button--full-width": buttonFullWidth,
      })}
    >
      {viewType === "default" ? (
        <div className="message-alert__inner">
          <div className="message-alert__header">
            <div className="message-alert__title">
              {titleIcon && (
                <span className="message-alert__icon">{displayedIcon}</span>
              )}
              {title && <h6 className="text--body-2">{title}</h6>}
            </div>
            {showCloseButton && createIcon("close", handleClose)}
          </div>
          <MessageAlertBody>{bodyContent}</MessageAlertBody>
        </div>
      ) : (
        <Collapse
          activeKey={expanded ? ["1"] : []}
          onChange={(keys) => setExpanded(keys.includes("1"))}
        >
          <Panel
            header={
              <div className="message-alert__header">
                <div className="message-alert__title">
                  <span className="message-alert__icon">{displayedIcon}</span>
                  {title && <h6 className="text--body-2">{title}</h6>}
                </div>
                {showCloseButton && createIcon("arrow_chevron_down")}
              </div>
            }
            key="1"
          >
            <MessageAlertBody>{bodyContent}</MessageAlertBody>
          </Panel>
        </Collapse>
      )}
    </div>
  );
};

export default MessageAlert;
