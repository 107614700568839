import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const RevenueWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.revenue-wrap': {
    '.revenue-box': {
      backgroundColor: '#f2f2f2',
      borderRadius: 8,
      '.upper-section': {
        padding: 24,
        borderRadius: 8,
        border: '1px solid' + theme.palette.grey[50],
        backgroundColor: theme.palette.common.white,
        '.section-header': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 12,
          '.u-form-group': {
            '.u-form-control, .u-form-control.u-select': {
              height: 32,
              backgroundColor: theme.customVariables.tableStripedBg,
              '.MuiInputBase-input': {
                padding: '0px 35px 0px 12px',
                fontSize: 14,
                color: theme.palette.grey[600]
              },
              '.MuiSelect-icon': {
                right: 8
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: '0 solid transparent'
              }
            }
          }
        }
      },
      '.lower-section': {
        padding: '16px 24px'
      },
      '.label-price': {
        'p:first-child': {
          color: theme.palette.grey[600]
        },
        'p:last-child': {
          color: theme.palette.grey[900]
        }
      }
    }
  }
}))
