import "./cash-tab-content.scss";
import { Accordion } from "@src/components/atoms/accordion/accordion.component";
import { TotalPriceAccordionContent } from "./total-price-accordion-content/total-price-accordion-content";

export const CashTabContent = () => {
  const CashAccordionItems = [
    {
      key: "1",
      label: (
        <div className="accordion-title-with-icon">
          Total Price
          <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
        </div>
      ),
      children: <TotalPriceAccordionContent />,
      extra: <span className="text--body-focus-price-1">$41,972</span>,
    },
  ];
  return (
    <>
      <div className="update-payment-tab update-payment-tab--cash">
        <div className="payment-calculator">
          <div className="payment-calculator__price-card">
            <div className="payment-calculator__price-card__inner">
              <div className="payment-calculator__price-card__left">
                <div className="payment-calculator__price-card__pricing">
                  <span className="text--body-2 label">
                    Cash offer
                    <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                  </span>
                  <div className="text--promo-focus-price price">$000,000</div>
                </div>
              </div>
            </div>
            <p className="text--disclaimer">
              Include taxes & fees, destination
            </p>
          </div>
        </div>
        <div className="pricing-details">
          <div className="pricing-details__heading">
            <div className="text--subsection-1">Pricing details.</div>
          </div>
          <div className="pricing-details__inner">
            <div className="pricing-details-accordion">
              <Accordion
                items={CashAccordionItems}
                defaultActiveKey={0}
                ghost={false}
                size="small"
                fullWidth
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
