import React, { FC } from 'react'
import { Divider, useTheme } from '@mui/material'
import {
  Box,
  Button,
  DatePicker,
  Dialog,
  FileDragDrop,
  FilePreview,
  Grid,
  Icon,
  Input,
  Menu,
  Select,
  Typography
} from '@ntpkunity/controls'
import { BtnAddWrap } from '@components'

export const AddInsurance: FC = () => {
  const theme = useTheme()

  const [openDialog, setOpenDialog] = React.useState(false)
  const handleOpen = () => {
    setOpenDialog(true)
  }
  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <>
      <BtnAddWrap theme={theme} className="btn-add-wrap">
        <Button
          theme={theme}
          fullWidth
          startIcon={<Icon name="AddCircleIcon" />}
          text="Add Insurance"
          size="large"
          onClick={handleOpen}
        />
      </BtnAddWrap>
      <Box theme={theme} className="vl-item">
        <Box theme={theme} className="vl-details">
          <Typography
            theme={theme}
            variant="body2"
            component="p"
            className="text-primary text-overflow vl-main-title"
            children={<b>Insurance</b>}
          />
          <Typography
            theme={theme}
            variant="body2"
            component="p"
            className="text-overflow"
            children={<b>Gap Insurance</b>}
          />
          <Typography
            theme={theme}
            variant="caption"
            component="p"
            className=" text-overflow vl-title-ul"
            children={
              <>
                Contact: <b> +145-308-9315</b>, Policy No: <b>GI-6546</b>, Expiry: <b>30/10/2024</b>
              </>
            }
          />
        </Box>
        <Menu
          theme={theme}
          disablePortal
          options={[
            {
              optionText: (
                <>
                  <Icon className="menu-icon" name="EditIcon" /> Edit
                </>
              ),
              optionValue: 'edit'
            },
            {
              optionText: (
                <>
                  <Icon className="menu-icon" name="DeleteIcon" /> Delete
                </>
              ),
              optionValue: 'delete'
            }
          ]}
          render={(cb) => (
            <Button
              theme={theme}
              defaultBtn
              className="vl-menu-btn"
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            />
          )}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
      </Box>
      <Dialog
        variant={undefined}
        disablePortal
        size="md"
        title="Add Insurance"
        open={openDialog}
        onCloseDialog={handleClose}
        theme={theme}
        children={
          <>
            <Grid theme={theme} container rowSpacing={3} columnSpacing={3}>
              <Grid item theme={theme} xs={12} sm={6}>
                <Input
                  theme={theme}
                  fullWidth
                  label="Insurance Provider Name"
                  placeholder="Add here . . "
                  type="text"
                />
              </Grid>
              <Grid item theme={theme} xs={12} sm={6}>
                <Input
                  theme={theme}
                  fullWidth
                  label="Insurance Agent Name"
                  placeholder="Add here . . "
                  type="text"
                />
              </Grid>
              <Grid item theme={theme} xs={12} sm={6}>
                <Input
                  theme={theme}
                  fullWidth
                  label="Policy Number"
                  placeholder="Add here . . "
                  type="text"
                />
              </Grid>
              <Grid item theme={theme} xs={12} sm={6}>
                <DatePicker
                  label="Expiration"
                  disablePast
                  disablePortal
                  theme={theme}
                  inputReadOnly
                />
              </Grid>
              <Grid item theme={theme} xs={12}>
                <Divider />
              </Grid>
              <Grid item theme={theme} xs={12}>
                <Typography
                  theme={theme}
                  variant="subtitle1"
                  component="h5"
                  children="Insurance Provider Address"
                />
              </Grid>
              <Grid item theme={theme} xs={12}>
                <Grid theme={theme} container columnSpacing={2} rowSpacing={3}>
                  <Grid theme={theme} item xs={12}>
                    <Input
                      type="text"
                      theme={theme}
                      fullWidth
                      label="Address Line 1"
                      placeholder="Type here.."
                    />
                  </Grid>
                  <Grid theme={theme} item xs={12}>
                    <Input
                      type="text"
                      theme={theme}
                      fullWidth
                      label="Address Line 2"
                      placeholder="Type here.."
                    />
                  </Grid>
                  <Grid theme={theme} item lg={3} md={3} sm={6} xs={12}>
                    <Input
                      type="text"
                      theme={theme}
                      fullWidth
                      label="City"
                      placeholder="Type here.."
                    />
                  </Grid>
                  <Grid theme={theme} item lg={3} md={3} sm={6} xs={12}>
                    <Select
                      theme={theme}
                      fullWidth
                      label="State"
                      items={[
                        { text: 'Option 1', value: '1', disabled: true },
                        { text: 'Option 2', value: '2' },
                        { text: 'Option 3', value: '3' }
                      ]}
                    />
                  </Grid>
                  <Grid theme={theme} item lg={3} md={3} sm={6} xs={12}>
                    <Input
                      type="text"
                      theme={theme}
                      fullWidth
                      label="Zip Code"
                      placeholder="Type here.."
                    />
                  </Grid>
                  <Grid theme={theme} item lg={3} md={3} sm={6} xs={12}>
                    <Input
                      type="text"
                      theme={theme}
                      fullWidth
                      label="Country"
                      placeholder="Type here.."
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ mb: 3, mt: 3 }} />
            <Box theme={theme} sx={{ mb: 1 }}>
              <Typography
                theme={theme}
                variant="subtitle1"
                component="h5"
                children="Proof of Insurance"
              />
              <Typography
                theme={theme}
                variant="caption"
                component="span"
                children="Supported formats: JPG, JPEG, PNG."
              />
            </Box>
            <FileDragDrop
              theme={theme}
              width="300"
              height="200px"
              backgroundColor="white"
              allowMultiple={false}
              onChange={() => {}}
              onDrop={() => {}}
            />
            <FilePreview theme={theme} files={[]} onRemoveFile={() => {}} />
          </>
        }
        customFooter={
          <>
            <Button theme={theme} secondary text="Cancel" />
            <Button theme={theme} primary text="Save Changes" />
          </>
        }
      />
    </>
  )
}
