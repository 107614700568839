import clsx from "clsx";
import "../../../styles/components/_autocomplete.scss";
import { AutoComplete as AntAutoComplete } from "antd";
import { AutoCompletePropsCustom } from "./autocomplete.props";

export const AutoComplete = ({
  onSearch,
  onSelect,
  options,
  placeholder,
  filterOption,
  defaultOpen,
  label,
  helpText,
  error,
  showInfoIcon = false,
  ...rest
}: AutoCompletePropsCustom) => {
  return (
    <div
      className={clsx({
        "autocomplete-wrapper": true,
      })}
    >
      {label && (
        <label
          className={clsx({
            "autocomplete-label text--label text-secondary": true,
          })}
        >
          <span>{label}</span>
          {showInfoIcon && (
            <div
              className="input-label-icon"
              role="img"
              aria-label="Icon for information"
              data-icon="information"
            />
          )}
        </label>
      )}
      <AntAutoComplete
        options={options}
        onSelect={onSelect}
        onSearch={onSearch}
        placeholder={placeholder}
        filterOption={filterOption}
        defaultOpen={defaultOpen}
        {...rest}
      />
      <div className="input--input-helperText">
        {helpText && !error?.message && (
          <label className="help-text text-secondary text--disclaimer">
            {helpText}
          </label>
        )}
        {error && error?.message && (
          <label className="error-text text-error text--disclaimer">
            {error.message}
          </label>
        )}
      </div>
    </div>
  );
};
