import { FC, useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { Select, Input, Icon } from "@ntpkunity/controls";
import { ConfirmationDialog } from "@sharedComponents";
import { DealerContext } from "context";
import { logout } from "@ntpkunity/controls-ums";
import { Controller } from "react-hook-form";
import {
  ActionOptions,
  DialogMessages,
  ValidationMessages,
  QueryKeys,
} from "Enums";
import {
  useQuery_GetAllConfiguredAppointments,
  useQuery_GetAllConfiguredTimeSlotIntervals,
} from "services/dealer-appointment.service";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { useStoreContext } from "Stores/SetupStore/Store";
import { useQueryClient } from "react-query";

export declare type DealerAppointmentType = {
  popUpState: boolean;
  title: string;
  actionType: ActionOptions;
  setPopUpState: any;
  form: any;
  dealer_code?: string;
  enabledPin: boolean;
  setIsEditMode: any;
  isEditMode: boolean;
  setError: any;
};

const DealerAppointmentDrawer: FC<DealerAppointmentType> = ({
  form,
  setError,
}) => {
  const theme = useTheme();

  const [dealer_id, setDealerId] = useState("");

  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);

  const queryClient = useQueryClient();
  useQuery_GetAllConfiguredAppointments();
  useQuery_GetAllConfiguredTimeSlotIntervals();
  const configured_appointments: Array<any> = queryClient.getQueryData(
    QueryKeys.GET_ALL_CONFIGURED_APPOINTMENTS
  );
  const configured_timeslots: Array<any> = queryClient.getQueryData(
    QueryKeys.GET_ALL_CONFIGURED_TIMESLOTINTERVALS
  );
  const { dealerData, dealer_code } = useContext(DealerContext);

  const {
    control,
    reset,
    watch,
    formState: { errors, isDirty },
    getValues,
  } = form;
  useEffect(() => {
    if (dealerData) {
      setDealerId(
        dealerData?.find((obj) => obj?.dealer_code === dealer_code)?.id
      );
    }
  }, [dealerData]);
  const selectedAppointmentType = watch("appointment_id");
  const record = configured_appointments?.find(
    (record) => record.id === selectedAppointmentType
  );
  const appointmentType = record ? record.appointment_type : null;

  const onSaveConfirm = (e: any) => {
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );

  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <Controller
        name="appointment_id"
        control={control}
        defaultValue={""}
        rules={{
          required: ValidationMessages.APPOINTMENT_TYPE_REQUIRED,
        }}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Appointment Type"}
            placeholder={"Select"}
            items={
              configured_appointments?.map((item) => ({
                text: item.appointment_type,
                value: item.id,
              })) || []
            }
            value={value ?? []}
            onChange={onChange}
            selectError={errors?.appointment_id?.message}
          />
        )}
      />
      <Controller
        name="time_slot_interval_id"
        control={control}
        defaultValue={undefined}
        rules={{
          required: ValidationMessages.TIME_SLOT_INTERVAL_REQUIRED,
        }}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={"Time Slot Interval"}
            placeholder={"Select"}
            items={
              configured_timeslots?.map((item) => ({
                text: `${item.time_slot_in_minute} min`,
                value: item.id,
              })) || []
            }
            value={value ?? []}
            onChange={onChange}
            selectError={errors?.time_slot_interval_id?.message}
          />
        )}
      />
      {appointmentType === "Pickup" && (
        <Controller
          name="cushion_time"
          control={control}
          defaultValue={""}
          rules={{
            validate: (value) => {
              const strValue = value?.toString();
              if (strValue?.length > 9) {
                return "Number is too large. Please enter a smaller number.";
              }
              return true;
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              theme={theme}
              fullWidth
              placeholder={"Type here..."}
              label={"Cushion Time (Optional)"}
              type="number"
              value={value ?? ""}
              onChange={onChange}
              endAdornment={"days"}
              error={errors?.cushion_time?.message}
            />
          )}
        />
      )}
      {appointmentType === "Pickup" && (
        <Controller
          name="in_transit_cushion_days"
          control={control}
          defaultValue={""}
          rules={{
            validate: (value) => {
              const strValue = value?.toString();
              if (strValue?.length > 9) {
                return "Number is too large. Please enter a smaller number.";
              }
              return true;
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              theme={theme}
              fullWidth
              placeholder={"Type here.."}
              label={"In-Transit Cushion (Optional)"}
              type="number"
              value={value ?? ""}
              endAdornment={"days"}
              onChange={onChange}
              error={errors?.in_transit_cushion_days?.message}
            />
          )}
        />
      )}
      <Controller
        name="is_active"
        control={control}
        defaultValue={true}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            sxProps={""}
            label={"Status"}
            placeholder={"Select"}
            items={[
              { text: "Enabled", value: true },
              { text: "Disabled", value: false },
            ]}
            value={value}
            onChange={onChange}
          ></Select>
        )}
      />
    </>
  );
};

export default DealerAppointmentDrawer;
