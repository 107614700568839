import { legacy_createStore as createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; 
import rootReducer from "../reducers/rootReducer";
import rootSaga from "../sagas/rootSaga";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["workQueue"], 
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
