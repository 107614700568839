import { colors } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'
import { StyleVariables } from '@styles'

export const CUSTOMER_JOURNEY_THEME_WRAP = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.cj-global-theme-wrap': {
    '.MuiTypography-root': {
      fontFamily: StyleVariables.defaultFontFamily
    },
    '.fw-normal': {
      fontWeight: theme.typography.fontWeightRegular
    },
    '.fw-medium': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '.fw-sbold': {
      fontWeight: theme.customVariables.fontWeightSemiBold
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.grey-400': {
      color: theme.palette.grey[400]
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.text-center': {
      textAlign: 'center'
    },
    '.text-white': {
      color: theme.palette.common.white
    },
    '.text-danger': {
      color: theme.palette.error.main
    },
    '.decoration-none': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline !important'
      }
    },
    '.MuiLink-root': {
      '&:hover': {
        textDecoration: 'underline !important',
        cursor: 'pointer'
      }
    },
    '.link': {
      '&:hover': {
        textDecoration: 'underline !important',
        cursor: 'pointer'
      }
    },
    '.text-dark':{
      color:theme.palette.grey[900]
    },
    '.line-through':{
      textDecoration: 'line-through'
    },
    '.text-uppercase': {
      textTransform: 'uppercase'
    },
    '.gap-3': {
      gap: 24
    },
    '.gap-2': {
      gap: 16
    },
    '.text-subtitle2': {
      fontSize: theme.typography.subtitle2.fontSize,
    },
    '.line-height18': {
      lineHeight: '18px'
    },
    '.w-100': {
      width: '100%'
    },
    '.mw-100':{
      maxWidth: '100%'
    },
    '.w-m-100': {
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    '.dFlex': {
      display: 'flex'
    },
    '.flexWrap': {
      flexWrap: 'wrap'
    },
    '.cp':{
      cursor: 'pointer'
    },
    '.justifyContentBetween': {
      justifyContent: 'space-between'
    },
    '.text-truncated': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    'svg':{
      '&.text-primary': {
        'path': {
          stroke: theme.palette.primary.main
        }
      },
      '&.icon-light': {
        'path': {
          stroke: theme.palette.grey[400] + ' !important'
        }
      },
      '&.transparent': {
        'path': {
          stroke: 'transparent !important'
        }
      }
    },
    '.btn':{
      '&.with-icon': {
        '&.btn-default': {
          'svg': {
            '&.text-primary': {
              'path': {
                stroke: theme.palette.primary.main
              }
            }
          }
        },
        '&.btn-secondary': {
          '&:hover': {
            'svg': {
              '&.fill-icon': {
                path: {
                  stroke: theme.palette.common.white
                }
              }
            },
          },
          'svg': {
            '&.fill-icon': {
              path: {
                stroke: 'transparent'
              }
            }
          }
        }
      }
    },
    '.res-btn': {
      '.btn': {
        [theme.breakpoints.down('md')]: {
          width: '100%'
        }
      }
    },
    '.bg-light': {
      '.u-form-group': {
        '.u-input-label': {
          backgroundColor: '#F5F5F7'
        },
        '&.u-date-picker': {
          '.u-form-control': {
            '&:hover': {
              'fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.87)'
              }
            },
            '.MuiInputLabel-formControl': {
              backgroundColor: '#F5F5F7'
            }
          }
        }
      },
      '.u-phone-input': {
        '.form-control': {
          backgroundColor: '#F5F5F7'
        },
        '.u-input-label': {
          backgroundColor: '#F5F5F7'
        },
      }
    },
    '.aspect-ratio-image-wrap': {
      img: {
        borderRadius: 16
      }
    },
    '.MuiSkeleton-root': {
      transformOrigin: '0 0',
      transform: 'scale(1, 1)',
      borderRadius: theme.shape.borderRadius
    },
    '.u-date-picker': {
      '.MuiPaper-root': {
        '.MuiCalendarPicker-root': {
          '> div > div': {
            '+ div.PrivatePickersSlideTransition-root': {
              width: 'auto'
            }
          }
        }
      }
    },
    '.full-page-alert-wrap': {
      '&.full-page': {
        minHeight: 'calc(100vh - 160px)',
        [theme.breakpoints.down('md')]: {
          padding: '64px 24px'
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      '.text-h4': {
        fontSize: theme.typography.body1.fontSize,
        lineHeight: theme.typography.body1.lineHeight
      },
      '.text-h3': {
        fontSize: theme.typography.h4.fontSize,
        lineHeight: theme.typography.h4.lineHeight
      },
      '.text-h2': {
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight
      },
      '.text-body2': {
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.typography.caption.lineHeight
      }
    },


    ".cj-toaster": {
      backgroundColor: 'rgba(52, 199, 89, 0.08)',
      textAlign: 'center',
      borderRadius: 8,
      padding: 4,
      ".MuiTypography-root": {
        color: "#34C759",
        textTransform: 'uppercase'
      }
    }
  }
}))
