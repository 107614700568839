import { Button } from "@src/components/atoms/button/button.component";
import { PricingEstimatesSteps } from "../../../../pricing-estimates-steps/pricing-estimates-steps.component";
import "../../fl-pricing-drawer-content.scss";
import { BMW_BASE, URL_BMW } from "@src/constants/routes";

export const FinanceLeasePricingDrawerContentIntro = () => {
  return (
    <>
      <div className="fl-pricing-drawer-wrap">
        <div className="fl-pricing-drawer-wrap--intro">
          <h4 className="text--subsection-1">
            Get a more precise estimate for monthly payments.
          </h4>
        </div>
        <PricingEstimatesSteps
          stepCount={"01."}
          title="Unlock personalized pricing."
          description="A BMW ID is required to ensure your details and credit score are securely saved. Please sign in or register to continue."
          iconName="lock_unlocked"
          content={
            <div className="fl-pricing-drawer-wrap--inner">
              <div className="action-area--group">
                <Button
                  htmlType={"button"}
                  text="Sign in"
                  type="default"
                  onClick={() =>
                    (window.location.href = BMW_BASE + "login" + URL_BMW)
                  }
                />
                <Button
                  htmlType={"button"}
                  text="Register"
                  onClick={() =>
                    (window.location.href = BMW_BASE + "register" + URL_BMW)
                  }
                />
              </div>
            </div>
          }
        />
        <PricingEstimatesSteps
          stepCount={"02."}
          title="Get a more accurate APR %."
          iconName="lock_locked"
          disabled
        />
      </div>
    </>
  );
};
