import { Slider as AntSlider } from "antd";
import "../../../styles/components/_slider.scss";

export const Slider = ({
  label = "Slider",
  range = false,
  min = 1,
  max = 13,
  step = 1,
  dots = false,
  defaultValue,
  tooltipFormatter = (value) => value,
  onChange,
}) => {
  return (
    <div className="slider-container">
      {label && <label className="text--label text-secondary">{label}</label>}
      <AntSlider
        range={range}
        min={min}
        max={max}
        step={step}
        dots={dots}
        defaultValue={defaultValue}
        tooltip={{
          formatter: tooltipFormatter,
        }}
        onChange={onChange}
      />
      <div className="slider__value text--label text-secondary">
        <span>{min}</span>
        <span>{max}</span>
      </div>
    </div>
  );
};

export default Slider;
