import { FC, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { Box, Grid, Icon, Image, Typography } from '@ntpkunity/controls'
import { DetailCard, LeadSummaryStatusSection, PageHeader, ProductBanner } from '@components'
import CarImage from '../../../public/assets/cars-images/CarImage03.png'
import TradeInCarImage from '../../../public/assets/cars-images/CarImage04.png'
import { PageContent } from './lead-summary-page.style'

export const LeadSummaryPageRawn: FC = () => {
  const theme = useTheme()
  const [showAccordionDetail, setShowAccordionDetail] = useState(false)

  return (
    <LayoutWithSideNav theme={theme}>
      <PageContent theme={theme} className="page-content">
        <Box theme={theme} sx={{ mb: 2.5 }}>
          <PageHeader className="main-page-header border-bottom" theme={theme}>
            <Box flexGrow={'1'} theme={theme}>
              <Typography theme={theme} variant="h2" component="h2" className="title">
                Lead Summary
              </Typography>
              <Typography theme={theme} variant="body2" component="p" className="caption">
                You can review the lead details below and reach out to the customer
              </Typography>
            </Box>
          </PageHeader>
        </Box>
        <ProductBanner>
          <Grid theme={theme} spacing={2} container>
            <Grid theme={theme} item xl={2} lg={3} md={3} sm={5} xs={12}>
              <Box theme={theme} className="thumbnail">
                <Image theme={theme} src={CarImage} aspectRatio="16:9" alt="Product" />
              </Box>
            </Grid>
            <Grid
              theme={theme}
              item
              xl={5}
              lg={4.5}
              md={4.5}
              sm={12}
              xs={12}
              className="product-details"
            >
              <Box theme={theme} className="title-md">
                2021 Hyundai
              </Box>
              <Box theme={theme} className="title-lg">
                Veloster N DCT
              </Box>
              <Box theme={theme} className="title-sm">
                Lead ID: <b>SEP-0010</b>, Status: <span className="text-primary">Active</span>
              </Box>
              <Box theme={theme} className="title-sm">
                VIN: <b>1B7HF13Y2WJ198530</b>
              </Box>
            </Grid>
            <Grid
              theme={theme}
              item
              xl={5}
              lg={4.5}
              md={4.5}
              sm={12}
              xs={12}
              sx={{ mt: 2 }}
              className="product-details"
              textAlign="right"
            >
              <Box theme={theme} className="actions-wrap">
                <Box theme={theme}>
                  <Box theme={theme} className="title-md">
                    Net Financed Amount
                  </Box>
                  <Box theme={theme} className="title-lg">
                    $30,375.00
                  </Box>
                  <Box theme={theme} className="title-sm">
                    Type: <b>36 Month Lease</b>, Rate: <b>0.0000%</b>
                  </Box>
                  <Box theme={theme} className="title-sm">
                    <>Est. Periodic Payment: </>
                    <b>$843.75</b>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ProductBanner>
        <Box theme={theme} mb={3}>
          <LeadSummaryStatusSection
            items={[
              {
                title: 'Payments',
                subtitle: '01:15'
              },
              {
                title: 'F&I Products',
                subtitle: '02:00'
              },
              {
                title: 'Add-Ons',
                subtitle: '01:15',
                selected: true
              },
              {
                title: 'Rebates',
                subtitle: '00:00',
                error: true
              },
              {
                title: 'Trade-In',
                subtitle: '02:35'
              },
              {
                title: 'Insurance',
                subtitle: '00:00',
                error: true
              },
              {
                title: 'Schedule',
                subtitle: '01:25'
              },
              {
                title: 'Financing',
                subtitle: '02:45'
              }
            ]}
          />
        </Box>
        <Grid theme={theme} className="container" container spacing={2}>
          <Grid theme={theme} item xl={8} lg={8} md={8} sm={12} xs={12}>
            <DetailCard className="detail-card" mb={2}>
              <Typography theme={theme} mb={2} component="h4" variant="h4">
                Customer Details
              </Typography>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="Name"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    Ravn M.
                  </Typography>
                </Grid>
              </Grid>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="Address"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    2570 24th St. Sacramento, CA 95818
                  </Typography>
                </Grid>
              </Grid>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="Email"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    ravn@gmail.com
                  </Typography>
                </Grid>
              </Grid>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="Credit Score"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    Good
                  </Typography>
                </Grid>
              </Grid>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="Mobile Phone Number"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    -
                  </Typography>
                </Grid>
              </Grid>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="Preferred Communication"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    Email
                  </Typography>
                </Grid>
              </Grid>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="License Number"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    D628-412-94-506-2
                  </Typography>
                </Grid>
              </Grid>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="Date of Birth"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    25/01/1990
                  </Typography>
                </Grid>
              </Grid>
            </DetailCard>
            <DetailCard className="detail-card" mb={2}>
              <Typography theme={theme} mb={2} component="h4" variant="h4">
                Asset Details
              </Typography>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="Asset"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    2021 Hyundai Veloster N DCT
                  </Typography>
                </Grid>
              </Grid>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="Asset Condition"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    New
                  </Typography>
                </Grid>
              </Grid>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="Stock Number"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    172348242
                  </Typography>
                </Grid>
              </Grid>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="Current Mileage"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    10 Miles
                  </Typography>
                </Grid>
              </Grid>
            </DetailCard>
            <DetailCard className="detail-card" mb={2}>
              <Box className="card-body" theme={theme}>
                <Box theme={theme} mb={2}>
                  <Typography theme={theme} component="h4" variant="h4" children="Trade-In" />
                  <Grid theme={theme} className="container" container>
                    <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Typography theme={theme} variant="body2" component="h3" className="label">
                        2021 Mercedes-Benz
                      </Typography>
                      <Typography
                        theme={theme}
                        variant="subtitle1"
                        component="div"
                        className="card-title"
                      >
                        C-Class AMG C 43 4MATIC Cabriolet
                      </Typography>
                    </Grid>
                    <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Box theme={theme} className="thumbnail">
                        <Image
                          theme={theme}
                          src={TradeInCarImage}
                          alt="Product"
                          aspectRatio="16:9"
                        />
                      </Box>
                    </Grid>
                    <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Box theme={theme} className="priceList">
                        <Typography
                          theme={theme}
                          className="card-title"
                          mb={1}
                          component="h4"
                          variant="h4"
                        >
                          $11,625.00
                        </Typography>
                        <Box theme={theme} className="price-detail">
                          <Typography
                            theme={theme}
                            className="detail offer"
                            component="span"
                            variant="caption"
                            children="Our Offer:&nbsp;"
                          />
                          <Typography
                            theme={theme}
                            className="detail price"
                            component="span"
                            variant="caption"
                          >
                            $16,625.00
                          </Typography>
                        </Box>
                        <Box theme={theme} className="price-detail">
                          <Typography
                            theme={theme}
                            className="detail offer"
                            component="span"
                            variant="caption"
                            children={<>Lease Balance:&nbsp; </>}
                          />
                          <Typography
                            theme={theme}
                            className="detail price"
                            component="span"
                            variant="caption"
                          >
                            -$5,000.00
                          </Typography>
                        </Box>
                        <Box theme={theme} className="price-detail">
                          <Typography
                            theme={theme}
                            className="detail offer"
                            component="span"
                            variant="caption"
                            children="Net Trade-In Amount:&nbsp;"
                          />
                          <Typography
                            theme={theme}
                            className="detail price"
                            component="span"
                            variant="caption"
                          >
                            $11,625.00
                          </Typography>
                        </Box>
                        <Box theme={theme} className="price-detail">
                          <Typography
                            theme={theme}
                            className="detail offer"
                            component="span"
                            variant="caption"
                            children="Estimated Mileage:&nbsp;"
                          />
                          <Typography
                            theme={theme}
                            className="detail price"
                            component="span"
                            variant="caption"
                          >
                            45,000
                          </Typography>
                        </Box>
                        <Box theme={theme} className="price-detail">
                          <Typography
                            theme={theme}
                            className="detail offer"
                            component="span"
                            variant="caption"
                            children="Condition:&nbsp;"
                          />
                          <Typography
                            theme={theme}
                            className="detail price"
                            component="span"
                            variant="caption"
                          >
                            Excellent
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </DetailCard>
            <DetailCard className="detail-card" mb={2}>
              <Typography theme={theme} mb={2} component="h4" variant="h4">
                Search Details
              </Typography>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="Keyword"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    Audi, Honda, Toyota, BMW, MINI, SUV
                  </Typography>
                </Grid>
              </Grid>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="Asset Condition"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    New, Certified Pre-Owned
                  </Typography>
                </Grid>
              </Grid>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="Selling Price"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    $10,000 - $50,000, $60,000 - $90,000, $90,000 - $100,000, $100,000 - $200,000
                  </Typography>
                </Grid>
              </Grid>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="Year"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    2020, 2021, 2022, 2023, 2024
                  </Typography>
                </Grid>
              </Grid>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="Make/Model/Trim"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    Audi, Honda Civic EX, Toyota Highlander XLE, BMW X5 M50i, MINI Cooper S
                    Countryman ALL4
                  </Typography>
                </Grid>
              </Grid>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="VIN"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    1G1AF1F57A7192174, 1HGCM82633A123456, WBA5A7C52ED123456, 5UXKR0C54G1234567
                  </Typography>
                </Grid>
              </Grid>
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item sm={3} xs={12}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children="Stock Number"
                  />
                </Grid>
                <Grid theme={theme} item sm={9} xs={12}>
                  <Typography theme={theme} variant="body2" component="span">
                    2834593534, 9876543210, 1234567890, 3456781234
                  </Typography>
                </Grid>
              </Grid>
            </DetailCard>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12} theme={theme}>
            <DetailCard className="detail-card payment-bd" mb={3}>
              <Box className="card-header" theme={theme}>
                <Typography component="h3" variant="h3" theme={theme}>
                  36-Months Lease
                </Typography>
                <Typography className="text" component="span" variant="body2" theme={theme}>
                  Payment Breakdown
                </Typography>
                <Typography
                  theme={theme}
                  variant="caption"
                  component={'small'}
                  display={'block'}
                  mt={2}
                  className="text-primary"
                >
                  Below amounts are calculated using RV ($000) and money factor (0.0)
                </Typography>
              </Box>
              <Box className="line" theme={theme}>
                <hr />
              </Box>
              <Box className="card-body" theme={theme}>
                <Box className="summary-row toggle" sx={{ mb: 2 }} theme={theme}>
                  <Box className="row" theme={theme}>
                    <Box className="center" theme={theme}>
                      <span className="card-text">
                        <b>Periodic Payment</b>
                      </span>
                      <Icon
                        name="ChevronDown"
                        className={showAccordionDetail ? 'toggle-btn ch-up' : 'toggle-btn'}
                        onClick={() => setShowAccordionDetail(!showAccordionDetail)}
                      />
                    </Box>
                    <Typography
                      className="card-text"
                      component="span"
                      variant="body2"
                      theme={theme}
                    >
                      <b>$843.75</b>
                    </Typography>
                  </Box>
                </Box>
                <Accordion expanded={showAccordionDetail}>
                  <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" />
                  <AccordionDetails sx={{ ml: 2, mb: 2 }}>
                    <Box theme={theme} className="row" mb={2}>
                      <Typography
                        theme={theme}
                        className="card-text text"
                        component="span"
                        variant="body2"
                      >
                        Net Periodic Payment
                      </Typography>
                      <Typography
                        theme={theme}
                        className="card-text text"
                        component="span"
                        variant="body2"
                      >
                        <b>-</b>
                      </Typography>
                    </Box>
                    <Box theme={theme} className="row">
                      <Typography
                        theme={theme}
                        className="card-text text"
                        component="span"
                        variant="body2"
                      >
                        Tax Amount
                      </Typography>
                      <Typography
                        theme={theme}
                        className="card-text text"
                        component="span"
                        variant="body2"
                      >
                        <b>-</b>
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Box className="row" sx={{ mb: 2 }} theme={theme}>
                  <Typography className="card-text" component="span" variant="body2" theme={theme}>
                    Terms
                  </Typography>
                  <Typography className="card-text" component="span" variant="body2" theme={theme}>
                    36 Months
                  </Typography>
                </Box>

                <Box className="row" sx={{ mb: 2 }} theme={theme}>
                  <Typography className="card-text" component="span" variant="body2" theme={theme}>
                    Rate
                  </Typography>
                  <Typography className="card-text" component="span" variant="body2" theme={theme}>
                    0.00%
                  </Typography>
                </Box>
                <Box className="row" sx={{ mb: 2 }} theme={theme}>
                  <Typography className="card-text" component="span" variant="body2" theme={theme}>
                    Annual Mileage
                  </Typography>
                  <Typography className="card-text" component="span" variant="body2" theme={theme}>
                    12,000 Miles
                  </Typography>
                </Box>

                <Box className="line" theme={theme}>
                  <hr />
                </Box>

                <Box className="row" sx={{ mb: 2 }} theme={theme}>
                  <Typography className="card-text" component="span" variant="body2" theme={theme}>
                    Selling Price
                  </Typography>
                  <Typography className="card-text" component="span" variant="body2" theme={theme}>
                    $54,000.00
                  </Typography>
                </Box>

                <Box className="row" theme={theme}>
                  <Typography className="card-text" component="span" variant="body2" theme={theme}>
                    Down Payment
                  </Typography>
                  <Typography className="card-text" component="span" variant="body2" theme={theme}>
                    $12,000.00
                  </Typography>
                </Box>

                <Box className="summary-row toggle" sx={{ mt: 2 }} theme={theme}>
                  <Box className="row" theme={theme}>
                    <Box className="center" theme={theme}>
                      <span className="card-text">Trade-In Equity/Inequity</span>
                    </Box>
                    <Typography
                      className="card-text"
                      component="span"
                      variant="body2"
                      theme={theme}
                    >
                      <b>$11,625.00</b>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </DetailCard>
          </Grid>
        </Grid>
      </PageContent>
    </LayoutWithSideNav>
  )
}
