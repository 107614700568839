import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Grid, Typography } from '@ntpkunity/controls'
import CalculationGrid from './finance-lease-calculator'
import PaymentDetails from './payment-details'
import { FinanceLeaseHeader } from './finance-lease-header'
import MultipleVehicleComparison from './product-list-view'
import { useDeskingContext, VehiclesForComparisonTypes } from './desking-context-provider'
import DealPreviewComponent from './deal-preview-component'
import { StatusWrap } from '@components'
import { useGetReservation } from '@apis/configurations.service'
import { useStoreContext } from '@store/storeContext'
import { InventoryType } from '@helpers/enums/status.enum'
import { RESERVATION_TYPE_PERCENTAGE_MSRP } from '@helpers/constants/constants'

const CalculationSection: FC = () => {
  const theme = useTheme()
  const { state } = useDeskingContext()
  const { vehiclesForComparison, isVehicleSelectedAndOrderSaved } = state
  const { states } = useStoreContext()
  const { data: getReservation, isLoading: isReservationLoading } = useGetReservation(
    states?.dealerInfo?.dealer_code
  )

  const dealerCode = states?.dealerInfo?.dealer_code
  const { vehiclesData } = state
  const vin = state?.vehiclesVins?.[0]
  const vehicleDealerCode = vehiclesData?.get(vin)?.vehicle?.dealer_code
  const isDealerCodeMatch = vehicleDealerCode === dealerCode
  const areDealerCodesAvailable = !!dealerCode && !!vehicleDealerCode

  const showReservationAmount =
    !isReservationLoading &&
    getReservation?.reservation?.applicable_to &&
    areDealerCodesAvailable &&
    !(
      getReservation?.reservation?.applicable_to === InventoryType.OtherInventory &&
      isDealerCodeMatch
    )

  const getMsrp = () => {
    const { order, vehiclesVins, vehiclesData } = state
    const vin = vehiclesVins?.[0]

    if (order?.order_asset?.msrp) {
      return order.order_asset.msrp
    }

    if (vin && vehiclesData) {
      const vehicleData = vehiclesData.get(vin)
      return vehicleData?.vehicle?.msrp || 0
    }

    return
  }

  const calculateReservationAmount = () => {
    if (!getReservation?.reservation?.reservation_amount) return '0.00'

    if (getReservation?.reservation?.reservation_type === RESERVATION_TYPE_PERCENTAGE_MSRP) {
      const msrp = getMsrp() || 0
      const percentage = getReservation?.reservation?.reservation_amount || 0
      const amount = (msrp * percentage) / 100
      return amount.toFixed(2)
    }

    return getReservation?.reservation?.reservation_amount.toFixed(2)
  }

  return (
    <>
      {(!state?.isStipulationScreen || state?.submission_tier) && <FinanceLeaseHeader />}
      <Grid theme={theme} container columnSpacing={2} rowSpacing={2}>
        <Grid theme={theme} item xl={6} lg={7} md={12} sm={12} xs={12}>
          {isVehicleSelectedAndOrderSaved ? (
            <DealPreviewComponent />
          ) : (
            <>
              {vehiclesForComparison === VehiclesForComparisonTypes.SINGLE ? (
                <CalculationGrid />
              ) : (
                <MultipleVehicleComparison />
              )}
            </>
          )}
        </Grid>
        <Grid theme={theme} item xl={6} lg={5} md={12} sm={12} xs={12}>
          {showReservationAmount && (
            <StatusWrap theme={theme} className="status-wrap reserve-status" mb={2}>
              <Typography
                theme={theme}
                component="small"
                variant="caption"
                className="w-100 d-block fw-600 status rejected"
              >
                Reservation Amount: ${calculateReservationAmount()}
              </Typography>
            </StatusWrap>
          )}
          <PaymentDetails />
        </Grid>
      </Grid>
    </>
  )
}

export default CalculationSection
