import "./trade-in-drawer-content.scss";
import { Button, Image } from "@src/components/atoms";
import Logo from "../../../../shared/assets/img/trade-in-drawer-logo.png";

export const TradeInDrawerContent = () => {
  return (
    <>
      <div className="trade-in-drawer">
        <div className="trade-in-drawer__inner">
          <div className="trade-in-drawer__left">
            <div className="trade-in-drawer__headline">
              <div className="trade-in-drawer__logo">
                <Image src={Logo} alt="image" aspectRatio="3:1" />
              </div>
              <h2 className="trade-in-drawer__headline__title">
                Get an instant trade-in offer from KBB.
              </h2>
            </div>
            <div className="trade-in-drawer__content">
              <h5 className="trade-in-drawer__content__title">
                About Kelley Blue Book Instant Cash Offer.
              </h5>
              <p className="trade-in-drawer__content__description text--body-2">
                Kelley Blue Book will provide a verified offer for you to apply
                towards this deal. While the process takes a few minutes, you’ll
                know your offer amount right away. The offer will be valid for 7
                days.
              </p>
              <Button
                htmlType={"button"}
                text="Learn more"
                fixed
                linkButton
                withIcon
                dataIcon="external_link"
                isIconEnd
              />
            </div>
          </div>
          <div className="trade-in-drawer__right"></div>
        </div>
      </div>
    </>
  );
};
