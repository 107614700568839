import { Col, Row } from "antd";
import "./hero-banner.scss";
import {
  Button,
  FeedbackLabel,
  Image,
  MultiSwatchCircles,
} from "@src/components/atoms";

import HeroBannerImage from "../../../../shared/assets/img/hero-banner-car-img.png";

export const HeroBanner = () => {
  return (
    <div className="hero-banner">
      <div className="bmw-container">
        <div className="hero-banner__inner">
          <div className="hero-banner__content">
            <div className="hero-banner__content__inner">
              <div className="hero-banner__review-deal">
                <div className="hero-banner__review-deal__left">
                  <p className="text--body-2">Review your deal</p>
                  <h3 className="text--h3">2024 330i xDrive Sedan</h3>
                  <FeedbackLabel
                    text={
                      <>
                        <span>At dealership</span> {" | "}{" "}
                        <span className="text--disclaimer">
                          VIN#: 3MW89FF00P8C84861
                        </span>
                      </>
                    }
                    type="success"
                  />
                </div>
                <div
                  className="hero-banner__review-deal__right"
                  style={{ display: "none" }}
                >
                  <div className="hero-banner__image--section">
                    <Image
                      src={HeroBannerImage}
                      alt="image"
                      aspectRatio="16:9"
                    />
                  </div>
                </div>
              </div>

              <div className="hero-banner__cart-pricing-section">
                <div className="hero-banner__cart">
                  <span className="text--body-2">
                    Your cart{" "}
                    <div
                      className="bmw-icon bmw-icon--before data-icon"
                      data-icon="information"
                    ></div>
                  </span>
                  <div>
                    <MultiSwatchCircles />
                  </div>
                </div>
                <div className="hero-banner__pricing-detail">
                  <div>
                    <p className="text--label">Estimated financing</p>
                    <div className="text--h2">
                      $618.32 <span className="text--body-1">/month</span>
                    </div>
                  </div>
                  <div className="hero-banner__pricing-text">
                    <div className="text--disclaimer">XX months</div>
                    <div className="text--disclaimer">X.XX% APR</div>
                    <div className="text--disclaimer">$X,XXX cash down</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-banner__image--section">
            <Image src={HeroBannerImage} alt="image" aspectRatio="16:9" />
          </div>

          <div className="hero-banner__scroll-tout-section">
            <p className="text--label text-white">
              View additional saved deals
            </p>
            <Button
              htmlType={"button"}
              linkButton
              withIcon
              dataIcon="arrow_down"
              onDark
            />
          </div>
        </div>
      </div>
    </div>
  );
};
