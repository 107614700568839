import { PaymentMethod } from "@src/components/molecules";

import "./deposit.scss";
import { Button } from "@src/components/atoms";

export const Deposit = ({ handleClick }: { handleClick: () => void }) => {
  return (
    <div className="deposit-wrapper">
      <div className="deposit-info-header">
        <div className="checkout-form-header">
          <h3 className="checkout-title text-secondary text--subsection-2">
            04
          </h3>
          <h3 className="checkout-title text--subsection-2">Deposit.</h3>
        </div>
      </div>
      <p className="deposit-info-subtitle text--body-2">
        You will pay a refundable one-time deposit today. The remaining down
        payment, less deposit, must be paid when you pick up your vehicle.
      </p>
      <div className="deposit-info-due-today-wrapper">
        <p className="deposit-info-due-today text--body-focus-price-1">
          Due today
        </p>
        <p className="deposit-info-due-today-amount text--body-focus-price-1">
          $495
        </p>
      </div>
      <div className="deposit-info-payment-wrapper">
        <PaymentMethod />
      </div>
      <div className="deposit-cta-wrapper">
        <Button
          type="default"
          id="payment-agree-pay-btn"
          htmlType={"button"}
          text="Agree & pay"
          onClick={handleClick}
          fullWidth
          fixed
        />
      </div>
    </div>
  );
};
