import "./multi-swatch-card.scss";

import { MultiSwatchCardProps } from "./multi-swatch-card-props";
import clsx from "clsx";
import { Button, Image } from "@src/components/atoms";
import { MultiSwatchCircles } from "@atoms/multi-swatch-circles/multi-swatch-circles";

export const MultiSwatchCard = ({
  className,
  image,
  title,
  subTitle,
  termsInfoNode,
  priceNode,
  totalPrice,
  isDisabledCard,
  hasCheckbox,
  actionsNode,
  feedbackLable,
}: MultiSwatchCardProps) => {
  return (
    <div
      className={clsx(className, {
        "multi-swatch-card": true,
        is_row_disabled: isDisabledCard,
      })}
    >
      <div className="">
        <MultiSwatchCircles />
      </div>
      <div>
        <div className="text--body-2">
          This 330i xDrive Sedan comes with 6 pre-installed accessories from BMW
          of Manhattan.
        </div>

        <div className="text--link-2 text-underline ">See what's included</div>
      </div>
    </div>
  );
};
