import { RolesAndPermission } from '@ntpkunity/controls-ums'
export const RoleAndPermissionsPage = ({}) => {
  return (
    <>
      <RolesAndPermission />
    </>
  
  )
}

