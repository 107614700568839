import { FC, useEffect } from 'react'
import { Typography, Grid, Skeleton } from '@ntpkunity/controls'
import { DetailCard } from 'components'
import { useTheme } from '@mui/material'
import { useStoreContext } from '@store/storeContext'
import { useGetCustomerDetails } from '@apis/dealer-workqueue.service'
import { removeSpecialChars } from '@helpers/methods'

const CustomerDetailsSection: FC<{}> = () => {
  const theme = useTheme()
  const { states, actions } = useStoreContext()
  const { mutate: getCustomerDetails, isLoading: customerDetailLoading } = useGetCustomerDetails()

  useEffect(() => {
    if (states?.dealerSelectedOrderData.customerId) {
      fetchCustomerDetails()
    }
  }, [states?.dealerSelectedOrderData.customerId])
  const fetchCustomerDetails = () => {
    actions.setCustomerInformation({})
    getCustomerDetails(
      { refId: states?.dealerSelectedOrderData?.customerId },
      {
        onSuccess(_response: any) {
          actions.setCustomerInformation(_response)
        }
      }
    )
  }
  const customerResidentialAddress = states?.customerInformation?.customer_addresses?.find(
    (obj: any) => obj?.address_type === 'Residential'
  )

  const customerName = states?.customerInformation?.first_name
    ? states?.customerInformation?.first_name + ' ' + states?.customerInformation?.last_name
    : ''
  const customerEmail = states?.customerInformation?.email ? states?.customerInformation?.email : ''
  const customerContactNumber = states?.customerInformation?.mobile_number
    ? states?.customerInformation?.mobile_number
    : ''
  const customerAddress = customerResidentialAddress
    ? customerResidentialAddress?.address_line_1
    : ''
  const customerAddress2 = customerResidentialAddress
    ? customerResidentialAddress?.address_line_2
    : ''
  const stateName = customerResidentialAddress ? customerResidentialAddress?.state_name : ''
  const city = customerResidentialAddress ? customerResidentialAddress?.city : ''
  const zipCode = customerResidentialAddress ? customerResidentialAddress?.zip_code : ''
  const licenseNumber = states?.customerInformation?.license_info?.license_number
  const licenseState = states?.customerInformation?.customer_addresses?.find(
    (obj: any) => obj?.address_type === 'DrivingLicense'
  )
  const completeAddress =
    (customerAddress ? customerAddress + ', ' : '') +
    (customerAddress2 ? customerAddress2 + ', ' : '') +
    (city ? city + ', ' : '') +
    (stateName ? stateName + ' ' : '') +
    (zipCode ? zipCode : '')

  return (
    <>
      <DetailCard className="detail-card" mb={2}>
        <Typography theme={theme} mb={2} component="h4" variant="h4">
          Customer Details
        </Typography>
        <Grid theme={theme} className="container" container>
          <Grid theme={theme} item sm={3} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children="Name"
            />
          </Grid>
          <Grid theme={theme} item sm={9} xs={12}>
            {customerDetailLoading ? (
              <Skeleton theme={theme} width={'10%'} height={'20px'} />
            ) : (
              <Typography theme={theme} variant="body2" component="span" children={customerName} />
            )}
          </Grid>
        </Grid>
        <Grid theme={theme} className="container" container>
          <Grid theme={theme} item sm={3} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children="Email"
            />
          </Grid>
          <Grid theme={theme} item sm={9} xs={12}>
            {customerDetailLoading ? (
              <Skeleton theme={theme} width={'10%'} height={'20px'} />
            ) : (
              <Typography theme={theme} variant="body2" component="span" children={customerEmail} />
            )}
          </Grid>
        </Grid>
        <Grid theme={theme} className="container" container>
          <Grid theme={theme} item sm={3} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children="Contact No."
            />
          </Grid>
          <Grid theme={theme} item sm={9} xs={12}>
            {customerDetailLoading ? (
              <Skeleton theme={theme} width={'10%'} height={'20px'} />
            ) : (
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                children={removeSpecialChars(customerContactNumber)}
              />
            )}
          </Grid>
        </Grid>
        <Grid className="container" theme={theme} container>
          <Grid theme={theme} item sm={3} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children="Address"
            />
          </Grid>
          <Grid theme={theme} item sm={9} xs={12}>
            {customerDetailLoading ? (
              <Skeleton theme={theme} width={'10%'} height={'20px'} />
            ) : (
              <Typography
                theme={theme}
                variant="body2"
                component="h3"
                className="label-detail"
                children={states?.customerInformation?.customer_addresses ? completeAddress : ''}
              />
            )}
          </Grid>
        </Grid>
        <Grid className="container" theme={theme} container>
          <Grid theme={theme} item sm={3} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children="Driving License Number"
            />
          </Grid>
          <Grid theme={theme} item sm={9} xs={12}>
            {customerDetailLoading ? (
              <Skeleton theme={theme} width={'10%'} height={'20px'} />
            ) : (
              <Typography
                theme={theme}
                variant="body2"
                component="h3"
                className="label-detail"
                children={licenseNumber}
              />
            )}
          </Grid>
        </Grid>
        <Grid className="container" theme={theme} container>
          <Grid theme={theme} item sm={3} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children="Driving License State"
            />
          </Grid>
          <Grid theme={theme} item sm={9} xs={12}>
            {customerDetailLoading ? (
              <Skeleton theme={theme} width={'10%'} height={'20px'} />
            ) : (
              <Typography
                theme={theme}
                variant="body2"
                component="h3"
                className="label-detail"
                children={licenseState?.state_name}
              />
            )}
          </Grid>
        </Grid>
      </DetailCard>
    </>
  )
}

export default CustomerDetailsSection
