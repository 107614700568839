const initialState = {
    myNotesData: [],
    loading: false,
    error: null,
  };
  
  const myNotesReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_MY_NOTES_REQUEST":
        return { ...state, loading: true };
      case "FETCH_MY_NOTES_SUCCESS":
        return { ...state, loading: false, myNotesData: action.payload };
      case "FETCH_MY_NOTES_FAILURE":
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default myNotesReducer;