import { useTheme } from "@mui/material";
import { Box, Checkbox, CircleLoader, Icon, Input } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { fetchFinanceStructureRequest, updateFinanceStructureRequest } from "@src/components/redux/actions/financeStructureActions";
import { Stack } from "@src/components/stack";
import { FinanceStructureWrap } from "./finance-structure.style"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom"
import { FinanceStructureModifyDialog } from "../finance-structure-modify-popup/finance-structure-modify-popup-dialog.component"

export const FinanceStructure = () => {
  const theme = useTheme();
  const [open, setOpened] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);
  const { data, updatedData, loading, error } = useSelector((state: any) => state?.financeStructure);
  const [financeStructureState, setFinanceStructureState] = useState<any>({});

  const { reference_number } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setFinanceStructureState(data);
    }
  }, [data]);

  useEffect(() => {
    if (reference_number) {
      dispatch(fetchFinanceStructureRequest(reference_number));
    }
  }, [dispatch, reference_number]);

  const handleOpenDialog = () => setOpened(true);
  const handleCloseDialog = () => setOpened(false);

  const handleInputChange = (field: string, value: any) => {
    setFinanceStructureState((prevState: any) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleInputBlur = (field: string) => {
    const updatedField = { [field]: financeStructureState[field] };
    dispatch(updateFinanceStructureRequest({ ...data, ...updatedField }));
  };

  const formatNumber = (value: number | string): string => {
    if (typeof value === "number") {
      return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(value);
    }
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(parsedValue);
    }
    return value || "N/A";
  };

  const fields = [
    { title: "Finance Type", key: "finance_type", startAdornment: "$" },
    { title: "Financed Amount", key: "finance_amount", startAdornment: "$" },
    { title: "Priodic payment", key: "estimated_monthly_payment", startAdornment: "$" },
    { title: "Asset Cost", key: "selling_price", startAdornment: "$" },
    { title: "Down Payment", key: "down_payment_value", startAdornment: "$" },
    { title: "Tax on Asset cost", key: "tax_amount", startAdornment: "$" },
    { title: "Tax Treatment", key: "Tax_Treatment" },
    { title: "Net Trade-in Value", key: "finance_amount", startAdornment: "$" },
    { title: "Fees, charge & discount", key: "Fees, charge & discount", startAdornment: "$" },
    { title: "Rebate", key: "rebate", startAdornment: "$" },
    { title: "Discount", key: "estimated_monthly_payment", startAdornment: "$" },
    { title: "GAP", key: "gap", startAdornment: "$" },
    { title: "Warranty", key: "insurance", startAdornment: "$" },
    { title: "Insurance", key: "backend_fees", startAdornment: "$" },
    { title: "Annual Admin Fees", key: "Annual Admin Fee", startAdornment: "$" },
    { title: "Documentation Fee", key: "documentation_fee", startAdornment: "$" },
    { title: "Option to Purchase Fee", key: "Option to Purchase Fee", startAdornment: "$" },
    { title: "Backend Fees", key: "backend_fees", startAdornment: "$" },
    { title: "Total Backend Fees", key: "total_backend_fees"},
    { title: "Dealer Fees", key: "dealer_fees"},
  ];
  

  return (
    <>
      <FinanceStructureWrap theme={theme} className="finance-structure-wrap"> 
        <Stack
          title={"Finance Structure"}
          paddingXs={2}
          paddingMd={2}
          actionArea={
            <a href="javascript:void(0)" className="text-primary primary-alert dFlex alignItemsCenter" onClick={handleOpenDialog}>
              Add/Modify <Icon name="ArrowLinkIcon" />
            </a>
          }
        >
          {loading ? (
            <CircleLoader theme={theme} size="sm" />
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
                  <Box theme={theme} className="checkbox-section">
                    <Box theme={theme} className="empty"></Box>
                    <Checkbox theme={theme} className="checkbox" label={'Active'} />
                  </Box>
                  <Box theme={theme} className="child-scrollable-container">
                    <BasicList
                      input
                      options={fields.map((field) => {
                        const value = financeStructureState?.[field.key];
                        const isNumber = typeof value === "number";

                        const formattedValue = isNumber ? `${formatNumber(value)}` : value;

                        return {
                          title: field.title,
                          titleBold: true,
                          action: (
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={
                                value != null && value !== "" && isNumber ? field.startAdornment || "" : null
                              }
                              value={value != null && value !== "" ? formattedValue : "N/A"}
                              onChange={(e) => handleInputChange(field.key, e.target.value)}
                              onBlur={() => handleInputBlur(field.key)}
                              inputClass={isNumber ? 'text-right' : ''}
                            />
                          ),
                        };
                      })}
                    />


                  </Box>
            </>
          )}
        </Stack>
      </FinanceStructureWrap>
      <FinanceStructureModifyDialog open={open} closeDialog={handleCloseDialog} />
    </>
  );
};
