import { IncentivesListCard } from "../../incentives-list-card/incentives-list-card.component";
import "./bundle-details-drawer-content.scss";
import Image from "../../../../shared/assets/img/bundle-details-drawer-img.png";
import Image1 from "../../../../shared/assets/img/protection-warranty-list-img-1.png";
import Image2 from "../../../../shared/assets/img/protection-warranty-list-img-2.png";
import Image5 from "../../../../shared/assets/img/protection-warranty-list-img-5.png";
import { Button, IconList } from "@src/components/atoms";

export const BundleDetailsDrawerContent = () => {
  return (
    <>
      <div className="bundle-details-drawer">
        <div className="bundle-details-drawer__head">
          <h3 className="bundle-details-drawer__head__title">
            Essential protection bundle
          </h3>
          <p className="bundle-details-drawer__head__subtitle text--body-2">
            [BMW of Manhattan] has pre-configured this bundle with coverage
            terms of 72 months / 100,000 miles. To adjust these settings, please{" "}
            <a
              href="/contact-dealer"
              className="text--link-2 fw-normal text-dark text-underline"
            >
              contact dealer.
            </a>
          </p>
        </div>
        <div className="bundle-details-drawer__content">
          <div className="bundle-details-drawer__list--card">
            <IncentivesListCard
              image={Image}
              smallTitle={"Provided by [Demo VSC]"}
              title={"Guaranteed auto protection"}
              subTitle={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              }
              price={
                <h2 className="text--body-focus-price-1">
                  $0,000 <span className="text--body-1">/month</span>
                </h2>
              }
              totalPrice={"Total: $XXXX.XX"}
              tag={"Whats included"}
              coverage={
                <>
                  <IconList
                    size="small"
                    listItems={[
                      {
                        className: "full-coverage",
                        type: "icon",
                        text: "Transfer Case (AWD Only)",
                      },
                      {
                        className: "full-coverage",
                        type: "icon",
                        text: "Induction System",
                      },
                      {
                        className: "full-coverage",
                        type: "icon",
                        text: "Steering",
                      },
                      {
                        className: "full-coverage",
                        type: "icon",
                        text: "Brakes and ABS",
                      },
                      {
                        className: "full-coverage",
                        type: "icon",
                        text: "Engine",
                      },
                      {
                        className: "full-coverage",
                        type: "icon",
                        text: "Heat and A/C",
                      },
                      {
                        className: "full-coverage",
                        type: "icon",
                        text: "Transimission",
                      },
                      {
                        className: "limited-coverage",
                        type: "icon",
                        text: "Electrical",
                      },
                      {
                        className: "full-coverage",
                        type: "icon",
                        text: "Final Drive Assembly",
                      },
                      {
                        className: "limited-coverage",
                        type: "icon",
                        text: "Interior Exterior",
                      },
                      {
                        className: "full-coverage",
                        type: "icon",
                        text: "Cooling System",
                      },
                      {
                        className: "no-coverage",
                        type: "icon",
                        text: "Infotainment System",
                      },
                      {
                        className: "full-coverage",
                        type: "icon",
                        text: "Fuel System",
                      },
                    ]}
                  />
                </>
              }
              legend={
                <div>
                  <IconList
                    size="small"
                    listItems={[
                      {
                        className: "legend__title text--body-2",
                        text: "Legend:",
                      },
                      {
                        className: "no-coverage",
                        type: "icon",
                        text: "No coverage",
                      },
                      {
                        className: "limited-coverage",
                        type: "icon",
                        text: (
                          <>
                            <span className="limited-coverage-info">
                              Limited coverage{" "}
                              <i
                                className="bmw-icon bmw-icon--after data-icon"
                                data-icon="information"
                              ></i>
                            </span>
                          </>
                        ),
                      },
                      {
                        className: "full-coverage",
                        type: "icon",
                        text: "Full coverage",
                      },
                    ]}
                  />
                </div>
              }
            />
          </div>
        </div>
        <div className="bundle-details-drawer__list bundle-details-drawer__list--card">
          <IncentivesListCard
            image={Image1}
            smallTitle={"Provided by [Demo VSC]"}
            title={"Guaranteed auto protection"}
            subTitle={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
            price={
              <h2 className="text--body-focus-price-1">
                $0,000 <span className="text--body-1">/month</span>
              </h2>
            }
            totalPrice={"Total: $XXXX.XX"}
            className=""
            actionsArea={
              <>
                <Button
                  htmlType={"button"}
                  text="Play video"
                  linkButton
                  withIcon
                  dataIcon="play"
                />
                <Button
                  htmlType={"button"}
                  text="Download brochure"
                  linkButton
                  withIcon
                  dataIcon="download"
                />
              </>
            }
          />
          <IncentivesListCard
            image={Image2}
            smallTitle={"Provided by [Demo VSC]"}
            title={"Seat protection"}
            subTitle={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            }
            price={
              <h2 className="text--body-focus-price-1">
                $0,000 <span className="text--body-1">/month</span>
              </h2>
            }
            totalPrice={"Total: $XXXX.XX"}
            className=""
            actionsArea={
              <>
                <Button
                  htmlType={"button"}
                  text="Play video"
                  linkButton
                  withIcon
                  dataIcon="play"
                />
              </>
            }
          />
          <IncentivesListCard
            image={Image5}
            smallTitle={"Provided by [Demo VSC]"}
            title={"Tire & wheel protection"}
            subTitle={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            }
            price={
              <h2 className="text--body-focus-price-1">
                $0,000 <span className="text--body-1">/month</span>
              </h2>
            }
            totalPrice={"Total: $XXXX.XX"}
            className=""
            actionsArea={
              <>
                <Button
                  htmlType={"button"}
                  text="Play video"
                  linkButton
                  withIcon
                  dataIcon="play"
                />
              </>
            }
          />
        </div>
      </div>
    </>
  );
};
