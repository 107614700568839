import { useState } from "react";
import { Carousel as AntCarousel } from "antd";
import "../../../styles/components/_carousel.scss";
import { CarouselProps } from "./carousel.props";
import { Image } from "../Image/Image";

const CustomArrow = ({ onClick, direction, isActive }: any) => (
  <div
    className={`custom-arrow custom-arrow-${direction} ${
      isActive ? "active" : ""
    }`}
    role="button"
    tabIndex={0}
    onClick={onClick}
    onKeyDown={(event) => {
      if (event.key === "Enter" || event.key === " ") {
        onClick();
      }
    }}
    aria-label={`Scroll ${direction}`}
  >
    <span
      className="bmw-icon bmw-icon--before data-icon"
      data-icon={
        direction === "left" ? "arrow_chevron_left" : "arrow_chevron_right"
      }
    ></span>
  </div>
);

export const Carousel = ({
  images,
  arrows,
  autoplay,
  autoplaySpeed,
  dotPosition,
  dots,
  infinite,
}: CarouselProps) => {
  const [activeArrow, setActiveArrow] = useState<"left" | "right" | null>(
    "right"
  );

  const handleArrowClick = (direction: "left" | "right") => {
    setActiveArrow(direction);
  };

  const handleAfterChange = (current: number) => {
    if (current === 0) {
      setActiveArrow("right");
    } else if (current === images.length - 1) {
      setActiveArrow("left");
    } else {
      setActiveArrow(null);
    }
  };

  return (
    <div className="carousel-container">
      <AntCarousel
        arrows={arrows}
        autoplay={autoplay}
        autoplaySpeed={autoplaySpeed}
        dotPosition={dotPosition}
        dots={dots}
        infinite={infinite}
        afterChange={handleAfterChange}
        prevArrow={
          <CustomArrow
            direction="left"
            isActive={activeArrow === "left"}
            onClick={() => handleArrowClick("left")}
          />
        }
        nextArrow={
          <CustomArrow
            direction="right"
            isActive={activeArrow === "right"}
            onClick={() => handleArrowClick("right")}
          />
        }
      >
        {images.map((image, index) => (
          <div key={index}>
            <Image
              src={image.src}
              alt={image.alt || `Slide ${index + 1}`}
              aspectRatio="1:1"
            />
          </div>
        ))}
      </AntCarousel>
    </div>
  );
};

export default Carousel;
