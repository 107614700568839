import { Button } from "@src/components/atoms";
import "./menu.scss";

export const Menu = () => {
  return (
    <div className="menu">
      <div className="menu-mobile">
        <Button
          htmlType="button"
          text="Apply for Credit"
          linkButton
          withIcon
          isIconEnd
          dataIcon="arrow_chevron_down"
          className="text-secondary"
        />
      </div>
      <div className="menu-desktop">
        <div className="menu-item-container">
          <div className="menu-title text--body-2">Purchase Online</div>
        </div>
        <div className="menu-divider"></div>
        <div className="menu-item-container">
          <div className="menu-item">
            <Button
              htmlType="button"
              text="Apply for Credit"
              linkButton
              withIcon
              className="menu-item--active"
              dataIcon="checkmark"
            />
            {/* toggle class 'menu-item--active' on active link & show checkmark in data-icon once moved to next page */}
          </div>

          <div className="menu-item">
            <Button
              htmlType="button"
              text="Finance Offer"
              linkButton
              withIcon
              isIconEnd
              className=""
              dataIcon="lock_locked"
            />
          </div>
          <div className="menu-item">
            <Button
              htmlType="button"
              text="Contracting/Final Steps"
              linkButton
              withIcon
              isIconEnd
              className=""
              dataIcon="lock_locked"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
