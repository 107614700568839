import {
  FETCH_APPLICANT_DETAILS_REQUEST,
  FETCH_APPLICANT_DETAILS_SUCCESS,
  FETCH_APPLICANT_DETAILS_FAILURE,
  OPEN_APPLICANT_DIALOG,
  CLOSE_APPLICANT_DIALOG,
} from "../actions/applicantDetailsActions";

const initialState = {
  loading: false, 
  dialogLoading: false, 
  currentApplicantReferenceNum: null,
  isOpen: false,
  applicantDetails: null,
  error: null,
};

const applicantDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APPLICANT_DETAILS_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_APPLICANT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        dialogLoading: false,
        applicantDetails: action.payload,
      };

    case FETCH_APPLICANT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        dialogLoading: false, 
        error: action.payload,
      };

    case OPEN_APPLICANT_DIALOG:
      return {
        ...state,
        isOpen: true,
        currentApplicantReferenceNum: action.payload,
        dialogLoading: true, 
        error: null,
      };

    case CLOSE_APPLICANT_DIALOG:
      return {
        ...state,
        isOpen: false,
        dialogLoading: false, 
      };

    default:
      return state;
  }
};

export default applicantDetailsReducer;
