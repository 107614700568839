import "./fixed-vehicle-card.style.scss";
import VehicleImage from "../../../shared/assets/img/vehicle-fixed-car.png";

export const FixedVehicleCard = () => {
  return (
    <div className="fixed-vehicle-card">
      <div className="text-container">
        <p className="financing-text text--body-focus-price-1">
          Financing $585/month
        </p>
        <p className="price-text text--disclaimer">
          Your Price: $48,760 MSRP (i)
        </p>
      </div>
      <img className="vehicle-image" src={VehicleImage} alt="Vehicle" />
    </div>
  );
};
