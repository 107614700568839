import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AiAssistantWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.ai-assistant-wrap": {
    ".ai-head": {
        borderRadius: '8px',
        background: 'linear-gradient(90deg, #171718 -84.22%, #232BF6 -15.8%, #E8563F 184.07%)',
        display: 'flex',
        padding: 16,
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 16,
        alignSelf: 'stretch',
        ".ai-head-text": {
            display: 'flex',
            alignItems: 'center',
            gap: 8
        }
    }
  }
}));
