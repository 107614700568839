import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const DashboardHeaderWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.dashboard-header-wrap': {
    backgroundColor: '#f2f2f2',
    padding: '32px 40px',
    marginBottom: 24,
    borderBottom: '1px solid' + theme.palette.grey[100],
    '.btn-row-wrap': {
      display: 'flex',
      gap: 8,
      flexWrap: 'wrap',
      '.btn-wrap-col': {
        display: 'flex',
        gap: 8,
        flexWrap: 'wrap',
        '.btn': {
          flexDirection: 'column',
          fontSize: theme.typography.caption.fontSize,
          fontWeight: theme.typography.fontWeightRegular,
          backgroundColor: theme.palette.common.white,
          padding: 16,
          minWidth: 120,
          color: theme.palette.grey[900],
          '.MuiButton-startIcon': {
            width: 32,
            height: 32,
            marginRight: 0,
            svg: {
              width: 32,
              height: 32
            }
          }
        },
        '&.col-two': {
          flex: 1,
          '.btn': {
            width: '100%',
            background: 'transparent',
            border: '1px dashed' + theme.palette.grey[100],
            '.MuiButton-startIcon': {
              width: 24,
              height: 24,
              svg: {
                width: 24,
                height: 24
              }
            }
          }
        }
      }
    }
  }
}))
