import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { LayoutWithSideNav } from "layout";
import { IGridProps, Grid, Typography, DuoTab } from "@ntpkunity/controls";
import { Main, PageHeader } from "@sharedComponents";

import { isParenthesizedExpression } from "@babel/types";

import {
  AnnualMileage,
  FinancedAmount,
  CreditRating,
  TermsSetup,
  AssetCatalogue,
  Stipulation,
  ContractTerms,
  ContractAllowedAnnumMileage,
  DocumentType,
  FeeName,
  AssetCondition,
  OptionCategory,
  Integrations,
  DecisionStatus,
  TradingAge,
  FniMarketingMaterial,
  Rebate,
} from "modules";
import { useSetupsStore } from "Stores/SetupStore/Store";
import {
  UseMutation_AddAnnualMileageMultiple,
  UseMutation_AddFinancedAmountMultiple,
  UseQuery_getAllMileage,
  UseQuery_getAllCreditRatings,
  UseQuery_GetAllTerms,
  UseQuery_GetAllAssetMakes,
  UseMutation_AddCreditRatingMultiple,
  useMutation_AddNewAssetMakeMultiple,
  useMutation_AddNewAssetModelMultiple,
  useMutation_AddNewAssetTrimMultiple,
  UseMutation_UpdateCompanyProfile,
  UseMutation_AddCompanyProfile,
  UseMutation_AddAddresses,
} from "services";
import {
  IAssetMake,
  IAssetModel,
  IAssetTrim,
  ICreditRating,
  ITerms,
} from "Interface";

import { styled } from "@mui/material/styles";
import { useQueryClient } from "react-query";
import { PermissionTypes, QueryKeys } from "Enums";
import { useHasPermissions, indexPermissions } from "@ntpkunity/controls-ums";

const OrderSetup: FC = () => {
  const theme = useTheme();

  const [state, dispatch] = useSetupsStore();
  const { annualMileages, openDrawerSt } = state;
  const queryClient = useQueryClient();
  const assetMakes: Array<IAssetMake> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MAKES
  );
  const creditRatings: Array<ICreditRating> = queryClient.getQueryData(
    QueryKeys.GET_ALL_CREDIT_RATINGS
  );
  const assetModels: Array<IAssetModel> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MODELS
  );
  const assetTrims: Array<IAssetTrim> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_TRIMS
  );
  const terms: Array<ITerms> = queryClient.getQueryData(
    QueryKeys.GET_ALL_TERMS
  );
  const [openDrawer, setOpenDrawerState] = useState<boolean>(false);
  const tabs = [
    {
      title: "Contract Annual Mileage",
      content: <ContractAllowedAnnumMileage />,
    },
    ...(useHasPermissions(
      indexPermissions.MENU.CONTRACT_TERM,
      PermissionTypes.VIEW
    )
      ? [
          {
            title: "Contract Term",
            content: (
              <>
                {" "}
                <ContractTerms />
              </>
            ),
          },
        ]
      : []),
    ...(useHasPermissions(indexPermissions.MENU.FEES, PermissionTypes.VIEW)
      ? [
          {
            title: "Fees",
            content: (
              <>
                {" "}
                <FeeName />
              </>
            ),
          },
        ]
      : []),
    ...(useHasPermissions(indexPermissions.MENU.BUSINESS_TRADING_AGE, PermissionTypes.VIEW)
    ? [
        {
          title: "Business Trading Age",
          content: (
            <>
              <TradingAge />
            </>
          ),
        },
      ]
    : []),
    {
      title: "F&I Marketing Materials",
      content: <FniMarketingMaterial />,
    },
    ...(useHasPermissions(indexPermissions.MENU.COMPANY_REBATE, PermissionTypes.VIEW)
      ? [
          {
            title: "Rebates",
            content: (
              <>
                <Rebate />
              </>
            ),
          },
        ]
      : []),
  ];

  const { mutate: createMileages } = UseMutation_AddAnnualMileageMultiple();
  const { mutate: createCreditRatings } = UseMutation_AddCreditRatingMultiple();
  //const { mutate: createTerms } = UseMutation_AddTermsMultiple();
  const { mutate: createAssetMakes } = useMutation_AddNewAssetMakeMultiple();
  const { mutate: createAssetModels } = useMutation_AddNewAssetModelMultiple();
  const { mutate: createAssetTrims } = useMutation_AddNewAssetTrimMultiple();

  const OnSaveChanges = () => {
    //Add Credit Rating if any new rating is Added.
    if (creditRatings.filter((ratings) => ratings.is_new).length > 0) {
      createCreditRatings(creditRatings.filter((ratings) => ratings.is_new));
    }
    //Add Terms if any new term is Added.
    // if (creditRatings.filter((term) => term.is_new).length > 0) {
    //   createTerms(terms.filter((term) => term.is_new));
    // }
    //Add Annual Mileages if any new Mileage is Added.
    if (annualMileages.filter((mileages) => mileages.is_new).length > 0) {
      createMileages(annualMileages.filter((mileages) => mileages.is_new));
    }

    //Add Asset Catalogue if Any new Asset Make is Added After Asset make is Added successfully also add models for that make if Any.
    if (assetMakes.filter((makes) => makes.is_new).length > 0) {
      AddNewMake(assetMakes.filter((makes) => makes.is_new));
    }

    //Add Asset Models if make already exists in Server
    const modelsForExistingMakes = assetModels.filter((models) => {
      return assetMakes
        .filter((makes) => !makes.is_new)
        .some((filteredmakes) => {
          return filteredmakes.id === models.make_id && models.is_new;
        });
    });

    if (modelsForExistingMakes.length > 0) {
      createAssetModels(modelsForExistingMakes, {
        onSuccess: (data, variables: any, context) => {
          const TrimsForNewModels = assetTrims.filter((trims) => {
            return variables.some((models) => {
              return models.model_id === trims.model_id;
            });
          });
          if (TrimsForNewModels.length > 0) {
            createAssetTrims(TrimsForNewModels);
          }
        },
      });
    }

    //Add Asset Trim if Model already exists in Server
    const trimsForExistingModels = assetTrims.filter((trims) => {
      return assetModels
        .filter((models) => !models.is_new)
        .some((filteredmodels) => {
          return filteredmodels.id === trims.model_id && trims.is_new;
        });
    });

    if (trimsForExistingModels.length > 0) {
      createAssetTrims(trimsForExistingModels);
    }
  };

  const AddNewMake = (Makes: Array<IAssetMake>) => {
    if (Makes.length > 0) {
      createAssetMakes(Makes, {
        onSuccess: (data, variables: any, context) => {
          const ModelsForNewMakes = assetModels.filter((models) => {
            return variables.some((makes) => {
              return makes.make_id === models.make_id;
            });
          });
          AddNewModel(ModelsForNewMakes);
        },
      });
    }
  };

  const AddNewModel = (Models: Array<IAssetModel>) => {
    if (Models.length > 0) {
      createAssetModels(Models, {
        onSuccess: (data, variables: any, context) => {
          const TrimsForNewModels = assetTrims.filter((trims) => {
            return variables.some((models) => {
              return models.model_id === trims.model_id;
            });
          });
          if (TrimsForNewModels.length > 0) {
            AddNewTrim(TrimsForNewModels);
          }
        },
      });
    }
  };

  const AddNewTrim = (AssetTrims: Array<IAssetTrim>) => {
    if (AssetTrims.length > 0) {
      createAssetTrims(AssetTrims);
    }
  };

  useEffect(() => {}, [openDrawerSt]);

  return (
    <Main open={openDrawerSt ? openDrawerSt : false}>
      <LayoutWithSideNav theme={theme}>
        <Grid theme={theme} container rowSpacing={2}>
          <Grid theme={theme} item xs={12} />
          <PageHeader theme={theme} className="main-page-header border-bottom">
            <Grid theme={theme} container spacing={2}>
              <Grid theme={theme} item xs={12} sm={12} lg={7} md={6}>
                <Typography
                  variant="h2"
                  component="h2"
                  rowGap={5}
                  data-testid="heading"
                  theme={theme}
                >
                  Deal Setups
                </Typography>
              </Grid>
            </Grid>
          </PageHeader>
          <Grid theme={theme} item xs={12} my={-2}>
            <DuoTab
              theme={theme}
              varient={"underline"}
              items={tabs}
              defaultTabIndex={0}
            />
          </Grid>
        </Grid>
      </LayoutWithSideNav>
    </Main>
  );
};

export default OrderSetup;
