import { useTheme } from '@mui/material'
import { PageContent } from './lane-dashboard.syle'
import { LayoutWithSideNav } from '@styles/layout'
import { Box, Grid, Typography } from '@ntpkunity/controls'
import {
  ActionItems,
  DashboardHeader,
  LeadsTable,
  LiveAction,
  Revenue,
  Scheduler,
  TopSelling,
  DashboardWidgets
} from 'components/lane-dashboard'

import CarImage01 from '../../public/assets/cars-images/CarImage01.png'
import CarImage02 from '../../public/assets/cars-images/CarImage02.png'
import CarImage03 from '../../public/assets/cars-images/CarImage03.png'
import CarImage04 from '../../public/assets/cars-images/CarImage04.png'
import CarImage05 from '../../public/assets/cars-images/CarImage05.png'
import CarImage06 from '../../public/assets/cars-images/CarImage06.png'
import CarImage07 from '../../public/assets/cars-images/CarImage07.png'
import CarImage08 from '../../public/assets/cars-images/CarImage08.png'

export const LaneDashboardPage = () => {
  const theme = useTheme()
  return (
    <LayoutWithSideNav theme={theme}>
      <PageContent theme={theme} className="page-content" mb={3}>
        <DashboardHeader />
        <Grid theme={theme} container item spacing={3} alignItems={'stretch'}>
          <Grid theme={theme} item lg={3} xs={6}>
            <Typography theme={theme} variant="body1" component="p" mb={2} className="fw-700">
              Revenue
            </Typography>
            <Revenue />
          </Grid>
          <Grid theme={theme} item lg={3} xs={6}>
            <Typography theme={theme} variant="body1" component="p" mb={2} className="fw-700">
              Scheduler
            </Typography>
            <Scheduler />
          </Grid>
          <Grid theme={theme} item lg={3} xs={6}>
            <Typography theme={theme} variant="body1" component="p" mb={2} className="fw-700">
              Top Selling Vehicles
            </Typography>
            <TopSelling
              items={[
                {
                  productImage: CarImage01,
                  productModal: '2021 Audi A4 Sedan Premium',
                  productName: '40 TFSI quattro',
                  listBg: true
                },
                {
                  productImage: CarImage02,
                  productModal: '2021 Honda Passport',
                  productName: 'Touring FWD',
                  listBg: true
                },
                {
                  productImage: CarImage03,
                  productModal: '2021 Hyundai Veloster',
                  productName: 'N DCT',
                  listBg: true
                },
                {
                  productImage: CarImage04,
                  productModal: '2021 Mercedes-Benz',
                  productName: 'C-Class AMG C 43 4MATIC Cabriolet',
                  listBg: true
                },
                {
                  productImage: CarImage05,
                  productModal: '2021 MINI Hardtop 2 Door',
                  productName: 'John Cooper Works FWD',
                  listBg: true
                },
                {
                  productImage: CarImage06,
                  productModal: '2021 Nissan Altima',
                  productName: '2.5 SR AWD Sedan',
                  listBg: true
                },
                {
                  productImage: CarImage07,
                  productModal: '2021 Tesla Model 3 ',
                  productName: 'Long Range AWD',
                  listBg: true
                },
                {
                  productImage: CarImage08,
                  productModal: '2021 Toyota Avalon',
                  productName: 'XSE Nightshade FWD (Natl)',
                  listBg: true
                }
              ]}
            />
          </Grid>
          <Grid theme={theme} item lg={3} xs={6}>
            <Typography theme={theme} variant="body1" component="p" mb={2} className="fw-700">
              Action Items
            </Typography>
            <ActionItems
              items={[
                {
                  title: 'Unread Messages',
                  value: 29
                },
                {
                  title: 'Assistance Requests',
                  value: 2
                },
                {
                  title: 'Vehicle Repair',
                  value: 14
                },
                {
                  title: 'Inquiries',
                  value: 8
                }
              ]}
            />
          </Grid>
          <Grid theme={theme} item lg={9} xs={12}>
            <Typography theme={theme} variant="body1" component="p" mb={2} className="fw-700">
              Leads
            </Typography>
            <LeadsTable />
            <Box theme={theme} mt={3}>
              <DashboardWidgets />
            </Box>
          </Grid>
          <Grid theme={theme} item lg={3} xs={12}>
            <Typography theme={theme} variant="body1" component="p" mb={2} className="fw-700">
              Live Actions
            </Typography>
            <LiveAction />
          </Grid>
        </Grid>
      </PageContent>
    </LayoutWithSideNav>
  )
}
