import { useEffect, useState } from "react";
import { Button, Checkbox } from "@src/components/atoms";
import "./agreements.style.scss";
import { useNavigate } from "react-router-dom";
import {
  useAddCustomerConsent,
  useGetDefaultLender,
  useGetFraudCheck,
  useOrderSubmit,
} from "@src/hooks";
import { orderFraudStatus } from "@src/context/app-actions";
import { useQueryClient } from "@tanstack/react-query";
import { Decision } from "@src/helpers";
import { useAppContext } from "@src/context/app-context";

export const Agreements = ({
  stepSixDataReceivedEvent,
}: {
  stepSixDataReceivedEvent: () => void;
}) => {
  const { isPending: FraudLoading, mutate: FraudCheck } = useGetFraudCheck();
  const {
    state: {
      order: {
        reference_number,
        customer_info: { reference_id },
      },
      lender: {
        defaultLender: { id },
      },
      dealer: { disclaimer },
    },
    dispatch: appDispatch,
  } = useAppContext();
  const { isPending: OrderSubmitLoading, mutate: submitOrderRequest } =
    useOrderSubmit();
  const navigate = useNavigate();
  const [handleSelect, setHandleSelect] = useState(false);
  const { mutate: consent_add } = useAddCustomerConsent();
  const result = Array.isArray(disclaimer)
    ? disclaimer.find(
        (disclaimer) => disclaimer.name === "credit-application-disclaimer"
      )
    : null;

  const sumbitApplication = () => {
    const submitRequest = async () => {
      try {
        consent_add({
          customer_reference_id: reference_id,
          disclaimer_name: result?.name,
          disclaimer_version: result?.version,
          order_reference_id: reference_number,
        });
        submitOrderRequest(
          {
            referenceNumber: reference_number,
            lender_ids: [id],
          },
          {
            onSuccess: () => {
              FraudCheck(
                {
                  referenceNumber: reference_number,
                  customer_reference_id: reference_id,
                },
                {
                  onSuccess: (response) => {
                    if (response.data?.decision?.value) {
                      appDispatch(
                        orderFraudStatus(response.data.decision.value)
                      );
                      if (response.data.decision.value === Decision.ACCEPT) {
                        navigate(
                          `/deal-state/deal/${reference_number}/${response.data.decision.value}`
                        );
                      } else {
                        navigate("/fraud-check");
                      }
                    } else {
                      navigate("/fraud-check");
                    }
                  },
                }
              );
            },
          }
        );
      } catch (error) {
        console.error("Error submitting order request:", error);
      }
    };
    submitRequest();
  };
  return (
    <div className="unfilled-agreement-container">
      <h2 className="section-header upper-label spacing">
        <span className="section-header number text--subsection-2">06</span>
        <span className="section-header text text--subsection-2">
          Agreements.
        </span>
      </h2>
      <p className="unfilled-agreement-container text--body-2">
        Please review and accept the following terms &amp; conditions before
        submitting your application.
      </p>
      <div className="unfilled-agreement-container checkbox-wrapper">
        <Checkbox
          onChange={(val) => setHandleSelect(val)}
          id={"agreementChk"}
          label=""
          htmlFor="agreementChk"
        />
        {result?.description && (
          <div
            dangerouslySetInnerHTML={{ __html: result?.description ?? <></> }}
          />
        )}
      </div>
      <Button
        type="primary"
        fixed
        htmlType={"button"}
        isLoading={OrderSubmitLoading || FraudLoading}
        text="Agree &amp; submit"
        disabled={!handleSelect}
        onClick={() => {
          stepSixDataReceivedEvent;
          sumbitApplication();
        }}
      />
    </div>
  );
};
