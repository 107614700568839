import React, { ReactNode } from "react";
import { DialogWrap } from "./dialog.style";
import { useTheme } from "@mui/material";

export const Popup = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  return (
    <DialogWrap className="dialog-wrap" theme={theme}>
      {children}
    </DialogWrap>
  );
};
