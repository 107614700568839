import { TagLabel } from "@src/components/atoms";
import { AdditionalInsuranceInfoProps } from "./additional-insurance-info.props";
import { StateCtaButtons } from "../state-cta-button/state-cta-button.component";
import "../../../../styles/components/_waiting-hub-state-card.scss";

export const AdditionalInsuranceInfo = ({
  statusText,
  statusType,
  headerIcon = "document_text_lines",
  headerTitle = "Additional insurance information.",
  bodyTitle,
  bodySubtitle,
  buttons = [],
  additionalInfo,
}: AdditionalInsuranceInfoProps) => {
  return (
    <div className="waiting-hub-state-card">
      <div className="waiting-hub-state__header">
        <h5 className="text--subsection-2">
          <span
            className="bmw-icon bmw-icon--before data-icon"
            data-icon={headerIcon}
          />
          {headerTitle}
        </h5>
        <TagLabel text={statusText} type={statusType} />
      </div>
      <div className="waiting-hub-state__body">
        <div className="waiting-hub-state__inner">
          <div className="waiting-hub-state__content">
            {bodySubtitle && (
              <p className="text--label text-muted">{bodySubtitle}</p>
            )}
            <p className="text--body-1">{bodyTitle}</p>
          </div>
          <StateCtaButtons buttons={buttons} />
        </div>
        {additionalInfo && (
          <div className="waiting-hub-state__info">
            <span
              className="bmw-icon bmw-icon--before data-icon"
              data-icon="light_bulb_radiating"
            />
            <p className="text--label text-muted">{additionalInfo}</p>
          </div>
        )}
      </div>
    </div>
  );
};
