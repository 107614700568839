import { useTheme } from '@mui/material'
import { RevenueWrap } from './revenue.style'
import { Box, Icon, Select, Typography } from '@ntpkunity/controls'

export const Revenue = () => {
  const theme = useTheme()

  return (
    <RevenueWrap theme={theme} className="revenue-wrap section-h">
      <Box theme={theme} className="revenue-box">
        <Box theme={theme} className="upper-section">
          <Box theme={theme} className="section-header">
            <Icon className="menu-icon" name="IncomeIcon" />
            <Select
              theme={theme}
              disablePortal={false}
              items={[
                {
                  text: 'This Week',
                  value: '0'
                },
                {
                  text: 'This Month',
                  value: '1'
                },
                {
                  text: 'This Year',
                  value: '2'
                }
              ]}
              value={1}
            />
          </Box>
          <Box theme={theme} className="section-body label-price">
            <Typography theme={theme} variant="caption" component="p">
              Total Income
            </Typography>
            <Typography theme={theme} variant="body1" component="p" className="fw-700">
              $23,194.80
            </Typography>
          </Box>
        </Box>
        <Box theme={theme} className="lower-section label-price">
          <Typography theme={theme} variant="caption" component="p">
            Your Profit
          </Typography>
          <Typography theme={theme} variant="body1" component="p" className="fw-700">
            $12,280.20
          </Typography>
        </Box>
      </Box>
    </RevenueWrap>
  )
}
