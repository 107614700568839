import { Button, Modal } from "@src/components/atoms";
import "./reservation-timer-modal.scss";

export const ReservationTimerModal = ({ isModalOpen, setIsModalOpen }) => {
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        size="compact"
        className="reservation-modal"
        dataIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="41"
            viewBox="0 0 40 41"
            fill="none"
          >
            <path
              d="M19.9999 33.8359C27.3633 33.8359 33.3333 27.8659 33.3333 20.5026C33.3333 13.1376 27.3633 7.16927 19.9999 7.16927C12.6366 7.16927 6.66659 13.1376 6.66659 20.5026C6.66658 27.8659 12.6366 33.8359 19.9999 33.8359ZM18.3333 12.1693L21.6666 12.1693L21.6666 22.1693L18.3333 22.1693L18.3333 12.1693ZM18.3333 25.5026L21.6666 25.5026L21.6666 28.8359L18.3333 28.8359L18.3333 25.5026Z"
              fill="#262626"
            />
          </svg>
        }
        title={<h3>Need more time? </h3>}
        subtitle={
          <p className="text--body-1">
            Your vehicle reservation will expire in{" "}
            <span className="fw-bold">[amount of time left]</span>. If you need
            more time, you may extend your reservation here by up to{" "}
            <span className="fw-bold">10 minutes</span>
          </p>
        }
        open={isModalOpen}
        onClose={handleClose}
        closable
        footer={
          <>
            <div className="action-btns">
              <Button htmlType="button" fullWidth text="Contact dealer" />
              <Button
                htmlType="button"
                fullWidth
                type="primary"
                text="Extend my reservation"
                onClick={() => {}}
              />
            </div>
          </>
        }
      >
        <p className="text--body-1">
          If you need more than 10 minutes, please contact your dealer.
        </p>
      </Modal>
    </>
  );
};
