import { OrderStatus } from "@src/helpers/enum";
import { clsx } from "clsx";

const WorkqueueStatus = ({ status }: { status: OrderStatus }) => {
  return (
    <>
      <span
        className={clsx("status-indicator", {
          "ind-danger": [OrderStatus.REJECTED, OrderStatus.EXPIRED].includes(status),
          "ind-success": status === OrderStatus.AUTOAPPROVE,
          "ind-warning": status === OrderStatus.APPROVED,
          "ind-contract": [OrderStatus.NEW].includes(status),
          // "ind-info": status === OrderStatus.APPROVED,
        })}
      ></span>
      {status}
    </>
  );
};

export default WorkqueueStatus;
