import { Checkbox } from "@src/components/atoms";

export const SpouseAddress = () => {
  return (
    <div className="spouse-address-wrap title-fields-spacer">
      <p className="material-status-check--subtitle text--body-1">
        Please provide your spouse’s address.
      </p>
      <Checkbox
        id={"spouseAddressCheck"}
        checked={false}
        label="Same as my address"
      />
    </div>
  );
};
