import { useTheme } from "@mui/material"
import { UserNameWrap } from "./user-name.style"
import { UserNameProps } from "./user-name.props"
import { Avatar, Typography, Box } from "@ntpkunity/controls"

export const UserName = ( {userTitle, userName, subTitle}: UserNameProps) => {
    const theme = useTheme();

    return(
        <>
            <UserNameWrap theme={theme} className="user-name-wrap">
                {userTitle &&
                    <Avatar theme={theme}>{userTitle}</Avatar>
                }
                <Box theme={theme}>
                    {userName &&
                        <Typography theme={theme} variant="caption" component={"small"} className="user-name fw-medium">{userName}</Typography>
                    }
                    {subTitle &&
                        <Typography theme={theme} variant="caption" component={"small"} className="text-muted" ml={0.5}>{subTitle}</Typography>
                    }
                </Box>
            </UserNameWrap>
        </>
    )
}