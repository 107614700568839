import { useTheme } from '@mui/material'
import { Dialog, Button, Input } from '@ntpkunity/controls'
import { Controller, useForm } from 'react-hook-form'
import { useModal } from 'react-modal-hook'
import { CustomPlanButtons } from './customPlanStyle'
import {
  ButtonType,
  DialogHeading,
  IconType,
  validation,
  ConfirmationDialog
} from 'shared'
import {
  className,
  FIRST_NAME,
  LAST_NAME,
  Validation
} from '@shared/constants'
import { type ReactElement, useEffect, type FC } from 'react'
import { useCreateCustomOnDemand } from '@modules/product'
import { useStoreContext } from 'store/storeContext'
import { COMPANY_NAME, USER_EMAIL } from '@shared/constants/userProfile'
import DisableLoader from '@shared/assets/images/loader-disabled-btn.gif'

export const messages = {
  title: 'Custom Plan',
  name: {
    name: 'full_name',
    email: 'email',
    phone: 'contact_number',
    company: 'company_name',
    maxTransPerSec: 'max_transactions_per_second',
    maxTransPerMon: 'max_transactions_per_month',
    ProductName: 'product_name',
    additionalDetails: 'additional_details'
  },
  label: {
    name: 'Name',
    email: 'Email',
    phone: 'Contact Number',
    company: 'Company',
    maxTransPerSec: 'Maximum Transactions per Second',
    maxTransPerMon: 'Maximum Transactions per Month',
    additionalDetails: 'Additional Details'
  },
  button: {
    sumbit: 'Submit',
    wait: 'Wait...',
    cancel: 'Cancel',
    subscribe: 'Subscribe',
    unsubscribe: 'Unsubscribe',
    upgrade: 'Upgrade',
    downgrade: 'Downgrade'
  }
}
interface ICustomPlanProps {
  hide: () => void
  productName: string
  productId: number | undefined
}

export const CustomPlan: FC<ICustomPlanProps> = ({ hide: hideCustomPlan, productName, productId }): ReactElement => {
  const theme = useTheme()
  const formMethod = useForm()
  const {
    states: { userProfile }
  } = useStoreContext()
  const { mutate: createCustomOnDemand, isLoading } = useCreateCustomOnDemand()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = formMethod

  const customPlan = `Thank you for your interest in the Custom On-Demand Package of ${productName}! Our sales representative will get back to you shortly.`

  const [show, hide] = useModal(
    () => (
      <ConfirmationDialog
        buttonText={ButtonType.DONE}
        iconName={IconType.SUCCESS}
        title={DialogHeading.THANKS}
        message={customPlan}
        onConfirm={() => {
          hide()
          hideCustomPlan()
        }}
      />
    ),
    []
  )

  const submit = (): void => {
    const body = { product_id: productId, payment_type: 'Bank transfer', ...formMethod.watch() }
    createCustomOnDemand(body, {
      onSuccess () {
        show()
      }
    })
  }

  useEffect(() => {
    reset({
      full_name: ((userProfile?.[FIRST_NAME] ?? '').length > 0)
        ? `${userProfile?.[FIRST_NAME] ?? ''} ${userProfile?.[LAST_NAME] ?? ''}`
        : '',
      email: userProfile?.[USER_EMAIL],
      company_name: userProfile?.[COMPANY_NAME]
    })
  }, [])

  return (
    <Dialog
      size="sm"
      open={true}
      onCloseDialog={hideCustomPlan}
      theme={theme}
      title={messages.title}
      customFooter={
        <>
          <CustomPlanButtons theme={theme} className="custom-plan-buttons">
            {// eslint-disable-next-line @typescript-eslint/no-misused-promises
            }<form className="buttons" onSubmit={handleSubmit(submit)}>
              <Button
                secondary
                text={messages.button.cancel}
                onClick={hideCustomPlan}
                theme={theme}
              ></Button>
              <Button
                className={className.customPlanSubmitBtn}
                type="submit"
                primary
                fullWidth
                theme={theme}
                disabled={isLoading}
                text={isLoading === true ? messages.button.wait : messages.button.sumbit}
                startIcon={
                  isLoading === true && <img src={DisableLoader} alt="Loader" />
                }
              ></Button>
            </form>
          </CustomPlanButtons>
        </>
      }
    >
      <form>
        <Controller
          name={messages.name.name}
          control={control}
          rules={validation(
            'Name',
            Validation.REQUIRED,
            Validation.WHITE_SPACE,
            Validation.LIMIT_LENGTH,
            '50',
            Validation.ONLY_ALPHABETS
          )}
          render={({ field }) => (
            <Input
              theme={theme}
              fullWidth
              label={'Full Name'}
              placeholder="Type here..."
              type={'text'}
              {...field}
              error={errors?.full_name?.message?.toString()}
            />
          )}
        />
        <Controller
          name={messages.name.email}
          control={control}
          rules={validation(
            messages.label.email,
            Validation.REQUIRED,
            Validation.EMAIL
          )}
          render={({ field }) => (
            <Input
              theme={theme}
              fullWidth
              label={'Email Address'}
              type={'text'}
              placeholder="example@example.com"
              {...field}
              error={errors?.email?.message?.toString()}
            />
          )}
        />
        <Controller
          name={messages.name.company}
          control={control}
          rules={validation(
            'Company name',
            Validation.REQUIRED,
            Validation.WHITE_SPACE,
            Validation.LIMIT_LENGTH,
            '50'
          )}
          render={({ field }) => (
            <Input
              theme={theme}
              fullWidth
              placeholder="Type here..."
              label={'Company Name'}
              type={'string'}
              {...field}
              error={errors?.company_name?.message?.toString()}
            />
          )}
        />
        <Controller
          name={messages.name.phone}
          control={control}
          rules={validation(
            'Contact number',
            Validation.PHNONE_NUMBER
          )}
          render={({ field }) => (
            <Input
              theme={theme}
              fullWidth
              label={messages.label.phone}
              placeholder="+000000000000"
              type={'number'}
              {...field}
              error={errors?.contact_number?.message?.toString()}
            />
          )}
        />
      </form>
    </Dialog>
  )
}
