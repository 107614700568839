// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].use[1]!../../atoms/form-inputs/form-input.style.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-container{display:flex;flex-direction:column;align-items:flex-start;gap:24px;align-self:stretch}.email-container__wrapper{display:flex;flex-direction:column;gap:24px}.email-container__wrapper .qr-code{width:100%;height:100%}.email-container button{width:253px}@media screen and (min-width: 1920px){.email-container button{width:320px}}.disclaimer{margin:0}`, "",{"version":3,"sources":["webpack://./src/components/molecules/provide-email/provide-email.scss"],"names":[],"mappings":"AAEA,iBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,QAAA,CACA,kBAAA,CAGF,0BACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,mCACE,UAAA,CACA,WAAA,CAGF,wBACE,WAAA,CAGF,sCACE,wBACE,WAAA,CAAA,CAIJ,YACE,QAAA","sourcesContent":["@import \"../../atoms/form-inputs/form-input.style.css\";\n\n.email-container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 24px;\n  align-self: stretch;\n}\n\n.email-container__wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.email-container__wrapper .qr-code {\n  width: 100%;\n  height: 100%;\n}\n\n.email-container button {\n  width: 253px;\n}\n\n@media screen and (min-width: 1920px) {\n  .email-container button {\n    width: 320px;\n  }\n}\n\n.disclaimer {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
