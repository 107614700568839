import { deleteLenderSelectionRule } from "../../services/lender-selection-rules.service";
import { useMutation, UseMutationOptions } from "react-query";
import { QueryKeys } from "../../Enums/UseQueryKeys";
import { AxiosError } from "axios";

type reqBody = {
  id: number;
};
type DeleteLenderSelectionRuleOptions = Omit<
  UseMutationOptions<any, AxiosError, reqBody>,
  "mutationFn" | "mutationKey"
>;

export const useDeleteLenderSelectionRule = (
  options?: DeleteLenderSelectionRuleOptions
) => {
  return useMutation({
    mutationKey: [QueryKeys.DELETE_LENDER_SELECTION_RULE],
    mutationFn: deleteLenderSelectionRule,
    ...(options || {}),
  });
};
