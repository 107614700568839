import "./accordion.style.css";

export const Accordion = ({
  billingAddress,
  garagingAddress,
  addBillingAddressHandler,
  addGaragingAddressHandler,
}: {
  billingAddress: boolean;
  garagingAddress: boolean;
  addBillingAddressHandler: () => void;
  addGaragingAddressHandler: () => void;
}) => {
  return (
    <>
      {!billingAddress && (
        <div
          role="button"
          tabIndex={0}
          className="checkout-accordion"
          onClick={addBillingAddressHandler}
        >
          <i className="checkout-accordion-plus" />
          <span className="checkout-accordion-text">Add billing address</span>
        </div>
      )}
      {!garagingAddress && (
        <div
          role="button"
          tabIndex={0}
          className="checkout-accordion"
          onClick={addGaragingAddressHandler}
        >
          <i className="checkout-accordion-plus" />
          <span className="checkout-accordion-text">Add garaging address</span>
        </div>
      )}
    </>
  );
};
