import { TagLabel } from "@src/components/atoms";
import { PaymentInfoProps } from "./payment-info.props";
import { StateCtaButtons } from "../state-cta-button/state-cta-button.component";
import "../../../../styles/components/_waiting-hub-state-card.scss";

export const PaymentInfo = ({
  statusText,
  statusType,
  headerIcon = "credit_card",
  headerTitle = "Payment",
  bodyTitle,
  bodySubtitle,
  bodyButtons = [],
  footerTitle,
  footerSubtitle,
  footerButtons = [],
}: PaymentInfoProps) => {
  return (
    <div className="waiting-hub-state-card">
      <div className="waiting-hub-state__header">
        <h5 className="text--subsection-2">
          <span
            className="bmw-icon bmw-icon--before data-icon"
            data-icon={headerIcon}
          />
          {headerTitle}
        </h5>
        {statusText && statusType && (
          <TagLabel text={statusText} type={statusType} />
        )}
      </div>
      <div className="waiting-hub-state__body">
        <div className="waiting-hub-state__inner">
          <div className="waiting-hub-state__content">
            {bodySubtitle && (
              <p className="text--label text-muted">{bodySubtitle}</p>
            )}
            <p className="text--body-1">{bodyTitle}</p>
          </div>
          <StateCtaButtons buttons={bodyButtons} />
        </div>
      </div>
      {(footerSubtitle ||
        footerTitle ||
        (footerButtons && footerButtons.length > 0)) && (
        <div className="waiting-hub-state__footer">
          <div className="waiting-hub-state__content">
            {footerSubtitle && (
              <p className="text--label text-muted">{footerSubtitle}</p>
            )}
            {footerTitle && <p className="text--body-1">{footerTitle}</p>}
          </div>
          {footerButtons && <StateCtaButtons buttons={footerButtons} />}
        </div>
      )}
    </div>
  );
};
