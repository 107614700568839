import { useTheme } from "@mui/material"
import { BusinessRulesAlertsWrap } from "./business-rules.style"
import { Alert } from "@src/components"


export const BusinessRulesAlerts = () => {
    const theme = useTheme()

    return(
        <>
            <BusinessRulesAlertsWrap theme={theme} className="business-rules-alerts-wrap">
                <Alert text={'Collateral Requirements'} description={<><a href="" className="text-danger">Wave Alert</a></>} error fullWidth />
                <Alert text={'Exceptions and Overrides'} description={<><a href="" className="text-danger">Wave Alert</a></>} error fullWidth />
                <Alert text={'Approval Workflow'} description={<><a href="" className="text-danger">Wave Alert</a></>} error fullWidth />
            </BusinessRulesAlertsWrap>
        </>
    )
}