import { ImageProps } from "./Image.props";
import clsx from "clsx";
import { ImageWrap } from "./image.style";

export const Image = ({
  aspectRatio,
  src,
  alt,
  containedImage,
}: ImageProps) => {
  return (
    <ImageWrap
      className={clsx({
        "aspect-ratio-image-wrap": true,
        "aspect-ratio--one-one": aspectRatio === "1:1",
        "aspect-ratio--one-two": aspectRatio === "1:2",
        "aspect-ratio--two-one": aspectRatio === "2:1",
        "aspect-ratio--two-three": aspectRatio === "2:3",
        "aspect-ratio--three-one": aspectRatio === "3:1",
        "aspect-ratio--three-two": aspectRatio === "3:2",
        "aspect-ratio--three-four": aspectRatio === "3:4",
        "aspect-ratio--four-one": aspectRatio === "4:1",
        "aspect-ratio--four-three": aspectRatio === "4:3",
        "aspect-ratio--four-five": aspectRatio === "4:5",
        "aspect-ratio--five-two": aspectRatio === "5:2",
        "aspect-ratio--five-four": aspectRatio === "5:4",
        "aspect-ratio--six-seven": aspectRatio === "6:7",
        "aspect-ratio--eight-three": aspectRatio === "8:3",
        "aspect-ratio--eight-five": aspectRatio === "8:5",
        "aspect-ratio--nine-eight": aspectRatio === "9:8",
        "aspect-ratio--nine-sixteen": aspectRatio === "9:16",
        "aspect-ratio--nine-twentyone": aspectRatio === "9:21",
        "aspect-ratio--ten-sixteen": aspectRatio === "10:16",
        "aspect-ratio--fourteen-three": aspectRatio === "14:3",
        "aspect-ratio--sixteen-five": aspectRatio === "16:5",
        "aspect-ratio--sixteen-seven": aspectRatio === "16:7",
        "aspect-ratio--sixteen-nine": aspectRatio === "16:9",
        "aspect-ratio--sixteen-ten": aspectRatio === "16:10",
        "aspect-ratio--twentyone-nine": aspectRatio === "21:9",
        "aspect-ratio--one-onesix": aspectRatio === "1:1.6",
        "aspect-ratio--onesix-one": aspectRatio === "1.6:1",
        "aspect-ratio--one-onefour": aspectRatio === "1:1.4",
        "aspect-ratio--onefour-one": aspectRatio === "1.4:1",
        "aspect-ratio--onetwo-one": aspectRatio === "1.29:1",
        "aspect-ratio--one-onetwo": aspectRatio === "1:1.29",
      })}
    >
      {src && (
        <img
          className={clsx({
            "aspect-ratio__aspect-ratio-img": true,
            "aspect-ratio__aspect-ratio-img-contain": containedImage,
          })}
          src={src}
          alt={alt}
        />
      )}
    </ImageWrap>
  );
};
