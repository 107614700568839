import React, { FC, useState } from "react";
import { useTheme, Link } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Button, Icon, Menu, Switch } from "@ntpkunity/controls";
import MarkupRow from "./markup-row";
import { ActionOptions } from "Enums";
import { CompressOutlined } from "@mui/icons-material";

const CreditScoreRow: FC<{
  bundleData: any;
  dealer_code?: any;
  onEdit: (data) => unknown;
  onDelete: (data) => unknown;
  handleSelection: any;
}> = ({ bundleData, dealer_code, onEdit, onDelete, handleSelection }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow className="child-tr">
        <TableCell>
          {open ? (
            <Icon
              name="ChevronDown"
              className="toggle-btn ch-right"
              onClick={() => setOpen(!open)}
            />
          ) : (
            <Icon
              name="ChevronDown"
              className="toggle-btn"
              onClick={() => setOpen(!open)}
            />
          )}
          {`${bundleData?.score_from} - ${bundleData?.score_to}`}
        </TableCell>
        <TableCell colSpan={3} />
        <TableCell className="action-cell fixed-cell">
          <Menu
            theme={theme}
            options={[
              {
                optionText: (
                  <>
                    <Icon className="menu-icon" name="DeleteIcon" />
                    {ActionOptions.DELETE}
                  </>
                ),
                optionkey: ActionOptions.DELETE,
                optionValue: bundleData?.id,
              },
            ]}
            handleOptionClick={handleSelection}
            render={(cb) => (
              <Button
                theme={theme}
                defaultBtn
                iconText={<Icon name="MoreIcon" />}
                onClick={cb}
              />
            )}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>
      {open ? (
        <>
          {bundleData && (
            <MarkupRow
              bundle_id={bundleData?.id}
              bundleData={bundleData}
              dealer_code={dealer_code}
              onEdit={onEdit}
              onDelete={onDelete}
              handleSelection={handleSelection}
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default CreditScoreRow;
