import "./icon-button.style.css";

export const IconButton = () => {
  return (
    <button
      className="icon-button icon-button--secondary icon-button--compact header-exit-button"
      type="button"
      data-icon="exit"
      aria-label="exit"
    ></button>
  );
};
