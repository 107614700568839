import "./full-details-drawer-content.scss";
export const FullDetailsDrawerContent = () => {
  return (
    <>
      <div className="incentives-and-discount--product-details-wrap">
        <div className="incentives-and-discount--product-details-credit">
          <h4 className="text--subsection-1 title">Graduate program</h4>
          <div className="incentives-and-discount--product-details-credit__total">
            <span className="text--body-2">Total credit:</span>
            <h3 className="text--promo-focus-price">$X,XXX</h3>
          </div>
          <p className="text--body-2 description">
            Recent college graduates can get an attractive rate and $1,000
            towards the purchase or lease of any new or Certified Pre-Owned
            (CPO) BMW.
          </p>

          <p className="text--disclaimer disclaimer">
            Note: Graduate program can not be combined with non FS cash credit.
          </p>
        </div>
        <div className="incentives-and-discount--product-details-eligibility">
          <h5>Requirements for eligibility:</h5>
          <ul className="text--body-2">
            <li>
              Have earned an undergraduate, graduate, or associate's degree from
              a U.S. accredited college or university within the previous 24
              months, OR be eligible to graduate within the next 6 months and
              have a verifiable offer of employment. A copy of your diploma or
              official transcript will be required.
            </li>
            <li>
              Proof of income or employer letter with the Human Resources
              representative's contact information, stating position/title, and
              income.
            </li>
            <li>
              Have at least 6 months of credit history with no previous
              derogatory marks on any credit lines.
            </li>
            <li>
              A debt-to-income ratio of less than 45%. Automotive payment to
              income ratio of less than 20%.
            </li>
            <li>
              Security deposit will be waived unless stipulated by credit.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
