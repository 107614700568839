import { useTheme } from "@mui/material";
import { Box, Icon, Typography } from "@ntpkunity/controls";
import { PageHeaderProps } from "@components/page-header/page-header.props";
import { PageHeaderWrap } from "@components/page-header/page-header.style";
import clsx from "clsx";

export const PageHeader = ({
  title,
  icon,
  actionArea,
  hasStatus,
  hasSeperator = false,
  hasLabel,
}: PageHeaderProps) => {
  const theme = useTheme();
  return (
    <PageHeaderWrap className="page-header-wrap" theme={theme}>
      {title && (
        <Box theme={theme} className="title-area">
          <Typography
            className="page-title text-h4"
            theme={theme}
            variant={"h4"}
            component={"h4"}
          >
            {title}
          </Typography>
          {hasLabel && (
            <Box theme={theme} className="has-label">
              {hasLabel}
            </Box>
          )}
          {icon && (
            <Box theme={theme} className="icon-area">
              <Icon name={icon as any} />
            </Box>
          )}
        </Box>
      )}

      {actionArea && (
        <Box theme={theme} className="action-area">
          {hasStatus && (
            <Box theme={theme} className="action-item">
              <Box
                theme={theme}
                className={clsx({
                  "status-wrap": true,
                  seperator: hasSeperator,
                })}
              >
                {hasStatus}
              </Box>
            </Box>
          )}
          {actionArea && (
            <Box theme={theme} className="action-item">
              {actionArea}
            </Box>
          )}
        </Box>
      )}
    </PageHeaderWrap>
  );
};
