import { useTheme } from "@mui/material"
import { Icon, Grid, Typography, Textarea, Box, Button, Select, Accordion } from "@ntpkunity/controls"
import { Stack } from "@src/components/stack"
import { DecisionHistoryWrap } from "./decision-history.style"
import { useState } from "react"


export const DecisionHistory = () => {
    const theme = useTheme()

    return (
        <>
        <DecisionHistoryWrap theme={theme} className='decision-history-wrap'>
            <Stack paddingMd={2} paddingXs={2}>
                <Box theme={theme} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography theme={theme} variant="body2" component={'span'} className="fw-sbold">Previous Decision</Typography>
                    <Button theme={theme} endIcon={<><Icon name="InfoIcon"/></>} text={'Additional Information Required'} className="state-unavailable" />
                </Box>
            </Stack>
            <Stack paddingMd={2} paddingXs={2}>
                <Typography theme={theme} variant="body2" component={'span'} className="fw-sbold">Previous Decision</Typography>
                <Box theme={theme} className="accordian-mini-wrap">
                    <Accordion theme={theme}
                        items={[
                            {
                                content: (
                                <>
                                    <Box theme={theme} display={'flex'} alignItems={'center'} gap={2}>
                                        <Button theme={theme} endIcon={<><Icon name="InfoIcon"/></>} text={'Increase Down Payment'} className="green-light" />
                                        <Button theme={theme} endIcon={<><Icon name="InfoIcon"/></>} text={'Increase Terms'} className="green-light" />
                                    </Box>
                                </>
                                ),
                                title: "Financial Stips",
                                key: "history-1",
                                isExpanded: true,
                                id: "history-1",
                                ariaControls: "history-1",
                            },
                            {
                                content: (
                                <>
                                    <Box theme={theme} display={'flex'} alignItems={'center'} gap={2}>
                                        <Button theme={theme} endIcon={<><Icon name="InfoIcon"/></>} text={'Increase Down Payment'} className="pink-light" />
                                        <Button theme={theme} endIcon={<><Icon name="InfoIcon"/></>} text={'Increase Terms'} className="green-light" />
                                    </Box>
                                </>
                                ),
                                title: "Non-Financial Stips",
                                key: "non-history-1",
                                isExpanded: true,
                                id: "non-history-1",
                                ariaControls: "non-history-1",
                            },
                        ]}>

                    </Accordion>
                </Box>
            </Stack>
        </DecisionHistoryWrap>
        </>
    )
        
            
}