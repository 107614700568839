import "../../../styles/components/_feedback-label.scss";
import { FeedbackLabelProps } from "./feedback-label.props";
import clsx from "clsx";

export const FeedbackLabel = ({ text, type, onDark }: FeedbackLabelProps) => {
  return (
    <div className="feedback-label">
      <span
        className={clsx({
          "feedback-label__indicator": true,
          "feedback-label__indicator--success": type === "success",
          "feedback-label__indicator--warning": type === "warning",
          "feedback-label__indicator--primary": type === "primary",
          "feedback-label__indicator--error": type === "error",
        })}
      ></span>
      <p
        className={clsx({
          "feedback-label__text text--status": true,
          "text-muted": !onDark,
          "text-white": onDark,
        })}
      >
        {text}
      </p>
    </div>
  );
};
