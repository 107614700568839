import React, { FC, useState } from "react";
import { useTheme, Link } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Button, Icon, Menu, Switch } from "@ntpkunity/controls";
import { ActionOptions } from "Enums";
import { useUpdateLenderMarkupStatus } from "services/financial-insurance.service";

const MarkupRow: FC<{
  bundle_id: number;
  bundleData: any;
  dealer_code?: any;
  onEdit: (data) => unknown;
  onDelete: (data) => unknown;
  handleSelection: any;
}> = ({ bundleData, bundle_id, handleSelection }) => {
  const theme = useTheme();
  const { mutate: UpdateDealerMarkupsBundle } = useUpdateLenderMarkupStatus();
  const [open, setOpen] = useState(false);

  return (
    <>
      {bundleData?.lender_markup_slabs?.map((lender, lenderIndex) => {
        const combinedMarkups = lender?.markup_slabs
          ?.map((slab) => slab?.markup)
          .join("%, ");
        return (
          <TableRow
            className="child-tr"
            key={`${lender?.lender_id}-${lenderIndex}`}
          >
            <TableCell></TableCell>
            <TableCell>{lender?.name}</TableCell>
            <TableCell>
              {combinedMarkups ? `${combinedMarkups}%` : ""}
            </TableCell>
            <TableCell>
              <Switch
                theme={theme}
                varient={"basic"}
                switchEnabled={lender?.is_active}
                label={lender?.is_active ? "Enabled" : "Disabled"}
                onChange={(event) => {
                  const updatedMarkupsData = {
                    dealer_id: bundleData.dealer_id,
                    score_range_id: bundleData.score_range_id,
                    lender_id: lender.lender_id,
                    is_active: event.target.checked,
                  };
                  UpdateDealerMarkupsBundle(updatedMarkupsData);
                }}
              />
            </TableCell>
            <TableCell className="action-cell fixed-cell">
              <Menu
                theme={theme}
                options={[
                  {
                    optionText: (
                      <>
                        <Icon className="menu-icon" name="EditIcon" />
                        {ActionOptions.EDIT}
                      </>
                    ),
                    optionkey: ActionOptions.EDIT,
                    optionValue: {
                      ...lender,
                      id: bundleData.id,
                      lender_id: lender.lender_id,
                      score_range_id: bundleData?.score_range_id,
                      markup_slabs: lender.markup_slabs,
                    },
                  },
                  {
                    optionText: (
                      <>
                        <Icon className="menu-icon" name="DeleteIcon" />
                        {ActionOptions.DELETE}
                      </>
                    ),
                    optionkey: ActionOptions.DELETE,
                    optionValue: {
                      ...lender,
                      lender_markup_id: lender.lender_markup_id,
                      id: bundleData.id,
                      score_range_id: bundleData?.score_range_id,
                    },
                  },
                ]}
                handleOptionClick={handleSelection}
                render={(cb) => (
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={<Icon name="MoreIcon" />}
                    onClick={cb}
                  />
                )}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

export default MarkupRow;
