import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AlertWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.alert-wrap": {
    padding: "8px 16px",
    display: "flex",
    flexDirection: "column",
    gap: 4,
    borderRadius: theme.shape.borderRadius,
    width: "max-content",
    ".top-content": {
      display: "flex",
      alignItems: "center",
      gap: 8,
      ".alert-text": {
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        gap: 8,
        ".MuiTypography-body2": {
          lineHeight: theme.typography.body1.lineHeight,
        },
      },
      ".alert-icon": {
        display: 'flex',
        gap: 8,
        svg: {
          width: 20,
          height: 20,
        },
      },
      "@media(max-width: 1599.95px)": {
        gap: 16,
      },
    },
    "&.error-alert": {
      backgroundColor: theme.palette.error.dark,
    },
    "&.success-alert": {
      backgroundColor: theme.palette.success.dark,
    },
    "@media(max-width: 1599.95px)": {
      padding: 8,
      gap: 1,
    },
  },
}));
