import { AppContext } from '@app/context-provider'
import { ProductShoppingCard } from '@components'
import { Translations } from '@constants'
import { isEmpty } from 'lodash'
import { IFniProduct } from '@interfaces'
import { fniContext } from '@utilities'
import { VehicleDetailsPopup } from 'libraries/vehicle-details-popup/vehicle-details-popup.component'
import React, { FC, useContext, useEffect, useState } from 'react'
// Component to wrap Product SHopping Card Component
export const FniProductDetailCard: FC<{
	fniProduct: IFniProduct
	rates: any
	selectedTerm: number
}> = ({ fniProduct, rates, selectedTerm }) => {
	const { addOrRemoveProduct } = useContext(fniContext)
	const [covergaeName, setCoverageName] = useState<string>('')
	const [coveragePrice, setCoveragePrice] = useState<number>(0.0)
	const appState = useContext(AppContext)
	const PLACEHOLDERS = appState.state.language.placeholders

  //get Smallest Rate with matched term
  const getSmallestRate = () => {
    let smallestSellingPrice = Infinity;
    let coverage_name = "";
    let coverage_price = 0.0;
    let coverage_terms = 1;
    fniProduct.coverage_terms = selectedTerm;
    for (let i = 0; i < rates?.length; i++) {
      if (rates?.[i]?.product_id == fniProduct?.product_id) {
        fniProduct.session_id = rates?.[i]?.session_id;
          let getCoveragesWithMatchedTerm = []
          if (!fniProduct?.price) {
            //When no product is added
            if (Array.isArray(rates?.[i]?.coverages?.coverage)) {
              getCoveragesWithMatchedTerm = rates?.[i]?.coverages?.coverage?.filter((x: any) => x?.term_months == selectedTerm)
            } else if (!isEmpty(rates?.[i]?.coverages?.coverage)) {
              getCoveragesWithMatchedTerm = [rates?.[i]?.coverages?.coverage]
            }
          } else { 
            getCoveragesWithMatchedTerm = rates?.[i]?.coverages?.coverage?.filter(
              (x: any) =>
                x?.deductibles?.deductible?.selling_price == fniProduct?.price
            );
          }
          coverage_terms = getCoveragesWithMatchedTerm?.[0]?.term_months ?? 1;
          fniProduct.coverage_terms = coverage_terms
          fniProduct.term = getCoveragesWithMatchedTerm?.[0]?.term_months
          fniProduct.mileage = getCoveragesWithMatchedTerm?.[0]?.term_miles
          getCoveragesWithMatchedTerm?.forEach((item: any) => {
            const sellingPrice = item?.deductibles?.deductible?.selling_price;
            const monthlyImpact = item?.deductibles?.deductible?.monthly_impact;
            fniProduct.form_id = item?.form?.form_id;
            fniProduct.rate_id = item?.deductibles?.deductible?.rate_id;
            if (sellingPrice < smallestSellingPrice) {
              smallestSellingPrice = sellingPrice;
              coverage_name = item?.coverage_name;
              coverage_price = monthlyImpact
            }
          });
      }
    }
    setCoverageName(coverage_name);
    setCoveragePrice(coverage_price);
    if (!fniProduct?.price) {
      fniProduct.price =
        smallestSellingPrice == Infinity ? 0 : smallestSellingPrice;
    }
  };
  useEffect(() => {
    getSmallestRate();
  }, [rates]);

	const [open, setOpen] = useState<boolean>(false)
	const openPopup = () => setOpen(true)
	return (
		<>
			<ProductShoppingCard
				productImg={fniProduct?.image?.[0]?.location}
				title={fniProduct?.product_name}
				desc={fniProduct?.description}
				linkText={PLACEHOLDERS.FNI_VIEW_PRODUCT_TEXT}
				onLinkClick={openPopup}
				buttonStatePrimary={!fniProduct.is_added}
				buttonStateSecondary={fniProduct.is_added}
				buttonText={fniProduct.is_added == true ? PLACEHOLDERS.FNI_ADDED : PLACEHOLDERS.FNI_ADD_PRODUCT_TEXT}
				onBtnClick={() => {
					addOrRemoveProduct(fniProduct.id, !fniProduct.is_added, null, fniProduct.term, fniProduct.mileage, null)
				}}
				coverageItem
				coverageTitle={covergaeName}
				coveragePrice={parseFloat(coveragePrice?.toFixed(2) || '0.0')}
				changeTextonHover={true}
				onHoverText={PLACEHOLDERS.FNI_REMOVE_PRODUCT_TEXT}
			/>
			{open && (
				<VehicleDetailsPopup
					open={open}
					setOpen={setOpen}
					product={fniProduct}
					rate={rates?.find((x) => x.product_id == fniProduct.product_id)}
					onButtonClick={addOrRemoveProduct}
					brochureUrl={fniProduct?.brochure_url}
				/>
			)}
		</>
	)
}
