import { Col, Row } from "antd";
import "./incentives-and-discounts.scss";
import {
  FixedVehicleCard,
  VehicleCard,
  WelcomeBanner,
} from "@src/components/molecules";
import { useExtractAndDecodeCode } from "@src/hooks/useChangeDomain";
import { IncentivesListCard } from "@src/components/molecules/incentives-list-card/incentives-list-card.component";
import { Button, Checkbox, Drawer, FeedbackLabel } from "@src/components/atoms";
import { URL_BMW, BMW_BASE } from "@src/constants/routes";
import Image1 from "../../../../shared/assets/img/incentives-discounts-list-img1.png";
import Image2 from "../../../../shared/assets/img/incentives-discounts-list-img2.png";
import Image3 from "../../../../shared/assets/img/incentives-discounts-list-img3.png";
import Image4 from "../../../../shared/assets/img/incentives-discounts-list-img4.png";
import Image5 from "../../../../shared/assets/img/incentives-discounts-list-img5.png";
import { FullDetailsDrawerContent } from "@src/components/molecules/incentives-and-discounts/full-details-drawer-content/full-details-drawer-content";
import { InsentivesAndDiscountsDrawerFooter } from "@src/components/molecules/incentives-and-discounts/insentives-and-discounts-drawer-footer/insentives-and-discounts-drawer-footer.component";
import { useEffect, useState } from "react";
import { UpdatePaymentTermsDrawerContent } from "@src/components/molecules/incentives-and-discounts/update-payment-terms-drawer-content/update-payment-terms-drawer-content";
import { useGetCompanyDomain, useGetToken, useGetCustomer } from "@src/hooks";
import { filterCustomerData } from "@src/helpers/appHelper";
import { decodeToken } from "react-jwt";
import { useAppContext } from "@src/context/app-context";
import { updateCustomer } from "@src/context/app-actions";
import { FinanceLeasePricingDrawerContent } from "@src/components/molecules/incentives-and-discounts/fl-pricing-drawer-content/fl-pricing-drawer-content.component";
export const IncentivesAndDiscounts = () => {
  useExtractAndDecodeCode();
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { mutate: getCustomerByEmail } = useGetCustomer();
  const {
    state: {
      dealer: { id },
      configuration: { urlOrigin },
      user: { customer },
    },
    dispatch: appDispatch,
  } = useAppContext();
  const { mutate: companyData } = useGetCompanyDomain();
  const { mutate: get_token } = useGetToken();
  const userName = JSON.parse(localStorage.getItem("customer") || "{}");
  const loginName = userName?.login_name ?? null;
  const gcid = userName?.gcid ?? null;
  const [isDrawerEnable, setDrawerenable] = useState(false);

  const toggleSignIn = () => {
    setDrawerenable(!isDrawerEnable);
  };

  const toggleDrawer = () => {
    setDrawerVisible(!isDrawerVisible);
  };
  const toggleFLPricingDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setIsDrawerOpen(false);
  };
  useEffect(() => {
    companyData(
      {
        origin_domain: urlOrigin,
      },
      {
        onSuccess: (data) => {
          if (loginName && gcid) {
            get_token(
              {
                user_name: loginName,
                reference_number: gcid,
                slug: data.slug,
              },
              {
                onSuccess: (response) => {
                  localStorage.setItem("customer", JSON.stringify(response));
                  const { email, ...rest } = decodeToken<{ email: string }>(
                    response.access_token
                  ) as any;
                  getCustomerByEmail(
                    { email: email, dealer_id: 7 },
                    {
                      onSuccess(customerInfo) {
                        const filterData = filterCustomerData(customerInfo);
                        const customerData = { ...customer, ...filterData };
                        appDispatch(updateCustomer(customerData));
                      },
                    }
                  );
                },
              }
            );
          }
        },
      }
    );
  }, []);
  return (
    <>
      <WelcomeBanner
        hasCloseIcon={true}
        title={"Find even more savings."}
        decription={
          "Plus, unlock personalized pricing and save your progress automatically if you sign in."
        }
        actionArea={
          <>
            <Button
              text="Sign in"
              type="default"
              htmlType="button"
              onClick={() =>
                (window.location.href = BMW_BASE + "login" + URL_BMW)
              }
            />
            <Button
              text="Register"
              htmlType="button"
              onClick={() =>
                (window.location.href = BMW_BASE + "register" + URL_BMW)
              }
            />
          </>
        }
      />
      <div className="incentives-and-discounts-wrap">
        <div className="bmw-container">
          <Row gutter={{ xs: 16, sm: 16, md: 24 }}>
            <Col xs={24} sm={24} md={24} lg={16}>
              <div className="incentives-discounts-left-content">
                <h3 className="incentives-discounts--title">
                  <span className="text--body-2 label">Build my deal</span>
                  Incentives & discounts
                </h3>
                <div className="incentives-discounts--list_wrap">
                  <div className="incentives-discounts--two_grid_sm">
                    <IncentivesListCard
                      image={Image1}
                      title={"New York energy incentives"}
                      subTitle={
                        "Residents of New York state may qualify for several EV energy incentives, including the Drive Clean Rebate."
                      }
                      price={
                        <h2 className="text--body-focus-price-1">$X,XXX</h2>
                      }
                      className=""
                      hasCheckbox={
                        <Checkbox
                          id={"incentives-discounts--chk1"}
                          label="Select"
                          htmlFor="incentives-discounts--chk1"
                          isCompact
                        />
                      }
                      actionsArea={
                        <Button
                          htmlType={"button"}
                          text="Full details"
                          linkButton
                          onClick={toggleFLPricingDrawer}
                        />
                      }
                    />
                    <IncentivesListCard
                      image={Image2}
                      title={"Purchase credit"}
                      subTitle={
                        "Certain BMWs might qualify for a purchase credit, reducing the final purchase price on your new or Certified Pre-Owned BMW."
                      }
                      price={
                        <h2 className="text--body-focus-price-1">$X,XXX</h2>
                      }
                      className=""
                      hasCheckbox={
                        <Checkbox
                          id={"incentives-discounts--chk1"}
                          label="Select"
                          htmlFor="incentives-discounts--chk1"
                          isCompact
                        />
                      }
                      actionsArea={
                        <Button
                          htmlType={"button"}
                          text="Full details"
                          linkButton
                          onClick={toggleDrawer}
                        />
                      }
                    />
                    <IncentivesListCard
                      image={Image3}
                      title={"Loyalty credit"}
                      subTitle={
                        "Loyal current and former BMW Owners may qualify for exclusive savings."
                      }
                      price={
                        <h2 className="text--body-focus-price-1">$X,XXX</h2>
                      }
                      className=""
                      hasCheckbox={
                        <Checkbox
                          id={"incentives-discounts--chk1"}
                          label="Select"
                          htmlFor="incentives-discounts--chk1"
                          isCompact
                        />
                      }
                      actionsArea={
                        <Button
                          htmlType={"button"}
                          text="Full details"
                          linkButton
                          onClick={toggleDrawer}
                        />
                      }
                    />
                    <IncentivesListCard
                      image={Image4}
                      title={"Conquest credit"}
                      subTitle={
                        "If you currently own or lease a non-BMW, you may qualify for a special Conquest credit to lease or purchase a BMW."
                      }
                      price={
                        <h2 className="text--body-focus-price-1">$X,XXX</h2>
                      }
                      className=""
                      isCardDisabled
                      feedbackLable={
                        <FeedbackLabel
                          text={
                            "[Incentive name] can not be combined with [incentive name]."
                          }
                          type="warning"
                        />
                      }
                      hasCheckbox={
                        <Checkbox
                          id={"incentives-discounts--chk1"}
                          label="Select"
                          htmlFor="incentives-discounts--chk1"
                          isCompact
                        />
                      }
                      actionsArea={
                        <Button
                          htmlType={"button"}
                          text="Full details"
                          linkButton
                          onClick={toggleDrawer}
                        />
                      }
                    />
                    <IncentivesListCard
                      image={Image5}
                      title={"BMW college graduate program"}
                      subTitle={
                        "Recent graduates can celebrate with special savings on a new or Certified Pre-Owned BMW."
                      }
                      price={
                        <h2 className="text--body-focus-price-1">$X,XXX</h2>
                      }
                      className=""
                      hasCheckbox={
                        <Checkbox
                          id={"incentives-discounts--chk1"}
                          label="Select"
                          htmlFor="incentives-discounts--chk1"
                          isCompact
                        />
                      }
                      actionsArea={
                        <Button
                          htmlType={"button"}
                          text="Full details"
                          linkButton
                          onClick={toggleDrawer}
                        />
                      }
                    />
                  </div>
                </div>

                <div className="incentives-discounts--ctas">
                  <div className="incentives-discounts--ctas_flexible">
                    <div>
                      <Button
                        htmlType={"button"}
                        text="Continue"
                        type="primary"
                        fixed
                        fullWidth
                      />
                    </div>
                    <div>
                      <Button
                        htmlType={"button"}
                        text="Sign in to save"
                        fullWidth
                        onClick={toggleSignIn}
                      />
                    </div>
                  </div>
                  <p className="text--disclaimer text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                    proident, sunt in culpa qui officia deserunt mollit anim id
                    est laborum.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <div className="address-right-content">
                <div className="address-right-content__inner">
                  {/* <VehicleCard cashDownInfo hasFooter /> */}
                  <div className="fixed-vehicle-card-placeholder"></div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <FixedVehicleCard />
      </div>
      <div className="incentives-and-discount-drawer">
        <Drawer
          width={336}
          open={isDrawerEnable}
          onClose={closeDrawer}
          maskClosable={true}
          keyboard={true}
          content={
            <>
              <FinanceLeasePricingDrawerContent />
            </>
          }
          footer={
            <>
              <InsentivesAndDiscountsDrawerFooter
                price={
                  <>
                    <small className="text--disclaimer">Est. payments</small>
                    <h2 className="text--body-focus-price-1">
                      $X,XXX
                      <span className="text--body-1">/month</span>{" "}
                    </h2>
                  </>
                }
              />
            </>
          }
        />
        <Drawer
          width={336}
          open={isDrawerOpen}
          onClose={closeDrawer}
          maskClosable={true}
          keyboard={true}
          content={
            <>
              <FullDetailsDrawerContent />
            </>
          }
          footer={
            <>
              <InsentivesAndDiscountsDrawerFooter
                price={
                  <>
                    <h2 className="text--body-focus-price-1">
                      $X,XXX <span className="text--body-1">total credit</span>
                    </h2>
                  </>
                }
                footerActions={
                  <Button
                    htmlType={"button"}
                    text="Add to deal"
                    type="default"
                    onClick={toggleDrawer}
                  />
                }
              />
            </>
          }
        />
        <Drawer
          width={336}
          open={isDrawerVisible}
          onClose={closeDrawer}
          maskClosable={true}
          keyboard={true}
          content={
            <>
              <FullDetailsDrawerContent />
            </>
          }
          footer={
            <>
              <InsentivesAndDiscountsDrawerFooter
                price={
                  <>
                    <h2 className="text--body-focus-price-1">
                      $X,XXX <span className="text--body-1">total credit</span>
                    </h2>
                  </>
                }
                footerActions={
                  <Button
                    htmlType={"button"}
                    text="Add to deal"
                    type="default"
                    onClick={toggleDrawer}
                  />
                }
              />
            </>
          }
        />
        <div className="update-payment-terms-drawer-main">
          <Drawer
            width={336}
            open={false}
            maskClosable={true}
            keyboard={true}
            content={
              <>
                <UpdatePaymentTermsDrawerContent />
              </>
            }
            footer={
              <>
                <InsentivesAndDiscountsDrawerFooter
                  price={
                    <>
                      <small className="text--disclaimer">Est. payments</small>
                      <h2 className="text--body-focus-price-1">
                        $X,XXX
                        <span className="text--body-1">/month</span>{" "}
                      </h2>
                    </>
                  }
                  footerActions={
                    <Button
                      htmlType={"button"}
                      text="Save & close"
                      type="default"
                    />
                  }
                />
              </>
            }
          />
        </div>
      </div>
    </>
  );
};
