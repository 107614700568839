import { useTheme } from "@mui/material"
import { Icon, Grid, Typography, Textarea, Box, Button, Select } from "@ntpkunity/controls"
import { Stack } from "@src/components/stack"
import { AddStipWrap } from "./add-stip.style"
import { useState } from "react"


export const AddStip = () => {
    const theme = useTheme()

    const [isToggled, setIsToggled] = useState(false);

    const handleClick = () => {
        setIsToggled(!isToggled); 
    };

    return (
        <>
        <AddStipWrap theme={theme} className={`add-stip-wrap ${isToggled ? 'toggled' : ''}`}>
            <Stack paddingMd={2} paddingXs={2} title={'Stipulation'} 
                actionArea={
                        <>
                            {isToggled === false ? (
                                <>
                                    <a href="javascript:void(0)" className="text-primary primary-alert dFlex alignItemsCenter" onClick={handleClick}>
                                        Add Stip <Icon name="AddIcon" />
                                    </a>
                                </>
                                ) : (
                                <Button theme={theme} defaultBtn endIcon={<Icon name="CloseBlack" />} onClick={() => setIsToggled(false)} />
                            )}
                        </>
                }
            >
                <Grid
                    theme={theme}
                    container
                    rowSpacing={2}
                    columnSpacing={2}
                    mt={0}
                >
                    <Grid item xs={6} theme={theme}>
                        <Select
                            theme={theme}
                            disablePortal={false}
                            label={'Stipulation Type'}
                            items={[
                                { text: "Financial", value: "financial" },
                                { text: "Non Financial", value: "nonFinancial" },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={6} theme={theme}>
                        <Select
                            theme={theme}
                            disablePortal={false}
                            label={'Recommendation'}
                            items={[
                                { text: "Increase Down Payment", value: "downPayment" },
                                { text: "Increase Terms", value: "increaseTerms" },
                                { text: "Add Employment Documents", value: "employmentDocuments" },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} theme={theme}>
                        <Textarea theme={theme} id="" type="text" fullWidth textCount={"10"} totalTextCount={"60"} rows={4} label="Comments"></Textarea>  
                    </Grid>
                    <Grid item xs={12} theme={theme}>
                        <Box theme={theme} display={'flex'} justifyContent={'center'}> 
                            <Button theme={theme} primary text="Add Stipulation" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} theme={theme}>
                        <Box theme={theme} display={'flex'} alignItems={'center'} gap={2}> 
                            <Button theme={theme} className="light-grey text-black" text={'Add Co-Borrower'} endIcon={<Icon name="CloseBlack" />} onClick={() => {}} />
                            <Button theme={theme} className="light-grey text-black" text={'Increase Down Payment'} endIcon={<Icon name="CloseBlack" />} onClick={() => {}} />
                        </Box>
                    </Grid>
                </Grid>
            </Stack>
        </AddStipWrap>
        </>
    )
        
            
}