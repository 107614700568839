import { BankDetails } from "../bank-details/bank-details.component";

import "./payment-method.scss";

import CreditImage from "../../../shared/assets/img/creditImage.png";
import { CreditCardDetails } from "../credit-card-details/credit-card-details.component";
import { PaymentAuthorization } from "../payment-authorization/payment-authorization.component";

export const PaymentMethod = () => {
  return (
    <>
      <div className="payment-method">
        <p className="payment-method-subtitle text--body-2">
          Select payment method
        </p>
        <div className="payment-method-choices-wrapper">
          <div className="payment-method-choice payment-method-choice--selected">
            <p className="payment-method-choice-name text--body-2">
              Bank account
            </p>
          </div>
          <div className="payment-method-choice">
            <p className="payment-method-choice-name text--body-2">
              Credit card
            </p>
            <img
              className="payment-method-choice-image"
              src={CreditImage}
              alt="Bank Account"
            />
          </div>
        </div>
      </div>
      <BankDetails />
      {/* <CreditCardDetails /> */}
      {/* <PaymentAuthorization /> */}
    </>
  );
};
