import { FC } from 'react'
import { Box, IBoxProps, Icon } from '@ntpkunity/controls'
import Carousel from 'react-multi-carousel'
import { IconButton, styled, useTheme } from '@mui/material'
import './ResponsiveCarousel.css'

export const CarouselButtons = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.carousel-button-group': {
    '.carousel-control': {
      position: 'absolute',
      backgroundColor: theme.palette.common.white,
      width: 40,
      height: 40,
      marginTop: -15,
      top: '16%',
      padding: 0,
      borderRadius: theme.shape.borderRadius,
      transform: 'translateY(-50%)',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      },
      '&.next-btn': {
        right: 0,
        transform: 'translatey(0) rotate(270deg)'
      },
      '&.prev-btn': {
        left: 0,
        transform: 'rotate(90deg)'
      },
      svg: {
        width: 20,
        height: 20,
        path: {
          stroke: theme.palette.grey[400]
        }
      },
      '&:hover': {
        backgroundColor: theme.customVariables.lightGrey
      }
    }
  }
}))

interface IResponsiveCarouselProps {
  children: any
  infinite?: boolean
  removeArrowOnDeviceType?: string | Array<string>
  keyBoardControl?: boolean
  containerClass?: string
  itemClass?: string
  slidesToSlide?: number
  autoPlay?: boolean
  responsive: ResponsiveType
  swipeable?: boolean
  draggable?: boolean
  pauseOnHover?: boolean
  autoPlaySpeed?: number
  showDots?: boolean
  renderDotsOutside?: boolean
}
export interface ResponsiveType {
  [key: string]: {
    breakpoint: {
      max: number
      min: number
    }
    items: number
    partialVisibilityGutter?: number
    paritialVisibilityGutter?: number
    slidesToSlide?: number
  }
}
const CarouselButtonGroup = ({ next, previous }: any) => {
  const theme = useTheme()
  return (
    <CarouselButtons theme={theme} className="carousel-button-group">
      <IconButton className="prev-btn carousel-control" onClick={() => previous()}>
        <Icon name="ChevronDown" />
      </IconButton>
      <IconButton className="next-btn carousel-control" onClick={() => next()}>
        <Icon name="ChevronDown" />
      </IconButton>
    </CarouselButtons>
  )
}

const ResponsiveCarousel: FC<IResponsiveCarouselProps> = ({
  children,
  infinite = true,
  removeArrowOnDeviceType = ['xl', 'lg', 'md', 'sm', 'xs'],
  keyBoardControl = true,
  containerClass = 'carousel-container',
  itemClass = 'carousel-item',
  slidesToSlide = 1,
  autoPlay = true,
  responsive,
  pauseOnHover,
  autoPlaySpeed,
  showDots = true,
  renderDotsOutside = false
}) => {
  return (
    <Carousel
      swipeable={true}
      draggable={true}
      showDots={showDots}
      pauseOnHover={pauseOnHover}
      responsive={responsive}
      infinite={infinite}
      autoPlay={autoPlay}
      autoPlaySpeed={autoPlaySpeed}
      keyBoardControl={keyBoardControl}
      containerClass={containerClass}
      removeArrowOnDeviceType={removeArrowOnDeviceType}
      itemClass={itemClass}
      slidesToSlide={slidesToSlide}
      renderButtonGroupOutside={true}
      additionalTransfrom={0}
      renderDotsOutside={renderDotsOutside}
      customButtonGroup={<CarouselButtonGroup next={{}} previous={{}} />}
    >
      {children}
    </Carousel>
  )
}

export default ResponsiveCarousel
