import { Input } from "@src/components/atoms";
import "./credit-card-details.scss";

export const CreditCardDetails = () => {
  return (
    <div className="bank-details">
      <p className="credit-details-subtitle text--body-2">
        Enter your credit details
      </p>
      <div className="credit-details-inputs-wrapper">
        <div className="credit-details-input cardholder-name">
          <Input
            id={"bank-details-input--cardholder_name"}
            name={"bank-details-input--cardholder_name"}
            placeholder="Cardholder name"
            label="Cardholder name"
          />
        </div>
        <div className="credit-details-input card-number">
          <Input
            id={"bank-details-input--card_number"}
            name={"bank-details-input--card_number"}
            placeholder="Card number"
            label="Card number"
          />
        </div>
        <div className="bank-details-input expiration-date">
          <Input
            id={"bank-details-input--expiration_date"}
            name={"bank-details-input--expiration_date"}
            placeholder="mm / yyyy"
            label="Expiration date (month/year)"
          />
        </div>
        <div className="bank-details-input security-code">
          <Input
            id={"bank-details-input--security_code"}
            name={"bank-details-input--security_code"}
            placeholder="CVV"
            label="Security code/CVV"
          />
        </div>
        <div className="bank-details-input zip-code">
          <Input
            id={"bank-details-input--zip_code"}
            name={"bank-details-input--zip_code"}
            placeholder="ZIP Code"
            label="ZIP Code"
          />
        </div>
      </div>
    </div>
  );
};
