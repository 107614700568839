import "./multi-swatch-circles.scss";

import { MultiSwatchCircleProps } from "./multi-swatch-circles-props";
import clsx from "clsx";
import { Button, Image } from "@src/components/atoms";
import ImageOne from "../../../shared/assets/img/multi-swatch-circle__one.png";
import ImageTwo from "../../../shared/assets/img/multi-swatch-circle__two.png";

import ImageThree from "../../../shared/assets/img/multi-swatch-circle__three.png";

export const MultiSwatchCircles = ({
  className,
  image,
  isDisabledCard,
}: MultiSwatchCircleProps) => {
  return (
    <div
      className={clsx(className, {
        "multi-swatch-circles-main": true,
      })}
    >
      <div className="multi-swatch-circles multi-swatch-circles--one">
        <Image src={ImageOne} alt="card-image" />
      </div>

      <div className="multi-swatch-circles multi-swatch-circles--two">
        <Image src={ImageTwo} alt="card-image" />
      </div>

      <div className="multi-swatch-circles multi-swatch-circles--three">
        <Image src={ImageThree} alt="card-image" />
      </div>

      <div className="multi-swatch-circles multi-swatch-circles--four">
        <p className="text--body-2">+3</p>
      </div>
    </div>
  );
};
