import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const MyNotesWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.my-notes-wrap": {
    ".add-sticky-notes": {
      padding: '12px',
      border: '1px solid',
      borderRadius: 8,
      borderColor: theme.palette.grey[100],
      width: '100%',
      height: 46,
      "&.clicked": {
        height: 'auto',
        ".add-title": {
          display: 'flex'
        }
      },
      ".add-title": {
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 12,
        paddingBottom: 12,
        gap: 10,
        borderBottom: '1px solid',
        borderColor: theme.palette.grey[100],
        display: 'none'
      },
      ".u-form-group": {
        ".u-form-control": {
          "&.textarea": {
            ".MuiInputBase-root": {
              padding: 0
            }
          },
          fieldset: {
            border: 'none'
          }
        }
      },
    },
    ".custom-checkbox-wrap": {
      ".u-custom-control-label": {
        margin: 0
      }
    },
    ".select-priority": {
      display: 'flex',
      alignItems: 'center',
      ".select": {
        width: 100
      },
      ".priority": {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        ".clr-picker": {
          width: 32,
          height: 32,
          borderRadius: 60,
          border: '1px solid transparent',
          "&.selected": {
            border: '1px solid' + CustomColors.bodyColor,
          }
        }
      }
    },
    '.scrollable-container': {
      maxHeight: 'calc(100vh - 162px)',
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarWidth: 'none',
      ".notes-listing": {
        columnCount: 2,
        ".my-notes-list-wrap": {
          marginBottom: 16
        }
      }
    },
  },
}));
