import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const TopSellingWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.top-selling-wrap': {
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: 7,
      height: 7
    },
    '&::-webkit-scrollbar-track': {
      padding: '0 1px',
      backgroundColor: '#f0f0f0'
    },
    '&::-webkit-scrollbar-thumb': {
      transition: 'all 0.3s',
      backgroundColor: '#cdcdcd'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a6a6a6'
    },
    '.selling-list': {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      borderRadius: 8,
      marginBottom: 8,
      '&.with-bg': {
        backgroundColor: '#f2f2f2',
        padding: 8
      },
      ':last-child': {
        marginBottom: 0
      }
    },
    '.prd-image': {
      maxWidth: 96,
      img: {
        width: '100%'
      }
    },
    '.prd-details': {
      flex: 1,
      '.title-md': {
        color: theme.palette.grey[600]
      },
      '.title-lg': {
        color: theme.palette.grey[900]
      }
    }
  }
}))
