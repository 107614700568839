import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Button, Textarea } from '@ntpkunity/controls'
import { Controller, useForm } from 'react-hook-form'
import ChatBoxWrap from './ChatBox.style'

const ChatBox: FC = () => {
  const theme = useTheme()
  const { control } = useForm()
  return (
    <ChatBoxWrap theme={theme} className="chat-box">
      <Controller
        name="message"
        control={control}
        defaultValue={''}
        render={({}) => (
          <Textarea
            id={'message'}
            theme={theme}
            fullWidth
            type="text"
            rows={4}
            placeholder="Comment or type “/” for commands"
          />
        )}
      />
      <Button primary theme={theme} text="Send" onClick={() => {}} />
    </ChatBoxWrap>
  )
}

export default ChatBox
