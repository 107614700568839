import { message } from "antd";

interface initialStateType {
  Index: number;
  message: boolean;
  stepZeroDataReceived: boolean;
  stepOneDataReceived: boolean;
  stepTwoDataReceived: boolean;
  stepThreeDataReceived: boolean;
  stepFourDataReceived: boolean;
  stepFiveDataReceived: boolean;
  stepSixDataReceived: boolean;
}

export const initialState: initialStateType = {
  Index: 0,
  stepZeroDataReceived: false,
  stepOneDataReceived: false,
  stepTwoDataReceived: false,
  stepThreeDataReceived: false,
  stepFourDataReceived: false,
  stepFiveDataReceived: false,
  message: false,
  stepSixDataReceived: false,
};

export const reducer = (state: initialStateType, action: { type: string }) => {
  switch (action.type) {
    case "Co-applicant":
      return {
        ...state,
        stepZeroDataReceived: true,
      };
    case "intial":
      return {
        ...state,
        stepZeroDataReceived: false,
      };
    case "zero-Step":
      return {
        ...state,
        message: true,
        stepZeroDataReceived: false,
      };
    case "Next-step": {
      if (state.Index === 6) {
        return state;
      }
      return {
        ...state,
        Index: state.Index + 1,
      };
    }
    case "Step-one-data-received": {
      return {
        ...state,
        stepOneDataReceived: true,
      };
    }
    case "Step-two-data-received": {
      return {
        ...state,
        stepTwoDataReceived: true,
      };
    }
    case "Step-three-data-received": {
      return {
        ...state,
        stepThreeDataReceived: true,
      };
    }
    case "Step-four-data-received": {
      return {
        ...state,
        stepFourDataReceived: true,
      };
    }
    case "Step-five-data-received": {
      return {
        ...state,
        stepFiveDataReceived: true,
      };
    }
    case "Step-six-data-received": {
      return {
        ...state,
        stepSixDataReceived: true,
      };
    }
    case "Step-one-data-received-status-change": {
      return {
        ...state,
        Index: 0,
        stepOneDataReceived: false,
      };
    }
    case "Step-two-data-received-status-change": {
      return {
        ...state,
        Index: 1,
        stepTwoDataReceived: false,
      };
    }
    case "Step-three-data-received-status-change": {
      return {
        ...state,
        Index: 2,
        stepThreeDataReceived: false,
      };
    }
    case "Step-four-data-received-status-change": {
      return {
        ...state,
        Index: 3,
        stepFourDataReceived: false,
      };
    }
    case "Step-five-data-received-status-change": {
      return {
        ...state,
        Index: 4,
        stepFiveDataReceived: false,
      };
    }
    case "Step-six-data-received-status-change": {
      return {
        ...state,
        Index: 5,
        stepSixDataReceived: false,
      };
    }
    default:
      return state;
  }
};
