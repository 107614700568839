import { addLenderSelectionRule } from "../../services/lender-selection-rules.service";
import { useMutation, UseMutationOptions } from "react-query";
import { QueryKeys } from "../../Enums/UseQueryKeys";
import { LenderSelectionRulePostReq } from "../../Types/lenderSelectionRules";
import { AxiosError } from "axios";

type AddLenderSelectionRuleOptions = Omit<
  UseMutationOptions<any, AxiosError, LenderSelectionRulePostReq>,
  "mutationFn" | "mutationKey"
>;

export const useAddLenderSelectionRule = (
  options?: AddLenderSelectionRuleOptions
) => {
  return useMutation({
    mutationKey: [QueryKeys.ADD_LENDER_SELECTION_RULE],
    mutationFn: addLenderSelectionRule,
    ...(options || {}),
  });
};
