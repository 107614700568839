import React, { useState, useEffect } from "react";
import { Card, Input, Button, Typography } from "antd";
const { Title } = Typography;

import { useLocation, useNavigate } from "react-router-dom";

const AuthChecker = ({ children }) => {
  const PASSWORD_KEY = "auth_password";
  const EXPIRY_KEY = "auth_expiry";
  const VALID_PASSWORD = "q!K5nM@PzX7Yt1";
  const VALID_HOURS = 24;
  const EXPIRY_TIME_MS = VALID_HOURS * 60 * 60 * 1000; // 1 hour

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [inputPassword, setInputPassword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const savedPassword = localStorage.getItem(PASSWORD_KEY);
    const expiryTime = localStorage.getItem(EXPIRY_KEY);

    if (
      savedPassword === VALID_PASSWORD &&
      expiryTime &&
      new Date().getTime() < Number(expiryTime)
    ) {
      setIsAuthenticated(true);
    } else {
      localStorage.removeItem(PASSWORD_KEY);
      localStorage.removeItem(EXPIRY_KEY);
      navigate(`${""}?returnUrl=${location.pathname}`);
    }
  }, [location.pathname, navigate]);

  const handleLogin = () => {
    if (inputPassword === VALID_PASSWORD) {
      const expiryTime = new Date().getTime() + EXPIRY_TIME_MS;
      localStorage.setItem(PASSWORD_KEY, VALID_PASSWORD);
      localStorage.setItem(EXPIRY_KEY, expiryTime.toString());
      const returnUrl =
        new URLSearchParams(location.search).get("returnUrl") || "/";
      setIsAuthenticated(true);
      navigate(returnUrl);
    } else {
      alert("Invalid password!");
    }
  };

  if (!isAuthenticated) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f0f2f5",
        }}
      >
        <Card style={{ width: 400, textAlign: "center" }}>
          <Title level={3}>Authentication Required</Title>
          <Input.Password
            value={inputPassword}
            onChange={(e) => setInputPassword(e.target.value)}
            placeholder="Enter password"
            style={{ marginBottom: 20 }}
          />
          <Button
            type="primary"
            block
            onClick={handleLogin}
            style={{ height: 50 }}
          >
            Login
          </Button>
        </Card>
      </div>
    );
  }

  return <>{children}</>;
};

export default AuthChecker;
