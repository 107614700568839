import {
  dealexPermissions
  //@ts-ignore
} from '@ntpkunity/controls-ums'

export const accessToken = 'settings'
export const ACCESS_TOKEN_KEY = 'access_token'
export const SETTINGS = 'settings'
export const EXTERNAL_SUBSCRIPTION_KEY = 'external-subscription-key'
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z]).{6,35}$/
export const PHONE_REGEX =
  /^\+?(\d{1,3})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const ZIP_CODE_REGEX = /^\d{5,5}?(-\d{4})?$/
export const WEBSITE_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
export const REMOVE_DASHES_REGEX = /[\s-]/g
export const vehcileStatuses = {
  VEHICLE_AVAILABLE_CONFIRMTAION: 'PENDING_AVAILABILITY_CHECK',
  GET_READY_CHECKLIST: 'PENDING_POST_SALE_DEALER_ACTIONS',
  SCHEDULE_PICKUP: 'PENDING_PICKUP',
  PENDING_RETURN: 'PENDING_RETURN',
  COMPLETED: 'COMPLETE',
  CANCELED: 'CANCELED'
}

export const WORKQUEUE_SCREEN_COMPONENTS = [
  {
    heading: 'Vehicle Availability Confirmation',
    subheading: 'Are these vehicles available?'
  },
  {
    heading: 'Get Ready Checklist',
    subheading: 'Mark get ready checklist.'
  },
  {
    heading: 'Scheduled Pick-ups',
    subheading: 'Mark checklist for pick-ups.'
  },
  {
    heading: 'Returns',
    subheading: 'Mark checklist for returns.'
  },
  {
    heading: 'Completed',
    subheading: 'Below is the list of completed deals.'
  },
  {
    heading: 'Cancelled',
    subheading: 'Below is the list of cancelled deals.'
  }
]

export const WORKQUEUE_MENU = [
  { optionText: 'Finance First', optionValue: dealexPermissions.ICONS.FINANCE_FIRST },
  { optionText: 'Pre-Qualification', optionValue: dealexPermissions.ICONS.PRE_QUALIFICATION }
]

export const urlPattern = /^(https?:\/\/)?[\da-z.-]+\.[a-z.]{2,6}\/?.*$/i

export const DEFAULT_RENTAL_FREQUENCY = 'Monthly'

export const DEFAULT_RENTAL_MODE = 'Advance'

export const UPDATE_DEAL = 'Update Deal'

export const CONTINUE_DEAL = 'Continue Deal'

export const RESERVE_ASSET = 'Reserve Asset'

export const SAVE_AND_CONTINUE = 'Save & Continue'

export const RENTAL_FREQUENCY = [
  { id: 'Monthly', text: 'Monthly' },
  { id: 'Quarterly', text: 'Quarterly' },
  { id: 'SemiAnnual', text: 'Semi Annual' },
  { id: 'Annual', text: 'Annual' }
]

export enum CustomerColumns {
  CUSTOMER_NAME = 'full_name',
  VINS = 'vins',
  StockNumbers = 'stockNumbers',
  REFERENCE_ID = 'reference_id',
  SUBSCRIPTION_START_DATE = 'subscription_start_date',
  SUBSCRIPTION_END_DATE = 'subscription_end_date',
  Order_Ids = 'orderIds',
  Order_Status = 'order_status',
  VIN = 'vin',
  ORDER_IDENTIFIER = 'order_identifier',
  Stock_Number = 'stock_number',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  DEALER_ID = 'dealer_id'
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc'
}

export enum SortOrderFields {
  order_updated_at = 'order_updated_at'
}

export const DisclaimerNames = {
  CCPA: 'CCPA',
  TCPA: 'TCPA',
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS'
}

export const ConsentStatus = {
  PUBLISHED: 'Published'
}

export const PRE_QUALIFICATION_CONSTANTS = {
  title: 'Pre-Qualification',
  formHeading: 'Get Pre-Qualified',
  addressTitle: 'Mailing Address',
  tableHeader: 'Pre-Qualification Responses',
  tableDescription: 'Below is the list of pre-qualification responses.',
  customerQualified: 'The customer is successfully qualified',
  customerNotQualified: 'The customer is not qualified',
  notQualifiedReason: 'The fraud check for this customer has failed.',
  disclaimer: `By submitting, I certify that I have received written consent under the FCRA to access this applicant's credit report from iSoftpull, Experian, TransUnion and/or Equifax and agree to retain copies of all written authorizations for a minimum of 5 years from the date of inquiry, and I will make such authorizations available to iSoftpull upon request. I also certify that I have received permission for this applicant's contact information to be given to a third party lender, if applicable.`,
  defaultValues: {
    social_security_number: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    dealer_id: 0,
    county: '',
    date_of_birth: '',
    state: '',
    email: '',
    first_name: '',
    last_name: '',
    mobile_phone_number: '',
    zip_code: '',
    reference_number: ''
  }
}

export const PRE_QUALIFICATIONS_STATUS = {
  accept: 'Qualified',
  reject: 'Not Qualified'
}

export const Months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const CreditApplicationCases: any = {
  Submitted: {
    icon: 'ClockIcon',
    class: 'grey'
  },
  Deferred: {
    icon: 'DialogCrossIcon',
    class: 'grey'
  },
  Approved: {
    icon: 'GreenCheckIcon',
    class: 'success'
  },
  Declined: {
    icon: 'DialogCrossIcon',
    class: 'error'
  },
  Failed: {
    icon: 'DialogCrossIcon',
    class: 'error'
  },
  Conditioned: {
    icon: 'NotVerifiedIcon',
    class: 'warning'
  }
}

export const VEHICLE_PRIOR_USE = [
  { text: 'Personal, Family & Household', value: 'Personal, Family & Household' },
  { text: 'Demonstrator', value: 'Demonstrator' },
  { text: 'Livery', value: 'Livery' },
  { text: 'Police', value: 'Police' },
  { text: 'Prior Wreckage', value: 'Prior Wreckage' },
  { text: 'Daily Rental', value: 'Daily Rental' },
  { text: 'Unknown', value: 'Unknown' }
]

export const TRANSMISSION_TYPE = [
  { text: 'Manual', value: 'Manual' },
  { text: 'Automatic', value: 'Automatic' }
]

export const SSN_MASKING_REGEX = [
  /[\d●]/,
  /[\d●]/,
  /[\d●]/,
  '-',
  /[\d●]/,
  /[\d●]/,
  '-',
  /[\d●]/,
  /[\d●]/,
  /[\d●]/,
  /[\d●]/
]

export const FRAUD_CHECK_FAILURE_MESSAGE = 'Fraud Check Failed'
export const FEE_MONTHLY_PAYMENT_BASED = 'Monthly Payment based'
export const RESERVATION_TYPE_PERCENTAGE_MSRP = 'Percentage of MSRP'
export const TimeZones = {
  'Baker Island Time': '-12:00',
  'Niue Time': '-11:00',
  'Hawaii-Aleutian Standard Time': '-10:00',
  'Alaska Standard Time': '-09:00',
  'Pacific Standard Time': '-08:00',
  'Mountain Standard Time': '-07:00',
  'Central Standard Time': '-06:00',
  'Eastern Standard Time': '-05:00',
  'Atlantic Standard Time': '-04:00',
  'Brasília Time': '-03:00',
  'South Georgia Time': '-02:00',
  'Azores Standard Time': '-01:00',
  'Coordinated Universal Time': '+00:00',
  'Central European Time': '+01:00',
  'Eastern European Time': '+02:00',
  'Moscow Standard Time': '+03:00',
  'Gulf Standard Time': '+04:00',
  'Pakistan Standard Time': '+05:00',
  'Bangladesh Standard Time': '+06:00',
  'Indochina Time': '+07:00',
  'China Standard Time': '+08:00',
  'Japan Standard Time': '+09:00',
  'Australian Eastern Standard Time': '+10:00',
  'Magadan Time': '+11:00',
  'Fiji Time': '+12:00',
  'India Standard Time': '+05:30',
  'Singapore Time': '+08:00',
  'Israel Standard Time': '+02:00',
  'South Africa Standard Time': '+02:00',
  'Mountain Daylight Time': '-06:00',
  'Eastern Daylight Time': '-04:00',
  'Pacific Daylight Time': '-07:00'
}

export const URL_PATTERN_REGEX = /^https?:\/\//i
