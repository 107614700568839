// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkout-accordion {
  margin: 16px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1920px) {
  .checkout-accordion {
    margin: 20px 0;
  }
}

.checkout-accordion-plus {
  font-size: var(--body-1-font-size);
  line-height: var(--body-1-line-height);
  font-weight: var(--clickable-font-weight);
  margin-right: 2px;
}

.checkout-accordion-plus:before {
  font-family: "bmw_next_icons";
  display: block;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  text-transform: none;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-align: left;
  speak: none;
  height: 1em;
  outline: 1px solid transparent;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  content: "plus";
  width: auto;
}

@media reader, speech, aural {
  .checkout-accordion-plus:before {
    display: none;
  }
}

.checkout-accordion-plus:before {
  font-weight: 400;
}

.checkout-accordion-text {
  font-size: var(--body-2-font-size);
  line-height: var(--body-2-line-height);
  font-weight: var(--clickable-font-weight);
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/components/organisms/credit-application/checkout/utils/accordion/accordion.style.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE;IACE,cAAc;EAChB;AACF;;AAEA;EACE,kCAAkC;EAClC,sCAAsC;EACtC,yCAAyC;EACzC,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;EAC7B,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,oBAAoB;EACpB,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;EACd,gBAAgB;EAChB,WAAW;EACX,WAAW;EACX,8BAA8B;EAC9B,mCAAmC;EACnC,kCAAkC;EAClC,kCAAkC;EAClC,6BAA6B;EAC7B,eAAe;EACf,WAAW;AACb;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kCAAkC;EAClC,sCAAsC;EACtC,yCAAyC;EACzC,gBAAgB;AAClB","sourcesContent":[".checkout-accordion {\n  margin: 16px 0;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n\n@media screen and (min-width: 1920px) {\n  .checkout-accordion {\n    margin: 20px 0;\n  }\n}\n\n.checkout-accordion-plus {\n  font-size: var(--body-1-font-size);\n  line-height: var(--body-1-line-height);\n  font-weight: var(--clickable-font-weight);\n  margin-right: 2px;\n}\n\n.checkout-accordion-plus:before {\n  font-family: \"bmw_next_icons\";\n  display: block;\n  font-weight: 400;\n  font-style: normal;\n  line-height: 1;\n  text-transform: none;\n  word-wrap: normal;\n  white-space: nowrap;\n  direction: ltr;\n  text-align: left;\n  speak: none;\n  height: 1em;\n  outline: 1px solid transparent;\n  -webkit-font-smoothing: antialiased;\n  text-rendering: optimizeLegibility;\n  -moz-osx-font-smoothing: grayscale;\n  font-feature-settings: \"liga\";\n  content: \"plus\";\n  width: auto;\n}\n\n@media reader, speech, aural {\n  .checkout-accordion-plus:before {\n    display: none;\n  }\n}\n\n.checkout-accordion-plus:before {\n  font-weight: 400;\n}\n\n.checkout-accordion-text {\n  font-size: var(--body-2-font-size);\n  line-height: var(--body-2-line-height);\n  font-weight: var(--clickable-font-weight);\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
