import { useTheme } from "@mui/material"
import { DocumentsListWrap } from "./document-list.style"
import { Icon, Box, Typography, Accordion, Label } from "@ntpkunity/controls"
import { Alert } from "@src/components"
import { PdfList } from "@src/components"


export const DocumentsList = () => {
    const theme = useTheme();

    const getTimestampWithGap = (monthsGap) => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() + monthsGap);
        return currentDate.toISOString().split("T")[0]; 
      };

    return(
        <>
            <DocumentsListWrap theme={theme} className="document-list-wrap">
                <Box theme={theme} className="documents">
                    <PdfList
                        title={'Applicant ID.pdf'}
                        subTitle={getTimestampWithGap(2)}
                        variant="light"
                        pdfIcon={true}
                    />
                    <PdfList
                        title={'Salary Slip.pdf'}
                        subTitle={getTimestampWithGap(3)}
                        variant="light"
                        pdfIcon={true}
                    />
                    <PdfList
                        title={'Bank Statement.pdf'}
                        subTitle={getTimestampWithGap(4)}
                        variant="light"
                        pdfIcon={true}
                    />
                    <PdfList    
                        title={'Employment Letter.pdf'}
                        subTitle={getTimestampWithGap(5)}
                        variant="light"
                        pdfIcon={true}
                    />
                </Box>
                {/* <Box theme={theme} className="documents">
                    <Typography theme={theme} variant="caption" component={'small'} className="text-muted">Insurance Contract Document</Typography>
                    <PdfList
                        title={'Sample_report.pdf'}
                        subTitle={'00-00-0000'}
                        variant="light"
                    />
                    <PdfList
                        title={'Sample_report.pdf'}
                        subTitle={'00-00-0000'}
                        variant="light"
                    />
                    <PdfList
                        title={'Sample_report.pdf'}
                        subTitle={'00-00-0000'}
                        variant="light"
                    />
                </Box> */}
            </DocumentsListWrap>
            
        </>
    )
}