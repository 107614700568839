import { useTheme } from '@mui/material'
import { LeadSummaryStatusSectionWrap } from './LeadSummaryStatusSection.style'
import { LeadSummaryStatusSectionProps } from './LeadSummaryStatusSection.props'
import { Box, Typography, Icon } from '@ntpkunity/controls'
import clsx from 'clsx'

export const LeadSummaryStatusSection = ({ items }: LeadSummaryStatusSectionProps) => {
  const theme = useTheme()

  return (
    <LeadSummaryStatusSectionWrap theme={theme} className="lead-summary-status-section">
      {items?.map((item, index) => (
        <Box theme={theme} className="section" key={index}>
          <Icon name="ChevronRight" className="icon" />
          <Box
            theme={theme}
            className={clsx({ 'inner-box': true, selected: item.selected, error: item.error })}
          >
            {item.title && (
              <Typography
                theme={theme}
                variant="subtitle2"
                component={'p'}
                className="title text-truncated"
              >
                {item.title}
              </Typography>
            )}
            {item.subtitle && (
              <Typography
                theme={theme}
                variant="caption"
                component={'small'}
                className="subtitle text-truncated"
              >
                {item.subtitle}
              </Typography>
            )}
          </Box>
        </Box>
      ))}
    </LeadSummaryStatusSectionWrap>
  )
}
