import { Button } from "@src/components/atoms";
import "./checkout-header.scss";

import Logo from "../../shared/assets/img/logo.jpg";
import { useAppContext } from "@src/context/app-context";
import { useEffect } from "react";

export const CheckoutHeader = () => {
  const {
    state: {
      dealer: { dealer_name, logo },
    },
  } = useAppContext();
  const theme = JSON.parse(localStorage.getItem("dealer") || "{}");
  return (
    <header className="checkout-header">
      <div className="checkout-header-left">
        <img
          className="checkout-header-logo"
          src={logo ? logo : theme.logo ?? Logo}
          alt="BMW Logo"
        />
        <div className="checkout-header-texts">
          <p className="text--body-1">
            {dealer_name ? dealer_name : theme?.dealer_name}
          </p>
          <p className="text--body-2">Digital Retailing Platform</p>
        </div>
      </div>
      <div className="checkout-header-right">
        <div className="checkout-header-user-container">
          <Button
            linkButton
            withIcon
            htmlType={"button"}
            text="Need help?"
            dataIcon="request_for_contact"
            disabled
          />
        </div>
        <div className="checkout-header-user-container">
          <Button
            linkButton
            withIcon
            htmlType={"button"}
            text="Christopher"
            dataIcon="person"
            disabled
          />
          <i className="checkout-header-chevron-down bmw-icon bmw-icon--before"></i>
        </div>
        <Button htmlType="button" onlyIcon dataIcon="exit" type="default" />
      </div>
    </header>
  );
};
