import { useTheme } from "@mui/material"
import { StickyNotesWrap } from "./sticky-notes.style"
import { Typography, Box, Button, Icon } from "@ntpkunity/controls";
import { clsx } from "clsx"

export const StickyNotes = ({title, subTitle, color}) => {
    const theme = useTheme();

    return(
        <>
            <StickyNotesWrap theme={theme} className={clsx("sticky-notes-wrap", {"light-yellow": color === "yellow", "light-ferozi": color === "ferozi", "light-purple": color === "purple"})}>
                <Box theme={theme} className="notes-head">
                    <Typography theme={theme} variant="body2" component={"span"} className="fw-sbold title">{title}</Typography>
                </Box>
                <Box theme={theme} className="notes-body">
                    <Typography theme={theme} variant="body1" component={"span"} className="text-muted text-body1 subTitle">
                        {subTitle}
                    </Typography>
                </Box>
                <Box theme={theme} className="notes-footer">
                    <Button
                        theme={theme}
                        defaultBtn
                        iconText={
                        <>
                            <Icon name="ExpandLeftIcon" />
                        </>
                        }
                        onClick={()=>{}}
                    />
                    <Button
                        theme={theme}
                        defaultBtn
                        iconText={
                        <>
                            <Icon name="IconDeleteV2" />
                        </>
                        }
                        onClick={()=>{}}
                    />
                </Box>
            </StickyNotesWrap>
        </>
    )
}