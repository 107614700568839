import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const PdfListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.business-rules-alerts-wrap": {
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    display: 'flex',
    aligItems: 'center',
    alignSelf: 'stretch',
    padding: 8,
    gap: 8,
    cursor: 'pointer',
    "&.light": {
      backgroundColor: CustomColors.lightGrey
    },
    ".doc-list": {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      flex: '1 0 0',
      gap: 4,
    }
  },
}));
