import { useQuery } from "react-query";
import { queryKeyFactory } from "@helpers/query";
import { getComplianceDocuments } from "@services/index.service";
import { ComplianceDocumentSetupRes } from "@_types/setups";
import { useGetCompanyByTenantId } from "@hooks/queries";

const useGetComplianceDocuments = (
  customerType?: string,
  financeType?: string
) => {
  const { data: companyInfo } = useGetCompanyByTenantId();
  const companyId = companyInfo?.id;

  return useQuery<
    ComplianceDocumentSetupRes,
    unknown,
    ComplianceDocumentSetupRes
  >({
    queryKey: queryKeyFactory.getComplianceDocuments(
      companyId,
      customerType,
      financeType
    ),
    queryFn: () => getComplianceDocuments(companyId, customerType, financeType),
    enabled: !!companyId,
  });
};

export default useGetComplianceDocuments;
