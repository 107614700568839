import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  FETCH_MY_NOTES_REQUEST,
  fetchMyNotesSuccess,
  fetchMyNotesFailure,
} from "../actions/myNotesActions";

function* fetchMyNotesSaga() {
  try {
    const apiUrl = process.env.REACT_APP_API_URL;
    // const apiKey = process.env.REACT_APP_AUTH_TOKEN;
    const apiKey = "pk_unity_3692394e-32d7-11ef-8144-4adabd389cec";

    const response = yield call(axios.get, `${apiUrl}/dms/customer/comments`, {
      headers: { "x-api-key": apiKey },
    });

    if (response?.data?.result) {
      yield put(fetchMyNotesSuccess(response.data.result));
    } else {
      throw new Error("Empty response from API");
    }
  } catch (error) {
    console.error("API Call Failed:", error);

    yield put(fetchMyNotesFailure(error.response?.data?.message || error.message));
  }
}

export function* watchMyNotesSaga() {
  yield takeLatest(FETCH_MY_NOTES_REQUEST, fetchMyNotesSaga);
}
