import { Button } from "@src/components/atoms";
import { StateCtaButtonProps } from "./state-cta-button.props";

export const StateCtaButtons = ({
  buttons,
}: {
  buttons: StateCtaButtonProps[];
}) => (
  <div className="waiting-hub-state__cta">
    {buttons.map((button, index) => (
      <Button
        key={index}
        htmlType="button"
        text={button.text}
        disabled={button.disabled}
        linkButton={button.linkButton}
        withIcon={button.withIcon}
        dataIcon={button.dataIcon}
        type={button.type}
        size="small"
        fullWidth
        onClick={button.onClick}
      />
    ))}
  </div>
);
