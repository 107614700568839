import { useTheme } from '@mui/material'
import {} from './AddPackagePageHeaderStyle'
import { CustomPageHeader } from '@shared/components/pageHeader/pageHeader'
import { Button, Typography } from '@ntpkunity/controls'

export const AddPackagePageHeaderComponent = () => {
  const theme = useTheme()
  return (
    <CustomPageHeader
      theme={theme}
      variant='border'
      onBackBtn={()=>{}}
      title={
        <>
          <Typography theme={theme} variant="h2" component="h2">
            Add Package
          </Typography>
        </>
      }
      actionArea={
        <>
          <Button theme={theme} primary text="Save" onClick={()=>{}} />
        </>
      }
    />
  )
}
