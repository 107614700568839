import { useMutation, useQuery } from "react-query"
import { TradeInManagement } from "../apis/trade-in-management"
import {QueryKeys} from "../constants";


export const useGetQuestions = (company_id: any, slug: string) => {
    return useMutation(
        async (body: any) => {
            return  TradeInManagement.getQuestionsAndAnswers(body, company_id, slug);
        }
    );
}

export const useGetConfiguration = (dealer_code:any, integration_type:any, provider_name: any, company_id: any, slug:string) => {
        return useQuery([QueryKeys.GET_CONFIGURATION_PROVIDER, provider_name] , () => {
            return TradeInManagement.getConfiguration(dealer_code,integration_type,provider_name, company_id, slug)
        }
    )
}

export const useGetTradeInProviderLogo = (provider_name: string, company_id: number, slug: string) => {
    return useQuery([QueryKeys.GET_CONFIGURATION_PROVIDER_LOGO, provider_name] , () => {
        return TradeInManagement.getTradeInProviderLogo(provider_name, company_id, slug)
    }
  )
}
export const useGetVehicleDetailsByVin = (slug: string) => {
    return useMutation(
        async (body: any) => {
            return  TradeInManagement.getTradeInThroughVin(body, slug);
        }
    );
}

export const useGetTradeInYear = (slug: string) => {
    return useMutation(
        async (body: any) => {
            return  TradeInManagement.getTradeInYear(body, slug);
        }
    );
}

export const useGetTradeInMake = (slug: string) => {
    return useMutation(
        async (body: any) => {
            return  TradeInManagement.getTradeInMake(body, slug);
        }
    );
}

export const useGetTradeInModel = (slug: string) => {
    return useMutation(
        async (body: any) => {
            return  TradeInManagement.getTradinModel(body, slug);
        }
    );
}

export const useGetTradeInTrim = (slug: string) => {
    return useMutation(
        async (body: any) => {
            return  TradeInManagement.getTradeInTrim(body, slug);
        }
    );
}

export const useGetTradeInVehicleDetail = (slug: string) => {
    return useMutation(
        async (body: any) => {
            return  TradeInManagement.getTradeInVehicleDetail(body, slug);
        }
    );
}

export const useGetTradeInVehicleValue = (tenant: any, slug: string) => {
    return useMutation(
        async (body: any) => {
            return  TradeInManagement.getTradeInVehicleValue(body, tenant, slug);
        }
    );
}
export const useGetTradeInVehicleOptions = (slug: string) => {
    return useMutation(
        async (body: any) => {
            return  TradeInManagement.getTradeInVehicleOptions(body, slug);
        }
    );
}
export const useGetDealerTradeIn = ( dealerCode: string,company_id: number, slug: string) => {
    return useQuery([QueryKeys.GET_DEALER_TRADE_IN, dealerCode] , () => {
        return TradeInManagement.getDealerTradeIn( dealerCode,company_id, slug)
    }
  )
}
