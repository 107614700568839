import { Button, Image, Modal } from "@src/components/atoms";
import DealVehicle from "../../../../shared/assets/img/dr-start/deal-vehicle.png";
import "./remove-deal-modal.scss";

export const RemoveDealModal = ({ isModalOpen, setIsModalOpen }) => {
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        className="deal-modal"
        open={isModalOpen}
        onClose={handleClose}
        closable
      >
        <div className="deal-modal__inner">
          <div className="deal-modal__img">
            <Image src={DealVehicle} alt="image" aspectRatio="1:1" />
          </div>

          <div className="deal-modal__content">
            <div>
              <h3>
                Are you sure you want to remove the deal you’ve built for this
                [20XX BMW Model Name]?
              </h3>
              <p className="text--body-1">
                Deleting this deal will remove it from your saved deals. Select
                cancel if you prefer to save it.
              </p>
            </div>
            <div className="deal-modal__cta">
              <Button type="primary" htmlType={"button"} text="Remove deal" />
              <Button
                type="default"
                htmlType={"button"}
                text="Cancel"
                onClick={handleClose}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
