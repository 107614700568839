import "./insurance-information-completed.scss";
import { Button } from "@src/components/atoms";
import { useAppContext } from "@src/context/app-context";
import { formatUSPhoneNumberWithCountryCode } from "@src/helpers";

export const InsuranceInformationCompleted = ({
  handleClick,
}: {
  handleClick: () => void;
}) => {
  const { state: appState } = useAppContext();
  return (
    <div className="insurance-information-info-wrapper">
      <div className="insurance-information-header">
        <div className="checkout-form-header">
          <h3 className="checkout-title checkout-step text--subsection-2 text-secondary">
            02
          </h3>
          <h3 className="checkout-title text--subsection-2">
            Insurance information.
          </h3>
        </div>
        <Button
          htmlType={"button"}
          text="Edit"
          onClick={handleClick}
          size="small"
        />
      </div>
      <div className="insurance-information-content">
        <div className="insurance-information-card">
          <div className="insurance-information-container-wrapper">
            <div className="insurance-information-container">
              <p className="insurance-information-title text--disclaimer text-secondary">
                Insurance provider's name
              </p>
              <p className="insurance-information-value text--body-2">
                {appState.order.Insurance_Inforation.insurer_name}
              </p>
            </div>
            <div className="insurance-information-block" />
            <div className="insurance-information-container">
              <p className="insurance-information-title text--disclaimer text-secondary">
                Insurance contact number
              </p>
              <p className="insurance-information-value text--body-2">
                {formatUSPhoneNumberWithCountryCode(
                  appState.order.Insurance_Inforation.agent_phone
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
