import "./root.component.scss";
import { Provider } from "react-redux";
import store from "./calc-store/store";
import { unityTheme } from "@ntpkunity/controls";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material";
import { getToken } from "./helpers/Localstorage";
import { FlexTheme } from "./shared/theme/flexStyle";
import {
  LoginValidatorComponent,
  UMSConfigurationComponent,
} from "@ntpkunity/controls-ums";
import CalculationRoutes from "./components/controls/links";

export default function Root() {
  !getToken()?.access_token &&
    window.location.replace(process.env.FLEX_MARKETING);
  document.title = "Flex";

  return (
    <UMSConfigurationComponent>
      <LoginValidatorComponent isPublic>
        <FlexTheme theme={unityTheme} className="flex-theme">
          <Provider store={store}>
            <Router>
              <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={unityTheme}>
                  <CalculationRoutes />
                </MuiThemeProvider>
              </StyledEngineProvider>
            </Router>
          </Provider>
        </FlexTheme>
      </LoginValidatorComponent>
    </UMSConfigurationComponent>
  );
}
