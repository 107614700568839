import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const StickyNotesDialogWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.sticky-notes-dialog-wrap": {
    padding: 8,
    ".stack-wrap": {
      ".stack-header": {
        padding: '0 4px'
      }
    },
    ".select-priority": {
      display: 'flex',
      alignItems: 'center',
      ".select": {
        width: 197
      },
      ".priority": {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        ".light-grey": {
          display: 'flex',
          alignItems: 'center',
          padding: '8px 16px',
          cursor: 'pointer',
          borderRadius: 8,
          gap: 8,
          ".u-custom-chip": {
            height: 'auto',
            "&.MuiChip-filled": {
              background: 'transparent',
              ".MuiChip-label": {
                color: CustomColors.bodyColor,
                padding: 0
              }
            },
          },
          img: {
            display: 'none'
          },
          "&.selected": {
            backgroundColor: '#E9EAFE',
            img: {
              display: 'block'
            },
          }
        },
        ".clr-picker": {
          width: 48,
          height: 48,
          borderRadius: 8,
          border: '1px solid transparent',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          img: {
            display: 'none'
          },
          "&.selected": {
            border: '1px solid' + CustomColors.bodyColor,
            img: {
              display: 'block'
            },
          }
        }
      }
    }
  },
}));
