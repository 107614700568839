import "./banner-section.scss";

export const WelcomeBackBanner = () => {
  return (
    <div className="dr-start-banner-section">
      <div className="bmw-container">
        <div className="dr-start-banner__inner">
          <div className="dr-start-banner__content">
            <h2 className="text--h2">
              Welcome back. Find your previously built deals here.
            </h2>
            <p className="text--body-2 text-white dr-start-banner__link">
              This list doesn't include all saved vehicles - please visit{" "}
              <a
                className="text-white fw-normal no-decoration"
                href="/bmwusa.com"
              >
                your garage
              </a>{" "}
              on bmwusa.com to view all saved builds.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
