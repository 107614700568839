import { useTheme } from "@mui/material";
import { Accordion, Box, Label, Dialog, CircleLoader, DuoTab } from "@ntpkunity/controls";
import { Popup } from "@src/components/Dialog/dialog.component";
import { RiskAssessmentDialogWrap } from "./risk-assessment-dialog.style"
import { RiskAssessmentDialogData } from "../risk-assessment-dialog-data/risk-assessment-dialog-data.component"
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRiskAssessmentRequest } from "@src/components/redux/actions/riskAssessmentActions";

export const RiskAssessmentDialog = ({ open, closeDialog }) => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const { riskAssessmentData, loading, error } = useSelector(
    (state: any) => state.riskAssessment
  );

  useEffect(() => {
    if (open) {
      const customerReferenceId = "U-H000D3UA";
      dispatch(fetchRiskAssessmentRequest(customerReferenceId));
    }
  }, [dispatch]);

  // useEffect(() => {
  //   if (open && assessmentId) {
  //     dispatch(fetchRiskAssessment(assessmentId));
  //   }
  // }, [dispatch, open, assessmentId]);

  if (loading) return <CircleLoader theme={theme} size="sm" />;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
    {/* <p>sdsd</p> */}
      <Popup>
        
        <Dialog
          theme={theme}
          size={"md"}
          onCloseDialog={closeDialog}
          open={open}
          title={"Risk Assessment Matrices"}
          disablePortal
          noFooter
        >
          <RiskAssessmentDialogWrap
            theme={theme}
            className="asset-detail-dialog-wrap collapse-wrap"
          >
            <DuoTab
              theme={theme}
              varient="underline"
              defaultTabIndex={1}
              items={[
                {
                  content: <><RiskAssessmentDialogData riskAssessmentDialogueData={riskAssessmentData?.primaryApplicant} /></>,
                  title: 'Primary Applicant',
                },
                {
                  content: <><RiskAssessmentDialogData riskAssessmentDialogueData={riskAssessmentData?.primaryApplicant} /></>,
                  title: 'Co-Applicant',
                },
              ]}
            />
          </RiskAssessmentDialogWrap>
        </Dialog>
      </Popup>
    </>
  );
};
