import { Button, Image } from "@src/components/atoms";
import "./hero-section.scss";
import Vehicle from "../../../../shared/assets/img/dr-start/vehicle.png";
import { HeroContent, BannerInfoContent } from "@src/components/molecules";
import clsx from "clsx";

export const HeroSection = ({
  buildInProgress,
  checkoutInProgress,
  hasAlert,
  haveDeals,
}: {
  buildInProgress?: boolean;
  checkoutInProgress?: boolean;
  haveDeals?: boolean;
  hasAlert?: boolean;
}) => {
  return (
    <div
      className={clsx({
        "dr-start-hero-section": true,
        "have-deals": haveDeals,
      })}
    >
      <div className="bmw-container">
        <div className="dr-start-hero__inner">
          <HeroContent
            buildInProgress={buildInProgress}
            checkoutInProgress={checkoutInProgress}
            hasAlert={hasAlert}
          />
          <div className="dr-start-hero__img">
            <Image src={Vehicle} alt="image" aspectRatio="16:9" />
          </div>
        </div>
        <div className="dr-start-hero__inner--after d-block d-lg-none">
          <BannerInfoContent
            buildInProgress={buildInProgress}
            checkoutInProgress={checkoutInProgress}
            hasAlert={hasAlert}
          />
        </div>
        {haveDeals ? (
          <div className="dr-start-hero__bottom">
            <p className="text--label text-white fw-normal">
              View additional saved deals
            </p>
            <Button
              htmlType={"button"}
              linkButton
              withIcon
              dataIcon="arrow_down"
              onDark
            />
          </div>
        ) : (
          <>
            <p className="text--label text-white dr-start-hero__link">
              By clicking build deal, I agree to the BMW Center{" "}
              <a className="text-white fw-normal no-decoration" href="/">
                Terms
              </a>{" "}
              and{" "}
              <a className="text-white fw-normal no-decoration" href="/">
                Privacy Policy
              </a>
              . Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea consequat.
            </p>
          </>
        )}
      </div>
    </div>
  );
};
