import { useTheme } from "@mui/material";
import { Box, Typography, Icon } from "@ntpkunity/controls";
import { ReactNode } from "react";
import clsx from "clsx";
import { AlertWrap } from "./alert-status-text.style";

export const AlertStatusText = ({
  text,
  statusText,
  error,
  success,
  description,
  fullWidth,
  value,
}: {
  text?: string | ReactNode;
  statusText?: string | ReactNode;
  error?: boolean;
  success?: boolean;
  description?: ReactNode;
  fullWidth?: boolean;
  value?: ReactNode;
}) => {
  const theme = useTheme();
  return (
    <AlertWrap
      theme={theme}
      className={clsx({
        "alert-wrap": true,
        "w-100": fullWidth,
        "error-alert": error,
        "success-alert": success,
      })}
    >
      <Box theme={theme} className="top-content">
        {text && (
          <Box theme={theme} className="alert-text">
            <Typography
              theme={theme}
              variant="body1"
              component={"span"}
              display={"block"}
              className="fw-sbold"
            >
              {text}
            </Typography>
            {value && (
              <Typography
                theme={theme}
                component={"h4"}
                variant="h4"
                className={clsx({
                  "text-danger": error,
                  "text-success": success,
                })}
              >
                {value}
              </Typography>
            )}
          </Box>
        )}
        <Box theme={theme} className="alert-icon">
          {statusText && <>
            <Typography
              theme={theme}
              component={"span"}
              variant="body2"
              className={clsx({
                "text-danger": error,
                "text-success": success,
              })}
            >{statusText}</Typography>
          </>}
          
          {success && <Icon name="GreenCheckCircleIcon" />}
          {error && <Icon name="ErrorTriangleOutline" />}
        </Box>
      </Box>
      {description && (
        <Box theme={theme} className="bottom-content">
          {description}
        </Box>
      )}
    </AlertWrap>
  );
};
