import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";

import AuthChecker from "@components/atoms/password-checker/password-checker.coponent";
import CheckoutPage from "@pages/checkout-page/checkout-page";
import FinanceOfferPage from "@pages/finance-offer-page/finance-offer-page";
import MitekImageCapture from "@pages/poc/mitek-page";

// extra page for test components
import { PreviewComponent } from "@src/components/components-preview/component-preview";
import DealStatePage from "@src/pages/deal-state/deal-state-page";
import ReturningToDr from "@src/pages/returning-to-dr/returning-to-dr";
import {
  IncentivesAndDiscountsPage,
  ProtectionAndWarrantyPage,
  TradeInPage,
} from "@src/pages";
import { AccessoriesPage } from "@src/pages/build-your-deal/accessories-page/accessories-page";
import { ReviewPage } from "@src/pages/build-your-deal/review-page/review-page";
import DrStartPage from "@src/pages/dr-start/dr-start-page";
import WaitingHubPage from "@src/pages/waiting-hub/waiting-hub-page";

const dealRefPath = "/deal/:dealRefNumber";

const ROUTING_PATH = {
  CHECKOUT: "/checkout",
  DEAL_ID_PAGE: `/checkout${dealRefPath}`,
  FINANCE_OFFER: "/finance-offer",
  FINANCE_OFFER_WITH_DEAL: `/finance-offer${dealRefPath}`,
  RETURNING_TO_DR: "/returning-dr",
  DEAL_STATE_PAGE: `/deal-state${dealRefPath}/:state`,
  PREVIEW_COMPONENT: "/component-preview",
  MISNAP_MITEK_POC: "/misnap-mobile",
  INCENTIVES_AND_DISCOUNTS_PAGE: "/incentives-and-discounts",
  PROTECTION_AND_WARRANTY_PAGE: "/protection-and-warranty",
  FRAUD_CHECK: "/fraud-check",
  ACCESSORIES_PAGE: "/accessories",
  REVIEW_PAGE: "/review",
  TRADE_IN_PAGE: "/trade-in",
  DR_START_PAGE: "/dr-start",
  WAITING_HUB_PAGE: "/waiting-hub",
  SSO: "/sso",
};

const ErrorPage = () => {
  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <p>The page you're looking for doesn't exist.</p>
      <a href={ROUTING_PATH.CHECKOUT}>Go to Checkout</a>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={ROUTING_PATH.CHECKOUT} replace />,
  },
  {
    path: ROUTING_PATH.CHECKOUT,
    element: (
      <AuthChecker>
        <CheckoutPage />
      </AuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.DEAL_ID_PAGE,
    element: (
      <AuthChecker>
        <CheckoutPage />
      </AuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.FINANCE_OFFER,
    element: (
      <AuthChecker>
        <FinanceOfferPage />
      </AuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.FINANCE_OFFER_WITH_DEAL,
    element: (
      <AuthChecker>
        <FinanceOfferPage />
      </AuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.DEAL_STATE_PAGE,
    element: (
      <AuthChecker>
        <DealStatePage />
      </AuthChecker>
    ),
  },

  {
    path: ROUTING_PATH.RETURNING_TO_DR,
    element: (
      <AuthChecker>
        <ReturningToDr />
      </AuthChecker>
    ),
  },

  {
    path: ROUTING_PATH.FRAUD_CHECK,
    element: (
      <AuthChecker>
        <DealStatePage error />
      </AuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.INCENTIVES_AND_DISCOUNTS_PAGE,
    element: (
      <AuthChecker>
        <IncentivesAndDiscountsPage />
      </AuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.SSO,
    element: (
      <AuthChecker>
        <IncentivesAndDiscountsPage />
      </AuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.PROTECTION_AND_WARRANTY_PAGE,
    element: (
      <AuthChecker>
        <ProtectionAndWarrantyPage />
      </AuthChecker>
    ),
  },

  {
    path: ROUTING_PATH.ACCESSORIES_PAGE,
    element: (
      <AuthChecker>
        <AccessoriesPage />
      </AuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.DR_START_PAGE,
    element: (
      <AuthChecker>
        <DrStartPage />
      </AuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.WAITING_HUB_PAGE,
    element: (
      <AuthChecker>
        <WaitingHubPage />
      </AuthChecker>
    ),
  },

  {
    path: ROUTING_PATH.REVIEW_PAGE,
    element: (
      <AuthChecker>
        <ReviewPage />
      </AuthChecker>
    ),
  },

  {
    path: ROUTING_PATH.PREVIEW_COMPONENT,
    element: <PreviewComponent />,
  },
  {
    path: ROUTING_PATH.TRADE_IN_PAGE,
    element: (
      <AuthChecker>
        <TradeInPage />
      </AuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.MISNAP_MITEK_POC,
    element: <MitekImageCapture />,
  },
  {
    path: "*", // Matches all undefined routes
    element: <ErrorPage />,
  },
]);

export const RouterProviderWrapper = () => {
  return <RouterProvider router={router} />;
};
