import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PhoneInputProps } from "./phone-input.props";
import "../../../styles/components/_phone-input.scss";
import clsx from "clsx";
import { validatePhoneNumberLength } from "@src/helpers/appHelper";

export const Phone = ({
  name,
  country,
  value: initialValue,
  onChange,
  placeholder,
  countryCodeEditable = false,
  disabled,
  enableSearch,
  disableSearchIcon,
  inputProps,
  label,
  showInfoIcon,
  helpText,
  error,
  formControl,
  status,
  clearErrors,
}: PhoneInputProps) => {
  const handlePhoneChange = (value, country) => {
    if (onChange) {
      onChange(value);
    }
    const isValidLength = validatePhoneNumberLength(value, country);

    if (!isValidLength && formControl?.setError) {
      formControl?.setError(name, {
        type: "manual",
        message: "Invalid phone number length",
      });
    } else {
      clearErrors && clearErrors(name);
    }
  };

  return (
    <div
      className={clsx({
        "phone-input-wrap": true,
        "phone-input-disabled": disabled,
        "phone-input-invalid": status === "error",
      })}
    >
      {label && (
        <label
          className={clsx({
            "phone-label text--label text-secondary": true,
          })}
        >
          <span>{label}</span>
          {showInfoIcon && (
            <div
              className="phone-label-icon"
              role="img"
              aria-label="Icon for information"
              data-icon="information"
            />
          )}
        </label>
      )}
      <PhoneInput
        country={country}
        value={initialValue}
        onChange={handlePhoneChange}
        placeholder={placeholder}
        disabled={disabled}
        countryCodeEditable={countryCodeEditable}
        enableSearch={enableSearch}
        disableSearchIcon={disableSearchIcon}
        isValid={validatePhoneNumberLength}
        specialLabel=""
        inputProps={inputProps}
      />
      {helpText && (
        <label className="help-text text-secondary text--disclaimer">
          {helpText}
        </label>
      )}
      {error && error.message && (
        <label className="error-text text-error text--disclaimer">
          {error.message}
        </label>
      )}
    </div>
  );
};
