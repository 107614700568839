import "../../../styles/components/financial-service-card-select.scss";
import { FinancialServiceCardSelectProps } from "./financial-service-card-select.props";
import clsx from "clsx";

export const FinancialServiceCardSelect = ({
  className,
  logo,
  title,
  subTitle,
  price,
  apr,
  term,
  downCash,
  selected,
  hasCheckbox,
}: FinancialServiceCardSelectProps) => {
  return (
    <div
      className={clsx(className, {
        "financial-service-card": true,
        "financial-service-card-filled": selected,
      })}
    >
      <div className="financial-service-header">
        <div className="financial-service-desktop-container">
          <div className="financial-service-header-title-container">
            <div className="financial-service-logo-container">
              {logo && (
                <img
                  className="financial-service-logo"
                  src={logo}
                  alt="logo-img"
                />
              )}
              <p className="financial-service-title text--subsection-2">
                {title}
              </p>
            </div>
          </div>
          <div className="financial-service-radio-desktop-container">
            {hasCheckbox && hasCheckbox}
          </div>
        </div>
        {subTitle && (
          <p className="financial-service-subtitle text--body-2">{subTitle}</p>
        )}
      </div>
      <hr className="financial-service-hr" />
      <div className="financial-service-content">
        <div className="financial-service-price-container">
          <div className="financial-service-content-container">
            <div className="financial-service-content-container">
              <span className="financial-service-month-price text--stage-focus-price">
                {price}
              </span>
              <span className="financial-service-monthly text--body-2">
                {" "}
                / month
              </span>
            </div>
            <div className="financial-service-content-container text--body-2">
              <span className="financial-service-cash text--body-focus-price">
                {downCash}
              </span>
              <span className="financial-service-cash-down"> cash down</span>
            </div>
          </div>
          <div className="financial-service-apr-container">
            <p className="financial-service-apr text--body-2">{apr}</p>
            <p className="financial-service-term text--body-2">{term}</p>
          </div>
        </div>
      </div>
      {/* <hr className="financial-service-hr financial-service-bottom-hr" /> */}
      <div className="financial-service-radio-mobile-container">
        {hasCheckbox && hasCheckbox}
      </div>
    </div>
  );
};
