import { createContext, useEffect, useState } from "react";
import {
  UseQuery_GetActiveDealers,
  UseQuery_GetAssociatedDealerships,
} from "services";
import { UserRole } from "Enums";
import { boolean } from "yup";

const DealerContext = createContext({
  dealer_code: "",
  updateDealerCode: (newValue: any) => {},
  activeDealers: [],
  associatedDealers: [],
  dealerData: [],
  isLoadingActiveDealers: true,
  isLoadingAssociatedDealers: true,
});

const DealerProvider = ({ children }) => {
  const access_token_local = JSON.parse(localStorage.getItem("settings"));
  const roleName = access_token_local?.role?.name;
  const [fetchActiveDealers, setFetchActiveDealers] = useState(false);
  const [fetchAssociatedDealerships, setFetchAssociatedDealerships] =
    useState(false);
  const [dealerData, setDealerData] = useState([]);
  const user_email = access_token_local?.email;
  const { data: activeDealers, isLoading: isLoadingActiveDealers } =
    UseQuery_GetActiveDealers(fetchActiveDealers);
  const { data: associatedDealers, isLoading: isLoadingAssociatedDealers } =
    UseQuery_GetAssociatedDealerships(fetchAssociatedDealerships, user_email);

  const [dealer_code, setDealerCode] = useState(
    localStorage.getItem("dealer_code")
  );

  const updateDealerCode = (newValue) => {
    localStorage.setItem("dealer_code", newValue.id);
    setDealerCode(newValue.id);
  };

  useEffect(() => {
    if (fetchActiveDealers && activeDealers) {
      setDealerData(activeDealers);
    } else if (fetchAssociatedDealerships && associatedDealers) {
      setDealerData(associatedDealers);
    }
  }, [
    fetchActiveDealers,
    fetchAssociatedDealerships,
    activeDealers,
    associatedDealers,
  ]);

  useEffect(() => {
    if (roleName && Object.values(UserRole).includes(roleName)) {
      setFetchAssociatedDealerships(true);
    } else {
      setFetchActiveDealers(true);
    }
  }, []);

  useEffect(() => {
    if (fetchActiveDealers) {
      const newDealerCode = activeDealers?.[0]?.dealer_code;
      if (
        !localStorage.getItem("dealer_code") ||
        localStorage.getItem("dealer_code") == "undefined"
      ) {
        localStorage.setItem("dealer_code", newDealerCode);
        setDealerCode(newDealerCode);
      } else if (newDealerCode && newDealerCode !== dealer_code) {
        localStorage.setItem("dealer_code", dealer_code);
        setDealerCode(dealer_code);
      }
    } else {
      const newDealerCode = associatedDealers?.[0]?.dealer_code;
      if (
        !localStorage.getItem("dealer_code") ||
        localStorage.getItem("dealer_code") == "undefined"
      ) {
        localStorage.setItem("dealer_code", newDealerCode);
        setDealerCode(newDealerCode);
      } else if (newDealerCode && newDealerCode !== dealer_code) {
        localStorage.setItem("dealer_code", dealer_code);
        setDealerCode(dealer_code);
      }
    }
  }, [
    fetchActiveDealers,
    fetchAssociatedDealerships,
    activeDealers,
    associatedDealers,
    dealerData,
  ]);
  return (
    <DealerContext.Provider
      value={{
        dealer_code,
        updateDealerCode,
        dealerData,
        activeDealers,
        associatedDealers,
        isLoadingActiveDealers,
        isLoadingAssociatedDealers,
      }}
    >
      {children}
    </DealerContext.Provider>
  );
};

export { DealerContext, DealerProvider };
