import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Box, Chip, Select, Typography } from '@ntpkunity/controls'
import { TableList } from '@components'

const VehicleAmountDraft: FC = () => {
  const theme = useTheme()
  return (
    <Box theme={theme} className="vehicle-amount-wrap grey-bg">
      <Chip
        theme={theme}
        label="DRAFT"
        component="div"
        clickable={false}
        variant="filled"
        href=""
      />
      <Typography
        theme={theme}
        component="p"
        variant="caption"
        sx={{ mb: 1 }}
        className="text-overflow"
        children={
          <>
            $
            <Typography
              theme={theme}
              component="span"
              variant="h3"
              sx={{ verticalAlign: 'middle' }}
            >
              2,477.49
            </Typography>{' '}
            /mon
          </>
        }
      />
      <TableList
        withBg
        items={[
          {
            title: 'Type',
            value: 'Finance'
          },
          {
            title: 'Lender',
            value: (
              <Select
                theme={theme}
                disablePortal={false}
                items={[
                  { text: 'Bank of America', value: '1' },
                  { text: 'BMW01', value: '2' },
                  { text: 'VIN Solutions', value: '3' }
                ]}
                value={'1'}
              />
            ),
            hasPrimaryText: true
          },
          {
            title: 'Down Payment',
            value: '$15,718.75'
          },
          {
            title: 'Term',
            value: '24'
          },
          {
            title: 'Rate',
            value: '3%'
          }
        ]}
      />
    </Box>
  )
}

export default VehicleAmountDraft
