import { Button, Input, Radio, Select } from "@src/components/atoms";
import "./financial-information.scss";
import { Datepicker } from "@src/components/atoms/date-picker/date-picker.component";
import { Phone } from "@src/components/atoms/phone-input/phone-input.component";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { financialProfileValidator } from "@src/validators";
import {
  useCustomerUpdate,
  useUpdateCustomerFinancialProfile,
  useGetDisclaimerByName,
} from "@src/hooks";
import { Customer } from "@src/types/customer";
import { updateCustomer, updateDisclaimers } from "@src/context/app-actions";
import {
  calculateDateDifference,
  cleanIncomeInfo,
} from "@src/helpers/appHelper";
import dayjs from "dayjs";
import { AdditionalIncome } from "@src/components/molecules";
import { useState } from "react";
import { cloneDeep } from "lodash";

export const FinancialInformation = ({
  stepThreeDataReceivedEvent,
  stateOptions,
  employmentTypes,
  customer,
  appDispatch,
  appState,
  dealerCode,
}: {
  stepThreeDataReceivedEvent: () => void;
  employmentTypes;
  stateOptions;
  appState;
  customer: Customer;
  appDispatch;
  dealerCode;
}) => {
  const { customer_addresses, reference_id, income_info, financial_profile } =
    customer;
  const { mutate: ChangeDisclaimer } = useGetDisclaimerByName();
  const { mutate: updateCustomerData, isPending: updateCustomerLoader } =
    useCustomerUpdate({
      reference_id: reference_id,
      dealer_code: dealerCode,
    });
  const [showAdditionalIncome, hideAdditionalIncome] = useState<boolean>(
    !!income_info.source_of_other_income && !!income_info.annual_other_income
  );
  const {
    mutate: updateCustomerFinancialProfile,
    isPending: updateCustomerFinancialProfileLoader,
  } = useUpdateCustomerFinancialProfile({
    reference_id: reference_id,
    dealer_code: dealerCode,
  });

  const { control, handleSubmit, watch } = useForm<any>({
    resolver: yupResolver(financialProfileValidator(showAdditionalIncome)),
    defaultValues: {
      ...income_info,
      address: { ...customer_addresses.employment, address_type: "Employment" },
      financial_profile: {
        residence: financial_profile.residence,
        monthly_payment: financial_profile.monthly_payment,
        bankruptcy: financial_profile.bankruptcy,
      },
    },
  });

  const employed = watch("employment_type") === "Employed";

  const onSubmit = (values) => {
    ChangeDisclaimer(
      { disclaimerName: "lender-submission" },
      {
        onSuccess: async (response: any) => {
          if (response[0]) {
            const changesDisclaimer = response[0].description.replace(
              /@dealer/,
              `${appState.dealer?.dealer_name}`
            );
            await appDispatch(
              updateDisclaimers([
                {
                  name: response[0].name,
                  version: response[0].version,
                  description: changesDisclaimer,
                },
              ])
            );
          }
        },
      }
    );
    const { financial_profile, employed_since, ...rest } = values;
    let clonedCustomerData = cloneDeep(customer);
    updateCustomerData(
      {
        ...rest,
        employed_since: employed_since
          ? dayjs(employed_since).format("YYYY-MM-DD")
          : null,
        reference_id: reference_id,
        type: "/employment-info",
        method: !!income_info.employment_type ? "PATCH" : "POST",
      },
      {
        onSuccess: (data) => {
          const {
            address_type,
            created_at,
            created_by,
            updated_at,
            updated_by,
            deleted_at,
            deleted_by,
            entity_type,
            ...rest
          } = data.address;
          clonedCustomerData = {
            ...clonedCustomerData,
            income_info: cleanIncomeInfo(data),
            customer_addresses: { ...customer_addresses, employment: rest },
          };
          appDispatch(updateCustomer(clonedCustomerData));

          stepThreeDataReceivedEvent();
        },
        onError: (error) => {
          console.error("Error fetching customer:", error);
        },
      }
    );
    updateCustomerFinancialProfile(
      {
        ...financial_profile,
        reference_id: reference_id,
        method: !!customer.financial_profile.reference_id ? "PATCH" : "POST",
      },
      {
        onSuccess: ({
          bankruptcy,
          monthly_payment,
          residence,
          reference_id,
        }) => {
          clonedCustomerData = {
            ...clonedCustomerData,
            financial_profile: {
              bankruptcy: bankruptcy,
              monthly_payment: monthly_payment,
              residence: residence,
              reference_id: reference_id,
            },
          };
          appDispatch(updateCustomer(clonedCustomerData));
        },
        onError: (error) => {
          console.error("Error fetching customer:", error);
        },
      }
    );
  };

  return (
    <div className="checkout">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="checkout-form-header checkout-form-header--padding">
          <h3 className="checkout-title text-secondary text--subsection-2">
            03
          </h3>
          <h3 className="checkout-title text--subsection-2">
            Financial Information.
          </h3>
        </div>
        <p className="financial-checkout-subtitle text--body-2">
          Tell us about your current employment situation. This information
          helps us verify your identity and personalize your payment terms.
        </p>
        <div className="financial-occupation-wrapper">
          <div>
            <div className="form-inner-field-container checkout-column-wrapper">
              {employed && (
                <Controller
                  name="occupation"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      id="financial-information--occupation"
                      placeholder="Occupation"
                      label="Occupation"
                      error={error}
                      type="text"
                      {...field}
                    />
                  )}
                />
              )}
              <Controller
                name="employment_type"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    id="employment-type"
                    options={employmentTypes.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    error={error}
                    placeholder="Select"
                    label="Employment type"
                  />
                )}
              />

              {employed && (
                <>
                  <Controller
                    name="annual_employment_income"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Input
                        {...field}
                        type="text"
                        error={error}
                        prefix="$"
                        id="financial-information--annual_income"
                        placeholder="0.00"
                        label="Annual income"
                      />
                    )}
                  />

                  <Controller
                    name="employed_since"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Datepicker
                        id="start_date"
                        maxDate={dayjs(new Date())}
                        placeholder="Choose"
                        label="Employed Since"
                        format="MMM DD, YYYY"
                        error={error}
                        {...field}
                      />
                    )}
                  />

                  <Controller
                    name="employment_duration"
                    control={control}
                    disabled
                    render={({ field: { value, ...rest } }) => (
                      <Input
                        {...rest}
                        value={
                          watch("employed_since")
                            ? calculateDateDifference(watch("employed_since"))
                            : ""
                        }
                        id="financial-information--duration_employment"
                        placeholder="Duration"
                        label="Duration of employment"
                      />
                    )}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <div className="financial-employer-container">
          {employed && (
            <>
              <p className="financial-subtitle text--body-2">
                Please provide your employment information.
              </p>
              <div className="form-inner-field-container checkout-column-wrapper">
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      id="financial-information--employer_name"
                      placeholder="Name"
                      error={error}
                      label="Employer name"
                    />
                  )}
                />

                <Controller
                  name="phone"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Phone
                      {...field}
                      country="us"
                      error={error}
                      label="Employer phone number"
                    />
                  )}
                />
              </div>
            </>
          )}

          {["Employed", "Self Employed"].includes(watch("employment_type")) && (
            <>
              <div className="checkout-column-wrapper checkout-column-wrapper--column">
                <div className="form-inner-field-container">
                  <Controller
                    name="address.address_line_1"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Input
                        {...field}
                        error={error}
                        id="financial-information--address1"
                        placeholder="Street address"
                        label="Address 1"
                      />
                    )}
                  />
                </div>

                <div className="form-inner-field-container">
                  <Controller
                    name="address.address_line_2"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Input
                        {...field}
                        error={error}
                        id="financial-information--address2"
                        placeholder="Apartment, suite, unit, building, floor"
                        label="Address 2 (optional)"
                        value={field.value || ""}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="form-inner-field-container checkout-column-wrapper">
                <Controller
                  name="address.city"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      id="financial-information--city"
                      placeholder="City"
                      label="City"
                      error={error}
                    />
                  )}
                />

                <Controller
                  name="address.state_name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Select
                      id="financial-information--state"
                      placeholder="Select"
                      label="State"
                      options={stateOptions}
                      error={error}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="address.zip_code"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      id="financial-information--zip_code"
                      placeholder="ZIP Code"
                      error={error}
                      label="ZIP Code (optional)"
                    />
                  )}
                />
              </div>
            </>
          )}
          {!showAdditionalIncome && (
            <div className="form-inner-field-container form-inner-additional-icome">
              <div className="form-inner--add-plus-button">
                <Button
                  linkButton
                  onClick={() => hideAdditionalIncome(true)}
                  htmlType="button"
                  withIcon
                  dataIcon="plus"
                  text="Add additional income"
                />
              </div>
            </div>
          )}
        </div>

        <hr className="checkout-hr" />
        {showAdditionalIncome && <AdditionalIncome control={control} />}

        <div className="financial-residence-container">
          <p className="text--body-2 financial-residence-text">
            Please provide your residence status.
          </p>
          <div className="residence-radio-group-wrap">
            <div className="residence-radio-group financial-radio-group">
              <Controller
                name="financial_profile.residence"
                control={control}
                render={({ field: { value, onChange, ...rest } }) => (
                  <>
                    <Radio
                      {...rest}
                      id="radio--mortgage"
                      defaultValue="Mortgage"
                      checked={value === "Mortgage"}
                      onChange={() => onChange("Mortgage")}
                      label="Mortgage"
                    />
                    <Radio
                      {...rest}
                      id="radio--rent"
                      checked={value === "Renting"}
                      onChange={() => onChange("Renting")}
                      defaultValue="Renting"
                      label="Renting"
                    />
                    <Radio
                      {...rest}
                      id="residence-with-relatives"
                      onChange={() => onChange("With Relatives")}
                      defaultValue="With Relatives"
                      checked={value === "With Relatives"}
                      label="With Relatives"
                    />
                    <Radio
                      {...rest}
                      id="radio--own_free_clear"
                      onChange={() => onChange("Personal Ownership")}
                      checked={value === "Personal Ownership"}
                      defaultValue="Own Free & Clear"
                      label="Own Free & Clear"
                    />
                  </>
                )}
              />
            </div>
            {["Mortgage", "Renting"].includes(
              watch("financial_profile.residence")
            ) && (
              <div className="financial-monthly-container">
                <div className="form-inner-field-container checkout-column-wrapper">
                  <Controller
                    name="financial_profile.monthly_payment"
                    control={control}
                    render={({ field }) => (
                      <Input
                        id={"input-wdchixq"}
                        placeholder="0.00"
                        prefix="$"
                        label="Monthly payments"
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="financial-choice-container">
          <p className="text--body-2 financial-choice-text">
            Have you ever applied for bankruptcy?
          </p>
          <div className="choice-radio-group-wrap">
            <div className="choice-radio-group financial-radio-group">
              <Controller
                name="financial_profile.bankruptcy"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <>
                    <Radio
                      {...rest}
                      id="yesCheckbox"
                      checked={value}
                      defaultValue="Yes"
                      label="Yes"
                    />
                    <Radio
                      {...rest}
                      id="noCheckbox"
                      checked={!value}
                      defaultValue="No"
                      label="No"
                    />
                  </>
                )}
              />
            </div>
          </div>
        </div>

        <div className="financial-cta-wrapper">
          <Button
            type="default"
            isLoading={
              updateCustomerFinancialProfileLoader || updateCustomerLoader
            }
            htmlType="submit"
            text="Save & continue"
            fixed
            fullWidth
          />
        </div>
      </form>
    </div>
  );
};
