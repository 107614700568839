import { APP_ROUTES } from "@router/path";
import { SETTINGS } from "@shared/constants";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const RouteToPage = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let token = JSON.parse(localStorage.getItem(SETTINGS))
  let pagePath = params.get("page-path");
  let dealer_code = params.get("dealer_code");
  const navigate = useNavigate();
  useEffect(() => {
    if (token.access_token) {
      if (pagePath === "create") {
        if (dealer_code) {
          navigate(`${APP_ROUTES.CREATE_TEMPLATE_PAGE}?dealer_code=${dealer_code}`);
        } else {
          navigate(APP_ROUTES.CREATE_TEMPLATE_PAGE);
        }
      } else if (pagePath === "") {
        if (dealer_code) {
          navigate(`${APP_ROUTES.TEMPLEX_HOME_PAGE}?dealer_code=${dealer_code}`);
        } else {
          navigate(APP_ROUTES.TEMPLEX_HOME_PAGE);
        }
      }
      else {
        if (dealer_code) {
          navigate(`${pagePath}?dealer_code=${dealer_code}`);
        } else {
          navigate(`/${pagePath}`);
        }
      }
    }
  }, []);
  return <></>;
};
