import "./qr-code.scss";
import QrImage from "../../../shared/assets/img/QR.png";

export const QrCode = () => {
  return (
    <div className="qr-code-container">
      <p className="qr-code-container__header text--body-2">
        Please scan the QR code and click on the link to start.
      </p>
      <div className="qr-code-container__wrapper">
        <div className="label text--disclaimer text-secondary">
          Scan QR code
        </div>
        <img className="qr-code" src={QrImage} alt={"QR code"} />
      </div>
    </div>
  );
};
