import { useTheme } from "@mui/material"
import { ThirdPartyResponseListWrap } from "./third-party-response-list.style"
import { Icon, Box, Typography, Accordion, Label } from "@ntpkunity/controls"
import { Alert } from "@src/components"
import { PdfList } from "@src/components"


export const ThirdPartyResponseList = () => {
    const theme = useTheme();

    const getTimestampWithGap = (monthsGap) => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() + monthsGap);
        return currentDate.toISOString().split("T")[0]; 
      };

    return(
        <>
            <ThirdPartyResponseListWrap theme={theme} className="third-party-response-list-wrap">
                <Accordion
                    theme={theme}
                    items={[
                    {
                        content: (
                        <>
                            <PdfList
                                title={<a href="https://example.com/Experian_Report.pdf" target="_blank" rel="noopener noreferrer">Experian Report.pdf</a>} // Replace with the actual URL of the PDF
                                subTitle={getTimestampWithGap(2)}
                                pdfIcon={false}
                            />
                        </>
                        ),
                        title: <>Experian Report 
                        <Label 
                            theme={theme} 
                            text="Active"
                            textTransform="none" 
                        />
                        </>,
                        key: "experian-report",
                        isExpanded: true,
                        id: "experian-report",
                        ariaControls: "experian-report",
                    },
                    {
                        content: (
                        <>
                            <PdfList
                                title={<a href="https://example.com/TransUnion_Report.pdf" target="_blank" rel="noopener noreferrer">TransUnion Report.pdf</a>} // Replace with the actual URL of the PDF'TransUnion Report.pdf'}
                                subTitle={getTimestampWithGap(3)}
                                pdfIcon={false}
                            />
                        </>
                        ),
                        title: <>TransUnion Report 
                            <Label 
                                theme={theme} 
                                text="Active"
                                textTransform="none" 
                            />
                        </>,
                        key: "transUnion-report",
                        isExpanded: false,
                        id: "transUnion-report",
                        ariaControls: "transUnion-report",
                    },
                    {
                        content: (
                        <>
                            <PdfList
    title={<a href="https://example.com/Equifex_Report.pdf" target="_blank" rel="noopener noreferrer">Equifex Report.pdf</a>}
    subTitle={getTimestampWithGap(4)}
    pdfIcon={false}
/>

                        </>
                        ),
                        title: <>Equifex Report 
                            <Label 
                                theme={theme} 
                                text="Active"
                                state="primary"
                                textTransform="none" 
                            />
                        </>,
                        key: "equifex-report",
                        isExpanded: false,
                        id: "equifex-report",
                        ariaControls: "equifex-report",
                    },
                    ]}
                    shouldCollapse
                />
            </ThirdPartyResponseListWrap>
            
        </>
    )
}