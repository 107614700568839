import { UserPermissions, WorkQueueTabs } from "@helpers/enum";
import { useHasPermissions } from "@ntpkunity/controls-ums";

export const useWorkqueuePermissions = () => {
  const hasTrackProposalPermission = useHasPermissions(
    UserPermissions.BROKER_TRACK_PROPOSALS_WORKQUEUE
  );
  const hasDraftProposalPermission = useHasPermissions(
    UserPermissions.BROKER_DRAFT_PROPOSALS_WORKQUEUE
  );
  const hasQuotationPermission = useHasPermissions(
    UserPermissions.BROKER_QUOTATIONS_WORKQUEUE
  );
  const hasDealerProposalPermission = useHasPermissions(
    UserPermissions.BROKER_DEALER_PROPOSALS_WORKQUEUE
  );

  return {
    [WorkQueueTabs.TRACK_PROPOSALS]: hasTrackProposalPermission,
    [WorkQueueTabs.DEALER_PROPOSALS]: hasDealerProposalPermission,
    [WorkQueueTabs.DRAFT_PROPOSALS]: hasDraftProposalPermission,
    [WorkQueueTabs.QUOTATIONS]: hasQuotationPermission,
  };
};
