import { CompanySearchReqParams, TSortParams } from "@_types";
import { QUERY_KEYS } from "@helpers/query";

export const queryKeyFactory = {
  searchCompanies: (params: CompanySearchReqParams) => {
    return [QUERY_KEYS.SEARCH_COMPANIES, params];
  },
  saveFinanceConfig: () => [QUERY_KEYS.SAVE_FINANCE_CONFIG],
  saveUmsPermissions: () => [QUERY_KEYS.SAVE_UMS_PERMISSIONS],
  getComplianceDocuments: (
    companyId: number,
    customer_type: string,
    financeType: string
  ) => {
    return [
      QUERY_KEYS.GET_COMPLIANCE_DOCUMENTS,
      companyId,
      customer_type,
      financeType,
    ];
  },
  getOrders<SearchParams extends { [key: string]: any }>(
    searchParams: SearchParams,
    sortParams: TSortParams<keyof SearchParams>,
    exportAllData: boolean
  ) {
    return [
      QUERY_KEYS.ORDER_WORKQUEUE,
      searchParams,
      sortParams,
      exportAllData,
    ];
  },
};
