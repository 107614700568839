export const FETCH_APPLICANT_DETAILS_REQUEST = "FETCH_APPLICANT_DETAILS_REQUEST";
export const FETCH_APPLICANT_DETAILS_SUCCESS = "FETCH_APPLICANT_DETAILS_SUCCESS";
export const FETCH_APPLICANT_DETAILS_FAILURE = "FETCH_APPLICANT_DETAILS_FAILURE";
export const OPEN_APPLICANT_DIALOG = "OPEN_APPLICANT_DIALOG";
export const CLOSE_APPLICANT_DIALOG = "CLOSE_APPLICANT_DIALOG";

export const fetchApplicantDetailsRequest = (applicantReferenceNum) => ({
  type: FETCH_APPLICANT_DETAILS_REQUEST,
  payload: applicantReferenceNum,
});

export const fetchApplicantDetailsSuccess = (data) => ({
  type: FETCH_APPLICANT_DETAILS_SUCCESS,
  payload: data,
});

export const fetchApplicantDetailsFailure = (error) => ({
  type: FETCH_APPLICANT_DETAILS_FAILURE,
  payload: error,
});

export const openApplicantDialog = (applicantReferenceNum) => ({
  type: OPEN_APPLICANT_DIALOG,
  payload: applicantReferenceNum,
});

export const closeApplicantDialog = () => ({
  type: CLOSE_APPLICANT_DIALOG,
});