import "./select-offer-completed.scss";
import CardLogo from "../../../../../../shared/assets/img/cardLogo.png";
import { Button, FinancialServiceCardSelect } from "@src/components/atoms";

export const SelectOfferCompleted = ({
  handleClick,
}: {
  handleClick: () => void;
}) => {
  return (
    <div className="offer-completed-container">
      <div className="select-offer-header">
        <div className="checkout-form-header">
          <h3 className="checkout-title text--subsection-2 text-secondary">
            01
          </h3>
          <h3 className="checkout-title text--subsection-2">Finance offer.</h3>
        </div>
        <Button
          htmlType={"button"}
          text="Edit"
          onClick={handleClick}
          size="small"
        />
      </div>
      <div className="offer-completed-content">
        <FinancialServiceCardSelect
          logo={CardLogo}
          title={"BMW Financial Services"}
          price={" $604.01"}
          downCash={"$5,000"}
          apr={"5.9% APR"}
          term={"60 month finance term"}
          className="financial-service-card-completed"
        />
      </div>
    </div>
  );
};
