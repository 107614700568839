import axios, { AxiosError } from "axios";
export const http = (URL, optionalHeaders = {}) => {
  const accessToken = localStorage.getItem("access_token");
  const headers = {
    "Content-type": "application/json",
    "x-api-key": process.env.REACT_APP_API_KEY,
    ...(optionalHeaders || {}),
  };

  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`;
    headers["x-api-key"] = process.env.REACT_APP_API_KEY;
  }

  const axiosInstance = axios.create({
    baseURL: URL,
    withCredentials: false,
    headers: headers,
  });

  const requestInterceptor = async (request) => {
    switch (request.baseURL) {
    }
    return request;
  };
  const errorInRequestInterceptor = async (error) => {};

  axiosInstance.interceptors.request.use(
    requestInterceptor,
    errorInRequestInterceptor
  );

  const responseInterceptor = (response) => {
    return response;
  };
  const errorInResponseInterceptor = (error: AxiosError) => {
    const status = error?.response?.status;
    switch (status) {
      default:
        return Promise.reject(error?.response?.data);
    }
  };

  axiosInstance.interceptors.response.use(
    responseInterceptor,
    errorInResponseInterceptor
  );

  return axiosInstance;
};
