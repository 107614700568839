import { useConfigurationContext } from "@ntpkunity/controls-common";
import { useContext, useMemo } from "react";
import { UMSStoreContext } from "@ntpkunity/controls-ums";
import { useGetPermissions } from "@hooks/queries";
import { UmsConfigurationMap } from "@_types";

export const getPermissionKey = (financeType: string, groupId: number) =>
  `${financeType}-${groupId}`;

export const useUmsPermissionsMap = () => {
  const { data: configurations } = useConfigurationContext();
  const {
    states: { productId: productId },
  } = useContext(UMSStoreContext) as any;
  const { data: umsPermissionsData } = useGetPermissions(productId?.productId);

  return useMemo(() => {
    return Object.keys(configurations).reduce<UmsConfigurationMap>(
      (acc, ft) => {
        const filteredPermissions = umsPermissionsData?.filter((permission) =>
          permission.name.includes(ft?.toLowerCase())
        );
        const permissions = filteredPermissions.reduce<UmsConfigurationMap>(
          (acc, cur) => {
            const permKey = getPermissionKey(ft, cur.group_id);
            acc[permKey] = {
              ...acc[permKey],
              [cur.name]: cur,
            };
            return acc;
          },
          {}
        );
        acc = {
          ...acc,
          ...permissions,
        };
        return acc;
      },
      {}
    );
  }, [umsPermissionsData]);
};

export const useUmsGroups = () => {
  const {
    states: { productId: productId },
  } = useContext(UMSStoreContext) as any;
  const { data: umsPermissionsData } = useGetPermissions(productId?.productId);

  return useMemo(() => {
    const umsGroupsMap = umsPermissionsData?.reduce((acc, cur) => {
      if (cur.group_id in acc) {
        return acc;
      }
      acc = {
        ...acc,
        [cur.group_id]: cur.role_name,
      };
      return acc;
    }, {});

    return Object.keys(umsGroupsMap).map((cur) => ({
      roleName: umsGroupsMap[cur] as string,
      groupId: Number(cur),
    }));
  }, [umsPermissionsData]);
};
