import { useTheme } from '@mui/material'
import { ActionItemsWrap } from './action-items.style'
import { Box, Typography } from '@ntpkunity/controls'
import { ActionItemsProps } from './action-items.props'
export const ActionItems = ({ items }: ActionItemsProps) => {
  const theme = useTheme()

  return (
    <ActionItemsWrap theme={theme} className="action-items-wrap section-h">
      {items.map((item, index) => (
        <Box key={index} theme={theme} mb={1} className="actions-list">
          <Typography theme={theme} variant="body2" component="p" className="action-name">
            {item.title}
          </Typography>
          <Box theme={theme} className="action-number">
            <Typography theme={theme} variant="caption" component="p" className="title-md">
              {item.value}
            </Typography>
          </Box>
        </Box>
      ))}
    </ActionItemsWrap>
  )
}
