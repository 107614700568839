import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { Box, Icon, VerticalTabs, IBoxProps } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'

import ContactDetailsComponent from '@app/customer-details/contact-details'
import EmploymentDetailsComponent from '@app/customer-details/employment-details'
import LicenseDetailsComponent from '@app/customer-details/license-details'
import PersonalFinanceDetailsComponent from '@app/customer-details/personal-finance-details'
import {
  UseQuery_Get_CustomerContact,
  UseQuery_Get_CustomerEmployment,
  UseQuery_Get_Personal_Finance,
  UseQuery_Get_License_Information,
  useGetIntegrationByProviderName
} from '@apis/customer-detail.service'
import { isEmpty } from '@helpers/methods'
import { IntegrationProvider, LenderIntegrationType } from '@helpers/enums'
import { useStoreContext } from '@store/storeContext'
import { mapPayload } from '@helpers/lead'
import { useUpdateLead } from '@hooks/lead-management'

const TabWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  width: '100%',
  display: 'inline-block',
  clear: 'both',

  hr: {
    opacity: 0.4
  },

  '.title-icon': {
    width: '100%',
    display: 'inline-block',
    'span, Icon': {
      float: 'left'
    },
    span: {
      marginRight: 10
    },
    svg: {
      marginTop: 4
    }
  },

  '.custom-checkbox-wrap': {
    display: 'inline-block'
  },
  '.u-tab-btn': {
    textTransform: 'capitalize',
    '&:last-child': {
      '.line': {
        display: 'none'
      }
    }
  }
}))

const CustomerDetails: FC<{
  customerReferenceId?: string
  setTabStates: (Boolean: any) => void
  order?: any
}> = ({ customerReferenceId, setTabStates, order }) => {
  const theme = useTheme()
  const { mutate: updateLead } = useUpdateLead()
  const [route, setRoute] = useState<any>(0)
  const onRouteChange = (newValue: number | string): void => {
    setRoute(newValue)
  }
  const {
    states: { dealerInfo }
  } = useStoreContext()

  const [isContact, setIsContact] = useState(false)
  const [isContactErrors, setIsContactErrros] = useState(false)
  const [isLicenseErrors, setIsLicenseErrors] = useState(false)
  const [isLicense, setIsLicense] = useState(false)
  const [isEmployment, setIsEmployment] = useState(false)
  const [isGoogleEnabled, setIsGoogleEnabled] = useState(false)
  const [isEmploymentErrors, setIsEmploymentErrors] = useState(false)
  const [isPersonalFinance, setPersonalFinance] = useState(false)
  const [isPersonalFinanceErrors, setIsPersonalFinanceErrors] = useState(false)
  const { data: contactData } = UseQuery_Get_CustomerContact(customerReferenceId ?? '')
  const { mutate: getProviderbyName } = useGetIntegrationByProviderName(
    IntegrationProvider.GOOGLE,
    LenderIntegrationType.ADDRESS_VALIDATION
  )
  const { data: employmentInfo } = UseQuery_Get_CustomerEmployment(customerReferenceId ?? '')
  const { data: licenseInfo } = UseQuery_Get_License_Information(customerReferenceId ?? '')
  const { data: personalFinanceData } = UseQuery_Get_Personal_Finance(customerReferenceId ?? '')
  const [isFocus, setIsFocus] = useState(false)
  const [_dealerCode, setDealerCode] = useState<string | undefined>(undefined)

  const tabstates = isContact && isLicense && isEmployment && isPersonalFinance
  setTabStates(tabstates)

  useEffect(() => {
    getProviderbyName(
      {
        provider_name: IntegrationProvider.GOOGLE,
        integration_type: LenderIntegrationType.ADDRESS_VALIDATION
      },
      {
        onSuccess(response: any) {
          setIsGoogleEnabled(response?.is_active)
          setDealerCode(response?.dealer_code)
        }
      }
    )
  }, [isFocus])

  useEffect(() => {
    if (!isEmpty(contactData?.first_name)) {
      setIsContact?.(true)
      setIsContactErrros?.(true)
    }
    if (!isEmpty(employmentInfo)) {
      setIsEmployment?.(true)
      setIsEmploymentErrors?.(true)
    }
    if (!isEmpty(licenseInfo)) {
      setIsLicense?.(true)
      setIsLicenseErrors?.(true)
    }
    if (!isEmpty(personalFinanceData)) {
      setPersonalFinance?.(true)
      setIsPersonalFinanceErrors?.(true)
    }
    const combinedData = {
      ...licenseInfo,
      ...employmentInfo,
      ...contactData
    }
    if (contactData) {
      let lead = mapPayload(dealerInfo?.id, customerReferenceId, 'customer_details', combinedData)
      updateLead(lead)
    }
  }, [contactData, personalFinanceData, licenseInfo, employmentInfo])

  return (
    <TabWrap theme={theme} display={'inline-box'} sx={{ width: '90%', mb: 5, pl: 4, pt: 2 }}>
      <Box theme={theme} sx={{ pl: 4 }}>
        <VerticalTabs
          theme={theme}
          onChange={(val: any) => onRouteChange(val)}
          items={[
            {
              title: (
                <>
                  <Box className="title-with-icon" theme={theme}>
                    <Icon className="icon icon-green" name="GreenCheckIcon" />
                    <span className="icon icon-text">1. </span>
                    <span>Contact Details</span>
                  </Box>
                  <Box theme={theme} className="line" />
                </>
              ),
              content: (
                <>
                  <ContactDetailsComponent
                    customerReferenceId={customerReferenceId}
                    setRoute={setRoute}
                    setIsContactErrros={setIsContactErrros}
                    setIsContact={setIsContact}
                    contactData={contactData}
                    isGoogleEnabled={isGoogleEnabled}
                    setIsFocus={setIsFocus}
                    dealer_code={dealerInfo?.dealer_code ?? ''}
                  />
                </>
              ),
              isCompleted: isContact && isContactErrors,
              isActive: true
            },
            {
              title: (
                <>
                  <Box className="title-with-icon" theme={theme}>
                    <Icon className="icon icon-green" name="GreenCheckIcon" />
                    <span className="icon icon-text">2. </span>
                    <span>License Details</span>
                  </Box>
                  <Box theme={theme} className="line" />
                </>
              ),
              content: (
                <>
                  <LicenseDetailsComponent
                    licenseoData={licenseInfo}
                    setIsLicenseErrors={setIsLicenseErrors}
                    customerReferenceId={customerReferenceId}
                    setRoute={setRoute}
                    setIsLicense={setIsLicense}
                    order={order}
                  />
                </>
              ),
              isCompleted: isLicense && isLicenseErrors,
              isActive: true
            },
            {
              title: (
                <>
                  <Box className="title-with-icon" theme={theme}>
                    <Icon className="icon icon-green" name="GreenCheckIcon" />
                    <span className="icon icon-text">3. </span>
                    <span>Employment Details</span>
                  </Box>
                  <Box theme={theme} className="line" />
                </>
              ),
              content: (
                <>
                  <EmploymentDetailsComponent
                    employInfoData={employmentInfo}
                    customerReferenceId={customerReferenceId}
                    setIsEmploymentErrors={setIsEmploymentErrors}
                    setRoute={setRoute}
                    setIsEmployment={setIsEmployment}
                    isGoogleEnabled={isGoogleEnabled}
                    setIsFocus={setIsFocus}
                    dealer_code={dealerInfo?.dealer_code ?? ''}
                  />
                </>
              ),
              isCompleted: isEmployment && isEmploymentErrors,
              isActive: true
            },
            {
              title: (
                <>
                  <Box className="title-with-icon" theme={theme}>
                    <Icon className="icon icon-green" name="GreenCheckIcon" />
                    <span className="icon icon-text">4. </span>
                    <span>Personal Finance Details</span>
                  </Box>
                  <Box theme={theme} className="line" />
                </>
              ),
              content: (
                <>
                  <PersonalFinanceDetailsComponent
                    // setIsOrderSubmit={setIsOrderSubmit}
                    setIsPersonalFinanceErrors={setIsPersonalFinanceErrors}
                    customerReferenceId={customerReferenceId}
                    personalFinanceData={personalFinanceData}
                    setPersonalFinance={setPersonalFinance}
                    dealer_code={dealerInfo?.dealer_code ?? ''}
                  />
                </>
              ),
              isCompleted: isPersonalFinance && isPersonalFinanceErrors,
              isActive: true
            }
          ]}
          defaultTabIndex={1}
          dynamic={true}
          route={route}
        ></VerticalTabs>
      </Box>
    </TabWrap>
  )
}

export default CustomerDetails
