// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
* {
  box-sizing: border-box;
}
::after,
::before {
  box-sizing: border-box;
}
body {
  margin: 16px !important;
  background-color: #F8F8F9;
  @media (max-width: 959.95px) {
    margin: 8px;
    overflow-x: hidden;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/root-style.css"],"names":[],"mappings":";AACA;EACE,sBAAsB;AACxB;AACA;;EAEE,sBAAsB;AACxB;AACA;EACE,uBAAuB;EACvB,yBAAyB;EACzB;IACE,WAAW;IACX,kBAAkB;EACpB;AACF","sourcesContent":["\n* {\n  box-sizing: border-box;\n}\n::after,\n::before {\n  box-sizing: border-box;\n}\nbody {\n  margin: 16px !important;\n  background-color: #F8F8F9;\n  @media (max-width: 959.95px) {\n    margin: 8px;\n    overflow-x: hidden;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
