import { useTheme } from '@mui/material'
import { ValueBoxWrap } from './value-box.style'
import { ValueBoxProps } from './value-box.props'
import { Box, Typography } from '@ntpkunity/controls'
import clsx from 'clsx'
import RedArrow from '../../../public/assets/icons/down-arrow-red.svg'
import GreenArrow from '../../../public/assets/icons/up-arrow-green.svg'

export const ValueBox = ({
  title,
  value,
  progressText,
  subText,
  success,
  danger,
  noBg
}: ValueBoxProps) => {
  const theme = useTheme()
  return (
    <ValueBoxWrap theme={theme} className={clsx({ 'value-box-wrap': true, 'no-bg': noBg })}>
      {title && (
        <Typography theme={theme} className="text-muted" variant="body1" component={'p'}>
          {title}
        </Typography>
      )}
      {value && (
        <Typography theme={theme} variant="h3" component={'h3'} className="fw-sbold text-h3">
          {value}
        </Typography>
      )}
      {progressText && (
        <Box
          theme={theme}
          className="progress-text"
          display={'flex'}
          alignItems={'center'}
          gap={0.5}
        >
          {success && (
            <span className="icon">
              <img src={GreenArrow} alt="" />
            </span>
          )}
          {danger && (
            <span className="icon">
              <img src={RedArrow} alt="" />
            </span>
          )}
          <Typography
            theme={theme}
            variant="body1"
            component={'p'}
            className={clsx({ 'fw-medium': true, 'text-success': success, 'text-danger': danger })}
          >
            {progressText}
          </Typography>
          {subText && (
            <Typography theme={theme} className="text-muted" variant="body1" component={'p'}>
              {subText}
            </Typography>
          )}
        </Box>
      )}
    </ValueBoxWrap>
  )
}
