import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AddStipWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.add-stip-wrap": {
    border: '1px solid',
    borderStyle: 'dashed',
    borderColor: theme.palette.grey[100],
    borderRadius: 8,
    position: 'relative',
    height: 56,
    overflow: 'hidden',
    transition: 'height 1s ease-out',
    "&.toggled": {
        height: 'auto'
    },
    ".action-area": {
        a: {
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            svg: {
                width: 16,
                height: 16
            }
        }
    }
  },
}));
