import { CashTabContent } from "./cash-tab-content/cash-tab-content";
import { FinanceTabContent } from "./finance-tab-content/finance-tab-content";
import { LeaseTabContent } from "./lease-tab-content/lease-tab-content";
import "./update-payment-terms-drawer-content.scss";
import { Tab } from "@src/components/atoms";

const tabItems = [
  {
    key: "1",
    label: "Lease",
    children: <LeaseTabContent />,
  },
  {
    key: "2",
    label: "Finance",
    children: <FinanceTabContent />,
  },
  {
    key: "3",
    label: "Cash",
    children: <CashTabContent />,
  },
];

export const UpdatePaymentTermsDrawerContent = () => {
  return (
    <>
      <div className="update-payment">
        <div className="update-payment__headline">
          <div className="text--subsection-1">Update your payment terms.</div>
        </div>
        <div className="update-payment__inner">
          <div className="update-payment__tabs">
            <Tab defaultActiveKey="1" items={tabItems} mode="condensed" />
          </div>
        </div>
      </div>
    </>
  );
};
