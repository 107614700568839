import { ReactNode } from "react";
import "../../../styles/components/_checkbox.scss";
import clsx from "clsx";

export const Checkbox = ({
  id,
  name,
  defaultValue,
  htmlFor,
  label,
  checked,
  error,
  indeterminate,
  disabled,
  onChange,
  isCompact,
  ...rest
}: {
  id?: string;
  name?: string;
  defaultValue?: string;
  htmlFor?: string;
  label?: ReactNode | string;
  checked?: boolean;
  error?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  onChange?: (e: boolean) => void;
  isCompact?: boolean;
}) => {
  return (
    <div
      className={clsx({
        "checkbox-wrapper": true,
        "checkbox-error": error,
        "checkbox-minus": indeterminate,
        "checkbox-disabled": disabled,
        "checkbox-compact": isCompact === true,
        "checkbox-without-label": !label,
      })}
    >
      <label className="checkbox-button__label" htmlFor={htmlFor}>
        <input
          className="checkbox-input"
          id={id}
          defaultValue={defaultValue}
          name={name}
          type="checkbox"
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.checked); // Call the passed onChange function
            }
          }}
          checked={checked}
          disabled={disabled}
          {...rest}
        />
        {label && (
          <span
            className={`checkbox-text ${
              isCompact === true ? "text--body-2" : "text--body-1"
            } `}
          >
            {label}
          </span>
        )}
      </label>
    </div>
  );
};
