import React, { FC } from 'react'
import { Link, useTheme } from '@mui/material'
import { Box, Button, Dialog, Icon, Input, Menu, Select, Typography } from '@ntpkunity/controls'
import { BtnAddWrap } from '@components'

export const AddCustomer: FC = () => {
  const theme = useTheme()

  const [openDialog, setOpenDialog] = React.useState(false)
  const handleOpen = () => {
    setOpenDialog(true)
  }
  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <>
      <BtnAddWrap theme={theme} className="btn-add-wrap">
        <Button
          theme={theme}
          fullWidth
          startIcon={<Icon name="AddCircleIcon" />}
          text="Add Customer"
          size="large"
          onClick={handleOpen}
        />
      </BtnAddWrap>
      <Box theme={theme} className="vl-item">
        <Box theme={theme} className="vl-details">
          <Typography
            theme={theme}
            variant="body2"
            component="p"
            className="text-primary text-overflow vl-main-title"
            children={<b>Customer</b>}
          />
          <Typography
            theme={theme}
            variant="body2"
            component="p"
            className="text-overflow"
            children={<b>watson@ymail.com</b>}
          />
          <Typography
            theme={theme}
            variant="caption"
            component="p"
            className=" text-overflow vl-title-ul"
            children={
              <>
                Mobile: <b>512-433-8321</b>, Credit: <b>255-005</b>
              </>
            }
          />
        </Box>
        <Menu
          theme={theme}
          disablePortal
          options={[
            {
              optionText: (
                <>
                  <Icon className="menu-icon" name="EditIcon" /> Edit
                </>
              ),
              optionValue: 'edit'
            },
            {
              optionText: (
                <>
                  <Icon className="menu-icon" name="DeleteIcon" /> Delete
                </>
              ),
              optionValue: 'delete'
            }
          ]}
          render={(cb) => (
            <Button
              theme={theme}
              defaultBtn
              className="vl-menu-btn"
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            />
          )}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
      </Box>
      <Dialog
        variant={undefined}
        disablePortal
        size="md"
        title="Add Customer's Details"
        open={openDialog}
        onCloseDialog={handleClose}
        theme={theme}
        children={
          <>
            <Box theme={theme} mb={2}>
              <Input
                theme={theme}
                fullWidth
                type="email"
                label="Email Address"
                placeholder="Type here.."
              />
              <Select
                theme={theme}
                fullWidth
                disablePortal={false}
                label="Preferred Communication"
                items={[
                  { text: 'Option 1', value: '1', disabled: true },
                  { text: 'Option 2', value: '2' },
                  { text: 'Option 3', value: '3' }
                ]}
              />
              <Input
                theme={theme}
                fullWidth
                type="number"
                label="Mobile Phone Number"
                placeholder="00-000-0000000"
              />
              <Select
                theme={theme}
                fullWidth
                disablePortal={false}
                label="Credit Score"
                items={[
                  { text: 'Option 1', value: '1', disabled: true },
                  { text: 'Option 2', value: '2' },
                  { text: 'Option 3', value: '3' }
                ]}
              />
            </Box>
            <Typography
              theme={theme}
              variant="caption"
              component="p"
              display={'block'}
              className="text-muted"
              children={
                <>
                  By continuing, you agree to the&nbsp;
                  <Link href="javascript:void(0)" className="link hover-underline">
                    terms and conditions
                  </Link>
                  , to the&nbsp;
                  <Link href="javascript:void(0)" className="link hover-underline">
                    TCPA disclosure
                  </Link>
                  &nbsp;and to receive marketing material related to product(s).
                </>
              }
            />
          </>
        }
        customFooter={
          <>
            <Button theme={theme} secondary text="Cancel" />
            <Button theme={theme} primary text="Save Changes" />
          </>
        }
      />
    </>
  )
}
