import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { PageHeader, ConfirmationDialog, AlertBox, CustomDialogWrap } from 'components'
import { Typography, Button, Grid, Box, Icon, Menu, Dialog } from '@ntpkunity/controls'
import {
  Status,
  Reasons,
  DialogMessages,
  CreditApplicationStatus,
  FraudCheckStatuses,
  DEALPROVIDERS
} from '@helpers/enums'
import { APP_ROUTES } from '@helpers/links'
import { useNavigate, useParams } from 'react-router-dom'
import { useUpdateOrderStatus } from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import {
  useConvertApplicationToQuotation,
  useCreditResponse,
  useGetOrderInformation,
  useGetSubmittedOrderInformation,
  useOrderSubmit,
  useAddDisclaimerAgreement
} from '@apis/order-management.service'
import {
  AssetDetails,
  CustomerDetails,
  InsuranceDetails,
  OrderDetails,
  TradeInDetails,
  PaymentBreakdown,
  PickupAppointment,
  Contracts,
  PaymentDetailsNew,
  CreditApplication
} from '@app/order-management/order-summary'
import { useGetDisclaimersDataByName } from '@apis/dealer-configurations.service'
import { useUpdateDMSCUSTOMER } from '@apis/customer-detail.service'
import { ILender, MultiLenderDialog } from 'controls/multi-lender-dialog'
import { useModal } from 'react-modal-hook'
import DisableLoader from '@public/assets/images/loader-disabled.gif'
import { useQueryClient } from 'react-query'
import { OrderStage } from '@app/desking/desking-context-provider'
import {
  useGetCustomerFraudCheck,
  useGetCustomerOrderSubmissions,
  useSendCustomerOrderLink,
  useGetCustomerConsent
} from '@apis/customer-management.service'
import { FRAUD_CHECK_FAILURE_MESSAGE } from '@helpers/constants'
import { useGetVehicleDetails } from '@apis/dealer-addon.service'
import { Vehicle_Status } from '@helpers/enums/vehicle-status.enum'
import { DealerConsent } from 'controls/dealer-consent-dialog'
import SourceResponseDialog from 'controls/source-response/source-response-dialog.component'

const OrderSummary: FC = () => {
  const [isBanner, setIsBanner] = useState(false)
  const [hasError, setError] = useState<boolean>(false)
  const [isDraftOrder, setIsDraftOrder] = useState(false)
  const [lenders, setLenders] = useState<ILender[]>([])
  const [dealerConsent, setDealerConsent] = useState(false)
  const [contractDocument, setContractDocument] = useState<any>()
  const [generalDocument, setGeneralDocument] = useState<any>()
  const [availableState, setAvailableState] = useState<boolean>(false)
  const [selectedLenders, setSelectedLenders] = useState<number[]>([])
  const [submitCustomerPopUp, setSubmitCustomerPopUp] = useState<boolean>(false)
  const [confirmationPopupState, setConfirmationPopupState] = useState<boolean>(false)
  const [disclaimerDescription, setDisclaimerDescription] = useState('')
  const [isSourceResponseDialogOpen, setIsSourceResponseDialogOpen] = useState<boolean>(false)

  const theme = useTheme()
  let navigate = useNavigate()
  const queryClient = useQueryClient()
  const { reference_id } = useParams()
  const { actions, states } = useStoreContext()
  const { mutate: updateDmsData } = useUpdateDMSCUSTOMER()
  const { mutate: sendCustomerOrderLink } = useSendCustomerOrderLink()
  const { mutate: addDisclaimerAgreement } = useAddDisclaimerAgreement()
  const { mutate: getCustomerOrderSubmissions } = useGetCustomerOrderSubmissions()
  const { mutate: getOrderInformation, data: orderDetail } = useGetOrderInformation()
  const { mutate: submitOrderRequest, isLoading: submitOrderLoading } = useOrderSubmit()
  const { mutate: creditResponse, isLoading: creditResponseLoading } = useCreditResponse()
  const { data: disclaimers } = useGetDisclaimersDataByName('credit-application-disclaimer')
  const { mutate: updateOrderStatus, isLoading: updateStatusLoading } = useUpdateOrderStatus()
  const { mutate: getVehicleDetailsOption, isLoading: vehicleDetailsOptionLoading } =
    useGetVehicleDetails()
  const { mutate: getSubmittedOrderInformation, isLoading: getSubmittedOrderInformationLoading } =
    useGetSubmittedOrderInformation()
  const { mutate: getCustomerFraudCheck, data: customerFraudCheck } = useGetCustomerFraudCheck()
  const [customerFraudCheckState, setCustomerFraudCheckState] = useState<any>()
  const [orderinformation, setOrderInformation] = useState<any>(orderDetail)
  const { mutate: updateOrderStage, isLoading: _orderStageLoading } =
    useConvertApplicationToQuotation()

  const { data: checkDisclaimer } = useGetCustomerConsent(
    orderinformation?.customer_info?.id,
    orderinformation?.reference_number
  )

  const disableInsuranceEditing = [
    Status.SchedulePickup,
    Status.Appointment,
    Status.Completed
  ].includes(orderDetail?.status)

  useEffect(() => {
    const data = states?.lenderInfo
    const disclaimerText = disclaimers?.[0]?.description
    if (data && disclaimerText) {
      const address = data?.addresses?.find((address: any) => address?.address_type === 'Mailing')
      setDisclaimerDescription(
        disclaimerText
          .replace(/@lender_name/g, data?.name)
          .replace(
            /@lender_address/g,
            `${address?.address_line_1}, ${address?.city}, ${address?.state_name}, ${address?.zip_code}, ${address?.county}`
          )
          .replace(/@lender_physical_location/g, data?.name)
      )
    }
  }, [disclaimers, states?.lenderInfo])

  useEffect(() => {
    if (reference_id || availableState) {
      getOrderInformationToPopulate(reference_id ?? '')
    }
  }, [reference_id, availableState])

  useEffect(() => {
    if (customerFraudCheck) {
      setCustomerFraudCheckState(customerFraudCheck)
    }
  }, [customerFraudCheck])

  useEffect(() => {
    if (states?.lendersData?.length > 0 && orderinformation?.order_submissions?.length > 0) {
      const currentLenders = structuredClone(states?.lendersData)
      const filteredLenders = currentLenders?.map((item: ILender) => {
        const existingLender = orderinformation?.order_submissions?.find(
          (lender: { lender_id: number }) => lender.lender_id === item.id
        )

        if (existingLender && existingLender?.status !== CreditApplicationStatus.FAILED) {
          item.is_already_selected = true
        }

        return item || {}
      })
      setLenders(filteredLenders)
    } else if (states?.lendersData?.length > 0) {
      const defaultLender = states?.lendersData?.find((lender) => lender.default_lender)
      setSelectedLenders([defaultLender?.id])
      if (orderinformation?.lender_id && orderinformation.lender_id !== defaultLender?.id) {
        setSelectedLenders([
          ...(defaultLender?.id ? [defaultLender.id] : []),
          ...(orderinformation?.lender_id ? [orderinformation.lender_id] : [])
        ])
      }
      setLenders(states?.lendersData)
    }

    if (orderinformation?.status === Status.Reviewed) {
      getCustomerFraudCheck({
        customer_reference_id: orderinformation?.customer_info?.reference_id
      })
    }
  }, [orderinformation?.order_submissions, states?.lendersData, orderinformation?.status])

  const getOrderInformationToPopulate = (order_reference_id: string) => {
    getOrderInformation(
      { reference_number: order_reference_id },
      {
        onSuccess(response: any) {
          setIsDraftOrder(Array.isArray(response))
          const orderResponse = Array.isArray(response) ? response?.[0] : response
          if (
            orderResponse?.status === Status.Submitted ||
            orderResponse?.status === Status.AwaitingMore ||
            orderResponse?.status === Status.FullyReceived ||
            orderResponse?.status === Status.Completed ||
            orderResponse?.status === Status.Conditioned ||
            orderResponse?.status === Status.Cancelled ||
            orderResponse?.status === Status.Declined ||
            orderResponse?.status === Status.Withdrawn ||
            orderResponse?.status === Status.ReSubmitted ||
            orderResponse?.status === Status.Reviewed ||
            orderResponse?.status === Status.InReview ||
            orderResponse?.status === Status.PendingConfirmation ||
            orderResponse?.status === Status.InReservation ||
            orderResponse?.status === Status.Draft ||
            orderResponse?.status === Status.PendingTradeInConfirmation ||
            orderResponse?.status === Status.PendingTradeInInspection
          )
            setOrderInformation(orderResponse)
          if (orderResponse?.documents?.length > 0) {
            const contractDocuments: any = []
            const generalDocuments: any = []
            orderResponse?.documents.forEach((entry: any) => {
              if (entry.type === 'Contract') {
                contractDocuments?.push(entry)
              } else if (entry.type === 'General') {
                generalDocuments?.push(entry)
              }
            })
            setGeneralDocument(generalDocuments)
            setContractDocument(contractDocuments)
          }
          if (orderResponse?.status === Status.Approved) {
            navigate(`/lane/deal-approved/${order_reference_id}`)
          }
        }
      }
    )
  }

  const onDeleteConfirm = (requestPayload: any) => {
    const data = {
      order_id: orderinformation?.reference_number,
      reqBody: {
        status: Status.Cancelled,
        user_id: 0,
        reason:
          orderinformation?.status === Status.PendingConfirmation
            ? requestPayload?.reason
              ? requestPayload?.reason
              : Reasons.ASSET_NOT_AVAILABLE
            : requestPayload?.reason
            ? requestPayload?.reason
            : Reasons.CANCELED_BY_DEALER
      }
    }
    updateOrderStatus(data, {
      onSuccess() {
        actions.setToast({
          toastMessage: 'Deal Cancelled',
          toastState: true
        })
        navigate(APP_ROUTES?.WORKQUEUE)
      },
      onError(error: any) {
        actions.setToast({
          toastMessage: error.error.toString(),
          toastState: true,
          variant: 'error'
        })
      }
    })
  }
  const getOrderStatus = () => {
    switch (orderinformation?.status) {
      case Status.PendingTradeInConfirmation:
      case Status.PendingTradeInInspection:
      case Status.PendingConfirmation:
      case Status.AwaitingMore:
      case Status.FullyReceived:
        return orderinformation?.status || ''
      case Status.Reviewed:
        return orderinformation?.customer_info?.miscellaneous_info?.fraud_check_status ===
          FraudCheckStatuses.ACCEPT
          ? `Deal ${orderinformation?.status} Successfully`
          : FRAUD_CHECK_FAILURE_MESSAGE
      default:
        return orderinformation?.status ? `Deal ${orderinformation?.status}` : ''
    }
  }

  const updateStage = () => {
    const data = {
      order_id: orderinformation?.reference_number,
      reqBody: {
        status: Status.Draft,
        user_id: 0
      }
    }
    updateOrderStatus(data)
    updateOrderStage({
      identifier: orderinformation?.identifier,
      reference_number: orderinformation?.reference_number
    })
    queryClient.setQueryData(['Order', orderinformation?.reference_number], {
      ...orderinformation,
      order_stage: OrderStage.QUOTATION
    })
    navigate(
      `/lane/desking?reference_id=${orderinformation?.reference_number}&customer_reference_id=${orderinformation?.customer_info?.reference_id}`
    )
  }

  const handleCreditApplication = () => {
    if (states?.dealDesk?.recalculation) {
      setIsBanner(true)
      actions.setRecalculation(false)
    } else {
      setIsBanner(false)
      handleShowSelectLenderPopup()
    }
  }

  const checkVehicleStatus = () => {
    getVehicleDetailsOption(
      { vin: orderinformation?.Vehicle_details?.vin },
      {
        onSuccess(response: any) {
          if (response?.vehicle_status === Vehicle_Status.Not_Available) {
            const payload = {
              order_id: orderinformation?.reference_number,
              reqBody: {
                status: Status.Cancelled,
                user_id: 0
              }
            }
            updateOrderStatus(payload)
            navigate(APP_ROUTES?.WORKQUEUE)
            actions.setToast({
              toastMessage: 'This vehicle is no longer available in inventory.',
              toastState: true,
              variant: 'error'
            })
          }
        },
        onError(error: any) {
          const errorDetails = JSON.parse(JSON.stringify(error))
          const vehicleNotFound = errorDetails?.error?.message?.includes('404')
          if (vehicleNotFound) {
            const payload = {
              order_id: orderinformation?.reference_number,
              reqBody: {
                status: Status.Cancelled,
                user_id: 0
              }
            }
            updateOrderStatus(payload)
            navigate(APP_ROUTES?.WORKQUEUE)
            actions.setToast({
              toastMessage: 'This vehicle is no longer available in inventory.',
              toastState: true,
              variant: 'error'
            })
          }
        }
      }
    )
  }
  const handleDealerConsent = () => {
    closeSlModal()
    const CreditApplicationData = checkDisclaimer?.filter(
      (item: any) => item?.disclaimer_name === 'credit-application-disclaimer'
    )
    if (CreditApplicationData?.length > 0) {
      return handleAvailableClick()
    }
    setDealerConsent(true)
  }

  const addDisclaimerData = () => {
    addDisclaimerAgreement({
      order_reference_id: orderinformation?.reference_number,
      customer_reference_id: orderinformation?.customer_info?.reference_id,
      disclaimer_name: 'credit-application-disclaimer',
      disclaimer_version: '1.0'
    })
    setDealerConsent(false)
  }
  const submitOrder = () => {
    const odometerData = checkDisclaimer?.filter((item: any) => item.disclaimer_name === 'odometer')
    if (
      orderinformation?.order_asset.mileage != null &&
      orderinformation?.order_asset.engine_cylinders &&
      orderinformation?.order_asset.transmission_type &&
      odometerData
    ) {
      const payload = {
        order_id: orderinformation?.reference_number,
        reqBody: {
          status: 'Submitted',
          user_id: 0
        }
      }
      updateOrderStatus(payload, {
        onSuccess(response: any) {
          submitCreditApprover(response.status)
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      })
    } else {
      actions.setToast({
        toastMessage: 'Mandatory information is missing on Odometer popup.',
        toastState: true,
        variant: 'error'
      })
      setError(true)
      closeSlModal()
    }
  }
  const handleAvailableClick = () => {
    const odometerData = checkDisclaimer?.filter((item: any) => item.disclaimer_name === 'odometer')
    setDealerConsent(false)
    if (
      orderinformation?.order_asset.mileage != null &&
      orderinformation?.order_asset.engine_cylinders &&
      orderinformation?.order_asset.transmission_type &&
      odometerData
    ) {
      checkDisclaimer ? '' : addDisclaimerData()
      checkVehicleStatus()
      if (orderinformation?.status === Status.FullyReceived) {
        return submitCreditApprover(orderinformation?.status)
      } else if (orderinformation?.status === Status.PendingConfirmation) {
        return submitOrderResquest()
      } else {
        setDealerConsent(false)
        return submitOrder()
      }
    } else {
      actions.setToast({
        toastMessage: 'Mandatory information is missing on Odometer popup.',
        toastState: true,
        variant: 'error'
      })
      setError(true)
    }
  }

  const submitOrderResquest = () => {
    const odometerData = checkDisclaimer?.filter((item: any) => item.disclaimer_name === 'odometer')
    if (
      orderinformation?.order_asset.mileage != null &&
      orderinformation?.order_asset.engine_cylinders &&
      orderinformation?.order_asset.transmission_type &&
      odometerData
    ) {
      submitOrderRequest(
        {
          referenceNumber: orderinformation?.reference_number,
          lender_ids: selectedLenders
        },
        {
          async onSuccess(_response: any) {
            actions.setToast({
              toastMessage: 'Deal Submitted for Credit Response',
              toastState: true
            })
            closeSlModal()
            setAvailableState(true)
          },
          onError(error: any) {
            actions.setToast({
              toastMessage: error.error.toString(),
              toastState: true,
              variant: 'error'
            })
          }
        }
      )
    } else {
      actions.setToast({
        toastMessage: 'Mandatory information is missing on Odometer popup.',
        toastState: true,
        variant: 'error'
      })
      setError(true)
      closeSlModal()
    }
  }

  const submitCreditApprover = (status: string) => {
    const bmwDealLenders =
      states.lendersData
        ?.filter(
          (lender) =>
            selectedLenders?.includes(lender.id) &&
            lender.credit_decisioning_provider === DEALPROVIDERS.BMWDeals
        )
        ?.map((lender) => lender.id) ?? []

    if (bmwDealLenders?.length > 0 && orderDetail?.customer_info.reference_id) {
      getCustomerOrderSubmissions(
        {
          customer_reference_id: orderDetail.customer_info.reference_id,
          submission_status: Status.Submitted,
          lender_ids: bmwDealLenders
        },
        {
          onSuccess: (response) => {
            if (response?.record_exists) {
              actions.setToast({
                toastMessage:
                  'There is already a credit application in progress, please wait until we receive decision of previous credit application of this customer',
                toastState: true
              })
              return
            } else {
              getSubmittedOrderInformation(
                { reference_number: orderinformation?.reference_number, order_status: status },
                {
                  onSuccess(response: any) {
                    const updatedResponse = { ...response, lender_ids: selectedLenders }
                    creditResponse(updatedResponse, {
                      async onSuccess() {
                        actions.setToast({
                          toastMessage: 'Deal Submitted for Credit Response',
                          toastState: true
                        })

                        setSelectedLenders([])
                        closeSlModal()

                        if (orderinformation?.status === Status.FullyReceived) {
                          getOrderInformationToPopulate(orderinformation?.reference_number)
                        } else {
                          setAvailableState(true)
                        }
                      },
                      onError(error: any) {
                        actions.setToast({
                          toastMessage: error.error.toString(),
                          toastState: true,
                          variant: 'error'
                        })
                      }
                    })
                  }
                }
              )
            }
          }
        }
      )
    } else {
      getSubmittedOrderInformation(
        { reference_number: orderinformation?.reference_number, order_status: status },
        {
          onSuccess(response: any) {
            const updatedResponse = { ...response, lender_ids: selectedLenders }
            creditResponse(updatedResponse, {
              async onSuccess() {
                actions.setToast({
                  toastMessage: 'Deal Submitted for Credit Response',
                  toastState: true
                })
                setDealerConsent(false)
                setSelectedLenders([])
                closeSlModal()

                if (orderinformation?.status === Status.FullyReceived) {
                  getOrderInformationToPopulate(orderinformation?.reference_number)
                } else {
                  setAvailableState(true)
                }
              },
              onError(error: any) {
                actions.setToast({
                  toastMessage: error.error.toString(),
                  toastState: true,
                  variant: 'error'
                })
              }
            })
          }
        }
      )
    }
  }
  const updateData = () => {
    const data = {
      dealer_code: states?.dealerInfo.dealer_code,
      reference_id: orderinformation?.customer_info?.reference_id
    }
    updateDmsData(data)
  }
  const handleShowSelectLenderPopup = () => {
    if (lenders?.length > 1) {
      openSlModal()
    }
    if (lenders?.length == 1) {
      setDealerConsent(true)
    }
    // if (lenders?.length > 1) openSlModal()
    // else submitOrder()
  }

  const onSendOrderLinkToCustomer = () => {
    sendCustomerOrderLink({
      reference_number: orderinformation?.reference_number
    })
  }

  const [openSlModal, closeSlModal] = useModal(
    () => (
      <CustomDialogWrap theme={theme} className="custom-dialog-wrap footer-separator">
        <Dialog
          disablePortal
          theme={theme}
          variant={undefined}
          open
          size="xl"
          title="Select Lenders"
          onCloseDialog={closeSlModal}
          customFooter={
            <>
              <Button
                theme={theme}
                primary
                text="Submit Application"
                onClick={handleDealerConsent}
                disabled={
                  vehicleDetailsOptionLoading ||
                  creditResponseLoading ||
                  submitOrderLoading ||
                  getSubmittedOrderInformationLoading ||
                  selectedLenders?.length < 1
                }
                startIcon={
                  (vehicleDetailsOptionLoading ||
                    creditResponseLoading ||
                    getSubmittedOrderInformationLoading ||
                    submitOrderLoading) && <img src={DisableLoader} alt="Loader" />
                }
              />
            </>
          }
        >
          <MultiLenderDialog
            selectedLenders={selectedLenders}
            orderDetail={orderinformation}
            setSelectedLenders={setSelectedLenders}
            lenders={lenders}
          />
        </Dialog>
      </CustomDialogWrap>
    ),
    [
      orderinformation,
      selectedLenders,
      lenders,
      creditResponseLoading,
      submitOrderLoading,
      getSubmittedOrderInformationLoading,
      vehicleDetailsOptionLoading
    ]
  )

  const shouldShowViewReport =
    customerFraudCheckState &&
    typeof customerFraudCheckState === 'object' &&
    Object.keys(customerFraudCheckState)?.length > 0

  return (
    <>
      {dealerConsent ? (
        <>
          <DealerConsent
            selectedLenders={selectedLenders}
            dialogOpen={dealerConsent}
            onConfirm={handleAvailableClick}
            setDialogOpen={setDealerConsent}
            dealerConsentDetail={disclaimerDescription}
            openSlModal={openSlModal}
          />
        </>
      ) : (
        ''
      )}
      <LayoutWithSideNav theme={theme}>
        <Box theme={theme} sx={{ mb: 2.5 }}>
          <PageHeader
            className="main-page-header border-bottom"
            theme={theme}
            container
            item
            spacing={2}
          >
            <Grid theme={theme} container spacing={2}>
              <Grid theme={theme} item lg={7} md={6} sm={12} xs={12}>
                <Typography theme={theme} variant="h2" component="h2">
                  {getOrderStatus()}
                </Typography>
                <Typography theme={theme} variant="body2" component="p">
                  {(() => {
                    switch (orderinformation?.status) {
                      case Status.Submitted:
                        return (
                          <>
                            This should not take too long. We will notify you once the credit
                            application has been reviewed.
                          </>
                        )
                      case Status.Completed:
                        return (
                          <>This deal has been completed. You can review the deal details below.</>
                        )
                      case Status.Conditioned:
                      case Status.AwaitingMore:
                      case Status.FullyReceived:
                        return (
                          <>
                            Please see the information below regarding the status of your credit
                            application review.
                          </>
                        )
                      case Status.Approved:
                        return (
                          <>
                            The credit application has been approved. Once you receive the payment,
                            please update the payment status below to proceed.
                          </>
                        )
                      case Status.Cancelled:
                        return (
                          <>
                            Sorry, the deal has been cancelled and moved to your cancelled deals
                            queue.
                          </>
                        )
                      case Status.Declined:
                        return (
                          <>
                            Sorry, the deal has been declined and moved to your declined deals
                            queue.
                          </>
                        )
                      case Status.Withdrawn:
                        return (
                          <>
                            Sorry, the deal has been withdrawn by the customer and moved to your
                            withdrawn deals queue.
                          </>
                        )
                      case Status.ReSubmitted:
                        return (
                          <>
                            This should not take too long. We will notify you once the credit
                            application has been reviewed.
                          </>
                        )
                      case Status.Reviewed:
                        return (
                          <>
                            {orderinformation?.customer_info?.miscellaneous_info
                              ?.fraud_check_status === FraudCheckStatuses.ACCEPT
                              ? `The fraud check for this application is approved. Please proceed with this
                            deal and submit the credit application.`
                              : `You may proceed with submitting the deal if you choose. Additionally,
                            you have the option to review the details of the fraud check.`}
                          </>
                        )
                      case Status.InReview:
                        return (
                          <>
                            This should not take too long. We will notify you once the credit
                            application has been reviewed.
                          </>
                        )
                      case Status.Draft:
                        return (
                          <>Your deal has been saved. You can view the saved deal details below.</>
                        )
                      case Status.PendingConfirmation:
                        return (
                          <>
                            Customer has submitted the deal. Finalize this deal by confirming
                            availability
                          </>
                        )
                      case Status.PendingTradeInInspection:
                        return <>Trade-in asset inspection is pending.</>
                      case Status.InReservation:
                        return (
                          <>
                            Customer has reserved the asset. Finalize this deal by confirming
                            availability.
                          </>
                        )
                      default:
                        return (
                          <>
                            This should not take too long. We will notify you once the credit
                            application has been reviewed.
                          </>
                        )
                    }
                  })()}
                </Typography>
              </Grid>
              <Grid theme={theme} item lg={5} md={6} sm={12} xs={12} textAlign="right">
                <Box
                  className="action-area"
                  flexShrink={0}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'right'}
                  theme={theme}
                >
                  {orderinformation &&
                  (orderinformation?.status == Status.Reviewed ||
                    (orderinformation?.status == Status.Draft &&
                      orderinformation?.order_stage === OrderStage.APPLICATION)) ? (
                    <>
                      {
                        <Menu
                          theme={theme}
                          options={[
                            {
                              optionText: isBanner ? 'Edit deal' : 'Cancel Deal',
                              optionValue: isBanner ? 'Edit' : 'Cancel'
                            },
                            {
                              optionText: 'Send To Customer',
                              optionValue: 'SendToCustomer'
                            },
                            ...(shouldShowViewReport
                              ? [
                                  {
                                    optionText: 'View KYC',
                                    optionValue: 'ViewReport'
                                  }
                                ]
                              : [])
                          ]}
                          handleOptionClick={(_event, _key, value) => {
                            switch (value) {
                              case 'Edit':
                                updateStage()
                                break
                              case 'SendToCustomer':
                                onSendOrderLinkToCustomer()
                                break
                              case 'Cancel':
                                setConfirmationPopupState(true)
                                break
                              case 'ViewReport':
                                setIsSourceResponseDialogOpen(true)
                                break
                            }
                          }}
                          render={(onMenuSelection) => (
                            <Button
                              theme={theme}
                              secondary
                              iconText={<Icon name="MoreIcon" />}
                              onClick={onMenuSelection}
                            ></Button>
                          )}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                          }}
                        />
                      }

                      <Button
                        theme={theme}
                        secondary
                        disabled={
                          creditResponseLoading ||
                          getSubmittedOrderInformationLoading ||
                          updateStatusLoading
                        }
                        startIcon={
                          (creditResponseLoading ||
                            getSubmittedOrderInformationLoading ||
                            updateStatusLoading) && <img src={DisableLoader} alt="Loader" />
                        }
                        text="Submit Application"
                        onClick={handleCreditApplication}
                      />
                    </>
                  ) : orderinformation &&
                    orderinformation?.status != Status.Completed &&
                    orderinformation?.status != Status.PendingConfirmation &&
                    orderinformation?.status != Status.Cancelled &&
                    orderinformation?.status != Status.Declined &&
                    orderinformation?.status != Status.Withdrawn ? (
                    <Button
                      theme={theme}
                      secondary
                      id="CancelOrder"
                      text={'Cancel Deal'}
                      onClick={(_e) => {
                        setConfirmationPopupState(true)
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {orderinformation && orderinformation?.status == Status.PendingConfirmation && (
                    <Button
                      theme={theme}
                      secondary
                      id="EditOrder"
                      text={'Edit Deal'}
                      onClick={(_e) => {
                        navigate(
                          `/lane/desking?reference_id=${orderinformation.reference_number}&customer_reference_id=${orderinformation?.customer_info?.reference_id}`
                        )
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </PageHeader>
        </Box>
        <AssetDetails
          isDraftOrder={isDraftOrder}
          hasError={hasError}
          setError={setError}
          order_info={orderinformation}
          setConfirmationPopupState={setConfirmationPopupState}
          setOptionObject={setOrderInformation}
          openSlModal={openSlModal}
          lenders={lenders}
          setSelectedLenders={setSelectedLenders}
        />
        {isBanner && (
          <AlertBox className="dark buttons">
            <span>
              There has been some financial updates in your deal. Please review the financials below
              before you proceed with the deal submission.
            </span>
          </AlertBox>
        )}
        <Grid theme={theme} className="container" container spacing={2}>
          <Grid theme={theme} item xl={8} lg={8} md={8} sm={12} xs={12}>
            {(orderDetail?.status == Status.Appointment ||
              orderDetail?.status == Status.Completed) &&
            orderDetail?.appointment_details ? (
              <PickupAppointment order_info={orderDetail}></PickupAppointment>
            ) : (
              ''
            )}

            {orderDetail?.documents && orderDetail?.documents.length > 0 ? (
              <>
                <Contracts
                  orderStatus={orderDetail?.status}
                  dealerId={orderDetail?.dealer_id}
                  referenceNumber={orderDetail?.reference_number}
                  referenceId={orderDetail?.customer_info.reference_id}
                  generalDocument={generalDocument}
                  contractDocument={contractDocument}
                />
              </>
            ) : (
              ''
            )}

            {orderDetail?.status == Status.SchedulePickup ||
            orderDetail?.status == Status.Appointment ||
            orderDetail?.status == Status.Completed ? (
              <PaymentDetailsNew order_detail={orderDetail} viewMode={true} />
            ) : (
              ''
            )}

            {[
              Status.Submitted,
              Status.AwaitingMore,
              Status.FullyReceived,
              Status.Conditioned
            ]?.includes(orderDetail?.status) &&
              orderinformation?.order_submissions?.length > 0 && (
                <CreditApplication
                  order_detail={orderinformation}
                  openSlModal={openSlModal}
                  lenders={lenders}
                  setSelectedLenders={setSelectedLenders}
                />
              )}

            <CustomerDetails customer_info={orderinformation?.customer_info} />

            <OrderDetails isDraftOrder={isDraftOrder} order_detail={orderinformation} />

            {orderinformation?.Insurance_Inforation ? (
              <InsuranceDetails
                insurance={orderinformation?.Insurance_Inforation}
                referenceId={orderDetail?.customer_info.reference_id}
                isInsuranceEditingDisabled={disableInsuranceEditing}
              />
            ) : (
              ''
            )}

            {orderinformation?.order_tradein ? (
              <TradeInDetails orderDetail={orderinformation} setOrderObject={setOrderInformation} />
            ) : (
              ''
            )}
          </Grid>
          {!isDraftOrder && <PaymentBreakdown order_info={orderinformation} />}
        </Grid>
        {confirmationPopupState && (
          <ConfirmationDialog
            openPopUp={confirmationPopupState}
            onConfirm={onDeleteConfirm}
            setPopUpState={setConfirmationPopupState}
            confirmationTitle={
              orderinformation?.status == Status.PendingConfirmation ||
              orderinformation?.status == Status.InReservation
                ? DialogMessages.assetUnavailableTitle
                : DialogMessages.confirmationTitle
            }
            confirmationText={
              orderinformation?.status == Status.PendingConfirmation ||
              orderinformation?.status == Status.InReservation
                ? DialogMessages.assetUnavailableConfirmationText
                : DialogMessages.confirmationText
            }
            primaryBtnText={
              orderinformation?.status == Status.PendingConfirmation ||
              orderinformation?.status == Status.InReservation
                ? DialogMessages.btnText
                : DialogMessages.cancelBtnText
            }
            isButtonTypeDanger
            icon={<Icon className="alert-icon" name="ErrorTriangle" />}
            onCancel={function (_action: any): void {
              setConfirmationPopupState(false)
            }}
            optionaldata={true}
            hideCancelButton={false}
          />
        )}
        {submitCustomerPopUp && (
          <ConfirmationDialog
            openPopUp={submitCustomerPopUp}
            onConfirm={function (_action: any): void {
              submitOrder()
              updateData()
            }}
            setPopUpState={setSubmitCustomerPopUp}
            confirmationTitle={DialogMessages.updatedDMSDATA}
            confirmationText={DialogMessages.updatedDMSDATATEXT}
            primaryBtnText="Yes"
            secondaryBtnText="No"
            icon={<Icon name="CircleTickIcon" />}
            onCancel={function (_action: any): void {
              setSubmitCustomerPopUp(false)
              submitOrder()
            }}
            hideCancelButton={false}
          />
        )}
        {isSourceResponseDialogOpen && (
          <SourceResponseDialog
            openPopup={isSourceResponseDialogOpen}
            setPopUpState={setIsSourceResponseDialogOpen}
            response={customerFraudCheckState}
          />
        )}
      </LayoutWithSideNav>
    </>
  )
}

export default OrderSummary
