import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material";
import { Box, Button, PersistentDrawer } from "@ntpkunity/controls";
import { DataTableWrap } from "@sharedComponents";
import DealerAppointmentTable from "./dealer-appointment-table";
import DealerAppointmentDrawer from "./dealer-appointment-drawer";

import { IDealerAppointment } from "Interface";
import { ActionOptions, QueryKeys } from "Enums";
import { useForm } from "react-hook-form";
import { DealerContext } from "context";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import {
  useUpdateDealerAppointment,
  useMutation_AddDealerAppointment,
  useQuery_GetAllConfiguredAppointments,
  useQuery_GetAllConfiguredTimeSlotIntervals,
} from "services/dealer-appointment.service";
import { useQueryClient } from "react-query";

const DealerAppointment: FC<{
  onPopupStateChange: (popUpState: any) => unknown;
}> = ({ onPopupStateChange }) => {
  const theme = useTheme();
  const { mutate: addDealerAppointment } = useMutation_AddDealerAppointment();
  const { mutate: updateDealerAppointment } = useUpdateDealerAppointment();
  const { actions } = useStoreContext();

  const { dealer_code } = useContext(DealerContext);
  const [popUpState, setPopUpState] = useState(false);
  const [enabledPin, setEnabledPin] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [state, dispatch] = useSetupsStore();

  onPopupStateChange(popUpState);

  const [actionTitle, setActionTitle] = useState<string>(
    "Add New Appointment Type"
  );

  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [actionData, setActionData] = useState<IDealerAppointment>();
  const queryClient = useQueryClient();
  useQuery_GetAllConfiguredAppointments();
  useQuery_GetAllConfiguredTimeSlotIntervals();
  const configured_appointments: Array<any> = queryClient.getQueryData(
    QueryKeys.GET_ALL_CONFIGURED_APPOINTMENTS
  );
  const handleSelection = (_event: any, key: any, value: any) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Appointment Type");
        setActionData(value);
        setPopUpState(true);
        setIsEditMode(false);
        dispatch(setOpenDrawer(true));
        form.reset({} as IDealerAppointment);
      default:
        return;
    }
  };

  const defaultValues: IDealerAppointment = useMemo(() => {
    const updatedFormDefaultValues = actionData;
    setActionData(updatedFormDefaultValues);
    return actionData;
  }, [actionData]) as unknown as IDealerAppointment;

  useEffect(() => {
    if (actionType == ActionOptions.EDIT) {
      form.reset({ ...defaultValues });
      setIsEditMode(true);
    } else {
      form.reset();
    }
  }, [defaultValues, popUpState]);

  const form = useForm<IDealerAppointment>();
  const { setError } = form;

  useEffect(() => {
    if (popUpState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [popUpState]);
  const handleError = (error) => {
    if (
      error?.error?.response?.data?.detail ===
        "A Dealer Appointment already exists for this appointment type" ||
      error?.response?.data?.detail ===
        "A Dealer Appointment already exists for this appointment type"
    ) {
      setError("appointment_id", {
        message: "Appointment Type already exists",
      });
      return;
    } else {
      onClose();
      actions.setToast({
        toastMessage: error?.response?.data?.detail,
        toastState: true,
        variant: "error",
      });
    }
  };

  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };
  const handleFormSubmit = () => {
    form.handleSubmit(onSubmit)();
  };
  const onSubmit = (formValues: any): void => {
    saveFormData(formValues);
  };
  const saveFormData = (data) => {
    if (actionType === ActionOptions.ADD) {
      (data.dealer_code = dealer_code),
        addDealerAppointment(
          { ...data },
          {
            onSuccess: (response) => {
              form.reset();
              onClose();
            },
            onError: (error) => {
              setPopUpState(true);
              handleError(error);
            },
          }
        );
    }
    if (actionType === ActionOptions.EDIT) {
      if (
        configured_appointments?.find((item) => item.id === data.appointment_id)
          ?.appointment_type !== "Pickup"
      ) {
        (data.cushion_time = ""), (data.in_transit_cushion_days = "");
      }
      updateDealerAppointment(
        { ...data },
        {
          onSuccess: (response) => {
            form.reset();
            onClose();
          },
          onError: (error) => {
            handleError(error);
          },
        }
      );
    }
  };

  return (
    <>
      <DataTableWrap theme={theme} className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <DealerAppointmentTable
          setPopUpState={setPopUpState}
          setActionData={setActionData}
          setActionType={setActionType}
          setActionTitle={setActionTitle}
          actionData={actionData}
          dealer_code={dealer_code}
        />
        <Button
          id="addNewDealerAppointment"
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Appointment Type"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
      </DataTableWrap>
      {popUpState && (
        <PersistentDrawer
          title={actionTitle}
          openPopUp={popUpState}
          setPopUpState={setPopUpState}
          enabledPin={true}
          theme={theme}
          children={
            <>
              <form onSubmit={(e) => e.preventDefault()}>
                <DealerAppointmentDrawer
                  popUpState={popUpState}
                  setPopUpState={setPopUpState}
                  title={actionTitle}
                  actionType={actionType}
                  form={form}
                  enabledPin={enabledPin}
                  dealer_code={dealer_code}
                  setIsEditMode={setIsEditMode}
                  isEditMode={isEditMode}
                  setError={setError}
                />
              </form>
            </>
          }
          customFooter={
            <Button
              theme={theme}
              primary
              type="submit"
              text="Save Appointment Type"
              fullWidth
              onClick={handleFormSubmit}
            />
          }
        />
      )}
    </>
  );
};

export default DealerAppointment;
