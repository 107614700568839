import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { Box, Button } from "@ntpkunity/controls";
import { DataTableWrap } from "@sharedComponents";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import TableList from "./TableList/TableList";
import AddNew from "./AddNew";
import {
  useAddLenderSelectionRule,
  useDeleteLenderSelectionRule,
  useEditLenderSelectionRule,
} from "../../hooks/lenderSelectionRules";
import { ILenderSelectionRule } from "../../Interface/LenderSelectionRule/ILenderSelectionRules";
import { useQueryClient } from "react-query";
import { QueryKeys } from "../../Enums/UseQueryKeys";
import { useGetAllLenderSelectioRules } from "../../hooks/lenderSelectionRules";
import {
  UseQuery_GetActiveDealers,
  UseQuery_GetAllAssetCategory,
  UseQuery_GetAllAssetType,
  UseQuery_GetAllCustomerType,
  UseQuery_getAllFinancedAmount,
  UseQuery_GetAllFinanceType,
  UseQuery_getAllLender,
  UseQuery_GetAllProductType,
} from "../../services";
import { SearchParams } from "../../Types/lenderSelectionRules";

const LenderSelectionRules: FC = () => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const [state, dispatch] = useSetupsStore();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [key, setKey] = useState(0);
  const [openTranslation, setOpenTranslation] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [queryParams, setQueryParams] = useState<SearchParams>({
    page_number: pageNumber,
    page_size: pageSize,
  });
  const { data: lenderSelectionRules } =
    useGetAllLenderSelectioRules(queryParams);
  const { mutate: addLenderSelectionRule, isLoading: addCallInProgress } =
    useAddLenderSelectionRule();
  const { mutate: editLenderSelectionRule, isLoading: updateCallInProgresss } =
    useEditLenderSelectionRule();
  const { mutate: deleteLenderSelectionRule } = useDeleteLenderSelectionRule();
  const { isFetched: financeTypesFetched } = UseQuery_GetAllFinanceType();
  const { isFetched: productTypesFetched } = UseQuery_GetAllProductType();
  const { isFetched: financeAmountRangesFetched } =
    UseQuery_getAllFinancedAmount();
  const { isFetched: customerTypesFetched } = UseQuery_GetAllCustomerType();
  const { isFetched: assetCategoriesFetched } = UseQuery_GetAllAssetCategory();
  const { isFetched: assetTypesFetched } = UseQuery_GetAllAssetType();
  const { isFetched: dealersFetched } = UseQuery_GetActiveDealers();
  const { isFetched: lendersFetched } = UseQuery_getAllLender();

  const isDataFetched =
    financeTypesFetched &&
    productTypesFetched &&
    financeAmountRangesFetched &&
    customerTypesFetched &&
    assetCategoriesFetched &&
    assetTypesFetched &&
    dealersFetched &&
    lendersFetched;
  const callInProgress = addCallInProgress || updateCallInProgresss;
  const selectedLenderSelectionRule = lenderSelectionRules?.find(
    (rule) => rule.id === key
  );
  const defaultValues = isEdit
    ? {
        product_types: selectedLenderSelectionRule?.product_types,
        finance_types: selectedLenderSelectionRule?.finance_types,
        finance_amount_range: selectedLenderSelectionRule?.finance_amount_range,
        customer_types: selectedLenderSelectionRule?.customer_types,
        asset_categories: selectedLenderSelectionRule?.asset_categories,
        asset_types: selectedLenderSelectionRule?.asset_types,
        lender_id: selectedLenderSelectionRule?.lender_id?.toString(),
        dealer_ids: selectedLenderSelectionRule?.dealer_ids,
      }
    : {
        product_types: [],
        finance_types: [],
        finance_amount_range: [],
        customer_types: [],
        asset_categories: [],
        asset_types: [],
        lender_id: "",
        dealer_ids: [],
      };
  const addNew = (_event, isEdit = false) => {
    setIsEdit(isEdit);
    setOpen(true);
    setOpenTranslation(false);
    dispatch(setOpenDrawer(true));
  };

  const addNewRule = (body: ILenderSelectionRule) => {
    addLenderSelectionRule(
      {
        ...body,
        lender_id: Number(body.lender_id),
      },
      {
        onSuccess() {
          setOpen(false);
          actions.setToast({
            toastMessage: "Lender Selection Rule has been Added",
            toastState: true,
          });
          queryClient.invalidateQueries(
            QueryKeys.GET_ALL_LENDER_SELECTION_RULES
          );
        },
        onError(error) {
          actions.setToast({
            toastMessage: error.response.data.detail,
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  const editRule = (body: ILenderSelectionRule) => {
    editLenderSelectionRule(
      {
        ...body,
        id: key,
        lender_id: Number(body.lender_id),
      },
      {
        onSuccess() {
          setOpen(false);
          actions.setToast({
            toastMessage: "Lender Selection Rule has been Updated",
            toastState: true,
          });
          queryClient.invalidateQueries(
            QueryKeys.GET_ALL_LENDER_SELECTION_RULES
          );
        },
        onError(error) {
          actions.setToast({
            toastMessage: error.response.data.detail,
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  const deleteRule = () => {
    deleteLenderSelectionRule(
      { id: key },
      {
        onSuccess() {
          actions.setToast({
            toastMessage: "Lender Selection Rule has been Deleted",
            toastState: true,
          });
          queryClient.invalidateQueries(
            QueryKeys.GET_ALL_LENDER_SELECTION_RULES
          );
        },
        onError(error) {
          actions.setToast({
            toastMessage: error.response.data.detail,
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  useEffect(() => {
    if (!open && !openTranslation) {
      dispatch(setOpenDrawer(false));
    }
  }, [open, openTranslation]);

  useEffect(() => {
    return () => dispatch(setOpenDrawer(false));
  }, []);

  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <TableList
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pageSize={pageSize}
          setPageSize={setPageSize}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          deleteRule={deleteRule}
          addNew={addNew}
          setKey={setKey}
          isDataFetched={isDataFetched}
        />
        <Button
          theme={theme}
          secondary
          fullWidth
          onClick={addNew}
          text="Add New Lender Selection Rule"
          disabled={!isDataFetched}
        />
      </DataTableWrap>

      {open && (
        <AddNew
          open={open}
          setOpen={setOpen}
          onSubmit={isEdit ? editRule : addNewRule}
          isEdit={isEdit}
          defaultValues={defaultValues}
          queryParams={queryParams}
          callInProgress={callInProgress}
        />
      )}
    </>
  );
};

export default LenderSelectionRules;
