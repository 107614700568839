import { styled } from "@mui/material/styles";
import { StyleVariables } from "../../styles/theme";

export const AutocompleteWrap: any = styled("div")(({ theme }) => ({
  "&.auto-complete-wrap": {
    ".u-custom-autocomplete": {
      ".MuiAutocomplete-root .u-form-group": {
        "MuiInputBase-formControl": {
          height: "auto",
        },
        ".MuiFormLabel-root": {
          marginTop: -5,
          "&.Mui-filled, &.MuiInputLabel-shrink": {
            marginTop: 0,
            transform: "translate(14px, -9px) scale(0.85)",
            color:
              theme.palette.mode === "light"
                ? theme.palette.text.secondary
                : "rgba(255, 255, 255, 0.87)",
          },
        },
        ".MuiAutocomplete-endAdornment .MuiButtonBase-root.Mui-disabled svg.MuiSvgIcon-root":
          {
            opacity: "0.55 !important",
          },
      },
      "&.autocomplete-disabled": {
        ".MuiAutocomplete-tag": {
          "&.MuiChip-filled.MuiAutocomplete-tag": {
            "&.Mui-disabled": {
              color:
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.38)"
                  : StyleVariables.darkInputDisableColor,
              WebkitTextFillColor:
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.38)"
                  : StyleVariables.darkInputDisableColor,
            },
          },
        },
        ".MuiFormLabel-root": {
          "&.Mui-disabled": {
            marginTop: 0,
            color:
              theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.38)"
                : StyleVariables.darkInputDisableColor + "!importamt",
            WebkitTextFillColor:
              theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.38)"
                : StyleVariables.darkInputDisableColor + "!importamt",
            opacity: 1,
          },
        },
      },
    },
    ".MuiCheckbox-root": {
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
  },
}));
