import "./product-details-drawer-content.scss";
import { Button, Image } from "@src/components/atoms";

import Image1 from "../../../../shared/assets/img/product-details-drawer-img.png";

export const ProductDetailsDrawerContent = () => {
  return (
    <>
      <div className="product-details-drawer-content">
        <div className="product-details-drawer">
          <div className="product-details-drawer__left">
            <div className="product-details-drawer__image">
              <Image src={Image1} alt="image" aspectRatio="1:1" />
              <i
                className="bmw-icon bmw-icon--after data-icon"
                data-icon="arrows_maximize"
              ></i>
            </div>
          </div>
          <div className="product-details-drawer__right">
            <div className="product-details-drawer__right--inner">
              <span className="product-details-drawer__subtitle text--disclaimer">
                Provided by [Demo VSC]
              </span>
              <div className="text--subsection-1">Windshield Protection</div>
              <div className="product-details-drawer__right--price-detail">
                <div className="product-details-drawer__right--price-section">
                  <span className="card-select-default-checked text--body-2">
                    Monthly cost:
                  </span>
                  <div className="text--promo-focus-price">
                    $X,XXX<span className="text--h3">/month</span>{" "}
                  </div>
                  <p className="text--label">$X,XXX total</p>
                </div>
                <p className="text--body-2">
                  Reduce the likelihood that your windshield will crack in the
                  first place. We coat the windshield with a protective layer
                  that enhances visibility and creates a strong, uniform
                  surface-resistant to unwelcome chips and cracks.
                </p>
              </div>
              <div className="product-details-drawer__price-list">
                <div className="pricing-details__item">
                  <span className="pricing-details__item__label text--body-2">
                    <i
                      className="bmw-icon bmw-icon--after data-icon"
                      data-icon="clock"
                    ></i>
                    Terms
                  </span>
                  <span className="pricing-details__item__price text--body-2">
                    XX Months
                  </span>
                </div>
                <div className="pricing-details__item">
                  <span className="pricing-details__item__label text--body-2">
                    <i
                      className="bmw-icon bmw-icon--after data-icon"
                      data-icon="street"
                    ></i>
                    Mileage
                  </span>
                  <span className="pricing-details__item__price text--body-2">
                    XXX,XXX miles
                  </span>
                </div>
                <div className="pricing-details__item">
                  <span className="pricing-details__item__label text--body-2">
                    <i
                      className="bmw-icon bmw-icon--after data-icon"
                      data-icon="insurance_calculator"
                    ></i>
                    Deductible
                    <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                  </span>
                  <span className="pricing-details__item__price text--body-2">
                    $XXX
                  </span>
                </div>
                <div className="pricing-details__item">
                  <span className="pricing-details__item__label text--body-2">
                    <i
                      className="bmw-icon bmw-icon--after data-icon"
                      data-icon="license_plate"
                    ></i>
                    Surcharges
                    <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                  </span>
                  <span className="pricing-details__item__price text--body-2">
                    $XXX
                  </span>
                </div>
              </div>
              <p className="text--disclaimer text-muted">
                For coverage adjustments or more questions, please{" "}
                <a
                  href="/contact-dealer"
                  className="text-dark text--disclaimer-link text-underline fw-normal"
                >
                  contact dealer
                </a>
                .
              </p>
            </div>

            <div className="product-details-drawer__right--links-section">
              <Button
                htmlType={"button"}
                text="Play video"
                linkButton
                withIcon
                dataIcon="play"
              />

              <Button
                htmlType={"button"}
                text="Download brochure"
                linkButton
                withIcon
                dataIcon="download"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
