import { useTheme } from "@mui/material";
import { Button, Box } from "@ntpkunity/controls";
import { FinancialConfigWrap } from "./financial-configurations.style";
import { FinancialConfigurationTabs } from "./config-tabs/config-tabs.component";
import { PageHeader, Stack } from "@components";
import { FC, useContext, useEffect, useMemo, useState } from "react";

import {
  FlatRateCalculationBasis,
  useConfigurationContext,
} from "@ntpkunity/controls-common";
import { useSaveFinancialConfigurations } from "@hooks/mutations/useSaveConfigurations";
import { FormProvider, useForm } from "react-hook-form";
import { useSnackbarContext } from "@contexts/snackbar";
import { useQueryClient } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import useGetPermissions from "@hooks/queries/useGetPermissions";
import { UMSStoreContext } from "@ntpkunity/controls-ums";
import { useSetupsSelector } from "@hooks";
import { FinancialConfigurationsForm } from "@_types";
import { PageContent } from "@pages/financial-configurations/financial-configurations.style";

export const FinancialConfigurations: FC<{}> = () => {
  const theme = useTheme();
  const {
    states: { productId: productId },
  } = useContext(UMSStoreContext) as any;
  const { data: umsPermissionsData } = useGetPermissions(productId?.productId);
  const { setSnackbar } = useSnackbarContext();
  const queryClient = useQueryClient();
  const { data: configurations } = useConfigurationContext();

  const handleSaveSuccess = () => {
    setSnackbar(
      {
        message: "Configurations successfully saved",
        open: true,
        variant: null,
      },
      10000
    );
    queryClient.invalidateQueries([QUERY_KEYS.QUOTATION_CONFIGURATIONS]);
    form.reset(form.getValues());
  };

  const handleSaveError = () => {
    setSnackbar(
      {
        message: "Error occurred while saving configurations",
        open: true,
        variant: "error",
      },
      10000
    );
  };

  const { mutate: saveFinancialConfigurations } =
    useSaveFinancialConfigurations({
      onSuccess: handleSaveSuccess,
      onError: handleSaveError,
    });
  const { data: financeTypes } = useSetupsSelector(
    (state) => state.financeTypes
  );

  const [financeType, setFinanceType] = useState(financeTypes[0]?.code);

  const reduceConfigObject = () => {
    return Object.keys(configurations).reduce((acc, key) => {
      acc[key] = {
        ...configurations[key],
        flatRateCalculationBasis:
          configurations[key].flatRateCalculationBasis ||
          FlatRateCalculationBasis.CALENDAR_DATES,
        paymentsIncludingBalloon:
          configurations[key].paymentsIncludingBalloon || false,
        financeType: key,
      };
      return acc;
    }, {});
  };

  const defaultValues = useMemo(reduceConfigObject, [umsPermissionsData]);
  const form = useForm<FinancialConfigurationsForm>({
    shouldUnregister: false,
    defaultValues: defaultValues,
  });

  const { isDirty } = form.formState;

  useEffect(() => {
    form.reset(defaultValues);
  }, [umsPermissionsData]);

  const onsubmit = (data: FinancialConfigurationsForm) => {
    const updatedFees = data[financeType]?.fees?.map((fee) => ({
      PaymentNumber: fee.paymentNumber ?? 0,
      FirstDueDate: new Date().toString(),
      Name: fee?.name,
      Frequency: fee?.type,
      Occurance: fee?.frequency,
      Amount: fee?.amount,
      IsEditable: fee?.isEditable,
      FirstFeePaymentDueAtStartDate: fee?.firstFeePaymentDueAtStartDate,
    }));
    const updatedData = {
      AmortizationMethod: data[financeType]?.amortizationMethod,
      APRCalculationMethod: data[financeType]?.aprCalculationMethod,
      InitialCashOutFlowForFlatRate:
        data[financeType]?.initialCashOutFlowForFlatRate,
      CommissionCalculationMethod:
        data[financeType]?.commissionCalculationMethod,
      IsCommissionPassToCustomer: data[financeType]?.isCommissionPassToCustomer,
      IsVATApplicable: data[financeType]?.isVATApplicable,
      Fees: updatedFees,
      Tax: 0,
      TaxType: data[financeType]?.taxType,
      GrossProfit: data[financeType]?.grossProfit,
      CalculateCorporateTax: data[financeType]?.calculateCorporateTax,
      FlatRateCalculationBasis: data[financeType]?.flatRateCalculationBasis,
      PaymentsIncludingBalloon: data[financeType]?.paymentsIncludingBalloon,
    };
    saveFinancialConfigurations({ ...updatedData, financeType });
  };

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onsubmit)}>
          <FinancialConfigWrap theme={theme} className="financial-config-wrap">
            <PageHeader
              title="Financial Configurations"
              actionArea={
                <>
                  <Button
                    theme={theme}
                    primary
                    type="submit"
                    text="Save Changes"
                    onClick={() => {}}
                    disabled={!isDirty}
                  />
                </>
              }
            />
            <PageContent theme={theme} className="page-content" pt={3}>
              <Box
                theme={theme}
                className="financial-config-container"
                m={{ xs: 0, md: "-8px -24px -8px -24px" }}
              >
                <Stack paddingMd={3} paddingXs={3}>
                  <FinancialConfigurationTabs
                    financeType={financeType}
                    setFinanceType={setFinanceType}
                  />
                </Stack>
              </Box>
            </PageContent>
          </FinancialConfigWrap>
        </form>
      </FormProvider>
    </>
  );
};
