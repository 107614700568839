import { Button } from "@src/components/atoms";
import { WelcomeBannerProps } from "./welcome-banner.props";
import "./welcome-banner.scss";

export const WelcomeBanner = ({
  title,
  decription,
  actionArea,
  hasCloseIcon,
}: WelcomeBannerProps) => {
  return (
    <>
      <div className="welcome-banner">
        {hasCloseIcon && (
          <div className="closeIcon">
            <Button htmlType={"button"} linkButton withIcon dataIcon="close" />
          </div>
        )}
        <div className="bmw-container">
          <div className="welcome-banner__inner">
            <div className="welcome-banner__left">
              <div className="welcome-banner__title">
                {title && (
                  <div className="welcome-banner__title-text">
                    <h2>{title}</h2>
                  </div>
                )}
                {decription && (
                  <div className="welcome-banner__title-decription">
                    <p className="text--body-1">{decription}</p>
                  </div>
                )}
              </div>
            </div>

            <div className="welcome-banner__right">
              {actionArea && (
                <div className="welcome-banner__actions">{actionArea}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
