import { useTheme } from "@mui/material"
import { Icon, Box, Typography } from "@ntpkunity/controls"
import { PdfListProps } from "./pdf-list.props"
import { PdfListWrap } from "./pdf-list.style"
import clsx from "clsx"


export const PdfList = ({title, subTitle, variant, onClick, pdfIcon}: PdfListProps) => {
    const theme = useTheme()

    return(
        <>
            <PdfListWrap theme={theme} className={clsx({"business-rules-alerts-wrap": true, "light": variant === "light"})} onClick={onClick}>
                {pdfIcon && <Icon name="PdfViewIcon" />}
                {title &&
                    <Box theme={theme} className="doc-list">
                        <Typography theme={theme} variant="body2" component={"span"} className="fw-medium">{title}</Typography>
                        {subTitle &&
                            <Typography theme={theme} variant="body2" component={"span"} className="text-muted">{subTitle}</Typography>
                        }
                    </Box>
                }
            </PdfListWrap>
        </>
    )
}