import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Login: FC = () => {
  let navigate = useNavigate()
  useEffect(() => {
    navigate('/lane/workqueue')
  }, [])

  return <></>
}

export default Login
