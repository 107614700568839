import { useTheme } from "@mui/material";
import {
  Box,
  Button,
  Dialog,
  Grid,
  Image,
  Typography,
} from "@ntpkunity/controls";
import {
  Popup,
  ProductAddCard,
  ProductDecriptionCard,
  ResponsiveCarousel,
} from "@components";
import React, { FC, useContext, useEffect, useState } from "react";
import { IFniProduct } from "@interfaces";
import { Translations } from "@constants";
import { AppContext } from "@app/context-provider";
import { useParams } from 'react-router-dom';
import { useTags } from 'hooks/event-analytics';
import { Event } from 'apis/event-analytics';
import { Tags, PageType } from 'constants/enums';
import { user_session } from '@constants'

export const VehicleDetailsPopup: FC<{
  open: boolean;
  setOpen: any;
  product: IFniProduct;
  rate?: any;
  onButtonClick?: any;
  brochureUrl?: string;
}> = ({ open, product, setOpen, rate, onButtonClick, brochureUrl }) => {
  const { tenant } = useParams();
  const { mutate: createTags } = useTags();
  const user_profile = JSON.parse(localStorage.getItem("user_profile"));
  const theme = useTheme();
  const appState = useContext(AppContext);
  const [selectedCoverage, setSelectedCoverage] = useState(-1);
  const PLACEHOLDERS = appState.state.language.placeholders;
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    const event_detail: Event = {
      session: user_session,
      slug: tenant,
      event_name: Tags.VIEW_DETAILS,
      user_id: user_profile?.user_id,
      page_type: PageType.FIPage,
    };
    createTags(event_detail);
  }, [])
  const isPriceAdded = (product, coverage): boolean => {
    return (
      (product.price == coverage.deductibles?.deductible?.retail_price) &&
      (product.term == coverage.term_months) &&
      product.is_added
    );
  };
  return (
    <Popup>
      <Dialog
        theme={theme}
        size="md"
        open={open}
        title={product.product_name}
        onCloseDialog={() => {
          setOpen(false);
        }}
        disablePortal
        customFooter={
          <Button
            theme={theme}
            primary
            text={PLACEHOLDERS.BUTTON_TEXT_CONTINUE}
            onClick={() => {
              setOpen(false);
            }}
          ></Button>
        }
      >
        <Grid theme={theme} container item spacing={2}>
          <Grid item xs={12} theme={theme}>
            <ResponsiveCarousel responsive={responsive} autoPlaySpeed={5000}>
              <Box theme={theme} key={1}>
                <Image
                  theme={theme}
                  src={product?.image?.[0]?.location}
                  aspectRatio="16:9"
                  alt="image"
                />
              </Box>
              <Box theme={theme} key={2}>
                <Image
                  theme={theme}
                  src={product?.image?.[0]?.location}
                  aspectRatio="16:9"
                  alt="image"
                />
              </Box>
              <Box theme={theme} key={3}>
                <Image
                  theme={theme}
                  src={product?.image?.[0]?.location}
                  aspectRatio="16:9"
                  alt="image"
                />
              </Box>
              <Box theme={theme} key={4}>
                <Image
                  theme={theme}
                  src={product?.image?.[0]?.location}
                  aspectRatio="16:9"
                  alt="image"
                />
              </Box>
            </ResponsiveCarousel>
          </Grid>
          <Grid item xs={12} theme={theme}>
            <ProductDecriptionCard
              label={product.provider_name}
              title={product.product_name}
              secondaryText={product.product_type}
              desc={product.description}
              link={[
                {
                  title: PLACEHOLDERS.FNI_VIEW_PRODUCT_BROCHURE,
                  onClick: () => {
                    window.open(brochureUrl);
                  },
                  hideLink: !brochureUrl,
                },
              ]}
            />
          </Grid>
          {rate?.coverages?.coverage.map((coverage: any, index: any) => (
            <Grid item xs={12} theme={theme} key={index}>
              <ProductAddCard
                title={coverage.coverage_name.concat(
                  ` - ${coverage.term_months} ${PLACEHOLDERS.MONTHS_TEXT}`
                )}
                primaryText={
                  "$" +
                  parseFloat(coverage.deductibles?.deductible?.monthly_impact || '0.0')?.toFixed(2) +
                  `/${PLACEHOLDERS.PER_MONTH}. `
                }
                secondaryText={
                  "$" +
                  coverage.deductibles?.deductible?.selling_price.toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }
                  ) +
                  " " +
                  PLACEHOLDERS.FNI_TOTAL_PRICE_TEXT
                }
                buttonText={
                  isPriceAdded(
                    product,
                    coverage
                  )
                    ? PLACEHOLDERS.FNI_ADDED_TEXT
                    : PLACEHOLDERS.FNI_ADD_TEXT
                }
                isSelected={index == selectedCoverage}
                onCardClick={() => {
                  setSelectedCoverage(index);
                  onButtonClick(
                    product.id,
                    false,
                    coverage.deductibles?.deductible?.selling_price,
                    coverage.term_months,
                    coverage.term_miles,
                    coverage.deductibles?.deductible?.monthly_impact,
                  );
                }}
                onClick={() => {
                  setSelectedCoverage(index);
                  onButtonClick(
                    product.id,
                    true,
                    coverage.deductibles?.deductible?.selling_price,
                    coverage.term_months,
                    coverage.term_miles
                  );
                }}
              />
            </Grid>
          ))}
          <Grid item xs={12} theme={theme}>
            <Typography
              theme={theme}
              variant="caption"
              component={"small"}
              display={"block"}
              className="text-muted text-center"
            >
              {PLACEHOLDERS.FNI_FOOTER_TEXT}
            </Typography>
          </Grid>
        </Grid>
      </Dialog>
    </Popup>
  );
};
