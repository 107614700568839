import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  FETCH_ASSET_DETAILS_REQUEST,
  fetchAssetDetailsSuccess,
  fetchAssetDetailsFailure,
  OPEN_ASSET_DIALOG,
} from "../actions/assetDetailsActions";

function* fetchAssetDetailsSaga(action) {
  const assetReferenceNum = action.payload;
  try {
    const response = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/dms/order/order_information/${assetReferenceNum}`,
      {
        headers: { "x-api-key": `${process.env.REACT_APP_API_KEY}` },
      }
    );
    yield put(fetchAssetDetailsSuccess(response.data));
  } catch (error) {
    yield put(fetchAssetDetailsFailure(error.message));
  }
}

function* fetchAssetDetailsOnDialogOpen(action) {
  try {
    const assetReferenceNum = action.payload;
    const response = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/dms/order/order_information/${assetReferenceNum}`,
      {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      }
    );

    yield put(fetchAssetDetailsSuccess(response.data));
  } catch (error) {
    yield put(fetchAssetDetailsFailure(error.message));
  }
}

export function* watchAssetDetailsSaga() {
  yield takeLatest(FETCH_ASSET_DETAILS_REQUEST, fetchAssetDetailsSaga);
  yield takeLatest(OPEN_ASSET_DIALOG, fetchAssetDetailsOnDialogOpen);
}