import { styled } from "@mui/material/styles";
import { useState } from "react";
import { Box, IBoxProps, unityTheme } from "@ntpkunity/controls";
import { CircularProgress } from "@mui/material";

const IframeWrap = styled("div")(() => ({
  position: "relative",
  height: "calc(100vh - 290px)",
}));

const CloseButton = styled("button")({
  position: "absolute",
  top: 3,
  right: 15,
  background: "transparent",
  color: "darkgrey",
  border: "1px solid darkgrey",
  padding: 0,
  cursor: "pointer",
  zIndex: 1000,
  width: "24px",
  height: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
});

const LoaderWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.loader-wrap": {
    position: "relative",
    width: "100%",
    height: "100%",
    ".loader": {
      width: 114,
      height: 114,
      position: "absolute",
      left: 0,
      right: 0,
      margin: "auto",
      top: "50%",
      transform: "translateY(-50%)",
      img: {
        maxHeight: 114,
      },
    },
  },
}));

export const IFrame = ({ onIframeClose, IFrameLink }) => {
  const [loading, setLoading] = useState(true);
  return (
    <IframeWrap>
      {loading ? (
        <LoaderWrap theme={unityTheme} className="loader-wrap">
          <Box theme={unityTheme} className="loader">
            <CircularProgress size={50} />
          </Box>
        </LoaderWrap>
      ) : null}
      <iframe
        width={"100%"}
        height={"100%"}
        style={{ display: loading ? "none" : "block" }}
        onLoad={() => setLoading(false)}
        src={IFrameLink}
        title="Create template"
      ></iframe>
      <CloseButton onClick={onIframeClose}>X</CloseButton>
    </IframeWrap>
  );
};
