import { useTheme } from "@mui/material";
import { Box, Grid, Typography } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { Stack } from "@src/components/stack";
import { Alert } from "@src/components"
import { useSelector } from "react-redux";

export const AssetDetailsList = () => {
  const theme = useTheme();

  const { assetDetails, loading, error } = useSelector((state: any) => state.assetDialog);
  return (
    <>
      <Grid
        theme={theme}
        container
        rowSpacing={0}
        columnSpacing={2}
        sx={{ mt: 0 }}
      >
        {
          loading ? (
            <p>loading...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : <Grid item xs={6} theme={theme}>
            <Stack paddingMd={0} paddingXs={0}>
              <BasicList
                noWrap
                options={[
                  {
                    title: "Make:",
                    subtitle: assetDetails?.Vehicle_details?.make ?? "N/A"
                  },
                  {
                    title: "Modal:",
                    subtitle: assetDetails?.Vehicle_details?.model ?? "N/A",
                  },
                  {
                    title: "Year:",
                    subtitle: assetDetails?.Vehicle_details?.year ?? "N/A",
                  },
                  {
                    title: "Trim:",
                    subtitle: assetDetails?.Vehicle_details?.trim_description ?? "N/A",
                  }
                ]}
              />
            </Stack>
          </Grid>
        }
        <Grid item xs={6} theme={theme}>
          <Stack paddingMd={0} paddingXs={0}>
            <BasicList
              options={[
                {
                  title: "Condition:",
                  subtitle: assetDetails?.Vehicle_details?.type ?? "N/A",
                },
                {
                  title: "Price:",
                  subtitle: assetDetails?.selling_price ?? "N/A",
                },
              ]}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
