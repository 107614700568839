import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Box, Button, Input, Select, Typography } from '@ntpkunity/controls'
import AddVehicle from './add-vehicle'

const CalculationGrid: FC<{ type: string }> = ({ type }) => {
  const theme = useTheme()

  return (
    <>
      <Box theme={theme} className="flc-wrap">
        <ul className="flc-ul">
          {/********************** Add Vehicle **********************/}
          <li className="flc-li d-inline-block">
            <AddVehicle />
          </li>
          {/********************** Header **********************/}
          <li className="flc-li">
            <Box theme={theme} className="flc-li-left-col">
              <Box theme={theme} className="flc-labels-wrap">
                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    children="Down Payment"
                  />
                </Box>
              </Box>
            </Box>
            <Box theme={theme} className="flc-li-selfStretch-col">
              <Box theme={theme} className="calc-form-group">
                <Input
                  type={'text'}
                  fullWidth
                  theme={theme}
                  startAdornment="$"
                  value={type === 'finance' ? '15,718.75' : '12,375.00'}
                />
              </Box>
            </Box>
            <Box theme={theme} className="flc-li-selfStretch-col">
              <Box theme={theme} className="calc-form-group">
                <Input
                  type={'text'}
                  fullWidth
                  theme={theme}
                  startAdornment="$"
                  value={type === 'finance' ? '16,718.75' : '13,375.00'}
                />
              </Box>
            </Box>
            <Box theme={theme} className="flc-li-selfStretch-col">
              <Box theme={theme} className="calc-form-group">
                <Input
                  type={'text'}
                  fullWidth
                  theme={theme}
                  startAdornment="$"
                  value={type === 'finance' ? '17,718.75' : '14,375.00'}
                />
              </Box>
            </Box>
          </li>
          {/********************** Calculation Table **********************/}
          <li className="flc-li">
            <Box theme={theme} className="flc-li-left-col">
              <Box theme={theme} className="flc-labels-wrap">
                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="label"
                    children="Term"
                  />
                  <Box theme={theme} className="calc-form-group">
                    <Select
                      theme={theme}
                      fullWidth
                      disablePortal
                      items={[
                        { text: '12mo', value: '1' },
                        { text: '24mo', value: '2' },
                        { text: '36mo', value: '3' },
                        { text: '48mo', value: '4' },
                        { text: '60mo', value: '5' },
                        { text: '72mo', value: '6' }
                      ]}
                      value={'2'}
                    />
                  </Box>
                </Box>
                {
                  /* Miles */
                  type !== 'finance' && (
                    <Box theme={theme} className="label-row">
                      <Typography
                        theme={theme}
                        variant="caption"
                        component="div"
                        className="label"
                        children="Miles"
                      />
                      <Box theme={theme} className="calc-form-group">
                        <Select
                          theme={theme}
                          fullWidth
                          disablePortal
                          items={[
                            { text: '10k', value: '1' },
                            { text: '20k', value: '2' },
                            { text: '30k', value: '3' },
                            { text: '40k', value: '4' },
                            { text: '45.545k', value: '5' }
                          ]}
                          value={'2'}
                        />
                      </Box>
                    </Box>
                  )
                }

                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="label"
                    children="Rate"
                  />
                  <Box theme={theme} className="calc-form-group">
                    <Input
                      type={'text'}
                      theme={theme}
                      endAdornment="%"
                      value={type === 'finance' ? '3' : '2.25'}
                    />
                  </Box>
                </Box>
                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="label"
                    children={type === 'finance' ? 'Balloon' : 'RV'}
                  />
                  <Box theme={theme} className="calc-form-group">
                    <Input
                      type={'text'}
                      theme={theme}
                      endAdornment="%"
                      value={type === 'finance' ? '0' : '0'}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Button
              theme={theme}
              defaultBtn
              className="amount grey-bg flc-li-selfStretch-col selected"
              text={
                <>
                  <span>$</span>
                  {type === 'finance' ? '2,477' : '2,278'}
                </>
              }
            />
            <Button
              theme={theme}
              defaultBtn
              className="amount grey-bg flc-li-selfStretch-col"
              text={
                <>
                  <span>$</span>
                  {type === 'finance' ? '2,435' : '2,236'}
                </>
              }
            />
            <Button
              theme={theme}
              defaultBtn
              className="amount grey-bg flc-li-selfStretch-col"
              text={
                <>
                  <span>$</span>
                  {type === 'finance' ? '2,392' : '2,193'}
                </>
              }
            />
          </li>
          {/********************************************/}
          <li className="flc-li">
            <Box theme={theme} className="flc-li-left-col">
              <Box theme={theme} className="flc-labels-wrap">
                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="label"
                    children="Term"
                  />
                  <Box theme={theme} className="calc-form-group">
                    <Select
                      theme={theme}
                      fullWidth
                      disablePortal
                      items={[
                        { text: '12mo', value: '1' },
                        { text: '24mo', value: '2' },
                        { text: '36mo', value: '3' },
                        { text: '48mo', value: '4' },
                        { text: '60mo', value: '5' },
                        { text: '72mo', value: '6' }
                      ]}
                      value={'3'}
                    />
                  </Box>
                </Box>

                {
                  /* Miles */
                  type !== 'finance' && (
                    <Box theme={theme} className="label-row">
                      <Typography
                        theme={theme}
                        variant="caption"
                        component="div"
                        className="label"
                        children="Miles"
                      />
                      <Box theme={theme} className="calc-form-group">
                        <Select
                          theme={theme}
                          fullWidth
                          disablePortal
                          items={[
                            { text: '10k', value: '1' },
                            { text: '20k', value: '2' },
                            { text: '30k', value: '3' },
                            { text: '40k', value: '4' },
                            { text: '45.545k', value: '5' }
                          ]}
                          value={'2'}
                        />
                      </Box>
                    </Box>
                  )
                }

                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="label"
                    children="Rate"
                  />
                  <Box theme={theme} className="calc-form-group">
                    <Input
                      type={'text'}
                      theme={theme}
                      endAdornment="%"
                      value={type === 'finance' ? '3' : '2.25'}
                    />
                  </Box>
                </Box>
                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="label"
                    children={type === 'finance' ? 'Balloon' : 'RV'}
                  />
                  <Box theme={theme} className="calc-form-group">
                    <Input
                      type={'text'}
                      theme={theme}
                      endAdornment="%"
                      value={type === 'finance' ? '0' : '0'}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Button
              theme={theme}
              defaultBtn
              className="amount grey-bg flc-li-selfStretch-col"
              text={
                <>
                  <span>$</span>
                  {type === 'finance' ? '1,676' : '1,540'}
                </>
              }
            />
            <Button
              theme={theme}
              defaultBtn
              className="amount grey-bg flc-li-selfStretch-col"
              text={
                <>
                  <span>$</span>
                  {type === 'finance' ? '1,647' : '1,511'}
                </>
              }
            />
            <Button
              theme={theme}
              defaultBtn
              className="amount grey-bg flc-li-selfStretch-col"
              text={
                <>
                  <span>$</span>
                  {type === 'finance' ? '1,618' : '1,483'}
                </>
              }
            />
          </li>
          {/********************************************/}
          <li className="flc-li">
            <Box theme={theme} className="flc-li-left-col">
              <Box theme={theme} className="flc-labels-wrap">
                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="label"
                    children="Term"
                  />
                  <Box theme={theme} className="calc-form-group">
                    <Select
                      theme={theme}
                      fullWidth
                      disablePortal
                      items={[
                        { text: '12mo', value: '1' },
                        { text: '24mo', value: '2' },
                        { text: '36mo', value: '3' },
                        { text: '48mo', value: '4' },
                        { text: '60mo', value: '5' },
                        { text: '72mo', value: '6' }
                      ]}
                      value={'4'}
                    />
                  </Box>
                </Box>

                {
                  /* Miles */
                  type !== 'finance' && (
                    <Box theme={theme} className="label-row">
                      <Typography
                        theme={theme}
                        variant="caption"
                        component="div"
                        className="label"
                        children="Miles"
                      />
                      <Box theme={theme} className="calc-form-group">
                        <Select
                          theme={theme}
                          fullWidth
                          disablePortal
                          items={[
                            { text: '10k', value: '1' },
                            { text: '20k', value: '2' },
                            { text: '30k', value: '3' },
                            { text: '40k', value: '4' },
                            { text: '45.545k', value: '5' }
                          ]}
                          value={'4'}
                        />
                      </Box>
                    </Box>
                  )
                }

                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="label"
                    children="Rate"
                  />
                  <Box theme={theme} className="calc-form-group">
                    <Input
                      type={'text'}
                      theme={theme}
                      endAdornment="%"
                      value={type === 'finance' ? '3' : '2.25'}
                    />
                  </Box>
                </Box>
                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="label"
                    children={type === 'finance' ? 'Balloon' : 'RV'}
                  />
                  <Box theme={theme} className="calc-form-group">
                    <Input
                      type={'text'}
                      theme={theme}
                      endAdornment="%"
                      value={type === 'finance' ? '0' : '0'}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Button
              theme={theme}
              defaultBtn
              className="amount grey-bg flc-li-selfStretch-col"
              text={
                <>
                  <span>$</span>
                  {type === 'finance' ? '1,276' : '1,171'}
                </>
              }
            />
            <Button
              theme={theme}
              defaultBtn
              className="amount grey-bg flc-li-selfStretch-col"
              text={
                <>
                  <span>$</span>
                  {type === 'finance' ? '1,254' : '1,149'}
                </>
              }
            />
            <Button
              theme={theme}
              defaultBtn
              className="amount grey-bg flc-li-selfStretch-col"
              text={
                <>
                  <span>$</span>
                  {type === 'finance' ? '1,232' : '1,128'}
                </>
              }
            />
          </li>
          {/********************** Footer **********************/}
          <li className="flc-li flc-footer">
            <Box theme={theme} className="flc-li-left-col">
              <Box theme={theme} className="flc-labels-wrap">
                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    children="Financed Amount"
                  />
                </Box>
              </Box>
            </Box>
            <Box theme={theme} className="flc-li-selfStretch-col border-bottom">
              <Typography
                theme={theme}
                variant="caption"
                component="div"
                className="amount"
                textAlign="center"
                children={type === 'finance' ? '$57,641.14' : '$55,621.33'}
              />
            </Box>
            <Box theme={theme} className="flc-li-selfStretch-col border-bottom">
              <Typography
                theme={theme}
                variant="caption"
                component="div"
                className="amount"
                textAlign="center"
                children={type === 'finance' ? '$56,641.14' : '$54,707.58'}
              />
            </Box>
            <Box theme={theme} className="flc-li-selfStretch-col border-bottom">
              <Typography
                theme={theme}
                variant="caption"
                component="div"
                className="amount"
                textAlign="center"
                children={type === 'finance' ? '$55,641.14' : '$53,793.83'}
              />
            </Box>
          </li>
          <li className="flc-li flc-footer">
            <Box theme={theme} className="flc-li-left-col">
              <Box theme={theme} className="flc-labels-wrap">
                <Box theme={theme} className="label-row">
                  <Typography theme={theme} variant="caption" component="div" children="Carry%" />
                </Box>
              </Box>
            </Box>
            <Box theme={theme} className="flc-li-selfStretch-col border-bottom">
              <Typography
                theme={theme}
                variant="caption"
                component="div"
                className="amount"
                textAlign="center"
                children={type === 'finance' ? '64.55' : '62.29'}
              />
            </Box>
            <Box theme={theme} className="flc-li-selfStretch-col border-bottom">
              <Typography
                theme={theme}
                variant="caption"
                component="div"
                className="amount"
                textAlign="center"
                children={type === 'finance' ? '63.43' : '61.26'}
              />
            </Box>
            <Box theme={theme} className="flc-li-selfStretch-col border-bottom">
              <Typography
                theme={theme}
                variant="caption"
                component="div"
                className="amount"
                textAlign="center"
                children={type === 'finance' ? '62.31' : '60.24'}
              />
            </Box>
          </li>
        </ul>
      </Box>
    </>
  )
}

export default CalculationGrid
