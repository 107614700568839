import React, { useEffect, useRef, useState } from "react";

declare const mitekScienceSDK: any; // Ensure to include the Mitek SDK globally in your HTML file

const MitekImageCapture: React.FC = () => {
  const videoRef = useRef<HTMLDivElement | null>(null);
  const [captureResult, setCaptureResult] = useState<any>(null);

  useEffect(() => {
    const mitekSDKPath = "/misnap/mitekSDK/"; // Adjust the path if necessary
    const license =
      "eyJzaWduYXR1cmUiOiJuNFNhTk9BakRlaGFRUUVTdlhCQlhjbTM4a2ZrcWJLY0UyQlhIb1hlYndqUi9ob0dKY1ZDM0psS3piY1psOGI3cDdyOHlLS0pKZ0tpcXZsd2VxWlI3SVpTd21tTEd4SmNKdVRMKzIrV1ZBYUxRdFA2L3hNT2xiMVMzb082K0Q3c2t1M002UFJ0UlFINUliRzFxV1p1cE5xdkFSYzhMVkhNTk0zem5zRVZidHdiUFFKVHRUSzR1WUp1VEhueko3L2lKK0RLbkpsVFd6S2dXa3VndzZXMm9Wdit2SWhYUmtmTUdDUmJva3hMaExEK1BZVzJ0Rzk4Nks1N05YQWNRK1lyV25JVlhqQ3VibERwSGVBU29XSzByWVdNL2dVYU1HanltTFRrUHJJZjNxUHpLV0laZlh5TWNUYkJUbmVDenYwNG9vSHRlaE5WdkxFMjhhWE9qSmpqNWc9PSIsInNpZ25lZCI6eyJleHBpcnkiOiIyMDI1LTExLTIyIiwiZmVhdHVyZXMiOlsiYmFyY29kZSIsImRlcG9zaXQiLCJmYWNlIiwiZ2VuZXJpYyIsImlkIiwibmZjIiwib2RjIiwib2RlIiwidm9pY2UiLCJlbSJdLCJhcHBsaWNhdGlvbklkZW50aWZpZXIiOnsid2ViIjpbImxvY2FsaG9zdCIsIjQ0LjIxMC4yNTAuMjIwIiwiOTguODMuMTcwLjIyMSJdfSwiZ3BvIjoiMTZkIiwib3JnYW5pemF0aW9uIjoiTWl0ZWsgU3lzdGVtcyBJbmMuIiwidmVyc2lvbiI6IjEuMCJ9fQ=="; // Replace with the actual license key

    // Preload Mitek SDK Components
    mitekScienceSDK.cmd("COMPONENT_PRELOAD", {
      preloadComponents: ["ALL"],
      mitekSDKPath,
      options: { license },
    });

    return () => {
      // Cleanup resources when the component unmounts
      mitekScienceSDK.cmd("SDK_STOP");
    };
  }, []);

  const startAutoCapture = () => {
    if (!videoRef.current) return;

    mitekScienceSDK.on("FRAME_PROCESSING_FEEDBACK", (feedback: any) => {
      // eslint-disable-next-line no-console
      console.log("Feedback:", feedback);
    });

    mitekScienceSDK.on("FRAME_CAPTURE_RESULT", (result: any) => {
      setCaptureResult(result);
      // eslint-disable-next-line no-console
      console.log("Capture Result:", result);
    });

    mitekScienceSDK.on("SDK_ERROR", (err: any) => {
      console.error("SDK Error:", err);
    });

    mitekScienceSDK.cmd("CAPTURE_AND_PROCESS_FRAME", {
      mode: "AUTO_CAPTURE",
      documentType: "DOCUMENT", // Adjust document type as needed
      mitekSDKPath: "/misnap/mitekSDK/",
      options: {
        qualityPercent: 90,
        hintFrequencyMS: 1000,
        disableSmileDetection: false,
        disablePerpendicularCapture: true,
        license:
          "eyJzaWduYXR1cmUiOiJuNFNhTk9BakRlaGFRUUVTdlhCQlhjbTM4a2ZrcWJLY0UyQlhIb1hlYndqUi9ob0dKY1ZDM0psS3piY1psOGI3cDdyOHlLS0pKZ0tpcXZsd2VxWlI3SVpTd21tTEd4SmNKdVRMKzIrV1ZBYUxRdFA2L3hNT2xiMVMzb082K0Q3c2t1M002UFJ0UlFINUliRzFxV1p1cE5xdkFSYzhMVkhNTk0zem5zRVZidHdiUFFKVHRUSzR1WUp1VEhueko3L2lKK0RLbkpsVFd6S2dXa3VndzZXMm9Wdit2SWhYUmtmTUdDUmJva3hMaExEK1BZVzJ0Rzk4Nks1N05YQWNRK1lyV25JVlhqQ3VibERwSGVBU29XSzByWVdNL2dVYU1HanltTFRrUHJJZjNxUHpLV0laZlh5TWNUYkJUbmVDenYwNG9vSHRlaE5WdkxFMjhhWE9qSmpqNWc9PSIsInNpZ25lZCI6eyJleHBpcnkiOiIyMDI1LTExLTIyIiwiZmVhdHVyZXMiOlsiYmFyY29kZSIsImRlcG9zaXQiLCJmYWNlIiwiZ2VuZXJpYyIsImlkIiwibmZjIiwib2RjIiwib2RlIiwidm9pY2UiLCJlbSJdLCJhcHBsaWNhdGlvbklkZW50aWZpZXIiOnsid2ViIjpbImxvY2FsaG9zdCIsIjQ0LjIxMC4yNTAuMjIwIiwiOTguODMuMTcwLjIyMSJdfSwiZ3BvIjoiMTZkIiwib3JnYW5pemF0aW9uIjoiTWl0ZWsgU3lzdGVtcyBJbmMuIiwidmVyc2lvbiI6IjEuMCJ9fQ==",
      },
    });
  };

  const startManualCapture = () => {
    if (!videoRef.current) return;

    mitekScienceSDK.on("FRAME_PROCESSING_FEEDBACK", (feedback: any) => {
      // eslint-disable-next-line no-console
      console.log("Feedback:", feedback);
    });

    mitekScienceSDK.on("FRAME_CAPTURE_RESULT", (result: any) => {
      setCaptureResult(result);
      // eslint-disable-next-line no-console
      console.log("Capture Result:", result);
    });

    mitekScienceSDK.on("SDK_ERROR", (err: any) => {
      console.error("SDK Error:", err);
    });

    mitekScienceSDK.cmd("CAPTURE_AND_PROCESS_FRAME", {
      mode: "MANUAL_CAPTURE",
      documentType: "DOCUMENT", // Adjust document type as needed
      mitekSDKPath: "/misnap/mitekSDK/",
      options: {
        qualityPercent: 90,
        license:
          "eyJzaWduYXR1cmUiOiJuNFNhTk9BakRlaGFRUUVTdlhCQlhjbTM4a2ZrcWJLY0UyQlhIb1hlYndqUi9ob0dKY1ZDM0psS3piY1psOGI3cDdyOHlLS0pKZ0tpcXZsd2VxWlI3SVpTd21tTEd4SmNKdVRMKzIrV1ZBYUxRdFA2L3hNT2xiMVMzb082K0Q3c2t1M002UFJ0UlFINUliRzFxV1p1cE5xdkFSYzhMVkhNTk0zem5zRVZidHdiUFFKVHRUSzR1WUp1VEhueko3L2lKK0RLbkpsVFd6S2dXa3VndzZXMm9Wdit2SWhYUmtmTUdDUmJva3hMaExEK1BZVzJ0Rzk4Nks1N05YQWNRK1lyV25JVlhqQ3VibERwSGVBU29XSzByWVdNL2dVYU1HanltTFRrUHJJZjNxUHpLV0laZlh5TWNUYkJUbmVDenYwNG9vSHRlaE5WdkxFMjhhWE9qSmpqNWc9PSIsInNpZ25lZCI6eyJleHBpcnkiOiIyMDI1LTExLTIyIiwiZmVhdHVyZXMiOlsiYmFyY29kZSIsImRlcG9zaXQiLCJmYWNlIiwiZ2VuZXJpYyIsImlkIiwibmZjIiwib2RjIiwib2RlIiwidm9pY2UiLCJlbSJdLCJhcHBsaWNhdGlvbklkZW50aWZpZXIiOnsid2ViIjpbImxvY2FsaG9zdCIsIjQ0LjIxMC4yNTAuMjIwIiwiOTguODMuMTcwLjIyMSJdfSwiZ3BvIjoiMTZkIiwib3JnYW5pemF0aW9uIjoiTWl0ZWsgU3lzdGVtcyBJbmMuIiwidmVyc2lvbiI6IjEuMCJ9fQ==",
      },
    });
  };

  const stopCapture = () => {
    mitekScienceSDK.cmd("SDK_STOP");
  };

  return (
    <div>
      <h1>Mitek Image Capture</h1>
      <div
        id="mitekDisplayContainer"
        ref={videoRef}
        style={{ height: "400px", width: "400px", border: "1px solid #ccc" }}
      ></div>
      <div>
        <button onClick={startAutoCapture}>Start Auto Capture</button>
        <button onClick={startManualCapture}>Start Manual Capture</button>
        <button onClick={stopCapture}>Stop Capture</button>
      </div>
      {captureResult && (
        <div>
          <h2>Capture Result:</h2>
          <pre>{JSON.stringify(captureResult, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default MitekImageCapture;
