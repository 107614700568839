import React, { FC, memo, useEffect, useState } from 'react'
import { Typography, useTheme, TableCell, TableRow, TablePagination } from '@mui/material'
import {
  unityTheme,
  Box,
  DataTable,
  Skeleton,
  Button,
  Icon,
  Input,
  DatePicker
} from '@ntpkunity/controls'
import { DataTableWrap } from '../../../components'
import { Controller, useForm } from 'react-hook-form'
import {
  useGetContractsByOrderIds,
  useGetCompletedOrdersRequest,
  useGetContractsByDate
} from '@apis/dealer-workqueue.service'
// import { WORKQUEUE_SCREEN_COMPONENTS } from '@helpers/constants/constants'
import { useStoreContext } from '@store/storeContext'
import { initialDealerWorkQueueState, utcToLocal } from '@helpers/methods'
import dayjs from 'dayjs'
import { CustomerColumns, SortOrder, SortOrderFields, vehcileStatuses } from '@helpers/constants'
import { useNavigate } from 'react-router-dom'
import ChevronDown from '@public/assets/icons/chevron-down'

const TbodyRow: FC<any> = ({ data, heading, orderSubscriptions }) => {
  const navigate = useNavigate()
  const { actions } = useStoreContext()

  const orderSubscriptionObj: any =
    orderSubscriptions?.length > 0 &&
    orderSubscriptions?.find((obj: any) => data?.order?.identifier == obj?.external_order_id)

  const handleClick = (e: any) => {
    if (heading == 'Completed') {
      navigate('deal-completed/' + data?.order?.identifier, {
        state: {
          ...data,
          heading
        }
      })
      actions.setDealerCompletedOrders(data)
    } else {
      navigate('deal-cancelled/' + data?.order?.identifier, {
        state: {
          ...data,
          heading
        }
      })
      actions.setDealerCancelledOrders(data)
    }
    e.stopPropagation()
  }
  return (
    <>
      {data && Object.keys(data)?.length > 0 && (
        // {data && (
        <TableRow className="child-tr">
          <TableCell>{data?.order?.identifier}</TableCell>
          <TableCell>
            {data?.order?.asset?.year +
              ' ' +
              data?.order?.asset?.make +
              ' ' +
              data?.order?.asset?.model +
              ' ' +
              data?.order?.asset?.trim}
          </TableCell>
          <TableCell>{data?.full_name}</TableCell>
          <TableCell>{data?.order?.asset?.vin}</TableCell>
          <TableCell>
            {/* {heading == 'Completed' && data?.subscription_date} */}
            {data?.order?.asset?.stock_number}
          </TableCell>
          {heading == 'Completed' && (
            <>
              <TableCell>
                {orderSubscriptionObj?.start_date &&
                  utcToLocal(orderSubscriptionObj?.start_date, 'MM/DD/YYYY')}
              </TableCell>
              <TableCell>
                {orderSubscriptionObj?.end_date &&
                  utcToLocal(orderSubscriptionObj?.end_date, 'MM/DD/YYYY')}
              </TableCell>
            </>
          )}
          <TableCell className="action-cell fixed-right">
            <Button
              defaultBtn
              iconText={<Icon name="IcView" />}
              onClick={(e) => {
                handleClick(e)
              }}
              sx={{ position: 'relative' }}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

const OrdersListUi: FC<any> = ({ data, heading, ordersLoading }) => {
  const theme = useTheme()
  const { control, getValues } = useForm<any>()
  const { mutate: getCompletedOrders, isLoading: completedOrdersLoading } =
    useGetCompletedOrdersRequest()
  const [pagination, setPagination] = useState({ page: 0, size: 25 })
  const [orderSubscriptions, setOrderSubscriptionInfo] = useState([])
  const { mutate: getContractsByOrderIds } = useGetContractsByOrderIds()
  const { mutate: contractsSearch, isLoading: contractsDateSearchLoading } = useGetContractsByDate()

  const { actions, states } = useStoreContext()
  const [internalCalls, setInternalCalls] = useState<boolean>(true)
  const [pageOnHandle, setPageHandle] = useState<boolean>(false)

  useEffect(() => {
    if (data?.result && data?.result?.length > 0 && internalCalls) {
      // getCustomerData(data)
      getContractsData(data)
    }
  }, [data, internalCalls])

  const getContractsData = (data: any) => {
    const newOrderIdsArray: any = []
    data?.result?.forEach((resultItem: any) => {
      const orderIdentifier = resultItem?.order?.identifier
      if (orderIdentifier) {
        newOrderIdsArray.push(orderIdentifier)
      }
    })

    // Assuming `getContractsByOrderIds` takes an array of order identifiers
    getContractsByOrderIds(newOrderIdsArray, {
      onSuccess(res: any) {
        setOrderSubscriptionInfo(res)
      }
    })
  }
  const handleChangePage = (
    //@ts-ignore
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setPagination((prevPagination) => ({ ...prevPagination, page: newPage }))
    setPageHandle(true)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      size: +event.target.value,
      page: 0
    }))
    setPageHandle(true)
  }
  const subtractFiveHours = (dateString: string): string => {
    // Parse the input date string
    const date = dayjs(dateString)

    // Subtract 5 hours from the parsed date
    const result = date.subtract(5, 'hour')

    // Return the result as a string
    return result.format('YYYY-MM-DDTHH:mm:ss')
  }
  useEffect(() => {
    if (pageOnHandle) {
      vehicleSearch()
    }
  }, [pageOnHandle])

  const InternalSubscriptionParamsExistInSearch = () => {
    return getValues('subscription_start_date') || getValues('subscription_end_date')
  }
  const addConditionToRequestBody = (
    field: CustomerColumns,
    value: any,
    operator: string,
    requestBody: any[]
  ) => {
    // Push the condition object to the requestBody array
    requestBody.push({
      field,
      operator: operator,
      value,
      and_or: 'And'
    })
  }

  const vehicleSearch = () => {
    setPageHandle(false)
    let params = `page_size=${pagination?.size}&page_number=${pagination?.page}&sort=${SortOrder.desc}&sort_by=${SortOrderFields.order_updated_at}`
    let status = heading === 'Completed' ? vehcileStatuses?.COMPLETED : vehcileStatuses.CANCELED
    if (status == vehcileStatuses?.COMPLETED) {
      actions.setDealerCompletedOrders(initialDealerWorkQueueState)
    } else {
      actions.setDealerCancelledOrders(initialDealerWorkQueueState)
    }
    const requestBody: any[] = []
    const checkCondition =
      Object.keys(states?.selectedANDealer)?.length > 0 && states?.selectedANDealer?.id !== ''
    if (checkCondition) {
      addConditionToRequestBody(
        CustomerColumns.DEALER_ID,
        states?.selectedANDealer?.id,
        'Equals',
        requestBody
      )
    }
    if (!InternalSubscriptionParamsExistInSearch()) {
      if (status) {
        addConditionToRequestBody(CustomerColumns.Order_Status, status, 'Equals', requestBody)
      }
      if (getValues('orderIds')) {
        addConditionToRequestBody(
          CustomerColumns.ORDER_IDENTIFIER,
          getValues('orderIds').toUpperCase(),
          'Equals',
          requestBody
        )
      }
      if (getValues('stockNumbers')) {
        addConditionToRequestBody(
          CustomerColumns.Stock_Number,
          getValues('stockNumbers').toUpperCase(),
          'Equals',
          requestBody
        )
      }
      if (getValues('vins')) {
        addConditionToRequestBody(
          CustomerColumns.VIN,
          getValues('vins').toUpperCase(),
          'Equals',
          requestBody
        )
      }
      if (getValues('customer_name')) {
        addConditionToRequestBody(
          CustomerColumns.CUSTOMER_NAME,
          getValues('customer_name').toUpperCase(),
          'Contains',
          requestBody
        )
      }
      getOrdersData(params, requestBody)
    } else {
      let subscription_start_date = getValues('subscription_start_date')
        ? getValues('subscription_start_date')
        : ''
      let subscription_end_date = getValues('subscription_end_date')
        ? getValues('subscription_end_date')
        : ''

      let subscription_start_date_from = subtractFiveHours(
        dayjs(getValues('subscription_start_date')).startOf('day').format('YYYY-MM-DDTHH:mm:ss')
      )
      let subscription_start_date_to = dayjs(getValues('subscription_start_date'))
        .endOf('day')
        .format('YYYY-MM-DDT23:59:59')
      let subscription_end_date_from = subtractFiveHours(
        dayjs(getValues('subscription_end_date')).startOf('day').format('YYYY-MM-DDTHH:mm:ss')
      )
      let subscription_end_date_to = dayjs(getValues('subscription_end_date'))
        .endOf('day')
        .format('YYYY-MM-DDT23:59:59')
      let contract_params = subscription_start_date
        ? `subscription_start_date_from=${subscription_start_date_from}&subscription_start_date_to=${subscription_start_date_to}`
        : ''
      contract_params +=
        subscription_end_date && subscription_start_date
          ? `&subscription_end_date_from=${subscription_end_date_from}&subscription_end_date_to=${subscription_end_date_to}`
          : subscription_end_date && !subscription_start_date
          ? `subscription_end_date_from=${subscription_end_date_from}&subscription_end_date_to=${subscription_end_date_to}`
          : ''
      contractsSearch(
        { contract_params },
        {
          onSuccess(res: any) {
            if (res && res.length > 0) {
              if (status) {
                addConditionToRequestBody(
                  CustomerColumns.Order_Status,
                  status,
                  'Equals',
                  requestBody
                )
              }
              if (getValues('orderIds')) {
                addConditionToRequestBody(
                  CustomerColumns.ORDER_IDENTIFIER,
                  getValues('orderIds').toUpperCase(),
                  'Equals',
                  requestBody
                )
              }
              if (getValues('stockNumbers')) {
                addConditionToRequestBody(
                  CustomerColumns.Stock_Number,
                  getValues('stockNumbers').toUpperCase(),
                  'Equals',
                  requestBody
                )
              }
              if (getValues('vins')) {
                addConditionToRequestBody(
                  CustomerColumns.VIN,
                  getValues('vins').toUpperCase(),
                  'Equals',
                  requestBody
                )
              }
              if (getValues('customer_name')) {
                addConditionToRequestBody(
                  CustomerColumns.CUSTOMER_NAME,
                  getValues('customer_name').toUpperCase(),
                  'Contains',
                  requestBody
                )
              }
              const existingOrderIdentifierIndex = requestBody.findIndex(
                (condition) => condition.field === CustomerColumns.ORDER_IDENTIFIER
              )

              // If an existing condition for order_identifier is found, remove it from requestBody
              if (existingOrderIdentifierIndex !== -1) {
                requestBody.splice(existingOrderIdentifierIndex, 1)
              }
              const orderIds = res.map(
                (order: { external_order_id: any }) => order.external_order_id
              )

              // Getting the orderIds from getValues('orderIds')
              const orderIdsFromInput = getValues('orderIds')

              // Creating a combined array
              const combinedOrderIds = []

              // Add external_order_ids to the combined array
              combinedOrderIds.push(...orderIds)

              // Add orderIds from input to the combined array if it's not null or undefined
              if (orderIdsFromInput !== null && orderIdsFromInput !== undefined) {
                combinedOrderIds.push(orderIdsFromInput.toString().toUpperCase()) // Assuming orderIdsFromInput is a string
              }
              addConditionToRequestBody(
                CustomerColumns.ORDER_IDENTIFIER,
                combinedOrderIds,
                'Equals',
                requestBody
              )
              getOrdersData(params, requestBody)
            } else {
              actions.setDealerCompletedOrders(initialDealerWorkQueueState)
            }
          }
        }
      )
    }
  }
  const getOrdersData = (params: any, requestBody: any) => {
    setInternalCalls(false)
    const newOrderIdsArray: any = []
    getCompletedOrders(
      { params, requestBody },
      {
        onSuccess(response: any) {
          if (heading === 'Completed') {
            actions.setDealerCompletedOrders(response)
          } else {
            actions.setDealerCancelledOrders(response)
          }
          response &&
            response?.result?.length > 0 &&
            response?.result?.forEach((resultItem: any) => {
              const orderIdentifier = resultItem?.order?.identifier
              if (orderIdentifier) {
                newOrderIdsArray.push(orderIdentifier)
              }
            })
          getContractsByOrderIds(newOrderIdsArray, {
            onSuccess(res: any) {
              setOrderSubscriptionInfo(res)
            }
          })
        }
      }
    )
  }
  return (
    <DataTableWrap theme={unityTheme}>
      <Box theme={theme} className="scroll">
        <Box className="scroll-hide spr-border" theme={theme} />
      </Box>
      <DataTable
        theme={unityTheme}
        variant="basic"
        theadChildren={
          <>
            <TableRow>
              <TableCell>Deal ID</TableCell>
              <TableCell>Year/Make/Model/Trim</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>VIN</TableCell>
              <TableCell>Stock</TableCell>
              {heading == 'Completed' && (
                <>
                  <TableCell>Subscription Start Date</TableCell>
                  <TableCell>Subscription End Date</TableCell>
                </>
              )}
              <TableCell className="action-cell fixed-right" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell>
                <Box theme={unityTheme} className="table-filter">
                  <Controller
                    name="orderIds"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                      <Input
                        theme={theme}
                        fullWidth
                        type="text"
                        onChange={(e) => onChange(e)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setPagination((prevPagination) => ({
                              ...prevPagination,
                              page: 0
                            }))
                            setPageHandle(true)
                          }
                        }}
                        value={value}
                        placeholder="Search..."
                      />
                    )}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                    onClick={() => {
                      setPagination((prevPagination) => ({
                        ...prevPagination,
                        page: 0
                      }))
                      setPageHandle(true)
                    }}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={unityTheme} className="table-filter">
                  <Controller
                    name="year_make_model_trim"
                    control={control}
                    render={() => (
                      <Input theme={theme} fullWidth disabled type="text" placeholder="Search..." />
                    )}
                  />
                  <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={unityTheme} className="table-filter">
                  <Controller
                    name="customer_name"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                      <Input
                        theme={theme}
                        fullWidth
                        type="text"
                        onChange={(e) => onChange(e)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setPagination((prevPagination) => ({
                              ...prevPagination,
                              page: 0
                            }))
                            setPageHandle(true)
                          }
                        }}
                        value={value}
                        placeholder="Search..."
                      />
                    )}
                  />
                  <Button
                    onClick={() => {
                      setPagination((prevPagination) => ({
                        ...prevPagination,
                        page: 0
                      }))
                      setPageHandle(true)
                    }}
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={unityTheme} className="table-filter">
                  <Controller
                    name="vins"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                      <Input
                        theme={theme}
                        fullWidth
                        type="text"
                        onChange={(e) => onChange(e)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setPagination((prevPagination) => ({
                              ...prevPagination,
                              page: 0
                            }))
                            setPageHandle(true)
                          }
                        }}
                        value={value}
                        placeholder="Search..."
                      />
                    )}
                  />
                  <Button
                    onClick={() => {
                      setPagination((prevPagination) => ({
                        ...prevPagination,
                        page: 0
                      }))
                      setPageHandle(true)
                    }}
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={unityTheme} className="table-filter">
                  <Controller
                    name={'stockNumbers'}
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                      <Input
                        theme={theme}
                        fullWidth
                        type="text"
                        onChange={(e) => onChange(e)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setPagination((prevPagination) => ({
                              ...prevPagination,
                              page: 0
                            }))
                            setPageHandle(true)
                          }
                        }}
                        value={value}
                        placeholder="Search..."
                      />
                    )}
                  />
                  <Button
                    onClick={() => {
                      setPagination((prevPagination) => ({
                        ...prevPagination,
                        page: 0
                      }))
                      setPageHandle(true)
                    }}
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              {heading == 'Completed' && (
                <>
                  <TableCell>
                    <Box theme={unityTheme} className="table-filter">
                      <Controller
                        name="subscription_start_date"
                        control={control}
                        render={({ field: { value, onChange } }: any) => (
                          <DatePicker
                            theme={theme}
                            disablePortal={false}
                            minDate={dayjs().subtract(365 * 5, 'day')}
                            onChange={(e: any) => {
                              onChange(
                                e.getFullYear() === 1970 || e?.getFullYear() === 1969
                                  ? null
                                  : e.toISOString()
                              )
                              setPagination((prevPagination) => ({
                                ...prevPagination,
                                page: 0
                              }))
                              setPageHandle(true)
                            }}
                            // onKeyDown={(e: any) => e.preventDefault()}
                            value={value ?? ''}
                            inputFormat="MM/DD/YYYY"
                          />
                        )}
                      />
                      <Button
                        onClick={() => {
                          setPagination((prevPagination) => ({
                            ...prevPagination,
                            page: 0
                          }))
                          setPageHandle(true)
                        }}
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={unityTheme} className="table-filter">
                      <Controller
                        name="subscription_end_date"
                        control={control}
                        render={({ field: { value, onChange } }: any) => (
                          <DatePicker
                            theme={theme}
                            disablePortal={false}
                            onChange={(e: any) => {
                              onChange(
                                e.getFullYear() === 1970 || e?.getFullYear() === 1969
                                  ? null
                                  : e.toISOString()
                              )
                              setPagination((prevPagination) => ({
                                ...prevPagination,
                                page: 0
                              }))
                              setPageHandle(true)
                            }}
                            // onKeyDown={(e: any) => e.preventDefault()}
                            value={value ?? ''}
                            inputFormat="MM/DD/YYYY"
                          />
                        )}
                      />
                      <Button
                        onClick={() => {
                          setPagination((prevPagination) => ({
                            ...prevPagination,
                            page: 0
                          }))
                          setPageHandle(true)
                        }}
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                </>
              )}
              {/* <TableCell className="action-cell fixed-right fixed-cell"></TableCell> */}
              <TableCell className="action-cell fixed-right" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {!ordersLoading &&
              !completedOrdersLoading &&
              !contractsDateSearchLoading &&
              data?.result &&
              data?.result?.length > 0 &&
              data?.result.map((obj: any, index: any) => (
                <React.Fragment key={index}>
                  <TbodyRow
                    heading={heading}
                    data={obj}
                    orderSubscriptions={orderSubscriptions?.length > 0 && orderSubscriptions}
                  />
                </React.Fragment>
              ))}
            {(ordersLoading || completedOrdersLoading || contractsDateSearchLoading) &&
              [1]?.map((val) => (
                <React.Fragment key={val}>
                  <TableRow>
                    <TableCell colSpan={heading == 'Cancelled' ? 5 : 7}>
                      <Skeleton theme={theme} width={'100'} height={20} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={heading == 'Cancelled' ? 5 : 7}>
                      <Skeleton theme={theme} width={'100'} height={20} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={heading == 'Cancelled' ? 5 : 7}>
                      <Skeleton theme={theme} width={'100'} height={20} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={heading == 'Cancelled' ? 5 : 7}>
                      <Skeleton theme={theme} width={'100'} height={20} />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            {!ordersLoading &&
              !completedOrdersLoading &&
              !contractsDateSearchLoading &&
              data?.result &&
              data?.result?.length <= 0 && (
                <TableRow>
                  <TableCell colSpan={heading === 'Completed' ? 7 : 5} sx={{ textAlign: 'center' }}>
                    <Typography variant="caption" component={'span'}>
                      There is no record for now
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
          </>
        }
      />

      <TablePagination
        component="div"
        id="pagination"
        count={data && data?.total_results ? data?.total_results : 50}
        page={pagination?.page}
        rowsPerPage={pagination?.size}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        SelectProps={{
          IconComponent: ChevronDown
        }}
      />
    </DataTableWrap>
  )
}

export default memo(OrdersListUi)
