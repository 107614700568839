import { FinanceType, vatTreatment } from "./enums";

export const rentalCalculationMethods = [
  { key: "", value: "Annuity", text: "Annuity" },
  { key: "", value: "Annuity360", text: "Annuity - Actual/360" },
  { key: "", value: "Annuity365", text: "Annuity - Actual/365" },
  { key: "", value: "Flat", text: "Flat" },
  { key: "", value: "Equal Principal", text: "Equal Principal" },
  { key: "", value: "Flat Plus", text: "Flat Plus" },
];
export const rentalAndRepaymentCalculationMethods = [
  { key: "", value: "Annuity", text: "Annuity" },
  { key: "", value: "Annuity360", text: "Annuity - Actual/360" },
  { key: "", value: "Annuity365", text: "Annuity - Actual/365" },
  // { key: "", value: "Flat", text: "Flat" },
  { key: "", value: "Equal Principal", text: "Equal Principal" },
  // { key: "", value: "Flat Plus", text: "Flat Plus" },
];
export const rentalCalculationMethodsAPR = [
  { key: "", value: "Annuity", text: "Annuity" },
];
export const rentalCalculationMethodsForRVExtension = [
  { key: "", value: "Annuity", text: "Annuity" },
];
export const amortizationMethods = [
  { key: "", value: "Straight Line", text: "Straight Line" },
  {
    key: "",
    value: "Straight Line with Actual Number of Days",
    text: "Straight Line with Actual Number of Days",
  },
  {
    key: "",
    value: "Straight Line Pro Rata",
    text: "Straight Line Pro Rata",
  },
  {
    key: "",
    value: "Annuity - Actual No of Days",
    text: "Annuity - Actual No of Days",
  },
];
export const VatTreatment = [
  { key: "", value: "VATDeferral", text: "VAT Deferral" },
  { key: "", value: "VATPaidUpfront", text: "VAT Paid Up Front" },
  {
    key: "",
    value: "VATAmortizeOverTheTerm",
    text: "VAT Amortize Over The Term",
  },
];
export const rentalModes = [
  { key: "", value: "Advance", text: "Advance" },
  { key: "", value: "Arrear", text: "Arrear" },
];
export const subsidyTypes = [
  { key: "", value: "Rate", text: "Target Rate Base Subsidy" },
  { key: "", value: "Split", text: "% from Dealer and % from Manufacturer" },
];
export const rentalModesNumeric = [
  { key: "", value: "1", text: "Advance" },
  { key: "", value: "0", text: "Arrear" },
];
export const rentalFrequencies = [
  { key: "", value: "Monthly", text: "Monthly" },
  { key: "", value: "Quarterly", text: "Quarterly" },
  { key: "", value: "SemiAnnual", text: "Semi-Annual" },
  { key: "", value: "Annual", text: "Annual" },
];
export const rentalCalculationFrequencies = [
  { key: "", value: "Weekly", text: "Weekly" },
  { key: "", value: "Fortnightly", text: "Fortnightly" },
  { key: "", value: "Monthly", text: "Monthly" },
  { key: "", value: "Quarterly", text: "Quarterly" },
  { key: "", value: "SemiAnnual", text: "Semi-Annual" },
  { key: "", value: "Annual", text: "Annual" },
];
export const rentalFrequenciesNumeric = [
  { key: "", value: "0", text: "Monthly" },
  { key: "", value: "1", text: "Quarterly" },
  { key: "", value: "2", text: "Semi-Annual" },
  { key: "", value: "3", text: "Annual" },
];
export const yesNoValues = [
  { key: "", value: "true", label: "Yes" },
  { key: "", value: "false", label: "No" },
];
export const yesNo = [
  { key: "", value: true, text: "Yes" },
  { key: "", value: false, text: "No" },
];
export const deferredTaxValue = [
  { key: "", value: true, label: "Yes" },
  { key: "", value: false, label: "No" },
];
export const structureRentalTypes = [
  { key: "", value: "Structured", text: "Structured" },
  { key: "", value: "Interest Only", text: "Interest Only" },
  { key: "", value: "Auto", text: "Auto" },
  { key: "", value: "Zero", text: "Zero" },
];
export const quickQuotestructureRental = [
  { key: "", value: "Structured", text: "Structured" },
  { key: "", value: "Zero", text: "Zero" },
];
export const financialstructureRentalTypes = [
  { key: "", value: "Structured", text: "Structured" },
  { key: "", value: "Interest Only", text: "Interest Only" },
  { key: "", value: "Auto", text: "Auto" },
  { key: "", value: "Zero", text: "Zero" },
  { key: "", value: "Bonus Payment", text: "Bonus" },
  { key: "", value: "VatDeferral", text: "Vat Deferral" },
];

export const rentalComponentsNoTax = [
  { key: "", value: 0, text: "Rental" },
  { key: "", value: 1, text: "Interest" },
  { key: "", value: 2, text: "Principle" },
];

export const rentalPlanCalculation = [
  { key: "", value: "Annuity", text: "Annuity - 30/360" },
  { key: "", value: "Annuity360", text: "Annuity - Actual/360" },
  { key: "", value: "Annuity365", text: "Annuity - Actual/365" },
];

export const BaseRateSource = [
  { key: "", value: "LIBOR", text: "LIBOR" },
  { key: "", value: "SOFR", text: "SOFR" },
  { key: "", value: "SONIA", text: "SONIA" },
  { key: "", value: "EURIBOR", text: "EURIBOR" },
];

export const BaseRateFrequency = [
  { key: "", value: "30 Days", text: "30 Days" },
  { key: "", value: "90 Days", text: "90 Days" },
  { key: "", value: "180 Days", text: "180 Days" },
  { key: "", value: "1 Month", text: "1 Month" },
  { key: "", value: "3 Months", text: "3 Months" },
  { key: "", value: "6 Months", text: "6 Months" },
  { key: "", value: "12 Months", text: "12 Months" },
];

export const ETPenaltyReference = [
  { key: "", value: "None", text: "None" },
  { key: "", value: "PrincipalOutstanding", text: "Principal Outstanding" },
  { key: "", value: "FutureInterest", text: "Future Interest" },
  { key: "", value: "AssetPlusVAC", text: "Asset Plus VAC" },
];

export const rentalPenalty = [
  { key: "", value: "None", text: "None" },
  { key: "", value: "Percentage", text: "Percentage" },
  { key: "", value: "Amount", text: "Amount" },
  { key: "", value: "NoOfDaysInterest", text: "No Of Days Interest" },
];

export const ptrentalValue = [
  { key: "", value: "Paid", text: "Paid" },
  { key: "", value: "OverDue", text: "OverDue" },
  { key: "", value: "Unpaid", text: "Unpaid" },
];

export const ptrentalInclusiveOROverdues = [
  { key: "", value: "Paid", text: "Paid" },
  { key: "", value: "OverDue", text: "OverDue" },
];

export const ETCalculationMethods = [
  { key: "", value: "DailyFutureInterest", text: "Daily Future Interest" },
  { key: "", value: "EffectiveAnnuity", text: "Effective Annuity" },
  { key: "", value: "NetReceivables", text: "Net Receivables" },
];

export const PurchaseAndReturn = [
  { key: "", value: "Purchase", text: "Purchase" },
  { key: "", value: "Return", text: "Return" },
];
export const componentName = [
  { key: "", value: "Main", text: "Main" },
  { key: "", value: "Associate", text: "Associated Component" },
];
export const stepRentals = [
  { key: "", value: "StepUp", text: "Step Up" },
  { key: "", value: "StepDown", text: "Step Down" },
];
export const incomePosting = [
  { key: "", value: true, label: "Yes" },
  { key: "", value: false, label: "No" },
];

export const accessToken = "settings";
export const exportInWordUrl = "/documents/word/export-in-word";
export const exportDetail =
  "No Template found of this tenant_id and template_type";

export const PaymentModes = [
  { value: "Advance", text: "Advance" },
  { value: "Arrear", text: "Arrear" },
];
export const FinanceTypes = [
  { value: FinanceType.HirePurchase, text: "Hire Purchase" },
  { value: FinanceType.FinanceLease, text: "Finance Lease" },
];
export const PaymentFrequencies = [
  { value: "Weekly", text: "Weekly" },
  { value: "Fortnightly", text: "Fortnightly" },
  { value: "Monthly", text: "Monthly" },
  { value: "Quarterly", text: "Quarterly" },
  { value: "SemiAnnual", text: "Semi-Annual" },
  { value: "Annual", text: "Annual" },
];
export const FeeFrequencies = [
  { value: "OneTime", text: "One Time" },
  { value: "Weekly", text: "Weekly" },
  { value: "Fortnightly", text: "Fortnightly" },
  { value: "Monthly", text: "Monthly" },
  { value: "Quarterly", text: "Quarterly" },
  { value: "SemiAnnual", text: "Semi-Annual" },
  { value: "Annual", text: "Annual" },
];
export const RentalTypes = [
  { value: "Auto", text: "Auto" },
  { value: "Structured", text: "Structured" },
];
export const VATTreatments = [
  { value: vatTreatment.VATPaidUpfront, text: "VAT Upfront" },
  { value: vatTreatment.VATDeferral, text: "VAT Deferred" },
];
export const CommissionTypes = [
  { value: 0, text: "None" },
  { value: 1, text: "Percentage" },
  { value: 2, text: "Amount" },
];
export const BalloonCollectionsAdvance = [
  { value: 1, text: "1 Month after last Payment" },
  { value: 2, text: "2 Month after last Payment" },
  { value: 3, text: "3 Month after last Payment" },
  { value: 4, text: "4 Month after last Payment" },
  { value: 5, text: "5 Month after last Payment" },
  { value: 6, text: "6 Month after last Payment" },
];
export const BalloonCollectionsArrear = [
  { value: 0, text: "With Last Payment" },
  { value: 1, text: "1 Month after last Payment" },
  { value: 2, text: "2 Month after last Payment" },
  { value: 3, text: "3 Month after last Payment" },
  { value: 4, text: "4 Month after last Payment" },
  { value: 5, text: "5 Month after last Payment" },
  { value: 6, text: "6 Month after last Payment" },
];
export const StepCalculationMethods = [
  { key: "", value: "Annuity - 30/360", text: "Annuity - 30/360" },
  { key: "", value: "Annuity - Actual/360", text: "Annuity - Actual/360" },
  { key: "", value: "Annuity - Actual/365", text: "Annuity - Actual/365" },
];
