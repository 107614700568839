import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const LeadSummaryStatusSectionWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.lead-summary-status-section': {
    borderRadius: 8,
    display: 'flex',
    alignItems: 'start',
    alignSelf: 'stretch',
    border: '1px solid',
    borderColor: theme.palette.grey[100],
    '.section': {
      display: 'flex',
      alignItems: 'start',
      flexDirection: 'column',
      justifyContent: 'center',
      borderRight: '1px solid',
      borderColor: theme.palette.grey[100],
      position: 'relative',
      padding: '8px 16px',
      flex: '1 0 0',
      height: 64,
      '.icon': {
        backgroundColor: theme.palette.background.default,
        position: 'absolute',
        right: '-13px',
        path: {
          stroke: theme.palette.grey[200]
        }
      },
      '.inner-box': {
        width: '100%',
        textAlign: 'center',
        borderRadius: 8,
        padding: '8px 0',
        '&.selected': {
          backgroundColor: theme.customVariables.lightGrey
        },
        '&.error': {
          color: theme.palette.error.main
        },
        '.subtitle': {
          color: theme.palette.grey[600]
        },
        '.text-truncated': {
          overflow: 'hidden',
          textAlign: 'center',
          textOverflow: 'ellipsis',
          display: 'block',
          whiteSpace: 'nowrap',
          maxWidth: 130,
          margin: 'auto'
        }
      },
      '&:last-child': {
        borderRight: 'none',
        '.icon': {
          display: 'none'
        }
      }
    }
  }
}))
