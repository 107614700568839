import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const LiveActionWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.live-action-wrap': {
    '.action-list-wrap': {
      overflowY: 'auto',
      overflowX: 'hidden',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: 7,
        height: 7
      },
      '&::-webkit-scrollbar-track': {
        padding: '0 1px',
        backgroundColor: '#f0f0f0'
      },
      '&::-webkit-scrollbar-thumb': {
        transition: 'all 0.3s',
        backgroundColor: '#cdcdcd'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a6a6a6'
      }
    },
    '.all-auctions': {
      display: 'flex',
      gap: 16,
      padding: '16px 0',
      '.auction-details': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        color: theme.palette.grey[600],
        '.separator': {
          position: 'relative',
          width: 3,
          height: 3,
          margin: '0 8px',
          ':before': {
            content: '""',
            position: 'absolute',
            width: 3,
            height: 3,
            borderRadius: '50%',
            backgroundColor: '#cccccc'
          }
        }
      }
    },
    '.divider': {
      textTransform: 'uppercase',
      span: {
        marginBottom: 0
      }
    }
  }
}))
