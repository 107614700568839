import { useTheme } from '@mui/material'
import { Box, Typography } from '@ntpkunity/controls'
import { LayoutWithSideNav } from '@styles/layout'
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import { FC } from 'react'
import { DashboardStyle } from './dashboard.style'

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Title,
  Legend
)

const CustomerJourneyAnalytics: FC = () => {
  const theme = useTheme()

  return (
    <LayoutWithSideNav theme={theme}>
      <DashboardStyle theme={theme} className="content-wrap">
        <Typography theme={theme} component="h2" variant="h2" sx={{ mt: 3 }}>
          Customer Journey Analytics
        </Typography>
        <Box theme={theme} sx={{ opacity: 0.3, mt: 3, mb: 3 }}>
          <hr />
        </Box>
        <iframe
          width="100%"
          height="2480"
          title="dashboard"
          style={{ border: '1px solid #EBECF2', borderRadius: '8px' }}
          src="https://lookerstudio.google.com/embed/reporting/3b0f9d66-4bbf-4316-82e0-c42d3388170f/page/a0jcD"
        ></iframe>
      </DashboardStyle>
    </LayoutWithSideNav>
  )
}

export default CustomerJourneyAnalytics
