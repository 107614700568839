import integrationData from "utilities/IntegrationData";

const integrationsData: any = integrationData;

export const debounce = (func: (...args: any) => void, delay: number) => {
  let timer: any;
  return function <Type>(this: any, ...args: Type[]) {
    const that = this as unknown as any;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(that, args);
    }, delay);
  };
};

export const getProviderName = (integrationType, provider) => {
  return integrationsData.integration_types
    .find((type) => type.integration_type === integrationType)
    ?.provider_name?.find((prov) => prov.value === provider)?.name;
};

export const getQueryParamsString = <T extends {} = { [key: string]: string }>(
  params: T
) => {
  const queryString = Object.keys(params)
    .filter((key) => !!params[key] || params[key] === 0)
    .map((key) => {
      if (Array.isArray(params[key])) {
        if (params[key].length) {
          const arrayParams = params[key].map((ele) => {
            return `${key}=${encodeURIComponent(ele)}`;
          });

          return arrayParams.join("&");
        } else {
          return null;
        }
      }
      return `${key}=${encodeURIComponent(params[key])}`;
    })
    .filter(Boolean)
    .join("&");
  return queryString ? `?${queryString}` : "";
};
