import { useTheme } from "@mui/material"
import { Popup } from "@components"
import { Box, Button, Dialog, Icon, Typography } from "@ntpkunity/controls"
import { PopupContent } from "./bypass-verification-popup.style"
import { useAppContext } from "@app/context-provider"

export const ByPassVerificationPopup = ({onChatClick,openPopup,dealerProfile}) => {
    const theme = useTheme()
    const { state: appState } = useAppContext();
    const PLACEHOLDERS = appState.language.placeholders;

    return(
        
            <Popup>
                <Dialog
                    theme={theme}
                    size="sm"
                    open={true}
                    onCloseDialog={()=>openPopup(false)}
                    disablePortal
                    noFooter
                >
                    <PopupContent theme={theme} className="popup-content">
                        <Typography theme={theme} variant="h3" component={"h3"} mt={2}>{PLACEHOLDERS.LICENSE_HEADER}</Typography>
                        <Typography theme={theme} variant="body2" component={"p"} mt={2}>
                        {PLACEHOLDERS.LICENSE_POPUP_DETAIL}
                        </Typography>
                        <Box theme={theme} className="action-btns" mt={3}>
                            <Button
                                theme={theme}
                                secondary
                                text={dealerProfile}
                                startIcon={<Icon name='CallIcon' />}
                                onClick={() => {
                                if (dealerProfile) {
                                    window.open(`tel:${dealerProfile}`, "_blank");
                                }
                                 }}
                                fullWidth
                            />
                            <Button
                                theme={theme}
                                secondary
                                text={PLACEHOLDERS.SUBMIT_ORDER_START_CHAT}
                                startIcon={<Icon name='StartChatIcon' />}
                                onClick={onChatClick}
                                fullWidth
                            />
                        </Box>
                    </PopupContent>
                </Dialog>
            </Popup>
    
    )
}


