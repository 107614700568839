import { useTheme } from '@mui/material'
import {
  CartSummaryMobile,
  CartSummaryWrap
} from './selectedProductsPreviewStyle'
import { Accordion, Box, Button, Skeleton, Typography } from '@ntpkunity/controls'
import { useGetAllCarts } from '@modules/product/services/cart/cartService'
import { useStoreContext } from 'store/storeContext'
import { useGetProductPricing } from '@modules/product'
import { CheckoutItemList } from '@shared/components/Cart/CheckoutItemList'
import { type FC, useEffect, useState } from 'react'
import {
  ButtonType,
  className,
  DialogHeading,
  IconType,
  IS_LOGGED_IN,
  packages,
  CART_DATA,
  PaymentMethod,
  STRIPE_USER_CREATED
} from '@shared/constants'
import {
  convertToAmountWithOutSign,
  generateSuccessMessageForCheckout,
  getItem,
  getItemByKey,
  removeItembyKey,
  setItem,
  stripeAmount
} from '@shared/helper'
import {
  useGetBillingAddress,
  usePlaceOrder
} from '@modules/checkout/services'
import { useGetCards } from '@modules/billingDetails'
import { type IProductDetailsPayload, type ICart, type IPrice } from '@shared/typings'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'
import { ConfirmationDialog } from '@shared/components'
import { useModal } from 'react-modal-hook'
import DisableLoader from '@shared/assets/images/loader-disabled-btn.gif'
import EmptyCartUI from './emptyCartUI'
interface ISelectedProductsPreviewComponent {
  isSelectedTabBankTransfer: boolean
  paymentFrequency: string
}

export const SelectedProductsPreviewComponent: FC<ISelectedProductsPreviewComponent> = ({ isSelectedTabBankTransfer, paymentFrequency }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { data: billingData } = useGetBillingAddress()
  const isLoggedIn = getItem(IS_LOGGED_IN) as unknown as boolean
  const [isDisable, setIsDisable] = useState<boolean>(!isSelectedTabBankTransfer)
  const [totalPrice, setTotalPrice] = useState<number>()
  const { data: carts, isFetching } = useGetAllCarts(isLoggedIn)
  const { data: allPricing, isLoading: isPricingLoading } =
    useGetProductPricing()
  const {
    mutate: placeOrder,
    error,
    isLoading: placeOrderLoader
  } = usePlaceOrder()
  const { data: cards } = useGetCards()
  const cardId = cards?.data?.find((card) => card.is_default)?.id

  const {
    states: { cart },
    actions: { setToaster, addMultipleItemsToCart }
  }: any = useStoreContext()
  useEffect(() => {
    if ((error ?? '').length > 0) {
      setToaster({
        isDisplay: true,
        message: error,
        type: 'error'
      })
    }
  }, [error])

  interface ITempCart {
    id: number
    price_id: number
    amount: number
    product_id: number
    account_type: string
    product_name: string
    package_name: string
    all_packages: IPrice[]
  }

  useEffect(() => {
    const data = getItemByKey(CART_DATA)
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!((carts as ICart[])?.length > 0) && data) {
      addMultipleItemsToCart(data.cartlist)
      removeItembyKey(CART_DATA)
    }
  }, [])

  useEffect(() => {
    if (isLoggedIn && !(isFetching) && !isPricingLoading) {
      const arr: ITempCart[] = []
      carts?.forEach((item: any) => {
        const {
          id,
          account_type: accountType,
          product_name: productName,
          product_id: productId,
          price_id: priceId,
          name,
          amount
        } = item
        const res = allPricing
          ?.filter(
            (prod) =>
              prod.product_id === item.product_id &&
              packages.includes(prod.name)
          )
          ?.sort((a, b) => a?.package_order - b?.package_order)
        const obj = {
          id,
          price_id: priceId,
          amount,
          product_id: productId,
          account_type: accountType,
          product_name: productName,
          package_name: name,
          all_packages: res
        }
        arr.push(obj)
      })
      addMultipleItemsToCart(arr)
    }
  }, [carts, allPricing])

  useEffect(() => {
    const hasCartItems = (cart?.cart as ICart[])?.length > 0
    const hasBillingData = billingData != null && Object.keys(billingData)?.length > 0
    const hasSelectedCard = (cardId ?? '').length > 0

    if (isSelectedTabBankTransfer || (hasCartItems && hasBillingData && hasSelectedCard)) {
      setIsDisable(false)
    } else {
      setIsDisable(true)
    }
  }, [cart?.cart, billingData, cardId, placeOrderLoader, isSelectedTabBankTransfer])

  useEffect(() => {
    if (placeOrderLoader) {
      setIsDisable(true)
    }
  }, [placeOrderLoader])

  const onConfirm = (): void => {
    navigate(APP_ROUTES.CUSTOMER_ACCOUNT)
  }

  const [show] = useModal(
    () => (
      <ConfirmationDialog
        btnClassName={className.checkoutCongratulaionsPopupContinueBtn}
        buttonText={ButtonType.CONTINUE}
        iconName={IconType.SUCCESS}
        title={DialogHeading.CONGRATULATIONS}
        message={generateSuccessMessageForCheckout(isSelectedTabBankTransfer, cart?.cart?.length)}
        onConfirm={onConfirm}
      />
    ),
    [onConfirm]
  )

  const payNow = (): void => {
    const orderDetail: any = cart?.cart?.reduce((agg: IProductDetailsPayload[], cart: ICart) => {
      return [
        ...agg,
        {
          product_id: cart.product_id,
          price_id: cart.price_id
        }
      ]
    }, [])

    const cardData = {
      payment_type: PaymentMethod.CARD,
      is_new_card: 'FALSE',
      card_id: cardId
    }

    const bankTransferData = {
      payment_type: PaymentMethod.BANK_TRANSFER,
      payment_frequency: paymentFrequency
    }

    const data = {
      order_detail: orderDetail,
      billing_email: billingData?.email,
      ...(isSelectedTabBankTransfer ? bankTransferData : cardData)
    }

    placeOrder(data, {
      onSuccess: () => {
        setItem(STRIPE_USER_CREATED, true)
        show()
      }
    })
  }

  return (
    <>
      <CartSummaryWrap theme={theme} className="cart-summary-wrap">
        <Box theme={theme} display={{ xs: 'none', md: 'block' }}>
          {isFetching
            ? <>
          <CheckoutItemList
            isLoading={isFetching}
            setTotalPrice={setTotalPrice}
            cart={cart?.cart}
            className={className.checkoutPayNowBtn}
                buttonText={placeOrderLoader ? 'Wait...' : 'Finish Checkout'}
            onClickHandle={payNow}
            buttonDisplay
            isDisable={isDisable}
            startIcon={
              placeOrderLoader ? <img src={DisableLoader} alt="Loader" /> : null
            }
          />
          <Box theme={theme} mt={3}>
            <Skeleton width={'100%'} height={88.8} theme={theme} />
          </Box>
          </>
            : cart?.cart?.length > 0 || (carts as ICart[])?.length > 0
              ? <>
              <CheckoutItemList
                setTotalPrice={setTotalPrice}
                cart={cart?.cart?.length > 0 ? cart?.cart : carts}
                className={className.checkoutPayNowBtn}
                  buttonText={placeOrderLoader ? 'Wait...' : 'Finish Checkout'}
                onClickHandle={payNow}
                buttonDisplay
                isDisable={isDisable}
                startIcon={
                  placeOrderLoader ? <img src={DisableLoader} alt="Loader" /> : null
                }
              />
                {
                  !isSelectedTabBankTransfer
                    ? (
                      <Box theme={theme} mt={3}>
                        <Typography
                          className="text-muted"
                          theme={theme}
                          variant="caption"
                          component="small"
                        >
                          <strong>First 30 days will be free of charge!</strong> <br />
                          By proceeding, you authorize us or our third party vendor(s) to make payment after 30 days are lapsed.
                        </Typography>
                      </Box>
                      )
                    : null
                }
            </>
              : <EmptyCartUI />
          }
        </Box>
        <CartSummaryMobile
          theme={theme}
          className="cart-summary-mobile"
          display={{ xs: 'block', md: 'none' }}
        >
          {cart?.cart?.length > 0
            ? <>
              <Accordion
                theme={theme}
                varient="filters"
                items={[
                  {
                    content: (
                      <CheckoutItemList
                        setTotalPrice={setTotalPrice}
                        cart={cart?.cart}
                        buttonText={'Finish Checkout'}
                        onClickHandle={payNow}
                        buttonDisplay={false}
                      />
                    ),
                    title: 'Show Summary',
                    key: 'string',
                    isExpanded: false,
                    subtitle: (
                      <>
                        <Box theme={theme} className="cart-item item-total">
                          <Box theme={theme} className="price-total">
                            <Typography
                              theme={theme}
                              textAlign={'right'}
                              className="text-primary total-value"
                              component="h4"
                              variant="h4"
                            >
                              <span className="text-dark">$ </span>{' '}
                              {convertToAmountWithOutSign(
                                stripeAmount(totalPrice as number) ?? 0
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )
                  }
                ]}
              />
              <Box theme={theme} mt={2}>
                <Button
                  theme={theme}
                  primary
                  fullWidth
                  onClick={payNow}
                  text={placeOrderLoader ? 'Wait...' : 'Finish Checkout'}
                  disabled={isDisable}
                  startIcon={
                    placeOrderLoader
                      ? (
                      <img src={DisableLoader} alt="Loader" />
                        )
                      : null
                  }
                />
              </Box>
            </>
            : <EmptyCartUI />
          }
        </CartSummaryMobile>
      </CartSummaryWrap>
    </>
  )
}
