import { ReactNode } from "react";
import "../../../styles/components/_tag-label.scss";
import clsx from "clsx";

export const TagLabel = ({
  text,
  type,
  withIcon,
  iconName,
  variant = "dark",
}: {
  text?: string | ReactNode;
  type?: "success" | "warning" | "error" | "primary" | "secondary" | "default";
  withIcon?: boolean;
  iconName?: string;
  variant?: "dark" | "light";
}) => {
  return (
    <span
      className={clsx({
        "tag-label": true,
        "flag-label": withIcon,
        "tag-label--success": type === "success",
        "tag-label--warning": type === "warning",
        "tag-label--primary": type === "primary",
        "tag-label--secondary": type === "secondary",
        "tag-label--default": type === "default",
        "tag-label--error": type === "error",
        "tag-label--variant-light": variant === "light",
      })}
    >
      {withIcon && (
        <div
          className="bmw-icon bmw-icon--before data-icon"
          role="img"
          aria-label="Flag Label Icon"
          data-icon={iconName}
        />
      )}
      <p
        className={clsx({
          "tag-label__text text--status": true,
        })}
      >
        {text}
      </p>
    </span>
  );
};
