import { FinanceLeasePricingDrawerContentIntro } from "./fl-dealer-pricing/fl-dealer-pricing-intro/fl-dealer-pricing-intro.component";
import { FinanceLeasePricingDrawerContentIntroError } from "./fl-dealer-pricing/fl-dealer-pricing-intro/fl-dealer-pricing-intro-error.component";
import { FinanceLeasePricingDrawerContentInfo } from "./fl-dealer-pricing/fl-dealer-pricing-info/fl-dealer-pricing-info.component";
import { FinanceLeasePricingDrawerContentInfoError } from "./fl-dealer-pricing/fl-dealer-pricing-info/fl-dealer-pricing-info-error.component";
import { FinanceLeasePricingDrawerContentApr } from "./fl-dealer-pricing/fl-dealer-pricing-apr/fl-dealer-pricing-apr.component";
import { FinanceLeaseAuthenticatedUserInfo } from "./fl-authenticated-user/fl-authenticated-user-info/fl-authenticated-user-info.component";
import { FinanceLeaseAuthenticatedUserApr } from "./fl-authenticated-user/fl-authenticated-user-apr/fl-authenticated-user-apr.component";
import { FinanceLeaseAuthenticatedUserCreditFrozen } from "./fl-authenticated-user/fl-authenticated-user-credit/fl-authenticated-user-credit-frozen.component";
import { FinanceLeaseAuthenticatedUserCreditUnFrozen } from "./fl-authenticated-user/fl-authenticated-user-credit/fl-authenticated-user-credit-unfrozen.component";

import "./fl-pricing-drawer-content.scss";

export const FinanceLeasePricingDrawerContent = () => {
  return (
    <>
      <FinanceLeasePricingDrawerContentIntro />
      {/* <FinanceLeasePricingDrawerContentIntroError /> */}
      {/* <FinanceLeasePricingDrawerContentInfo /> */}
      {/* <FinanceLeasePricingDrawerContentInfoError /> */}
      {/* <FinanceLeasePricingDrawerContentApr /> */}
      {/* <FinanceLeaseAuthenticatedUserInfo /> */}
      {/* <FinanceLeaseAuthenticatedUserApr /> */}
      {/* <FinanceLeaseAuthenticatedUserCreditFrozen /> */}
      {/* <FinanceLeaseAuthenticatedUserCreditUnFrozen /> */}
    </>
  );
};
