import { useTheme } from '@mui/material'
import {} from './AddPackageStyle'
import {
  AddPackageList,
  AddPackagePageHeaderComponent,
} from '@modules/AddPackage/components'

export const AddPackagePage = () => {
  const theme = useTheme()

  return (
    <>
      <AddPackagePageHeaderComponent />
      <AddPackageList />
    </>
  )
}
