import { useTheme } from "@mui/material";
import { DashboardPageHeader } from "@src/components/dashboard";
import { DashboardStacks } from "@src/components/dashboard/dashboard-stack/dashboard-stack";
import { LayoutWithSideNavComponent } from "@src/layouts";

export const DashboardPage = () => {
  const theme = useTheme();
  return (
    <LayoutWithSideNavComponent theme={theme}>
      <DashboardPageHeader />
      <DashboardStacks />
    </LayoutWithSideNavComponent>
  );
};
