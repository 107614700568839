import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import { Grid, Box, Typography } from '@ntpkunity/controls'
import { PageHeader, ProductBanner } from 'components'
import {
  useGetSingleOrderRequest,
  useGetOdometerRequest,
  useGetContracts
} from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'

const CardBanner: FC<{ setOrderDataLoading?: any; setContractsData?: any }> = ({
  setOrderDataLoading,
  setContractsData
}) => {
  const theme = useTheme()
  const location = useLocation()
  const { id } = useParams()
  const { mutate: getContractsByOrderIds } = useGetContracts()
  const { actions, states } = useStoreContext()
  const [orderData, setOrderData] = useState({
    dealNumber: '',
    metadata: {
      year: '',
      make: '',
      model: '',
      trim: '',
      stockNumber: '',
      storeName: '',
      stockType: '',
      vin: '',
      odometerAtOrderCreation: '',
      odometerReportedByDealer: '',
      description: '',
      vehicle_type: '',
      vehicleImages: ['']
    },
    fulfillment: {
      dealerAddress: { address1: '', city: '', state: '', zip: '' }
    }
  })
  //@ts-ignore
  const { mutate: getOrderRequest, data } = useGetSingleOrderRequest()
  //@ts-ignore
  const { mutate: getOdometerData, data: odometerData } = useGetOdometerRequest()

  useEffect(() => {
    orderDataSet()
    contractData()
  }, [])
  useEffect(() => {
    if (states?.dealerAndLenderInfo && Object.keys(states?.dealerAndLenderInfo).length > 0) {
      getOdometerData({
        company_id: states?.dealerAndLenderInfo?.company_id
          ? states?.dealerAndLenderInfo?.company_id
          : states?.dealerAndLenderInfo?.id
      })
    }
  }, [states?.dealerAndLenderInfo])

  const orderDataSet = () => {
    getOrderRequest(
      { order_id: id },
      {
        onSuccess(_response: any) {
          actions.setDealerSelectedOrderData(_response?.orders[0])
          setOrderData({
            ..._response?.orders[0],
            dealNumber: _response?.orders[0].dealNumber,
            fulfillment: _response?.orders[0]?.fulfillment
          })
          setOrderDataLoading(true)
        }
      }
    )
  }
  const contractData = () => {
    getContractsByOrderIds(id, {
      onSuccess(res: any) {
        setContractsData(res)
      }
    })
  }

  const showNow =
    location?.pathname.includes('/work-queue/checklist/') ||
    location?.pathname.includes('/work-queue/schedule-pickup/')
  let currentURL = window.location.href

  // Check if the URL includes 'completed'
  let isCompleted = currentURL.includes('completed')
  let isCancelled = currentURL.includes('cancelled')

  return (
    <>
      {isCancelled || isCompleted ? (
        <PageHeader className="main-page-header border-bottom" theme={theme}>
          <Box flexGrow={'1'} theme={theme}>
            <Typography theme={theme} variant="h2" component="h2" className="title">
              {isCancelled ? 'Deal Cancelled' : 'Deal Completed'}
            </Typography>
            <Typography theme={theme} variant="body2" component="span" className="caption">
              {isCancelled
                ? 'This deal has been cancelled. Cancelled Details will be shown here.'
                : 'This deal has been completed.'}
            </Typography>
          </Box>
        </PageHeader>
      ) : null}
      <ProductBanner theme={theme} className="product-banner">
        <Box theme={theme} className="pb-wrap">
          <Box theme={theme} className="thumbnail">
            <img
              src={
                orderData?.metadata?.vehicleImages?.length > 0
                  ? orderData?.metadata?.vehicleImages[0]?.replace('[w]x[h]', '166x124')
                  : ''
              }
              alt=""
            />
          </Box>
          <Box theme={theme} className="product-details">
            <Grid theme={theme} container columnSpacing={2}>
              <Grid theme={theme} item sm={6} xs={12}>
                <Box theme={theme} className="title-lg">
                  {'DEAL ID: ' + (orderData?.metadata?.vin && id)}
                </Box>
                <Box theme={theme} className="title-md">
                  {orderData?.metadata?.year +
                    ' ' +
                    orderData?.metadata?.make +
                    ' ' +
                    orderData?.metadata?.model +
                    ' ' +
                    orderData?.metadata?.trim}
                </Box>
                <Box theme={theme} className="title-sm">
                  Stock: <b>{orderData?.metadata?.stockNumber}</b>
                </Box>
                <Box theme={theme} className="title-sm">
                  VIN: <b>{orderData?.metadata?.vin}</b>
                </Box>
                <Box theme={theme} className="title-sm">
                  Odometer Reading:{' '}
                  <b>
                    {orderData && orderData?.metadata?.odometerReportedByDealer
                      ? orderData?.metadata?.odometerReportedByDealer
                      : orderData && orderData?.metadata?.odometerAtOrderCreation
                      ? orderData && orderData?.metadata?.odometerAtOrderCreation
                      : '-'}
                  </b>
                </Box>
                <Box theme={theme} className="title-sm">
                  Vehicle Type: <b>{orderData?.metadata?.stockType}</b>
                </Box>
                {showNow && (
                  <Box theme={theme} className="title-sm">
                    Deal Number: <b>{orderData?.dealNumber ?? '-'}</b>
                  </Box>
                )}
              </Grid>
              <Grid theme={theme} item sm={6} xs={12} textAlign="right">
                <Box theme={theme} className="actions-wrap">
                  <Box theme={theme} className="action-details">
                    <Box
                      theme={theme}
                      className={
                        location.pathname.includes('/lane/dealer/work-queue/checklist/')
                          ? ''
                          : 'top-hide'
                      }
                    ></Box>
                    <Box theme={theme} className="title-sm">
                      Dealership: <b>{orderData?.metadata?.storeName}</b>
                    </Box>
                    <Box theme={theme} className="title-sm">
                      Location:{' '}
                      <b>
                        {orderData?.fulfillment?.dealerAddress &&
                          orderData?.fulfillment?.dealerAddress?.address1 +
                            ', ' +
                            orderData?.fulfillment?.dealerAddress?.city +
                            ', ' +
                            orderData?.fulfillment?.dealerAddress?.state +
                            ' ' +
                            orderData?.fulfillment?.dealerAddress?.zip}
                      </b>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </ProductBanner>
    </>
  )
}

export default CardBanner
