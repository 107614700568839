import { AdvanceDocumentEditor, Header } from "@modules/components";
import { useUploadTemplate } from "@modules/services/configureTemplateService";
import { Slide, useTheme } from "@mui/material";
import {
  Box,
  Button,
  Icon,
  Input,
  Select,
  Snackbar,
  Switch,
} from "@ntpkunity/controls";
import { APP_ROUTES } from "@router/path";
import { TEMPLATE_NAME_REGEX } from "@shared/constants";
import { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CreateTemplatePageWrap } from "./createTemplatePageStyle";
interface Template {
  template?: {
    template_name: string;
    template_html: string;
    template_type: string;
    is_active: boolean;
  };
  template_base64?: string;
  isEdit?: boolean;
}

const CreateTemplatePage: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const template = location?.state as Template;
  const dealerCode = new URLSearchParams(location.search).get("dealer_code");
  const apiKey = new URLSearchParams(location.search).get("x-api-key");
  const [template_html, setTemplateHtml] = useState(
    template?.template?.template_html
      ? JSON.parse(template?.template?.template_html)
      : ""
  );
  const [checked, setChecked] = useState(
    template?.template?.is_active ?? false
  );
  const [bookmarkHidden, setBookmarkHidden] = useState(false);
  const [search, setSearch] = useState(
    template?.template?.template_name.replace(/\.[^/.]+$/, "") ?? ""
  );
  const [templateType, setTemplateType] = useState(
    template?.template?.template_type ?? "quote_summary_hire_purchase"
  );
  const [templateBase64, setTemplateBase64] = useState(
    template?.template_base64 ?? ""
  );
  const [toastData, setToastData] = useState({
    toastLoading: false,
    toastMessage: "",
    variant: "",
  });

  const { mutate: uploadTemplate } = useUploadTemplate();

  const appendParamsToUrl = (path: string) => {
    const url = new URL(path, window.location.href);
    if (dealerCode) {
      url.searchParams.append("dealer_code", dealerCode);
    }
    if (apiKey) {
      url.searchParams.append("x-api-key", apiKey);
    }
    return url.pathname + url.search;
  };
  

  const getDocType = (docType: any): string => {
    switch (docType.pop()) {
      case "txt":
        return "SMS";
      case "html":
        return "Email";
      case "docx":
        return "Word";
      default:
        return "";
    }
  };

  const [docType, setDocType] = useState(
    template?.isEdit
      ? getDocType(template.template.template_name.split("."))
      : "Word"
  );

  const getFileExtension = (docType) => {
    switch (docType) {
      case "SMS":
        return ".txt";
      case "Email":
        return ".html";
      case "Word":
        return ".docx";
      default:
        return "";
    }
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastData({
      toastLoading: false,
      toastMessage: "",
      variant: "",
    });
  };

  const handleUploadTemplate = () => {
    if (!search.trim()) {
      setSearch("");
      setToastData({
        toastLoading: true,
        toastMessage: "Please Enter Template Name",
        variant: "error",
      });
      return;
    }

    const data = {
      is_active: checked,
      is_deleted: false,
      template_name: `${search.replace(/\s+/g, '_')}${getFileExtension(docType)}`,
      template_url: "string",
      template_type: docType == "SMS" ? "SMS" : "quote_summary_hire_purchase",
      storage_type: "S3",
      dealer_code: dealerCode ? dealerCode : null,
      base64_html_template: "string",
      base64_file_template: templateBase64.substring(
        templateBase64.indexOf(",") + 1
      ),
      default: false,
      template_html: JSON.stringify(template_html),
    };

    // @ts-ignore
    uploadTemplate(data, {
      onSuccess: () => {
        setToastData({
          toastLoading: true,
          toastMessage: "Template Saved Successfully",
          variant: "success",
        });
        setTimeout(() => (
          navigate(appendParamsToUrl(APP_ROUTES.TEMPLEX_HOME_PAGE))
        ), 1000)
      },
      onError: () => {
        setToastData({
          toastLoading: true,
          toastMessage: "Error Saving Template",
          variant: "error",
        });
      },
    });
  };


  return (
    <>
      <CreateTemplatePageWrap
        theme={theme}
        className="create-template-page-wrap"
      >
        <Header
          onBack={() => navigate(appendParamsToUrl(APP_ROUTES.TEMPLEX_HOME_PAGE))}
          actionArea={
            <>
              <Button
                theme={theme}
                secondary
                iconText={<Icon name="MoreIcon" />}
              />
              <Button
                theme={theme}
                primary
                text="Save Template"
                onClick={handleUploadTemplate}
              />
            </>
          }
        />
        <Box theme={theme} className="main-content">
          <Box theme={theme} className="editor-container inner-container">
            <Box theme={theme} className="filter">
              <Box theme={theme} className="filter-wrapper">
                <Select
                  theme={theme}
                  value={"Storage: S3"}
                  items={[{ value: "Storage: S3", text: "Storage: S3" }]}
                  disabled
                />
                {/* {(docType !== "SMS" || !template) && (
                  <Select
                    theme={theme}
                    value={templateType}
                    disablePortal={false}
                    items={[
                      {
                        value: "quote_summary_hire_purchase",
                        text: "Quote Summary-Hire Purchase",
                      },
                      {
                        value: "quote_summary_finance_lease",
                        text: "Quote Summary-Finance Lease",
                      },
                      {
                        value: "quote_summary_loan",
                        text: "Quote Summary-Loan",
                      },
                      {
                        value: "quote_repayment",
                        text: "Quote Repayment",
                      },
                      {
                        value: "hire_purchase",
                        text: "Hire Purchase",
                      },
                      {
                        value: "finance_lease",
                        text: "Finance Lease",
                      },
                      {
                        value: "loan",
                        text: "Loan",
                      },
                    ]}
                    onChange={(e) => setTemplateType(e.target.value.toString())}
                  />
                )} */}
                <Select
                  theme={theme}
                  disablePortal={false}
                  items={[
                    {
                      text: "SMS",
                      value: "SMS",
                    },
                    {
                      text: "Word",
                      value: "Word",
                    },
                    {
                      text: "Email",
                      value: "Email",
                    },
                  ]}
                  value={docType}
                  onChange={(e: any) => {
                    setDocType(e.target.value);
                  }}
                />
              </Box>
            </Box>
            <Box theme={theme} className="input-container with-switch">
              <Input
                theme={theme}
                placeholder="Type template name here..."
                type="text"
                fullWidth
                value={search}
                endAdornment={
                  <Box theme={theme} className="switch-container">
                    <Switch
                      theme={theme}
                      varient={"basic"}
                      switchEnabled={bookmarkHidden}
                      label="Bookmarks"
                      onChange={(e) => {
                        setBookmarkHidden(!bookmarkHidden);
                      }}
                    />
                  </Box>
                }
                onChange={(event) => {
                  TEMPLATE_NAME_REGEX.test(event)
                    ? setSearch(event)
                    : setSearch(search);
                }}
              />
            </Box>
            <AdvanceDocumentEditor
              onTemplateUpload={setTemplateBase64}
              template_base64={templateBase64}
              template_html={template_html}
              isEdit={template?.isEdit}
              bookmarkHidden={bookmarkHidden}
              setBookmarkHidden={setBookmarkHidden}
              docType={docType}
              setTemplateHtml={setTemplateHtml}
            />
          </Box>
        </Box>
      </CreateTemplatePageWrap>
      <Snackbar
        theme={theme}
        action={'btn'}
        btn={<></>}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        variant={toastData.variant === "success" ? "success" : "error"}
        open={toastData?.toastLoading ? toastData?.toastLoading : false}
        message={toastData?.toastMessage ? toastData?.toastMessage : ""}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
      />
    </>
  );
};

export default CreateTemplatePage;
