export const FETCH_ASSET_DETAILS_REQUEST = 'FETCH_ASSET_DETAILS_REQUEST';
export const FETCH_ASSET_DETAILS_SUCCESS = 'FETCH_ASSET_DETAILS_SUCCESS';
export const FETCH_ASSET_DETAILS_FAILURE = 'FETCH_ASSET_DETAILS_FAILURE';
export const OPEN_ASSET_DIALOG = "OPEN_ASSET_DIALOG";
export const CLOSE_ASSET_DIALOG = "CLOSE_ASSET_DIALOG";

export const fetchAssetDetailsRequest = (assetReferenceNum) => ({
  type: FETCH_ASSET_DETAILS_REQUEST,
  payload: assetReferenceNum,
});

export const fetchAssetDetailsSuccess = (assetDetails) => ({
  type: FETCH_ASSET_DETAILS_SUCCESS,
  payload: assetDetails,
});

export const fetchAssetDetailsFailure = (error) => ({
  type: FETCH_ASSET_DETAILS_FAILURE,
  payload: error,
});

export const openAssetDialog = (assetReferenceNum) => ({
  type: OPEN_ASSET_DIALOG,
  payload: assetReferenceNum
});

export const closeAssetDialogue = () => ({
  type: CLOSE_ASSET_DIALOG,
});
