import { PickUpBanner } from "@src/components/molecules";
import {
  BmwExplore,
  HeroScheduled,
  MyBmwApp,
  ShopNow,
  StateCard,
} from "@src/components/organisms";
import { BaseLayout } from "@src/layouts";

const WaitingHubPage = () => {
  const showPickUpBanner = false;
  return (
    <BaseLayout hasMenu={true} hasFooter={true}>
      <HeroScheduled showPickUpBanner={showPickUpBanner} />
      {showPickUpBanner && <PickUpBanner />}
      <StateCard />
      <MyBmwApp />
      <ShopNow />
      <BmwExplore />
    </BaseLayout>
  );
};

export default WaitingHubPage;
