import axios, { AxiosError } from 'axios';
import { handleRefreshToken, logout } from '@ntpkunity/controls-ums'
import {Navigation} from "@constants";

// export class ApiError extends Error {
//   name: string

//   constructor(public response: Response, public data?: ErrorDataType, message?: string) {
//     super(message || response.statusText)
//     this.name = this.constructor.name
//   }
// }

// export type ErrorDataType = {
//   status?: number
//   errorCode?: string
//   message?: string
// }

export const http = (URL, optionalHeaders = null) => {
  const access_token = localStorage.getItem('access_token');
  const headers = {
    'Content-type': 'application/json',
    ...optionalHeaders
  }

  if (access_token) {
    headers['Authorization'] = `Bearer ${access_token}`
  }

  const AXIOS = axios.create({
    baseURL: URL,
    withCredentials: false,
    headers: headers
  });

  const requestInterceptor = async (request) => {
    switch (request.baseURL) {
    }
    return request
  }
  const errorInRequestInterceptor = async (error) => {
     return Promise.reject(error);
  }

  AXIOS.interceptors.request.use(requestInterceptor, errorInRequestInterceptor);

  const responseInterceptor = (response) => { return response }

  const errorInResponseInterceptor = async (error: AxiosError) => {
    // Handling refresh token logic if needed
    if (error.response?.status === 401) {
      try {
        const extractedValue = window.location.pathname.split('/')[2]
        const reference_dealer = localStorage.getItem('reference_dealer')
        localStorage.clear()
		if (reference_dealer) {
		  localStorage.setItem('reference_dealer', reference_dealer);
		}
        await handleRefreshToken(error, axios, logout(`${Navigation.AUTHENTICATION.SIGN_IN_PAGE.replace(':tenant', extractedValue)}`));
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    // Handle additional error statuses here, if needed
    switch (error?.response?.status) {
      case 401:
        // Unauthorized, handle accordingly (logout or refresh token)
        return Promise.reject('Unauthorized access');
      case 500:
        // Internal Server Error
        return Promise.reject('Server error occurred');
      default:
        return Promise.reject(error?.response?.data);
    }
  }

  AXIOS.interceptors.response.use(responseInterceptor, errorInResponseInterceptor);

  return AXIOS;
}
