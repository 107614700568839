import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const AnalyticsContentWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.analytics-content-wrap': {
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-uppercase': {
      textTransform: 'uppercase'
    },
    '.fw-sbold': {
      fontWeight: theme.customVariables.fontWeightSemiBold
    },
    '.insights-list-wrap': {
      ul: {
        marginTop: 8,
        marginBottom: 0,
        paddingLeft: 15,
        li: {
          fontSize: theme.typography.body2.fontSize,
          lineHeight: theme.typography.body2.lineHeight,
          color: theme.palette.grey[600]
        }
      }
    },
    '.top-selling-wrap': {
      maxHeight: 315
    }
  }
}))
