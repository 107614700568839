import tinymce from "tinymce/tinymce";

import {
  useGetBookmarks,
  useGetEvents,
} from "@modules/services/configureTemplateService";
import { ITemplateConfiguration } from "@modules/templexConfiguration.types";
import { useTheme } from "@mui/material";
import {
  Box,
  Button,
  Grid,
  PersistentDrawer,
  Select,
  Typography,
  Icon,
  Input
} from "@ntpkunity/controls";
import { Editor } from "@tinymce/tinymce-react";
import HTMLtoDOCX from "html-to-docx";
import { useEffect, useRef, useState, useMemo } from "react";
import "tinymce/icons/default";
import "tinymce/models/dom/model";
import "tinymce/plugins/advlist";
import "tinymce/plugins/anchor";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/autosave";
import "tinymce/plugins/charmap";
import "tinymce/plugins/code";
import "tinymce/plugins/codesample";
import "tinymce/plugins/directionality";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/image";
import "tinymce/plugins/importcss";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/media";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/preview";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/save";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/table";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/wordcount";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/themes/silver";

import { DocumentEditorWrap } from "./documentEditorStyle";
import { TableControlModal } from "./tableModal";
import { useForm } from "react-hook-form";
import { MultiSelectAll } from "@shared/components";

export function AdvanceDocumentEditor(props: any) {
  const theme = useTheme();
  const editorRef = useRef(null);
  const { init, ...rest } = props;
  const [events, setEvents] = useState([]);
  const { data: getEvents } = useGetEvents();
  const [selectedEvents, setSelectedEvents] = useState<any>([]);

  const [open, setOpen] = useState(false);
  const [header, setHeader] = useState([]);
  const [headerValues, setHeaderValues] = useState([]);
  const [footer, setFooter] = useState([]);
  const [editorContent, setEditorContent] = useState("");
  const [activeBookmark, setActiveBookmark] = useState<string[]>([]);
  const [sortOrder, setSortOrder] = useState<string | number>("0");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {
    formState: { errors, isDirty },
  } = useForm<ITemplateConfiguration>();

  const handleEventChange = (event: any, newSelectedValues: any) => {
    const selectedValue = newSelectedValues;
    if (Array.isArray(selectedValue)) {
      const selectedEvents = selectedValue
        .map((value: any) => {
          const numericValue = Number(value);
          if (isNaN(numericValue)) {
            console.error("Invalid number:", value);
            return null;
          }
          return events.find((e) => e.event_value === numericValue) || null;
        })
        .filter((event) => event !== null);

      setSelectedEvents(selectedEvents);
    } else {
      const numericValue = Number(selectedValue);

      if (isNaN(numericValue)) {
        console.error("Selected value is not a valid number:", selectedValue);
        return;
      }

      const selectedEvent = events.find((e) => e.event_value === numericValue);

      if (selectedEvent) {
        setSelectedEvents([selectedEvent]);
      } else {
        console.error("No event found with event_value:", numericValue);
      }
    }
  };

  const handleSortOrderChange = (event: any) => {
    const newSortOrder = event.target.value;
    setSortOrder(newSortOrder);
  };

  const eventNamesForAPI = selectedEvents.map((evt) => evt.event_name);

  const { data: bookmarksData, refetch } = useGetBookmarks(eventNamesForAPI);
  const toggleBookmarkStatus = (bookmark) => {
    activeBookmark.includes(bookmark)
      ? setActiveBookmark((prevBookmarks) =>
          prevBookmarks.filter((item) => item !== bookmark)
        )
      : setActiveBookmark((prevBookmarks) => [...prevBookmarks, bookmark]);
  };

  const DragAndDropButton = ({ text, isActive, onClick, value }) => {
    const handleDragStart = (e) => {
      e.dataTransfer.setData("text/plain", `{{${value}}}`);
    };

    return (
      <Button
        theme={theme}
        text={text}
        draggable
        className={isActive ? "active" : ""}
        onClick={onClick}
        onDragStart={handleDragStart}
      />
    );
  };

  const uploadTemplate = async () => {
    const { docType, onTemplateUpload, setTemplateHtml } = props;

    const wrapWithPaddingTable = (htmlString) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, "text/html");
      const elements = doc.querySelectorAll(
        "[style*='padding-left'], [style*='padding-right']"
      );

      elements.forEach((element) => {
        const el = element as HTMLElement;
        const paddingLeft = el.style.paddingLeft;
        const paddingRight = el.style.paddingRight;

        if (paddingLeft || paddingRight) {
          const wrapperTable = document.createElement("table");
          wrapperTable.classList.add("padding-wrapper");
          const wrapperRow = document.createElement("tr");
          wrapperRow.classList.add("padding-wrapper");

          if (paddingLeft) {
            const paddingValue = paddingLeft.replace("px", "");
            const paddingCell = document.createElement("td");
            paddingCell.classList.add("padding-wrapper");
            paddingCell.style.width = `${paddingValue}px`;
            wrapperRow.appendChild(paddingCell);
          }

          const contentCell = document.createElement("td");
          contentCell.classList.add("padding-wrapper");
          contentCell.innerHTML = el.outerHTML;
          wrapperRow.appendChild(contentCell);

          if (paddingRight) {
            const paddingValue = paddingRight.replace("px", "");
            const paddingCell = document.createElement("td");
            paddingCell.classList.add("padding-wrapper");
            paddingCell.style.width = `${paddingValue}px`;
            wrapperRow.appendChild(paddingCell);
          }

          wrapperTable.appendChild(wrapperRow);
          el.replaceWith(wrapperTable);
        }
      });

      return doc.body.innerHTML;
    };

    const content = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
      </head>
      <body style="margin: 20px; padding: 20px; box-sizing: border-box; font-size: 10.5px; font-family: Arial;">
        <table width="96%" class="padding-wrapper">
          <tr class="padding-wrapper">
            <td class="padding-wrapper">
              ${wrapWithPaddingTable(editorContent)}
            </td>
          </tr>
        </table>
      </body>
      </html>
    `;

    const convertBlobToBase64 = (blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    };

    try {
      let blob;

      switch (docType) {
        case "SMS":
          const textContent =
            new DOMParser().parseFromString(content, "text/html").body
              .textContent || "";
          blob = new Blob([textContent.trim()], { type: "text/plain" });
          break;

        case "Email":
          blob = new Blob([content], { type: "text/html" });
          break;

        default:
          const fileBuffer = await HTMLtoDOCX(editorContent, null);
          blob = new Blob([fileBuffer], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          break;
      }

      if (blob) {
        const base64data = await convertBlobToBase64(blob);
        onTemplateUpload(base64data);
        setTemplateHtml(content);
      }
    } catch (error) {
      console.error("Error processing template:", error);
    }
  };

  const removePaddingTables = (html) => {
    const paddingWrapperRegex =
      /<([a-zA-Z0-9]+)([^>]*\sclass\s*=\s*["']\s*padding-wrapper\s*["'][^>]*)>(.*?)<\/\1>/g;
    const startTagRegex = /<table[^>]*>\s*<tr[^>]*>\s*<td[^>]*>/i;
    const endTagRegex = /<\/td>\s*<\/tr>\s*<\/table>/i;
    let cleanedHtml = html;
    while (paddingWrapperRegex.test(cleanedHtml)) {
      cleanedHtml = cleanedHtml.replace(paddingWrapperRegex, "$3");
    }
    cleanedHtml = cleanedHtml
      .replace(startTagRegex, "")
      .replace(endTagRegex, "");

    return cleanedHtml;
  };

  useEffect(() => {
    const handleDrop = (e) => {
      e.preventDefault();
      const bookmark = e.dataTransfer.getData("text/plain");
      if (editorRef.current) {
        const editor = editorRef.current.editor;
        editor.insertContent(bookmark);
      }
    };

    const editorContainer = document.querySelector(".tox-edit-area");
    if (editorContainer) {
      editorContainer.addEventListener("drop", handleDrop);
    }

    return () => {
      if (editorContainer) {
        editorContainer.removeEventListener("drop", handleDrop);
      }
    };
  }, []);

  useEffect(() => {
    if (props.template_html) {
      setEditorContent(removePaddingTables(props.template_html));
    }
  }, []);

  useEffect(() => {
    uploadTemplate();
  }, [editorContent, props.docType]);

  useEffect(() => {
    if (getEvents !== undefined) {
      const allEventValues = [
        ...getEvents
          ?.map((e, i) => ({
            event_name: e.event_name,
            event_value: i,
          }))
          .sort((a, b) => a.event_name.localeCompare(b.event_name)),
      ];
  
      setEvents(allEventValues);
      setSelectedEvents(allEventValues);
    }
  }, [getEvents]);

  useEffect(() => {
    refetch();
  }, [selectedEvents, refetch]);

  const sortedBookmarks = bookmarksData
    ? Object.entries(bookmarksData).sort((a, b) => {
        const nameA = a[1]?.name.toUpperCase();
        const nameB = b[1]?.name.toUpperCase();
        if (sortOrder === "0") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      })
    : [];

  const filteredBookmarks = useMemo(() => {
    if (!searchTerm.trim()) return sortedBookmarks;

    const final = sortedBookmarks.filter(([key, value]) =>
      value.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return final;
  }, [searchTerm, sortedBookmarks]);
  return (
    <>
      <DocumentEditorWrap theme={theme} className="document-editor-wrap">
        <Grid theme={theme} container columnSpacing={2} rowSpacing={3}>
          <Grid theme={theme} item sm>
            <Box theme={theme} className="editor-container">
              <Editor
                key={props.docType}
                value={editorContent}
                onEditorChange={(content) => {
                  setEditorContent(content);
                }}
                onInit={(evt, editor) => (editorRef.current = editor)}
                init={{
                  ...init,
                  branding: false,
                  skin: false,
                  inline: false,
                  width: "960px",
                  table_grid: true,
                  content_css: true,
                  toolbar_mode: "sliding",
                  selector: "textarea#open-source-plugins",
                  menubar:
                    props.docType === "SMS"
                      ? false
                      : "file edit view insert format tools table help",
                  plugins:
                    "checklist casechange export formatpainter tinymcespellchecker permanentpen powerpaste advtable editimage tableofcontents footnotes mergetags autocorrect typography inlinecss markdown importcss searchreplace autolink directionality fullscreen image table charmap pagebreak nonbreaking insertdatetime advlist lists wordcount",
                  toolbar:
                    props.docType === "SMS"
                      ? false
                      : "bold italic underline strikethroug image media table mergetags | typography | align lineheight | checklist numlist bullist indent outdent | removeformat | undo redo |fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify |forecolor backcolor | pagebreak | charmap emoticons | fullscreen | link",
                  file_picker_callback: (cb) => {
                    const input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute("accept", "image/*");
                    input.addEventListener("change", (e: any) => {
                      const file = e.target.files[0];
                      const reader: any = new FileReader();
                      reader.addEventListener("load", () => {
                        const id = "blobid" + new Date().getTime();
                        const blobCache =
                          tinymce.activeEditor.editorUpload.blobCache;
                        const base64 = reader.result.split(",")[1];
                        const blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);
                        cb(blobInfo.blobUri(), { title: file.name });
                      });
                      reader.readAsDataURL(file);
                    });
                    input.click();
                  },
                }}
                {...rest}
              />
            </Box>
          </Grid>
          {props.bookmarkHidden && (
            <Grid theme={theme} item lg={4} md={5} sm={12} xs={12}>
              <Box theme={theme} className="bookmark-drawer">
                <PersistentDrawer
                  theme={theme}
                  openPopUp={true}
                  setPopUpState={() => {
                    props.setBookmarkHidden(!props.bookmarkHidden);
                  }}
                  title="Bookmarks"
                  customFooter={false}
                  children={
                    <>
                      <Box theme={theme} className="search-bookmark">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          type="string"
                          placeholder="Search bookmarks..."
                          endAdornment={<Icon name="SearchIcon" />}
                          value={searchTerm}
                          onChange={(e: string) => setSearchTerm(e)}
                        ></Input>
                      </Box>
                      <Box theme={theme} className="sort-filter">
                        <Box theme={theme} display={"flex"} gap={"4px"} mb={3}>
                          <Typography
                            theme={theme}
                            component="span"
                            variant="body2"
                            className="text-muted"
                          >
                            Select Category:{" "}
                          </Typography>
                          <MultiSelectAll
                            theme={theme}
                            id="DrawerMultiSelect"
                            sxProps={{ m: 0, width: "100%" }}
                            disablePortal={false}
                            items={events.map((evt) => ({
                              text: evt.event_name,
                              value: evt.event_value,
                            }))}
                            value={selectedEvents.map((evt) => evt.event_value)}
                            onChange={handleEventChange}
                          />
                        </Box>
                        <Box theme={theme} display={"flex"} gap={"4px"} mb={3}>
                          <Typography
                            theme={theme}
                            component="span"
                            variant="body2"
                            className="text-muted"
                          >
                            Sort By:{" "}
                          </Typography>
                          <Select
                            theme={theme}
                            disablePortal={false}
                            items={[
                              {
                                text: "AtoZ",
                                value: "0",
                              },
                              {
                                text: "ZtoA",
                                value: "1",
                              },
                            ]}
                            value={sortOrder}
                            onChange={handleSortOrderChange}
                          />
                        </Box>
                      </Box>
                      {selectedEvents.length > 0 &&
                        filteredBookmarks.length > 0 && (
                          <Box theme={theme} className="tags">
                            {filteredBookmarks.map(([key, value], index) => (
                              <DragAndDropButton
                                key={index}
                                text={`${value?.name}`}
                                isActive={activeBookmark.includes(key)}
                                value={value?.value}
                                onClick={() => toggleBookmarkStatus(key)}
                              />
                            ))}
                          </Box>
                        )}
                    </>
                  }
                />
              </Box>
            </Grid>
          )}
        </Grid>
        <TableControlModal
          handleClose={handleClose}
          handleOpen={handleOpen}
          open={open}
          header={header}
          headerValues={headerValues}
          footer={footer}
          setHeader={setHeader}
          setHeaderValues={setHeaderValues}
          setFooter={setFooter}
        />
      </DocumentEditorWrap>
    </>
  );
}
