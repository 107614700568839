import { useTheme } from "@mui/material"
import { AiAssistantWrap } from "./ai-assistant.style"
import { Stack } from "@src/components"
import { Box, Typography } from "@ntpkunity/controls"
import img from "@src/assets/icons/ai-assist.svg"
import { AiAssistantList } from "../ai-assistant-list/ai-assistant-list.component"

export const AiAssistant = () => {
    const theme = useTheme();

    return(
        <>
            <AiAssistantWrap theme={theme} className="ai-assistant-wrap">
                <Stack paddingXs={2} paddingMd={2}>
                    <Box theme={theme} className="ai-head">
                        <Box theme={theme} className="ai-head-text">
                            <img src={img} className="img" />
                            <Typography theme={theme} variant="h4" component={'h4'} className="text-h4 fw-sbold text-white">AI-Assisted Analysis</Typography>
                        </Box>
                    </Box>
                    <AiAssistantList />
                </Stack>
            </AiAssistantWrap>
        </>
    )
}