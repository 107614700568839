import { BASE_URL } from "@shared/constants";
import Http from "@shared/helper/http-api";
import { token } from "@shared/utils";
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "react-query";


interface Bookmark {
  id: number,
  name: string;
  value: string;
}

export const TEMPLATE_ENDPOINTS  = {
  SAVE_TEMPLATE_CONFIGURATION: "configuration/template/create-template-configuration",
  UPLOAD_TEMPLATE: "configuration/template/",
  UPLOAD_FILE_AS_BASE64: "configuration/upload-file-as-base64",
  GET_ALL_TEMPLATES: "configuration/template/tenant_id",
  GETBASE64_TEMPLATE: "configuration/template/get-base64-template",
  UPDATE_TEMPLATE: "configuration/template/update-by-name/",
  GET_TEMPLATE_CONFIGURATION: "configuration/template/get-template-configuration",
  UPDATE_TEMPLATE_CONFIGURATION: "configuration/template/update-template-configuration",
  GET_ALL_TEMPLATE_CONFIGURATION_FILTER: "configuration/template/get-template-configuration/filter",
  DELETE_TEMPLATE_CONFIGURATION: "configuration/templates",
  GET_BOOKMARKS: "configuration/document-notification-bookmarks",
  GET_EVENTS: "configuration/events",
};

export const useUploadFileAsBase64 = (): any => {
  const { data, isLoading, mutate, error } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.post<any>(TEMPLATE_ENDPOINTS.UPLOAD_FILE_AS_BASE64, body);
    }
  );
  return { data, isLoading, mutate, error: error?.message };
};

export const useGetAllTemplates = () => {
  const location = useLocation();
  const dealerCode = new URLSearchParams(location.search).get('dealer_code');
  const { data, error } = useQuery(
    TEMPLATE_ENDPOINTS.GET_ALL_TEMPLATES,
    () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      const urlWithDealerCode = dealerCode 
        ? `${TEMPLATE_ENDPOINTS.GET_ALL_TEMPLATES}?dealer_code=${dealerCode}&dealer_only=no` 
        : TEMPLATE_ENDPOINTS.GET_ALL_TEMPLATES;

      return apiService.get<any>(urlWithDealerCode, "");
    },
    { refetchOnWindowFocus: false }
  );
  return { data, error };
};

export const useUploadTemplate = () => {
  const { data, isLoading, mutate, error } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.post<any>(TEMPLATE_ENDPOINTS.UPLOAD_TEMPLATE, body);
    }
  );
  return { data, isLoading, mutate, error: error?.message };
};

export const useUpdateTemplate = (template_name) => {
  const { data, isLoading, mutate, error } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.patch<any>(TEMPLATE_ENDPOINTS.UPDATE_TEMPLATE + template_name, body);
    }
  );
  return { data, isLoading, mutate, error: error?.message };
};

export const useGetBase64Template = () => {
  const { data, isLoading, mutate, error } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.post<any>(TEMPLATE_ENDPOINTS.GETBASE64_TEMPLATE, body);
    }
  );
  return { data, isLoading, mutate, error: error?.message };
};

export const useSaveTemplateConfiguration = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate, error } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.post<any>(TEMPLATE_ENDPOINTS.SAVE_TEMPLATE_CONFIGURATION, body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TEMPLATE_ENDPOINTS.GET_TEMPLATE_CONFIGURATION);
        queryClient.invalidateQueries(TEMPLATE_ENDPOINTS.GET_ALL_TEMPLATE_CONFIGURATION_FILTER);
      },
    }
  );
  return { data, isLoading, mutate, error: error?.message };
};

export const useUpdateTemplateConfiguration = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate, error } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.patch<any>(TEMPLATE_ENDPOINTS.UPDATE_TEMPLATE_CONFIGURATION, body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TEMPLATE_ENDPOINTS.GET_TEMPLATE_CONFIGURATION);
        queryClient.invalidateQueries(TEMPLATE_ENDPOINTS.GET_ALL_TEMPLATE_CONFIGURATION_FILTER);
      },
    }
  );
  return { data, isLoading, mutate, error: error?.message };
};

export const useGetTemplateConfigurations = () => {
  const { data, error } = useQuery(
    TEMPLATE_ENDPOINTS.GET_TEMPLATE_CONFIGURATION,
    () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.get<any>(
        TEMPLATE_ENDPOINTS.GET_TEMPLATE_CONFIGURATION + `/${token.tenant_id}`
      );
    },
    { refetchOnWindowFocus: false }
  );
  return { data, error };
};

export const useGetTemplateConfigurationFilter = (columnFilters: string) => {
  const { data, error } = useQuery(
    TEMPLATE_ENDPOINTS.GET_ALL_TEMPLATE_CONFIGURATION_FILTER,
    () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.get<any>(
        TEMPLATE_ENDPOINTS.GET_ALL_TEMPLATE_CONFIGURATION_FILTER +
          `/${token.tenant_id}?`.concat(columnFilters)
      );
    },
    { keepPreviousData: true }
  );
  return { data, error };
};

export const useDeleteTemplate = () => {
  const { data, isLoading, mutate, error } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.delete<any>(`${TEMPLATE_ENDPOINTS.DELETE_TEMPLATE_CONFIGURATION}/${body}`);
    }
  );
  return { data, isLoading, mutate, error: error?.message };
};

export const useGetEvents = () => {
  const { data, error } = useQuery(
    TEMPLATE_ENDPOINTS.GET_EVENTS,
    () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.get<any>(TEMPLATE_ENDPOINTS.GET_EVENTS, "");
    },
    { refetchOnWindowFocus: false }
  );
  return { data, error };
};

export const useGetBookmarks = (eventNames: string[]) => {
  const { data, error, refetch } = useQuery<Bookmark, Error>(
    [TEMPLATE_ENDPOINTS.GET_BOOKMARKS, eventNames],
    () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });

      // Join eventNames into a comma-separated string, filtering out 'ALL' if it exists
      const eventNamesParam = eventNames.filter(name => name !== 'ALL').join(',');

      const params = eventNamesParam ? `?event_name=${eventNamesParam}` : '';

      return apiService.get<Bookmark>(`${TEMPLATE_ENDPOINTS.GET_BOOKMARKS}${params}`, "");
    },
    {
      refetchOnWindowFocus: false,
      enabled: eventNames.length > 0,
    }
  );

  return { data, error, refetch };
};

