import { Button, Image } from "@src/components/atoms";
import "./hero-scheduled.scss";
import heroVehicle from "../../../../shared/assets/img/waiting-hub/hero-vehicle.png";
import { HeroScheduledContent } from "@src/components/molecules";

interface HeroScheduledProps {
  showPickUpBanner?: boolean;
}

export const HeroScheduled = ({ showPickUpBanner }: HeroScheduledProps) => {
  return (
    <div
      className={`waiting-hub-hero-scheduled ${
        showPickUpBanner ? "with-pickup-banner" : ""
      }`}
    >
      <div className="bmw-container">
        <div className="waiting-hub-hero__inner">
          <HeroScheduledContent type="dealership" />
          <div className="waiting-hub-hero__img">
            <Image src={heroVehicle} alt="image" aspectRatio="16:9" />
          </div>
        </div>
        <div className="waiting-hub-hero__bottom">
          <p className="text--label text-white">What to do before pickup</p>
          <Button
            htmlType={"button"}
            linkButton
            withIcon
            dataIcon="arrow_down"
            onDark
          />
        </div>
      </div>
    </div>
  );
};
