import { Radio } from "@src/components/atoms";
import "./address-type-selector.scss";

export const AddressTypeSelector = () => {
  return (
    <>
      <div className="type-selector-wrap">
        <p className="text--body-2 financial-residence-text-two">
          Is this a residence or business address?
        </p>
        <div className="type-selector-wrap--radio-container">
          <label htmlFor="residence-address">
            <Radio
              label="Residence"
              onChange={() => {}}
              name="residenceAddress"
              htmlFor="residence-address"
              id="residence-address"
            />
          </label>
          <label htmlFor="business-address">
            <Radio
              label="Business"
              onChange={() => {}}
              name="businessAddress"
              htmlFor="business-address"
              id="business-address"
            />
          </label>
        </div>
      </div>
      <hr className="checkout-hr" />
    </>
  );
};
