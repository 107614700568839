export const BASE_PATH = '/drift/:tenant'
const ORDER_JOURNEY_BASE_PATH = `${BASE_PATH}/vehicle-detail/:vin`
const DEAL_SUMMARY_BASE_PATH = `${BASE_PATH}/deal/:vin`
export const LOGIN_ROUTE = 'log-in'
export const RESET_PASSWORD_ROUTE = 'reset-password'

export const Navigation = {
	BASE_PATH: BASE_PATH,
	PAGE_NOT_FOUND: 'page-not-found',
	INVENTORY_PAGE: `${BASE_PATH}/inventory`,
	DEAL_SUMMARY: `${BASE_PATH}/deal-summary/:vin/:orderId`,
	ORDER_PAGE: ORDER_JOURNEY_BASE_PATH,
	ORDER_ID_PAGE: `${ORDER_JOURNEY_BASE_PATH}/:orderId`,
	DEAL_SUMMARY_PAGE: `${DEAL_SUMMARY_BASE_PATH}/:orderId`,
	AUTHENTICATION: {
		SIGN_IN_PAGE: `${BASE_PATH}/sign-in`,
		SIGN_UP_PAGE: `${BASE_PATH}/sign-up`,
		LOG_IN_PAGE: `${BASE_PATH}/${LOGIN_ROUTE}`,
		OTP_PAGE: `${BASE_PATH}/otp`,
		EMAIL_VERIFICATION: `${BASE_PATH}/email-verification`,
		USER_PROFILE: `${BASE_PATH}/user-profile`,
		FORGOT_PASSWORD: `${BASE_PATH}/forgot-password`,
		RESEND_PASSWORD_LINK: `${BASE_PATH}/resend-password-link`,
		RESET_PASSWORD: `drift/:tenant/${RESET_PASSWORD_ROUTE}`,
		RESET_PASSWORD_LINK_EXPIRED: `${BASE_PATH}/reset-password-link-expired`,
		SIGNUP_CONSENT: `${BASE_PATH}/signup-consent`,
	},
	APPLCATION_JOURNEY: {
		ADD_ONS_PAGE: `${ORDER_JOURNEY_BASE_PATH}/add-ons`,
		FINANCING_PAGE: `${BASE_PATH}/financing`,
		FNI_PRODUCTS_PAGE: `${BASE_PATH}/fni-products`,
		INSURANCE_PAGE: `${BASE_PATH}/insurance`,
		PAYMENT_PAGE: `${ORDER_JOURNEY_BASE_PATH}/payment`,
		REBATES_PAGE: `${BASE_PATH}/rebates`,
		SCHEDULE_PAGE: `${BASE_PATH}/schedule`,
		TRADE_IN_PAGE: `${BASE_PATH}/trade-in`,
		SUBMIT_ORDER_PAGE: `${BASE_PATH}/submit-deal/:vin/:orderId?`,
		DEAL_SUMMARY: `${BASE_PATH}/deal-summary/:vin/:orderId?`,
	},
	PROFILE: {
		USER_PROFILE: `${BASE_PATH}/profile`,
		ORDERS: `${BASE_PATH}/profile?tab=deals`,
		FAVORITES: `${BASE_PATH}/profile/favorites`,
		APPOINTMENTS: `${BASE_PATH}/profile?tab=appointments`,
		PREFRENCES: `${BASE_PATH}/profile/preferences`,
		PRE_QUALIFIED: `${BASE_PATH}/profile/pre-qualified`,
	},
}
