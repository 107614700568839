import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PageContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({}) => ({
  '&.page-content': {
    '.dashboard-header-wrap': {
      marginLeft: -40,
      marginRight: -40,
      borderTop: '1px solid #ccc',
      marginTop: -1
    },
    '.section-h': {
      maxHeight: 210,
      height: '100%',
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      '&.top-selling-wrap': {
        paddingRight: 8
      },
      '&::-webkit-scrollbar': {
        width: 7,
        height: 7
      },
      '&::-webkit-scrollbar-track': {
        padding: '0 1px',
        backgroundColor: '#f0f0f0'
      },
      '&::-webkit-scrollbar-thumb': {
        transition: 'all 0.3s',
        backgroundColor: '#cdcdcd'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a6a6a6'
      }
    },
    '.action-list-wrap': {
      maxHeight: 330
    }
  }
}))
