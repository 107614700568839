import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ButtonGroupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.button-group-wrap': {
    padding: 2,
    borderRadius: 8,
    backgroundColor: '#F6F7F9',
    overflowX: 'auto',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0
    },
    '.btn': {
      '&.btn-default': {
        padding: '8px 28px',
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.grey[700],
        position: 'relative',
        flexWrap: 'nowrap',
        flexShrink: 0,
        flexGrow: 1,
        '&::before': {
          display: 'block',
          content: '" "',
          width: 1,
          height: 20,
          backgroundColor: theme.palette.grey[200],
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateY(-50%)'
        },
        '&:first-child, &.selected': {
          '&::before': {
            display: 'none'
          }
        },
        '&.selected': {
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.grey[900],
          backgroundColor: theme.palette.common.white,
          boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.16)',
          '+': {
            '.btn': {
              '&.btn-default': {
                '&::before': {
                  display: 'none'
                }
              }
            }
          }
        },
        [theme.breakpoints.down('md')]: {
          flexWrap: 'nowrap',
          flexGrow: 1
        }
      },
      [theme.breakpoints.down('md')]: {
        '&:last-of-type': {
          width: 'auto !important'
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'flex-start'
    }
  }
}))
