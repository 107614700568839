import { FC, useState, useCallback } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { Box, IBoxProps } from '@ntpkunity/controls'
import InProgressPageHeader from '@app/in-progress/page-header'
import { styled } from '@mui/material/styles'
import CheckAvailable from '@app/in-progress/check-available'
import { useForm } from 'react-hook-form'

const PageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '.btn-back': {
    paddingLeft: '5px !important',
    '&:hover': {
      backgroundColor: 'transparent',
      svg: {
        path: {
          transition: 'all 0.3s',
          stroke: theme.palette.grey[700]
        }
      }
    },
    '.MuiTouchRipple-root': {
      display: 'none'
    }
  },
  '.ndt': {
    width: '100%',
    display: 'inline-block',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[600],
    textAlign: 'right',
    b: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey[900]
    }
  },
  '.text-danger': {
    color: theme.palette.error.main
  }
}))

const DealerVehcileAvailableInProgressUi: FC = () => {
  const theme = useTheme()
  const [reqData, setRequestData] = useState<any>({})
  const [showButton, setShowSaveButton] = useState(false)
  const [checkListName, setCheckListName] = useState('')
  const [checklistIdentifier, setCheckListIdentifier] = useState('')
  const [savedOptionAvailable, setSavedChecklistOptions] = useState(false)
  const [availavleChecklistData, setAvailavleChecklistData] = useState<any>()
  const [checklistErrors, setChecklistErrors] = useState<any>()
  const form = useForm({ mode: 'all' })

  function deleteKeyIfExists(obj: any, keyToDelete: any) {
    if (obj && obj.hasOwnProperty(keyToDelete)) {
      delete obj[keyToDelete]
    }
  }
  //@ts-ignore
  const reasonOrOdometer = useCallback(
    (
      available: any,
      value: any,
      checklistOptions: any,
      other_reason?: any,
      damage_details?: any,
      checkListName?: any,
      checklistIdentifier?: any,
      savedOptionAvailable?: any,
      open_repair_orders?: any,
      checkList?: any
    ) => {
      setCheckListName(checkListName)
      setAvailavleChecklistData(checkList)
      setCheckListIdentifier(checklistIdentifier)
      setSavedChecklistOptions(savedOptionAvailable)
      if (available == 'not available' && value) {
        deleteKeyIfExists(checklistOptions, 'Does the vehicle have any damage?')
        deleteKeyIfExists(checklistOptions, 'Has the vehicle been put on hold?')
        deleteKeyIfExists(checklistOptions, 'Is this vehicle available?')
        deleteKeyIfExists(checklistOptions, 'Are there any open Repair Deals against this vehicle?')
        if (value == '0' && damage_details) {
          setShowSaveButton(true)
          setRequestData({
            available: available,
            value: value,
            checklistOptions,
            damage_details
          })
        }
        if (value !== '0') {
          setRequestData({
            available: available,
            value: value,
            checklistOptions
          })
          setShowSaveButton(true)
        }
      } else if (available == 'available') {
        setShowSaveButton(true),
          setRequestData({
            available: available,
            value: value,
            checklistOptions,
            damage_details: damage_details ?? null,
            open_repair_orders: open_repair_orders ?? null
          })
      } else if (
        available == 'not available' &&
        value &&
        value !== '0' &&
        checklistOptions &&
        Object.keys(checklistOptions).length > 0
      ) {
      } else if (
        available == 'not available' &&
        value == '0' &&
        other_reason &&
        checklistOptions &&
        Object.keys(checklistOptions).length > 0
      ) {
      } else {
        if (available == 'not available') setShowSaveButton(false)
        else setShowSaveButton(true)
      }
    },
    []
  )

  return (
    <>
      <LayoutWithSideNav theme={theme}>
        <PageWrap theme={theme} sx={{ mb: 2.5 }}>
          <InProgressPageHeader
            showButton={showButton}
            reqData={reqData}
            checkListName={checkListName}
            checklistIdentifier={checklistIdentifier}
            savedOptionAvailable={savedOptionAvailable}
            form={form}
            checklistData={availavleChecklistData}
            setChecklistErrors={setChecklistErrors}
          />
          <CheckAvailable
            form={form}
            reasonOrOdometer={reasonOrOdometer}
            checklistErrors={checklistErrors}
          />
        </PageWrap>
      </LayoutWithSideNav>
    </>
  )
}

export default DealerVehcileAvailableInProgressUi
