import React, { FC } from 'react'
import { useTheme } from '@mui/material'
import { CustomDialogWrap, VehicleList } from '@components'
import { Box, Button, Dialog, Icon, Menu, Typography } from '@ntpkunity/controls'
import vcImage from '../../../public/assets/images/BMW-2023-X5-M60i.png'

interface IInventoryPopupProps {}

const AddVehicle: FC<IInventoryPopupProps> = () => {
  const theme = useTheme()
  const [openDialog, setOpenDialog] = React.useState(false)

  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <>
      <VehicleList theme={theme} className="vehicle-list" width={'100%'}>
        <Box theme={theme} className="vl-item sm vertical">
          <Box theme={theme} className="vl-img-wrap">
            <img src={vcImage} alt="Car" />
          </Box>
          <Box theme={theme} className="vl-details">
            <Typography
              theme={theme}
              variant="body2"
              component="p"
              className=" text-overflow"
              children={<b>2023 BMW X5 M60i</b>}
            />
            <Typography
              theme={theme}
              variant="caption"
              component="p"
              className=" text-overflow vl-title-ul"
              children={
                <>
                  VIN: <b>WAWLN5C59H2000017</b>, Stock: <b>1TJ45895</b>
                </>
              }
            />
          </Box>
          <Menu
            theme={theme}
            disablePortal
            options={[
              {
                optionText: (
                  <>
                    <Icon className="menu-icon" name="EditIcon" /> Edit
                  </>
                ),
                optionValue: 'edit'
              },
              {
                optionText: (
                  <>
                    <Icon className="menu-icon" name="DeleteIcon" /> Delete
                  </>
                ),
                optionValue: 'delete'
              }
            ]}
            render={(cb) => (
              <Button
                theme={theme}
                defaultBtn
                className="vl-menu-btn"
                iconText={<Icon name="MoreIcon" />}
                onClick={cb}
              />
            )}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          />
        </Box>
      </VehicleList>
      <CustomDialogWrap theme={theme} className="custom-dialog-wrap lane-dialog-wrap s-full-width">
        <Dialog
          theme={theme}
          variant={'full-window'}
          size="xl"
          disablePortal
          open={openDialog}
          onCloseDialog={handleClose}
          noFooter={true}
          children={
            <>
              <Box theme={theme} className="lane-dialog-content">
                <Box theme={theme} className="wrap">
                  <Box theme={theme} className="container scroll-container"></Box>
                </Box>
              </Box>
            </>
          }
        />
      </CustomDialogWrap>
    </>
  )
}
export default AddVehicle
