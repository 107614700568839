
import { useTheme } from "@mui/material"
import { MyNotesWrap } from "./my-notes.style"
import { Stack } from "@src/components/stack";
import { Button, Icon, Box, Typography, Select, Textarea, Checkbox, DateRange } from "@ntpkunity/controls";
import { MyNotesList } from "../my-notes-list/my-notes-list.component"
import { useState } from "react";
import { StickyNotesPopup } from "@src/components/dashboard/sticky-notes-stack/sticky-notes-popup/sticky-notes-popup.component"
import clsx from "clsx"

export const MyNotes = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("all");

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };
    const handlePriorityChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleClick = () => {
        setOpen(true);
    };

    return (
        <>
            <MyNotesWrap theme={theme} className="my-notes-wrap">
                <Stack bgLight paddingMd={2} paddingXs={2} fullWidth>
                    <Stack paddingMd={2} paddingXs={2} className="my-notes-stack" title="My Notes" fullWidth>
                        <Box theme={theme} className="custom-dropdown">
                            <Typography
                                theme={theme}
                                className="text-muted"
                                variant="body2"
                                component={"span"}
                                display={"block"}
                            >
                                Select Priority:
                            </Typography>
                            <Select
                                theme={theme}
                                disablePortal={false}
                                items={[
                                    { text: "High Priority", value: "high" },
                                    { text: "Mid Priority", value: "mid" },
                                    { text: "Low Priority", value: "low" },
                                ]}
                                onChange={handlePriorityChange}
                                value={selectedCategory}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            />
                        </Box>
                        <Box theme={theme} className={clsx("add-sticky-notes", { "clicked": open === true })}>
                            <Box theme={theme} className="add-title">
                                <Typography
                                    theme={theme}
                                    variant="body1"
                                    component={"span"}
                                    display={"block"}
                                    className="fw-sbold"
                                >
                                    Add Title
                                </Typography>
                                <Box theme={theme} className="custom-dropdown">
                                    <Typography
                                        theme={theme}
                                        className="text-muted"
                                        variant="body2"
                                        component={"span"}
                                        display={"block"}
                                    >
                                        Select Category:
                                    </Typography>
                                    <Select
                                        theme={theme}
                                        disablePortal={false}
                                        items={[
                                            { text: "Internal Note", value: "internal" },
                                            { text: "General Note", value: "general" },
                                            { text: "Decision Note", value: "decision" },
                                            { text: "Dealer Note", value: "dealer" }
                                        ]}
                                        onChange={handleCategoryChange}
                                        value={selectedCategory}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Textarea 
                                theme={theme} 
                                type="text" 
                                rows={2} 
                                placeholder="Type note here.." 
                                id="" 
                                fullWidth
                                onFocus={handleClick} 
                            />
                        </Box>
                        <Box theme={theme} display={'flex'} alignItems={'center'} gap={5}>
                            <Checkbox theme={theme} label={'Mark as Sticky Note'} checkBoxChecked />
                            <Box theme={theme} className="select-priority">
                                <Typography theme={theme} variant="body2" component={'span'} className="select fw-sbold">Select Color:</Typography>
                                <Box theme={theme} className="priority">
                                    <Box theme={theme} className="clr-picker light-yellow"> </Box>
                                    <Box theme={theme} className="clr-picker light-purple selected"> </Box>
                                    <Box theme={theme} className="clr-picker light-ferozi"></Box>
                                </Box>
                            </Box>
                        </Box>
                        <Button theme={theme} primary text={'Save Note'} />
                    </Stack>
                    <Box theme={theme} display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} pt={2} pb={2}>
                        <Box theme={theme} className="custom-dropdown">
                            <Typography
                                theme={theme}
                                className="text-muted"
                                variant="body2"
                                component={"span"}
                                display={"block"}
                            >
                                Category:
                            </Typography>
                            <Select
                                theme={theme}
                                disablePortal={false}
                                items={[
                                    { text: "All", value: "all" },
                                ]}
                                onChange={handleCategoryChange}
                                value={selectedCategory}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            />
                        </Box>
                        <Box theme={theme} className="multi-functions">
                            <Box theme={theme} className="datepicker">
                                <DateRange
                                    theme={theme}
                                    onChange={() => { }}
                                    disablePortal={true}
                                    placeholder={"Today"}
                                    endAdornment={
                                        <Icon name="CalendarIcon" className="calendar-icon" />
                                    }
                                    render={<input placeholder="Today" readOnly />}
                                    onFocus={() => { }}
                                />
                                <Button
                                    theme={theme}
                                    defaultBtn
                                    iconText={<Icon name="ChevronDown" />}
                                    onClick={() => { }}
                                />
                            </Box>
                            <Button
                                theme={theme}
                                defaultBtn
                                className="icon-light"
                                iconText={<Icon name="ResetIcon" />}
                                onClick={() => { }}
                            />
                            <Button
                                theme={theme}
                                defaultBtn
                                className="icon-light"
                                iconText={<Icon name="RefreshIcon" />}
                                onClick={() => { }}
                            />
                            <Button
                                theme={theme}
                                defaultBtn
                                iconText={<Icon name="SearchIcon" />}
                                onClick={() => { }}
                            />
                        </Box>
                    </Box>
                    <Box theme={theme} className="scrollable-container">
                        <Box theme={theme} className="notes-listing">
                            <MyNotesList />
                        </Box>
                    </Box>
                </Stack>
            </MyNotesWrap>
            {/* <StickyNotesPopup open={open} closeDialog={handleClick} /> */}
        </>
    )
}