import { useTheme } from '@mui/material'
import { Grid, Skeleton, NoDataPreview } from '@ntpkunity/controls'
import {  QueryKeys, Tabs } from '@constants'
import { CancelOrderDialog } from '@libraries'
import { ProductOrderCard } from '@components'
import { useContext, useEffect, useState } from 'react'
import { Stage, Status, TaggingClasses } from 'constants/enums'
import { useGetCurrencies, useGetCustomerByEmail, useGetCustomerOrders } from '@hooks'
import { AppContext } from '@app/context-provider'
import { useQueryClient } from 'react-query'
import { useWorkflowContext } from '@app/router-workflow'
import { areArraysEqual } from 'helpers/methods'
import { getTabsClass } from '@helpers'
import { useNavigate} from 'react-router-dom'

export const Orders = () => {
	const theme = useTheme()
	const navigate = useNavigate()

	const { getOrderWorkflow } = useWorkflowContext()
	const [orderTabs, setOrderTabs] = useState([])
	const queryClient = useQueryClient()

	const appState = useContext(AppContext)
	const PLACEHOLDERS = appState.state.language.placeholders

	const [currency, setCurrency] = useState<string>('')

	const { data: currencyData } = useGetCurrencies(appState?.state?.slug)
	const [withdrawOrder, setWithdrawOrder] = useState(null)
	const { data, isFetched, isLoading } = useGetCustomerByEmail(JSON.parse(localStorage.getItem('settings'))?.email)
	const { mutate: getAllCustomerOrders, data: customerOrders } = useGetCustomerOrders()

	useEffect(() => {
		if (isFetched && !withdrawOrder) {
			const queryString = Object.values(Status)
				.map((value) => `&multiple_order_status=${value}`)
				.join('')
			getAllCustomerOrders(queryString.concat(`&customer_reference_id=${data?.reference_id}&latest_order=${true}`))
		}
	}, [isFetched, withdrawOrder])
	useEffect(() => {
		const tenant_configuration: any = queryClient.getQueryData(QueryKeys.TENANT_CONFIGURATION)
		const defaultCurrency = currencyData?.find((currency: any) => currency.id === tenant_configuration?.data?.default_currency)?.symbol
		setCurrency(defaultCurrency)
	}, [currencyData])
	useEffect(() => {
		const tenant_configuration: any = queryClient.getQueryData(QueryKeys.TENANT_CONFIGURATION)
		const defaultCurrency = currencyData?.find((currency: any) => currency.id === tenant_configuration?.data?.default_currency)?.symbol
		setCurrency(defaultCurrency)
	}, [currencyData])

	useEffect(() => {
		const workflow: any = JSON.parse(localStorage.getItem('workflow'))
		if (workflow?.meta_data?.tabs && !areArraysEqual(workflow?.meta_data?.tabs, orderTabs)) {
			setOrderTabs(workflow?.meta_data?.tabs)
		}
	})

	return (
		<Grid theme={theme} container item spacing={{ xs: 2 }} sx={{ height: '100%' }}>
			{!!withdrawOrder && (
				<CancelOrderDialog withdrawOrder={withdrawOrder} setCancelorder={setWithdrawOrder} handleSuccess={() => setWithdrawOrder(false)} />
			)}
			{isLoading ? (
				<>
					{[1, 2, 3].map((x, index) => (
						<Grid theme={theme} item xs={12} md={4} lg={4} key={index}>
							<Skeleton key={index} theme={theme} width={'300px'} height={'450px'} />
						</Grid>
					))}
				</>
			) : customerOrders?.result?.length === 0 ? (
				<Grid theme={theme} item xs={12}>
					<NoDataPreview icon title={PLACEHOLDERS?.NO_DEAL_TITLE} subTitle={PLACEHOLDERS?.NO_DEAL_SUBTITLE} theme={theme} />{' '}
				</Grid>
			) : (
				customerOrders?.result?.map((order, index) => (
					<Grid theme={theme} item xs={12} md={6} lg={4} key={index}>
						<ProductOrderCard
							taggingClass={
								order.status === Status.Approved
									? getTabsClass(orderTabs, orderTabs?.[0] || Tabs?.INSURANCE, false)
									: order.order_stage === Stage.APPLICATION
									? TaggingClasses.FINANCING
									: TaggingClasses.PAYMENT
							}
							status={order.status}
							setWithdrawOrder={setWithdrawOrder}
							productImg={order?.photo_urls?.[0]?.location}
							secondaryTextOne={`${order?.year} ${order?.make}`}
							secondaryTextTwo={`${order?.finance_type} ${PLACEHOLDERS?.FOR_LOWERCASE_TEXT}`}
							primaryTextOne={`${order?.model} ${order?.trim_description ?? ''}`}
							primaryTextTwo={
								order?.estimated_monthly_payment
									? `${currency}${((order?.estimated_monthly_payment || 0) + (order?.monthly_sales_use_tax || 0)).toLocaleString(undefined, {
											maximumFractionDigits: 2,
											minimumFractionDigits: 2,
									  })}/${PLACEHOLDERS?.PER_MONTH}.`
									: `${currency}0.00/${PLACEHOLDERS?.PER_MONTH}.`
							}
							orderId={order?.reference_number}
							vin={order?.vin}
							viewBtnVisibility={[Status.Expired, Status.Withdrawn, Status.Cancelled, Status.FULLY_RECEIVED, Status.Completed].includes(order.status)}
							onClick={(btnText, vin, orderId) => {
								if ([Status.Expired, Status.Withdrawn, Status.Cancelled, Status.FULLY_RECEIVED, Status.Completed].includes(order.status)) {
									navigate(`/drift/${appState.state.slug}/deal-summary/${vin}/${orderId}`)
								} else {
									const running_id = order?.running_id || null
									const status = running_id ? null : order?.status
									getOrderWorkflow(order?.vin, order?.reference_number, running_id, status)
									queryClient.invalidateQueries([QueryKeys.GET_ORDER_INFORMATION, order?.reference_number])
									queryClient.invalidateQueries(QueryKeys.GET_SETUP_DATA)
									queryClient.invalidateQueries(QueryKeys.GET_CONTRACT_TERMS)
								}
							}}
						/>
					</Grid>
				))
			)}
		</Grid>
	)
}
