import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

const ChatWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.chats-button-wrap': {
    position: 'fixed',
    bottom: 16,
    right: 16,
    zIndex: 9999,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    '.chat-icon-wrap': {
      position: 'relative',
      '.chats-count': {
        position: 'absolute',
        top: -6,
        right: -6,
        minWidth: 20,
        height: 20,
        borderRadius: 40,
        padding: '0 5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: theme.palette.common.white,
        fontWeight: theme.typography.caption.fontWeight,
        fontSize: theme.typography.caption.fontSize,
        backgroundColor: theme.palette.primary.main,
        zIndex: 99
      },
      '.btn.chat-icon': {
        width: 56,
        height: 56,
        backgroundColor: theme.palette.grey[900],
        borderRadius: 24,
        boxShadow:
          '0px 50px 14px 0px rgba(0, 0, 0, 0.01), 0px 32px 13px 0px rgba(0, 0, 0, 0.04), 0px 18px 11px 0px rgba(0, 0, 0, 0.15), 0px 8px 8px 0px rgba(0, 0, 0, 0.26), 0px 2px 4px 0px rgba(0, 0, 0, 0.29);',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 16,
        svg: {
          path: {
            stroke: theme.palette.common.white
          }
        }
      }
    }
  }
}))

export default ChatWrap
