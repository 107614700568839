import "./move-dates.style.css";

export const MoveDateAddress = () => {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="checkout-previous-address-label">
        Please provide your previous address.
      </label>
      <div className="checkout-initial-form-container">
        <form style={{ marginTop: 32 }} className="checkout-form--previous">
          <div className="checkout-form-spacing">
            <div>
              <label
                className="input-field-label default"
                htmlFor="input-bpvgvof"
              >
                <span>Previous address 1</span>
              </label>
              <div
                className="input-field-wrapper default"
                style={{ width: "100%" }}
                id="input-bpvgvof"
              >
                <input
                  id="input-bpvgvof"
                  className="input default"
                  type="undefined"
                  placeholder="Street address"
                />
              </div>
            </div>
          </div>
          <div>
            <label
              className="input-field-label default"
              htmlFor="input-pki6sbv"
            >
              <span>Previous address 2 (optional)</span>
            </label>
            <div
              className="input-field-wrapper default"
              style={{ width: "100%" }}
              id="input-pki6sbv"
            >
              <input
                id="input-pki6sbv"
                className="input default"
                type="undefined"
                placeholder="Apartment, suite, unit, building, floor"
              />
            </div>
          </div>
          <div className="checkout-column-wrapper">
            <div>
              <label
                className="input-field-label default"
                htmlFor="input-gho1rw8"
              >
                <span>Previous city</span>
              </label>
              <div
                className="input-field-wrapper default"
                style={{ width: "100%" }}
                id="input-gho1rw8"
              >
                <input
                  id="input-gho1rw8"
                  className="input default"
                  type="text"
                  placeholder="City"
                />
              </div>
            </div>
            <div>
              <label
                className="input-field-label default"
                htmlFor="input-h8n2r3q"
              >
                <span>Previous state</span>
              </label>
              <div
                className="input-field-wrapper default"
                style={{ width: "100%" }}
                id="input-h8n2r3q"
              >
                <input
                  id="input-h8n2r3q"
                  className="input default"
                  type="text"
                  placeholder="Select"
                />
                <div className="input-icon" data-icon="arrow_chevron_down" />
              </div>
            </div>
            <div>
              <label
                className="input-field-label default"
                htmlFor="input-3zw8173"
              >
                <span>Previous county (optional)</span>
              </label>
              <div
                className="input-field-wrapper default"
                style={{ width: "100%" }}
                id="input-3zw8173"
              >
                <input
                  id="input-3zw8173"
                  className="input default"
                  type="text"
                  placeholder="County"
                />
              </div>
            </div>
            <div>
              <label
                className="input-field-label default"
                htmlFor="input-x5nwmmb"
              >
                <span>Previous ZIP Code</span>
              </label>
              <div
                className="input-field-wrapper default"
                style={{ width: "100%" }}
                id="input-x5nwmmb"
              >
                <input
                  id="input-x5nwmmb"
                  className="input default"
                  type="undefined"
                  placeholder="ZIP Code"
                />
              </div>
            </div>
            <div>
              <label
                className="input-field-label default"
                htmlFor="input-8brklru"
              >
                <span>Move-in date</span>
              </label>
              <div
                className="input-field-wrapper default"
                style={{ width: "100%" }}
                id="input-8brklru"
              >
                <input
                  id="input-8brklru"
                  className="input default"
                  type="text"
                  placeholder="Choose"
                />
                <div className="input-icon" data-icon="calendar" />
              </div>
            </div>
            <div>
              <label
                className="input-field-label default"
                htmlFor="input-q7mdckm"
              >
                <span>Move-out date</span>
              </label>
              <div
                className="input-field-wrapper default"
                style={{ width: "100%" }}
                id="input-q7mdckm"
              >
                <input
                  id="input-q7mdckm"
                  className="input default"
                  type="text"
                  placeholder="Choose"
                />
                <div className="input-icon" data-icon="calendar" />
              </div>
            </div>
          </div>
        </form>
      </div>
      <p style={{ marginTop: 48 }} className="text--body-2">
        If your billing or mailing address is different from your
        permanent/garaging address, please provide them below.
      </p>
    </>
  );
};
