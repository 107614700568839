import { FC, ReactNode } from "react";
import { SxProps, Theme, Tooltip, TooltipProps, styled } from "@mui/material";
import { muiTheme } from "@ntpkunity/controls";

export declare type ITooltipProps = Partial<TooltipProps> & {
  theme: Theme;
  title: ReactNode | string;
  disableFocusListener: boolean;
  disableHoverListener: boolean;
  disableTouchListener: boolean;
  slotProps: {
    arrow?: object;
    popper?: object;
    tooltip?: object;
    transition?: object;
  };
};

const TooltipSX = (theme: Theme): SxProps => ({
  "&.MuiTooltip-popper": {
    ".MuiTooltip-arrow": {
      display: "none",
    },
  },
  "& .MuiTooltip-tooltip": {
    fontFamily: "'Inter', sans-serif",
    backgroundColor:
      theme.palette.mode === "light"
        ? [theme.palette.common.white]
        : muiTheme.palette.grey[800],
    borderRadius: "8px",
    maxWidth: 300,
    padding: "24px",
    boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.16)",
    marginTop: "0 !important",
    ".u-form-group": {
      ".u-input-label": {
        backgroundColor:
          theme.palette.mode === "light"
            ? [theme.palette.common.white]
            : muiTheme.palette.grey[800],
      },
      ".u-form-control": {
        borderColor:
          theme.palette.mode === "light"
            ? "rgba(0,0,0,0.20)"
            : "rgba(255,255,255,0.20)",
        ".MuiOutlinedInput-notchedOutline": {
          borderColor:
            theme.palette.mode === "light"
              ? "rgba(0, 0, 0, 0.23)"
              : "rgba(255,255,255,0.20)",
        },
        "&:hover": {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor:
              theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.87)"
                : "#bdbdbd",
          },
        },
      },
    },
  },
});

const ToolTipDropdown: FC<ITooltipProps> = ({
  title = {},
  open,
  theme,
  className,
  placement,
  leaveDelay,
  disableFocusListener,
  disableHoverListener,
  disableTouchListener,
  slotProps,
  ref,
  ...props
}) => {
  return (
    <Tooltip
      PopperProps={{
        sx: TooltipSX(theme),
      }}
      slotProps={slotProps}
      title={title}
      arrow
      classes={{ popper: className }}
      open={open}
      leaveDelay={leaveDelay}
      placement={placement}
      disableFocusListener={disableFocusListener}
      disableHoverListener={disableHoverListener}
      disableTouchListener={disableTouchListener}
    >
      <div {...props} ref={ref as any}></div>
    </Tooltip>
  );
};

export default ToolTipDropdown;
