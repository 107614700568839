import {
    FileDragDrop,
    FilePreview,
    Grid,
    Typography,
    Dialog,
    Box,
    Icon,
    Button,
    Skeleton,
} from "@ntpkunity/controls";
import { FileDragDropWrap } from "@shared/FileDragDropWrap";
import { useTheme } from "@mui/material";
import { Controller } from "react-hook-form";
import { useEffect, useState, FC } from "react";
import {
    useAddInsuranceDocument,
    useDeleteInsuranceDocument,
    useDocumentURLByArtifactId,
    useGetSignedURLForDocumentUpload,
    useUploadFileBySignedURL,
} from "common/hooks/useCustomerData";
import { getInternalAccessToken } from "utilities/methods";
import { useParams } from "react-router-dom";
import DisableLoader from "../../../../shared/assets/images/loader-disabled.gif";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "enums/PermissionTypes";
import { useStoreContext } from "store/storeContext";
import type { IInsuranceDocument } from "Interface/IInsuranceDocument";

export const InsuranceProof: FC<{
    form: any;
    isInsuranceDataAlreadyAdded: boolean;
    insuranceDocuments: IInsuranceDocument[];
    setInsuranceDocuments: React.Dispatch<React.SetStateAction<any[]>>;
}> = ({
    form,
    isInsuranceDataAlreadyAdded,
    insuranceDocuments,
    setInsuranceDocuments,
}) => {
        const theme = useTheme();
        const { actions } = useStoreContext();
        const { control, getValues, setValue } = form;
        const params = useParams();
        const { mutate: fileUpload, isLoading: isAddLoading } =
            useAddInsuranceDocument();
        const { mutate: fileDelete, isLoading: isRemoveLoading } =
            useDeleteInsuranceDocument();
        const { mutate: downloadDocument, isLoading: isDownloading } =
            useDocumentURLByArtifactId();
        const { mutate: getSignedURLForDocumentUpload, isLoading: isLoadingGetSignedURLForDocumentUpload } =
            useGetSignedURLForDocumentUpload();
        const { mutate: uploadFileBySignedURL, isLoading: isLoadinguploadFileBySignedURL } =
            useUploadFileBySignedURL();

        const insuranceDocs = getValues("insuranceDetail.insurance_documents");
        const [imageFiles, setImageFiles] = useState<any[]>([]);
        const [dialogOpened, setDialogOpened] = useState<boolean>(false);
        const [convertingImgToBase64, setConvertingImgToBase64] =
            useState<boolean>(false);
        const [imageIndex, setImageIndex] = useState<number>(-1);
        const viewUploadedInsurance = useHasPermissions(
            ccpPermissions.FIELDS.VIEW_INSURANCE_DOCUMENTS,
            PermissionTypes.DISABLED
        );
        const [viewInsurance, setViewUploadedInsurance] = useState(false);
        const tokenInforamtion = getInternalAccessToken();

        const editInsuaranceDetail = useHasPermissions(
            ccpPermissions.FIELDS.EDIT_INSURANCE_DETAIL,
            PermissionTypes.DISABLED
        );
        const [updateInsauranceDetail, setUpdateInsuracneDetail] = useState(false);
        useEffect(() => {
            setUpdateInsuracneDetail(editInsuaranceDetail);
        }, [editInsuaranceDetail]);

        useEffect(() => {
            setViewUploadedInsurance(viewUploadedInsurance);
        }, [viewUploadedInsurance]);
        type previewFilesItems = {
            name: string;
            size: string;
        };
        /* eslint-disable array-callback-return */
        const previewFiles = (imageFiles) => {
            const items: previewFilesItems[] = [];
            imageFiles?.map((r) => {
                items.push({
                    name: r.name,
                    size: r.file_size,
                });
            });
            return items;
        };

        const convertBase64ToBinary = (base64String: string) => {
            // Decode the base64 string
            const decodedString = atob(base64String);

            // Convert the decoded string to a binary data (Uint8Array)
            const binaryLength = decodedString.length;
            const bytes = new Uint8Array(binaryLength);
            for (let i = 0; i < binaryLength; i++) {
                bytes[i] = decodedString.charCodeAt(i);
            }

            // Set the binary data in state
            return bytes
        };

        const fileUploadBySignedURL = (signedURLData: any, file_binary: any, file: any) => {
            uploadFileBySignedURL(
                {
                    upload_url: signedURLData?.uploadUrl,
                    file: file_binary,
                    content_type: signedURLData?.artifact?.fileType
                },
                {
                    onSuccess() {
                        if (isInsuranceDataAlreadyAdded) {
                            fileUpload(
                                {
                                    reference_id: params?.reference_id,
                                    doucment_reference_id: signedURLData?.artifact?.id,
                                    content_type: signedURLData?.artifact?.fileType,
                                    name: file?.name,
                                    file_size: file?.size,
                                    created_by: tokenInforamtion?.user_name,
                                    created_at: new Date().toISOString().split("T")[0],
                                },
                                {
                                    onSuccess(response) {
                                        setImageFiles(response.data);
                                        actions.setToast({
                                            toastMessage: "Document Uploaded Successfully",
                                            toastState: true,
                                        });
                                    },
                                    onError(error: any) {
                                        actions.setToast({
                                            toastMessage: error.error.toString(),
                                            toastState: true,
                                            variant: "error",
                                        });
                                    },
                                }
                            );
                        } else {
                            const fileUploadPayload: IInsuranceDocument = {
                                doucment_reference_id: signedURLData?.artifact?.id,
                                content_type: signedURLData?.artifact?.fileType,
                                name: file?.name,
                                file_size: file?.size.toString(),
                                created_by: tokenInforamtion?.user_name,
                            };
                            const _insuranceDocuments = [...insuranceDocuments, fileUploadPayload];
                            setInsuranceDocuments(_insuranceDocuments);
                            setImageFiles(_insuranceDocuments);
                            setValue("insurancedocumentsAdded", true, { shouldDirty: true });
                            setConvertingImgToBase64(false);
                        }
                    },
                    onError(error: any) {
                        actions.setToast({
                            toastMessage: error.error.toString(),
                            toastState: true,
                            variant: "error",
                        });
                    }
                }
            )
        }

        const addInsuranceDocuments = (file: File) => {
            setConvertingImgToBase64(true);
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                const binary_data = typeof fileReader.result === "string"
                    ? convertBase64ToBinary(fileReader.result.replace("data:", "").replace(/^.+,/, ""))
                    : null;
                    getSignedURLForDocumentUpload(
                        {
                            document_name: file?.name,
                            content_type: file?.type
                        },
                        {
                            onSuccess({ data }) {
                                fileUploadBySignedURL(data, binary_data, file)
                            },
                            onError(error: any) {
                                actions.setToast({
                                    toastMessage: error.error.toString(),
                                    toastState: true,
                                    variant: "error",
                                });
                            }
                        }
                    )
                
            };
            fileReader.onerror = () => {
                setConvertingImgToBase64(false);
            };
        };

        const onimageUpload = (e: any) => {
            if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
                if (isInsuranceDataAlreadyAdded) {
                    addFilesForPreview(e.target.files);
                } else {
                    addInsuranceDocuments(e.target.files[0]);
                }
            }
        };

        const onimageDrop = (e: any) => {
            if (
                e &&
                e?.dataTransfer &&
                e?.dataTransfer?.files &&
                e?.dataTransfer?.files &&
                e?.dataTransfer?.files.length > 0
            ) {
                if (isInsuranceDataAlreadyAdded) {
                    addFilesForPreview(e.dataTransfer.files);
                } else {
                    addInsuranceDocuments(e.dataTransfer.files[0]);
                }
            }
        };

        const addFilesForPreview = (files: any) => {
            const fileReader = new FileReader();
            for (let i = 0; i < files.length; i++) {
                fileReader.readAsDataURL(files[i]);
                fileReader.onload = () => {
                    const binary_data = typeof fileReader.result === "string"
                    ? convertBase64ToBinary(fileReader.result.replace("data:", "").replace(/^.+,/, ""))
                    : null;
                    getSignedURLForDocumentUpload(
                        {
                            document_name: files[i]?.name,
                            content_type: files[i]?.type
                        },
                        {
                            onSuccess({ data }) {
                                fileUploadBySignedURL(data, binary_data, files[i])
                            },
                            onError(error: any) {
                                actions.setToast({
                                    toastMessage: error.error.toString(),
                                    toastState: true,
                                    variant: "error",
                                });
                            }
                        }
                    )
                };
            }
        };

        const onRemoveFile = (_e: any, index: number) => {
            setDialogOpened(true);
            if (index > -1) {
                setImageIndex(index);
            }
        };
        const onSaveChanges = () => {
            if (setDialogOpened) {
                if (isInsuranceDataAlreadyAdded) {
                    fileDelete(
                        {
                            reference_id: params?.reference_id,
                            identifier: imageFiles.at(imageIndex).identifier,
                            deleted_by: tokenInforamtion?.user_name,
                        },
                        {
                            onSuccess() {
                                const imageArray = imageFiles;
                                imageArray.splice(imageIndex, 1);
                                setImageFiles([...imageArray]);
                                setDialogOpened(false);
                                actions.setToast({
                                    toastMessage: "Document Removed Successfully",
                                    toastState: true,
                                });
                            },
                            onError(error: any) {
                                actions.setToast({
                                    toastMessage: error.error.toString(),
                                    toastState: true,
                                    variant: "error",
                                });
                            },
                        }
                    );
                } else {
                    const imageArray = imageFiles;
                    imageArray.splice(imageIndex, 1);
                    setImageFiles([...imageArray]);
                    setInsuranceDocuments([...imageArray]);
                    setDialogOpened(false);
                }
            }
        };

        useEffect(() => {
            if (insuranceDocs !== undefined) {
                setImageFiles(insuranceDocs);
            }
        }, [insuranceDocs]);
        console.log("insuranceDocs",insuranceDocs)

        const handleDownloadDocument = (e: any, index: number) => {
            window.open(insuranceDocs[index].document_uri)
            downloadDocument(
                { key: imageFiles.at(index).document_uri },
                {
                    onSuccess({ data }) {
                        window.open(data?.document_url);
                    },
                }
            );
        };

        return (
            <>
                <Typography variant="h4" component="h4" rowGap={5} mb={3} theme={theme}>
                    Proof of Insurance
                </Typography>
                <Grid
                    theme={theme}
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid item xs={12} sm={6} md={3} lg={3} theme={theme}>
                        {isAddLoading || isDownloading || convertingImgToBase64 || isLoadinguploadFileBySignedURL || isLoadingGetSignedURLForDocumentUpload ? (
                            <>
                                <Skeleton
                                    theme={theme}
                                    width={"100%"}
                                    height={200}
                                    sx={{ mt: 1 }}
                                    variant="rectangular"
                                />
                                <Skeleton
                                    theme={theme}
                                    width={"100%"}
                                    height={20}
                                    sx={{ mt: 1 }}
                                />
                                <Skeleton
                                    theme={theme}
                                    width={"100%"}
                                    height={20}
                                    sx={{ mt: 1 }}
                                />
                                <Skeleton
                                    theme={theme}
                                    width={"100%"}
                                    height={20}
                                    sx={{ mt: 1 }}
                                />
                            </>
                        ) : (
                            <FileDragDropWrap>
                                <Controller
                                    name="insuranceDetail.insurance_documents"
                                    control={control}
                                    render={({ field }) => (
                                        <FileDragDrop
                                            {...field}
                                            theme={theme}
                                            width="300"
                                            height="200px"
                                            backgroundColor="white"
                                            onDrop={onimageDrop}
                                            onChange={onimageUpload}
                                            disabled={!updateInsauranceDetail}
                                        />
                                    )}
                                />
                                <>
                                    {imageFiles?.length > 0 &&
                                        imageFiles[0] != null &&
                                        viewInsurance && (
                                            <FilePreview
                                                files={
                                                    imageFiles.length > 0 ? previewFiles(imageFiles) : []
                                                }
                                                onRemoveFile={onRemoveFile}
                                                theme={theme}
                                                viewIcon
                                                onClickIcon={handleDownloadDocument}
                                            />
                                        )}
                                </>
                            </FileDragDropWrap>
                        )}
                    </Grid>
                </Grid>
                <Dialog
                    theme={theme}
                    size="xs"
                    variant="confirmation"
                    open={dialogOpened}
                    children={
                        <>
                            <Box theme={theme} textAlign="center">
                                <Box theme={theme} className="dialog-icon">
                                    <Icon name="ErrorTriangle" />
                                </Box>
                                <Box theme={theme} mt={4}>
                                    <Typography
                                        theme={theme}
                                        mb={2}
                                        className="content-title"
                                        variant="h2"
                                        component="h2"
                                    >
                                        Are you sure you want to remove this document?
                                    </Typography>
                                    <Typography
                                        theme={theme}
                                        className="text-muted"
                                        variant="body2"
                                        component="span"
                                    >
                                        Once saved, you cannot undo this action.
                                    </Typography>
                                </Box>
                            </Box>
                        </>
                    }
                    customFooter={
                        <>
                            <Grid container theme={theme} spacing={2}>
                                <Grid theme={theme} item xs={6} textAlign={"right"}>
                                    <Button
                                        theme={theme}
                                        secondary
                                        text="Cancel"
                                        onClick={() => {
                                            setDialogOpened(false);
                                        }}
                                    />
                                </Grid>
                                <Grid theme={theme} item xs={6}>
                                    <Button
                                        disabled={isRemoveLoading || isAddLoading || isLoadinguploadFileBySignedURL || isLoadingGetSignedURLForDocumentUpload}
                                        theme={theme}
                                        primary
                                        text="Save"
                                        onClick={onSaveChanges}
                                        endIcon={
                                            (isRemoveLoading || isAddLoading || isLoadinguploadFileBySignedURL || isLoadingGetSignedURLForDocumentUpload) && (
                                                <img src={DisableLoader} alt="Loader" />
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }
                />
            </>
        );
    };
