import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PageContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.page-content': {
    '.main-page-header': {
      '&.border-bottom': {
        marginBottom: 8
      }
    },
    '.fw-medium': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-uppercase': {
      textTransform: 'uppercase'
    }
  }
}))

export const FullCalendarWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.full-calendar-wrap': {
    '.fc': {
      '.fc-toolbar': {
        '&.fc-header-toolbar': {
          backgroundColor: '#F2F2F2',
          padding: 8,
          borderRadius: theme.shape.borderRadius,
          marginBottom: 0,
          '.fc-toolbar-chunk': {
            '.fc-toolbar-title': {
              fontSize: theme.typography.body2.fontSize,
              lineHeight: theme.typography.body2.lineHeight,
              fontWeight: theme.typography.fontWeightBold,
              backgroundColor: theme.palette.common.white,
              padding: '12px 16px',
              borderRadius: theme.shape.borderRadius
            },
            '.fc-button': {
              padding: '8px 16px',
              fontSize: theme.typography.body2.fontSize,
              lineHeight: theme.typography.body2.lineHeight,
              borderRadius: theme.shape.borderRadius,
              fontWeight: theme.customVariables.fontWeightSemiBold,
              textTransform: 'capitalize',
              '&.fc-button-primary': {
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                '&:hover': {
                  color: theme.palette.common.white,
                  backgroundColor: theme.palette.grey[900],
                  borderColor: theme.palette.grey[900]
                },
                '&:disabled': {
                  borderColor: theme.palette.grey[100],
                  backgroundColor: theme.palette.grey[100],
                  color: theme.palette.grey[300],
                  opacity: 1
                }
              },
              '&:focus': {
                boxShadow: 'none'
              }
            },
            '.fc-button-group': {
              '.fc-button': {
                padding: 8,
                backgroundColor: theme.palette.common.white,
                borderColor: '#999',
                color: theme.palette.grey[900],
                '&:not(:last-child)': {
                  borderBottomRightRadius: 0,
                  borderTopRightRadius: 0
                },
                ':not(:first-child)': {
                  borderBottomLeftRadius: 0,
                  borderTopLeftRadius: 0
                }
              }
            }
          }
        }
      },
      '.fc-view-harness': {
        maxHeight: 'calc(100vh - 265px)'
      },
      '.fc-scrollgrid': {
        borderColor: 'transparent'
      },
      '.fc-scrollgrid-section-header': {
        '.fc-scroller': {
          overflow: 'hidden !important'
        }
      },
      '.fc-scrollgrid-section-body': {
        '.fc-scroller': {
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: 7,
            height: 7
          },
          '&::-webkit-scrollbar-track': {
            padding: '0 1px',
            backgroundColor: '#f0f0f0'
          },
          '&::-webkit-scrollbar-thumb': {
            transition: 'all 0.3s',
            backgroundColor: '#cdcdcd'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#a6a6a6'
          }
        }
      },
      '.fc-timegrid-col': {
        '&.fc-day-today': {
          backgroundColor: 'transparent'
        }
      },
      '&.fc-theme-standard': {
        th: {
          borderColor: 'transparent transparent #E5E5E5',
          textAlign: 'left',
          '&.fc-col-header-cell': {
            padding: '8px 4px',
            '.fc-col-header-cell-cushion': {
              padding: '12px 16px;',
              backgroundColor: '#F2F2F2',
              borderRadius: theme.shape.borderRadius,
              fontSize: theme.typography.body2.fontSize,
              lineHeight: theme.typography.body2.lineHeight,
              fontWeight: theme.typography.fontWeightBold,
              display: 'block'
            }
          },
          '&.fc-timegrid-axis': {
            padding: '8px 4px 8px 0',
            '.fc-timegrid-axis-frame': {
              backgroundColor: '#F2F2F2',
              padding: 8,
              height: 44,
              borderRadius: theme.shape.borderRadius
            }
          }
        },
        td: {
          borderColor: '#E5E5E5',
          borderTopStyle: 'dashed',
          '&.fc-timegrid-slot': {
            padding: '4px 8px'
          },
          '&.fc-timegrid-slot-label': {
            padding: '8px 8px 0 0',
            fontSize: theme.typography.caption.fontSize,
            lineHeight: theme.typography.caption.lineHeight,
            color: theme.palette.grey[400],
            verticalAlign: 'top',
            '.fc-timegrid-slot-label-cushion': {
              padding: 0,
              textTransform: 'uppercase'
            }
          },
          '&.fc-timegrid-slot-minor': {
            borderTopColor: 'transparent',
            borderTopStyle: 'none'
          }
        }
      }
    },
    '.fc-event': {
      '&.fc-timegrid-event': {
        borderRadius: 8,
        '.fc-event-main': {
          padding: '8px 16px',
          p: {
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            margin: 0,
            color: theme.palette.grey[900],
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          },
          small: {
            fontSize: theme.typography.caption.fontSize,
            lineHeight: theme.typography.caption.lineHeight,
            display: 'block',
            marginTop: 2
          }
        }
      },
      '&.fc-v-event': {
        backgroundColor: 'rgba(85, 40, 237, 0.08)',
        borderColor: 'rgba(85, 40, 237, 0.08)'
      }
    }
  }
}))
