import React, {
  createContext,
  useReducer,
  ReactNode,
  useContext,
  Reducer,
} from "react";

import { appReducer } from "@src/context/app-reducer";
import { initialState } from "@src/context/app-state";
import { IApplicationState } from "@src/types";
import { ActionType } from "./app-actions";

// Create context
export const AppContext = createContext<{
  state: IApplicationState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const ApplicationContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer<
    Reducer<IApplicationState, { type: ActionType; payload: any }>
  >(appReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
