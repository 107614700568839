import { styled } from '@mui/material'

export const ProductsBundleWrap = styled('div')(({ theme }) => ({
  '&.bundles-wrap': {
    height: '100%',
    '.bundle-box': {
      padding: 16,
      borderRadius: 8,
      border: '2px solid' + theme.customVariables.tableStripedBg,
      position: 'relative',
      height: '100%',
      overflow: 'hidden',
      transition: 'all 0.3s',
      '&:before': {
        backgroundColor: theme.palette.grey[300],
        display: 'inline-block',
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0.08,
        transition: 'all 0.3s'
      },
      '.bundle-header': {
        position: 'relative',
        '.title-sm': {
          fontWeight: theme.typography.fontWeightBold
        },
        '.btn': {
          transition: 'all 0.3s',
          minWidth: 120
        }
      },
      '.add-list': {
        visibility: 'hidden',
        opacity: 0,
        transition: 'all 0.3s',
        height: 51,
        marginBottom: -51,
        '.dashed-btn': {
          width: '100%',
          border: '2px dashed' + theme.palette.grey[300]
        },
        '.u-dropdown-menu': {
          '.MuiMenu-paper': {
            width: '100%',
            maxWidth: 328,
            maxHeight: 'none !important',
            overflow: 'initial',
            '.MuiMenu-list .custom-child-wrap .u-custom-autocomplete': {
              marginBottom: 0,
              '.MuiAutocomplete-root': {
                '.MuiTextField-root.MuiFormControl-root': {
                  '.MuiAutocomplete-inputRoot': {
                    color: theme.palette.grey[600],
                    '.MuiAutocomplete-endAdornment': {
                      '.MuiAutocomplete-popupIndicator': {
                        transform: 'rotate(0deg)',
                        backgroundColor: 'transparent !important'
                      }
                    },
                    '&:hover': {
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.grey[900]
                      }
                    }
                  }
                }
              },
              '.MuiAutocomplete-popper': {
                position: 'relative !important',
                top: '0 !important',
                transform: 'none !important',
                width: '100% !important',
                '.MuiAutocomplete-paper': {
                  border: 'none',
                  boxShadow: 'none',
                  '.MuiAutocomplete-listbox': {
                    padding: 0,
                    borderWidth: '0 !important',
                    borderStyle: 'solid !important',
                    borderColor: 'transparent !important',
                    borderRadius: '0 !important',
                    overflow: 'auto',
                    maxHeight: 220,
                    boxShadow: '0 0 0 transparent !important',
                    '@supports (-moz-appearance:none)': {
                      scrollbarWidth: 'thin'
                    },
                    '&::-webkit-scrollbar': {
                      width: 7,
                      height: 7,
                      borderRadius: 8
                    },
                    '&::-webkit-scrollbar-track': {
                      padding: '0 1px',
                      backgroundColor: '#f0f0f0',
                      borderRadius: 8
                    },
                    '&::-webkit-scrollbar-thumb': {
                      transition: 'all 0.3s',
                      backgroundColor: '#cdcdcd',
                      borderRadius: 8
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      backgroundColor: '#a6a6a6'
                    },
                    '.MuiAutocomplete-option': {
                      minHeight: 48
                    }
                  }
                }
              }
            }
          }
        }
      },
      '&.selected-box': {
        borderColor: theme.palette.primary.main,
        '&:before': {
          backgroundColor: theme.palette.primary.main
        },
        '.add-list': {
          visibility: 'visible',
          opacity: 1,
          marginBottom: 0
        }
      }
    }
  }
}))
export const BundleListWrap = styled('div')(({ theme }) => ({
  '&.product-bundle-list': {
    position: 'relative',
    maxHeight: 'calc(100vh - 518px)',
    overflow: 'auto',
    transition: 'all 0.3s',

    '&::-webkit-scrollbar': {
      width: 0,
      height: 7
    },
    '&.selected-list': {
      maxHeight: 'calc(100vh - 585px)',
      marginBottom: 16
    },
    '.list-box': {
      backgroundColor: theme.palette.common.white,
      borderRadius: 8,
      marginBottom: 8,
      padding: '12px 0 16px 12px',
      '&:last-child': {
        marginBottom: 0
      },
      '.list-box-details': {
        flex: 1,
        '.title-sm': {
          fontWeight: theme.typography.fontWeightBold
        },
        '.mini-list': {
          '.mini-list-item': {
            position: 'relative',
            '&:after': {
              content: "''",
              position: 'absolute',
              top: 0,
              right: -8,
              width: 1,
              height: '100%',
              backgroundColor: theme.palette.divider
            },
            '&:last-child': {
              '&:after': {
                display: 'none'
              }
            }
          }
        }
      }
    }
  }
}))
