import "./accessories-list-card-drawer-content.scss";
import { Button, Image } from "@src/components/atoms";

import Image1 from "../../../../shared/assets/img/accessories-list-card-drawer-content-img.png";

export const AccessoriesListCardDrawerContent = () => {
  return (
    <>
      <div className="accessories-full-details">
        <div className="accessories-full-details__left">
          <div className="accessories-full-details__image">
            <Image src={Image1} alt="image" aspectRatio="1:1" />
          </div>
        </div>
        <div className="accessories-full-details__right">
          <div className="accessories-full-details__right--inner">
            <div className="text--subsection-1">Paint Protection Wrap</div>
            <div className="accessories-full-details__right--price-detail">
              <div className="accessories-full-details__right--price-section">
                <span className="card-select-default-checked text--body-2">
                  Monthly cost:
                </span>
                <div className="text--promo-focus-price">
                  $X,XXX<span className="text--body-1">/month</span>{" "}
                </div>
                <p className="text--label">$X,XXX total</p>
              </div>
              <p className="text--body-2">
                A clear, protective automotive film which keeps your car looking
                newer, longer! This film application reduces the amount of chips
                caused by road debris and protects against cracking, yellowing
                and fading.
              </p>
            </div>

            <p className="text--disclaimer text-muted">
              Upgraded packages also available. Ex: (full hood, mirrors, side
              skirts).
            </p>
          </div>

          <div className="accessories-full-details__right--links-section">
            <Button
              htmlType={"button"}
              text="Play video"
              linkButton
              withIcon
              dataIcon="play"
            />

            <Button
              htmlType={"button"}
              text="Download brochure"
              linkButton
              withIcon
              dataIcon="download"
            />
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
};
