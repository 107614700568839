import { TradeIn } from "@src/components/organisms/build-your-deal";
import { BaseLayout } from "@src/layouts";

export const TradeInPage = () => {
  return (
    <BaseLayout>
      <TradeIn />
    </BaseLayout>
  );
};
