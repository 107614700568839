import { TabWrap } from "@modules/customerDetails/pages/customerDetails/customerDetailsStyle";
import { useTheme } from "@mui/material";
import {
    Box,
    Button,
    Dialog,
    Grid,
    Icon,
    Skeleton,
    Typography,
} from "@ntpkunity/controls";
import { useEffect, useState, FC } from "react";
import ReactJson from "react-json-view";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ThirdParties } from "enums/ThirdParties";
import type { IExperianResponse, IThirdPartyResponse } from "Interface";
import { convertToPascalCase, formatedDate } from "utilities/methods";
import {
    useGetCustomerThirdPartyResponseHistory,
    useDocumentURLByArtifactId,
} from "common/hooks/useCustomerData";
import { useParams } from "react-router";
import { QueryKeys } from "enums/UseQueryKeys";
import noPreviewimImage from "../../../../shared/assets/images/no-preview-img.svg";
import DisableLoader from "@shared/assets/images/loader-disabled.gif";

export const ThridPartyResponsesDetailModal: FC<{
    openPopUp: boolean;
    setPopUpState;
    thirdPartyValue: ThirdParties;
    customerProfile;
    identifier;
    index;
}> = ({
    openPopUp,
    setPopUpState,
    thirdPartyValue,
    customerProfile,
    identifier,
    index,
}) => {
        const theme = useTheme();
        const params = useParams();
        const [thirdPartyModalResponse, setThirdPartyResponseModal] = useState<
            IThirdPartyResponse | IExperianResponse
        >();
        const [selfieImage, setSelfieImage] = useState(undefined);
        const [backImage, setBackImage] = useState(undefined);
        const [frontImage, setFrontImage] = useState(undefined);
        const [dialogOpened, setDialogOpened] = useState<boolean>(false);
        const [getValue, setvalue] = useState("");
        const [selectedImageArtifactId, setSelectedImageArtifactId] = useState("");
        const [licenseImageType, setLicenseImageType] = useState("");
        const [rotateImage, setRotateImage] = useState(0);
        const [toggleClass, settoggleClass] = useState(false);
        const [isImageDownloading, setIsImageDownloading] = useState(false);
        const [columnFilters, setColumnFilters] = useState(
            `reference_id=${encodeURIComponent(
                params?.reference_id
            )}&type_list=${thirdPartyValue.toLowerCase()}&include_images=true&include_response=true&identifier=${identifier}`
        );
        const { data: getThirdPartyResponse, isLoading } =
            useGetCustomerThirdPartyResponseHistory(
                columnFilters,
                QueryKeys.GET_THIRD_PARTY_MODAL_RESPONSE
            );
        const { mutate: downloadDocument, isLoading: isLoadingDownload } =
        useDocumentURLByArtifactId();
        const documentURLByArtifactId = async (artifactId) => {
            return new Promise((resolve, reject) => {
                downloadDocument(
                    { artifect_id: artifactId },
                    {
                        onSuccess: ({ data }) => {
                            resolve(data?.document_url);
                        },
                        onError: (error) => {
                            reject(error);
                        },
                    }
                );
            });
        };
        useEffect(() => {
            if (getThirdPartyResponse != undefined) {
                getThirdPartyResponse?.data?.map(async (element) => {
                    if (element?.license_detail?.front_image != null) {
                        setFrontImage(
                            await documentURLByArtifactId(
                                element?.license_detail?.front_image
                            )
                        );
                    }
                    if (element?.license_detail?.back_image != null) {
                        setBackImage(
                            await documentURLByArtifactId(
                                element?.license_detail?.back_image
                            )
                        );
                    }
                    if (element?.license_detail?.selfie_image != null) {
                        setSelfieImage(
                            await documentURLByArtifactId(
                                element?.license_detail?.selfie_image
                            )
                        );
                    }
                });
            }
            setThirdPartyResponseModal(
                thirdPartyValue?.toLowerCase() === ThirdParties.EXPERIAN.toLowerCase()
                    ? getThirdPartyResponse?.data?.[index]
                    : getThirdPartyResponse?.data?.[0]
            );
        }, [getThirdPartyResponse?.data, index]);
        const HANDLE_LICENCE_IMAGE_DOWNLOAD = async () => {
            setIsImageDownloading(true);
            downloadImage(await documentURLByArtifactId(selectedImageArtifactId), `${licenseImageType}.png`)
        }
        const downloadImage = async (signedUrl, filename) => {
            // Fetch the image data
            const response = await fetch(signedUrl);
            const blob = await response.blob();
    
            // Create a temporary anchor element and initiate download
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            setIsImageDownloading(false);
        }
        const setQueryString = () => {
            const query_string = `reference_id=${encodeURIComponent(
                params?.reference_id
            )}&type_list=${thirdPartyValue.toLowerCase()}&include_images=true&include_response=true&identifier=${identifier}`;
            return query_string;
        };
        useEffect(() => {
            setColumnFilters(setQueryString);
        }, [identifier, thirdPartyValue, index]);
        const socureResponse = thirdPartyModalResponse as IThirdPartyResponse;
        const experianResponse = thirdPartyModalResponse as IExperianResponse;
        const renderRespectiveFields = (thirdPartyValue) => {
            switch (thirdPartyValue) {
                case ThirdParties.MITEK:
                    return (
                        <>
                            <Grid
                                theme={theme}
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                sx={{ mb: 3.5 }}
                            >
                                <Grid item xs={12} sm={6} md={(thirdPartyModalResponse as IThirdPartyResponse)?.license_detail?.is_pdf_417 ? 7 : 4} lg={(thirdPartyModalResponse as IThirdPartyResponse)?.license_detail?.is_pdf_417 ? 7 : 4} theme={theme}>
                                    <Box theme={theme} className="image-card">
                                        {isLoadingDownload ? (
                                            <Skeleton
                                                width={"100%"}
                                                height={274}
                                                theme={theme}
                                            ></Skeleton>
                                        ) : (
                                            <Box
                                                className={
                                                    (thirdPartyModalResponse as IThirdPartyResponse)
                                                        ?.license_detail?.front_image
                                                        ? "image-box image-show"
                                                        : "image-box image-hide"
                                                }
                                                theme={theme}
                                            >
                                                {frontImage ? (
                                                    <img
                                                        className="image-response"
                                                        src={frontImage ?? frontImage}
                                                        alt=""
                                                        onClick={() => {
                                                            setvalue(frontImage);
                                                            setDialogOpened(true);
                                                            setLicenseImageType('Front');
                                                            setSelectedImageArtifactId(getThirdPartyResponse?.data?.[0]?.license_detail?.front_image);
                                                            setRotateImage(0)
                                                        }}
                                                    />
                                                ) : (
                                                    <Icon name="DoubleImageIcon" />
                                                )}
                                            </Box>
                                        )}
                                        {isLoading ? (
                                            <Skeleton
                                                height={20}
                                                theme={theme}
                                                sx={{ mt: 2 }}
                                            ></Skeleton>
                                        ) : (
                                            <Typography
                                                variant="body2"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                Driver's License Front
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>
                                {!(thirdPartyModalResponse as IThirdPartyResponse)?.license_detail?.is_pdf_417 &&
                                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                                        <Box theme={theme} className="image-card">
                                            {isLoadingDownload ? (
                                                <Skeleton
                                                    width={"100%"}
                                                    height={274}
                                                    theme={theme}
                                                ></Skeleton>
                                            ) : (
                                                <Box
                                                    className={
                                                        (thirdPartyModalResponse as IThirdPartyResponse)
                                                            ?.license_detail?.back_image
                                                            ? "image-box image-show"
                                                            : "image-box image-hide"
                                                    }
                                                    theme={theme}
                                                >
                                                    {backImage &&
                                                        (thirdPartyModalResponse as IThirdPartyResponse)
                                                            ?.license_detail?.is_pdf_417 === false ? (
                                                        <img
                                                            className="image-response"
                                                            src={backImage ?? backImage}
                                                            alt=""
                                                            onClick={() => {
                                                                setvalue(backImage);
                                                                setLicenseImageType('Back');
                                                                setSelectedImageArtifactId(getThirdPartyResponse?.data?.[0]?.license_detail?.back_image);
                                                                setDialogOpened(true);
                                                                setRotateImage(0)
                                                            }}
                                                        />
                                                    ) : (thirdPartyModalResponse as IThirdPartyResponse)
                                                        ?.license_detail?.is_pdf_417 === true ? (
                                                        <img src={noPreviewimImage} />
                                                    ) : (
                                                        <Icon name="DoubleImageIcon" />
                                                    )}
                                                </Box>
                                            )}
                                            {isLoading ? (
                                                <Skeleton
                                                    height={20}
                                                    theme={theme}
                                                    sx={{ mt: 2 }}
                                                ></Skeleton>
                                            ) : (
                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    rowGap={5}
                                                    theme={theme}
                                                >
                                                    Driver's License Back
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                }
                                <Grid item xs={12} sm={6} md={(thirdPartyModalResponse as IThirdPartyResponse)?.license_detail?.is_pdf_417 ? 5 : 4} lg={(thirdPartyModalResponse as IThirdPartyResponse)?.license_detail?.is_pdf_417 ? 5 : 4} theme={theme}>
                                    <Box theme={theme} className="image-card">
                                        {isLoadingDownload ? (
                                            <Skeleton
                                                width={"100%"}
                                                height={274}
                                                theme={theme}
                                            ></Skeleton>
                                        ) : (
                                            <Box className="image-box image-hide" theme={theme}>
                                                {selfieImage ? (
                                                    <img
                                                        className="image-response"
                                                        src={selfieImage ?? selfieImage}
                                                        alt=""
                                                        onClick={() => {
                                                            setvalue(selfieImage ?? selfieImage);
                                                            setLicenseImageType('Selfie');
                                                            setSelectedImageArtifactId(getThirdPartyResponse?.data?.[0]?.license_detail?.selfie_image);
                                                            setDialogOpened(true);
                                                            setRotateImage(0)
                                                        }}
                                                    />
                                                ) : (
                                                    <Icon name="DoubleImageIcon" />
                                                )}
                                            </Box>
                                        )}
                                        {isLoading ? (
                                            <Skeleton
                                                height={20}
                                                theme={theme}
                                                sx={{ mt: 2 }}
                                            ></Skeleton>
                                        ) : (
                                            <Typography
                                                variant="body2"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                Selfie Image
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                            {isLoading ? (
                                <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                            ) : (
                                <Box theme={theme} sx={{ mb: 4, mt: 4 }}>
                                    <hr />
                                </Box>
                            )}
                            <Grid
                                theme={theme}
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                sx={{ mb: 3.5 }}
                            >
                                {isLoading ? (
                                    <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                                ) : (
                                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                                        <Box className="label" theme={theme}>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                Full Name
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="h4"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                {`${(thirdPartyModalResponse as IThirdPartyResponse)
                                                    ?.license_detail?.first_name
                                                    } ${(thirdPartyModalResponse as IThirdPartyResponse)
                                                        ?.license_detail?.last_name
                                                    }`}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {isLoading ? (
                                    <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                                ) : (
                                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                                        <Box className="label" theme={theme}>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                License Number
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="h4"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                {
                                                    (thirdPartyModalResponse as IThirdPartyResponse)
                                                        ?.license_detail?.license_number
                                                }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {isLoading ? (
                                    <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                                ) : (
                                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                                        <Box className="label" theme={theme}>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                License Issuing State
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="h4"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                {
                                                    (thirdPartyModalResponse as IThirdPartyResponse)
                                                        ?.license_detail?.address?.state_name
                                                }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {isLoading ? (
                                    <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                                ) : (
                                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                                        <Box className="label" theme={theme}>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                License Issuing Date
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="h4"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                {(thirdPartyModalResponse as IThirdPartyResponse)
                                                    ?.license_detail?.issue_date === null
                                                    ? ""
                                                    : formatedDate(
                                                        (thirdPartyModalResponse as IThirdPartyResponse)
                                                            ?.license_detail?.issue_date,
                                                        "MM/DD/YYYY"
                                                    )}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {isLoading ? (
                                    <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                                ) : (
                                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                                        <Box className="label" theme={theme}>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                Expiration
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="h4"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                {(thirdPartyModalResponse as IThirdPartyResponse)
                                                    ?.license_detail?.expiry_date === null
                                                    ? ""
                                                    : formatedDate(
                                                        (thirdPartyModalResponse as IThirdPartyResponse)
                                                            ?.license_detail?.expiry_date,
                                                        "MM/DD/YYYY"
                                                    )}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {isLoading ? (
                                    <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                                ) : (
                                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                                        <Box className="label" theme={theme}>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                Date of Birth
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="h4"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                {(thirdPartyModalResponse as IThirdPartyResponse)
                                                    ?.license_detail?.date_of_birth === null
                                                    ? ""
                                                    : formatedDate(
                                                        (thirdPartyModalResponse as IThirdPartyResponse)
                                                            ?.license_detail?.date_of_birth,
                                                        "MM/DD/YYYY"
                                                    )}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {isLoading ? (
                                    <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                                ) : (
                                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                                        <Box className="label" theme={theme}>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                Status
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="h4"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                {
                                                    (thirdPartyModalResponse as IThirdPartyResponse)
                                                        ?.license_detail?.status
                                                }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                            {isLoading ? (
                                <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                            ) : (
                                <Box theme={theme} sx={{ mb: 4, mt: 4 }}>
                                    <hr />
                                </Box>
                            )}
                        </>
                    );
                case ThirdParties.SOCURE:
                    return (
                        <>
                            <Box theme={theme} className="socure-modal">
                                <Typography theme={theme} variant="h3" component="h3">
                                    {`${customerProfile?.first_name} ${customerProfile?.middle_name} ${customerProfile?.last_name}`}
                                </Typography>
                                <Box
                                    theme={theme}
                                    display="flex"
                                    alignItems="center"
                                    sx={{ mt: 1 }}
                                >
                                    {/* {
                                    (thirdPartyResponse?.status === 'accept') ?
                                        <Icon name="GreenCheckCircleIcon" /> :
                                        <Icon name="InfoErrorIcon" className="error" />
                                } */}
                                    <Typography theme={theme} variant="body2" component="span">
                                        Status:
                                    </Typography>
                                    <Typography
                                        theme={theme}
                                        variant="body2"
                                        component="span"
                                        sx={{ ml: 1 }}
                                    >
                                        <b>
                                            {thirdPartyValue !== ThirdParties.EXPERIAN
                                                ? convertToPascalCase(
                                                    (thirdPartyModalResponse as IThirdPartyResponse)
                                                        ?.status
                                                )
                                                : (
                                                    thirdPartyModalResponse as unknown as IExperianResponse
                                                )?.resultCode}
                                        </b>
                                    </Typography>
                                </Box>
                            </Box>
                            <Box theme={theme} mt={4} mb={4}>
                                <hr />
                            </Box>
                        </>
                    );
                case ThirdParties.EXPERIAN:
                    return (
                        <>
                            <Box theme={theme} className="socure-modal">
                                <Typography theme={theme} variant="h3" component="h3">
                                    {customerProfile?.first_name ? customerProfile?.first_name + " " : ""}
                                    {customerProfile?.middle_name ? customerProfile?.middle_name + " " : ""}
                                    {customerProfile?.last_name ? customerProfile?.last_name : ""}
                                </Typography>
                                <Box
                                    theme={theme}
                                    display="flex"
                                    alignItems="center"
                                    sx={{ mt: 1 }}
                                >
                                    <Typography theme={theme} variant="body2" component="span">
                                        Status:
                                    </Typography>
                                    <Typography
                                        theme={theme}
                                        variant="body2"
                                        component="span"
                                        sx={{ ml: 1 }}
                                    >
                                        <b>
                                            {thirdPartyValue !== ThirdParties.EXPERIAN
                                                ? convertToPascalCase(
                                                    (thirdPartyModalResponse as IThirdPartyResponse)
                                                        ?.status
                                                )
                                                : (
                                                    thirdPartyModalResponse as unknown as IExperianResponse
                                                )?.resultCode}
                                        </b>
                                    </Typography>
                                </Box>
                            </Box>
                            <Box theme={theme} mt={4} mb={4}>
                                <hr />
                            </Box>
                            <Grid
                                theme={theme}
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                sx={{ mb: 3.5 }}
                            >
                                {isLoading ? (
                                    <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                                ) : (
                                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                                        <Box className="label" theme={theme}>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                Name
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="h4"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                {
                                                    `${(
                                                        thirdPartyModalResponse as unknown as IExperianResponse
                                                    )?.creditReport?.data?.Response?.expCreditProfile?.[0]?.consumerIdentity?.name?.[0]?.firstName || ''} 
                                                ${(
                                                        thirdPartyModalResponse as unknown as IExperianResponse
                                                    )?.creditReport?.data?.Response?.expCreditProfile?.[0]?.consumerIdentity?.name?.[0]?.middleName || ''} 
                                                ${(
                                                        thirdPartyModalResponse as unknown as IExperianResponse
                                                    )?.creditReport?.data?.Response?.expCreditProfile?.[0]?.consumerIdentity?.name?.[0]?.surname || ''}`
                                                }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {isLoading ? (
                                    <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                                ) : (
                                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                                        <Box className="label" theme={theme}>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                Address
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="h4"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                {
                                                    `${(
                                                        thirdPartyModalResponse as unknown as IExperianResponse
                                                    )?.creditReport?.data?.Response?.expCreditProfile?.[0]?.addressInformation?.[0]?.streetPrefix || ''} 
                                                ${(
                                                        thirdPartyModalResponse as unknown as IExperianResponse
                                                    )?.creditReport?.data?.Response?.expCreditProfile?.[0]?.addressInformation?.[0]?.streetName || ''} 
                                                ${(
                                                        thirdPartyModalResponse as unknown as IExperianResponse
                                                    )?.creditReport?.data?.Response?.expCreditProfile?.[0]?.addressInformation?.[0]?.streetSuffix || ''}
                                                ${(
                                                        thirdPartyModalResponse as unknown as IExperianResponse
                                                    )?.creditReport?.data?.Response?.expCreditProfile?.[0]?.addressInformation?.[0]?.city || ''}
                                                ${(
                                                        thirdPartyModalResponse as unknown as IExperianResponse
                                                    )?.creditReport?.data?.Response?.expCreditProfile?.[0]?.addressInformation?.[0]?.state || ''}
                                                ${(
                                                        thirdPartyModalResponse as unknown as IExperianResponse
                                                    )?.creditReport?.data?.Response?.expCreditProfile?.[0]?.addressInformation?.[0]?.zipCode || ''}`
                                                }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {isLoading ? (
                                    <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                                ) : (
                                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                                        <Box className="label" theme={theme}>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                Status
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="h4"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                {
                                                    (
                                                        thirdPartyModalResponse as unknown as IExperianResponse
                                                    )?.creditReport?.data?.Response?.decisionResults?.productDecision?.[0]?.decisionCategory
                                                }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {isLoading ? (
                                    <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                                ) : (
                                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                                        <Box className="label" theme={theme}>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                Credit Decision
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="h4"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                {
                                                    `${(
                                                        thirdPartyModalResponse as unknown as IExperianResponse
                                                    )?.creditReport?.data?.Response?.decisionResults?.productDecision?.[0]?.policyRuleSet?.[0]?.policyRule} 
                                                ${(
                                                        thirdPartyModalResponse as unknown as IExperianResponse
                                                    )?.creditReport?.data?.Response?.decisionResults?.productDecision?.[0]?.policyRuleSet?.[0]?.reasonCode}`
                                                }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {isLoading ? (
                                    <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                                ) : (
                                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                                        <Box className="label" theme={theme}>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                Credit Score
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="h4"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                {
                                                    (
                                                        thirdPartyModalResponse as unknown as IExperianResponse
                                                    )?.creditReport?.data?.Response?.expCreditProfile?.[0]?.riskModel?.find(f => f.modelIndicator === 'AF')?.score || '-'
                                                }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {isLoading ? (
                                    <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                                ) : (
                                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                                        <Box className="label" theme={theme}>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                Self Reported Income
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="h4"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })
                                                    ?.format(((thirdPartyModalResponse as unknown as IExperianResponse)?.decisionRequest?.Request?.additionalAttributes?.find(f => f.id === 'ANNUALINC')?.value || 0))
                                                }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {isLoading ? (
                                    <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                                ) : (
                                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                                        <Box className="label" theme={theme}>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                Experian Reported Income
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="h4"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                {
                                                    (((
                                                        thirdPartyModalResponse as unknown as IExperianResponse
                                                    )?.creditReport?.data?.Response?.expCreditProfile?.[0]?.riskModel?.find(f => f.modelIndicator === 'IW')?.score || 0) * 1000)?.toLocaleString("en-GB", {
                                                        style: "currency",
                                                        currency: "GBP",
                                                    })
                                                }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {isLoading ? (
                                    <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                                ) : (
                                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                                        <Box className="label" theme={theme}>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                Income Ratio
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="h4"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                {
                                                    (thirdPartyModalResponse as unknown as IExperianResponse)?.creditReport?.data?.Response?.expCreditProfile?.[0]?.riskModel ?
                                                        (
                                                            Math.round(
                                                                (
                                                                    (
                                                                        (
                                                                            (
                                                                                (thirdPartyModalResponse as unknown as IExperianResponse)?.decisionRequest?.Request?.additionalAttributes?.find(f => f.id === 'ANNUALINC')?.value || 0
                                                                            )
                                                                            /
                                                                            (
                                                                                ((thirdPartyModalResponse as unknown as IExperianResponse)?.creditReport?.data?.Response?.expCreditProfile?.[0]?.riskModel?.find(f => f.modelIndicator === 'IW')?.score || 0) * 1000
                                                                            )
                                                                        ) * 100
                                                                    )
                                                                )
                                                            ).toLocaleString("en-GB") + "%"
                                                        )
                                                        : "-"
                                                }

                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid
                                theme={theme}
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                sx={{ mb: 3.5 }}
                            >
                                {isLoading ? (
                                    <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                                ) : (
                                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                                        <Box className="label" theme={theme}>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                Other Name(s)
                                            </Typography>
                                            {
                                                (
                                                    thirdPartyModalResponse as unknown as IExperianResponse
                                                )?.creditReport?.data?.Response?.expCreditProfile?.[0]?.consumerIdentity?.name?.map((item, index) => {
                                                    if (index > 0) {
                                                        return (
                                                            <Typography
                                                                variant="body1"
                                                                component="h4"
                                                                rowGap={5}
                                                                theme={theme}
                                                                key={index}
                                                            >
                                                                {
                                                                    `${item?.firstName || ''} ${item?.middleName || ''} ${item?.surname || ''}`
                                                                }
                                                            </Typography>
                                                        )
                                                    }
                                                })
                                            }
                                        </Box>
                                    </Grid>
                                )}
                                {isLoading ? (
                                    <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                                ) : (
                                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                                        <Box className="label" theme={theme}>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                rowGap={5}
                                                theme={theme}
                                            >
                                                Other Address(es)
                                            </Typography>
                                            {
                                                (
                                                    thirdPartyModalResponse as unknown as IExperianResponse
                                                )?.creditReport?.data?.Response?.expCreditProfile?.[0]?.addressInformation?.map((item, index) => {
                                                    if (index > 0) {
                                                        return (
                                                            <Typography
                                                                variant="body1"
                                                                component="h4"
                                                                rowGap={5}
                                                                theme={theme}
                                                                key={index}
                                                            >
                                                                {
                                                                    `${item?.streetPrefix || ''} ${item?.streetName || ''} ${item?.streetSuffix || ''} ${item?.city || ''}
                                                                ${item?.state || ''} ${item?.zipCode || ''}`
                                                                }
                                                            </Typography>
                                                        )
                                                    }
                                                })
                                            }
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                            <Box theme={theme} mt={4} mb={4}>
                                <hr />
                            </Box>
                        </>
                    );
            }
        };
        return (
            <>
                <Dialog
                    variant={undefined}
                    // size="xl"
                    title={`${thirdPartyValue} Response`}
                    open={openPopUp}
                    onCloseDialog={() => {
                        setPopUpState(false);
                    }}
                    customFooter={<></>}
                    theme={theme}
                    children={
                        <>
                            <TabWrap className="tab-wrap tab-wrap-modal">
                                {renderRespectiveFields(thirdPartyValue)}
                                <Box theme={theme} className="response-copy">
                                    <Typography
                                        variant="body2"
                                        component="h4"
                                        rowGap={5}
                                        theme={theme}
                                        className="response"
                                    >
                                        <ReactJson
                                            src={
                                                thirdPartyValue !== ThirdParties.EXPERIAN
                                                    ? socureResponse &&
                                                    JSON.parse(socureResponse?.third_party_response)
                                                    : experianResponse &&
                                                    (experianResponse as unknown as IExperianResponse)
                                                        ?.creditReport
                                            }
                                            name="response"
                                            displayDataTypes={false}
                                            collapseStringsAfterLength={50}
                                            quotesOnKeys={false}
                                            sortKeys={true}
                                        />
                                        <CopyToClipboard
                                            text={
                                                thirdPartyValue !== ThirdParties.EXPERIAN
                                                    ? socureResponse &&
                                                    JSON.stringify(
                                                        JSON.parse(socureResponse?.third_party_response)
                                                    )
                                                    : experianResponse &&
                                                    JSON.stringify(
                                                        (experianResponse as unknown as IExperianResponse)
                                                            ?.creditReport
                                                    )
                                            }
                                        >
                                            <Button
                                                className="copy-text"
                                                defaultBtn
                                                theme={theme}
                                                iconText={<Icon name="Copy" />}
                                            />
                                        </CopyToClipboard>
                                    </Typography>
                                </Box>
                            </TabWrap>
                        </>
                    }
                />
                <Dialog
                    theme={theme}
                    size="md"
                    title={'Third party responses'}
                    onCloseDialog={() => { setDialogOpened(false) }}
                    open={dialogOpened}
                    children={
                        <>
                            <TabWrap className="license-modal">
                                <Box theme={theme} textAlign="center">
                                    
                                    <Box theme={theme} className={`img-rotate ${toggleClass && "max"}`}
                                        style={{ rotate: `${rotateImage}deg` }}>
                                        <img
                                            className="image-response"
                                            src={getValue}
                                            alt=""
                                        />
                                    </Box>
                                </Box>
                            </TabWrap>
                        </>
                    }
                    customFooter={
                        <>
                            <Grid container theme={theme} spacing={2}>
                                <Grid theme={theme} item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button 
                                        theme={theme} 
                                        secondary 
                                        text="Rotate"
                                        className="btn-rotate"
                                        onClick={() => {
                                            setRotateImage(-90 + rotateImage)
                                            settoggleClass(!toggleClass)
                                        }}
                                        startIcon={<Icon name="ResetIcon" />}
                                    />
                                </Grid>
                                <Grid theme={theme} item xs={6}>
                                    <Button
                                        theme={theme}
                                        primary
                                        text="Download"
                                        onClick={HANDLE_LICENCE_IMAGE_DOWNLOAD}
                                        disabled={isImageDownloading}
                                        startIcon={<Icon name="SaveIcon" />}
                                        endIcon={
                                            (isImageDownloading) && (
                                                <img src={DisableLoader} alt="Loader" />
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }
                />
            </>
        );
    };
