import { useState } from 'react'
import { useTheme } from '@mui/material'
import { Button, Grid, Typography, Box, Icon } from '@ntpkunity/controls'
import { VehicleCardUi } from '@components'
import { ILender, IMultiLenderProps } from './multi-lender.types'

const MultiLenderDialog = ({
  selectedLenders = [],
  lenders,
  orderDetail,
  setSelectedLenders
}: IMultiLenderProps) => {
  const theme = useTheme()
  const [hoveredEl, setHoveredEl] = useState<number | undefined>(undefined)

  const handleAddLender = (id: number) => {
    setSelectedLenders([...selectedLenders, id])
  }
  const handleRemoveLender = (id: number) => {
    setSelectedLenders([...selectedLenders?.filter((item) => item !== id)])
  }

  const handleMouseEnter = (isAdded: boolean, id: number) => {
    if (isAdded) setHoveredEl(id)
  }

  const handleMouseLeave = () => setHoveredEl(undefined)

  return (
    <>
      <Box theme={theme} sx={{ textAlign: 'center', mb: 2 }}>
        <Typography theme={theme} variant="body2" component="div" className="description">
          Please select the lenders below to whom you want to submit this deal
        </Typography>
      </Box>
      <Grid theme={theme} spacing={2} container sx={{ mb: 5 }}>
        {lenders
          ?.filter((lender: ILender) => !lender?.is_already_selected)
          ?.map((lender: ILender, index: number) => {
            const isAdded = selectedLenders?.includes(lender?.id)
            return (
              <Grid theme={theme} item xs={12} md={6} lg={3} key={index}>
                <VehicleCardUi theme={theme} className="vehicle-card full-width">
                  <Box theme={theme} className="vc-img-wrap h-120">
                    <Box theme={theme}>
                      <Icon name="LenderConfigurations" />
                    </Box>
                  </Box>
                  <Typography theme={theme} className="price" component="div">
                    {lender?.name}
                  </Typography>
                  <Button
                    disabled={lender?.default_lender || lender.id === orderDetail?.lender_id}
                    theme={theme}
                    fullWidth
                    primary={isAdded}
                    secondary={!isAdded}
                    text={isAdded ? (hoveredEl === lender?.id ? 'Remove' : 'Added') : 'Add'}
                    onMouseEnter={() => handleMouseEnter(isAdded, lender?.id)}
                    onMouseLeave={handleMouseLeave}
                    onClick={
                      isAdded
                        ? () => handleRemoveLender(lender?.id)
                        : () => handleAddLender(lender?.id)
                    }
                  />
                </VehicleCardUi>
              </Grid>
            )
          })}
      </Grid>
    </>
  )
}
export default MultiLenderDialog
