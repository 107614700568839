import { FC, useEffect, useState } from "react";
import { Link, useTheme } from "@mui/material";
import {
  Dialog,
  Box,
  IBoxProps,
  Button,
  Grid,
  Typography,
} from "@ntpkunity/controls";
import { styled } from "@mui/material/styles";
import { useModal } from "react-modal-hook";
import { useGetAddonByTrim } from "services/options.service";
import { IVehicleDetail } from "Interface";
import { useQueryClient } from "react-query";
import { QueryKeys } from "Enums";

const VC = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.vehicle-card": {
    fontFamily: theme.typography.fontFamily,
    border: "1px solid" + theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    padding: 24,
    transition: "all 0.3s ease-in-out 0s",
    width: "100%",
    display: "inline-block",
    textAlign: "center",
    position: "relative",
    ".tag": {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.caption.fontSize,
      background: theme.palette.grey[700],
      color: theme.palette.common.white,
      borderRadius: +theme.shape.borderRadius * 2,
      fontWeight: theme.customVariables.fontWeightSemiBold,
      padding: "3px 8px",
      position: "absolute",
      top: "16px",
      left: "16px",
      lineHeight: "18px",
    },
    ".vc-img-wrap": {
      backgroundColor: theme.customVariables.tableStripedBg,
      borderRadius: 8,
      width: "100%",
      height: 120,
      display: "flex",
      verticalAlign: "middle",
      textAlign: "center",
      overFlow: "hidden",
      marginBottom: 16,
      img: {
        maxWidth: "100%",
        maxHeight: 120,
        width: "auto",
        height: "auto",
        display: "block",
        margin: "auto",
      },
    },
    ".title": {
      fontSize: theme.typography.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.grey[600],
      width: "100%",
      display: "block",
      clear: "both",
      whiteSpace: "nowrap",
      wordWrap: "break-word",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    ".price": {
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey[900],
      width: "100%",
      display: "block",
      clear: "both",
      marginBottom: 8,
    },
    ".link": {
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      margin: "auto",
      display: "inline-block",
      clear: "both",
      textDecoration: "none",
      marginBottom: 16,
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main,
        textDecoration: "underline !important",
      },
    },
    "&:hover": {
      boxShadow: "0 0 16px rgba(0, 0, 0, 0.16)",
    },
  },
}));

const DetailImg = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  width: "100%",
  display: "flex",
  verticalAlign: "middle",
  textAlign: "center",
  overFlow: "hidden",
  img: {
    maxWidth: "100%",
    maxHeight: "69vh",
    width: "auto",
    height: "auto",
    display: "block",
    margin: "auto",
  },
}));

const AddNewInventoryOption: FC<{
  setfinalOption: any;
  setcategory: any;
  vehicleData: IVehicleDetail;
  setlistChanged: any;
  dealer_code: string;
  addedOption: number[];
  setAddedOption: any;
}> = ({
  setfinalOption,
  setcategory,
  vehicleData,
  setlistChanged,
  dealer_code,
  addedOption,
  setAddedOption,
}) => {
  const theme = useTheme();
  const [categoryName, setCategoryName] = useState(setcategory);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [attachedOption, setattachedOption] = useState<any>();
  const [allOption, setallOption] = useState<any>([]);
  const { mutate: getDealerAddon, data: dealerOptions } = useGetAddonByTrim();
  const queryClient = useQueryClient();
  const allCurrencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const filteredCurrency = allCurrencies?.filter(
      (currency) => currency.id === companyProfile?.default_currency
    );
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [companyProfile]);

  const loadOptions = () => {
    let prev: any = [];
    prev = dealerOptions?.map((options: any) => {
      return options.id;
    });
    setallOption(prev);
  };
  const removeItem = (id: number) => {
    setattachedOption(attachedOption.filter((i) => i !== id));
    setAddedOption(addedOption.filter((i) => i !== id));
    // vehicleData.dealer_options = vehicleData.dealer_options.filter(
    //   (item: any) => item !== id
    // );
  };
  const addItem = (id: number) => {
    setattachedOption([...attachedOption, id]);
    setfinalOption([...attachedOption, id]);
    // vehicleData.dealer_options = vehicleData.dealer_options.concat([id]);
  };
  useEffect(() => {
    setattachedOption(addedOption);
  }, []);
  useEffect(() => {
    setCategoryName(setcategory);
  }, [attachedOption]);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        className="dialog-with-border"
        theme={theme}
        disablePortal
        title={selectedOption?.product_name}
        open={open}
        onCloseDialog={onClose}
        noFooter
        children={
          <DetailImg theme={theme}>
            <img src={selectedOption?.image[0]?.location} alt="option detail" />
          </DetailImg>
        }
      />
      <Grid theme={theme} spacing={2} container sx={{ mb: 5 }}>
        {categoryName?.map((addOnDetail: any, index: number) => (
          <Grid theme={theme} key={index} item xs={12} md={6} lg={3}>
            <VC theme={theme} className="vehicle-card" onLoad={loadOptions}>
              <Typography theme={theme} className="tag" component="span">
                {addOnDetail.offered_by}
              </Typography>
              <Box theme={theme} className="vc-img-wrap">
                <img
                  className="prd-img"
                  src={addOnDetail?.image[0]?.location}
                  alt="Option Detail"
                />
              </Box>
              <Typography
                theme={theme}
                component="div"
                id={"productNameOptions" + addOnDetail.product_name}
                className="title"
              >
                {addOnDetail.product_name}
              </Typography>
              <Typography
                theme={theme}
                component="div"
                id={"price" + addOnDetail.price.toLocaleString()}
                className="price"
              >
                {defaultCurrency}
                {addOnDetail.price.toLocaleString()}{" "}
                {addOnDetail?.installation_mode === "Pre-Installed" &&
                  (addOnDetail?.is_price_inclusive ? "(Inc.)" : "(Exc.)")}
              </Typography>
              <Link
                id={"viewDetails" + addOnDetail.id}
                className="link"
                onClick={() => {
                  setSelectedOption(addOnDetail);
                  setOpen(true);
                }}
              >
                View Details
              </Link>
              {addedOption?.find((item) => {
                return item == addOnDetail.id;
              }) ? (
                <Button
                  theme={theme}
                  fullWidth
                  secondary
                  id={"removeButtonCart" + addOnDetail.id}
                  text={"Remove"}
                  onClick={() => {
                    removeItem(addOnDetail.id);
                    setlistChanged(true);
                  }}
                />
              ) : (
                <Button
                  theme={theme}
                  fullWidth
                  primary
                  id="addButtonCart"
                  text={"Add"}
                  onClick={() => {
                    addItem(addOnDetail.id);
                    setlistChanged(true);
                  }}
                />
              )}
            </VC>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default AddNewInventoryOption;
