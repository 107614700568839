import {
  AdditionalInsuranceInfo,
  ContractInfo,
  DocumentInfo,
  PaymentInfo,
  VehicleAppointmentPickup,
} from "@src/components/molecules";
import "./state-card.scss";

export const StateCard = () => {
  const vehicleApptItems = [
    {
      key: "1",
      label: "What do I need to bring to pickup?",
      children:
        "You need to bring your ID, vehicle registration, and any paperwork provided.",
    },
  ];

  return (
    <>
      <div className="state-card-wrapper">
        <div className="bmw-container">
          <div className="state-card__main">
            <div className="state-card__inner">
              <div className="state-card__title">
                <h3 className="text--h3">Get a head start today.</h3>
                <span
                  className="bmw-icon bmw-icon--before data-icon"
                  data-icon="lock_unlocked"
                />
              </div>
              <div className="state-card__content">
                <VehicleAppointmentPickup
                  headerIcon="clock"
                  headerTitle="Vehicle pickup appointment."
                  statusText="To-do"
                  statusType="secondary"
                  bodyTitle="Saturday, October 19, 8:00 AM - 9:30 AM"
                  bodySubtitle="Next available timeslot:"
                  buttons={[
                    {
                      text: "Request appointment",
                      type: "default",
                      disabled: false,
                      onClick: () => ({}),
                    },
                    {
                      text: "Pick another time",
                      disabled: false,
                      onClick: () => ({}),
                    },
                  ]}
                  accordionItems={vehicleApptItems}
                  accordionDefaultActiveKey="0"
                />
                <DocumentInfo
                  statusText="To-do"
                  statusType="secondary"
                  headerIcon="document_text_lines"
                  headerTitle="Supporting documents."
                  contentBlocks={[
                    {
                      bodySubtitle: "First Document",
                      bodyTitle:
                        " Proof of eligibility for incentives & discounts.",

                      buttons: [
                        {
                          text: "Upload",
                          type: "default",
                          onClick: () => ({}),
                        },
                      ],
                    },
                    {
                      bodyTitle: "Proof of address.",
                      buttons: [
                        {
                          text: "Upload",
                          type: "default",
                          disabled: true,
                          onClick: () => ({}),
                        },
                      ],
                    },
                  ]}
                  additionalInfo="You may provide supporting documents online or at the dealer."
                />
                <ContractInfo
                  statusText="To-do"
                  statusType="secondary"
                  headerTitle="Contracts & documents."
                  headerIcon="register"
                  bodyTitle="Your contracts are ready for e-sign."
                  buttons={[
                    {
                      text: "E-sign ",
                      type: "default",
                      disabled: false,
                      onClick: () => ({}),
                    },
                    {
                      text: "Preview ",
                      disabled: true,
                      onClick: () => ({}),
                    },
                  ]}
                  additionalInfo="You may provide supporting documents online or at the dealer."
                />
              </div>
            </div>
            <div className="state-card__inner">
              <div className="state-card__title">
                <h3 className="text--h3">Coming up next.</h3>
                <span
                  className="bmw-icon bmw-icon--before data-icon"
                  data-icon="lock_locked"
                />
              </div>
              <div className="state-card__content">
                <AdditionalInsuranceInfo
                  headerTitle="Additional insurance information."
                  statusText="To-do"
                  statusType="secondary"
                  bodyTitle="Once your vehicle pickup appointment is confirmed, you’ll be able to provide additional insurance information."
                  bodySubtitle="Insurance provider"
                  buttons={[
                    {
                      text: "Provide now",
                      type: "primary",
                      disabled: true,
                      onClick: () => ({}),
                    },
                  ]}
                  additionalInfo="You may provide additional information online or at the dealer."
                />
                <PaymentInfo
                  headerTitle="Payment."
                  headerIcon="credit_card"
                  bodySubtitle="Due-at-Signing:"
                  bodyTitle={
                    <p className="text--body-1">
                      Once your contracts are signed, you may pay your amount
                      Due-at-Signing of{" "}
                      <span className="fw-bold">$X,XXX.XX</span> online or at
                      the dealer.
                    </p>
                  }
                  bodyButtons={[
                    {
                      text: "Pay now",
                      type: "primary",
                      disabled: true,
                      onClick: () => ({}),
                    },
                  ]}
                  footerSubtitle="Additional payment settings:"
                  footerTitle="Automatic recurring payments with EasyPay (optional)."
                  footerButtons={[{ text: "Set up", disabled: true }]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
