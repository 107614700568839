import { InsentivesAndDiscountsDrawerFooterProps } from "./insentives-and-discounts-drawer-footer.props";
import "./insentives-and-discounts-drawer-footer.scss";

export const InsentivesAndDiscountsDrawerFooter = ({
  price,
  footerActions,
}: InsentivesAndDiscountsDrawerFooterProps) => {
  return (
    <div className="insentives-and-discounts-drawer-footer">
      <div className="insentives-and-discounts-drawer-footer_price">
        {price}
      </div>
      <div className="insentives-and-discounts-drawer-footer_action">
        {footerActions}
      </div>
    </div>
  );
};
