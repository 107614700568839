import { Button } from "@src/components/atoms";
import { BundleCardProps } from "./bundle-card.props";
import "./bundle-card.scss";

export const BundleCard = ({
  headline,
  badge,
  price,
  terms,
  details,
  footer,
}: BundleCardProps) => {
  return (
    <>
      <div className="bundle-card">
        <div className="bundle-card__inner">
          {headline && (
            <div className="bundle-card__headline">
              <div className="bundle-card__headline-text">
                <div className="text--subsection-2">{headline}</div>
              </div>
              {badge && (
                <div className="bundle-card__headline-badge">
                  <span className="text--disclaimer bundle-card__headline-badge__item">
                    {badge}
                  </span>
                </div>
              )}
            </div>
          )}

          <div className="bundle-card__content">
            <div className="bundle-card__content__price">
              {price && (
                <div className="bundle-card__content__price__payment">
                  {price}
                </div>
              )}
              {terms && (
                <div className="bundle-card__content__price__terms">
                  {terms}
                </div>
              )}
            </div>
            {details && (
              <div className="bundle-card__content__details">{details}</div>
            )}
          </div>

          {footer && <div className="bundle-card__footer">{footer}</div>}
        </div>
      </div>
    </>
  );
};
