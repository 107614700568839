import { useTheme } from "@mui/material";
import { DriftChatHeaderWrap } from "./chat-header.style";
import { Box, Typography } from "@ntpkunity/controls";
import clsx from "clsx";
import { useChatServiceContext } from "@hooks";
import { getCurrentDealer, getDealerInfo } from "helpers/methods";

const ChatMessagesHeader = () => {
  const theme = useTheme();
  const { dealerOnline } = useChatServiceContext();
  const dealerInfo = getDealerInfo();
  const currentDealer = getCurrentDealer();
  const dealerName = dealerInfo?.dealer_name || currentDealer

  return (
    <DriftChatHeaderWrap theme={theme} className="messages-header-wrap">
      <Box theme={theme} className="sender-wrap">
        <Box theme={theme} className="sender-details">
          <span
            className={clsx("status", {
              online: dealerOnline,
              offline: !dealerOnline,
            })}
          />
          <Typography
            theme={theme}
            component="p"
            variant="subtitle1"
            className="sender-name"
          >
            {dealerName}
          </Typography>
        </Box>
      </Box>
    </DriftChatHeaderWrap>
  );
};

export default ChatMessagesHeader;
