import { FC } from 'react'
import { useTheme } from '@mui/material'
import { ScrollableTabs } from '@ntpkunity/controls'
import VehicleAmountDraft from './vehicle-amount-draft'
import { ScrollableTabsWrap } from '@components'
import AddOpptions from './add-options'
// import AddFees from './add-fees'

const OrderDetails: FC = () => {
  const theme = useTheme()
  return (
    <>
      <VehicleAmountDraft />
      <ScrollableTabsWrap theme={theme} className="scrollable-tabs-wrap sm">
        <ScrollableTabs
          theme={theme}
          items={[
            { title: 'Options', content: <AddOpptions /> },
            { title: 'F&I', content: <></> },
            { title: 'Rebates', content: <></> },
            { title: 'Fees', content: <></> }
          ]}
          defaultTabIndex={0}
          scrollButtons={false}
          onChange={() => {}}
        />
      </ScrollableTabsWrap>
    </>
  )
}

export default OrderDetails
