import { useTheme } from "@mui/material";
import {
  Box,
  Select,
  Typography,
  DatePicker,
  Button,
  Icon,
  DateRangePicker,
  DateRange,
} from "@ntpkunity/controls";
import { WorkqueueFilterWrap } from "./workqueue-filters.style";
import { useSelector } from "react-redux";
import { useState } from "react";

export const WorkqueueFilters = () => {
  const theme = useTheme();
  const [selectedCategory, setSelectedCategory] = useState("all");
  const workQueues = useSelector((state: any) => state.workQueue.workQueues);

  const uniqueStatuses = Array.from(
    new Set(workQueues.map((item) => item.status ?? "Unknown"))
  );

  const statusOptions = uniqueStatuses.map((status) => ({
    text: status,
    value: status,
  }));

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <WorkqueueFilterWrap theme={theme} className="table-widget">
      <Box theme={theme} className="custom-dropdown">
        <Typography
          theme={theme}
          className="text-body2 text-muted"
          variant="body2"
          component={"span"}
          display={"block"}
        >
          Select Category:
        </Typography>
        <Select
          theme={theme}
          disablePortal={false}
          items={[
            { text: "All Queues", value: "all" },
            ...statusOptions, 
          ]}
          onChange={handleCategoryChange}
          value={selectedCategory}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </Box>
      <Box theme={theme} className="multi-functions">
        <Box theme={theme} className="datepicker">
          <DateRange
            theme={theme}
            onChange={() => {}}
            disablePortal={true}
            placeholder={"Calendar"}
            endAdornment={
              <Icon name="CalendarIcon" className="calendar-icon" />
            }
            render={<input placeholder="Calendar" readOnly />}
            onFocus={() => {}}
          />
        </Box>
        <Box theme={theme} className="sort-btn">
          <Box theme={theme} className="custom-dropdown">
            <Icon name="AscDescIcon" className="sort-icon" />
            <Select
              theme={theme}
              disablePortal={false}
              items={[
                {
                  text: "Sort By",
                  value: "sort",
                  disabled: true,
                },
                {
                  text: "Ascending",
                  value: "asc",
                },
                {
                  text: "Decending",
                  value: "dsc",
                },
              ]}
              onChange={() => {}}
              value={"sort"}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            />
          </Box>
        </Box>
        <Button
          theme={theme}
          defaultBtn
          iconText={<Icon name="ResetIcon" />}
          onClick={() => {}}
        />
        <Button
          theme={theme}
          defaultBtn
          iconText={<Icon name="RefreshIcon" />}
          onClick={() => {}}
        />
        <Button
          theme={theme}
          defaultBtn
          iconText={<Icon name="SearchIcon" />}
          onClick={() => {}}
        />
      </Box>
    </WorkqueueFilterWrap>
  );
};
