import React, { useState, useEffect, useReducer } from "react";
import { useTheme } from "@mui/material";
import { PageWrap } from "./financing.style";
import {Navigation, QueryKeys} from "@constants";
import { Tab, PageHeader } from "@components";
import { Icon, Box, Snackbar, ScrollableTabs, CircleLoader } from "@ntpkunity/controls";
import {IStates, IVehicle} from "interfaces";
import { useQueryClient } from "react-query";
import { useAppContext } from "@app/context-provider";
import {
  pagesActionType,
  useJourneyLayoutContext,
} from "layouts/journey-layout/journey-layout-context-provider";
import { FinanceTab } from "@constants";
import { SnackbarUtility } from "@utilities";
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import {Tags, PageType, Stage, FeeTypes} from 'constants/enums';
import { user_session } from '@constants'
import { useParams } from 'react-router-dom'
import {useOrderContext, updateOrder, updateDealerFee} from 'pages/(order-management)/order/order-context-provider'
import {useFormContext} from "../../(order-management)/order/form-context-provider";
import ResidenceDetailsWrapper from "./warpper/residence-details-wrapper";
import EmploymentDetailsWrapper from "./warpper/employment-info-wrapper";
import PersonalFinanceDetailsWrapper
    from "./warpper/personal-finance-details-wrapper";
import LicenceDetailsWrapper from "./warpper/licence-details-wrapper";
import {useContactDetailsForm} from "../../../utilities/form/contact-details";
import {useResidenceDetailsForm} from "../../../utilities/form/residence-details";
import {useLicenseDetailsForm} from "../../../utilities/form/license-details";
import {useEmploymentDetailsForm} from "../../../utilities/form/employment-details";
import {usePersonalFinanceDetailsForm} from "../../../utilities/form/personal-finance-details";
import ContactDetailWrapper from "./warpper/contact-detail-wrapper";
import { mapPayload } from "helpers/lead";
import {
  useConvertQuotationToApplication,
  useGetDealerFeebyState,
  useSaveOrderRequest,
  useUpdateLead,
  useUpdateOrderRequest
} from "@hooks";
import {useWorkflowContext} from "@app/router-workflow";
import {FEE_MONTHLY_PAYMENT_BASED} from "../../../constants/fee";


export const FinancingPage = () => {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState<FinanceTab>(
      FinanceTab["Personal-Details"]
  );
  const {formStates, resetFormState} = useFormContext();
  const {state: appState} = useAppContext();
  const queryClient = useQueryClient();
  const {vin} = useParams()

  const vehicle: IVehicle = queryClient.getQueryData([QueryKeys.VEHICLE, vin]);
  const customerData: any = queryClient.getQueryData(QueryKeys.GET_CUSTOMER_BY_EMAIL);
  const contactData: any = queryClient.getQueryData(QueryKeys.CUSTOMER_CONTRACT);
  const personalFinanceData: any = queryClient.getQueryData(QueryKeys.GET_PERSONAL_FINANCE);
  const licenseInformation: any = queryClient.getQueryData(QueryKeys.GET_LICENSE_INFO);
  const employmentInfo: any = queryClient.getQueryData(QueryKeys.GET_CUSTOMER_EMPLOYMENT_DETAILS);
  const LenderConfigurations: any = queryClient.getQueryData(QueryKeys.GET_DEFAULT_LENDER);
  const {mutate: convertQuotationRequest} = useConvertQuotationToApplication()
  const {mutate: dealerFee} = useGetDealerFeebyState(appState?.slug)
  const {mutate: saveOrderRequest, isLoading: saveRequestLodaing} = useSaveOrderRequest()
  const {dispatch: pageDispatch} = useJourneyLayoutContext();
  const {state: pageState} = useJourneyLayoutContext();
  const [snackbarState, snackbardispatch] = useReducer(
      SnackbarUtility.snackbarReducer,
      SnackbarUtility.initialState
  );
  const {mutate: updateOrderRequest} = useUpdateOrderRequest()
  const PLACEHOLDERS = appState.language.placeholders;
  const {tenant} = useParams()
  const user_profile = JSON.parse(localStorage.getItem("user_profile"))
  const dealerData = JSON.parse(localStorage.getItem('dealer_information'))?.dealer
  const {mutate: createTags} = useTags();
  const {state: {order}, dispatch: orderDispatch} = useOrderContext()
  const {mutate: updateLead} = useUpdateLead(appState?.slug);
  const {navigateNext} = useWorkflowContext()
  const states: IStates[] = queryClient.getQueryData(
      QueryKeys.GET_ALL_STATES
  );
  useEffect(() => {
    const combinedData = {
      ...licenseInformation,
      ...employmentInfo,
      ...contactData,
    };
    if (customerData) {
      const lead = mapPayload(+vehicle?.dealer_id, tenant, customerData?.reference_id, 'customer_details', combinedData)
      updateLead(lead);
    }
  }, [customerData, contactData]);

  useEffect(() => {
    const {order_fni, order_fees, dealer_fees, ...payload_order} = order;
    const orderFees = order_fees?.map((fee) => {
      if (fee.calculation_type === FEE_MONTHLY_PAYMENT_BASED && fee.is_active && fee.event === FeeTypes.EOT) {
        const dealer_fee = order?.dealer_fees?.find(dealerFee => dealerFee.id === (fee.order_id ? fee.dealer_fee_id : fee.id))
        const multipliedValue = (order?.estimated_monthly_payment + order?.monthly_sales_use_tax) * (fee.multiplication_factor ?? 1);
        fee.applied_price = dealer_fee?.default_amount;
        if (multipliedValue < dealer_fee?.default_amount) {
          fee.applied_price = parseFloat(multipliedValue.toFixed(2));
        }
      }
      return fee;
    });
    const payload = {
      ...payload_order,
      lender_id: order?.lender_id && order?.lender_id != 0 ? order?.lender_id : LenderConfigurations?.data?.id,
      down_payment: (order?.down_payment * 100) / order?.order_asset?.unit_price,
      allowed_usage: order?.annual_usage,
      running_id: localStorage.getItem('running_id'),
      order_options: order?.order_options?.length > 0 ? order?.order_options : null,
      order_fnI: order?.order_fnI?.length > 0 ? order?.order_fnI : null,
      insurance: order?.insurance ? order?.insurance : null,
      order_stage: 'Application',
      down_payment_value: order?.down_payment,
      order_fees: orderFees,
      customer_id: order?.customer_id ?? customerData?.customer_id,
      reference_id: order?.reference_id ?? customerData?.reference_id

    }
    if (!order?.identifier) {

      snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.AUTO_ORDER_SAVING_IN_PROGRESS));
      saveOrderRequest([payload], {
        onSuccess(response) {
          orderDispatch(updateOrder(response[0]));
          const stateCode = states?.find((x) => x?.id === dealerData?.dealer_address?.state_id)?.code;
          dealerFee({stateCode, dealerCode: dealerData?.dealer_code}, {
            onSuccess(data) {
              const activeFees = data?.filter(fee => fee.is_active);
              orderDispatch(updateDealerFee(activeFees));
            }
          });
          const quotationObject = response
              .filter(data => data?.finance_type === order?.finance_type)
              .map(data => {
                return {...data, base_rate: 0, margin: 0, applicable_rate: 0};
              });
          if (payload?.order_tradein) {
            resetFormState('TradIn')
          }
          snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_SAVE_SUCCESS));
          setTimeout(() => {
            snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR());
          }, 3000);
          navigateNext(Navigation.ORDER_PAGE, {
            'vin': order?.order_asset?.vin,
            'orderId': response[0].reference_number
          });
        },
        onError() {
          snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_SAVE_FAILED));
          setTimeout(() => {
            snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR());
          }, 3000);
        }
      });
    } else if (order?.order_stage === Stage.QUOTATION && order?.identifier) {
      updateOrderRequest(payload, {
        onSuccess(response) {
          convertQuotationRequest({
            ...response,
            changeVehicleStatus: false,
          });
        }
      });
    }
    const page_event: Event = {
      session: user_session,
      slug: tenant,
      event_name: Tags.Schedule_END,
      user_id: user_profile?.user_id,
      page_type: PageType.ScheduleAppointments
    }
    createTags(page_event)
    const event: Event = {
      session: user_session,
      slug: tenant,
      event_name: Tags.DISPLAY_FINANCING,
      dealer_id: +vehicle?.dealer_id,
      vehicle_make: vehicle?.make,
      vehicle_model: vehicle?.model,
      user_id: user_profile?.user_id,
      order_id: order?.identifier,
      vin: order?.vin,
      page_type: PageType.Financing
    }
    createTags(event)
  }, [])


  useEffect(() => {
    if (pageState.finance.personal_details.success == false) {
      pageDispatch({
        type: pagesActionType.UPDATE_PERSONAL_DETAILS,
        payload: {
          error: true,
        },
      });
    }
    if (pageState.finance.residence_details.success == false) {
      pageDispatch({
        type: pagesActionType.UPDATE_RESIDENCE_DETAILS,
        payload: {
          error: true,
        },
      });
    }
    if (pageState.finance.license_details.success == false) {
      pageDispatch({
        type: pagesActionType.UPDATE_LICENSE_DETAILS,
        payload: {
          error: true,
        },
      });
    }
    if (pageState.finance.employment_details.success == false) {
      pageDispatch({
        type: pagesActionType.UPDATE_EMPLOYMENT_DETAILS,
        payload: {
          error: true,
        },
      });
    }
    if (pageState.finance.personal_finance_details.success == false) {
      pageDispatch({
        type: pagesActionType.UPDATE_PERSONAL_FINANCE_DETAILS,
        payload: {
          error: true,
        },
      });
    }
  }, [pageState?.finance]);


  const {contactDetailForm, watchContactFields} = useContactDetailsForm(contactData, formStates);
    const [isGaraging, setIsGaraging] = useState(false)
    const [isBilling, setIsBilling] = useState(false)
    const [previous,setPrevious] = useState(false)

  const { residentForm, watchResidenceFields } = useResidenceDetailsForm
  (
      contactData,  customerData?.reference_id ||
      null, PLACEHOLDERS, formStates, isGaraging, isBilling, previous
  );
  const { licenceForm, watchLicenseFields } = useLicenseDetailsForm(licenseInformation, formStates);
  const { employmentForm, watchEmploymentFields } = useEmploymentDetailsForm(employmentInfo,PLACEHOLDERS, formStates);
  const { personalFinanceFrom, watchPersonalFinanceFields } = usePersonalFinanceDetailsForm(personalFinanceData, formStates);

  const contactDetailsProps =  {
        customerReferenceId: customerData?.reference_id,
        contactData,
        setTab: setTabIndex,
        contactDetailForm,
        watchContactFields
    }

  const residenceDetailsProps = {
    customerReferenceId: customerData?.reference_id,
    contactData,
    setTab: setTabIndex,
    residentForm,
    watchResidenceFields,
    isGaraging,
    setIsGaraging,
    isBilling,
    setIsBilling,
    previous,
    setPrevious
  };

  const licenseDetailsProps = {
    setTab: setTabIndex,
    licenseData: licenseInformation,
    licenceForm,
    watchLicenseFields,
  };
  //
  const employmentDetailsProps = {
    employInfoData: employmentInfo,
    customerReferenceId: customerData?.reference_id,
    setTab: setTabIndex,
    employmentForm,
    watchEmploymentFields,
  };
  //
  const personalFinanceDetailsProps = {
    customerReferenceId: customerData?.reference_id,
    personalFinanceData,
    personalFinanceFrom,
    watchPersonalFinanceFields,
  };


  return (
    <PageWrap theme={theme} className="page-wrap">
      <PageHeader
        marginBottom={3}
        title={PLACEHOLDERS.FINANCING_TITLE}
        subTitle={PLACEHOLDERS.FINANCING_SUB_TITLE}
      />
      <Tab hasBorder autoWidth>
        <ScrollableTabs
          scrollButtons={"auto"}
          theme={theme}
          onChange={(e: any, value: FinanceTab) => {
            setTabIndex(value);
          }}
          items={[
            {
              content: (<ContactDetailWrapper {...contactDetailsProps} />),
              title: (
                <>
                  <Box className="title-with-icon" theme={theme}>
                    {PLACEHOLDERS.FINANCING_PERSONAL_DETAILS}
                  </Box>
                  {pageState?.finance?.personal_details.success ? (
                    ''
                  ) : (
                    <Box theme={theme} className="icon icon-error"></Box>
                  )}
                </>
              ),
            },
            {
              content: (<ResidenceDetailsWrapper {...residenceDetailsProps}/>),
              title: (
                <>
                  {pageState?.finance?.residence_details.success === true ? (
                    <>
                      <Box className="title-with-icon" theme={theme}>
                        {PLACEHOLDERS.FINANCING_TITLE_RESIDENCE_DETAILS}
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box className="title-with-icon" theme={theme}>
                        {PLACEHOLDERS.FINANCING_TITLE_RESIDENCE_DETAILS}
                      </Box>
                      <Icon className="icon icon-error" name="InfoErrorIcon" />
                      <Box theme={theme} className="line" />
                    </>
                  )}
                </>
              ),
            },
            {
              content: (<LicenceDetailsWrapper {...licenseDetailsProps}/>),
              title: (
                <>
                  {pageState?.finance?.license_details.success === true ? (
                    <>
                      <Box className="title-with-icon" theme={theme}>
                        {PLACEHOLDERS.FINANCING_TITLE_LICENSE_DETAILS}
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box className="title-with-icon" theme={theme}>
                        {PLACEHOLDERS.FINANCING_TITLE_LICENSE_DETAILS}
                      </Box>
                      <Icon className="icon icon-error" name="InfoErrorIcon" />
                      <Box theme={theme} className="line" />
                    </>
                  )}
                </>
              ),
            },
            {
              content: (<EmploymentDetailsWrapper {...employmentDetailsProps}/>),
              title: (
                <>
                  {pageState?.finance?.employment_details.success === true ? (
                    <>
                      <Box className="title-with-icon" theme={theme}>
                        {PLACEHOLDERS.FINANCING_TITLE_EMPLOYMENT_DETAILS}
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box className="title-with-icon" theme={theme}>
                        {PLACEHOLDERS.FINANCING_TITLE_EMPLOYMENT_DETAILS}
                      </Box>
                      <Icon className="icon icon-error" name="InfoErrorIcon" />
                      <Box theme={theme} className="line" />
                    </>
                  )}
                </>
              ),
            },
            {
              content: (<PersonalFinanceDetailsWrapper {...personalFinanceDetailsProps}/>),
              title: (
                <>
                  {pageState?.finance?.personal_finance_details.success ===
                    true ? (
                    <>
                      <Box className="title-with-icon" theme={theme}>
                        {PLACEHOLDERS.FINANCING_TITLE_PERSONAL_FINANCE_DETAILS}
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box className="title-with-icon" theme={theme}>
                        {PLACEHOLDERS.FINANCING_TITLE_PERSONAL_FINANCE_DETAILS}
                      </Box>
                      <Icon className="icon icon-error" name="InfoErrorIcon" />
                      <Box theme={theme} className="line" />
                    </>
                  )}
                </>
              ),
            },
          ]}
          defaultTabIndex={0}
          tabValue={tabIndex}
        />
      </Tab>
      <Snackbar
        theme={theme}
        message={<Box theme={theme} display={'flex'}>
        <Box theme={theme} width="30px">
          <CircleLoader theme={theme} size='xs' />
        </Box> {snackbarState.message}
      </Box> }
        open={snackbarState.open}
        onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      />
    </PageWrap>

  );
};
