import { styled } from "@mui/material";
import { Box, IBoxProps } from "@ntpkunity/controls"


export const AssetDetailCarouselWrap = styled(
    Box,
    {}
  )<Partial<IBoxProps>>(({ theme }) => ({
    "&.asset-detail-carousel-wrap": {
        display: 'flex',
        alignItems: 'center',
        ".react-multi-carousel-list": {
          width: '100%',
          padding: 0,
          marginLeft: 8,
          ".react-multi-carousel-track": {
            gap: 8
          }
        },
        ".carousel-button-group": {
          display: 'none'
        }
    },
  }));