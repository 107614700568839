import React, { FC, useState, useEffect } from "react";
import { useTheme, Link, TablePagination } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CreditScoreRow from "./range-row";
import { Box, Button, Icon, Input, DataTable } from "@ntpkunity/controls";
import { PaginationWrap } from "@sharedComponents";
import { UseQuery_getAllDealerMarkups_filter } from "services/financial-insurance.service";
import { ActionOptions } from "Enums";
import ChevronDown from "../../../shared/assets/images/chevron-down";
import { UseQuery_getAllCreditRatings, UseQuery_getAllLenders } from "services";

const MarkupTable: FC<{
  dealer_code?: any;
  onEdit: (data) => unknown;
  onDelete: (data) => unknown;
}> = ({ dealer_code, onEdit, onDelete }) => {
  const theme = useTheme();
  const [creditRange, setCreditRange] = useState("");
  const [lendernameFilter, setLenderNameFilter] = useState("");
  const [markupFilter, setMarkUpFilter] = useState("");
  const [bundleStatusFilter, setBundleStatusFilter] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `dealer_code=${dealer_code}&page_number=${pageNumber}&page_size=${pageSize}`
  );

  UseQuery_getAllCreditRatings();
  UseQuery_getAllLenders();
  const { data } = UseQuery_getAllDealerMarkups_filter(columnFilters);
  const dealerMarkupsFilter = data;

  useEffect(() => {
    if (dealer_code) {
      setColumnFilters(
        `dealer_code=${dealer_code}&page_number=${pageNumber}&page_size=${pageSize}`
      );
    }
  }, [dealer_code]);

  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `dealer_code=${dealer_code}&page_number=${pgNo}&page_size=${pgSize}`;
    if (creditRange.trimStart() != null && creditRange.trimStart() != "") {
      query_string = query_string.concat(`&credit_score=${creditRange}`);
    }
    if (
      lendernameFilter.trimStart() != null &&
      lendernameFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&lender_name=${lendernameFilter}`);
    }
    if (markupFilter.trimStart() != null && markupFilter.trimStart() != "") {
      query_string = query_string.concat(`&markup=${markupFilter}`);
    }
    if (
      bundleStatusFilter.trimStart() != null &&
      bundleStatusFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&is_active=${bundleStatusFilter}`);
    }
    return query_string;
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const handleSelection = (_event: any, key: any, value: any) => {
    switch (key) {
      case ActionOptions.EDIT:
        onEdit(value);
        return;
      case ActionOptions.DELETE:
        onDelete(value);
        return;
      default:
        return;
    }
  };

  return (
    <>
      <DataTable
        theme={theme}
        variant="basic"
        sx={{ mb: 3 }}
        theadChildren={
          <>
            <TableRow>
              <TableCell>Credit Score Range</TableCell>
              <TableCell>Lender Name</TableCell>
              <TableCell>Markup %</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="number"
                    onChange={(value) => {
                      setCreditRange(value);
                    }}
                    value={creditRange?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"bundleSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="bundleFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setLenderNameFilter(value);
                    }}
                    value={lendernameFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"bundleSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="bundleFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="number"
                    onChange={(value) => {
                      setMarkUpFilter(value);
                    }}
                    value={markupFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"bundleMarkUpSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="bundleMarkupFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setBundleStatusFilter(value);
                    }}
                    value={bundleStatusFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"taxableSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="bundleStatusFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {dealerMarkupsFilter &&
              dealerMarkupsFilter?.results?.map((bundleRow, index) => {
                return (
                  <React.Fragment key={bundleRow.id + "parent"}>
                    <CreditScoreRow
                      bundleData={bundleRow}
                      dealer_code={dealer_code}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      handleSelection={handleSelection}
                    />
                  </React.Fragment>
                );
              })}
          </>
        }
      />
      <PaginationWrap>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={dealerMarkupsFilter ? dealerMarkupsFilter?.total_results : -1}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
            MenuProps: { disablePortal: true },
          }}
        />
      </PaginationWrap>
    </>
  );
};
export default MarkupTable;
