import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ActionItemsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.action-items-wrap': {
    '.actions-list': {
      backgroundColor: '#f2f2f2',
      padding: '12px 16px',
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      borderRadius: 8,
      marginBottom: 8,
      ':last-child': {
        marginBottom: 0
      },
      '.action-name': {
        color: theme.palette.grey[900],
        flex: 1
      },
      '.action-number': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: 24,
        maxHeight: 20,
        minWidth: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4px 6px'
      }
    }
  }
}))
