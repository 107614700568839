import React from "react";
import { useTheme } from "@mui/material";
import clsx from "clsx";
import { StackProps } from "@components/stack/stack.props";
import { StackWrap } from "@components/stack/stack.style";
import { Box, Typography } from "@ntpkunity/controls";

export const Stack = ({
  className,
  paddingMd = 4,
  paddingXs = 3,
  marginTopXs,
  marginTopMd,
  bgLight,
  bgDark,
  hasShadow,
  children,
  dFlex,
  dNone,
  justifyBetween,
  title,
  subTitle,
  actionArea,
  fullWidth,
  onClick,
}: StackProps) => {
  const theme = useTheme();
  return (
    <StackWrap
      className={clsx(className, {
        "stack-wrap": true,
        "bg-light": bgLight,
        "bg-dark": bgDark,
        "has-shadow": hasShadow,
        "d-flex": dFlex,
        "d-none": dNone,
        "justify-content-between": justifyBetween,
        "w-100": fullWidth
      })}
      theme={theme}
      p={{ xs: paddingXs, md: paddingMd }}
      mt={{ xs: marginTopXs, md: marginTopMd }}
      onClick={onClick}
    >
      {title && (
        <Box theme={theme} className="stack-header">
          <Box theme={theme} className="title">
            <Typography theme={theme} component={"h4"} variant="h4">
              {title}
            </Typography>
            {subTitle &&
              <Typography theme={theme} component={"span"} variant="subtitle1">
                {subTitle}
              </Typography>
            }
          </Box>
          {actionArea && (
            <Box theme={theme} className="action-area">
              {actionArea}
            </Box>
          )}
        </Box>
      )}
      {children}
    </StackWrap>
  );
};
