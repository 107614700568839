import { FC, useState } from 'react'
import { useTheme } from '@mui/material'
import { Box, Grid, Button } from '@ntpkunity/controls'
import PaymentDetails from './payment-details'
import CalculationGrid from './Calculation/grid-view'
// import CalculationSingle from './Calculation/single-view'
// import CalculationList from './Calculation/list-view'
import { BtnTabs } from '@components'

const CalculationSection: FC = () => {
  const theme = useTheme()
  const [currentState, setCurrentState] = useState('finance')
  return (
    <>
      {/* <ActionHeader/> */}
      <Box theme={theme} className="fl-header-wrap">
        <Grid theme={theme} container columnSpacing={1}>
          <Grid theme={theme} item xs={6} pt={0.5}>
            <BtnTabs theme={theme} className="btn-tabs">
              <Button
                theme={theme}
                type="button"
                text="Finance"
                defaultBtn
                className={currentState === 'finance' ? 'selected' : ''}
                onClick={() => {
                  setCurrentState('finance')
                }}
              />
              <Button
                theme={theme}
                type="button"
                text="Lease"
                defaultBtn
                onClick={() => {
                  setCurrentState('lease')
                }}
                className={currentState === 'lease' ? 'selected' : ''}
              />
            </BtnTabs>
          </Grid>
          {/* <Grid theme={theme} item xl={3} lg={3.5} md={4} sm={4} xs={4} pt={0.5}>
            <Box theme={theme} display={'flex'} justifyContent={'end'}>
              <BtnTabs theme={theme} className="btn-tabs rounded">
                <Button
                  theme={theme}
                  type="button"
                  className="selected"
                  iconText={<Icon name="GridView" />}
                  defaultBtn
                />
                <Button
                  theme={theme}
                  type="button"
                  iconText={<Icon name="ListView" />}
                  defaultBtn
                />
              </BtnTabs>
            </Box>
          </Grid> */}
          <Grid theme={theme} item xs={6}>
            <Box theme={theme} className="fl-action-btn">
              <Button
                theme={theme}
                secondary
                size="small"
                text="Send to Customer"
                onClick={() => {}}
              />
              <Button theme={theme} secondary size="small" text="Save" onClick={() => {}} />
              <Button theme={theme} primary size="small" text="Continue Order" onClick={() => {}} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid theme={theme} container columnSpacing={2} rowSpacing={2}>
        <Grid theme={theme} item xl={6} lg={7} md={12} sm={12} xs={12}>
          <CalculationGrid type={currentState} />
          {/* <CalculationSingle type={currentState} />
          <CalculationList type={currentState} /> */}
        </Grid>
        <Grid theme={theme} item xl={6} lg={5} md={12} sm={12} xs={12}>
          <PaymentDetails type={currentState} />
        </Grid>
      </Grid>
    </>
  )
}

export default CalculationSection
