import { useTheme } from "@mui/material";
import { Accordion, Box, Button, Dialog, DuoTab } from "@ntpkunity/controls";
import { ApplicantDetailDialogWrap } from "./applicant-detail-dialog.style";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { ApplicantDetailsList } from "../applicant-detail-list/applicant-detail-list.component";
import { CoApplicantList } from "../co-applicant-list/co-applicant-list.component"
import { Popup } from "@src/components/Dialog/dialog.component";

export const ApplicantDetailDialog = ({ open, closeDialog }) => {
  const theme = useTheme();

  return (
    <>
      <Popup>
        <Dialog
          theme={theme}
          size={"md"}
          onCloseDialog={closeDialog}
          open={open}
          title={"Applicant Details"}
          disablePortal
          noFooter
        >
          <ApplicantDetailDialogWrap
            theme={theme}
            className="applicant-detail-dialog-wrap collapse-wrap"
          >
            <DuoTab
              theme={theme}
              varient="underline"
              defaultTabIndex={0}
              items={[
                {
                  content: <><ApplicantDetailsList /></>,
                  title: 'Primary Applicant',
                },
                {
                  content: <><CoApplicantList /></>,
                  title: 'Co-Applicant',
                },
              ]}
            />
            {/* <Box theme={theme} className="accordion-box bg-light">
              <Accordion
                theme={theme}
                items={[
                  {
                    content: (
                      <>
                        <ApplicantDetailsList />
                      </>
                    ),
                    title: "Primary Applicant",
                    key: "primary-applicant",
                    isExpanded: true,
                    id: "primary-applicant",
                    ariaControls: "primary-applicant",
                  },
                  {
                    content: (
                      <>
                        <ApplicantDetailsList />
                      </>
                    ),
                    title: "Co- Applicant",
                    key: "co-applicant",
                    isExpanded: false,
                    id: "co-applicant",
                    ariaControls: "co-applicant",
                  },
                ]}
                shouldCollapse
              />
            </Box> */}
          </ApplicantDetailDialogWrap>
        </Dialog>
      </Popup>
    </>
  );
};
