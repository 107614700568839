import { useTheme } from '@mui/material'
import { DuoTab } from '@ntpkunity/controls'
import { BusinessKpiAnalytics } from '../business-kpi-analytics'
import { VdpAnalytics } from '../vdp-analytics'

export const AnalyticsTabs = () => {
  const theme = useTheme()
  return (
    <DuoTab
      theme={theme}
      varient="underline"
      items={[
        {
          title: 'Business KPI Dashboard',
          content: <BusinessKpiAnalytics />
        },
        {
          title: 'VDP Dashboard',
          content: <VdpAnalytics />
        }
      ]}
      defaultTabIndex={0}
    />
  )
}
