import { ReactNode } from "react";
import { CheckoutHeader, Menu, CheckoutFooter } from "@src/layouts";

import "./base-layout.scss";

export const BaseLayout = ({
  children,
  hasMenu = true,
  hasFooter = true,
}: {
  children?: ReactNode;
  hasMenu?: boolean;
  hasFooter?: boolean;
}) => {
  return (
    <div className="base-layout-container">
      <CheckoutHeader />
      {hasMenu && (
        <div className="base-layout--menu-container">
          <Menu />
        </div>
      )}
      <div className="base-layout--main-content-area">{children}</div>
      {hasFooter && <CheckoutFooter />}
    </div>
  );
};
