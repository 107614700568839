import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ValueBoxWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.value-box-wrap': {
    backgroundColor: '#F2F2F2',
    padding: '16px 24px',
    borderRadius: theme.shape.borderRadius,
    '&.no-bg': {
      backgroundColor: 'transparent',
      padding: 0,
      borderRadius: 0
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-success': {
      color: theme.palette.success.main
    },
    '.text-danger': {
      color: theme.palette.error.main
    },
    '.fw-medium': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '.fw-sbold': {
      fontWeight: theme.customVariables.fontWeightSemiBold
    },
    '.icon': {
      width: 24,
      height: 24
    }
  }
}))
