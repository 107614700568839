import { useMediaQuery, useTheme } from "@mui/material";
import { Box, Grid, Button, Icon } from "@ntpkunity/controls";
import { ApplicantDetailsStack } from "../applicant-details/applicant-detail-stack/applicant-detail-stack.component";
import { DashboardStackWrap } from "./dashboard-stack.style";
import { AssetDetailsStack } from "../asset-detail/asset-detail-stack/asset-detail-stack.component";
import { RiskAssessmentStack } from "../risk-assessment-metrices/risk-assessment-stack/risk-assessment-stack.component"
import { FinanceStructure } from "@src/components/dashboard/finance-structure/finance-structure/finance-structure.component"
import { BusinessRules } from "@src/components/dashboard/business-rules-stack/business-rules/business-rules.component"
import { ThirdPartyResponse } from "../third-party-response-stack/third-party-response/third-party-response.component"
import { StickyNotesList } from "../sticky-notes-stack/sticky-notes-list/sticky-notes-list.component"
import { ActivitiesAndNotesSlidePopup } from "@src/components/dashboard/activities-and-notes-slide/activities-and-notes-slide-popup/activities-and-notes-slide-popup.component"
import { AddStickyNote } from "../sticky-notes-stack/add-sticky-note/add-sticky-note.component" 
import { AiAssistant } from "../ai-assistant-stack/ai-assistant/ai-assistant.component" 
import { Documents } from "../documents/document-stack/document-stack.component"

export const DashboardStacks = () => {
  const theme = useTheme();

  const isGridChange = useMediaQuery(theme.breakpoints.down("xl"));

  return (
    <>
      <DashboardStackWrap theme={theme} className="dashboard-stack-wrap">
        <Grid
          theme={theme}
          container
          item
          spacing={2}
        >
          <Grid item xs={isGridChange ? 10.3 : 10.4} theme={theme}>
            <Grid
              theme={theme}
              container
              item
              spacing={2}
            >
              <Grid theme={theme} item xs={6}>
                <Box theme={theme} className="scrollable-container" mt={2}>
                  <Grid
                    theme={theme}
                    container
                    item
                    spacing={2}
                  >
                    <Grid theme={theme} item xs={6}>
                      <ApplicantDetailsStack />
                      <Box theme={theme} mt={2}>
                        <AssetDetailsStack />
                      </Box>
                      <Box theme={theme} mt={2}>
                        <ThirdPartyResponse />
                      </Box>
                      <Box theme={theme} mt={2}>
                        <BusinessRules />
                      </Box>
                    </Grid>
                    <Grid theme={theme} item xs={6}>
                      <RiskAssessmentStack />
                      <Box theme={theme} mt={2}>
                        <Documents />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Grid
                    theme={theme}
                    container
                    item
                    spacing={2}
                  >
                  <Grid theme={theme} item xs={6}>
                      <Box theme={theme} mt={2}>
                        <FinanceStructure />
                      </Box>
                    </Grid>
                    <Grid theme={theme} item xs={6}>
                      <Box theme={theme} className="scrollable-container" mt={2}>
                        <AiAssistant />
                      </Box>
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={isGridChange ? 1.7 : 1.6} theme={theme}>
            <Box theme={theme} className="scrollable-container" mt={2}>
              <StickyNotesList />
              <Box theme={theme} mt={1}>
                <AddStickyNote />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DashboardStackWrap>
      <ActivitiesAndNotesSlidePopup />
    </>
  );
};
