import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const BusinessRulesAlertsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.business-rules-alerts-wrap": {
    display: 'flex',
    flexDirection: 'column',
    gap: 8
  },
}));
