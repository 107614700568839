import { Input as AntInput } from "antd";
import clsx from "clsx";
import "../../../styles/components/_input.scss";
import { InputProps } from "./input.props";

export const Input = ({
  className,
  id,
  label,
  name,
  placeholder,
  prefix,
  suffix,
  helpText,
  type,
  value,
  size,
  onChange,
  readOnly,
  disabled,
  autoComplete,
  showInfoIcon,
  autoSize,
  showCount,
  maxLength,
  error,
  onDark,
  ...rest
}: InputProps) => {
  const isTextArea = type === "textarea";
  const currentCount = value ? String(value).length : 0;

  return (
    <div
      className={clsx(className, {
        "input-wrapper": true,
        "input--input-disabled": disabled === true,
        "input--input-on-dark": onDark === true,
      })}
    >
      {label && (
        <label
          className={clsx({
            "input-label text--label text-secondary": true,
          })}
        >
          <span>{label}</span>
          {showInfoIcon && (
            <div
              className="input-label-icon"
              role="img"
              aria-label="Icon for information"
              data-icon="information"
            />
          )}
        </label>
      )}
      {isTextArea ? (
        <AntInput.TextArea
          className={clsx(className, {
            "input--input-small": size === "small",
            "input--input-error": error,
          })}
          name={name}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          disabled={disabled}
          autoSize={autoSize}
          maxLength={maxLength}
        />
      ) : (
        <AntInput
          className={clsx(className, {
            "input--input-small": size === "small",
            "input--input-error": error,
          })}
          name={name}
          id={id}
          placeholder={placeholder}
          value={value}
          type={type}
          suffix={suffix}
          prefix={prefix}
          onChange={onChange}
          readOnly={readOnly}
          disabled={disabled}
          autoComplete={autoComplete}
          maxLength={maxLength}
          {...rest}
        />
      )}
      <div className="input--input-helperText">
        {helpText && !error && (
          <label className="help-text text-secondary text--disclaimer">
            {helpText}
          </label>
        )}
        {error && error.message && (
          <label className="error-text text-error text--disclaimer">
            {error.message}
          </label>
        )}
        {showCount && maxLength && (
          <label className="help-text text-secondary text--disclaimer">
            {currentCount}/{maxLength}
          </label>
        )}
      </div>
    </div>
  );
};
