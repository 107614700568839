import { useTheme } from '@mui/material'
import { SchedulerWrap } from './scheduler.style'
import { Box, Typography } from '@ntpkunity/controls'
import { Link } from 'react-router-dom'
import { APP_ROUTES } from '@helpers/links'

export const Scheduler = () => {
  const theme = useTheme()

  return (
    <SchedulerWrap theme={theme} className="scheduler-wrap section-h">
      <Box theme={theme} className="scheduler-box">
        <Box theme={theme} className="upper-section">
          <Box theme={theme} className="section-header">
            <img src={require('../../../public/assets/images/Scheduler.svg')} alt="Calendar" />
          </Box>
          <Box theme={theme} className="section-body">
            <Box theme={theme} className="label-price">
              <Typography theme={theme} variant="body1" component="p" className="fw-700">
                Monday, 10 February, 2025
              </Typography>
              <Typography theme={theme} variant="body2" component="p">
                You have 4 appointments scheduled for the day
              </Typography>
            </Box>
            <Box theme={theme} className="date-dots">
              <span className="dot filled"></span>
              <span className="dot filled"></span>
              <span className="dot filled"></span>
              <span className="dot filled"></span>
              <span className="dot filled"></span>
              <span className="dot filled"></span>
              <span className="dot filled"></span>
              <span className="dot filled"></span>
              <span className="dot filled"></span>
              <span className="dot filled"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </Box>
            <Link to={APP_ROUTES.CALENDAR} className="all-appointments">
              <Typography theme={theme} variant="caption" component="p">
                View All Appointments
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </SchedulerWrap>
  )
}
