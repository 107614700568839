import React, { FC } from 'react'
import { useTheme } from '@mui/material'
import {
  Box,
  Button,
  Dialog,
  FileDragDrop,
  FilePreview,
  Grid,
  Icon,
  Input,
  Menu,
  Radio,
  Select,
  Typography
} from '@ntpkunity/controls'
import { BtnAddWrap } from '@components'
import vcImage from '../../../public/assets/images/vc-img.png'

export const AddTradeIn: FC = () => {
  const theme = useTheme()

  const [openDialog, setOpenDialog] = React.useState(false)
  const handleOpen = () => {
    setOpenDialog(true)
  }
  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <>
      <BtnAddWrap theme={theme} className="btn-add-wrap">
        <Button
          theme={theme}
          fullWidth
          startIcon={<Icon name="AddCircleIcon" />}
          text="Add Trade-In"
          size="large"
          onClick={handleOpen}
        />
      </BtnAddWrap>
      <Box theme={theme} className="vl-item">
        <Box theme={theme} className="vl-details">
          <Typography
            theme={theme}
            variant="body2"
            component="p"
            className="text-primary text-overflow vl-main-title"
            children={<b>Trade-In</b>}
          />
          <Typography
            theme={theme}
            variant="body2"
            component="p"
            className="text-overflow"
            children={<b>2023 BMW 4 Series M440i Gran Coupe</b>}
          />
          <Typography
            theme={theme}
            variant="caption"
            component="p"
            className=" text-overflow vl-title-ul"
            children={
              <>
                Trade Value: <b>$20,000</b>, Payoff: <b>$0,000</b>, Miles: <b>20,000</b>
              </>
            }
          />
        </Box>
        <Box theme={theme} className="vl-img-wrap">
          <img src={vcImage} alt="Car" />
        </Box>
        <Menu
          theme={theme}
          disablePortal
          options={[
            {
              optionText: (
                <>
                  <Icon className="menu-icon" name="EditIcon" /> Edit
                </>
              ),
              optionValue: 'edit'
            },
            {
              optionText: (
                <>
                  <Icon className="menu-icon" name="DeleteIcon" /> Delete
                </>
              ),
              optionValue: 'delete'
            }
          ]}
          render={(cb) => (
            <Button
              theme={theme}
              defaultBtn
              className="vl-menu-btn"
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            />
          )}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
      </Box>
      <Dialog
        variant={undefined}
        disablePortal
        size="lg"
        title="Add Trade-In"
        open={openDialog}
        onCloseDialog={handleClose}
        theme={theme}
        children={
          <>
            <Grid theme={theme} container rowSpacing={3} columnSpacing={3}>
              <Grid theme={theme} item xs={12} sm={6}>
                <Input
                  theme={theme}
                  fullWidth
                  label="Year"
                  placeholder=" Add here.."
                  type="text"
                  min={0}
                  max={9999}
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Input
                  theme={theme}
                  fullWidth
                  label="Make"
                  placeholder="Add here . . "
                  type="text"
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Input
                  label="Model"
                  theme={theme}
                  fullWidth
                  placeholder={' Add here..'}
                  type="text"
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Input
                  label="Trim (Optional)"
                  theme={theme}
                  fullWidth
                  placeholder={' Add here..'}
                  type="text"
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Input
                  label="Current Mileage (Odometer)"
                  theme={theme}
                  fullWidth
                  placeholder={' Add here..'}
                  type="decimal"
                  masking={true}
                  maskNumeric={true}
                  maskDecimalScale={0}
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Input
                  label="VIN (Optional)"
                  theme={theme}
                  fullWidth
                  placeholder={' Add here..'}
                  type="text"
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Select
                  label="Condition"
                  theme={theme}
                  items={[
                    { text: 'Fair', value: 'Fair' },
                    { text: 'Good', value: 'Good' },
                    { text: 'Very Good', value: 'Very Good' },
                    { text: 'Excellent', value: 'Excellent' }
                  ]}
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Input
                  label="Trade-In Amount"
                  theme={theme}
                  fullWidth
                  startAdornment="$"
                  min={0}
                  placeholder={' Add here..'}
                  type="decimal"
                  masking={true}
                  maskNumeric={true}
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Typography variant="subtitle1" component={'div'} theme={theme} sx={{ mb: 1 }}>
                  Is asset titled in customer's name?
                </Typography>
                <Radio
                  options={[
                    {
                      label: 'Yes',
                      value: true
                    },
                    {
                      label: 'No',
                      value: false
                    }
                  ]}
                  row
                  theme={theme}
                  fullWidth
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Typography variant="subtitle1" component={'div'} theme={theme} sx={{ mb: 1 }}>
                  Does the customer have lease/loan balance?
                </Typography>

                <Radio
                  options={[
                    {
                      label: 'Yes',
                      value: true
                    },
                    {
                      label: 'No',
                      value: false
                    }
                  ]}
                  row
                  theme={theme}
                  fullWidth
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Input
                  label="Contract No. / Credit Reference"
                  theme={theme}
                  fullWidth
                  placeholder={' Add here..'}
                  type="text"
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Input
                  label="Lender Name"
                  theme={theme}
                  fullWidth
                  placeholder={' Add here..'}
                  type="text"
                />
              </Grid>

              <Grid theme={theme} item xs={12} sm={6}>
                <Input
                  label="Lender Physical Address (Optional)"
                  theme={theme}
                  fullWidth
                  placeholder={' Add here..'}
                  type="text"
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Input
                  theme={theme}
                  fullWidth
                  label="Lender Phone Number (Optional)"
                  type="text"
                  placeholder="000-000-0000"
                  format="###-###-####"
                  masking={true}
                  maskNumeric={true}
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Select
                  label="Payment Type"
                  theme={theme}
                  items={[
                    { text: 'Lease', value: 'Lease' },
                    { text: 'Finance', value: 'Finance' }
                  ]}
                  fullWidth
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Input
                  label="Payoff Amount (Within 10 days)"
                  theme={theme}
                  fullWidth
                  startAdornment="$"
                  min={0}
                  placeholder={' Add here..'}
                  type="decimal"
                  masking={true}
                  maskNumeric={true}
                />
              </Grid>
              <Grid theme={theme} item xs={12}>
                <FileDragDrop
                  theme={theme}
                  width="300"
                  height="200px"
                  backgroundColor="white"
                  allowMultiple={true}
                  hoverLabel={
                    <>
                      Drag and drop vehicle image, or <span className="text-primary">browse</span>
                    </>
                  }
                  onChange={function (): void {
                    throw new Error('Function not implemented.')
                  }}
                  onDrop={function (): void {
                    throw new Error('Function not implemented.')
                  }}
                />
                <FilePreview
                  theme={theme}
                  files={[]}
                  onRemoveFile={function (): void {
                    throw new Error('Function not implemented.')
                  }}
                />
              </Grid>
            </Grid>
          </>
        }
        customFooter={
          <>
            <Button theme={theme} primary text="Continue" />
          </>
        }
      />
    </>
  )
}
