import { Accordion } from "@src/components/atoms/accordion/accordion.component";

export const TotalPriceAccordionContent = () => {
  const customAddOnsAccordionItems = [
    {
      key: "1",
      label: "Incentives & Discount",
      extra: <span className="text--body-2">-$X,XXX</span>,
    },
    {
      key: "2",
      label: (
        <div className="accordion-title-with-icon">
          Trade-in Equity
          <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
        </div>
      ),
      extra: <span className="text--body-2">-$X,XXX</span>,
    },
    {
      key: "3",
      label: "Protection & Coverage",
      extra: <span className="text--body-2">$X,XXX</span>,
    },
    {
      key: "4",
      label: "Accessories",
      extra: <span className="text--body-2">$X,XXX</span>,
    },
  ];

  const taxFeesAccordionItems = [
    {
      key: "1",
      label: "Fees",
      extra: <span className="text--body-2">$000</span>,
    },
  ];

  return (
    <>
      <div className="pricing-details-accordion-content">
        <div className="pricing-details-accordion-content__box">
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Your Price
            </span>
            <span className="pricing-details__item__price text--body-2 fw-bold">
              $46,670
            </span>
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              MSRP
            </span>
            <span className="pricing-details__item__price text--body-2">
              $48,670
            </span>
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Dealer Discount
            </span>
            <span className="pricing-details__item__price text--body-2">
              -$2,000
            </span>
          </div>
        </div>
        <div className="pricing-details-accordion-content__box">
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Custom Add-Ons
            </span>
            <span className="pricing-details__item__price text--body-2 fw-bold">
              $0,000
            </span>
          </div>
          <Accordion
            items={customAddOnsAccordionItems}
            defaultActiveKey={0}
            ghost={false}
            size="small"
            fullWidth
          />
        </div>
        <div className="pricing-details-accordion-content__box">
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Taxes & fees
            </span>
            <span className="pricing-details__item__price text--body-2 fw-bold">
              $0,000
            </span>
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Sales Tax
              <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
            </span>
            <span className="pricing-details__item__price text--body-2">
              $4,200
            </span>
          </div>
          <div className="pricing-details__item pr-0">
            <Accordion
              items={taxFeesAccordionItems}
              defaultActiveKey={0}
              ghost={false}
              size="small"
              fullWidth
            />
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Acquisition Fee
            </span>
            <span className="pricing-details__item__price text--body-2">
              $900
            </span>
          </div>
        </div>
        <div className="pricing-details-accordion-content__box">
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Down Payment
            </span>
            <span className="pricing-details__item__price text--body-2 fw-bold">
              -$5,000
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
