import { useAppContext } from "@src/context/app-context";
import { useEffect } from "react";

import {
  loadTenantConfigurations,
  updateStates,
  updateCustomer,
  loadDefaultLenderConfigurations,
  updateLenders,
  updateDealerProfile,
  storeSetupData,
  setOrderInfo,
  updateLogo,
} from "@src/context/app-actions";
import { FinanceType } from "@src/constants";
import {
  collectionIntoSelectOptions,
  filterCustomerData,
} from "@src/helpers/appHelper";
import {
  useGetStates,
  useGetCompanyDomain,
  useGetDefaultLender,
  useGetByDealerId,
  useGetCustomerByEmail,
  useGetLenders,
  useGetCustomerFinancialProfile,
  useGetSetupData,
} from "@src/hooks";
import { useGetTheme } from "./dealer";

export const useFetchAndStore = () => {
  const {
    state: {
      user: { customer },
      configuration: { urlOrigin },
      tenant: { tenantId },
      order,
    },
    dispatch: appDispatch,
  } = useAppContext();
  const {
    finance_type,
    customer_info: { email },
    dealer_id,
  } = order;
  const { mutate: defaultLender } = useGetDefaultLender();
  const { mutate: companyData } = useGetCompanyDomain();
  const { mutate: lenderData } = useGetLenders();
  const { mutate: dealerProfile } = useGetByDealerId();
  const { mutate: getCustomerByEmail } = useGetCustomerByEmail();
  const { mutate: getCustomerFinancialProfile } =
    useGetCustomerFinancialProfile();
  const { mutate: getStates } = useGetStates();
  const { mutate: getSetupData } = useGetSetupData();
  const { mutate: getTheme } = useGetTheme();

  useEffect(() => {
    companyData(
      {
        origin_domain: urlOrigin,
      },
      {
        onSuccess: (data) => {
          appDispatch(loadTenantConfigurations(data));
          defaultLender(
            {
              company_id: data.id,
            },
            {
              onSuccess: (data) => {
                appDispatch(loadDefaultLenderConfigurations(data));
              },
            }
          );
        },
      }
    );
    getStates(
      {},
      {
        onSuccess: (data) => {
          appDispatch(
            updateStates(collectionIntoSelectOptions(data, "name", "name"))
          );
        },
        onError: (error) => {
          console.error("Error fetching customer:", error);
        },
      }
    );
  }, []);

  useEffect(() => {
    if (dealer_id && tenantId) {
      getSetupData(tenantId, {
        onSuccess: (setupData) => {
          appDispatch(
            setOrderInfo({
              ...order,
              credit_score: setupData?.["credit-ratings"]?.find(
                (item) => item.description === order?.credit_rating
              )?.score_from,
            })
          );

          appDispatch(storeSetupData(setupData));
        },
      });
      dealerProfile(
        {
          company_id: tenantId,
          dealerId: dealer_id,
        },
        {
          onSuccess: (dealerProfile) => {
            const { dealer_code, dealer_email, ...rest } = dealerProfile;
            getTheme(
              { email: dealer_email },
              {
                onSuccess: (response) => {
                  const { logo, ...rest } = response;
                  appDispatch(updateLogo(logo?.location));
                },
              }
            );
            appDispatch(updateDealerProfile(dealerProfile));
            if (finance_type == FinanceType.FINANCE) {
              lenderData(
                {
                  dealerCode: dealer_code,
                  finance_type: finance_type,
                },
                {
                  onSuccess: (response) => {
                    appDispatch(updateLenders(response));
                  },
                }
              );
            }
            getCustomerByEmail(
              {
                email: email,
              },
              {
                onSuccess: (customerInfo) => {
                  const filterData = filterCustomerData(customerInfo);
                  const customerData = { ...customer, ...filterData };
                  appDispatch(updateCustomer(customerData));
                  getCustomerFinancialProfile(
                    {
                      reference_id: customerInfo.reference_id,
                      dealer_code: dealer_code,
                    },
                    {
                      onSuccess: ({
                        bankruptcy,
                        monthly_payment,
                        residence,
                        reference_id,
                      }) => {
                        appDispatch(
                          updateCustomer({
                            ...customerData,
                            financial_profile: {
                              bankruptcy,
                              monthly_payment,
                              residence,
                              reference_id,
                            },
                          })
                        );
                      },
                      onError: (error) => {
                        console.error("Error fetching customer:", error);
                      },
                    }
                  );
                },
                onError: (error) => {
                  console.error("Error fetching customer:", error);
                },
              }
            );
          },
        }
      );
    }
  }, [dealer_id, tenantId]);
};
