import { ReactNode } from "react";
import "../../../styles/components/_radio.scss";
import clsx from "clsx";

export const Radio = ({
  id,
  name,
  defaultValue,
  htmlFor,
  label,
  checked,
  error,
  disabled,
  onChange,
  size,
  rest,
}: {
  id?: string;
  name?: string;
  defaultValue?: string;
  htmlFor?: string;
  label?: ReactNode | string;
  checked?: boolean;
  error?: boolean;
  disabled?: boolean;
  onChange?: (e: any) => void;
  size?: "large" | "middle" | "small";
  rest?: any;
}) => {
  return (
    <div
      className={clsx({
        "radio-button-container": true,
        "radio-error": error,
        "radio-disabled": disabled,
      })}
    >
      <input
        className="radio-button__input"
        id={id}
        name={name}
        type="radio"
        defaultValue={defaultValue}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        {...rest}
      />
      <label
        className={`radio-button__label ${
          size === "small" ? "text--body-2" : "text--body-1"
        } `}
        htmlFor={htmlFor}
      >
        {label}
      </label>
    </div>
  );
};
