
import { useTheme } from "@mui/material";
import { Box, Button, Grid, Icon, Label } from "@ntpkunity/controls";
import { Stack } from "@src/components/stack";
import { DocumentsList } from "../documents-list/document-list.component"

export const Documents = () => {
  const theme = useTheme();

  return (
    <>
      <Stack
        title={"Documents"}
        paddingXs={2}
        paddingMd={2}
      >
        <DocumentsList />
      </Stack>
    </>
  );
};
