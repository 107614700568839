import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const AuditTrailWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.audit-trail-wrap": {
        '.scrollable-container': {
          maxHeight: 'calc(100vh - 162px)',
          overflowX: 'hidden',
          overflowY: 'auto',
          scrollbarWidth: 'none',
        ".notes-listing": {
          columnCount: 2,
          ".my-notes-list-wrap": {
            marginBottom: 16
          }
        }
      },
    },
}));
