import { Endpoint } from '@constants';
import { http } from '@utilities';
import {UMS_BASE_URL} from "./order-management";
export const CONFIG_BASE_URL = process.env.CONFIG_BASE_URL;

export namespace TradeInManagement {
    export const getQuestionsAndAnswers = (event_payoad: any, company_id:{company_id: string}, slug: string ): any => {
        const body = {
          url:`${Endpoint.GET_CHECKLIST_QUESTIONS}`,
          method_type: 'post',
          payload: {
            ...event_payoad,

          },
          company_id:company_id
        }
        return http(UMS_BASE_URL).post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body).then((res) => {
            return res.data
        })
    }

    export const getTradeInProviderLogo = ( provider_name: string, company_id: number, slug: string): any => {

        const body = {
          url:`${Endpoint.GET_TRADE_IN_PROVIDER_LOGO}/${provider_name}`,
          method_type: 'get',
          company_id : company_id
        }
        return http(UMS_BASE_URL)
            .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body).then((res) => {
            return res.data
        })
    }

    export const getConfiguration = (dealer_code:string,integration_type:string, provider_name: string , company_id: string, slug:string): any => {
    const body = {
      url:`${Endpoint.DMS_INTEGRATION_BY_PROVIDER_NAME}/${dealer_code}/${integration_type}/${provider_name}`,
      method_type: 'get',
      company_id : company_id
    }
    return http(UMS_BASE_URL)
      .post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body)
      .then((res) => {
            return res.data
        })
    }

    export const getTradeInThroughVin = (event_payload: any, slug: string): any => {
        const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code
         const body = {
          url:`${Endpoint.GET_DMS_VEHICLE_DETAILS_BY_VIN}`,
          method_type: 'post',
          payload: {
            ...event_payload},
         dealer_code:dealer_code,
         service_provider: "KBB"
        }
         return http(UMS_BASE_URL).post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body).then((res) => {
            return res.data
        })
    }

    export const getTradeInYear = (event_payload: any, slug: string) => {
        const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code
        const body = {
          url:`${Endpoint.EP_GET_DMS_TRADE_IN_YEAR}`,
          method_type: 'post',
          payload: {
            ...event_payload},
         dealer_code:dealer_code,
         service_provider: "KBB"
        }
         return http(UMS_BASE_URL).post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body).then((res) => {
            return res.data
        })
    }

    export const getTradinModel = (event_payload: any, slug: string) => {
        const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code
        const body = {
          url:`${Endpoint.EP_GET_DMS_TRADE_IN_MODEL}`,
          method_type: 'post',
          payload: {
            ...event_payload},
         dealer_code:dealer_code,
         service_provider: "KBB"
        }
        return http(UMS_BASE_URL).post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body).then((res) => {
            return res.data
        })
    }

    export const getTradeInMake = (event_payload: any, slug: string) => {
        const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code
        const body = {
          url:`${Endpoint.EP_GET_DMS_TRADE_IN_MAKE}`,
          method_type: 'post',
          payload: {
            ...event_payload},
         dealer_code:dealer_code,
         service_provider: "KBB"
        }
         return http(UMS_BASE_URL).post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body).then((res) => {
            return res.data
        })
    }
    export const getTradeInTrim = (event_payload: any, slug: string) => {
        const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code
        const body = {
          url:`${Endpoint.EP_GET_DMS_TRADE_IN_TRIM}`,
          method_type: 'post',
          payload: {
            ...event_payload},
         dealer_code:dealer_code,
         service_provider: "KBB"
        }
         return http(UMS_BASE_URL).post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body).then((res) => {
            return res.data
        })
    }
    export const getTradeInVehicleDetail = (event_payload: any, slug: string) => {
        const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code
        const body = {
          url:`${Endpoint.EP_GET_DMS_TRADE_IN_VEHICLE_DETAIL}`,
          method_type: 'post',
          payload: {
            ...event_payload},
         dealer_code:dealer_code,
         service_provider: "KBB"
        }
         return http(UMS_BASE_URL).post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body).then((res) => {
            return res.data
        })
    }
    export const getTradeInVehicleValue = (event_payload: any, tenant: any, slug: string) => {
        const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code
        const body = {
          url:`${Endpoint.EP_GET_DMS_TRADE_IN_VEHICLE_VALUE}`,
          method_type: 'post',
          payload: {
            ...event_payload},
         dealer_code:dealer_code,
         service_provider: "KBB",
         company_id: tenant
        }
         return http(UMS_BASE_URL).post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body).then((res) => {
            return res.data
        })
    }
    export const getTradeInVehicleOptions = (event_payload, slug) => {
        const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code
        const body = {
          url:`${Endpoint.EP_GET_DMS_TRADE_IN_VEHICLE_OPTIONS}`,
          method_type: 'post',
          payload: {
            ...event_payload},
         dealer_code:dealer_code,
         service_provider: "KBB"
        }
         return http(UMS_BASE_URL).post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body).then((res) => {
            return res.data
        })
    }
    export const getDealerTradeIn = (dealerCode, companyId, slug) => {

        const body = {
          url:`${Endpoint.GET_CONFIG_DEALER_TRADIN}/${dealerCode}`,
          method_type: 'get',
         company_id: companyId
        }
         return http(UMS_BASE_URL).post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body).then((res) => {
            return res.data
        })
    }

}
