import { Button, Image } from "@src/components/atoms";
import exploreVehicle from "../../../../shared/assets/img/waiting-hub/explore.png";
import exploreVehicleMb from "../../../../shared/assets/img/waiting-hub/explore-mb.png";
import "./bmw-explore.scss";

export const BmwExplore = () => {
  return (
    <div className="bmw-explore-section">
      <div className="bmw-container">
        <div className="bmw-explore__inner">
          <div className="bmw-explore__img">
            <div className="d-none d-xl-block">
              <Image src={exploreVehicle} alt="image" aspectRatio="16:9" />
            </div>
            <div className="d-xl-none">
              <Image src={exploreVehicleMb} alt="image" aspectRatio="2:1" />
            </div>
          </div>
          <div className="bmw-explore__content">
            <h1>More BMWs to explore.</h1>
            <p className="text--body-1">
              Experience everything from the latest model of your current BMW to
              one of our other innovative vehicles - or build your own.
            </p>
            <Button
              type="default"
              htmlType={"button"}
              text="Back to [BMWUSA/Dealer]"
              fullWidth
            />
          </div>
        </div>
      </div>
    </div>
  );
};
