import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const LeadsTableWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({}) => ({
  '&.leads-table-wrap': {
    '.image-cell': {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      '.product-image': {
        maxWidth: 55,
        width: '100%',
        img: {
          display: 'block'
        }
      }
    }
  }
}))
