import { useTheme } from '@mui/material'
import { Box, Button, Grid, Icon, Menu } from '@ntpkunity/controls'
import { DashboardWidgetsWrap } from './dashboard-widgets.style'
import { Stack } from '@components'
import { ValueBox } from 'components/analytics/value-box'
import DashboardGraph01 from '../../../public/assets/images/dash-graph-01.png'
import DashboardGraph02 from '../../../public/assets/images/dash-graph-02.png'
import DashboardGraph03 from '../../../public/assets/images/dash-graph-03.png'

export const DashboardWidgets = () => {
  const theme = useTheme()

  return (
    <DashboardWidgetsWrap theme={theme} className="dashboard-widgets-wrap">
      <Grid theme={theme} container item spacing={3} alignItems={'stretch'}>
        <Grid theme={theme} item lg={4} xs={12}>
          <Stack
            paddingMd={3}
            paddingXs={3}
            className="with-action-only"
            actionArea={
              <Stack paddingMd={1} paddingXs={1} dFlex>
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="ExpandIcon" />}
                  onClick={() => {}}
                />
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="EditPenIcon" />}
                  onClick={() => {}}
                />
                <Menu
                  theme={theme}
                  disablePortal
                  options={[
                    {
                      optionText: 'Option 1',
                      optionValue: 'option-1'
                    },
                    {
                      optionText: 'Option 2',
                      optionValue: 'option 2'
                    }
                  ]}
                  render={(cb) => (
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="ThreeDotMenuIcon" />}
                      onClick={cb}
                    />
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                />
              </Stack>
            }
          >
            <ValueBox title={'Inventory'} value={'380'} progressText={'00%'} success noBg />
            <Box theme={theme} mt={3}>
              <img src={DashboardGraph01} alt="" style={{ maxWidth: '100%' }} />
            </Box>
          </Stack>
        </Grid>
        <Grid theme={theme} item lg={4} xs={12}>
          <Stack paddingMd={3} paddingXs={3}>
            <ValueBox title={'Leads'} value={'124'} progressText={'00%'} success noBg />
            <Box theme={theme} mt={3}>
              <img src={DashboardGraph02} alt="" style={{ maxWidth: '100%' }} />
            </Box>
          </Stack>
        </Grid>
        <Grid theme={theme} item lg={4} xs={12}>
          <Stack paddingMd={3} paddingXs={3}>
            <ValueBox title={'Inquiries'} value={'38'} progressText={'00%'} danger noBg />
            <Box theme={theme} mt={3}>
              <img src={DashboardGraph03} alt="" style={{ maxWidth: '100%' }} />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </DashboardWidgetsWrap>
  )
}
