import { useTheme } from "@mui/material";
import { Box, Typography } from "@ntpkunity/controls";
import { BasicListWrap } from "./basic-list.style";
import { BasicListProps } from "./basic-list.props";
import clsx from "clsx";

export const BasicList = ({ options, noWrap, input, accordion }: BasicListProps) => {
  const theme = useTheme();

  return (
    <>
      <BasicListWrap
        theme={theme}
        className={clsx("basic-list-wrap", { "text-nowrap": noWrap, "input": input, 'accordion': accordion })}
      >
        {options?.map((item, index) => {
          return (
            <Box theme={theme} className="list" key={index}>
              <Typography
                theme={theme}
                variant={"body2"}
                component={"span"}
                className={clsx("text-body2 title", {
                  "text-right": item.textRight,
                  "fw-medium" : item.titleBold
                })}
              >
                {item.title}
              </Typography>
              {item.subtitle &&
                <Typography
                  theme={theme}
                  variant={"body2"}
                  component={"span"}
                  className={clsx("fw-medium text-body2 subtitle", {
                    "text-right": item.textRight,
                  })}
                >
                  {item.subtitle}
                </Typography>
              }
              {item.action &&
                <Box theme={theme} className="action-area">
                  {item.action}
                </Box>
              }
            </Box>
          );
        })}
      </BasicListWrap>
    </>
  );
};
