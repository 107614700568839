import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const SchedulerWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.scheduler-wrap': {
    '.scheduler-box': {
      backgroundColor: '#f2f2f2',
      borderRadius: 8,
      padding: 24,
      '.upper-section': {
        '.section-header': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 16
        },
        '.section-body': {
          '.label-price': {
            marginBottom: 16,
            'p:first-child': {
              color: theme.palette.grey[900]
            },
            'p:last-child': {
              color: theme.palette.grey[600],
              fontSize: 10
            }
          },
          '.date-dots': {
            display: 'flex',
            gap: 4,
            flexWrap: 'wrap',
            marginBottom: 16,
            '.dot': {
              width: 9,
              height: 9,
              display: 'block',
              borderRadius: '50%',
              backgroundColor: theme.palette.grey[100],
              '&.filled': {
                backgroundColor: theme.palette.primary.main
              }
            }
          },
          '.all-appointments': {
            color: theme.palette.grey[600],
            textDecoration: 'none'
          }
        }
      }
    }
  }
}))
