import { dealexPermissions } from '@ntpkunity/controls-ums/dealexPermissions'
import {
  useHasPermissions
  //@ts-ignore
} from '@ntpkunity/controls-ums'
export const useEditPermissionHook = () => {
  const hasAdminPermission = useHasPermissions(dealexPermissions?.SECTIONS?.CAN_EDIT)

  return hasAdminPermission
}
