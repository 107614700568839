import { useTheme } from "@mui/material"
import { Select, Grid, Typography, Textarea, Box, Button } from "@ntpkunity/controls"
import { Stack } from "@src/components/stack"
import { AddStip } from "../add-stip/add-stip.component"


export const Decision = () => {
    const theme = useTheme()

    return (
        <>  
            <AddStip />
            <Stack paddingMd={2} paddingXs={2}>
                <Grid
                    theme={theme}
                    container
                    rowSpacing={2}
                    columnSpacing={2}
                    mt={0}
                >
                    <Grid item xs={12} theme={theme}>
                        <Select
                            theme={theme}
                            disablePortal={false}
                            label={'Decision Type'}
                            items={[
                                { text: "Approved", value: "select1" },
                                { text: "Conditional Approved", value: "select2" },
                                { text: "Additional Information Required", value: "select3" },
                                { text: "Conditioned", value: "select4" },
                                { text: "Referred", value: "select5" },
                                { text: "Declined", value: "select6" },
                            ]}
                        />
                    </Grid>
                </Grid>
                <Grid
                    theme={theme}
                    container
                    rowSpacing={2}
                    columnSpacing={2}
                    mt={2}
                >
                    <Grid item xs={12} theme={theme}>
                        <Select
                            theme={theme}
                            disablePortal={false}
                            label={'Comment Type'}
                            items={[
                                { text: "General Comments", value: "select1" },
                                { text: "Internal Comments", value: "select2" },
                                { text: "Dealer Comments", value: "select3" },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} theme={theme}>
                        <Textarea theme={theme} id="" type="text" fullWidth textCount={"10"} totalTextCount={"60"} rows={4} label="Comments"></Textarea>  
                    </Grid>
                    <Grid item xs={12} theme={theme}>
                        <Box theme={theme} display={'flex'} alignItems={'center'} justifyContent={'center'} gap={1}> 
                            <Button theme={theme} primary text="Send Decision" />
                        </Box>
                    </Grid>
                </Grid>
            </Stack>
        </>
    )
        
            
}