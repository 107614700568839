import {
  LenderSelectionRule,
  LenderSelectionRulePostReq,
} from "../Types/lenderSelectionRules";
import { ConfigurationAPI } from "../services/http-common";
import {
  EP_ADD_DELETE_LENDER_SELECTION_RULE,
  EP_GET_LENDER_SELECTION_RULE,
  EP_UPDATE_LENDER_SELECTION_RULE,
} from "../shared/config/endpoints";

export const getAllLenderSelectionRules = async (params: string) => {
  return await ConfigurationAPI.get(
    `${EP_GET_LENDER_SELECTION_RULE}${params}`
  ).then((res) => res.data);
};
export const addLenderSelectionRule = async (
  reqBody: LenderSelectionRulePostReq
) => {
  return await ConfigurationAPI.post(
    EP_ADD_DELETE_LENDER_SELECTION_RULE,
    reqBody
  );
};
export const editLenderSelectionRule = async (reqBody: LenderSelectionRule) => {
  return await ConfigurationAPI.patch(EP_UPDATE_LENDER_SELECTION_RULE, reqBody);
};
export const deleteLenderSelectionRule = async (reqBody: { id: number }) => {
  return await ConfigurationAPI.delete(
    `${EP_ADD_DELETE_LENDER_SELECTION_RULE}/${reqBody.id}`
  );
};
