import { Tooltip as AntTooltip } from "antd";
import clsx from "clsx";
import { useRef } from "react";
import "../../../styles/components/_tooltip.scss";
export const Tooltip = ({
  text,
  prompt,
  placement,
  open,
  arrow,
  className,
}: {
  text?: string;
  className?: string;
  prompt?: string;
  placement?:
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";
  open?: boolean;
  arrow?: boolean | { pointAtCenter: boolean };
}) => {
  const tooltipWrapRef = useRef<HTMLDivElement>(null);
  return (
    <div
      className={clsx(className, {
        "tooltip-wrapper": true,
      })}
      ref={tooltipWrapRef}
    >
      <AntTooltip
        title={prompt}
        placement={placement}
        defaultOpen={open}
        open={open}
        arrow={arrow}
        getPopupContainer={(trigger) => tooltipWrapRef.current || document.body}
      >
        <span>{text}</span>
      </AntTooltip>
    </div>
  );
};
