import "./read-more-button.style.css";

export const ReadMore = () => {
  return (
    <a
      className="style-button--as-link-read-more"
      data-icon="arrow_chevron_down"
      href="http://www.google.com"
      aria-label="Show more"
    >
      Show more
    </a>
  );
};
