import { FC } from "react";
import { styled, useTheme } from "@mui/material";
import {
  Box,
  Button,
  DatePicker,
  Grid,
  IBoxProps,
  Icon,
  Input,
  Select,
  Switch,
  Typography,
} from "@ntpkunity/controls";
import HolidayRow from "./holiday";
import { Controller, useFieldArray } from "react-hook-form";

export const WdhWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.wdh-wrap": {
    ".wdh-row": {
      display: "flex",
      width: "100%",
      gap: 16,
      marginBottom: 24,
      ".wdh-col": {
        width: "100vh",
        "&.switch": {
          width: "96vh",
          paddingTop: 12,
        },
        "&.caption": {
          width: "auto",
          textAlign: "center",
          color: theme.palette.grey[600],
          paddingTop: 12,
        },
        "&.status": {
          width: "100vh",
          textAlign: "left",
          color: theme.palette.grey[600],
        },
        "&.unable": {
          paddingTop: 12,
        },
      },
    },
  },
}));

export const HdWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.hd-wrap": {
    borderLeft: "1px solid" + theme.palette.divider,
    minHeight: 520,
    paddingLeft: 24,
    ".hd-row": {
      display: "flex",
      width: "100%",
      gap: 16,
      alignItems: "center",
      marginBottom: 24,
      ".hd-col": {
        width: "100vh",
        "&:last-child": {
          width: 78,
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      borderLeft: "0 solid transparent",
      borderTop: "1px solid" + theme.palette.divider,
      minHeight: "auto",
      paddingLeft: 0,
      paddingTop: 24,
    },
  },
}));
const WorkSchedule: FC<{
  form: any;
  dealer_code: string;
}> = ({ form, dealer_code }) => {
  const theme = useTheme();
  const {
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = form;
  const { fields, update, append, remove } = useFieldArray({
    control,
    name: "holidayData",
  });
  const workDays = form.getValues("workingDays");
  const validateTimes = (startTime: string, endTime: string, index: number) => {
    const parseTimeToMinutes = (time: string): number => {
      const [timeStr, period] = time?.split(" ");
      const [hours, minutes] = timeStr?.split(":")?.map(Number);
      let totalMinutes = (hours % 12) * 60 + minutes;
      if (period === "PM") totalMinutes += 12 * 60;
      return totalMinutes;
    };

    const startTimeInMinutes = parseTimeToMinutes(startTime);
    const endTimeInMinutes = parseTimeToMinutes(endTime);

    if (startTimeInMinutes > endTimeInMinutes) {
      setError(`workingDays.working_days[${index}].day_end_time`, {
        message: "End time cannot be earlier than start time",
      });
    } else {
      clearErrors(`workingDays.working_days[${index}].day_end_time`);
    }

    if (endTimeInMinutes < startTimeInMinutes) {
      setError(`workingDays.working_days[${index}].day_start_time`, {
        message: "Start time cannot be smaller than end time",
      });
    } else {
      clearErrors(`workingDays.working_days[${index}].day_start_time`);
    }
  };
  const timeOptions = [
    { text: "12:00 AM", value: "12:00 AM" },
    { text: "12:30 AM", value: "12:30 AM" },
    { text: "01:00 AM", value: "01:00 AM" },
    { text: "01:30 AM", value: "01:30 AM" },
    { text: "02:00 AM", value: "02:00 AM" },
    { text: "02:30 AM", value: "02:30 AM" },
    { text: "03:00 AM", value: "03:00 AM" },
    { text: "03:30 AM", value: "03:30 AM" },
    { text: "04:00 AM", value: "04:00 AM" },
    { text: "04:30 AM", value: "04:30 AM" },
    { text: "05:00 AM", value: "05:00 AM" },
    { text: "05:30 AM", value: "05:30 AM" },
    { text: "06:00 AM", value: "06:00 AM" },
    { text: "06:30 AM", value: "06:30 AM" },
    { text: "07:00 AM", value: "07:00 AM" },
    { text: "07:30 AM", value: "07:30 AM" },
    { text: "08:00 AM", value: "08:30 AM" },
    { text: "09:00 AM", value: "09:00 AM" },
    { text: "09:30 AM", value: "09:30 AM" },
    { text: "10:00 AM", value: "10:00 AM" },
    { text: "10:30 AM", value: "10:30 AM" },
    { text: "11:00 AM", value: "11:00 AM" },
    { text: "11:30 AM", value: "11:30 AM" },
    { text: "12:00 AM", value: "12:00 AM" },
    { text: "12:30 PM", value: "12:30 PM" },
    { text: "01:00 PM", value: "01:00 PM" },
    { text: "01:30 PM", value: "01:30 PM" },
    { text: "02:00 PM", value: "02:00 PM" },
    { text: "02:30 PM", value: "02:30 PM" },
    { text: "03:00 PM", value: "03:00 PM" },
    { text: "03:30 PM", value: "03:30 PM" },
    { text: "04:00 PM", value: "04:00 PM" },
    { text: "04:30 PM", value: "04:30 PM" },
    { text: "05:00 PM", value: "05:00 PM" },
    { text: "05:30 PM", value: "05:30 PM" },
    { text: "06:00 PM", value: "06:00 PM" },
    { text: "06:30 PM", value: "06:30 PM" },
    { text: "07:00 PM", value: "07:00 PM" },
    { text: "07:30 PM", value: "07:30 PM" },
    { text: "08:00 PM", value: "08:00 PM" },
    { text: "08:30 PM", value: "08:30 PM" },
    { text: "09:00 PM", value: "09:00 PM" },
    { text: "09:30 PM", value: "09:30 PM" },
    { text: "10:00 PM", value: "10:00 PM" },
    { text: "10:30 PM", value: "10:30 PM" },
    { text: "11:00 PM", value: "11:00 PM" },
    { text: "11:30 PM", value: "11:30 PM" },
    { text: "12:00 PM", value: "12:00 PM" },
  ];

  return (
    <>
      <Grid theme={theme} container columnSpacing={3} mb={4}>
        <Grid theme={theme} item md={6} sm={12} xs={12}>
          <WdhWrap theme={theme} className="wdh-wrap">
            <Typography
              theme={theme}
              component="h4"
              variant="h4"
              children="Working Days & Hours"
              mb={3}
            />
            {workDays?.working_days?.map((day, index) => {
              return (
                <Box theme={theme} className="wdh-row" key={index}>
                  <Box theme={theme} className="wdh-col switch">
                    <Controller
                      name={`workingDays.working_days[${index}].is_active`}
                      control={control}
                      defaultValue={day.is_active}
                      render={({ field: { value, onChange } }) => (
                        <Switch
                          theme={theme}
                          varient={"basic"}
                          label={day.workday}
                          switchEnabled={value}
                          onChange={(e) => {
                            onChange(e.target.checked);
                          }}
                        />
                      )}
                    />
                  </Box>
                  {!day.is_active && (
                    <>
                      <Box theme={theme} className="wdh-col unable">
                        <Typography
                          theme={theme}
                          component="span"
                          variant="body2"
                          children="Not working on this day"
                        />
                      </Box>
                      <Box theme={theme} className="wdh-col"></Box>
                    </>
                  )}
                  {day.is_active && (
                    <Box theme={theme} className="wdh-col">
                      <Controller
                        name={`workingDays.working_days[${index}].day_start_time`}
                        control={control}
                        defaultValue={day.day_start_time}
                        render={({ field: { value, onChange } }) => (
                          <Select
                            theme={theme}
                            placeholder={"Select"}
                            items={timeOptions}
                            value={value ?? []}
                            onChange={(e) => {
                              const selectedValue = e.target.value as string;
                              const dayEndTime = form.getValues(
                                `workingDays.working_days[${index}].day_end_time`
                              );
                              validateTimes(selectedValue, dayEndTime, index);
                              onChange(selectedValue);
                            }}
                            selectError={
                              errors?.workingDays?.working_days?.[index]
                                ?.day_start_time?.message
                            }
                          />
                        )}
                      />
                    </Box>
                  )}
                  {day.is_active && (
                    <Box theme={theme} className="wdh-col caption">
                      <Typography
                        theme={theme}
                        component="span"
                        variant="body2"
                        children="to"
                      />
                    </Box>
                  )}
                  {day.is_active && (
                    <Box theme={theme} className="wdh-col">
                      <Controller
                        name={`workingDays.working_days[${index}].day_end_time`}
                        control={control}
                        defaultValue={day.day_end_time}
                        render={({ field: { value, onChange } }) => (
                          <Select
                            theme={theme}
                            placeholder={"Select"}
                            items={timeOptions}
                            value={value ?? []}
                            onChange={(e) => {
                              const selectedValue = e.target.value as string;
                              const dayStartTime = form.getValues(
                                `workingDays.working_days[${index}].day_start_time`
                              );
                              validateTimes(dayStartTime, selectedValue, index);
                              onChange(selectedValue);
                            }}
                            selectError={
                              errors?.workingDays?.working_days?.[index]
                                ?.day_end_time?.message
                            }
                          />
                        )}
                      />
                    </Box>
                  )}
                </Box>
              );
            })}
          </WdhWrap>
        </Grid>
        <Grid theme={theme} item md={6} sm={12} xs={12}>
          <HdWrap theme={theme} className="hd-wrap">
            <Typography
              theme={theme}
              component="h4"
              variant="h4"
              children="Holidays"
              mb={3}
            />
            {fields?.map((item, index) => (
              <HolidayRow
                key={item.id}
                form={form}
                index={index}
                remove={remove}
                dealer_code={dealer_code}
              />
            ))}
            <Button
              theme={theme}
              secondary
              fullWidth
              onClick={(e) => {
                append({
                  id: null,
                  holiday_purpose: null,
                  holiday_occurance: null,
                  holiday_date: null,
                });
              }}
              text={"Add New Holiday"}
            />
          </HdWrap>
        </Grid>
      </Grid>
    </>
  );
};

export default WorkSchedule;
