import { FullScreenContent } from "@src/components/molecules";
import { Button, IconList } from "@src/components/atoms";
import { useAppContext } from "@src/context/app-context";
import { BaseLayout } from "@src/layouts";
import { Decision } from "@src/helpers";
import { Input } from "@atoms/input/input.component";
import "./returning-to-dr.scss";

const ReturningToDr = () => {
  const { state: appState } = useAppContext();

  return (
    <BaseLayout hasMenu={false}>
      <div className="full-screen-content-wrap__returning-dr-wrap">
        <FullScreenContent
          title={<h1 className="text-white">Find your next BMW.</h1>}
          subtitle={
            <p className="text--body-1 text-white">
              Choose a BMW Center to access dealer inventory, <br /> set up a
              test drive, and get expert advice.
            </p>
          }
          content={<> </>}
          zipcodeArea={
            <>
              <Input
                id={""}
                name={""}
                placeholder="10012"
                label="Search by ZIP Code"
                showInfoIcon
                onDark
              />

              <Button
                text="Shop Now"
                type="primary"
                htmlType="button"
                className="zipcode-button"
                fullWidth
              />
            </>
          }
        />
      </div>
    </BaseLayout>
  );
};

export default ReturningToDr;
