import { FC, useEffect, useState } from 'react'
//@ts-ignore
import { getAllSettingsFromLocalStorage } from '@helpers/methods'
import { loginRequest } from '../../../authConfig'
import { useMsal, useIsAuthenticated } from '@azure/msal-react'
import jwt_decode from 'jwt-decode'
import { useGetADUser } from '@apis/dealer-workqueue.service'
import { useTheme } from '@mui/material'
import { CircleLoader } from '@ntpkunity/controls'

const CheckLogin: FC = ({ children }) => {
  const isAuthenticated = useIsAuthenticated()
  const { instance } = useMsal()
  const theme = useTheme()
  const [checkSSOLogin, setCheckSSOLogin] = useState(false)
  const { mutate: getADUser } = useGetADUser()

  const getInternalAccessToken = (): any => {
    const settings: any = localStorage.getItem('settings')
    return JSON.parse(settings) as any
  }
  const setInternalAccessToken = (access_token: any) => {
    localStorage.setItem('settings', JSON.stringify(access_token))
  }

  const decodeADAccessToken = (ad_acc_tk: any) => {
    const dec_tk = jwt_decode(ad_acc_tk) as any
    const adUserInfo: any = {
      access_token: ad_acc_tk,
      user_name: dec_tk.preferred_username,
      tenant_id: dec_tk.tid,
      job_code: dec_tk.jobcode,
      hyperion_id: dec_tk.hyperion
    }
    return adUserInfo
  }

  useEffect(() => {
    if (
      window?.location?.pathname?.includes('lane/dealer/work-queue') ||
      window?.location?.pathname?.includes('lane/dealer/work-queue/checklist') ||
      window?.location?.pathname?.includes('lane/dealer/work-queue/schedule-pickup') ||
      window?.location?.pathname?.includes('lane/dealer/work-queue/vehicle-check')
    ) {
      setCheckSSOLogin(true)
      try {
        const internalAccessToken: any = getInternalAccessToken()
        if (null !== internalAccessToken) {
        } else {
          instance
            .acquireTokenSilent({
              ...loginRequest,
              account: instance.getAllAccounts()[0]
            })
            .then(({ accessToken }: any) => {
              if (isAuthenticated) {
                const adUserInfo: any = decodeADAccessToken(accessToken)
                const body = {
                  email: adUserInfo.user_name,
                  job_code: adUserInfo.job_code,
                  ad_tenant_id: adUserInfo.tenant_id
                }
                getADUser(
                  { data: body },
                  {
                    onSuccess(res: any) {
                      setInternalAccessToken(res)
                      window.location.reload()
                    }
                  }
                )
              }
              //@ts-ignore
            })
            .catch(() => {
              //@ts-ignore
              instance.loginRedirect(loginRequest).catch((e) => {
                //@ts-ignore
                instance
                  .handleRedirectPromise()
                  .then(() => {})
                  .catch(() => {})
              })
            })
        }
      } catch (e) {
        //@ts-ignore
        instance.loginRedirect(loginRequest).catch((e) => {})
      }
    }
  }, [isAuthenticated])

  return (
    <>
      {checkSSOLogin && !isAuthenticated ? (
        <CircleLoader theme={theme} />
      ) : (checkSSOLogin && isAuthenticated && getInternalAccessToken()) || !checkSSOLogin ? (
        children
      ) : (
        ''
      )}
    </>
  )
}

export default CheckLogin
