export const FETCH_FINANCE_STRUCTURE_REQUEST = "FETCH_FINANCE_STRUCTURE_REQUEST";
export const FETCH_FINANCE_STRUCTURE_SUCCESS = "FETCH_FINANCE_STRUCTURE_SUCCESS";
export const FETCH_FINANCE_STRUCTURE_FAILURE = "FETCH_FINANCE_STRUCTURE_FAILURE";

export const UPDATE_FINANCE_STRUCTURE_REQUEST = "UPDATE_FINANCE_STRUCTURE_REQUEST";
export const UPDATE_FINANCE_STRUCTURE_SUCCESS = "UPDATE_FINANCE_STRUCTURE_SUCCESS";
export const UPDATE_FINANCE_STRUCTURE_FAILURE = "UPDATE_FINANCE_STRUCTURE_FAILURE";

export const fetchFinanceStructureRequest = (reference_number) => ({
    type: FETCH_FINANCE_STRUCTURE_REQUEST,
    payload: reference_number
});

export const fetchFinanceStructureSuccess = (data) => ({
    type: FETCH_FINANCE_STRUCTURE_SUCCESS,
    payload: data,
});

export const fetchFinanceStructureFailure = (error) => ({
    type: FETCH_FINANCE_STRUCTURE_FAILURE,
    payload: error,
});

export const updateFinanceStructureRequest = (data) => ({
    type: UPDATE_FINANCE_STRUCTURE_REQUEST,
    payload: data,
});

export const updateFinanceStructureSuccess = (data) => ({
    type: UPDATE_FINANCE_STRUCTURE_SUCCESS,
    payload: data,
});

export const updateFinanceStructureFailure = (error) => ({
    type: UPDATE_FINANCE_STRUCTURE_FAILURE,
    payload: error,
});