import { Button, Modal } from "../../atoms";

export const NoInsuranceModal = ({ hide }) => {
  return (
    <Modal
      content={true}
      title={<h3>Insurance required to continue shopping.</h3>}
      onClose={hide}
      subtitle={
        <p className="text--body-1">
          This protects you financially in case of accidents, theft, or damage
          to your vehicle. If you have questions about how to get insurance, or
          you want to continue shopping in person, please contact your BMW
          dealer if you have questions.
        </p>
      }
      open={true}
      footer={
        <Button
          htmlType="button"
          fullWidth
          type="primary"
          text="Contact dealer"
          onClick={hide}
        />
      }
    ></Modal>
  );
};
