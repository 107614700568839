import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const ActivitiesWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.activities-wrap": {
      ".label-wrap": {
        ".label-bg": {
          ".MuiTypography-root": {
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
            "@media(min-width: 1599.95px)": {
              fontSize: theme.typography.body2.fontSize,
              fontWeight: theme.typography.fontWeightMedium,
            }
          },
        }
      }
    },
}));
