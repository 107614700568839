import { RouterProvider } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import router from "./application-router";
import { CDE_APP_THEME, CDE_THEME_WRAP } from "styles";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react"; 
import { store, persistor } from "../components/redux/store/store";

const Application = () => {
  return (
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={CDE_APP_THEME}>
          <CDE_THEME_WRAP className="global-theme-wrap" theme={CDE_APP_THEME}>
            <RouterProvider router={router} />
          </CDE_THEME_WRAP>
        </MuiThemeProvider>
      </StyledEngineProvider>
      </PersistGate>
      </Provider>
  );
};

export default Application;
