import { FC } from 'react'
import { LeadKey, user_session } from '@constants'
import { InputAdornment, useTheme } from '@mui/material'
import { Typography, Box, Input, Icon, Select, Dialog, Button, Skeleton, } from '@ntpkunity/controls'
import { DealershipCardDetail, Popup, Stack, Textbox } from '@components'
import { PricingQuestionnairePopupWrap } from './pricing-questionnaire-popup.style'
import { useEffect, useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { IZipCode } from 'interfaces'
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import { Tags,PageType } from 'constants/enums';
import { useGetConfigurationRadius, getAllDealerDistanceByCustomer, useUpdateLead, useGetCustomerByEmail } from '@hooks'
import {AppContext, updateDealerCode} from '@app/context-provider'
import { useParams } from 'react-router-dom'
import { useGetIntegrationConfiguration } from 'hooks/order-management'
import { mapPayload } from 'helpers/lead'
import { getDealerInfo, getUserProfile, setUserProfile } from 'helpers/methods'

const formControlName = {
    radius: 'radius',
    zip_code: 'zip_code'
}

export const PricingQuestionnairePopup: FC<{
    companyId?: any
    closeDialog?: boolean
    open?: boolean
    popupText?: any
    setPopUpValue?: any
}> = ({ companyId, closeDialog, popupText, open, setPopUpValue }) => {
    const theme = useTheme()
    const [radiusValue, setRadiusValue] = useState(null);
    const [radiusArray, setRadiusArray] = useState([]);
    const { tenant } = useParams()
    let user_id
    const setting = JSON.parse(localStorage.getItem("settings"))
    const [distanceRadius, setDistanceRadius] = useState([]);
    const [zipCode, setZipCode] = useState("");
    const userProfileInfo = getUserProfile();
    const { data: configurationRadius, isLoading: isLoadingConfiguration } = useGetConfigurationRadius(companyId ?? '', tenant)
    const { mutate: getDealerDistance, isLoading: isLoadingDealerDistance } = getAllDealerDistanceByCustomer()
    const { mutateAsync: getProvider } = useGetIntegrationConfiguration();
    const { data: customerData, isFetched, isLoading } = useGetCustomerByEmail(
        JSON.parse(localStorage.getItem("settings"))?.email
      );
   useEffect(()=>{
    if(customerData && userProfileInfo){
        setUserProfile({...userProfileInfo,
            first_name:customerData.first_name,
            last_name:customerData.last_name,
            contact_number:customerData.mobile_number
        })
    }
   },[customerData])   
    const appState = useContext(AppContext);
    const { mutate: createTags } = useTags();
    const PLACEHOLDERS = appState.state.language.placeholders;
    const { mutate: updateLead } = useUpdateLead(appState?.state?.slug);

    const form = useForm<any>({
        mode: 'all'
    })

    const handleScroll = () => {
        const event: Event = {
            session: user_session,
            slug:tenant,
            event_name:Tags.SCROLL,
            user_id: user_id
        }
        createTags(event)
      };
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = form

    useEffect(()=>{
        const event: Event = {
            session: user_session,
            slug:tenant,
            event_name:Tags.SESSION_END,
            user_id: user_id
        }
        createTags(event)
        const event_page: Event = {
            session: user_session,
            slug:tenant,
            event_name:Tags.ZIPCODE_POPUP,
            user_id: user_id,
            page_type:PageType.ZipCode
        }
        createTags(event_page)
    },[])
    useEffect(() => {
        const defaultRadius = configurationRadius?.find((radius: any) => {
            return radius.radius_value === appState?.state?.default_radius;
        });
        if (defaultRadius) {
            setRadiusValue(defaultRadius?.radius_value);
        }
        setRadiusArray(configurationRadius)
    }, [configurationRadius]);

    useEffect(() => {
        const zipCodeData = localStorage.getItem('user_profile')
        const zipCodeObject = JSON.parse(zipCodeData);
        setZipCode(zipCodeObject?.zip_code)
        user_id = zipCodeObject?.user_id
        if (zipCodeObject?.zip_code) {
            reset({
                zip_code: zipCodeObject?.zip_code,
                radius: appState?.state?.default_radius,
            });
        }
        const payload = {
            zip_code: zipCodeObject?.zip_code,
            radius: appState?.state?.default_radius || 0,
            companyId: companyId,
            slug: appState?.state?.slug
        };

        getDealerDistance(payload, {
            onSuccess: (data) => {
                setDistanceRadius(data?.result);
            },
            onError: (error) => {
                console.error("Error:", error);
            }
        });


    }, [companyId]);

    const handleSelect = async(dealer, index) => {
        dealer.dealer_selected = true;
        let lead = mapPayload(dealer?.dealer_id,tenant,customerData?.reference_id,LeadKey.Customer_Detail,{'email':setting?.email})
        updateLead(lead);
        localStorage.setItem("dealer_information", JSON.stringify({ dealer: dealer }));
        appState.dispatch(updateDealerCode(dealer))
        const zipCodeData = localStorage.getItem('user_profile')
        const zipCodeObject = JSON.parse(zipCodeData);
        localStorage.setItem(
            "dealer_zip_code",
            JSON.stringify({
                "zip_code": zipCode,
                "radius": radiusValue
            })
        );

        setPopUpValue(false)
    }

    const onSubmit = (data: IZipCode) => {

        const payload = {
            zip_code: data.zip_code,
            radius: radiusValue,
            companyId: companyId,
            slug: appState?.state?.slug
        }
        setZipCode(data?.zip_code)
        localStorage.setItem(
            "dealer_zip_code",
            JSON.stringify({
                "zip_code": zipCode,
                "radius": payload.radius || 0
            })
        );

        getDealerDistance(payload, {
            onSuccess: (data) => {
                if (data?.result) {
                    setDistanceRadius(data?.result)
                }
                if (data?.detail) {
                    setDistanceRadius([])
                }
            },
            onError: (error) => {
                console.error("Error:", error);
                // Handle error
            }
        });
    }

    return (
        <>
            <Popup>
                <Dialog
                    theme={theme}
                    size="sm"
                    title={PLACEHOLDERS.ZIP_CODE_TELL_US}
                    open={open}
                    onCloseDialog={!closeDialog ? () => { } : undefined}
                    disablePortal
                    noFooter
                >
                    <PricingQuestionnairePopupWrap className='pricing-questionnaire-popup-wrap has-header-footer' theme={theme}>
                        <Stack bgLight paddingXs={3} paddingMd={3} className='delivery-preference'>
                            <Typography theme={theme} className='text-muted' variant='caption' component={'small'} mb={3} display={'block'}>
                                {popupText}
                            </Typography>
                            <Textbox>
                                <Controller
                                    name={formControlName.zip_code as any}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: PLACEHOLDERS.ZIP_CODE_REQUIRED,
                                        },
                                        maxLength: {
                                            value: 5,
                                            message: PLACEHOLDERS.ZIP_CODE_LENGTH_5,
                                        },
                                        minLength: {
                                            value: 5,
                                            message: PLACEHOLDERS.ZIP_CODE_LENGTH_5,
                                        }
                                    }}
                                    render={({ field }) => (
                                        <Input
                                            theme={theme}
                                            type='text'
                                            fullWidth
                                            placeholder={PLACEHOLDERS.ZIP_CODE_ENTER}
                                            label={PLACEHOLDERS.ZIP_CODE}
                                            {...field}
                                            onKeyDown={(e) => { if (e.key === 'Enter') { handleSubmit(onSubmit)(); } }}
                                            error={errors?.zip_code?.message.toString()}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <Icon name="SearchIcon"
                                                        onClick={handleSubmit(onSubmit)}
                                                    />
                                                </InputAdornment>
                                            }
                                        />
                                    )}
                                />
                            </Textbox>
                        </Stack>
                        <Box theme={theme} className='menu-with-title' mt={2}>
                            <Typography theme={theme} variant='body2' component={'p'}>
                                {PLACEHOLDERS.ZIP_CODE_SELECT_DEALERSHIP}
                            </Typography>
                            <Box theme={theme} className='menu'>
                                <Typography theme={theme} className='fw-medium text-muted select-title' variant='body2' component={'span'}>
                                    {PLACEHOLDERS.ZIP_CODE_RANGE}:
                                </Typography>
                                <Controller
                                    name={formControlName.radius as any}
                                    defaultValue={'SELECT'}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            endAdornment={PLACEHOLDERS.MILES_TEXT}
                                            theme={theme}
                                            disablePortal={false}
                                            items={radiusArray?.map((item) => ({
                                                text: item.radius_value.toString(), // Assuming you want to use radius_value as text
                                                value: item.radius_value // Assuming you want to use radius_value as value
                                            }))}
                                            sxProps={''}
                                            value={radiusValue}
                                            selectError={errors?.employment_type?.message?.toString()}
                                            onChange={(e: any) => {
                                                setRadiusValue(e.target.value)
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>

                        {isLoadingDealerDistance ? (
                            <Skeleton theme={theme} width={'100%'} height={34} />
                        ) : (
                            <DealershipCardDetail
                                noDealershipTitle={distanceRadius && distanceRadius?.length === 0 ? PLACEHOLDERS.ZIP_CODE_NO_DEALERSHIP_FOUND : ''}
                                noDealershipDetail={distanceRadius && distanceRadius?.length === 0 ? PLACEHOLDERS.ZIP_CODE_NO_DEALERSHIP_AVAILABLE : ''}
                                items={
                                    distanceRadius && distanceRadius?.length > 0
                                        ? distanceRadius.map((dealer, index) => ({
                                            title:  `${dealer.dealer_name}`,
                                            subtitle:`${dealer.dealer_address_1.trim()}, ${dealer.dealer_city ? dealer.dealer_city.trim() : ''}, ${dealer.dealer_zip_code}${dealer?.dealer_state_name ? `, ${dealer?.dealer_state_name.trim()}` : ''}`,
                                            distance: `${dealer.dealer_active == true ? dealer.dealer_distance.toFixed(1) : ''}`,
                                            selected: dealer.false,
                                            onClick: () => handleSelect(dealer, index)
                                        }))
                                        : []
                                }
                            />
                        )}
                    </PricingQuestionnairePopupWrap>
                </Dialog>
            </Popup>
        </>
    )
}
