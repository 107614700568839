import { useTheme } from "@mui/material";
import { Box, Button, Icon, Input } from "@ntpkunity/controls";
// import { useWorkQueueContext } from "@src/context/WorkQueueContext";

export const WorkqueueFilter = ({ columnKey }) => {
  const theme = useTheme();
  // const { filters, updateFilter } = useWorkQueueContext();

  const handleChange = (event: React.ChangeEvent) => {
    if (event !== undefined) {
      // updateFilter(columnKey, event);
    } else {
      console.error("Input event structure is invalid:", event);
    }
  };
  

  return (
    <>
      <Box theme={theme} className="table-filter">
        <Input
          theme={theme}
          fullWidth={true}
          type={"text"}
          startAdornment=""
          endAdornment=""
          id=""
          placeholder={"Search..."}
          onChange={(event) => handleChange(event)}
          // value={filters[columnKey] || ""}
        />
          <Button
            theme={theme}
            defaultBtn
            iconText={<Icon name="IconFilter" />}
            onClick={() => {
              console.log(`Filter applied for column: ${columnKey}`);
            }}
          />
      </Box>    
    </>
  );

};
