export const FETCH_MY_NOTES_REQUEST = "FETCH_MY_NOTES_REQUEST";
export const FETCH_MY_NOTES_SUCCESS = "FETCH_MY_NOTES_SUCCESS";
export const FETCH_MY_NOTES_FAILURE = "FETCH_MY_NOTES_FAILURE";

export const fetchMyNotesRequest = () => ({
  type: FETCH_MY_NOTES_REQUEST,
});

export const fetchMyNotesSuccess = (notes) => ({
  type: FETCH_MY_NOTES_SUCCESS,
  payload: notes,
});

export const fetchMyNotesFailure = (error) => ({
  type: FETCH_MY_NOTES_FAILURE,
  payload: error,
});