import { useTheme } from '@mui/material'
import { Box, Button, Checkbox, Dialog } from '@ntpkunity/controls'
import { IDealerConsentProps } from './dealer-consent.types'
import { CustomDialogWrap } from '@components'
import { Controller, useForm } from 'react-hook-form'

const DealerConsent = ({
  selectedLenders = [],
  dialogOpen,
  setDialogOpen,
  onConfirm,
  openSlModal,
  dealerConsentDetail
}: IDealerConsentProps) => {
  const theme = useTheme()
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm()

  const onSubmit = (data: any) => {
    onConfirm(data)
  }
  return (
    <CustomDialogWrap className="custom-dialog-wrap">
      <Dialog
        theme={theme}
        title={'Information & Notices'}
        size="xl"
        open={dialogOpen}
        disablePortal
        onCloseDialog={() => {
          setDialogOpen(false)
        }}
        onBackBtn={
          selectedLenders.length > 1
            ? () => {
                setDialogOpen(false)
                openSlModal()
              }
            : undefined
        }
        customFooter={
          <>
            <Button
              theme={theme}
              primary
              text={'Submit Application'}
              onClick={handleSubmit(onSubmit)}
            />
          </>
        }
      >
        <Box theme={theme} className="custom-p">
          <div dangerouslySetInnerHTML={{ __html: dealerConsentDetail as string }}></div>
        </Box>
        <Box className="checkbox-cell fixed-cell" theme={theme}>
          <Controller
            name="check_box_value"
            control={control}
            rules={{
              validate: (value) => value || 'You must agree to the terms' // If no value, return an error message
            }}
            render={({ field }) => (
              <Checkbox
                label="I have read and agree to the information and notices."
                theme={theme}
                checkBoxChecked={field.value ? true : false}
                {...field}
                value={field.value ? true : false}
                onChange={(e) => {
                  field.onChange(e)
                }}
                error={!!errors?.check_box_value}
              ></Checkbox>
            )}
          />
        </Box>
      </Dialog>
    </CustomDialogWrap>
  )
}
export default DealerConsent
