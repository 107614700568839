import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const UserNameWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.user-name-wrap": {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    ".u-avator": {
      backgroundColor: "#474A59"
    },
    ".user-name": {
        whiteSpace: 'nowrap'
    },
    ".MuiTypography-root": {
      "@media(min-width: 1599.95px)": {
        fontSize: theme.typography.body1.fontSize,
        lineHeight: theme.typography.body1.lineHeight,
        fontWeight: theme.typography.body1.fontWeight,
      }
    }
  }
}));
