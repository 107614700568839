import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const DocumentsListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.document-list-wrap": {
    ".documents": {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        marginTop: 16
    }
  }
}));
