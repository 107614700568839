import { TableCell, TableRow, useTheme } from "@mui/material";
import { Skeleton } from "@ntpkunity/controls";
import { FC } from "react";

export const SkeletonTr: FC = () => {
  const theme = useTheme();
  return (
    <TableRow>
      <TableCell key={1} className="text-center checkbox-cell">
        <Skeleton theme={theme} height={14} />
      </TableCell>
      {Array.from({ length: 8 }).map((_, index) => (
        <TableCell key={index + 1} className="text-center">
          <Skeleton theme={theme} height={14} />
        </TableCell>
      ))}
      <TableCell key={10} className="text-center action-cell">
        <Skeleton theme={theme} height={14} />
      </TableCell>
    </TableRow>
  );
};
