import { ThemeConfig } from "antd";

export const GLOBAL_THEME: ThemeConfig = {
  cssVar: {
    prefix: "bmw",
  },
  token: {
    colorPrimary: "#1c69d4",
    colorInfo: "#1c69d4",
    colorSuccess: "#1b7b3a",
    colorLink: "#262626",
    colorTextBase: "#262626",
    colorText: "#262626",
    colorWarning: "#ffad1f",
    colorError: "#D20000",
    colorWhite: "#ffffff",
    colorBorder: "#bbbbbb",
    colorBorderSecondary: "#e6e6e6",
    fontFamily:
      '"bmwTypeNextWeb", "Arial", "Helvetica", "Roboto", sans-serif !important',
    colorTextSecondary: "#666666",
    screenMDMin: 768,
    screenMDMax: 1024,
    screenLGMin: 1024,
    screenLGMax: 1280,
    screenLG: 1280,
    screenXL: 1920,
    screenXLMin: 1280,
    screenXLMax: 1920,
    screenXXLMin: 1920,
    screenXXL: 2480,
    colorTextDisabled: "",
    colorErrorBg: "#F7E7E9",
    colorTextLightSolid: "#F2F2F2",
    linkHoverDecoration: "underline",
    colorLinkHover: "#262626",
    linkFocusDecoration: "underline",
  },
  components: {
    Button: {
      dangerColor: "#ffffff",
      dangerShadow: "none",
      defaultActiveBg: "transparent",
      defaultActiveBorderColor: "#262626",
      defaultActiveColor: "#262626",
      defaultBg: "transparent",
      defaultBorderColor: "#262626",
      defaultColor: "#262626",
      defaultHoverBg: "transparent",
      ghostBg: "#4d4d4d",
      defaultHoverBorderColor: "#262626",
      defaultHoverColor: "#262626",
      defaultShadow: "transparent",
      fontWeight: "400",
      linkHoverBg: "transparent",
      onlyIconSize: 24,
      primaryShadow: "transparent",
      textHoverBg: "transparent",
      textTextActiveColor: "#0653b6",
      textTextColor: "#262626",
      textTextHoverColor: "#1c69d4",
      fontSize: 14,
      fontFamily:
        '"bmwTypeNextWeb", "Arial", "Helvetica", "Roboto", sans-serif !important',
      boxShadow: "none",
      borderColorDisabled: "#E6E6E6",
      borderRadius: 3,
      paddingInline: 24,
      paddingInlineSM: 24,
      paddingBlock: 14,
      paddingBlockSM: 16,
      colorPrimaryHover: "#1c69d4",
      colorPrimaryActive: "#1c69d4",
      colorPrimaryBorder: "transparent",
      colorPrimaryBgHover: "#1c69d4",
      colorPrimaryTextHover: "#1c69d4",
      colorPrimaryTextActive: "#ffffff",
    },
    Input: {
      activeBg: "#ffffff",
      activeBorderColor: "transparent",
      colorBorder: "#BBBBBB",
      borderRadius: 3,
      paddingBlock: 14,
      paddingBlockSM: 6,
      paddingInline: 20,
      paddingInlineSM: 8,
      controlHeightSM: 36,
      hoverBorderColor: "transparent",
      activeShadow: "0 0 0 2px #4d4d4d",
      errorActiveShadow: "none",
      inputFontSize: 14,
      colorTextPlaceholder: "#666666",
      colorErrorBorder: "#D20000",
      colorErrorBorderHover: "0 0 0 2px #D20000",
    },
  },
};
