import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

const PageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.page-content': {
    '.TabsUnstyled-root.TabsUnstyled-horizontal': {
      '.tab-list-underline': {
        justifyContent: 'center',
        position: 'sticky',
        zIndex: 1,
        top: 0,
        backgroundColor: theme.palette.common.white,
        '.underline-varient-btn': {
          fontSize: theme.typography.fontSize
        }
      }
    }
  }
}))

export default PageWrap
