import { FC } from 'react'
import { useTheme } from '@mui/material'
import SendingChat from './SendingChats/SendingChats-component'
import ReceivingChat from './ReceivingChats/ReceivingChats-component'
import { TextDivider, Typography } from '@ntpkunity/controls'
import ChatBox from './ChatBox/ChatBox-component'
import ChatsWrap from './Chats.style'

const Chats: FC = () => {
  const theme = useTheme()
  return (
    <ChatsWrap theme={theme} className="chats-wrap">
      <SendingChat />
      <ReceivingChat />
      <TextDivider
        theme={theme}
        title={
          <>
            <Typography
              theme={theme}
              variant="body2"
              component="span"
              mb={1}
              className="text-uppercase"
            >
              JUNE 15, 2024
            </Typography>
          </>
        }
      />
      <SendingChat />
      <ReceivingChat />
      <TextDivider
        theme={theme}
        title={
          <>
            <Typography
              theme={theme}
              variant="body2"
              component="span"
              mb={1}
              className="text-uppercase"
            >
              TODAY
            </Typography>
          </>
        }
      />
      <ReceivingChat />
      <ChatBox />
    </ChatsWrap>
  )
}

export default Chats
