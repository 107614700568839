import { IFilteredParams } from "../../../Interface/LenderSelectionRule/IFilteredParams";
import { TableCell, useTheme } from "@mui/material";
import { Box, Button, Icon, Input } from "@ntpkunity/controls";
import { FC } from "react";
import { Control, Controller } from "react-hook-form";

type SearchFieldProps = {
  name: FilteredParamsKeys;
  control: Control<IFilteredParams>;
  id: string;
  handleFiltersChange: () => void;
};

type FilteredParamsKeys =
  | "filtered_product_types"
  | "filtered_finance_types"
  | "filtered_finance_amount_ranges"
  | "filtered_customer_types"
  | "filtered_asset_categories"
  | "filtered_asset_types"
  | "filtered_dealers"
  | "filtered_lenders";

export const SearchField: FC<SearchFieldProps> = ({
  name,
  control,
  id,
  handleFiltersChange,
}) => {
  const theme = useTheme();

  return (
    <>
      <TableCell>
        <Box theme={theme} className="table-filter">
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Input
                id={id}
                theme={theme}
                fullWidth={true}
                placeholder={"Search..."}
                type="text"
                {...field}
                onKeyDown={handleFiltersChange}
              />
            )}
          />
          <Button
            defaultBtn
            onClick={handleFiltersChange}
            iconText={<Icon name="IconFilter" />}
          />
        </Box>
      </TableCell>
    </>
  );
};
