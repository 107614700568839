import { useTheme } from "@mui/material";
import { Typography, Grid, Dialog, Box, DuoTab, Icon, Textarea } from "@ntpkunity/controls";
import { Popup } from "@src/components/Dialog/dialog.component";
import { AddDecisionDialogWrap } from "./add-decision-dialog.style"
import { Stack } from "@src/components/stack";
import { Decision } from "../add-decision-popup-data/decision/decision.component"
import { DecisionHistory } from "../add-decision-popup-data/decision-history/decision-history.component"

export const AddDecisionDialog = ({ open, closeDialog }) => {
  const theme = useTheme();

  return (
    <>
      <Popup>
        <Dialog
          theme={theme}
          size={"sm"}
          onCloseDialog={closeDialog}
          open={open}
          title={"Send Decision"}
          disablePortal
          noFooter
        >
          <AddDecisionDialogWrap
            theme={theme}
            className="add-decision-dialog-wrap"
          >
            <DuoTab
              theme={theme}
              varient="underline"
              defaultTabIndex={0}
              items={[
                {
                  content: <><Decision /></>,
                  title: 'Decision',
                },
                {
                  content: <><DecisionHistory /></>,
                  title: 'Decision History',
                },
              ]}
            />
          </AddDecisionDialogWrap>
        </Dialog>
      </Popup>
    </>
  );
};
