import { getIntegrations } from '@apis/dealer-configurations.service'
import { QueryKeys } from '@helpers/enums'
import { useQuery, UseQueryResult } from 'react-query'
import { IIntegrationsResponse } from 'types/responses/companyIntegrations'

export const useGetIntegrationsByCompanyId = (
  companyId: number
): UseQueryResult<IIntegrationsResponse[]> => {
  return useQuery<IIntegrationsResponse[]>({
    queryKey: [QueryKeys.GET_INTEGRATIONS_BY_COMPANY_ID, companyId],
    queryFn: () => getIntegrations(companyId),
    enabled: !!companyId
  })
}
