import { Input } from "@src/components/atoms";
import "./bank-details.scss";

export const BankDetails = () => {
  return (
    <div className="bank-details">
      <p className="bank-details-subtitle text--body-2">
        Enter your bank details
      </p>
      <div className="bank-details-inputs-wrapper">
        <div className="bank-details-input institution-name">
          <Input
            id={"bank-details-input--institution_name"}
            name={"bank-details-input--institution_name"}
            placeholder="Institution name"
            label="Institution name"
          />
        </div>
        <div className="bank-details-input branch-name">
          <Input
            id={"bank-details-input--branch_name"}
            name={"bank-details-input--branch_name"}
            placeholder="Branch name"
            label="Branch name"
          />
        </div>
        <div className="bank-details-input account-number">
          <Input
            id={"bank-details-input--account_number"}
            name={"bank-details-input--account_number"}
            placeholder="Account number"
            label="Account number"
          />
        </div>
        <div className="bank-details-input financial-code">
          <Input
            id={"bank-details-input--financial_code"}
            name={"bank-details-input--financial_code"}
            placeholder="Financial institution code"
            label="Financial institution code"
          />
        </div>
        <div className="bank-details-input swift-number">
          <Input
            id={"bank-details-input--swift_number"}
            name={"bank-details-input--swift_number"}
            placeholder="Swift number"
            label="Swift number"
          />
        </div>
        <div className="bank-details-input routing-number">
          <Input
            id={"bank-details-input--routing_number"}
            name={"bank-details-input--routing_number"}
            placeholder="Routing number"
            label="Routing number"
          />
        </div>
      </div>
    </div>
  );
};
