import {
  WelcomeBackBanner,
  Deals,
  HeroSection,
} from "@src/components/organisms";
import { BaseLayout } from "@src/layouts";

const DrStartPage = ({ haveDeals = true }: { haveDeals?: boolean }) => {
  return (
    <BaseLayout hasMenu={false} hasFooter={true}>
      <HeroSection
        buildInProgress={true}
        haveDeals={true}
        checkoutInProgress={false}
        hasAlert={false}
      />
      {/* <WelcomeBackBanner /> */}
      {haveDeals && <Deals />}
    </BaseLayout>
  );
};

export default DrStartPage;
