import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { Button, Dialog, Grid, Icon } from '@ntpkunity/controls'
import { LayoutWithSideNav } from '@styles/layout'
import { DashboardStyle } from './dashboard.style'
// // import TradeInControl from 'controls/TradeInControl/trade-in-control'
// import InsuranceControl from 'controls/InsuranceControl/insurance-control'
// import { InsuranceFormData } from 'controls/InsuranceControl/insurance-control.types'
import EditFees from 'controls/fees/edit-fees'
import { useStoreContext } from '@store/storeContext'
import { useGetDealerProfileById } from '@apis/dealer-configurations.service'
// import { IOption } from 'controls/addon-control/addon-interface'
// import TradeInControl from 'controls/TradeInControl/trade-in-control'

const TestingPage: FC = () => {
  const theme = useTheme()

  //   const [_financeType] = useState<'Finance' | 'Lease'>('Lease')

  const { states } = useStoreContext()

  const { mutate: getDealerProfile, data: _dealerProfileData } = useGetDealerProfileById()

  useEffect(() => {
    if (states?.dealerAndLenderInfo?.id !== undefined) {
      getDealerProfile({
        dealer_id: states?.dealerAndLenderInfo?.dealer_code
      })
    }
  }, [states?.dealerAndLenderInfo?.id])

  // const optionsDefaultData: IOption[] = [
  //     {
  //         is_active: true,
  //         is_deleted: false,
  //         id: 3334,
  //         specific_category: null,
  //         image: [],
  //         vehicle_type: 710,
  //         dealer_id: 2703,
  //         supplier: "Dealer 001",
  //         offered_by: "OEM",
  //         installation_mode: "Optional",
  //         video_url: null,
  //         description: "Test RV on Option",
  //         price: 300,
  //         brochure_url: null,
  //         product_name: "Test RV on Option",
  //         is_price_inclusive: false,
  //         category_id: 712,
  //         rv_adder: 150,
  //         category_name: "Audio & Entertainment",
  //         part_no: "1",
  //         compatible_models: [
  //             {
  //                 is_active: true,
  //                 is_deleted: false,
  //                 id: 1516,
  //                 trim_name: "i5 M60",
  //                 trim_description: "i5 M60",
  //                 trim_code: "B5002",
  //                 trim_image: [
  //                     {
  //                         location: "https://unitydmsbucket.s3.amazonaws.com/9886abi5+m60.jpg",
  //                         key: "9886abi5 m60.jpg",
  //                         size: 211599,
  //                         name: "i5 m60.jpg"
  //                     }
  //                 ],
  //                 model_id: 1769
  //             },
  //             {
  //                 is_active: true,
  //                 is_deleted: false,
  //                 id: 1514,
  //                 trim_name: "M3",
  //                 trim_description: "M3",
  //                 trim_code: "B3002",
  //                 trim_image: [
  //                     {
  //                         location: "https://unitydmsbucket.s3.amazonaws.com/72317dm3.png?AWSAccessKeyId=AKIA3C6BGWJIJJES2KX2&Signature=3IkSz79YeP3HUCRhbvo0qBIkXw0%3D&Expires=1708934277",
  //                         key: "72317dm3.png",
  //                         size: 190687,
  //                         name: "m3.png"
  //                     }
  //                 ],
  //                 model_id: 1768
  //             },
  //             {
  //                 is_active: true,
  //                 is_deleted: false,
  //                 id: 1518,
  //                 trim_name: "i7 M70",
  //                 trim_description: "i7 M70",
  //                 trim_code: "B7002",
  //                 trim_image: [
  //                     {
  //                         location: "https://unitydmsbucket.s3.amazonaws.com/f1da71i7+m70.jpg",
  //                         key: "f1da71i7 m70.jpg",
  //                         size: 506337,
  //                         name: "i7 m70.jpg"
  //                     }
  //                 ],
  //                 model_id: 1770
  //             },
  //             {
  //                 is_active: true,
  //                 is_deleted: false,
  //                 id: 1515,
  //                 trim_name: "530i xDrive",
  //                 trim_description: "530i xDrive",
  //                 trim_code: "B5001",
  //                 trim_image: [
  //                     {
  //                         location: "https://unitydmsbucket.s3.amazonaws.com/15c91d530i.webp",
  //                         key: "15c91d530i.webp",
  //                         size: 21516,
  //                         name: "530i.webp"
  //                     }
  //                 ],
  //                 model_id: 1769
  //             },
  //             {
  //                 is_active: true,
  //                 is_deleted: false,
  //                 id: 1555,
  //                 trim_name: "xDrive 28i",
  //                 trim_description: "xDrive 28i",
  //                 trim_code: "X1001",
  //                 trim_image: [
  //                     {
  //                         location: "https://unitydmsbucket.s3.amazonaws.com/eaa647x28i-01.png",
  //                         key: "eaa647x28i-01.png",
  //                         size: 324899,
  //                         name: "x28i-01.png"
  //                     }
  //                 ],
  //                 model_id: 1786
  //             },
  //             {
  //                 is_active: true,
  //                 is_deleted: false,
  //                 id: 1556,
  //                 trim_name: "M35i",
  //                 trim_description: "M35i",
  //                 trim_code: "X1002",
  //                 trim_image: [
  //                     {
  //                         location: "https://unitydmsbucket.s3.amazonaws.com/d7ebe1x1-01.png",
  //                         key: "d7ebe1x1-01.png",
  //                         size: 382306,
  //                         name: "x1-01.png"
  //                     }
  //                 ],
  //                 model_id: 1786
  //             },
  //             {
  //                 is_active: true,
  //                 is_deleted: false,
  //                 id: 1513,
  //                 trim_name: "330i xDrive",
  //                 trim_description: "330i xDrive",
  //                 trim_code: "B3001",
  //                 trim_image: [
  //                     {
  //                         location: "https://unitydmsbucket.s3.amazonaws.com/0e617f330i+xdrive.jpg",
  //                         key: "0e617f330i xdrive.jpg",
  //                         size: 180907,
  //                         name: "330i xdrive.jpg"
  //                     }
  //                 ],
  //                 model_id: 1768
  //             },
  //             {
  //                 is_active: true,
  //                 is_deleted: false,
  //                 id: 1557,
  //                 trim_name: "xDrive 40i",
  //                 trim_description: "xDrive 40i",
  //                 trim_code: "X5001",
  //                 trim_image: [
  //                     {
  //                         location: "https://unitydmsbucket.s3.amazonaws.com/905670x5g-01.webp",
  //                         key: "905670x5g-01.webp",
  //                         size: 90826,
  //                         name: "x5g-01.webp"
  //                     }
  //                 ],
  //                 model_id: 1787
  //             },
  //             {
  //                 is_active: true,
  //                 is_deleted: false,
  //                 id: 1517,
  //                 trim_name: "750e xDrive",
  //                 trim_description: "750e xDrive",
  //                 trim_code: "B7001",
  //                 trim_image: [
  //                     {
  //                         location: "https://unitydmsbucket.s3.amazonaws.com/2b7b0d750e+xdrive.jpg",
  //                         key: "2b7b0d750e xdrive.jpg",
  //                         size: 711345,
  //                         name: "750e xdrive.jpg"
  //                     }
  //                 ],
  //                 model_id: 1770
  //             },
  //             {
  //                 is_active: true,
  //                 is_deleted: false,
  //                 id: 1750,
  //                 trim_name: "230i xDrive",
  //                 trim_description: "230i xDrive",
  //                 trim_code: "B2001",
  //                 trim_image: [
  //                     {
  //                         location: "https://unitydmsbucket.s3.amazonaws.com/ed8d303MW33CM0XP8D62382_1.jpg",
  //                         key: "ed8d303MW33CM0XP8D62382_1.jpg",
  //                         size: 16166,
  //                         name: "3MW33CM0XP8D62382_1.jpg"
  //                     }
  //                 ],
  //                 model_id: 1882
  //             },
  //             {
  //                 is_active: true,
  //                 is_deleted: false,
  //                 id: 1751,
  //                 trim_name: "Sport Utility",
  //                 trim_description: "Sport Utility",
  //                 trim_code: "X3001",
  //                 trim_image: [
  //                     {
  //                         location: "https://unitydmsbucket.s3.amazonaws.com/37cdcf5UX53DP02R9T42986_1.jpg",
  //                         key: "37cdcf5UX53DP02R9T42986_1.jpg",
  //                         size: 17681,
  //                         name: "5UX53DP02R9T42986_1.jpg"
  //                     }
  //                 ],
  //                 model_id: 1883
  //             },
  //             {
  //                 is_active: true,
  //                 is_deleted: false,
  //                 id: 1558,
  //                 trim_name: "M60i",
  //                 trim_description: "M60i",
  //                 trim_code: "X5003",
  //                 trim_image: [
  //                     {
  //                         location: "https://unitydmsbucket.s3.amazonaws.com/fa9b0cx5m-01.png",
  //                         key: "fa9b0cx5m-01.png",
  //                         size: 386845,
  //                         name: "x5m-01.png"
  //                     }
  //                 ],
  //                 model_id: 1787
  //             },
  //             {
  //                 is_active: true,
  //                 is_deleted: false,
  //                 id: 7328,
  //                 trim_name: "pDrive",
  //                 trim_description: "Test Trim",
  //                 trim_code: "00089",
  //                 trim_image: [
  //                     {
  //                         location: "https://enduserbucket.s3.amazonaws.com/bcbc5fa6cf45wheelCover.jpg",
  //                         key: "bcbc5fa6cf45wheelCover.jpg",
  //                         presigned_url: "https://enduserbucket.s3.amazonaws.com/bcbc5fa6cf45wheelCover.jpg?AWSAccessKeyId=AKIA3C6BGWJIOQWEUIVN&Signature=t7uvKSlWSyJRsQlkwc8YQcqm240%3D&Expires=1714734171",
  //                         size: 9857,
  //                         name: "a6cf45wheelCover.jpg"
  //                     }
  //                 ],
  //                 model_id: 7593
  //             }
  //         ]
  //     }
  // ]

  const [_, _setSelectedObjectsArray] = useState([])

  // const [openTradeInDialog, setTradeInOpenDialog] = React.useState(false)
  // const [_, setTradeInObj] = useState<any>()

  // const handleTradeInOpen = () => {
  //   setTradeInOpenDialog(true)
  // }

  // const handleTradeInClose = () => {
  //   setTradeInOpenDialog(false)
  // }

  // const selectTradeInObj = (tradeInData: any) => {
  //   setTradeInObj(tradeInData)
  // }

  const [openDealerFeesDialog, setOpenDealerFeesDialog] = useState(false)

  // const [tradeInObj, setTradeInObj] = useState<any>({
  //   year: "2021",
  //   make: "Make",
  //   model: "model",
  //   trim: "trim",
  //   mileage: "20",
  //   vin: "VIN34343434343434",
  //   condition: "Fair",
  //   trade_in_amount: "67500.00",
  //   is_customer_titled: "true",
  //   loan_lease_balance: "true",
  //   asset_image: [],
  //   lender_contract_number: "2323232",
  //   lender_name: "Danish Irfan",
  //   lender_address: "2323232",
  //   lender_contact_number: "3151515553",
  //   payment_type: "Lease",
  //   payoff_amount: "20.00"
  // })
  // const [tradeInObj, setTradeInObj] = useState<any>({
  //   year: "2021",
  //   make: "Make",
  //   model: "model",
  //   trim: "trim",
  //   mileage: "20",
  //   vin: "VIN34343434343434",
  //   condition: "Fair",
  //   trade_in_amount: "67500.00",
  //   is_customer_titled: "true",
  //   loan_lease_balance: "true",
  //   asset_image: [],
  //   lender_contract_number: "2323232",
  //   lender_name: "Danish Irfan",
  //   lender_address: "2323232",
  //   lender_contact_number: "3151515553",
  //   payment_type: "Lease",
  //   payoff_amount: "20.00"
  // })

  const [dealerFees, setDealerFees] = useState({
    lease: [
      {
        id: 6535,
        fee_name: 'Approval Fee',
        default_amount: 300,
        taxable: false,
        fee_handling: 'Financed',
        is_deleted: false,
        states: [2, 5],
        is_active: false,
        dealer_id: 2703,
        applicable_finance_type: ['Lease'],
        vendor: null,
        external_code: null,
        event: 'Inception',
        calculation_type: 'Fixed amount',
        multiplication_factor: null,
        dealer_code: '00001'
      },
      {
        id: 6537,
        fee_name: 'Documentation Fee',
        default_amount: 500,
        taxable: false,
        fee_handling: 'Upfront',
        is_deleted: false,
        states: [5],
        is_active: false,
        dealer_id: 2703,
        applicable_finance_type: ['Lease', 'Finance'],
        vendor: null,
        external_code: null,
        event: 'Inception',
        calculation_type: 'Fixed amount',
        multiplication_factor: null,
        dealer_code: '00001'
      },
      {
        id: 6538,
        fee_name: 'Processing Fee',
        default_amount: 200,
        taxable: false,
        fee_handling: 'Upfront',
        is_deleted: false,
        states: [5],
        is_active: false,
        dealer_id: 2703,
        applicable_finance_type: ['Lease'],
        vendor: null,
        external_code: null,
        event: 'Inception',
        calculation_type: 'Fixed amount',
        multiplication_factor: null,
        dealer_code: '00001'
      },
      {
        id: 7030,
        fee_name: 'License Fee',
        default_amount: 10,
        taxable: true,
        fee_handling: 'Financed',
        is_deleted: false,
        states: [1, 5],
        is_active: true,
        dealer_id: 2703,
        applicable_finance_type: ['Lease', 'Finance'],
        vendor: null,
        external_code: null,
        event: 'Inception',
        calculation_type: 'Fixed amount',
        multiplication_factor: null,
        dealer_code: '00001'
      }
    ],
    finance: [
      {
        id: 6536,
        fee_name: 'Evaluation Fee',
        default_amount: 150,
        taxable: true,
        fee_handling: 'Financed',
        is_deleted: false,
        states: [5],
        is_active: true,
        dealer_id: 2703,
        applicable_finance_type: ['Finance'],
        vendor: null,
        external_code: null,
        event: 'Inception',
        calculation_type: 'Fixed amount',
        multiplication_factor: null,
        dealer_code: '00001'
      },
      {
        id: 6537,
        fee_name: 'Documentation Fee',
        default_amount: 500,
        taxable: false,
        fee_handling: 'Upfront',
        is_deleted: false,
        states: [5],
        is_active: false,
        dealer_id: 2703,
        applicable_finance_type: ['Lease', 'Finance'],
        vendor: null,
        external_code: null,
        event: 'Inception',
        calculation_type: 'Fixed amount',
        multiplication_factor: null,
        dealer_code: '00001'
      },
      {
        id: 7030,
        fee_name: 'License Fee',
        default_amount: 10,
        taxable: true,
        fee_handling: 'Financed',
        is_deleted: false,
        states: [1, 5],
        is_active: true,
        dealer_id: 2703,
        applicable_finance_type: ['Lease', 'Finance'],
        vendor: null,
        external_code: null,
        event: 'Inception',
        calculation_type: 'Fixed amount',
        multiplication_factor: null,
        dealer_code: '00001'
      }
    ]
  })
  // const [insuranceFormData, setInsuranceFormData] = useState<InsuranceFormData>({
  //   insurer_name: '',
  //   agent_name: '',
  //   policy_number: '',
  //   expiration_date: '',
  //   insurance_documents: [],
  //   insurer_address: {
  //     address_line_1: '',
  //     city: '',
  //     state_name: '',
  //     zip_code: ''
  //   }
  // })

  const handleDealerFeesOpen = () => {
    setOpenDealerFeesDialog(true)
  }

  const handleDealerFeesClose = () => {
    setOpenDealerFeesDialog(false)
  }

  return (
    <LayoutWithSideNav theme={theme}>
      <DashboardStyle theme={theme} className="content-wrap">
        {/* <Grid theme={theme} item xs={4}>
          <h1>Trade-In Control</h1>
          <TradeInControl
            tradeInObj={tradeInObj}
            setTradeInObj={setTradeInObj}
          />
        </Grid> */}

        {/* <Grid theme={theme} item xs={4}>
          <h1>Insurance Popup</h1>
          <InsuranceControl
            insuranceFormData={insuranceFormData}
            setInsuranceFormData={setInsuranceFormData}
          />
        </Grid> */}
        <Grid theme={theme} item xs={4}>
          <Button
            theme={theme}
            fullWidth
            startIcon={<Icon name="AddCircleIcon" />}
            text="Edit Dealer Fees"
            size="large"
            onClick={handleDealerFeesOpen}
          />
          <Dialog
            variant={undefined}
            size="sm"
            title="Fees"
            open={openDealerFeesDialog}
            onCloseDialog={handleDealerFeesClose}
            theme={theme}
            noFooter={true}
            children={
              <>
                <EditFees
                  dealerFees={dealerFees}
                  setDealerFees={() => {
                    setDealerFees
                  }}
                  defaultCurrency={'$'}
                  financeType={'finance'}
                />
              </>
            }
          />
        </Grid>
        {/* <Grid theme={theme} item xs={4}>
          <Button
            theme={theme}
            fullWidth
            startIcon={<Icon name="AddCircleIcon" />}
            text="Edit Dealer Fees"
            size="large"
            onClick={handleDealerFeesOpen}
          />
          <Dialog
            variant={undefined}
            size="sm"
            title="Fees"
            open={openDealerFeesDialog}
            onCloseDialog={handleDealerFeesClose}
            theme={theme}
            noFooter={true}
            children={
              <>
                <EditFees
                  dealerFees={dealerFees}
                  setDealerFees={setDealerFees}
                  financeType={'finance'}
                />
              </>
            }
          />
        </Grid> */}
      </DashboardStyle>
    </LayoutWithSideNav>
  )
}

export default TestingPage
