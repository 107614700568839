import { Review } from "@src/components/organisms";
import { BaseLayout } from "@src/layouts";

export const ReviewPage = () => {
  return (
    <BaseLayout>
      <Review />
    </BaseLayout>
  );
};
