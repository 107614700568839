import { FC, useEffect, useState } from "react";
import { useTheme, TablePagination } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Box, Button, Icon, Menu, Input, DataTable } from "@ntpkunity/controls";
import {
  ConfirmationDialog,
  CustomSwitch,
  PaginationWrap,
} from "@sharedComponents";
import { useStoreContext } from "Stores/SetupStore/Store";
import {
  useUpdateDealerAppointment,
  useMutation_DeleteDealerAppointment,
  UseQuery_getAllDealerAppointments_filter,
} from "services/dealer-appointment.service";
import { IDealerAppointment, IDealerAppointmentFilter } from "Interface";
import { ActionOptions, DialogMessages } from "Enums";
import ChevronDown from "../../../shared/assets/images/chevron-down";

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

const DealerAppointmentTable: FC<{
  setPopUpState?: any;
  setActionData?: any;
  setActionType?: any;
  setActionTitle?: any;
  actionData?: any;
  dealer_code?: any;
}> = ({
  setPopUpState,
  setActionData,
  setActionType,
  setActionTitle,
  actionData,
  dealer_code,
}) => {
  const { states, actions } = useStoreContext();

  const theme = useTheme();
  const { mutate: updateDealerAppointment } = useUpdateDealerAppointment();
  const { mutate: deleteDealerAppointment } =
    useMutation_DeleteDealerAppointment();
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const [appointment_type, setAppointmentType] = useState("");
  const [time_slot_interval, setTimeSlotInterval] = useState(undefined);
  const [cushion_time, setCushionTime] = useState(undefined);
  const [in_transit_cushion, setInTransitCushion] = useState(undefined);
  const [statusFilter, setStatusFilter] = useState("");

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `dealer_code=${dealer_code}&page_number=${pageNumber}&page_size=${pageSize}`
  );
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `dealer_code=${dealer_code}&page_number=${pgNo}&page_size=${pgSize}`;
    if (
      appointment_type?.trimStart() != null &&
      appointment_type?.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&appointment_type=${appointment_type}`
      );
    }
    if (
      time_slot_interval?.trimStart() != null &&
      time_slot_interval?.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&time_slot_interval=${time_slot_interval}`
      );
    }
    if (cushion_time?.trimStart() != null && cushion_time?.trimStart() != "") {
      query_string = query_string.concat(`&cushion_time=${cushion_time}`);
    }
    if (
      in_transit_cushion?.trimStart() != null &&
      in_transit_cushion?.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&in_transit_cushion=${in_transit_cushion}`
      );
    }
    if (statusFilter?.trimStart() != null && statusFilter?.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };
  const { data } = UseQuery_getAllDealerAppointments_filter(columnFilters);
  const dealerAppointmentFilter: IDealerAppointmentFilter = data;
  useEffect(() => {
    if (dealer_code) {
      setColumnFilters(
        `dealer_code=${dealer_code}&page_number=${pageNumber}&page_size=${pageSize}`
      );
    }
  }, [dealer_code]);
  const handleEditRebate = (item: any) => {
    setActionData(item);
    setPopUpState(true);
    setActionTitle("Edit Appointment Type");
    setActionType(ActionOptions.EDIT);
  };

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        handleEditRebate(value);
        return;
      case ActionOptions.DELETE:
        setActionData(value);
        setConfirmationPopupState(true);
        return;
      default:
        return;
    }
  };

  const onDeleteConfirm = (e: any) => {
    deleteDealerAppointment(actionData?.id, {
      onSuccess: (data, variables, context) => {
        setActionData(undefined);
      },
    });
  };
  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  return (
    <>
      <DataTable
        theme={theme}
        variant="basic"
        sx={{ mb: 3 }}
        theadChildren={
          <>
            <TableRow>
              <TableCell id="providerHeader">Appointment Type</TableCell>
              <TableCell id="providerHeader">Time Slot Interval</TableCell>
              <TableCell id="productTypeHeader">Cushion Time</TableCell>
              <TableCell id="productNameHeader">In-Transit Cushion</TableCell>
              <TableCell id="statusHeader">Status</TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setAppointmentType(value);
                    }}
                    value={appointment_type?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"appointmentType"}
                  />
                  <Button
                    defaultBtn
                    id="appointmentTypeFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="number"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"timeSlotInterval"}
                    onChange={(value) => {
                      setTimeSlotInterval(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={time_slot_interval?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    id="timeSlotIntervalFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="number"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"timeSlotInterval"}
                    onChange={(value) => {
                      setCushionTime(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={cushion_time?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    id="providerTypeFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="number"
                    onChange={(value) => {
                      setInTransitCushion(value);
                    }}
                    value={in_transit_cushion?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                  />
                  <Button
                    defaultBtn
                    id="inTransationCushionSearch"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setStatusFilter(value);
                    }}
                    value={statusFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"numberSearchId"}
                  />
                  <Button
                    defaultBtn
                    id="numberFilter"
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {dealerAppointmentFilter?.result &&
              dealerAppointmentFilter.result.map(
                (data: IDealerAppointment, index: number) => (
                  <TableRow className="child-tr" key={index}>
                    <TableCell id={"apppointmentType" + data.appointment_type}>
                      {data.appointment_type}
                    </TableCell>
                    <TableCell
                      id={"TimeSlotInterval" + data.time_slot_interval}
                    >
                      {data.time_slot_interval &&
                        data.time_slot_interval + " min"}
                    </TableCell>
                    <TableCell id={"CushionTime" + data.cushion_time}>
                      {data.appointment_type === "Pickup" &&
                      data.cushion_time != null
                        ? data.cushion_time + " days"
                        : "-"}
                    </TableCell>
                    <TableCell
                      id={"InTransationDays" + data.in_transit_cushion_days}
                    >
                      {data.appointment_type === "Pickup" &&
                      data.in_transit_cushion_days != null
                        ? data.in_transit_cushion_days + " days"
                        : "-"}
                    </TableCell>
                    <TableCell>
                      <CustomSwitch
                        theme={theme}
                        varient={"basic"}
                        label={data.is_active ? "Enabled" : "Disabled"}
                        switchEnabled={data.is_active}
                        onChange={(event) => {
                          updateDealerAppointment({
                            ...data,
                            is_active: event.target.checked,
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell className="action-cell fixed-cell">
                      <Menu
                        theme={theme}
                        options={tableOptions.map((option: any) => {
                          return {
                            optionText: option.optionText,
                            optionkey: option.optionkey,
                            optionValue: data,
                          };
                        })}
                        handleOptionClick={handleSelection}
                        render={(onMenuSelection) => (
                          <Button
                            defaultBtn
                            iconText={<Icon name="MoreIcon" />}
                            onClick={onMenuSelection}
                          ></Button>
                        )}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )
              )}
          </>
        }
      />
      <PaginationWrap>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={
            dealerAppointmentFilter
              ? dealerAppointmentFilter?.total_results
              : -1
          }
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
            MenuProps: { disablePortal: true },
          }}
        />
      </PaginationWrap>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};
export default DealerAppointmentTable;
