import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const StackWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.stack-wrap': {
    backgroundColor: theme.palette.common.white,
    position: 'relative',
    border: '1px solid #CCCCCC',
    height: '100%',
    borderRadius: 8,
    '&.with-action-only': {
      position: 'relative',
      '.action-area': {
        opacity: 0,
        transition: 'all 0.3s ease-in-out 0s',
        position: 'absolute',
        right: 8,
        top: 8,
        '.stack-wrap': {
          gap: 4
        }
      },
      '&:hover': {
        '.action-area': {
          opacity: 1
        }
      }
    },
    '&.bg-light': {
      backgroundColor: '#F7F7F7'
    },
    '&.bg-dark': {
      backgroundColor: theme.palette.grey[900]
    },
    '&.has-shadow': {
      boxShadow: '0px 16px 16px -8px rgba(0, 0, 0, 0.24)'
    },
    '&.d-flex': {
      display: 'flex'
    },
    '&.d-none': {
      display: 'none'
    },
    '&.justify-content-between': {
      justifyContent: 'space-between'
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.stack-header': {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      '.title': {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        flexGrow: 1
      },
      '.action-area': {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        flexShrink: 0,
        '.btn': {
          '&.with-icon': {
            '&.icon-only': {
              padding: 0
            }
          }
        },
        '.cp': {
          cursor: 'pointer'
        }
      }
    },
    '.loader-wrap': {
      minHeight: 150
    }
  }
}))
