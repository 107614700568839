import { createBrowserRouter } from "react-router-dom";
import { WorkQueuePage, DashboardPage } from "@src/pages";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <div>404 Page not found.</div>,
  },
  {
    path: "/cde",
    element: <WorkQueuePage />,
    errorElement: <div>404 Page not found.</div>,
  },
  {
    path: "/cde/dashboard/:reference_number",
    element: <DashboardPage />,
  },
]);

export default router;
