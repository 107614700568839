import clsx from "clsx";
import { ApplicationDetailsProps } from "./application-details.props";
import "./application-details.scss";
import { TagLabel } from "@src/components/atoms";

export const ApplicationDetails = ({
  title,
  tagLabel,
  tagLabelType,
  innerHeading,
  innerDescription,
}: ApplicationDetailsProps) => {
  return (
    <div
      className={clsx({
        "application-details-wrapper": true,
      })}
    >
      <div className="application-details-wrapper--header">
        <h5 className="text--subsection-2">{title}</h5>
        <TagLabel text={tagLabel} type={tagLabelType} />
      </div>
      <div className="application-details-wrapper--body">
        <p className="text--disclaimer">{innerHeading}</p>
        <p className="text--body-2">{innerDescription}</p>
      </div>
    </div>
  );
};
