import { TableCell, TableRow, ToggleButton, useTheme } from '@mui/material'
import { LeadsTableWrap } from './leads-table.style'
import { Box, Button, DataTable, Icon, Input, Menu } from '@ntpkunity/controls'
import { ToggleCollapse } from '@styles/components'
import { DataTableWrap } from 'components/DataTableWrap'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@helpers/links'

import CarImage01 from '../../../public/assets/cars-images/CarImage01.png'
import CarImage02 from '../../../public/assets/cars-images/CarImage02.png'
import CarImage03 from '../../../public/assets/cars-images/CarImage03.png'
import CarImage04 from '../../../public/assets/cars-images/CarImage04.png'
import CarImage05 from '../../../public/assets/cars-images/CarImage05.png'

const data = [
  {
    email: 'watson@ymail.com',
    productImage: CarImage01,
    productModal: '2021 Audi A4 Sedan Premium',
    productName: '40 TFSI quattro',
    navigateTo: APP_ROUTES.LEAD_SUMMARY_STATIC_WATSON
  },
  {
    email: 'alex@hotmail.com',
    productImage: CarImage02,
    productModal: '2021 Honda Passport',
    productName: 'Touring FWD',
    navigateTo: APP_ROUTES.LEAD_SUMMARY_STATIC_ALEX
  },
  {
    email: 'ravn@gmail.com',
    productImage: CarImage03,
    productModal: '2021 Hyundai Veloster',
    productName: 'N DCT',
    navigateTo: APP_ROUTES.LEAD_SUMMARY_STATIC_RAVN
  },
  {
    email: 'hales@outlook.com',
    productImage: CarImage04,
    productModal: '2021 Mercedes-Benz',
    productName: 'C-Class AMG C 43 4MATIC Cabriolet',
    navigateTo: APP_ROUTES.LEAD_SUMMARY_STATIC_HALES
  },
  {
    email: 'shane21@yahoo.com',
    productImage: CarImage05,
    productModal: '2021 MINI Hardtop 2 Door',
    productName: 'John Cooper Works FWD',
    navigateTo: APP_ROUTES.LEAD_SUMMARY_STATIC_SHANE
  }
]

const LeadsTableRow = ({
  email,
  productImage,
  productModel,
  productName,
  navigateTo
}: {
  email: string
  productImage: string
  productModel: string
  productName: string
  navigateTo: string
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <TableRow className="child-tr">
        <TableCell className="indent-cell" colSpan={5}>
          <Box theme={theme} display={'flex'}>
            <ToggleCollapse theme={theme} className="toggle-collapse right-bottom" mr={1}>
              <ToggleButton value="check" selected={false} onChange={() => setIsOpen(!isOpen)}>
                <Icon name="ChevronDown" />
              </ToggleButton>
            </ToggleCollapse>
            <>{email}</>
          </Box>
        </TableCell>
      </TableRow>
      {isOpen && (
        <TableRow className="child-tr">
          <TableCell>{email}</TableCell>
          <TableCell>
            <Box theme={theme} className="image-cell">
              <Box theme={theme} className="product-image">
                <img src={productImage} alt="car" />
              </Box>
              <>
                {productModel} {productName}
              </>
            </Box>
          </TableCell>
          <TableCell>-</TableCell>
          <TableCell>
            {/* use above function getStatusClass() to use status colors */}
            <span className={`status blue`}></span>
            <>Active</>
          </TableCell>
          <TableCell className="action-cell fixed-cell">
            <Menu
              theme={theme}
              disablePortal
              options={[
                {
                  optionText: 'View Details',
                  optionValue: 'View Details'
                }
              ]}
              handleOptionClick={() => {
                navigate(navigateTo)
              }}
              render={(onMenuSelection: any) => (
                <Button defaultBtn iconText={<Icon name="MoreIcon" />} onClick={onMenuSelection} />
              )}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export const LeadsTable = () => {
  const theme = useTheme()

  return (
    <LeadsTableWrap theme={theme} className="leads-table-wrap">
      <DataTableWrap className="min-h">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <DataTable
          theme={theme}
          theadChildren={
            <>
              <TableRow>
                <TableCell className="indent-cell">Email Address</TableCell>
                <TableCell className="indent-cell">Year/Make/Model/Trim</TableCell>
                <TableCell>Lead State</TableCell>
                <TableCell>Lead Status</TableCell>
                <TableCell className="action-cell fixed-cell"></TableCell>
              </TableRow>
              <TableRow className="filters-row">
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      startAdornment={''}
                      endAdornment={''}
                      id={''}
                      placeholder="Search"
                    />
                    <Button defaultBtn iconText={<Icon name="IconFilter" />} />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      startAdornment={''}
                      endAdornment={''}
                      id={''}
                      placeholder="Search"
                    />
                    <Button defaultBtn iconText={<Icon name="IconFilter" />} />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      startAdornment={''}
                      endAdornment={''}
                      id={''}
                      placeholder="Search"
                    />
                    <Button defaultBtn iconText={<Icon name="IconFilter" />} />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={''}
                      startAdornment={''}
                      endAdornment={''}
                      id={''}
                      placeholder="Search"
                    />
                    <Button defaultBtn iconText={<Icon name="IconFilter" />} />
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell"></TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {data.map((item, index) => (
                <LeadsTableRow
                  key={index}
                  email={item.email}
                  productImage={item.productImage}
                  productModel={item.productModal}
                  productName={item.productName}
                  navigateTo={item.navigateTo}
                />
              ))}
            </>
          }
        />
      </DataTableWrap>
    </LeadsTableWrap>
  )
}
