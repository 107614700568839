import { styled } from "@mui/material/styles";
import zIndex from "@mui/material/styles/zIndex";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const WorkqueueFilterWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.table-widget": {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    justifyContent: "space-between",
    padding: "4px 0",
    gap: 16,
    ".multi-functions": {
      display: "flex",
      alignItems: "center",
      gap: 4,
      ".datepicker": {
        ".date-range": {
          padding: "8px 16px",
          maxWidth: 120,
          borderRadius: 8,
          "&:hover": {
            backgroundColor: CustomColors.lightGrey,
            ".rmdp-container": {
              input: {
                backgroundColor: CustomColors.lightGrey,
              },
            },
          },
          "&.focused": {
            backgroundColor: CustomColors.lightGrey,
            ".rmdp-container": {
              input: {
                backgroundColor: CustomColors.lightGrey,
                "&::placeholder":{
                  color: theme.palette.grey[900],
                  opacity: 1
                }
              },
            },
          },
          ".rmdp-container": {
            ".ep-arrow": {
              "+": {
                div: {
                  zIndex: "1201 !important",
                },
              },
            },
            input: {
              height: "auto",
              padding: 0,
              paddingLeft: 24,
              border: "none",
              cursor: 'pointer'
            },
            "&:nth-child(3)": {
              zIndex: 1201,
            }
          },
          ".calendar-icon": {
            left: 16,
            top: 8,
            path: {
              stroke: theme.palette.grey[400],
            },
          },
        },
      },
      ".sort-btn": {
        display: "flex",
        alignItems: "center",
        padding: "8px 16px",
        borderRadius: 8,
        "&:hover": {
          backgroundColor: CustomColors.lightGrey,
        },
        "&:focus": {
          backgroundColor: CustomColors.lightGrey,
        },
        ".custom-dropdown": {
          position: "relative",
          ".sort-icon": {
            position: "absolute",
            left: 4,
            width: 20,
            height: 20
          },
          ".u-form-group": {
            ".u-form-control": {
              "&.u-select": {
                fontWeight: theme.typography.body1.fontWeight,
                color: theme.palette.grey[600],
                ".MuiInputBase-input": {
                  padding: "0px 0px 0px 32px",
                },
                ".MuiSelect-icon": {
                  display: "none",
                },
              },
            },
          },
        },
      },
    },
  },
}));
