import "react";
import { useTheme } from "@mui/material";
import { ComplianceDocumentWrap } from "./compliance-document.style";
import ComplianceDocumentList from "./compliance-document-list.component";

export const ComplianceDocument = ({
  customerDetails,
  complianceDocuments,
}) => {
  const theme = useTheme();
  return (
    <ComplianceDocumentWrap theme={theme} className="compliance-document-wrap">
      <ComplianceDocumentList
        complianceDocuments={complianceDocuments}
        customerDetails={customerDetails}
      />
    </ComplianceDocumentWrap>
  );
};
