export const EmploymentType = {
  Employed: "Employed",
  Self_Employed: "Self Employed",
  Retired: "Retired",
  Not_Employed: "Not Employed",
};
export const FinanceType = {
  FINANCE: "Finance",
  LEASE: "Lease",
};
