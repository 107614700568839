import { Header, Search } from "@modules/components";
import {
  TEMPLATE_ENDPOINTS,
  useDeleteTemplate,
  useGetAllTemplates,
  useGetBase64Template,
} from "@modules/services/configureTemplateService";
import { Slide, useTheme } from "@mui/material";
import {
  Accordion,
  Box,
  Button,
  CircleLoader,
  Grid,
  Icon,
  Select,
  Snackbar,
  Switch,
  Typography,
} from "@ntpkunity/controls";
import { APP_ROUTES } from "@router/path";
import { FC, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import DockLogo from "../../../shared/assets/images/dock-logo.svg";
import { HomePageWrap } from "./templexHomePageStyle";

const TemplexHomePage: FC = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [staticTemplates, setStaticTemplates] = useState([]);
  const [tenantTemplates, setTenantTemplates] = useState([]);
  const { data } = useGetAllTemplates();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedView, setSelectedView] = useState("all");
  const [loader, setLoader] = useState([]);
  const [disabled, setDisabled] = useState([]);
  const { mutate: getBase64Template } = useGetBase64Template();
  const { mutate: deleteTemplate } = useDeleteTemplate();
  const [sortCriteria, setSortCriteria] = useState("date_descending");

  const [toastData, setToastData] = useState({
    toastLoading: false,
    toastMessage: "",
    variant: "",
  });

  const dealerCode = new URLSearchParams(location.search).get("dealer_code");
  const apiKey = new URLSearchParams(location.search).get("x-api-key");

  const appendParamsToUrl = (path: string) => {
    const url = new URL(path, window.location.href);
    if (dealerCode) {
      url.searchParams.append("dealer_code", dealerCode);
    }
    if (apiKey) {
      url.searchParams.append("x-api-key", apiKey);
    }
    return url.pathname + url.search;
  };
  
  
  
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setToastData({
      toastLoading: false,
      toastMessage: "",
      variant: "",
    });
  };

  const handleSortChange = (e) => {
    setSortCriteria(e.target.value);
  };

  const handleViewChange = (e) => {
    setSelectedView(e.target.value);
  };

  const sortedTemplates = [...staticTemplates]?.sort((a, b) => {
    if (sortCriteria === "name") {
      return a.template_name.localeCompare(b.template_name);
    } else if (sortCriteria === "date_ascending") {
      return (
        new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
      );
    }
    return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
  });

  const sortedTenantTemplates = [...tenantTemplates]?.sort((a, b) => {
    if (sortCriteria === "name") {
      return a.template_name.localeCompare(b.template_name);
    } else if (sortCriteria === "date_ascending") {
      return (
        new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
      );
    }
    return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
  });

  const handleDownloadTemplate = (e: any) => {
    const data = { template_name: e?.template_name };
    //@ts-ignore
    getBase64Template(data, {
      onSuccess: (response) => {
        const byteCharacters = atob(response);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: "application/octet-stream" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = e.template_name;
        link.click();
        URL.revokeObjectURL(link.href);
        disabled.pop();
      },
    });
  };

  const handleDeleteTemplate = (e: any) => {
    deleteTemplate(e.id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          TEMPLATE_ENDPOINTS.GET_ALL_TEMPLATES
        );
        setToastData({
          toastLoading: true,
          toastMessage: "Template deleted successfully",
          variant: "success",
        });
      },
      onError: () => {
        setToastData({
          toastLoading: true,
          toastMessage: "Error While Deleting Template",
          variant: "error",
        });
      },
    });
  };

  const handleEditTemplate = (e: any) => {
    navigate(appendParamsToUrl(APP_ROUTES.CREATE_TEMPLATE_PAGE), {
      state: { template: e, isEdit: true },
    })
  };

  const segregateTemplates = (templates) => {
    const filteredTemplates = templates.filter(
      (template) => template.template_type !== "E-Contracts"
    );
    if (dealerCode) {
      const tenantTemplatesList = filteredTemplates.filter(
        (template) => template.dealer_id === null
      );
      const staticTemplatesList = filteredTemplates.filter(
        (template) => template.dealer_id !== null
      );
      setTenantTemplates(tenantTemplatesList);
      setStaticTemplates(staticTemplatesList);
    } else {
      setStaticTemplates(filteredTemplates);
    }
  };
  
  useEffect(() => {
    setIsLoading(true);
    if (data !== undefined) {
      segregateTemplates(data);
      setIsLoading(false);
    }
  }, [data, dealerCode]);

  return (
    <>
      <HomePageWrap theme={theme} className="home-page-wrap">
        <Box theme={theme} className="fixed-header">
          <Header
            logo={DockLogo}
            onLogoClick={() => window.location.reload()}
            actionArea={
              <>
                {!dealerCode && <Button
                  theme={theme}
                  secondary
                  iconText={<Icon name="DealerConfigIcon" />}
                  onClick={() =>
                    navigate(appendParamsToUrl(APP_ROUTES.CONFIGURE_TEMPLATE_STORAGE))
                  }
                />}
                <Box
                  theme={theme}
                  className="duo-buttons"
                  display={{ xs: "none", md: "flex" }}
                  alignItems={"center"}
                  gap={2}
                >
                  <Button
                    theme={theme}
                    secondary
                    onClick={() => navigate(appendParamsToUrl(APP_ROUTES.UPLOAD_TEMPLATE))}
                    startIcon={<Icon name="UploadIcon" />}
                    text="Upload Template"
                  />
                  <Button
                    theme={theme}
                    primary
                    startIcon={<Icon name="AddIcon" />}
                    text="Create Template"
                    onClick={() =>
                      navigate(appendParamsToUrl(APP_ROUTES.CREATE_TEMPLATE_PAGE), {
                        state: { isEdit: false },
                      })
                    }
                  />
                </Box>
              </>
            }
            responsiveArea={
              <>
                <Button
                  theme={theme}
                  secondary
                  onClick={() => navigate(appendParamsToUrl(APP_ROUTES.UPLOAD_TEMPLATE))}
                  startIcon={<Icon name="UploadIcon" />}
                  text="Upload Template"
                />
                <Button
                  theme={theme}
                  primary
                  startIcon={<Icon name="AddIcon" />}
                  text="Create Template"
                  onClick={() => navigate(appendParamsToUrl(APP_ROUTES.CREATE_TEMPLATE_PAGE))}
                />
              </>
            }
          />
          <Search
            value={search}
            onChange={(event) => setSearch(event)}
            actionArea={
              <>
                <Box theme={theme} className="sort-filter">
                  {dealerCode && (
                    <Box theme={theme} className="action-item">
                      <Typography
                        theme={theme}
                        component="span"
                        variant="body2"
                        className="text-muted fw-medium"
                      >
                        View:{" "}
                      </Typography>
                      <Select
                        theme={theme}
                        disablePortal={false}
                        value={selectedView}
                        onChange={handleViewChange}
                        items={[
                          {
                            text: "All",
                            value: "all",
                          },
                          {
                            text: "Tenant Templates",
                            value: "tenant",
                          },
                          {
                            text: "My Templates",
                            value: "myTemplates",
                          },
                        ]}
                      />
                    </Box>
                  )}
                  <Box theme={theme} className="action-item">
                    <Typography
                      theme={theme}
                      component="span"
                      variant="body2"
                      className="text-muted fw-medium"
                    >
                      Sort:{" "}
                    </Typography>
                    <Select
                      theme={theme}
                      disablePortal={false}
                      value={sortCriteria}
                      onChange={handleSortChange}
                      items={[
                        {
                          text: "Name",
                          value: "name",
                        },
                        {
                          text: "Date Ascending",
                          value: "date_ascending",
                        },
                        {
                          text: "Date Descending",
                          value: "date_descending",
                        },
                      ]}
                    />
                  </Box>
                </Box>
              </>
            }
          />
        </Box>
        <Box theme={theme} className="main-content" pt={{ xs: 3, md: 5 }}>
          {isLoading ? (
            <CircleLoader theme={theme} />
          ) : (
            <Box className="templates-wrapper" theme={theme}>
              <Grid theme={theme} container spacing={3} mb={3}>
                {selectedView === "tenant"
                  ? sortedTenantTemplates
                      ?.filter((e) =>
                        e?.template_name
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      )
                      .map((e) => (
                        <Grid
                          theme={theme}
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={4}
                          key={e?.id}
                        >
                          <Box theme={theme} className="templates-card">
                            <Box
                              theme={theme}
                              className="templates-card-header"
                            >
                              <Typography
                                theme={theme}
                                component="p"
                                variant="subtitle1"
                                noWrap={true}
                                className="template-name-text"
                              >
                                {e?.template_name}
                              </Typography>
                            </Box>
                            <Typography
                              theme={theme}
                              component="span"
                              variant="body2"
                              display={"block"}
                              mt={1}
                              noWrap={true}
                              className="template-date text-muted"
                            >
                              Last Updated: {e?.updated_at}
                            </Typography>
                            <Box
                              theme={theme}
                              className="templates-card-footer"
                            >
                              <Box theme={theme} className="switch-container">
                              </Box>
                              <Box theme={theme} className="tools-container">
                                <Button
                                  theme={theme}
                                  disabled={
                                    disabled.includes(e.id) ||
                                    loader.includes(e.id)
                                  }
                                  iconText={<Icon name="DownloadIcon" />}
                                  onClick={() => {
                                    disabled.push(e.id);
                                    handleDownloadTemplate(e);
                                  }}
                                  defaultBtn
                                />
                                <Button
                                  theme={theme}
                                  disabled
                                  iconText={<Icon name="EditIcon" />}
                                  onClick={() => {}}
                                  defaultBtn
                                />
                                <Button
                                  disabled
                                  theme={theme}
                                  iconText={<Icon name="DeleteIcon" />}
                                  onClick={() => {}}
                                  defaultBtn
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      ))
                  : sortedTemplates
                      ?.filter((e) =>
                        e?.template_name
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      )
                      .map((e) => (
                        <Grid
                          theme={theme}
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={4}
                          key={e?.id}
                        >
                          <Box theme={theme} className="templates-card">
                            <Box
                              theme={theme}
                              className="templates-card-header"
                            >
                              <Typography
                                theme={theme}
                                component="p"
                                variant="subtitle1"
                                noWrap={true}
                                className="template-name-text"
                              >
                                {e?.template_name}
                              </Typography>
                            </Box>
                            <Typography
                              theme={theme}
                              component="span"
                              variant="body2"
                              display={"block"}
                              mt={1}
                              noWrap={true}
                              className="template-date text-muted"
                            >
                              Last Updated: {e?.updated_at}
                            </Typography>
                            <Box
                              theme={theme}
                              className="templates-card-footer"
                            >
                              <Box theme={theme} className="switch-container">
                              </Box>
                              <Box theme={theme} className="tools-container">
                                <Button
                                  theme={theme}
                                  disabled={
                                    disabled.includes(e.id) ||
                                    loader.includes(e.id)
                                  }
                                  iconText={<Icon name="DownloadIcon" />}
                                  onClick={() => {
                                    disabled.push(e.id);
                                    handleDownloadTemplate(e);
                                  }}
                                  defaultBtn
                                />
                                <Button
                                  theme={theme}
                                  disabled={
                                    e?.template_name.endsWith(".pdf") ||
                                    disabled.includes(e.id)
                                  }
                                  iconText={
                                    loader.includes(e.id) ? (
                                      <CircleLoader theme={theme} size="xs" />
                                    ) : (
                                      <Icon name="EditIcon" />
                                    )
                                  }
                                  onClick={() => {
                                    loader.push(e.id);
                                    disabled.push(e.id);
                                    handleEditTemplate(e);
                                  }}
                                  defaultBtn
                                />
                                <Button
                                  disabled={
                                    disabled.includes(e.id) ||
                                    loader.includes(e.id)
                                  }
                                  theme={theme}
                                  iconText={<Icon name="DeleteIcon" />}
                                  onClick={() => {
                                    disabled.push(e.id);
                                    handleDeleteTemplate(e);
                                  }}
                                  defaultBtn
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
              </Grid>
              {sortedTenantTemplates &&
                sortedTenantTemplates.length > 0 &&
                dealerCode &&
                selectedView === "all" && (
                  <Box theme={theme} className="tenant-templates">
                    <Accordion
                      theme={theme}
                      items={[
                        {
                          title: "Tenant Templates",
                          isExpanded: true,
                          content: (
                            <Grid theme={theme} container spacing={3}>
                              {sortedTenantTemplates.length > 0 ? (
                                sortedTenantTemplates
                                  .filter((e) =>
                                    e?.template_name
                                      .toLowerCase()
                                      .includes(search.toLowerCase())
                                  )
                                  .map((e) => (
                                    <Grid
                                      theme={theme}
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={4}
                                      key={e?.id}
                                    >
                                      <Box
                                        theme={theme}
                                        className="templates-card"
                                      >
                                        <Box
                                          theme={theme}
                                          className="templates-card-header"
                                        >
                                          <Typography
                                            theme={theme}
                                            component="p"
                                            variant="subtitle1"
                                            noWrap={true}
                                            className="template-name-text"
                                          >
                                            {e?.template_name}
                                          </Typography>
                                        </Box>
                                        <Typography
                                          theme={theme}
                                          component="span"
                                          variant="body2"
                                          display={"block"}
                                          mt={1}
                                          noWrap={true}
                                          className="template-date text-muted"
                                        >
                                          Last Updated: {e?.updated_at}
                                        </Typography>
                                        <Box
                                          theme={theme}
                                          className="templates-card-footer"
                                        >
                                          <Box
                                            theme={theme}
                                            className="switch-container"
                                          >
                                          </Box>
                                          <Box
                                            theme={theme}
                                            className="tools-container"
                                          >
                                            <Button
                                              theme={theme}
                                              disabled={
                                                disabled.includes(e.id) ||
                                                loader.includes(e.id)
                                              }
                                              iconText={
                                                <Icon name="DownloadIcon" />
                                              }
                                              onClick={() => {
                                                disabled.push(e.id);
                                                handleDownloadTemplate(e);
                                              }}
                                              defaultBtn
                                            />
                                            <Button
                                              theme={theme}
                                              disabled
                                              iconText={
                                                <Icon name="EditIcon" />
                                              }
                                              onClick={() => {}}
                                              defaultBtn
                                            />
                                            <Button
                                              disabled
                                              theme={theme}
                                              iconText={
                                                <Icon name="DeleteIcon" />
                                              }
                                              onClick={() => {}}
                                              defaultBtn
                                            />
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  ))
                              ) : (
                                <Box theme={theme}>
                                  <Typography component="span" theme={theme}>
                                    There is no Tenant's Template
                                  </Typography>
                                </Box>
                              )}
                            </Grid>
                          ),
                          key: "string",
                        },
                      ]}
                    />
                  </Box>
                )}
            </Box>
          )}
        </Box>
      </HomePageWrap>
      <Snackbar
        theme={theme}
        btn={<></>}
        action={"btn"}
        autoHideDuration={3000}
        onClose={handleClose}
        open={toastData?.toastLoading ? toastData?.toastLoading : false}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        variant={toastData.variant === "success" ? "success" : "error"}
        message={toastData?.toastMessage ? toastData?.toastMessage : ""}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
      />
    </>
  );
};

export default TemplexHomePage;
