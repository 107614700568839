export const QueryKeys = {
  VEHICLE: "vehicle",
  VEHICLE_DETAIL: "vehicle-detail",
  CUSTOMER_CHECK:"customer-keys",
  GET_SETUP_DATA: "get-setup-data",
  ADDON: "addon",
  ORDER_INFO:"Order-info",
  LANGUAGE:"Languages",
  FNI: "financial-insurance",
  DEALER_PROFILE: "dealer-profile",
  GET_ALL_COUTRIES: "GET_ALL_COUTRIES",
  GET_ALL_STATES: "get-all-states",
  TENANT_CONFIGURATION: "tenant-configurations",
  VEHICLE_TRIM_CODE: "vehicle-trim-code",
  CUSTOMER_EMAIL: "customer_email",
  CUSTOMER_CONTRACT: "customer_contract",
  UPDATE_CUSTOMER_CONTRACT: "update_customer_contract",
  GET_CURRENCY: "get_currency",
  GET_DEFAULT_LENDER:'default-lender-configurations',
  GET_LENDER:'lender-configurations-by-id',
  GET_TENANT_CONFIGURATION:'tenant-configurations',
  GET_COMPANY_ID: "company_id",
  GET_CUSTOMER_BY_EMAIL: "get-customer-by-email",
  GET_CHECK_DMS_CUSTOMER: "get-check-dms-customer",
  UPDATE_CHECK_DMS_CUSTOMER: "update-check-dms-customer",
  GET_DEALER_FEE_BY_STATE: "get-dealer-fee-by-state",
  GET_PERSONAL_FINANCE: "get-personal-finance",
  GET_CUSTOMER_EMPLOYMENT_DETAILS: "GET_CUSTOMER_EMPLOYMENT_DETAILS",
  GET_ORDER_INFORMATION: "get-order-information",
  STRIPE_ORDER_DETAILS: "stripe-order-details",
  STRIPE_PAYMENT_DETAILS: "stripe-payment-details",
  GET_CONFIGURATION_RADIUS: "Configuration Radius",
  GET_CONTRACT_TERMS:"Contract terms",
  GET_ALL_DEALER_DISTANCE: "get-all-dealer-distance",
  GET_LICENSE_INFO: "license-information",
  GET_CONFIGURATION_PROVIDER: "get-integration-by-provider-name",
  GET_CONFIGURATION_PROVIDER_LOGO: "get-trade-in-logo-by-provider-name",
  GET_TRADE_IN_DETAILS: "trade-in-summary-details",
  GET_VEHICLE_DATA: "get-vehicle-data",
  GET_TENEANT_CONSENT: "tenant-consent",
  GET_CUSTOMER_CONSENTS: "get-customer-consents",
  GET_DEALER_PREFERENCE: "get-dealer-preference",
  GET_PAYMENT_ALLOWED_LIMIT: "get-payment-allowed-limit",
  TRADE_IN_HISTORY: "trade-in-history",
  GET_ALL_DOCUMENT_PACKAGES: "get-all-document-packages",
  GET_CUSTOMER_SIGNATURE: "get-customer-signature",
  GENERATE_PRESIGNED_URL: "generate-pre-signed-url",
  GET_ORDER_STIPULATION_DATA: "get-order-stipulation-data",
  GET_CUSTOMER_INSURANCE_INFORMATION: "get-customer-insurance-information",
  GET_TENANT_THEME_BY_EMAIL: "get-tenant-theme-by-email",
  GET_DEALER_TRADE_IN: "get-dealer-trade-in",

};
