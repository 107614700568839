import { VerifyRadio } from "@src/components/molecules";
import "./verify-identity.scss";
import { Button } from "@src/components/atoms";
import { ResendVerification } from "../resend-verification/resend-verification";
import { VerificationInProgress } from "../verification-in-progress/verification-in-progress";
import { VerificationFailed } from "../verification-failed/verification-failed";

export const VerifyIdentity = ({
  stepFourDataReceivedEvent,
}: {
  stepFourDataReceivedEvent: () => void;
}) => {
  return (
    <>
      <div className="preferred-method-container">
        <div className="preferred-method-container__content">
          <h2 className="section-header upper-label">
            <span className="section-header text--subsection-2 number">04</span>
            <span className="section-header text--subsection-2 text">
              Identity verification.
            </span>
          </h2>
          <p className="text--body-2">
            In order to continue with the credit application, we need to obtain
            your identification document and selfie photos. BMW and our service
            providers will use this information to verify your identity and
            satisfy other legal requirements.
          </p>
          <div className="sub-text image-disclaimer text--disclaimer text-secondary">
            Please note that your selfie image will not be stored.
          </div>

          <div className="verification-wrapper-web">
            <div className="list-wrapper">
              <p className="text--body-2">You'll need:</p>
              <ul className="list-wrapper list text--body-2">
                <li>A device with a camera (such as a phone or tablet)</li>
                <li>Your driver's license</li>
              </ul>
            </div>
            <VerifyRadio
              stepFourDataReceivedEvent={stepFourDataReceivedEvent}
            />
          </div>

          {/* mobile tablets view */}
          <div className="verification-wrapper-mobile">
            <div className="list-wrapper">
              <p className="text--body-2">You'll need:</p>
              <ul className="list-wrapper list text--body-2">
                <li>Your driver's license</li>
              </ul>
            </div>
            <div className="verification-wrapper--button-wrapper">
              <Button
                type="default"
                htmlType={"button"}
                text="Start verification"
                fullWidth
              />
            </div>
          </div>
        </div>
      </div>
      {/* <ResendVerification />
      <VerificationInProgress />
      <VerificationFailed />  */}
    </>
  );
};
