import { useTheme } from "@mui/material";
import {
    Box,
    Grid,
    Typography,
    Icon,
    Skeleton,
    Button,
    Dialog,
} from "@ntpkunity/controls";
import { TabWrap } from "@modules/customerDetails/pages/customerDetails/customerDetailsStyle";
import { Fragment, useEffect, useState, FC } from "react";
import {
    useGetCustomerLicenseInfo,
    useDocumentURLByArtifactId,
    //useDocumentDownloadByArtifactId,
} from "common/hooks/useCustomerData";
import { useParams } from "react-router-dom";
import { formatedDate } from "../../../../utilities/methods";
import noPreviewimImage from "../../../../shared/assets/images/no-preview-img.svg";
import DisableLoader from "@shared/assets/images/loader-disabled.gif";

export const CustomerLicenseDetails: FC = () => {
    const theme = useTheme();
    const params = useParams();
    const reference_id = params?.reference_id;
    const [getCustomerLicenseInfo, setCustomerLicenseInfo] = useState(null);
    const [dialogOpened, setDialogOpened] = useState<boolean>(false);
    const [getValue, setvalue] = useState("");
    const [selectedImageArtifactId, setSelectedImageArtifactId] = useState("");
    const [licenseImageType, setLicenseImageType] = useState("");
    const [selfieImage, setSelfieImage] = useState(undefined);
    const [backImage, setBackImage] = useState(undefined);
    const [frontImage, setFrontImage] = useState(undefined);
    const [rotateImage, setRotateImage] = useState(0);
    const [toggleClass, settoggleClass] = useState(false);
    const [isImageDownloading, setIsImageDownloading] = useState(false);
    const { data: licenseResponse, isLoading } =
        useGetCustomerLicenseInfo(reference_id);
    const { mutate: documentURL, isLoading: isLoadingDocumentUrl } =
        useDocumentURLByArtifactId();
    // const { mutate: documentDownload } =
    //     useDocumentDownloadByArtifactId();

    useEffect(() => {
        setCustomerLicenseInfo(licenseResponse?.data);
    }, [licenseResponse]);

    useEffect(() => {
        const licenseInfo = [getCustomerLicenseInfo];
        if (getCustomerLicenseInfo != undefined) {
            licenseInfo?.map(async (element) => {
                setFrontImage(element?.front_image);
                setBackImage(element?.back_image);
                setSelfieImage(
                    element?.selfie_image)
                
            });
        }
    }, [getCustomerLicenseInfo]);

    const documentURLByArtifactId = async (artifactId) => {
        return new Promise((resolve, reject) => {
            documentURL(
                { key: artifactId },
                {
                    onSuccess: ({ data }) => {
                        resolve(data?.document_url);
                    },
                    onError: (error) => {
                        reject(error);
                    },
                }
            );
        });
    };

    const HANDLE_LICENCE_IMAGE_DOWNLOAD = async () => {
        setIsImageDownloading(true);
        downloadImage(await documentURLByArtifactId(selectedImageArtifactId), `${licenseImageType}.png`)
    }
    const downloadImage = async (signedUrl, filename) => {
        // Fetch the image data
        const response = await fetch(signedUrl);
        const blob = await response.blob();

        // Create a temporary anchor element and initiate download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setIsImageDownloading(false);
    }

    return (
        <>
            <TabWrap className="tab-wrap">
                <Grid
                    theme={theme}
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ mb: 3.5 }}
                >
                    <Grid item xs={12} sm={6} md={getCustomerLicenseInfo?.is_pdf_417 ? 7 : 4} lg={getCustomerLicenseInfo?.is_pdf_417 ? 7 : 4} theme={theme}>
                        <Box theme={theme} className="image-card">
                            <Box
                                className={
                                    getCustomerLicenseInfo?.front_image
                                        ? "image-box image-show"
                                        : "image-box image-hide"
                                }
                                theme={theme}
                            >
                                {isLoadingDocumentUrl ? (
                                    <Skeleton
                                        width={"100%"}
                                        height={274}
                                        theme={theme}
                                    ></Skeleton>
                                ) : (
                                    <Fragment>
                                        {frontImage ? (
                                            <img
                                                onClick={() => {
                                                    setvalue(frontImage);
                                                    setLicenseImageType('Front');
                                                    setSelectedImageArtifactId(getCustomerLicenseInfo?.front_image);
                                                    setDialogOpened(true);
                                                    setRotateImage(0)
                                                }}
                                                className="image-response"
                                                src={frontImage ?? frontImage}
                                                alt=""
                                            />
                                        ) : (
                                            <Icon name="DoubleImageIcon" />
                                        )}
                                    </Fragment>
                                )}
                            </Box>
                            {isLoading ? (
                                <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                            ) : (
                                <Typography
                                    variant="body2"
                                    component="p"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    Driver's License Front
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    {!getCustomerLicenseInfo?.is_pdf_417 &&
                        <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                            <Box theme={theme} className="image-card">
                                <Box
                                    className={
                                        getCustomerLicenseInfo?.back_image
                                            ? "image-box image-show"
                                            : "image-box image-hide"
                                    }
                                    theme={theme}
                                >
                                    {isLoadingDocumentUrl ? (
                                        <Skeleton
                                            width={"100%"}
                                            height={274}
                                            theme={theme}
                                        ></Skeleton>
                                    ) : (
                                        <Fragment>
                                            {backImage &&
                                                getCustomerLicenseInfo?.is_pdf_417 === false ? (
                                                <img
                                                    onClick={() => {
                                                        setvalue(backImage);
                                                        setLicenseImageType('Back');
                                                        setSelectedImageArtifactId(getCustomerLicenseInfo?.back_image);
                                                        setDialogOpened(true);
                                                        setRotateImage(0)
                                                    }}
                                                    className="image-response"
                                                    src={backImage ?? backImage}
                                                    alt=""
                                                />
                                            ) : getCustomerLicenseInfo?.is_pdf_417 === true ? (
                                                <img src={noPreviewimImage} />
                                            ) : (
                                                <Icon name="DoubleImageIcon" />
                                            )}
                                        </Fragment>
                                    )}
                                </Box>
                                {isLoading ? (
                                    <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                                ) : (
                                    <Typography
                                        variant="body2"
                                        component="p"
                                        rowGap={5}
                                        theme={theme}
                                    >
                                        Driver's License Back
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                    }
                    <Grid item xs={12} sm={6} md={getCustomerLicenseInfo?.is_pdf_417 ? 5 : 4} lg={getCustomerLicenseInfo?.is_pdf_417 ? 5 : 4} theme={theme}>
                        <Box theme={theme} className="image-card">
                            <Box
                                className={
                                    getCustomerLicenseInfo?.selfie_image
                                        ? "image-box image-show"
                                        : "image-box image-hide"
                                }
                                theme={theme}
                            >
                                {isLoadingDocumentUrl ? (
                                    <Skeleton
                                        width={"100%"}
                                        height={274}
                                        theme={theme}
                                    ></Skeleton>
                                ) : (
                                    <Fragment>
                                        {selfieImage ? (
                                            <img
                                                onClick={() => {
                                                    setvalue(selfieImage ?? selfieImage);
                                                    setLicenseImageType('Selfie');
                                                    setSelectedImageArtifactId(getCustomerLicenseInfo?.selfie_image);
                                                    setDialogOpened(true);
                                                    setRotateImage(0)
                                                }}
                                                className="image-response"
                                                src={selfieImage ?? selfieImage}
                                                alt=""
                                            />
                                        ) : (
                                            <Icon name="DoubleImageIcon" />
                                        )}
                                    </Fragment>
                                )}
                            </Box>
                            {isLoading ? (
                                <Skeleton height={20} theme={theme} sx={{ mt: 2 }}></Skeleton>
                            ) : (
                                <Typography
                                    variant="body2"
                                    component="p"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    Selfie Image
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                </Grid>
                <Box theme={theme} sx={{ mb: 4, mt: 4 }}>
                    <hr />
                </Box>
                <Grid
                    theme={theme}
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ mb: 3.5 }}
                >
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label disabled" theme={theme}>
                            {isLoading ? (
                                <Skeleton width={60} height={20} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="caption"
                                    component="span"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    First Name:
                                </Typography>
                            )}
                            {isLoading ? (
                                <Skeleton width={60} height={25} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="body2"
                                    component="h4"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    {getCustomerLicenseInfo?.first_name === undefined
                                        ? "-" : getCustomerLicenseInfo?.first_name}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label disabled" theme={theme}>
                            {isLoading ? (
                                <Skeleton width={60} height={20} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="caption"
                                    component="span"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    Last Name:
                                </Typography>
                            )}
                            {isLoading ? (
                                <Skeleton width={60} height={25} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="body2"
                                    component="h4"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    {getCustomerLicenseInfo?.last_name === undefined
                                        ? "-" :
                                        getCustomerLicenseInfo?.last_name}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label disabled" theme={theme}>
                            {isLoading ? (
                                <Skeleton width={60} height={20} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="caption"
                                    component="span"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    License Number:
                                </Typography>
                            )}
                            {isLoading ? (
                                <Skeleton width={60} height={25} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="body2"
                                    component="h4"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    {getCustomerLicenseInfo?.license_number === undefined
                                        ? "-" : getCustomerLicenseInfo?.license_number}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label disabled" theme={theme}>
                            {isLoading ? (
                                <Skeleton width={60} height={20} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="caption"
                                    component="span"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    License Issuing Date
                                </Typography>
                            )}
                            {isLoading ? (
                                <Skeleton width={60} height={25} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="body2"
                                    component="h4"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    {getCustomerLicenseInfo?.issue_date === undefined
                                        ? "-"
                                        : formatedDate(
                                            getCustomerLicenseInfo?.issue_date,
                                            "MM/DD/YYYY"
                                        )}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label disabled" theme={theme}>
                            {isLoading ? (
                                <Skeleton width={60} height={20} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="caption"
                                    component="span"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    Expiration:
                                </Typography>
                            )}
                            {isLoading ? (
                                <Skeleton width={60} height={25} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="body2"
                                    component="h4"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    {getCustomerLicenseInfo?.expiry_date === undefined
                                        ? "-"
                                        : formatedDate(
                                            getCustomerLicenseInfo?.expiry_date,
                                            "MM/DD/YYYY"
                                        )}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label disabled" theme={theme}>
                            {isLoading ? (
                                <Skeleton width={60} height={20} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="caption"
                                    component="span"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    Date of Birth:
                                </Typography>
                            )}
                            {isLoading ? (
                                <Skeleton width={60} height={25} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="body2"
                                    component="h4"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    {getCustomerLicenseInfo?.date_of_birth === undefined
                                        ? "-"
                                        : formatedDate(
                                            getCustomerLicenseInfo?.date_of_birth,
                                            "MM/DD/YYYY"
                                        )}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label disabled" theme={theme}>
                            {isLoading ? (
                                <Skeleton width={60} height={20} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="caption"
                                    component="span"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    Verification Status:
                                </Typography>
                            )}
                            {isLoading ? (
                                <Skeleton width={60} height={25} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="body2"
                                    component="h4"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    {getCustomerLicenseInfo?.status === undefined
                                        ? "-" : getCustomerLicenseInfo?.status}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label disabled" theme={theme}>
                            {isLoading ? (
                                <Skeleton width={60} height={20} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="caption"
                                    component="span"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    City:
                                </Typography>
                            )}
                            {isLoading ? (
                                <Skeleton width={60} height={25} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="body2"
                                    component="h4"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    {getCustomerLicenseInfo?.address?.city === undefined
                                        ? "-" : getCustomerLicenseInfo?.address?.city}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label disabled" theme={theme}>
                            {isLoading ? (
                                <Skeleton width={60} height={20} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="caption"
                                    component="span"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    State:
                                </Typography>
                            )}
                            {isLoading ? (
                                <Skeleton width={60} height={25} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="body2"
                                    component="h4"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    {getCustomerLicenseInfo?.address?.state_name === undefined
                                        ? "-" : getCustomerLicenseInfo?.address?.state_name}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label disabled" theme={theme}>
                            {isLoading ? (
                                <Skeleton width={60} height={20} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="caption"
                                    component="span"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    Zip Code:
                                </Typography>
                            )}
                            {isLoading ? (
                                <Skeleton width={60} height={25} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="body2"
                                    component="h4"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    {getCustomerLicenseInfo?.address?.zip_code === undefined
                                        ? "-" : getCustomerLicenseInfo?.address?.zip_code}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label disabled" theme={theme}>
                            {isLoading ? (
                                <Skeleton width={60} height={20} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="caption"
                                    component="span"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    Address line 1:
                                </Typography>
                            )}
                            {isLoading ? (
                                <Skeleton width={60} height={25} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="body2"
                                    component="h4"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    {getCustomerLicenseInfo?.address?.address_line_1 === undefined
                                        ? "-" : getCustomerLicenseInfo?.address?.address_line_1}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label disabled" theme={theme}>
                            {isLoading ? (
                                <Skeleton width={60} height={20} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="caption"
                                    component="span"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    Address line 2:
                                </Typography>
                            )}
                            {isLoading ? (
                                <Skeleton width={60} height={25} theme={theme}></Skeleton>
                            ) : (
                                <Typography
                                    variant="body2"
                                    component="h4"
                                    rowGap={5}
                                    theme={theme}
                                >
                                    {getCustomerLicenseInfo?.address?.address_line_2
                                        ? getCustomerLicenseInfo?.address?.address_line_2
                                        : "-"}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </TabWrap>
            <Dialog
                theme={theme}
                size="md"
                title={`Driver's License ${licenseImageType}`}
                onCloseDialog={() => { setDialogOpened(false) }}
                open={dialogOpened}
                children={
                    <>
                        <TabWrap className="license-modal">
                            <Box theme={theme} textAlign="center">
                                <Box theme={theme} className={`img-rotate ${toggleClass && "max"}`}
                                    style={{ rotate: `${rotateImage}deg` }}>
                                    <img
                                        className="image-response"
                                        src={getValue}
                                        alt=""
                                    />
                                </Box>
                            </Box>
                        </TabWrap>
                    </>
                }
                customFooter={
                    <>
                        <Grid container theme={theme} spacing={2}>
                            <Grid theme={theme} item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                                <Button 
                                    theme={theme} 
                                    secondary 
                                    text="Rotate" 
                                    className="btn-rotate"
                                    onClick={() => {
                                        setRotateImage(-90 + rotateImage)
                                        settoggleClass(!toggleClass)
                                    }}
                                    startIcon={<Icon name="ResetIcon" />}
                                />
                            </Grid>
                            <Grid theme={theme} item xs={6}>
                                <Button
                                    theme={theme}
                                    primary
                                    text="Download"
                                    onClick={HANDLE_LICENCE_IMAGE_DOWNLOAD}
                                    disabled={isImageDownloading}
                                    startIcon={<Icon name="SaveIcon" />}
                                    endIcon={
                                        (isImageDownloading) && (
                                            <img src={DisableLoader} alt="Loader" />
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                        
                    </>
                }
            />
        </>
    );
};
