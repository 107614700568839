import { useTheme } from "@mui/material"
import { Stack } from "@src/components"
import { Box, Typography, Label } from "@ntpkunity/controls"
import { SwotAnalysisWrap } from "./awot-analysis.style"
import img from "@src/assets/icons/ai-tick-arrow.svg"
import cross from "@src/assets/icons/ai-red-cross.svg"
import down from "@src/assets/icons/ai-down-arrow.svg"
import up from "@src/assets/icons/ai-up-arrow.svg"
import notVerify from "@src/assets/icons/ai-not-verify-icon.svg"
import { SwotAnalysisProp } from "./swot-analysis.props"
import clsx from 'clsx'


export const SwotAnalysis = ({items}:SwotAnalysisProp) => {  
    const theme = useTheme();

    return(
        <>
            <SwotAnalysisWrap theme={theme} className="swot-analysis-wrap">
                {items?.map((item, index) => (
                    <Stack paddingXs={1.5} paddingMd={1.5} className="cde-card" key={index}>
                        <Box theme={theme} className="card-head">
                            <Box theme={theme} display={'flex'} alignItems={'center'} gap={'8px'}>
                                {item.status === 'strengths' &&
                                    <img src={img} className="img" />
                                }
                                {item.status === 'opportunities' &&
                                    <img src={up} className="img" />
                                }
                                {item.status === 'weaknesses' &&
                                    <img src={cross} className="img" />
                                }
                                {item.status === 'threats' &&
                                    <img src={down} className="img" />
                                }
                                {item.title &&
                                    <Typography theme={theme} variant="caption" component={'small'} className="fw-medium caption-text">{item.title}</Typography>
                                }
                            </Box>
                            {item.success || item.error ? 
                                <Box theme={theme} className={clsx({'status': true, 'success': item.success, 'error': item.error})}></Box>
                                :
                                null
                            }
                            {item.notVerify &&
                                <img src={notVerify} className="img" />
                            }
                        </Box>
                        
                        <Box theme={theme} className="card-body">
                            {item.description &&
                                <Typography theme={theme} variant="caption" component={'small'} className="text-muted caption-text">{item.description}</Typography>
                            }
                            {item.recommendation &&
                                <Box theme={theme} display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                                    <Typography theme={theme} variant="caption" component={'small'} className="text-muted fw-medium">Recommendation:</Typography>
                                    {item.actionArea}
                                </Box>
                            }
                            {item.linkText &&
                                <Box theme={theme} mt={0.4} mb={0.4} width={'100%'} textAlign={'center'}>
                                    <a href="javascript:void(0)" className="text-primary text-link">{item.linkText}</a>                        
                                </Box>
                            }
                            {item.listing?.map((list, index2 ) => (
                                <Typography theme={theme} variant="caption" component={'small'} className="fw-medium label caption-text" key={index2}>{list.title}</Typography>
                            ))}
                        </Box>
                    </Stack>
                ))}
            </SwotAnalysisWrap>
        </>
    )
}