import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const CustomDialogWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.custom-dialog-wrap': {
    '&.footer-separator': {
      '.u-dialog': {
        '.u-dialog-action': {
          position: 'relative',
          ':before': {
            display: 'block',
            borderTopStyle: 'solid',
            borderTopColor: theme.palette.divider,
            width: '100%',
            height: '1px',
            position: 'absolute',
            content: "''",
            top: 0,
            borderTopWidth: '1px',
            maxWidth: 'calc(100% - 127px)',
            left: 60
          }
        }
      }
    },
    ////////////////////////////////////////
    '&.lane-dialog-wrap': {
      '.MuiDialog-root.u-dialog.MuiModal-root': {
        '.u-dialog-title': {
          position: 'absolute',
          width: '100%',
          top: -30,
          padding: 0,

          '.u-dialog-close svg path': {
            stroke: '#fff'
          }
        },
        '.MuiDialogContent-root.u-dialog-content': {
          padding: 0,
          overflow: 'initial',
          border: '0 solid transparent'
        }
      },
      '&.s-xl': {
        '.MuiDialog-root.u-dialog.u-dialog-xl': {
          '.MuiPaper-root.MuiDialog-paper': {
            overflow: 'initial'
          },
          '.MuiDialog-paper': {
            maxWidth: '100%'
          }
        }
      },
      '&.s-full-width': {
        '.wrap': {
          height: 'calc(100vh - 47px)',
          borderRadius: '24px'
        }
      },
      ////////////////////////////////////////
      '.lane-dialog-content': {
        fontFamily: theme.typography.fontFamily,
        '.top-bar': {
          textAlign: 'right',
          display: 'none',
          height: 40,
          width: '100%',
          a: {
            color: theme.palette.common.white,
            padding: 7,
            display: 'flex',
            width: 40,
            justifyContent: 'center',
            float: 'right',
            height: 40,
            textAlign: 'center',
            textDecoration: 'none',
            fontSize: 20,
            cursor: 'pointer',
            marginRight: 20
          }
        },
        '.scroll-container': {
          padding: '0 24px',
          width: '100%',
          height: 'calc(100vh - 254px)',
          display: 'inline-block',
          overflow: 'auto',
          '@supports (-moz-appearance:none)': {
            scrollbarWidth: 'thin'
          },
          '&::-webkit-scrollbar': {
            width: 7,
            height: 7
          },
          '&::-webkit-scrollbar-track': {
            padding: '0 1px',
            borderRadius: 20
          },
          '&::-webkit-scrollbar-thumb': {
            transition: 'all 0.3s',
            backgroundColor: '#cdcdcd',
            borderRadius: 20
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#a6a6a6'
          }
        },
        '&.inventory-dialog-content': {
          '.scroll-container': {
            overflow: 'hidden'
          }
        },
        '.wrap': {
          backgroundColor: theme.palette.common.white,
          display: 'inline-block',
          width: '100%',
          height: 'calc(100vh - 40px)',
          borderRadius: '24px 24px 0 0',

          '.container': {
            display: 'inline-block',
            width: '100%',
            padding: '16px 0 16px 16px',

            '&.scroll-container': {
              padding: 16,
              height: '100%'
            }
          }
        },
        '.custom-filters': {
          '.scroll': {
            height: 'calc(100vh - 82px)'
          }
        }
      }
    },
    '.custom-p': {
      'p:first-of-type': {
        marginTop: 0
      },
      'p:last-of-type': {
        marginBottom: 0
      },
      'p:nth-last-of-type(2), p:nth-last-of-type(3)': {
        color: theme.palette.grey[600]
      },
      ul: {
        paddingLeft: 24
      }
    }
  },
  '&.insurance-dialog-wrap': {
    '.u-dialog': {
      '.u-dialog-content': {
        '.insurance-autocomplete': {
          '.u-custom-autocomplete': {
            marginBottom: 0,
            '.MuiAutocomplete-root .u-form-group .MuiInputLabel-formControl.Mui-error': {
              color: theme.palette.error.main + '!important'
            }
          }
        },
        '.u-custom-file-upload': {
          marginBottom: 4
        }
      }
    }
  }
}))

export const DialogTotal: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.dialog-total': {
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    left: 0,
    bottom: 0,
    padding: '16px 0',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.palette.grey[900],
    zIndex: 100,
    borderBottom: '1px solid' + theme.palette.divider,
    '.primary': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold
    }
  }
}))
