import "./directional-button.style.css";

export const DirectionalButton = () => {
  return (
    <button
      className="style-button--directional style-button--outline-dark"
      data-icon="arrow_chevron_left"
      aria-label="Label"
    >
      Label
    </button>
  );
};
