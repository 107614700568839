import { getProviderByIntegrationType } from '@apis/integrations.service'
import { QueryKeys } from '@helpers/enums'
import { useQuery, UseQueryResult } from 'react-query'
import { IIntegrationsResponse } from 'types/responses/companyIntegrations'

export const useGetProviderByIntegrationType = (
  dealerCode: string,
  integrationType: string,
  companyId: number
): UseQueryResult<IIntegrationsResponse> => {
  return useQuery<IIntegrationsResponse>({
    queryKey: [QueryKeys.GET_PROVIDERS_BY_INTEGRATION_TYPE, dealerCode],
    queryFn: () => getProviderByIntegrationType(dealerCode, integrationType, companyId),
    enabled: !!dealerCode && !!integrationType && !!companyId,
    retry: 3
  })
}
