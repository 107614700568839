import { Accordion } from "@src/components/atoms/accordion/accordion.component";

export const TotalContributionAccordionContent = () => {
  const cashDownAccordionItems = [
    {
      key: "1",
      label: "Incentives & Rebates",
      extra: <span className="text--body-2">-$1,000</span>,
    },
    {
      key: "2",
      label: "Trade-in Equity",
      extra: <span className="text--body-2">-$500</span>,
    },
  ];

  return (
    <>
      <div className="pricing-details-accordion-content">
        <div className="pricing-details-accordion-content__box">
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Cash down
              <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
            </span>
            <span className="pricing-details__item__price text--body-2">
              -$8,500
            </span>
          </div>
          <Accordion
            items={cashDownAccordionItems}
            defaultActiveKey={0}
            ghost={false}
            size="small"
            fullWidth
          />
        </div>
      </div>
    </>
  );
};
