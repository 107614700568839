import { useEffect, useState } from 'react'
import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Grid, Box, Typography, Button, Icon, Checkbox } from '@ntpkunity/controls'
import { useForm } from 'react-hook-form'
import { useUpdateCustomerContact } from '@apis/customer-detail.service'
import { IAddress } from '@models'
import { useStoreContext } from '@store/storeContext'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import CustomerInfoComponent from './customer-info'
import AddressDetailsComponent from './address-detail'
import { AddressType } from '@helpers/enums'
import { useHasFocus } from '@hooks/useHasHook'
import { findAddress, getMonthsFromGivenDateFormat } from '@helpers/methods'
import {
  createOrderRequestPayloadFromState,
  createPricingRequestPayloadFromState
} from '@app/desking/utils'
import { useDeskingContext } from '@app/desking/desking-context-provider'
import { useOrderCalculationRequest } from '@apis/inventory-management.service'
import { useUpdateQuotationById } from '@apis/order-management.service'
import { useGetAllDealerFeebyState } from '@apis/dealer-configurations.service'
import { useQueryClient } from 'react-query'

interface IFormInputs {
  customer_profile?: {
    prefix?: string | null
    first_name?: string | null
    middle_name?: string | null
    last_name?: string | null
    suffix?: string | null
    date_of_birth: string
    ssn?: string | null
    mobile_number?: string | null
    home_number?: string | null
    email?: string | number
  }
  mail_address: IAddress | null
  garaging_address?: IAddress | null
  billing_address?: IAddress | null
  previous_address?: IAddress | null
}

function createValidationSchema(
  isGaraging: boolean,
  isBilling: boolean,
  isPreviousAddress: boolean
) {
  const schema = yup.object().shape({
    customer_profile: yup.object().shape(
      {
        first_name: yup
          .string()
          .nullable()
          .required('First Name is required')
          .max(100, 'First Name maximum length is up to 100 characters')
          .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed'),
        middle_name: yup
          .string()
          .nullable()
          .max(100, 'Middle Name maximum length is up to 100 characters')
          .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed'),
        last_name: yup
          .string()
          .nullable()
          .required('Last Name is required')
          .max(100, 'Last Name maximum length is up to 100 characters')
          .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed'),
        suffix: yup
          .string()
          .nullable()
          .max(100, 'Customer Suffix maximum length is up to 100 characters')
          .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed'),
        ssn: yup
          .string()
          .nullable()
          .required('Social Security Number is required')
          .length(9, 'Social Security Number must be exactly 9 digits')
          .matches(/^(\d{9}|\●{9})$/, 'Social Security Number must be numeric'),
        mobile_number: yup
          .string()
          .nullable()
          .required('Mobile Phone Number is required')
          .matches(
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
            'Valid Mobile Phone Number is required'
          ),
        home_number: yup
          .string()
          .nullable()
          .test('is-valid-phone', 'Valid Home Phone Number is required', (value) => {
            // Only apply the validation if there's a value
            if (value) {
              return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value)
            }
            // If there's no value, validation is not required
            return true
          })
      },
      [['ssn', 'ssn']]
    ),
    mail_address: yup.object().shape({
      address_line_1: yup.string().nullable().required('Address Line 1 is required'),
      city: yup.string().nullable().required('City is required'),
      state_name: yup.string().nullable().required('State Name is required'),
      state_id: yup.string().nullable().required('State Name is required'),
      zip_code: yup.string().nullable().required('Zip Code is required'),
      move_in_duration: yup
        .string()
        .nullable()
        .required('Move-In Date is required')
        .matches(/^(0[1-9]|1[0-2])(19|20)\d{2}$/, 'Move-In Date should be in MM/YYYY format')
    }),
    previous_address: isPreviousAddress
      ? yup.object().shape({
          address_line_1: yup.string().nullable().required('Address Line 1 is required'),
          city: yup.string().nullable().required('City is required'),
          state_name: yup.string().nullable().required('State Name is required'),
          state_id: yup.string().nullable().required('State Name is required'),
          zip_code: yup.string().nullable().required('Zip Code is required'),
          move_in_duration: yup
            .string()
            .nullable()
            .required('Move-In Date is required')
            .matches(/^(0[1-9]|1[0-2])(19|20)\d{2}$/, 'Move-In Date should be in MM/YYYY format')
        })
      : yup.object().shape({}),
    garaging_address: !isGaraging
      ? yup.object().shape({
          address_line_1: yup.string().nullable().required('Address Line 1 is required'),
          city: yup.string().nullable().required('City is required'),
          state_id: yup.string().nullable().required('State Name is required'),
          state_name: yup.string().nullable().required('State Name is required'),
          zip_code: yup.string().nullable().required('Zip Code is required')
        })
      : yup.object().shape({}),
    billing_address: !isBilling
      ? yup.object().shape({
          address_line_1: yup.string().nullable().required('Address Line 1 is required'),
          city: yup.string().nullable().required('City is required'),
          state_id: yup.string().nullable().required('State Name is required'),
          state_name: yup.string().nullable().required('State Name is required'),
          zip_code: yup.string().nullable().required('Zip Code is required')
        })
      : yup.object().shape({})
  })
  return schema
}

interface IContactDetailsComponentProps {
  setIsContact?: (value: boolean) => void
  setIsContactErrros?: (value: boolean) => void
  setRoute: (value: number) => void
  contactData?: any
  customerReferenceId?: string
  isGoogleEnabled: boolean
  setIsFocus?: (value: boolean) => void
  dealer_code?: string
}

const ContactDetailsComponent: FC<IContactDetailsComponentProps> = ({
  setIsContact,
  setIsContactErrros,
  setRoute,
  contactData,
  customerReferenceId,
  isGoogleEnabled,
  setIsFocus,
  dealer_code
}) => {
  const theme = useTheme()

  const queryClient = useQueryClient()
  const stateData: any = queryClient.getQueryData(['COMPANY_CONFIGURATIONS', 'states'])
  const [getCustomerDataByReferenceId, setCustomerDataByReferenceId] = useState<IFormInputs>()
  const [isGaraging, setIsGaraging] = useState(false)
  const [isBilling, setIsBilling] = useState(false)
  const [isPreviousAddress, setIsPreviousAddress] = useState(false)
  const validationSchema = createValidationSchema(isGaraging, isBilling, isPreviousAddress)
  const { mutateAsync: getAllDealerFee, data: allDealerFeeData } = useGetAllDealerFeebyState()

  const form = useForm<IFormInputs>({
    defaultValues: getCustomerDataByReferenceId,
    resolver: yupResolver(validationSchema),
    mode: 'onTouched'
  })

  const {
    handleSubmit,
    formState: { errors, isValid },
    reset,
    getValues,
    setValue
  } = form
  const { states, actions } = useStoreContext()
  const { state } = useDeskingContext()
  const [isVerified, setIsVerified] = useState(false)
  const [_isAddressDirty, setIsAddressDirty] = useState(false)
  const { mutate: updateCustomerByReferenceId } = useUpdateCustomerContact()
  const [isgaragingVerified, setIsGaragingVerified] = useState(false)
  const [isbillingVerified, setIsBillingVerified] = useState(false)
  const [isPreviousAddressVerified, setIsPreviousAddressVerified] = useState(false)
  const [recalculate, setRecalculate] = useState(false)
  const [updatedAddress, setUpdatedAddress] = useState<IAddress[]>()
  const focus = useHasFocus()
  const {
    mutateAsync: getOrderCalculationRequest,
    isLoading: _calcReqLoading,
    error: _calcReqError,
    data: _orderRequestData
  } = useOrderCalculationRequest()
  const {
    mutateAsync: updateOrderRequest,
    isLoading: _updateRequestLoading,
    data: _updateResponse,
    error: _updateRequestError
  } = useUpdateQuotationById()
  useEffect(() => {
    if (contactData) {
      const mailAddress = contactData?.customer_addresses?.find(
        (x: any) => x?.address_type === AddressType.MAILING
      )
      const { ssn, ...restOfContactData } = contactData || {}
      setCustomerDataByReferenceId({
        ...getCustomerDataByReferenceId,
        customer_profile: { ...restOfContactData },
        mail_address: { ...mailAddress }
      })
      setTimeout(() => {
        form.setValue('customer_profile.ssn', contactData?.ssn ? '●●●●●●●●●' : '')
      }, 500)
    }
  }, [contactData])

  useEffect(() => {
    setIsFocus?.(focus)
  }, [focus])

  useEffect(() => {
    if (getCustomerDataByReferenceId) {
      const mailAddress = contactData?.customer_addresses?.find(
        (x: any) => x?.address_type === AddressType.MAILING
      )
      const garagingAddress = contactData?.customer_addresses?.find(
        (x: any) => x?.address_type === AddressType.GARAGING
      )
      const billingAddress = contactData?.customer_addresses?.find(
        (x: any) => x?.address_type === AddressType.BILLING
      )
      const previousAddress = contactData?.customer_addresses?.find(
        (x: any) => x?.address_type === AddressType.PREVIOUS
      )
      const isGAddress =
        garagingAddress && garagingAddress.entity_type
          ? garagingAddress?.entity_type === AddressType.MAILING
          : isGaraging
      const isBAddress =
        billingAddress && billingAddress.entity_type
          ? billingAddress?.entity_type === AddressType.MAILING
          : isBilling
      reset(getCustomerDataByReferenceId)
      setValue('mail_address', mailAddress)
      setValue('mail_address.state_id', mailAddress?.state_name)
      setValue('mail_address.move_in_duration', setMoveInDate(mailAddress?.move_in_date))
      setIsGaraging(isGAddress)
      setIsBilling(isBAddress)
      if (garagingAddress && !isGAddress) {
        setValue('garaging_address', garagingAddress)
        setValue('garaging_address.state_id', garagingAddress?.state_name)
      }
      if (billingAddress && !isBAddress) {
        setValue('billing_address', billingAddress)
        setValue('billing_address.state_id', billingAddress?.state_name)
      }

      if (previousAddress) {
        setIsPreviousAddress(true)
        setValue('previous_address', previousAddress)
        setValue('previous_address.state_id', previousAddress?.state_name)
        setValue('previous_address.move_in_duration', setMoveInDate(previousAddress?.move_in_date))
      }
    }
  }, [getCustomerDataByReferenceId])

  useEffect(() => {
    if (getValues('mail_address')?.verified) {
      setIsVerified(true)
    }
  }, [getValues('mail_address')])

  useEffect(() => {
    const customerGaragingAddress = findAddress(
      recalculate ? updatedAddress : contactData?.customer_addresses
    )
    if (customerGaragingAddress) {
      if (
        customerGaragingAddress?.state_name !==
          states.dealDesk.dealerProfile.dealer_address.state_name ||
        recalculate
      ) {
        getAllDealerFee(
          {
            dealer_id: states?.dealerInfo?.dealer_code,
            state_code: `${
              stateData?.find((x: any) => x?.name === customerGaragingAddress.state_name)?.code
            }`
          },
          {
            onSuccess(res: any) {
              calculationOrder(customerGaragingAddress, res)
            }
          }
        )
      } else {
        actions.setRecalculation(false)
      }
    }
  }, [stateData, contactData, recalculate])

  useEffect(() => {
    const mailAddress = getValues('mail_address')
    if (mailAddress && mailAddress?.move_in_duration) {
      const moveInDate = mailAddress.move_in_duration
      const moveInMonths = getMonthsFromGivenDateFormat(moveInDate || '')

      if (moveInMonths < parseInt(states?.lenderInfo?.min_months_stay_at_address || '')) {
        setIsPreviousAddress(true)
      } else {
        setIsPreviousAddress(false)
      }
    }
  }, [getValues('mail_address'), states?.lenderInfo])

  const fillGargingAddress = (e: any) => {
    if (e.target.checked) {
      const mailAddress = getValues('mail_address')
      setIsGaraging(true)
      setValue('garaging_address', mailAddress)
    } else {
      reset({ ...getValues(), garaging_address: null })
      setIsGaraging(false)
    }
  }
  const fillBillingAddress = (e: any) => {
    if (e.target.checked) {
      const mailAddress = getValues('mail_address')
      setIsBilling(true)
      setValue('billing_address', mailAddress)
    } else {
      reset({ ...getValues(), billing_address: null })
      setIsBilling(false)
    }
  }

  const calculationOrder = async (data: any, fee?: any) => {
    const pricingPayload = createPricingRequestPayloadFromState(
      state,
      states?.dealDesk,
      0,
      0,
      state.vehiclesVins[0] ?? ''
    )
    const orderReqObj = createOrderRequestPayloadFromState(
      state,
      states.dealerInfo,
      state.order.lender_id
    )
    const fees_data = allDealerFeeData ? allDealerFeeData : fee
    await getOrderCalculationRequest({
      ...pricingPayload,
      fees: fees_data
        ?.filter(
          (fee: any) => fee.applicable_finance_type.includes(state.finance_type) && fee.is_active
        )
        ?.map((fee: any) => {
          return {
            fee_name: fee.fee_name,
            fee_amount: fee.default_amount,
            fee_handling: fee?.fee_handling,
            is_active: fee?.is_active,
            applicable_finance_type: fee?.applicable_finance_type,
            is_taxable: fee?.taxable
          }
        }),
      customer_address: {
        street_address: data?.address_line_1,
        city: data?.city,
        state: data?.state_name,
        zip_code: data?.zip_code
      }
    }).then((calculationResponse: any) => {
      if (recalculate) {
        setRoute(1)
      }
      if (
        state?.order?.estimated_monthly_payment !== calculationResponse?.monthly_payment ||
        state?.order?.due_at_signing !== calculationResponse?.due_at_signing
      ) {
        const reqobj = {
          ...orderReqObj,
          monthly_deprecation: calculationResponse?.monthly_deprecation,
          monthly_sales_use_tax: calculationResponse?.monthly_sales_use_tax,
          capital_cost_reduction_tax: calculationResponse?.taxes?.capital_cost_reduction_tax,
          tax_on_capitalized_cost_reduction: calculationResponse?.tax_on_capitalized_cost_reduction,
          rebate: calculationResponse?.rebate_promotions,
          down_payment:
            (Number(calculationResponse?.down_payment) * 100) / calculationResponse?.selling_price,
          down_payment_value: calculationResponse?.down_payment,
          due_at_signing: calculationResponse?.due_at_signing,
          net_finance_amount: calculationResponse?.adjusted_capitalized_cost,
          finance_amount: calculationResponse?.gross_capitalized_cost,
          capitalized_cost_reduction: calculationResponse?.capitalized_cost_reduction,
          estimated_monthly_payment: calculationResponse?.monthly_payment,
          tax_amount: calculationResponse?.tax_amount,
          is_payment_updated: true
        }
        updateOrderRequest(reqobj, {
          onSuccess(_response: any) {
            actions.setRecalculation(true)
          }
        })
      } else {
        actions.setRecalculation(false)
      }
    })
  }

  useEffect(() => {
    if (getValues('garaging_address')?.verified) {
      setIsGaragingVerified(true)
    }
  }, [getValues('garaging_address')])

  useEffect(() => {
    if (getValues('billing_address')?.verified) {
      setIsBillingVerified(true)
    }
  }, [getValues('billing_address')])

  useEffect(() => {
    if (getValues('previous_address')?.verified) {
      setIsPreviousAddressVerified(true)
    }
  }, [getValues('previous_address')])

  const getMoveInDate = (moveInDuration: string) => {
    let date = null
    if (moveInDuration && moveInDuration?.toString()?.length > 3) {
      const month = parseInt(moveInDuration?.toString().slice(0, 2))
      let year = parseInt(moveInDuration?.toString().slice(2, 6))
      date = `${year}-${month}-${1}`
      return date
    }
    return date
  }

  const setMoveInDate = (moveInDate: string) => {
    let monthYear = ''

    if (moveInDate?.length > 0) {
      if (parseInt(moveInDate?.toString()?.slice(0, 4)) > new Date().getFullYear()) {
      }
      monthYear = moveInDate?.toString()?.slice(5, 7) + moveInDate?.toString()?.slice(0, 4)
    }
    return monthYear
  }

  const hasErrors = Object.keys(errors).length > 0
  if (hasErrors) {
    setIsContact?.(false)
    setIsContactErrros?.(hasErrors)
  }
  const onSubmit = (data: IFormInputs) => {
    const addresses = []
    const mailAddress = getValues('mail_address')
    const garagingAddress = getValues('garaging_address')
    const billingAddress = getValues('billing_address')
    const previousAddress = getValues('previous_address')
    if (mailAddress) {
      mailAddress.address_type = AddressType.MAILING
      if (!isGoogleEnabled) {
        mailAddress.verified = false
      }
      addresses.push({
        ...mailAddress,
        move_in_date: mailAddress?.move_in_duration
          ? getMoveInDate(mailAddress?.move_in_duration)
          : null
      })
    }

    if (previousAddress) {
      previousAddress.address_type = AddressType.PREVIOUS
      if (!isGoogleEnabled) {
        previousAddress.verified = false
      }
      addresses.push({
        ...previousAddress,
        move_in_date: previousAddress?.move_in_duration
          ? getMoveInDate(previousAddress?.move_in_duration)
          : null,
        is_active: isPreviousAddress,
        is_deleted: !isPreviousAddress
      })
    }

    if (garagingAddress && !isGaraging) {
      garagingAddress.address_type = AddressType.GARAGING
      if (!isGoogleEnabled) {
        garagingAddress.verified = false
      }
      addresses.push({
        ...garagingAddress
      })
    }
    if (billingAddress && !isBilling) {
      billingAddress.address_type = AddressType.BILLING
      if (!isGoogleEnabled) {
        billingAddress.verified = false
      }
      addresses.push({
        ...billingAddress
      })
    }

    if (mailAddress && isGaraging) {
      addresses.push({
        address_type: AddressType.GARAGING,
        entity_type: AddressType.MAILING
      })
    }
    if (mailAddress && isBilling) {
      addresses.push({
        address_type: AddressType.BILLING,
        entity_type: AddressType.MAILING
      })
    }
    setUpdatedAddress(addresses)
    const newData = {
      customer_profile: {
        prefix: data?.customer_profile?.prefix === 'SELECT' ? null : data?.customer_profile?.prefix,
        first_name: data?.customer_profile?.first_name
          ? data?.customer_profile?.first_name
          : undefined,
        middle_name: data?.customer_profile?.middle_name
          ? data?.customer_profile?.middle_name
          : undefined,
        last_name: data?.customer_profile?.last_name
          ? data?.customer_profile?.last_name
          : undefined,
        suffix: data?.customer_profile?.suffix ? data?.customer_profile?.suffix : undefined,
        ssn: /^\●+$/?.test(data?.customer_profile?.ssn || '')
          ? contactData?.ssn
          : data?.customer_profile?.ssn
          ? data?.customer_profile?.ssn
          : undefined,
        mobile_number: data?.customer_profile?.mobile_number
          ? data?.customer_profile?.mobile_number
          : undefined,
        home_number: data?.customer_profile?.home_number ?? '',
        updated_by: 'Netsol'
      },
      customer_addresses: addresses
    }
    updateCustomerByReferenceId(
      {
        ...newData,
        reference_id: customerReferenceId,
        dealer_code: dealer_code
      },
      {
        onSuccess() {
          actions.setToast({
            toastMessage: 'Record updated successfully',
            toastState: true
          })
          setRecalculate(true)
          setIsContact?.(true)
          setIsContactErrros?.(!isValid)
        },
        onError(error: any) {
          const errorDetails = JSON.parse(JSON.stringify(error))
          actions.setToast({
            toastMessage: errorDetails?.error?.message?.includes('409')
              ? 'SSN must be unique.'
              : error?.detail?.message?.message?.toString() ||
                'An error occurred while processing your request. Please try again later.',
            toastState: true,
            variant: 'error'
          })
          setIsContact?.(false)
          setIsContactErrros?.(true)
        }
      }
    )
  }
  return (
    <>
      <Typography theme={theme} component="h3" variant="h3" sx={{ mb: 3 }}>
        Contact Details
      </Typography>
      {/* <form onSubmit={handleSubmit(submit)}> */}
      <Box theme={theme} sx={{ mb: 4 }}>
        <CustomerInfoComponent form={form} />
      </Box>
      <Box theme={theme} sx={{ mb: 4 }}>
        <hr />
      </Box>
      <Typography theme={theme} component="h4" variant="h4" className="title-icon" mb={2}>
        <span>Mailing Address </span>{' '}
        {isVerified ? (
          <Box theme={theme} display={'inline'} sx={{ position: 'relative', top: '-4px' }}>
            <Icon name="GreenCheckIcon" />
          </Box>
        ) : null}
      </Typography>

      <Box theme={theme} sx={{ mb: 4 }}>
        <AddressDetailsComponent
          form={form}
          isGoogleEnabled={isGoogleEnabled}
          addressValue={'mail_address'}
          setIsAddressDirty={setIsAddressDirty}
          errorProps={errors.mail_address}
        />
      </Box>

      <Box theme={theme} sx={{ mb: 4 }}>
        <hr />
      </Box>

      {isPreviousAddress && (
        <>
          <Typography theme={theme} component="h4" variant="h4" className="title-icon" mb={2}>
            <span>Previous Address </span>{' '}
            {isPreviousAddressVerified ? (
              <Box theme={theme} display={'inline'} sx={{ position: 'relative', top: '-4px' }}>
                <Icon name="GreenCheckIcon" />
              </Box>
            ) : null}
          </Typography>

          <Box theme={theme} sx={{ mb: 4 }}>
            <AddressDetailsComponent
              form={form}
              isGoogleEnabled={isGoogleEnabled}
              addressValue={'previous_address'}
              setIsAddressDirty={setIsAddressDirty}
              errorProps={errors.previous_address}
              hideMoveInHelper
            />
          </Box>

          <Box theme={theme} sx={{ mb: 4 }}>
            <hr />
          </Box>
        </>
      )}

      <Typography theme={theme} component="h4" variant="h4" className="title-icon" mb={2}>
        <span>Billing Address </span>{' '}
        {!isBilling && isbillingVerified ? (
          <Box theme={theme} display={'inline'} sx={{ position: 'relative', top: '-4px' }}>
            <Icon name="GreenCheckIcon" />
          </Box>
        ) : null}
      </Typography>
      <Box theme={theme}>
        <Checkbox
          checkBoxChecked={isBilling}
          label="Same as mailing address"
          onChange={(e: any) => fillBillingAddress(e)}
          theme={theme}
        />
      </Box>

      <Grid theme={theme} container spacing={3} sx={{ mb: 4 }}>
        <Grid theme={theme} item xs={12}>
          {!isBilling ? (
            <AddressDetailsComponent
              form={form}
              isGoogleEnabled={isGoogleEnabled}
              addressValue={'billing_address'}
              setIsAddressDirty={setIsAddressDirty}
              moveIn={false}
              errorProps={errors.billing_address}
            />
          ) : null}
        </Grid>
      </Grid>

      <Typography theme={theme} component="h4" variant="h4" className="title-icon" mb={2}>
        <span>Garaging Address </span>{' '}
        {!isGaraging && isgaragingVerified ? (
          <Box theme={theme} display={'inline'} sx={{ position: 'relative', top: '-4px' }}>
            <Icon name="GreenCheckIcon" />
          </Box>
        ) : null}
      </Typography>
      <Box theme={theme}>
        <Checkbox
          checkBoxChecked={isGaraging}
          label="Same as mailing address"
          onChange={(e: any) => fillGargingAddress(e)}
          theme={theme}
        />
      </Box>

      <Grid theme={theme} container spacing={3} sx={{ mb: 4 }}>
        <Grid theme={theme} item xs={12}>
          {!isGaraging ? (
            <AddressDetailsComponent
              form={form}
              isGoogleEnabled={isGoogleEnabled}
              addressValue={'garaging_address'}
              setIsAddressDirty={setIsAddressDirty}
              errorProps={errors.garaging_address}
              moveIn={false}
            />
          ) : null}
        </Grid>
      </Grid>

      <Box theme={theme} sx={{ mb: 4 }}>
        <hr />
      </Box>
      <Box theme={theme} textAlign="right" sx={{ mb: 5 }}>
        <Button
          theme={theme}
          primary
          text="Save Contact Details"
          type="submit"
          // disabled={!isDirty || !isAddressDirty}
          onClick={handleSubmit(onSubmit)}
        />
      </Box>
    </>
  )
}
export default ContactDetailsComponent
