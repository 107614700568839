import clsx from "clsx";
import { PricingEstimatesStepsProps } from "./pricing-estimates-steps.props";
import "./pricing-estimates-steps.scss";

export const PricingEstimatesSteps = ({
  title,
  description,
  stepCount,
  iconName,
  content,
  disabled,
}: PricingEstimatesStepsProps) => {
  return (
    <div
      className={clsx({
        "pricing-estimates-steps-wrapper": true,
        "pricing-estimates-steps-wrapper--disabled": disabled,
      })}
    >
      {stepCount && (
        <span className="text--subsection-2 pricing-estimates-steps-wrapper--step">
          {stepCount}
        </span>
      )}
      <div className="flex-1">
        {title && (
          <p className="text--subsection-2 pricing-estimates-steps-wrapper--title">
            {title}
          </p>
        )}
        {description && (
          <p className="text--body-2 pricing-estimates-steps-wrapper--description">
            {description}
          </p>
        )}
        {content && (
          <div className="pricing-estimates-steps-wrapper--content">
            {content}
          </div>
        )}
      </div>
      {iconName && (
        <span
          className="bmw-icon bmw-icon--before data-icon"
          aria-label="pricing estimates step icon"
          data-icon={iconName}
        />
      )}
    </div>
  );
};
