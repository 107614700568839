import { Button, Checkbox, Input, MessageAlert } from "@src/components/atoms";
import "./co-applicant-details.scss";
export const CoApplicantDetails = ({ state, dispatch }) => {
  return (
    <div className="co-applicant-details-wrap">
      <form onSubmit={() => {}} className="co-applicant-details-wrap--form">
        <p className="text--body-2">
          Please provide your co-applicant&apos;s email address to invite them
          to this platform.{" "}
        </p>
        <div className="co-applicant-details-wrap--form-row">
          <div className="flex-1">
            <Input
              name={"co_applicatnt_name"}
              id={"co_applicatnt_name"}
              placeholder="Co-applicant name"
              label="Co-applicant name"
              value={"Sarah Runnen"}
            />
          </div>
          <div className="flex-1">
            <Input
              name={"co_applicatnt_email"}
              id={"co_applicatnt_email"}
              placeholder="Co-applicant email address"
              label="Co-applicant email address"
              value={"sarahrunnen@bmwna.com"}
            />
          </div>
        </div>
        <Checkbox
          id={"coApplicantNameCheck"}
          checked
          label="By entering my co-applicant's name and email address, I understand that my co-applicant will receive an email inviting them to this platform. I represent that I have their permission to provide their information for this invite."
          htmlFor="coApplicantNameCheck"
        />
        <div className="form-wrapper--button-top-spacer">
          <Button
            type="default"
            htmlType="button"
            text="Send Invite"
            onClick={() => dispatch({ type: "zero-Step" })}
            fixed
            fullWidth
          />
        </div>
      </form>
    </div>
  );
};
