import Http from '@helpers/http-api'
import { useMutation, useQuery } from 'react-query'
import {
  EP_Appointment,
  EP_GET_DEALER_HOLIDAYS,
  EP_GET_INDIVIDUAL,
  EP_WORKDAY
} from '@helpers/endpoints'
import {
  WorkDaysResponse,
  HolidayResponse
} from '@app/order-management/order-summary/workdays-types'
export const useAddAppointment = (): any => {
  const { data, isLoading, mutate } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(EP_Appointment, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateAppointment = (): any => {
  const { data, isLoading, mutate } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(EP_Appointment, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useDeleteAppointment = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.delete<any>(`${EP_Appointment}/${body.id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetWorkdays = (dealer_code: string, company_id: number) => {
  return useQuery<WorkDaysResponse, Error>(
    ['workday', dealer_code],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.CONFIG_API_GATEWAY
      })
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        company_id: company_id
      }

      return apiService.get(`${EP_GET_INDIVIDUAL}/${dealer_code}/${EP_WORKDAY}`, {}, { headers })
    },
    { enabled: !!company_id && !!dealer_code }
  )
}

export const useDealerHolidays = (dealer_code: string, company_id: number) => {
  return useQuery<HolidayResponse[], Error>(
    ['holidays', dealer_code],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.CONFIG_API_GATEWAY
      })
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        company_id: company_id
      }

      return apiService.get(`${EP_GET_DEALER_HOLIDAYS}/${dealer_code}`, {}, { headers })
    },
    { enabled: !!company_id && !!dealer_code }
  )
}
