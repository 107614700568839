import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PageContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({}) => ({
  '&.page-content': {
    '.thumbnail': {
      img: {
        maxHeight: '100%'
      }
    },
    '.detail-card': {
      '.thumbnail': {
        img: {
          maxHeight: '100%'
        }
      }
    }
  }
}))
