import { useTheme } from '@mui/material'
import {} from './WorkQueuePageHeaderStyle'
import { CustomPageHeader } from '@shared/components/pageHeader/pageHeader'
import { Button, Typography } from '@ntpkunity/controls'

export const WorkQueuePageHeaderComponent = () => {
  const theme = useTheme()
  return (
    <CustomPageHeader
      theme={theme}
      title={
        <>
          <Typography theme={theme} variant="h2" component="h2">
            Work Queue
          </Typography>
        </>
      }
      actionArea={
        <>
          <Button theme={theme} primary text="Add Package" onClick={()=>{}} />
        </>
      }
    />
  )
}
