import {
    FETCH_AUDIT_TRAIL_REQUEST,
    FETCH_AUDIT_TRAIL_SUCCESS,
    FETCH_AUDIT_TRAIL_FAILURE,
} from "../actions/auditTrailActions";

const initialState = {
    loading: false,
    auditTrailData: [],
    error: null,
};

const auditTrailReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_AUDIT_TRAIL_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_AUDIT_TRAIL_SUCCESS:
            return { ...state, loading: false, auditTrailData: action.payload };
        case FETCH_AUDIT_TRAIL_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default auditTrailReducer;
