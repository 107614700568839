import { FC, useState } from "react";
import { TableCell, TablePagination, TableRow, useTheme } from "@mui/material";
import ChevronDown from "shared/assets/images/chevron-down";
import { DataTable, Checkbox } from "@ntpkunity/controls";
import { PaginationWrap } from "@sharedComponents";
import { useGetAllLenderSelectioRules } from "../../../hooks/lenderSelectionRules";
import {
  LenderSelectionRule,
  SearchParams,
} from "../../../Types/lenderSelectionRules";
import { useForm } from "react-hook-form";
import { IFilteredParams } from "../../../Interface/LenderSelectionRule/IFilteredParams";
import { SkeletonTr } from "./SkeletonTr";
import { Tr } from "./Tr";
import { SearchField } from "./SearchField";

const TableList: FC<{
  pageNumber: number;
  setPageNumber: any;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  queryParams: SearchParams;
  setQueryParams: React.Dispatch<React.SetStateAction<Object>>;
  deleteRule: (id: number) => void;
  setKey: React.Dispatch<React.SetStateAction<number>>;
  addNew: (_event: any, isEdit?: boolean) => void;
  isDataFetched: boolean;
}> = ({
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  queryParams,
  setQueryParams,
  deleteRule,
  setKey,
  addNew,
  isDataFetched,
}) => {
  const theme = useTheme();
  const {
    data: lenderSelectionRules,
    isLoading,
    isFetched,
  } = useGetAllLenderSelectioRules(queryParams);
  const { control, watch } = useForm<IFilteredParams>();

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setQueryParams((prevState) => ({
      ...prevState,
      page_number: newPage,
    }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setQueryParams((prevState) => ({
      ...prevState,
      page_size: +event.target.value,
      page_number: 0,
    }));
  };

  const handleFiltersChange = () => {
    const paramsObj = {
      page_number: pageNumber,
      page_size: pageSize,
    };
    const filterFormValues = watch();
    Object.entries(filterFormValues).forEach(([key, value]) => {
      if (value) paramsObj[key] = value;
    });
    setQueryParams(paramsObj);
  };

  return (
    <>
      <DataTable
        theme={theme}
        theadChildren={
          <>
            <TableRow>
              <TableCell className="checkbox-cell fixed-cell">
                <Checkbox label="" theme={theme} />
              </TableCell>
              <TableCell>Product Type</TableCell>
              <TableCell>Finance Type</TableCell>
              <TableCell>Finance Amount Range</TableCell>
              <TableCell>Customer Type</TableCell>
              <TableCell>Asset Category</TableCell>
              <TableCell>Asset Type</TableCell>
              <TableCell>Dealer Selection</TableCell>
              <TableCell>Lender Selection</TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>

            <TableRow className="filters-row">
              <TableCell className="checkbox-cell fixed-cell" />
              <SearchField
                name={"filtered_product_types"}
                id={"id_filtered_product_types"}
                control={control}
                handleFiltersChange={handleFiltersChange}
              />
              <SearchField
                name={"filtered_finance_types"}
                id={"id_filtered_finance_types"}
                control={control}
                handleFiltersChange={handleFiltersChange}
              />
              <SearchField
                name={"filtered_finance_amount_ranges"}
                id={"id_filtered_finance_amount_ranges"}
                control={control}
                handleFiltersChange={handleFiltersChange}
              />
              <SearchField
                name={"filtered_customer_types"}
                id={"id_filtered_customer_types"}
                control={control}
                handleFiltersChange={handleFiltersChange}
              />
              <SearchField
                name={"filtered_asset_categories"}
                id={"id_filtered_asset_categories"}
                control={control}
                handleFiltersChange={handleFiltersChange}
              />
              <SearchField
                name={"filtered_asset_types"}
                id={"id_filtered_asset_types"}
                control={control}
                handleFiltersChange={handleFiltersChange}
              />
              <SearchField
                name={"filtered_dealers"}
                id={"id_filtered_dealers"}
                control={control}
                handleFiltersChange={handleFiltersChange}
              />
              <SearchField
                name={"filtered_lenders"}
                id={"id_filtered_lenders"}
                control={control}
                handleFiltersChange={handleFiltersChange}
              />
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {isFetched && lenderSelectionRules?.length < 1 ? (
              <TableRow className="child-tr">
                <TableCell className="text-center" colSpan={10}>
                  No Record Found!
                </TableCell>
              </TableRow>
            ) : isLoading ? (
              Array.from({ length: 3 }).map((_, index) => (
                <SkeletonTr key={index} />
              ))
            ) : (
              lenderSelectionRules.map((rules: LenderSelectionRule) => (
                <Tr
                  key={rules.id}
                  rules={rules}
                  deleteRule={deleteRule}
                  setKey={setKey}
                  addNew={addNew}
                  isDataFetched={isDataFetched}
                />
              ))
            )}
          </>
        }
      />
      <PaginationWrap>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={-1}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
            MenuProps: { disablePortal: true },
          }}
        />
      </PaginationWrap>
    </>
  );
};

export default TableList;
