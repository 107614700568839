import { useSetupsSelector } from "@hooks";
import { useTheme } from "@mui/material";
import { Skeleton } from "@ntpkunity/controls";
import React from "react";

export const FinanceTypeCell = ({
  financeTypeCode,
  highlightSearchValue,
}: {
  financeTypeCode: string;
  highlightSearchValue?: (value: string) => React.ReactNode;
}) => {
  const theme = useTheme();
  const { isLoading: financeTypesLoading, data: financeTypes } =
    useSetupsSelector((state) => state.financeTypes);

  if (financeTypesLoading) {
    return <Skeleton theme={theme} height={14} />;
  }

  const financeTypeName =
    financeTypes?.find((ft) => ft.code === financeTypeCode)?.name || "";

  return (
    <>
      {!!highlightSearchValue
        ? highlightSearchValue(financeTypeName)
        : financeTypeName}
    </>
  );
};
