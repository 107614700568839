import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  TableCell,
  TableRow,
  Link,
  useTheme,
  TablePagination,
} from "@mui/material";
import {
  Box,
  Button,
  DataTable,
  DateRange,
  Dialog,
  Grid,
  Icon,
  Input,
  Menu,
  Skeleton,
  Tooltip,
  Typography,
} from "@ntpkunity/controls";
import { DataTableWrap } from "@shared/DataTableWrap";
import {
  useDeleteStripeInvoice,
  useGetChargeAgainstInvoiceId,
  useGetInvoiceLineItems,
  useSendStripeInvoice,
  useStripeInvoiceList,
  useVoidStripeInvoice,
} from "common/hooks/useHubexData";
import type { ICustomerInvoice } from "Interface";
import { Controller, useForm } from "react-hook-form";
import { PAGE_SIZE, dateFormatSearch } from "common/constants";
import { useGetContractByRefercnceId } from "common/hooks/useSubscription";
import { InvoiceModalComponent } from "../invoiceModal/invoiceModalComponent";
import { SortingOrder } from "enums/SortingOrder";
import {
  ModalModes,
  InvoiceDisplayStatus,
  InvoiceDisplayType,
  SubscriptionStatus,
} from "enums";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "enums/PermissionTypes";
import { useStoreContext } from "store/storeContext";
import DisableLoader from "../../../../../shared/assets/images/loader-disabled.gif";
import { InvoiceHistoryModalComponent } from "../invoiceHistoryModal/invoiceHistoryModalComponent";
import { RefundModalComponent } from "@shared/RefundModal";
import { StatusCard } from "@shared/StatusCard";
import { utcToLocal } from "utilities/methods";

export const InvoiceListComponent = () => {
  const theme = useTheme();
  const params = useParams();
  const { actions } = useStoreContext();
  const reference_id = params?.reference_id;
  const order_reference_id = params?.order_reference_id;
  const { control, watch, formState } = useForm<ICustomerInvoice>();
  const formValues = watch();
  const { data: contractData, isLoading: contractDataLoading } =
    useGetContractByRefercnceId(reference_id, order_reference_id);
  const {
    mutate: getStripeInvoices,
    isLoading: customerInvoiceSearchResultLoading,
  } = useStripeInvoiceList();
  const { mutate: deleteInvoice, isLoading: deleteInvoiceLoading } =
    useDeleteStripeInvoice();
  const { mutate: voidInvoice, isLoading: voidInvoiceLoading } =
    useVoidStripeInvoice();
  const { mutate: getInvoiceLineItems } = useGetInvoiceLineItems();
  const { mutate: sendInvoice } = useSendStripeInvoice();
  const { mutate: getChargeInvoiceId, isLoading: isPaymentAttemptsLoading } = useGetChargeAgainstInvoiceId();
  const [filteredCustomerInvoices, setFilteredCustomerInvoices] =
    useState<ICustomerInvoice[]>();
  const [customerInvoiceSearchResult, setCustomerInvoiceSearchResult] =
    useState<any>();

  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [openInvoiceDialog, setOpenInvoiceDialog] = useState<boolean>(null);
  const [openHistoryDialog, setOpenHistoryDialog] = useState<boolean>(null);
  const [openRefundDialog, setOpenRefundDialog] = useState<boolean>(null);
  const [modalOpeningMode, setModalOpeningMode] = useState<ModalModes>();
  const [selectedInvoice, setSelectedInvoice] = useState<ICustomerInvoice>();
  const [sortBy, setSortBy] = useState(SortingOrder.ASC);
  const [dialogOpened, setDialogOpened] = useState<boolean>(false);
  const [deleteInvoiceId, setDeleteInvoiceId] = useState("");
  const [voidInvoiceId, setVoidInvoiceId] = useState("");
  const [selectedMenuOption, setSelectMenuOption] = useState("");
  const [sortData, setSortData] = useState("");
  const [pastDuedays, setPastDueDays] = useState<number>();
  const [amountDue, setAmountDue] = useState<number>();
  const [draftAmountDue, setDraftAmountDue] = useState<number>();
  const [paymentAttemptDialogOpened, setPaymentAttemptOpened] = useState<boolean>(false);
  const [chargeDetails, setChargeDetail] = useState([]);

  const createInvoicePermission = useHasPermissions(
    ccpPermissions.FIELDS.CREATE_INVOICE,
    PermissionTypes.VIEW
  );
  const editInvoicePermission = useHasPermissions(
    ccpPermissions.FIELDS.EDIT_INVOICE,
    PermissionTypes.DISABLED
  );
  const deleteInvoicePermission = useHasPermissions(
    ccpPermissions.FIELDS.DELETE_INVOICE,
    PermissionTypes.VIEW
  );
  const voidInvoicePermission = useHasPermissions(
    ccpPermissions.FIELDS.VOID_INVOICE,
    PermissionTypes.VIEW
  );
  const viewInvoicePermission = useHasPermissions(
    ccpPermissions.FIELDS.VIEW_INVOICE,
    PermissionTypes.DISABLED
  );
  const historyInvoicePermission = useHasPermissions(
    ccpPermissions.FIELDS.INVOICE_HISTORY,
    PermissionTypes.DISABLED
  );
  const creditNotePermission = useHasPermissions(
    ccpPermissions.FIELDS.CREDIT_NOTE,
    PermissionTypes.DISABLED
  );
  const resendInvoicePermission = useHasPermissions(
    ccpPermissions.FIELDS.DISABLE_RESEND_INVOICE_BUTTON,
    PermissionTypes.DISABLED
  );
  const refundPermission = useHasPermissions(
    ccpPermissions.FIELDS.DISABLE_REFUND,
    PermissionTypes.VIEW
  );

  const [isCreateInvoiceAllow, setIsCreateInvoiceAllow] = useState(false);
  const [isEditInvoiceAllow, setIsEditInvoiceAllow] = useState(false);
  const [isDeleteInvoiceAllow, setIsDeleteInvoiceAllow] = useState(false);
  const [isVoidInvoiceAllow, setIsVoidInvoiceAllow] = useState(false);
  const [isViewInvoiceAllow, setIsViewInvoiceAllow] = useState(false);
  const [isInvoiceHistoryAllow, setIsInvoiceHistoryAllow] = useState(false);
  const [isCreditNoteAllow, setIsCreditNoteAllow] = useState(false);
  const [isResendInvoiceAllow, setIsResendInvoiceAllow] = useState(false);
  const [isRefundAllow, setIsRefundAllow] = useState(false);

  useEffect(() => {
    if (
      (openInvoiceDialog === false || openRefundDialog === false) &&
      contractData
    ) {
      const subscriptionId = contractData?.vendor_subscription_id;
      getCustomerInvoices({
        customer: contractData?.customer?.stripe_customer_id,
        subscription: subscriptionId,
        meta_data: { stripe_subscription_id: subscriptionId },
        expand: [
          "total_count",
          "data.payment_intent.payment_method",
          "data.charge",
        ],
      });
    }
  }, [openInvoiceDialog, openRefundDialog]);

  useEffect(() => {
    if (contractData) {
      // TODO: Fetch stripeSubscriptionId from orderData
      const subscriptionId = contractData?.vendor_subscription_id;
      getCustomerInvoices({
        customer: contractData?.customer?.stripe_customer_id,
        subscription: subscriptionId,
        meta_data: { stripe_subscription_id: subscriptionId },
        expand: [
          "total_count",
          "data.payment_intent.payment_method",
          "data.charge",
        ],
      });
    }
  }, [contractData]);

  useEffect(() => {
    setIsCreateInvoiceAllow(createInvoicePermission);
  }, [createInvoicePermission]);
  useEffect(() => {
    setIsEditInvoiceAllow(editInvoicePermission);
  }, [editInvoicePermission]);
  useEffect(() => {
    setIsDeleteInvoiceAllow(deleteInvoicePermission);
  }, [deleteInvoicePermission]);
  useEffect(() => {
    setIsVoidInvoiceAllow(voidInvoicePermission);
  }, [voidInvoicePermission]);
  useEffect(() => {
    setIsViewInvoiceAllow(viewInvoicePermission);
  }, [viewInvoicePermission]);
  useEffect(() => {
    setIsInvoiceHistoryAllow(historyInvoicePermission);
  }, [historyInvoicePermission]);
  useEffect(() => {
    setIsCreditNoteAllow(creditNotePermission);
  }, [creditNotePermission]);
  useEffect(() => {
    setIsResendInvoiceAllow(resendInvoicePermission);
  }, [resendInvoicePermission]);
  useEffect(() => {
    setIsRefundAllow(refundPermission);
  }, [refundPermission]);

  useEffect(() => {
    if (customerInvoiceSearchResult?.data) {
      setFilteredCustomerInvoices(getFilteredCustomerInvoices(0));
    }
  }, [customerInvoiceSearchResult?.data, pageSize]);

  useEffect(() => {
    if (formState.isDirty) {
      setFilteredCustomerInvoices(getFilteredCustomerInvoices(0));
    }
  }, [
    formState.isDirty,
    formValues.invoice_type,
    formValues.invoice_number,
    formValues.due_amount,
    formValues.refund_amount,
    formValues.due_date,
    formValues.created_date,
    formValues.invoice_status,
    formValues.payment_attempts,
    formValues.past_due_days,
    formValues.paid_date,
    formValues.payment_method,
  ]);

  const getCustomerInvoices = (query_params) => {
    getStripeInvoices(query_params, {
      onSuccess(response) {
        const sortedArrayOrder =
          response && [...response?.data]?.sort((a, b) => a - b);
        setCustomerInvoiceSearchResult({
          ...response,
          data: sortedArrayOrder,
        });
      },
      onError(error: any) {
        console.log("Error Occured", error);
      },
    });
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setFilteredCustomerInvoices(getFilteredCustomerInvoices(newPage));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setPageSize(parseInt(event.target.value, 10));
  };

  const handleOpenInvoiceDialog = (
    modalOpeningMode: ModalModes,
    customerInvoice?: ICustomerInvoice
  ) => {
    if (customerInvoice?.lines_has_more) {
      getInvoiceLineItems(
        { invoiceId: customerInvoice?.id },
        {
          onSuccess(data) {
            setSelectedInvoice({ ...customerInvoice, lines: data });
            setModalOpeningMode(modalOpeningMode);
            setOpenInvoiceDialog(true);
          },
        }
      );
    } else {
      setSelectedInvoice(customerInvoice);
      setModalOpeningMode(modalOpeningMode);
      setOpenInvoiceDialog(true);
    }
  };

  const handleOpenHistoryDialog = (customerInvoice?: ICustomerInvoice) => {
    setSelectedInvoice(customerInvoice);
    setOpenHistoryDialog(true);
  };

  const handleResendInvoice = (invoiceId: string) => {
    sendInvoice(
      {
        invoice_id: invoiceId,
      },
      {
        onSuccess({ data }) {
          actions.setToast({
            toastMessage: `Invoice sent to ${data?.customer_email}`,
            toastState: true,
          });
        },
        onError(error: any) {
          console.log("Error Occured", error);
          actions.setToast({
            toastMessage: error?.detail?.message?.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  const handleDeleteInvoice = (invoiceId: string) => {
    setSelectMenuOption("delete");
    setDialogOpened(true);
    setDeleteInvoiceId(invoiceId);
  };
  const DialogDeleteSave = () => {
    deleteInvoice(
      {
        invoice_id: deleteInvoiceId,
      },
      {
        onSuccess({ data }) {
          if (data?.deleted) {
            if (customerInvoiceSearchResult?.data?.length > 0) {
              customerInvoiceSearchResult.data =
                customerInvoiceSearchResult?.data?.filter(
                  (f) => f.id !== data?.id
                );
            }
          }
          setDialogOpened(false);
          setOpenInvoiceDialog(false);
          actions.setToast({
            toastMessage: "Invoice has been deleted",
            toastState: true,
          });
        },
        onError(error: any) {
          console.log("Error Occured", error);
          actions.setToast({
            toastMessage: error?.detail?.message?.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };
  const handleVoidInvoice = (invoiceId: string) => {
    setSelectMenuOption("void");
    setDialogOpened(true);
    setVoidInvoiceId(invoiceId);
  };
  const DialogVoidSave = () => {
    voidInvoice(
      {
        invoice_id: voidInvoiceId,
      },
      {
        onSuccess({ data }) {
          if (customerInvoiceSearchResult?.data?.length > 0) {
            const index = customerInvoiceSearchResult?.data?.findIndex(
              (f) => f.id === data?.id
            );
            if (index > -1) {
              customerInvoiceSearchResult.data[index].invoice_status =
                data?.status;
            }
          }
          setDialogOpened(false);
          actions.setToast({
            toastMessage: "Invoice marked Void",
            toastState: true,
          });
        },
        onError(error: any) {
          console.log("Error Occured", error);
          actions.setToast({
            toastMessage: error?.detail?.message?.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  const handlePaymentAttemptClick = (invoiceId: string) => {
    getChargeInvoiceId(
      {
        invoice_id: invoiceId,
        customer_id: contractData?.customer?.stripe_customer_id,
      },
      {
        onSuccess({ data }) {
          setChargeDetail(data)
        },
        onError() {
          actions.setToast({
            toastMessage: "Something went wrong",
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  const handleOnSave = () => {
    if (selectedMenuOption === "delete") {
      DialogDeleteSave();
    } else if (selectedMenuOption === "void") {
      DialogVoidSave();
    }
  };
  const paginateArray = (array: ICustomerInvoice[], pageSize, pageNumber) => {
    const startIndex = (pageNumber + 1 - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return array?.length > 0 ? array.slice(startIndex, endIndex) : [];
  };
  const filterArray = (array: ICustomerInvoice[], filter: ICustomerInvoice) => {
    return array?.length > 0
      ? array.filter((item) => {
        const createdDate = new Date(
          utcToLocal(
            new Date(item?.created_date * 1000).toISOString(),
            dateFormatSearch
          )
        );
        const dueDate = new Date(
          utcToLocal(
            new Date(item?.due_date * 1000).toISOString(),
            dateFormatSearch
          )
        );
        const paymentDate = new Date(
          utcToLocal(
            new Date(item?.paid_date * 1000).toISOString(),
            dateFormatSearch
          )
        );
        const fromInvoiceCreated = filter.created_date?.[0]
          ? new Date(filter.created_date?.[0]?.toDate()?.setHours(0, 0, 0, 0))
          : null;
        const toInvoiceCreated = filter.created_date?.[1]
          ? new Date(
            filter.created_date?.[1]?.toDate()?.setHours(23, 59, 59, 999)
          )
          : null;
        const fromDueDate = filter.due_date?.[0]
          ? new Date(filter.due_date?.[0]?.toDate()?.setHours(0, 0, 0, 0))
          : null;
        const toDueDate = filter.due_date?.[1]
          ? new Date(
            filter.due_date?.[1]?.toDate()?.setHours(23, 59, 59, 999)
          )
          : null;
        const fromPaymentDate = filter.paid_date?.[0]
          ? new Date(filter.paid_date?.[0]?.toDate()?.setHours(0, 0, 0, 0))
          : null;
        const toPaymentDate = filter.paid_date?.[1]
          ? new Date(
            filter.paid_date?.[1]?.toDate()?.setHours(23, 59, 59, 999)
          )
          : null;
        return (
          (fromInvoiceCreated && toInvoiceCreated
            ? (createdDate >= fromInvoiceCreated &&
              createdDate <= toInvoiceCreated) ||
            (createdDate === fromInvoiceCreated &&
              createdDate === toInvoiceCreated)
            : true) &&
          (filter?.invoice_type
            ? item.invoice_type
              ?.toLowerCase()
              .includes(filter.invoice_type.toLowerCase())
            : true) &&
          (filter?.invoice_number
            ? item.invoice_number
              ?.toLowerCase()
              .includes(filter.invoice_number.toLowerCase())
            : true) &&
          (filter?.due_amount
            ? item.due_amount === +filter.due_amount * 100
            : true) &&
          (filter?.refund_amount
            ? item.refund_amount === +filter.refund_amount * 100
            : true) &&
          (fromDueDate && toDueDate
            ? (dueDate >= fromDueDate && dueDate <= toDueDate) ||
            (dueDate === fromDueDate && dueDate === toDueDate)
            : true) &&
          (filter?.invoice_status
            ? item.invoice_status
              ?.toLowerCase()
              .includes(filter.invoice_status.toLowerCase())
            : true) &&
          (filter?.payment_attempts
            ? item.payment_attempts === +filter.payment_attempts
            : true) &&
          (filter?.past_due_days
            ? item.past_due_days === +filter.past_due_days
            : true) &&
          (fromPaymentDate && toPaymentDate
            ? (paymentDate >= fromPaymentDate &&
              paymentDate <= toPaymentDate) ||
            (paymentDate === fromPaymentDate &&
              paymentDate === toPaymentDate)
            : true) &&
          (filter?.payment_method
            ? item.payment_method
              ?.toLowerCase()
              .includes(filter.payment_method)
            : true)
        );
      })
      : [];
  };
  const sorting = (sortingdata): void => {
    setSortData(sortingdata);
    if (sortBy === SortingOrder.ASC) {
      const sortedData = [...customerInvoiceSearchResult?.data].sort((a, b) =>
        (a[sortingdata] ?? "")?.localeCompare(b[sortingdata] ?? "")
      );
      setFilteredCustomerInvoices([...sortedData]);

      setSortBy(SortingOrder.DSC);
    } else if (sortBy === SortingOrder.DSC) {
      const sortedData = [...customerInvoiceSearchResult?.data].sort((a, b) =>
        (b[sortingdata] ?? "")?.localeCompare(a[sortingdata] ?? "")
      );

      setFilteredCustomerInvoices([...sortedData]);

      setSortBy(SortingOrder.ASC);
    }
  };

  const sorting_num_data = (sortingdata) => {
    setSortData(sortingdata);
    if (sortBy === SortingOrder.ASC) {
      const sortedData = [...customerInvoiceSearchResult?.data].sort(
        (a, b) => a[sortingdata] - b[sortingdata]
      );

      setFilteredCustomerInvoices([...sortedData]);

      setSortBy(SortingOrder.DSC);
    } else if (sortBy === SortingOrder.DSC) {
      const sortedData = [...customerInvoiceSearchResult?.data].sort(
        (a, b) => b[sortingdata] - a[sortingdata]
      );

      setFilteredCustomerInvoices([...sortedData]);

      setSortBy(SortingOrder.ASC);
    }
  };

  const getFilteredCustomerInvoices = (pageNumber) => {
    setPageNumber(pageNumber);
    const filteredResult = filterArray(
      customerInvoiceSearchResult?.data,
      formValues
    );
    setTotalRecords(filteredResult.length);
    return paginateArray(filteredResult, pageSize, pageNumber);
  };

  const handleOpenRefundDialog = (customerInvoice: ICustomerInvoice) => {
    setSelectedInvoice(customerInvoice);
    setOpenRefundDialog(true);
  };

  useEffect(() => {
    if (customerInvoiceSearchResult?.data) {
      const openInvoices = customerInvoiceSearchResult?.data?.filter(
        (invoice) =>
          invoice.invoice_status === InvoiceDisplayStatus.OPEN ||
          invoice.invoice_status === InvoiceDisplayStatus.PAST_DUE ||
          invoice.invoice_status === InvoiceDisplayStatus.PENDING
      );
      const amount_due = openInvoices?.reduce((sum, currentInvoice) => {
        return sum + currentInvoice?.due_amount;
      }, 0);
      setAmountDue(amount_due);
      const pastDueInvoices = customerInvoiceSearchResult?.data?.filter(
        (invoice) => invoice.invoice_status === InvoiceDisplayStatus.PAST_DUE
      );
      const past_due_days: number = pastDueInvoices?.reduce(
        (maxInvoice, currentInvoice) => {
          return currentInvoice?.past_due_days > maxInvoice?.past_due_days
            ? currentInvoice
            : maxInvoice;
        },
        pastDueInvoices?.[0]
      )?.past_due_days;
      setPastDueDays(past_due_days);

      const draftAmountDue = customerInvoiceSearchResult?.data?.reduce(
        (sum, currentInvoice) => {
          if (currentInvoice.invoice_status === InvoiceDisplayStatus.DRAFT) {
            return sum + currentInvoice?.due_amount;
          } else {
            return sum;
          }
        },
        0
      );

      setDraftAmountDue(draftAmountDue);
    }
  }, [customerInvoiceSearchResult?.data]);
  return (
    <>
      {contractDataLoading || customerInvoiceSearchResultLoading ? (
        <Skeleton width={"100%"} height={20} theme={theme} />
      ) : (
        <Grid theme={theme} container columnSpacing={2} rowSpacing={0}>
          <Grid theme={theme} item xs={4}>
            <StatusCard sx={{ mt: "-8px" }}>
              <Box theme={theme} className="caption">
                <Typography
                  theme={theme}
                  variant="caption"
                  component="span"
                  children="Amount Past Due: "
                />
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="p"
                  children={
                    amountDue > 0
                      ? (amountDue/100)?.toLocaleString("en-GB", {
                        style: "currency",
                        currency: "GBP",
                      })
                      : "-"
                  }
                />
                <Tooltip
                  theme={theme}
                  placement="right"
                  title="This amount includes all unpaid open invoices"
                  disablePortal
                >
                  <Icon name="InfoIcon" />
                </Tooltip>
              </Box>
            </StatusCard>
          </Grid>
          <Grid theme={theme} item xs={4}>
            <StatusCard sx={{ mt: "-8px" }}>
              <Box theme={theme} className="caption">
                <Typography
                  theme={theme}
                  variant="caption"
                  component="span"
                  children="Days Past Due: "
                />
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="p"
                  children={pastDuedays || 0}
                />
              </Box>
            </StatusCard>
          </Grid>
          <Grid theme={theme} item xs={4}>
            <StatusCard sx={{ mt: "-8px" }}>
              <Box theme={theme} className="caption">
                <Typography
                  theme={theme}
                  variant="caption"
                  component="span"
                  children="Due Amount (draft): "
                />
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="p"
                  children={
                    draftAmountDue > 0
                      ? (draftAmountDue/100)?.toLocaleString("en-GB", {
                        style: "currency",
                        currency: "GBP",
                      })
                      : "-"
                  }
                />
                <Tooltip
                  theme={theme}
                  placement="right"
                  title="This amount includes all unpaid draft invoices"
                  disablePortal
                >
                  <Icon name="InfoIcon" />
                </Tooltip>
              </Box>
            </StatusCard>
          </Grid>
        </Grid>
      )}
      <DataTableWrap className="table-tabs-pagination-button-statusCard">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <DataTable
          theme={theme}
          theadChildren={
            <>
              <TableRow className="table-header">
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Invoice Created
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortData === "created_date" ? (
                          sortBy === SortingOrder.ASC ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        sorting_num_data("created_date");
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Invoice Type
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortData === "invoice_type" ? (
                          sortBy === SortingOrder.ASC ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        sorting("invoice_type");
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Invoice Number
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortData === "invoice_number" ? (
                          sortBy === SortingOrder.ASC ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        sorting("invoice_number");
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Due Amount
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortData === "due_amount" ? (
                          sortBy === SortingOrder.ASC ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        sorting_num_data("due_amount");
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Refund Amount
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortData === "refund_amount" ? (
                          sortBy === SortingOrder.ASC ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        sorting_num_data("refund_amount");
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Due Date
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortData === "due_date" ? (
                          sortBy === SortingOrder.ASC ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        sorting_num_data("due_date");
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Invoice Status
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortData === "invoice_status" ? (
                          sortBy === SortingOrder.ASC ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        sorting("invoice_status");
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Payment Attempts
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortData === "payment_attempts" ? (
                          sortBy === SortingOrder.ASC ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        sorting_num_data("payment_attempts");
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Days Past Due
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortData === "past_due_days" ? (
                          sortBy === SortingOrder.ASC ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        sorting_num_data("past_due_days");
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Payment Date
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortData === "paid_date" ? (
                          sortBy === SortingOrder.ASC ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        sorting_num_data("paid_date");
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Payment Method
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortData === "payment_method" ? (
                          sortBy === SortingOrder.ASC ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscendingIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        sorting("payment_method");
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell space-fixed"></TableCell>
              </TableRow>
              <TableRow className="filters-row with-date-range">
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="created_date"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <DateRange
                          theme={theme}
                          placeholder="00/00/0000 - 00/00/0000"
                          onFocus={onfocus}
                          onBlur={onblur}
                          onChange={onChange}
                          format="MM/DD/YYYY"
                          range
                          rangeHover
                          endAdornment={
                            <Icon
                              name="CalendarIcon"
                              className="calendar-icon"
                            />
                          }
                          dateSeparator={" - "}
                          noLabel={true}
                        />
                      )}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="invoice_type"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          id="invoiceType"
                          testid="invoiceType"
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="invoice_number"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          id="invoiceNumber"
                          testid="invoiceNumber"
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="due_amount"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          id="dueAmount"
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          value={value}
                          onChange={onChange}
                          masking={true}
                          maskNumeric={true}
                        />
                      )}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="refund_amount"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          id="refundAmount"
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          value={value}
                          onChange={onChange}
                          masking={true}
                          maskNumeric={true}
                        />
                      )}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="due_date"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <DateRange
                          theme={theme}
                          placeholder="00/00/0000 - 00/00/0000"
                          onFocus={onfocus}
                          onBlur={onblur}
                          onChange={onChange}
                          format="MM/DD/YYYY"
                          range
                          rangeHover
                          endAdornment={
                            <Icon
                              name="CalendarIcon"
                              className="calendar-icon"
                            />
                          }
                          dateSeparator={" - "}
                          noLabel={true}
                        />
                      )}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="invoice_status"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          id="invoiceStatus"
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="payment_attempts"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          id="paymentAttempts"
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="past_due_days"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          id="pastDueDays"
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="paid_date"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <DateRange
                          theme={theme}
                          placeholder="00/00/0000 - 00/00/0000"
                          onFocus={onfocus}
                          onBlur={onblur}
                          onChange={onChange}
                          format="MM/DD/YYYY"
                          range
                          rangeHover
                          endAdornment={
                            <Icon
                              name="CalendarIcon"
                              className="calendar-icon"
                            />
                          }
                          dateSeparator={" - "}
                          noLabel={true}
                        />
                      )}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="payment_method"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          id="paymentMethod"
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell space-fixed"></TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {contractDataLoading || customerInvoiceSearchResultLoading ? (
                <Fragment>
                  <TableRow>
                    <TableCell colSpan={10}>
                      <Skeleton width={"100%"} height={20} theme={theme} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={10}>
                      <Skeleton width={"100%"} height={20} theme={theme} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={10}>
                      <Skeleton width={"100%"} height={20} theme={theme} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={10}>
                      <Skeleton width={"100%"} height={20} theme={theme} />
                    </TableCell>
                  </TableRow>
                </Fragment>
              ) : (
                <>
                  {filteredCustomerInvoices?.map((invoice, index) => {
                    return (
                      <TableRow key={index} className="child-tr">
                        <TableCell>
                          {invoice.created_date
                            ? utcToLocal(
                              new Date(
                                invoice.created_date * 1000
                              )?.toISOString(),
                              dateFormatSearch
                            )
                            : "-"}
                        </TableCell>
                        <TableCell>{invoice.invoice_type}</TableCell>
                        <TableCell>
                          {invoice.invoice_number ? (
                            <Link
                              className="link"
                              variant="body2"
                              component={"a"}
                            >
                              {invoice.invoice_number
                                ? invoice.invoice_number
                                : "-"}
                            </Link>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell>
                          {(invoice.due_amount > 0
                            ? invoice.due_amount/100
                            : 0
                          )?.toLocaleString("en-GB", {
                            style: "currency",
                            currency: "GBP",
                          })}
                        </TableCell>
                        <TableCell>
                          {(invoice?.charge?.amount_refunded > 0
                            ? invoice?.charge?.amount_refunded /100
                            : 0
                          )?.toLocaleString("en-GB", {
                            style: "currency",
                            currency: "GBP",
                          })}
                        </TableCell>
                        <TableCell>
                          {invoice.due_date
                            ? utcToLocal(
                              new Date(
                                invoice.due_date * 1000
                              )?.toISOString(),
                              dateFormatSearch
                            )
                            : "-"}
                        </TableCell>
                        <TableCell sx={{ textTransform: "capitalize" }}>
                          {invoice.invoice_status}
                        </TableCell>
                        <TableCell>
                          <Link
                            className="link"
                            variant="body2"
                            component={"a"}
                            onClick={() => {
                              setPaymentAttemptOpened(true)
                              handlePaymentAttemptClick(invoice?.id)
                            }}
                          >
                            View Details
                          </Link>
                        </TableCell>
                        <TableCell>{invoice.past_due_days}</TableCell>
                        <TableCell>
                          {invoice?.paid_date
                            ? utcToLocal(
                              new Date(
                                invoice?.paid_date * 1000
                              ).toISOString(),
                              dateFormatSearch
                            )
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {invoice.payment_method
                            ? invoice.payment_method
                            : "-"}
                        </TableCell>
                        <TableCell className="action-cell fixed-cell menuWithNoScroll">
                          <Menu
                            theme={theme}
                            disablePortal={false}
                            NoScroll={true}
                            options={
                              isDeleteInvoiceAllow &&
                                isVoidInvoiceAllow &&
                                isRefundAllow &&
                                isCreditNoteAllow ?
                                [
                                  {
                                    optionText: "Edit Invoice",
                                    optionValue: "edit",
                                    disabled:
                                      invoice.invoice_status !==
                                      InvoiceDisplayStatus.DRAFT ||
                                      !isEditInvoiceAllow ||
                                      invoice.invoice_type !==
                                      InvoiceDisplayType.ADHOC_INVOICE,
                                  },
                                  {
                                    optionText: "Delete Invoice",
                                    optionValue: "delete",
                                    disabled:
                                      invoice.invoice_status !==
                                      InvoiceDisplayStatus.DRAFT ||
                                      contractData?.final_bill_invoice_id ===
                                      invoice?.id,
                                  },
                                  {
                                    optionText: "Void Invoice",
                                    optionValue: "void",
                                    disabled:
                                      invoice.invoice_status ===
                                      InvoiceDisplayStatus.DRAFT ||
                                      invoice.invoice_status ===
                                      InvoiceDisplayStatus.PAID ||
                                      invoice.invoice_status ===
                                      InvoiceDisplayStatus.VOID ||
                                      invoice.invoice_status ===
                                      InvoiceDisplayStatus.UNCOLLECTIBLE,
                                  },
                                  {
                                    optionText: "View Invoice",
                                    optionValue: "view",
                                    disabled: !isViewInvoiceAllow,
                                  },
                                  {
                                    optionText: "Credit Note",
                                    optionValue: "credit_note",
                                    disabled:
                                      invoice.invoice_status ===
                                      InvoiceDisplayStatus.DRAFT ||
                                      invoice.invoice_status ===
                                      InvoiceDisplayStatus.VOID ||
                                      invoice.invoice_status ===
                                      InvoiceDisplayStatus.PENDING,
                                  },
                                  {
                                    optionText: "Refund Invoice",
                                    optionValue: "refund",
                                    disabled:
                                      invoice.invoice_status !==
                                      InvoiceDisplayStatus.PAID,
                                  },
                                  {
                                    optionText: "Resend Invoice",
                                    optionValue: "resend",
                                    disabled:
                                      invoice.invoice_status !==
                                      InvoiceDisplayStatus.OPEN ||
                                      !isResendInvoiceAllow,
                                  },
                                  {
                                    optionText: "Invoice History",
                                    optionValue: "history",
                                    disabled: !isInvoiceHistoryAllow,
                                  },
                                ] :
                                [
                                  {
                                    optionText: "Edit Invoice",
                                    optionValue: "edit",
                                    disabled:
                                      invoice.invoice_status !==
                                      InvoiceDisplayStatus.DRAFT ||
                                      !isEditInvoiceAllow ||
                                      invoice.invoice_type !==
                                      InvoiceDisplayType.ADHOC_INVOICE,
                                  },
                                  {
                                    optionText: "View Invoice",
                                    optionValue: "view",
                                    disabled: !isViewInvoiceAllow,
                                  },
                                  {
                                    optionText: "Resend Invoice",
                                    optionValue: "resend",
                                    disabled:
                                      invoice.invoice_status !==
                                      InvoiceDisplayStatus.OPEN ||
                                      !isResendInvoiceAllow,
                                  },
                                  {
                                    optionText: "Invoice History",
                                    optionValue: "history",
                                    disabled: !isInvoiceHistoryAllow,
                                  },
                                ]
                            }
                            handleOptionClick={(_event, _key, value) => {
                              switch (value) {
                                case "delete":
                                  handleDeleteInvoice(invoice?.id);
                                  break;
                                case "edit":
                                  handleOpenInvoiceDialog(
                                    ModalModes.EDIT,
                                    invoice
                                  );
                                  break;
                                case "void":
                                  handleVoidInvoice(invoice?.id);
                                  break;
                                case "view":
                                  handleOpenInvoiceDialog(
                                    ModalModes.VIEW,
                                    invoice
                                  );
                                  break;
                                case "credit_note":
                                  handleOpenInvoiceDialog(
                                    ModalModes.CREDIT_NOTE,
                                    invoice
                                  );
                                  break;
                                case "refund":
                                  handleOpenRefundDialog(invoice);
                                  break;
                                case "resend":
                                  handleResendInvoice(invoice?.id);
                                  break;
                                case "history":
                                  handleOpenHistoryDialog(invoice);
                                  break;
                              }
                            }}
                            render={(onMenuSelection) => (
                              <Button
                                defaultBtn
                                theme={theme}
                                iconText={<Icon name="MoreIcon" />}
                                onClick={onMenuSelection}
                              />
                            )}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              )}
            </>
          }
        />
        {filteredCustomerInvoices && (
          <TablePagination
            component="div"
            count={totalRecords}
            page={pageNumber}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        {isCreateInvoiceAllow && (
          <Button
            theme={theme}
            secondary
            fullWidth={true}
            text={"Create Invoice"}
            onClick={() => {
              // handleOpenInvoiceDialog(ModalModes.ADD);
              if (!contractData?.customer?.stripe_customer_id) {
                actions.setToast({
                  toastMessage: "Customer is not configured on Stripe",
                  toastState: true,
                  variant: "error",
                });
              } else if (!contractData?.vendor_subscription_id) {
                actions.setToast({
                  toastMessage: "Subscription is not created on Stripe",
                  toastState: true,
                  variant: "error",
                });
              } else {
                if (
                  contractData?.subscription_status ===
                  SubscriptionStatus.PAUSED
                ) {
                  actions.setToast({
                    toastMessage:
                      "Due to a paused subscription or billing flag, this invoice has been created as draft and will not be sent to the customer until the pause or flag has been lifted.",
                    toastState: true,
                    showIcon: true,
                    variant: "error",
                  });
                }
                handleOpenInvoiceDialog(ModalModes.ADD);
              }
            }}
          />
        )}
      </DataTableWrap>
      {openInvoiceDialog && (
        <InvoiceModalComponent
          openInvoiceDialog={openInvoiceDialog}
          setOpenInvoiceDialog={setOpenInvoiceDialog}
          modalOpeningMode={modalOpeningMode}
          setModalOpeningMode={setModalOpeningMode}
          contractData={contractData}
          customerInvoice={selectedInvoice}
          setCustomerInvoice={setSelectedInvoice}
          handleDeleteInvoice={handleDeleteInvoice}
          handleOpenInvoiceDialog={handleOpenInvoiceDialog}
          handleVoidInvoice={handleVoidInvoice}
          voidPInvoice={isVoidInvoiceAllow}
          creditPInvoice={isCreditNoteAllow}
          deletePInvoice={isDeleteInvoiceAllow}
          editInvoice={isEditInvoiceAllow}
          handleOpenHistoryDialog={handleOpenHistoryDialog}
          handleOpenRefundDialog={handleOpenRefundDialog}
        />
      )}
      {openHistoryDialog && (
        <InvoiceHistoryModalComponent
          openHistoryDialog={openHistoryDialog}
          customerInvoice={selectedInvoice}
          setOpenHistoryDialog={setOpenHistoryDialog}
        />
      )}
      {openRefundDialog && (
        <RefundModalComponent
          openRefundDialog={openRefundDialog}
          setOpenRefundDialog={setOpenRefundDialog}
          customerInvoice={selectedInvoice}
        />
      )}
      <Dialog
        theme={theme}
        size="xs"
        variant="confirmation"
        open={dialogOpened}
        children={
          <>
            <Box theme={theme} textAlign="center">
              <Box theme={theme} className="dialog-icon">
                <Icon name="ErrorTriangle" />
              </Box>
              <Box theme={theme} mt={4}>
                <Typography
                  theme={theme}
                  mb={2}
                  className="content-title"
                  variant="h2"
                  component="h2"
                >
                  Are you sure you want to{" "}
                  {selectedMenuOption === "delete"
                    ? "delete"
                    : selectedMenuOption === "void"
                      ? "void"
                      : null}{" "}
                  this invoice?
                </Typography>
                <Typography
                  theme={theme}
                  className="text-muted"
                  variant="body2"
                  component="span"
                >
                  Once saved, you cannot undo this action.
                </Typography>
              </Box>
            </Box>
          </>
        }
        customFooter={
          <>
            <Grid container theme={theme} spacing={2}>
              <Grid theme={theme} item xs={6} textAlign={"right"}>
                <Button
                  theme={theme}
                  secondary
                  text="Cancel"
                  onClick={() => {
                    setDialogOpened(false);
                  }}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Button
                  theme={theme}
                  disabled={voidInvoiceLoading || deleteInvoiceLoading}
                  primary
                  text="Save"
                  onClick={handleOnSave}
                  endIcon={
                    (deleteInvoiceLoading || voidInvoiceLoading) && (
                      <img src={DisableLoader} alt="Loader" />
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        }
      />
      <Dialog
        theme={theme}
        title={isPaymentAttemptsLoading ? "Payment Attempts" :`Payment Attempts (${chargeDetails.length || 0})`}
        open={paymentAttemptDialogOpened}
        disablePortal={false}
        children={
          <>
            <DataTable
              theme={theme}
              theadChildren={
                <>
                  <TableRow className="table-header">
                    <TableCell width={200}>
                      <Box theme={theme} className="table-data">
                        <Box className="table-data-title" theme={theme}>
                          Date
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box theme={theme} className="table-data">
                        <Box className="table-data-title" theme={theme}>
                          Reason
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                </>
              }
              tbodyChildren={
                <>
                  {isPaymentAttemptsLoading ? (
                    <>
                      <TableRow>
                        <TableCell>
                          <Skeleton
                            width={"100%"}
                            height={20}
                            theme={theme}
                          />
                        </TableCell>
                        <TableCell>
                          <Skeleton
                            width={"100%"}
                            height={20}
                            theme={theme}
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    (chargeDetails?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item?.created
                          ? utcToLocal(
                            new Date(
                              item?.created * 1000
                            )?.toISOString(),
                            dateFormatSearch
                          )
                          : "-"}</TableCell>
                        <TableCell>{item?.failure_message === null && item?.status === "succeeded" ?
                          "Payment attempt was successful" : item?.failure_message}</TableCell>
                      </TableRow>
                    )))
                  )}
                </>
              }
            />
          </>
        }
        customFooter={
          <>
            <Button
              theme={theme}
              primary
              onClick={() => {
                setPaymentAttemptOpened(false)
              }}
              text={"Done"}
            />
          </>
        }
      />
    </>
  );
};
