import { FC, useState, useEffect } from 'react'
import { useTheme } from '@mui/material'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Icon,
  Input,
  Select,
  Tooltip,
  Typography
} from '@ntpkunity/controls'
import { useGetFnIProductRate } from '@apis/financial-insurance.service'
const FIPriceDialog: FC<{
  isOpen: any
  setIsOpen: any
  details: any
  defaultCurrency: any
  selectDataObj?: any
  dealerInfo?: any
  formattedData?: any
  editPopupCallback?: any
}> = ({
  isOpen,
  setIsOpen,
  details,
  defaultCurrency,
  selectDataObj,
  dealerInfo,
  formattedData,
  editPopupCallback
}) => {
  const theme = useTheme()
  const { mutate: getProductRates, data: productRates } = useGetFnIProductRate()
  const [selectedFinalCoverage, setSelectedFinalCoverage] = useState<any>([])
  const [selectedFinalDeductible, setSelectedFinalDeductible] = useState<any>([])
  const [selectedSurcharges, setselectedSurcharges] = useState<any>([])
  const [selected, setSelected] = useState<string[]>(details.surcharges ?? [])
  const [coverageFormId, setCoverageFormId] = useState<string>(`${details?.form_id}`)
  const [rateID, setRateID] = useState<string>(`${details?.rate_id}`)
  const [retailPrice, setRetailPrice] = useState<any>()
  const [deductiblePrice, setDeductiblePrice] = useState<any>()
  const [coverageData, setCoverageData] = useState<{
    term_miles: Array<string>
    term_months: Array<string>
  }>({ term_miles: [], term_months: [] })

  useEffect(() => {
    if (formattedData && details && dealerInfo) {
      getProductRates({
        data: getRateRequestObject(formattedData, details, dealerInfo)
      })
    }
  }, [formattedData, details, dealerInfo])

  const normalizeDeductibles = (deductibles: any): any[] => {
    if (!deductibles) return []
    return Array.isArray(deductibles) ? deductibles : [deductibles]
  }

  const getRateRequestObject = (formattedData: any, details: any, dealerInfo: any) => {
    return {
      deal_info: dealerInfo,
      products_info: [
        {
          product_id: details?.product_id,
          starting_mileage: 0,
          ending_mileage: 10000,
          filters: [{ term_months: details?.term_months }],
          surcharges: formattedData?.surcharges
        }
      ],
      test_request: true
    }
  }

  useEffect(() => {
    if (details?.price) {
      setRetailPrice(details.price)
    }
    if (details?.term_months) {
      setCoverageData((prevState) => ({
        ...prevState,
        ...(details?.term_months ? { term_months: [details.term_months] } : {}),
        ...(details?.term_miles ? { term_miles: [details.term_miles] } : {})
      }))
    }

    if (details?.deductiblePrice) setDeductiblePrice(details?.deductiblePrice)
    if (details?.form_id) setCoverageFormId(details?.form_id)
    if (details?.rate_id) setRateID(details?.rate_id)
  }, [details])

  useEffect(() => {
    if (productRates?.rates?.rate) {
      const finalCoverage = productRates?.rates?.rate?.coverages
      if (finalCoverage) {
        const coverages = finalCoverage?.coverage
        if (Array.isArray(coverages) && coverages.length > 0) {
          const selectedDeductibles = finalCoverage?.coverage?.filter(
            (item: any) => item?.form?.form_id === Number(details?.form_id)
          )
          if (selectedDeductibles && selectedDeductibles.length > 0) {
            if (selectedDeductibles.length === 1) {
              setSelectedFinalDeductible(selectedDeductibles[0]?.deductibles?.deductible)
              setselectedSurcharges(selectedDeductibles?.[0]?.surcharges)
            } else {
              const matchingDeductible = selectedDeductibles?.find((deductible: any) => {
                const deductibles = Array.isArray(deductible?.deductibles?.deductible)
                  ? deductible?.deductibles?.deductible
                  : [deductible?.deductibles?.deductible]
                return deductibles.some((d: any) => d?.rate_id === Number(rateID))
              })

              if (matchingDeductible) {
                const deductible = Array.isArray(matchingDeductible?.deductibles?.deductible)
                  ? matchingDeductible?.deductibles?.deductible
                  : Array.of(matchingDeductible?.deductibles?.deductible)

                setSelectedFinalDeductible(deductible)
                setselectedSurcharges(matchingDeductible?.surcharges)
              } else {
                const minDeductible = selectedDeductibles.reduce(
                  (minItem: any, currentItem: any) => {
                    const currentRetailPrice = currentItem?.deductibles?.deductible?.retail_price
                    const minRetailPrice = minItem?.deductibles?.deductible?.retail_price
                    return currentRetailPrice < minRetailPrice ? currentItem : minItem
                  }
                )
                const mininumDeductible = Array.isArray(minDeductible?.deductibles?.deductible)
                  ? minDeductible?.deductibles?.deductible
                  : Array.of(minDeductible?.deductibles?.deductible)

                setSelectedFinalDeductible(mininumDeductible)
                setselectedSurcharges(minDeductible?.surcharges)
              }
            }
          }
          setSelectedFinalCoverage(productRates?.rates?.rate)
        } else {
          if (coverages?.deductibles) {
            setSelectedFinalDeductible(coverages?.deductibles?.deductible)
            setSelectedFinalCoverage(productRates?.rates?.rate)
            setselectedSurcharges(coverages?.deductibles?.surcharges)
          }
        }
      }
    }
  }, [productRates, details?.product_id])

  const handleClose = () => {
    setIsOpen(false)
  }

  const calculateRetailPrice = (basePrice: number, markup: number): number => {
    if (!basePrice || !markup) return basePrice || 0
    return basePrice + (basePrice * markup) / 100
  }

  const selectFAndIData = (formID: string, rateID: string) => {
    let coverageObj = selectedFinalCoverage?.coverages?.coverage?.filter(
      (x: any) =>
        x?.form?.form_id === Number(formID) &&
        normalizeDeductibles(x?.deductibles?.deductible).some(
          (deductible: any) => deductible?.rate_id === Number(rateID)
        )
    )

    if (coverageObj) {
      const deductibles = normalizeDeductibles(
        coverageObj?.deductibles?.deductible ?? coverageObj?.[0]?.deductibles?.deductible
      )

      if (deductibles.length > 0) {
        const selectedDeductible =
          deductibles.find((deductible: any) => deductible?.rate_id === Number(rateID)) ||
          deductibles[0]

        setRateID(selectedDeductible?.rate_id)
        setDeductiblePrice(selectedDeductible?.retail_price)
        setRetailPrice(calculateRetailPrice(selectedDeductible?.retail_price, details?.markup))
        setSelectedFinalDeductible(deductibles)
      }
      setSelected([])
      setselectedSurcharges(coverageObj?.surcharges ?? coverageObj?.[0]?.surcharges)
      const termMiles = coverageObj?.term_miles ?? coverageObj?.[0]?.term_miles
      const termMonths = coverageObj?.term_months ?? coverageObj?.[0]?.term_months
      setCoverageData({
        term_miles: termMiles ? [termMiles] : [],
        term_months: termMonths ? [termMonths] : []
      })
    }
  }

  const updatePrice = () => {
    const coverageObj = selectDataObj.find((x: any) => x?.product_id === details?.product_id)
    if (coverageObj) {
      coverageObj.price = retailPrice
      coverageObj.surcharges = selected
      coverageObj.form_id = coverageFormId
      coverageObj.rate_id = rateID
      coverageObj.deductiblePrice = deductiblePrice
      selectDataObj = selectDataObj.map((obj: any) =>
        obj?.product_id === details?.product_id
          ? {
              ...obj,
              price: coverageObj.price
            }
          : obj
      )
      editPopupCallback(coverageObj)
    }
    setIsOpen(false)
  }

  const getPriceToolTip = (price: any): string | null => {
    const finalPrice = calculateRetailPrice(price, details?.markup)
    return `${(finalPrice - price).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })} (${details?.markup}%)`
  }

  const getBaseAmount = (price: any): any => {
    return price.toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
  }
  const handleCheckboxChange = (code: string) => {
    const isBoxSelected = selected.includes(code)
    const updatedSelected = isBoxSelected
      ? selected.filter((item) => item !== code)
      : [...selected, code]
    setSelected(updatedSelected)
    let totalSurchargeAmount = 0
    updatedSelected?.forEach((code) => {
      const surCharge = selectedSurcharges?.surcharged?.find((s: any) => s?.code === code)
      if (surCharge) {
        totalSurchargeAmount += parseFloat(surCharge?.retail_price)
      }
    })
    const deductible = selectedFinalDeductible?.filter(
      (item: any) => item?.rate_id === Number(rateID)
    )
    const markupAmount = details?.markup || 0
    const markup =
      updatedSelected?.length > 0
        ? ((totalSurchargeAmount + deductible[0]?.retail_price) * markupAmount) / 100
        : (deductible[0]?.retail_price * markupAmount) / 100
    const totalRetailPrice = markup + deductible[0]?.retail_price + totalSurchargeAmount
    setRetailPrice(totalRetailPrice)
  }
  const formatDeductible = (price: number) =>
    defaultCurrency +
    price.toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })

  return (
    <Dialog
      theme={theme}
      title={details?.product_name}
      size="xs"
      open={isOpen}
      onCloseDialog={handleClose}
      customFooter={
        <>
          <Button theme={theme} secondary text={'Cancel'} onClick={handleClose} />
          <Button
            theme={theme}
            disabled={false}
            type="submit"
            primary
            text="Save"
            onClick={updatePrice}
          />
        </>
      }
    >
      <Box theme={theme}>
        <Select
          theme={theme}
          disablePortal={true}
          disabled={false}
          fullWidth
          label={'Coverage Name'}
          value={`${coverageFormId}-${rateID}`}
          items={
            Array.isArray(selectedFinalCoverage?.coverages?.coverage)
              ? selectedFinalCoverage.coverages.coverage.map((category: any) => {
                  const deductibles = category?.deductibles?.deductible

                  const deductibleArray = Array.isArray(deductibles)
                    ? deductibles
                    : deductibles
                    ? [deductibles]
                    : []

                  const matchingDeductible = deductibleArray.find(
                    (deductible: any) => deductible?.rate_id === rateID
                  )

                  const selectedDeductible =
                    matchingDeductible ??
                    deductibleArray.reduce(
                      (lowest: any, current: any) =>
                        current.retail_price < lowest.retail_price ? current : lowest,
                      deductibleArray[0]
                    )

                  return {
                    value: `${category?.form?.form_id}-${selectedDeductible?.rate_id ?? ''}`,
                    text: category?.coverage_name
                  }
                })
              : selectedFinalCoverage?.coverages?.coverage
              ? [
                  {
                    value: `${selectedFinalCoverage.coverages.coverage.form?.form_id}-${
                      Array.isArray(
                        selectedFinalCoverage.coverages.coverage?.deductibles?.deductible
                      )
                        ? selectedFinalCoverage.coverages.coverage.deductibles.deductible.reduce(
                            (lowest: any, current: any) =>
                              current.retail_price < lowest.retail_price ? current : lowest
                          )?.rate_id ?? ''
                        : selectedFinalCoverage.coverages.coverage?.deductibles?.deductible
                            ?.rate_id ?? ''
                    }`,
                    text: selectedFinalCoverage.coverages.coverage.coverage_name
                  }
                ]
              : []
          }
          onChange={(e: any) => {
            const selectedValue = e?.target?.value
            if (selectedValue) {
              const [extractedFormId, extractedRateId] = selectedValue.split('-')
              setCoverageFormId(extractedFormId)
              setRateID(extractedRateId)
              selectFAndIData(extractedFormId, extractedRateId)
            }
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
        <Select
          theme={theme}
          disablePortal={false}
          fullWidth
          disabled={false}
          label={'Term'}
          value={coverageData?.term_months?.[0] || ''}
          items={(coverageData?.term_months || [])?.map((term: any) => ({
            value: term,
            text: `${term} Months`
          }))}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
        <Select
          theme={theme}
          disablePortal={false}
          fullWidth
          disabled={false}
          label={'Mileage'}
          value={coverageData?.term_miles?.[0] || ''}
          items={coverageData?.term_miles?.map((milage: any) => ({
            value: milage,
            text: `${new Intl.NumberFormat('en-US')?.format(milage)} Miles`
            // text: `${milage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} Miles`
          }))}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
        <Select
          theme={theme}
          disablePortal={false}
          fullWidth
          disabled={false}
          label={'Deductible'}
          value={rateID}
          items={normalizeDeductibles(selectedFinalDeductible)?.map((deductible: any) => ({
            value: deductible?.rate_id,
            text: formatDeductible(deductible?.retail_price)
          }))}
          onChange={(e: any) => {
            const selectedRateId = e?.target?.value
            if (selectedRateId) {
              setRateID(selectedRateId)
              const selectedDeductible = normalizeDeductibles(selectedFinalDeductible).find(
                (item: any) => item?.rate_id === Number(selectedRateId)
              )
              setSelected([])
              setDeductiblePrice(selectedDeductible?.retail_price)
              setRetailPrice(
                calculateRetailPrice(selectedDeductible?.retail_price, details?.markup)
              )
            }
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
        <Box theme={theme}>
          {selectedSurcharges?.surcharged ? (
            <Box theme={theme} mb={3}>
              <Typography variant="subtitle1" component={'div'} theme={theme} mb={2}>
                Surcharges
              </Typography>
              {selectedSurcharges?.surcharged?.map((surcharge: any, _index: number) => (
                <Box
                  key={surcharge?.code}
                  theme={theme}
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  mb={2}
                >
                  <Checkbox
                    theme={theme}
                    label={surcharge?.description}
                    checkBoxChecked={selected?.includes(surcharge?.code)}
                    onChange={() => handleCheckboxChange(surcharge?.code)}
                  />
                  <Typography variant="body2" component={'span'} theme={theme}>
                    {defaultCurrency}
                    {(surcharge?.retail_price ?? 0).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : null}
          <Input
            theme={theme}
            fullWidth
            label={'Price'}
            type={'text'}
            masking
            maskNumeric
            maskDecimalScale={2}
            value={retailPrice}
            onChange={(value) => setRetailPrice(Number(value))}
            startAdornment={defaultCurrency}
            endAdornment={
              <Tooltip
                theme={theme}
                title={
                  <>
                    <Typography
                      theme={theme}
                      component="p"
                      variant="subtitle2"
                      children={`Markup: ${defaultCurrency}${getPriceToolTip(
                        deductiblePrice ?? 0
                      )}`}
                    />
                    <Typography
                      theme={theme}
                      component="p"
                      variant="subtitle2"
                      children={`Base Amount: ${defaultCurrency}${getBaseAmount(
                        deductiblePrice ?? 0
                      )}`}
                    />
                  </>
                }
              >
                <Icon name="InfoIcon" />
              </Tooltip>
            }
          />
        </Box>
      </Box>
    </Dialog>
  )
}

export default FIPriceDialog
