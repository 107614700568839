import { useTheme } from "@mui/material"
import { AddStickyNoteWrap } from "./add-sticky-note.style"
import img from "@src/assets/icons/addNotesIcon.svg"
import { Typography, Box, Button, Icon } from "@ntpkunity/controls";
import { clsx } from "clsx"

export const AddStickyNote = ({}) => {
    const theme = useTheme();

    return(
        <>
            <AddStickyNoteWrap theme={theme} className="add-sticky-note-wrap">
                <img src={img} className="" />
                <Typography theme={theme} variant="body1" component={'small'} className="text-body1 add-note">Add Sticky Note</Typography>
            </AddStickyNoteWrap>
        </>
    )
}